import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Modal_ from './Modal'
import amplitude from 'amplitude-js'

const TeamCard = (props) => {
    //get props

    const [show, setShow] = useState(false)
    const doHide = () => setShow(false)

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        <div style={isMobile ? {width: '100%', padding: '20px', color: 'white', }: {width: '100%', padding: '20px', color: 'white', display: 'flex', justifyContent: 'space-between'}} key={props.bit.title}>
            <div style={{display: isMobile ? 'inline' : 'flex'}}>
                <div style={{borderColor: 'white', border: '1px solid rgb(120,120,120)', borderRadius: '.3rem', width: isMobile ? '100%' : 280, height: isMobile ? 160 : 80, padding: '10px'}}>
                    <img src={props.bit.images_black[0]} style={{width: '100%'}} />
                </div>
                {isMobile ? <br /> : null}
                <div style={isMobile ? {width: '100%', display: 'inline'} : {paddingLeft: '20px',}}>
                    <div style={{fontSize: '1.1rem', }}>
                        {props.bit.title}
                    </div>
                    <div style={{fontSize: '.8rem', color: 'rgb(110,110,110)'}}>
                    {props.bit.paragraphs[0].substring(0,140)}...

                    </div>
                </div>
            </div>
            <div style={{fontSize: '.6rem', textAlign: isMobile ? 'center':'right', marginTop: isMobile ? '6px':'32px', marginLeft: isMobile ? '0px':'10px'}}>
                <button onClick={()=>{setShow(props.bit.title); amplitude.getInstance().logEvent(
                    "learn", {
                          "question": props.bit.title
                    }
                )}} style={{minWidth: isMobile ? '60%':'90px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: isMobile ? '0px':'8px', paddingRight: '8px', backgroundColor: '#c4b454', borderRadius: '1rem', border: 'none', outline: 'none'}} data-backdrop="false">
                    Ready Story
                </button>
            </div>
        </div>
        {show && <Modal_ key={props.bit.title} show={show} hide={doHide} item={props.bit} />}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(TeamCard)