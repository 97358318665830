import React, {useState, useEffect} from 'react'
import './Analysis.css'
import {db, firebaseAnalytics} from '../../services/firebase'
import ReactHtmlParser from 'react-html-parser'
import Article from './Article'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import firebase from 'firebase'

const Ticker = (props) => {
    //props
    const teams = props.teams


    return(<>
        {/* Ticker */}
        <div className="marquee">
            <Link to="/Trade">
                {/*<hr className="marquee-line" />*/}
                <span className='marquee-text'>{teams.map(t => {
                        return(<>{`Sim${t.team} $${t.lastSoldPrice.toFixed(2)} `}{' '}{'|'} </>)
                    
                })}</span>
                <hr className="marquee-line" />
            </Link>
        </div>
    </>)
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Ticker)