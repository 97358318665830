import React, {useState,useEffect, useRef} from 'react'
import NavBar from './NavBar2'
import Top from '../Top'
import NumberProof from './NumberProof'
import Timeline from './Timeline'
import Reviews from './Reviews'
import Questions from './Questions'
import Footer from './Footer'
import {firebaseAnalytics} from '../../../services/firebase'
import Popup from 'reactjs-popup'
import Signup from '../Signup'
import './Timeline.css'
import useMouseLeave from 'use-mouse-leave';
import Newsletter from './newsletter'

const LandingPage = () => {

    let headerImg = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ftop_landing_page.png?alt=media&token=5a6eb76a-a831-47e2-bbed-bd7008e083b8'

    const [signup, setSignup] = useState(false)

    const seeSignup = () => setSignup(true)

    const [leaving, setLeaving] = useState(false)
    const [leavingCount, setLeavingCount] = useState(0)
    const [mouseLeft, ref] = useMouseLeave();
    useEffect(() => {
        if (mouseLeft) {
            // The mouse has just left our element, time to
            // run whatever it was we wanted to run on mouseleave:
            // ...
        }
    }, [mouseLeft]);

    useEffect(()=>{
        firebaseAnalytics.logEvent('landing_page_1')
    },[])

    const doMouseLeave = () => {
            if(!leaving){
                seeSignup(true)
            }
            setLeavingCount(leavingCount + 1)
            setLeaving(true)
    }


    return(<div ref={ref} onMouseLeave={doMouseLeave} style={{height: "100%", minWidth: "100%", backgroundColor: "black", }}>

        <NavBar one={true} />
        <Top one={true} />
        <NumberProof one={true} />
        <Reviews one={true} />
        <Timeline seeSignup={() => setSignup(true)} one={true} />
        <Questions seeSignup={() => setSignup(true)} one={true} />
        <Newsletter />
        <Footer one={true} />
{/* 
        <Popup open={signup} modal>
            <div className='signup_modal' style={{ backgroundColor: 'rgb(250,250,250)', border: '1px solid black', boxShadow: '0 0 1px 2px #c5b358',}}>
                <div style={{width: '100%', marginLeft: '0%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>

                    <div style={{textAlign: 'center', fontWeight: '600', fontSize: '1.5rem', padding: '10px'}}>{leavingCount === 1 ? `You're leaving a free $10 on the table...`: <>Almost there. <br/> Get your free $10.</>}</div>

                    {leavingCount == 1 ? <div style={{padding: '20px'}}>
                        We could have <strong>SWORN</strong> you were someone who wanted to make money. Our Bad. We'll just have to keep the $10 in our pocket then...
                    </div> : 
                        <div style={{padding: '20px'}}>
                        <h4 className="Top-CTA-subtitle" style={{marginTop: '0px', marginBottom: '15px', fontSize: '.9rem', fontWeight: '400'}}>Join <strong>12,000+ bullish investors</strong> and see why experts are calling the <strong>Stock Market for Sports</strong> the future of investing</h4>
                    </div>
                    }

                    <Signup />
                
                    <div style={{textAlign: 'center', backgroundColor: "black"}}>
                        <button onClick={()=>setSignup(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                    </div>
                </div>
                
            </div>
        </Popup> */}

    </div>)
}

export default LandingPage