import React, {useState, useEffect} from 'react'
import './Analysis.css'
import { connect } from 'react-redux'
import {getIndexByID} from '../../routes/teams/indexes'
import IndexChart from '../Indexes/IndexChart'
import Loader from '../adHoc/Loader'


const Indexes = () => {
    //Indexes States
    const [SB92, setSB92] = useState({
        total: 0,
        change: 0,
        percentChange: 0,
    })
    const [SB157, setSB157] = useState({
        total: 0,
        change: 0,
        percentChange: 0,
    })
    const [simMLB, setSimMLB] = useState(0)
    const [simNBA, setSimNBA] = useState(0)
    const [simNFL, setSimNFL] = useState(0)
    const [simCFB, setSimCFB] = useState(0)
    const [simNHL, setSimNHL] = useState(0)

    //Index history
    const [SB92history, setSB92history] = useState({})
    const [SB157history, setSB157history] = useState({})
    const [simMLBhistory, setSimMLBhistory] = useState({})
    const [simNBAhistory, setSimNBAhistory] = useState({})
    const [simNFLhistory, setSimNFLhistory] = useState({})
    const [simCFBhistory, setSimCFBhistory] = useState({})
    const [simNHLhistory, setSimNHLhistory] = useState({})

    const calcIndexes = (res, setType, setTypeHistory, league) => {
        let indArr = res
        indArr.sort((a,b) => {
            if(a.timestamp > b.timestamp){
                return -1
            }
            else return 1
        })
        //main
        if(indArr.length > 31 && indArr[0] && indArr[30] && indArr[0].price && indArr[30].price){
            let indexObj = {
                total: indArr[0].price, //* ((league === 'SB157' || league === 'SB92') ?  1000 : 1 ),
                change: (indArr[0].price - indArr[30].price) * ((league === 'SB157' || league === 'SB92') ?  1 : 1 ),
                percentChange: ((indArr[0].price - indArr[30].price)/indArr[30].price).toFixed(2)
            }
            setType(indexObj)
        }
        else if(indArr.length > 1 && indArr[0] && indArr[1] && indArr[0].price && indArr[1].price){
            let indexObj = {
                total: indArr[0].price, //* ((league === 'SB157' || league === 'SB92') ?  1000 : 1 ),
                change: (indArr[0].price - indArr[1].price) * ((league === 'SB157' || league === 'SB92') ?  1 : 1 ),
                percentChange: ((indArr[0].price - indArr[1].price)/indArr[1].price).toFixed(2)
            }
            setType(indexObj)
        }
        else{
            if(indArr.length > 0){
                let indexObj = {
                    total: indArr[0].price, // * ((league === 'SB157' || league === 'SB92') ?  1000 : 1 ),
                    change: 0,
                    percentChange: 0 
                }
                setType(indexObj)
            }
        }
        //dates and prices
        let dates = []
        let prices = []
        let numOfDays = indArr.length > 30 ? 30 : indArr.length
        for (let i = numOfDays - 1; i >= 0; i--){
            dates.push(indArr[i].timestamp.substring(0, 10))
            prices.push((indArr[i].price * ((league === 'SB157' || league === 'SB92') ?  1 : 1 )).toFixed(2))
        }

        setTypeHistory({
            dates: dates,
            prices: prices,
        })
    }

    useEffect(()=>{
        getIndexByID('Sim189', 'DAY').then(res => {
            calcIndexes(res, setSB157, setSB157history, 'SB157')
        })
        getIndexByID('Sim92', 'DAY').then(res => {
            calcIndexes(res, setSB92, setSB92history, 'SB92')
        })
        getIndexByID('SimNFL', 'DAY').then(res => {
            calcIndexes(res, setSimNFL, setSimNFLhistory, 'NFL')
        })
        getIndexByID('SimCFB', 'DAY').then(res => {
            calcIndexes(res, setSimCFB, setSimCFBhistory, 'CFB')
        })
        getIndexByID('SimNBA', 'DAY').then(res => {
            calcIndexes(res, setSimNBA, setSimNBAhistory, 'NBA')
        })
        getIndexByID('SimMLB', 'DAY').then(res => {
            calcIndexes(res, setSimMLB, setSimMLBhistory, 'MLB')
        })
        getIndexByID('SimNHL', 'DAY').then(res => {
            calcIndexes(res, setSimNHL, setSimNHLhistory, 'NHL')
        })
    },[])

    return(
    <div className="TA-Indexes">
        <div className="TA-IndexCard">
        {SB157history ? <IndexChart labels={SB157history['dates']} prices={SB157history['prices']} /> : <Loader />}
            <div className="TA-IC-Level">
                
                <div className="TA-IC-Left taic-name">SB189</div>
                <div className={SB157.percentChange && SB157.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{SB157.percentChange ?  SB157.percentChange: '0.00'}%</div>
            </div>
            
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{SB157.total && SB157.total.toFixed(2)}</div>
                <div className={SB157.change && SB157.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{SB157.change ?  SB157.change.toFixed(2) : '0.00'}</div>
                
            </div>
            
        </div>
        {/*<div className="taic-line"></div>*/}
        <div className="TA-IndexCard">
        {SB92history ? <IndexChart labels={SB92history['dates']} prices={SB92history['prices']}  /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">Sim92</div>
                <div className={SB92.percentChange && SB92.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{SB92.percentChange ? SB92.percentChange : '0.00'}%</div>
            </div>
            
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{SB92.total && SB92.total.toFixed(2)}</div>
                <div className={SB92.change && SB92.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{SB92.change ? SB92.change.toFixed(2) : '0.00'}</div>
                
            </div>
            
        </div>
        <div className="TA-IndexCard">
        {simNFLhistory ? <IndexChart labels={simNFLhistory['dates']} prices={simNFLhistory['prices']} /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">SimNFL</div>
                <div className={simNFL.percentChange && simNFL.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNFL.percentChange ? simNFL.percentChange : '0.00'}%</div>
            </div>
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{simNFL.total && simNFL.total.toFixed(2)}</div>
                <div className={simNFL.change && simNFL.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNFL.change ? simNFL.change.toFixed(2) : '0.00'}</div>
            </div>
            
        </div>
        <div className="TA-IndexCard ">
        {simNBAhistory ? <IndexChart labels={simNBAhistory['dates']} prices={simNBAhistory['prices']} /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">SimNBA</div>
                <div className={simNBA.percentChange && simNBA.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNBA.percentChange ? simNBA.percentChange : '0.00'}%</div>
            </div>
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{simNBA.total && simNBA.total.toFixed(2)}</div>
                <div className={simNBA.change && simNBA.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNBA.change ? simNBA.change.toFixed(2) : '0.00'}</div>
            </div>
            
        </div>

        <div className="TA-IndexCard ">
        {simNHLhistory ? <IndexChart labels={simNHLhistory['dates']} prices={simNHLhistory['prices']} /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">SimNHL</div>
                <div className={simNHL.percentChange && simNHL.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNHL.percentChange ? simNHL.percentChange : '0.00'}%</div>
            </div>
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{simNHL.total && simNHL.total.toFixed(2)}</div>
                <div className={simNHL.change && simNHL.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simNHL.change ? simNHL.change.toFixed(2) : '0.00'}</div>
            </div>
            
        </div>

        <div className="TA-IndexCard ">
        {simCFBhistory ? <IndexChart labels={simCFBhistory['dates']} prices={simCFBhistory['prices']} /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">SimCFB</div>
                <div className={simCFB.percentChange && simCFB.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simCFB.percentChange ? simCFB.percentChange : '0.00'}%</div>
            </div>
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{simCFB.total && simCFB.total.toFixed(2)}</div>
                <div className={simCFB.change && simCFB.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simCFB.change ? simCFB.change.toFixed(2) : '0.00'}</div>
            </div>
            
        </div>

        <div className="TA-IndexCard">
        {simMLBhistory ? <IndexChart labels={simMLBhistory['dates']} prices={simMLBhistory['prices']}  /> : <Loader />}
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-name">SimMLB</div>
                <div className={simMLB.percentChange && simMLB.percentChange > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simMLB.percentChange ? simMLB.percentChange : '0.00'}%</div>
            </div>
            <div className="TA-IC-Level">
                <div className="TA-IC-Left taic-total">{simMLB.total && simMLB.total.toFixed(2)}</div>
                <div className={simMLB.change && simMLB.change > -0.00001 ? 'TA-IC-Right green-text' : "TA-IC-Right red-text"}>{simMLB.change ? simMLB.change.toFixed(2) : '0.00'}</div>
            </div>
            
        </div>
        
        
        
    </div>
    )

}


const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Indexes)