import React, {useState, useEffect} from "react";
import "./TeamReports.css";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {db, firebaseAnalytics} from '../../../services/firebase'
//import Polls from '../../TradeBlock/Polls'

const TeamReports = (props) => {

    const id = props.match.params.id

    const user = props.user
    const teams = props.teams

    const gold = user.gold ? true : false

    const [team, setTeam] = useState()
    const [noReport, setNoReport] = useState(false)

    useEffect(()=>{
        if(!team){
            db.collection('TeamReports').doc(id).get().then(doc => {
                if(doc.exists){
                    const data = doc.data()
                    setTeam(data)
                }
                else{
                    setNoReport(true)
                }
                
            }).catch(err => alert('err'))
        }
        firebaseAnalytics.logEvent("teamReport_visit", {
           user: user.email 
        })
    },[])

    //Displays
    //1. Current Price
    //2. 2020 Record
    //3. 2021 Project Win Payouts
    //4. Reports
    //5. On the field
    //6. In the market

    return(
        <div style={{padding: '3%', paddingTop: '10vh', paddingBottom: '10vh', backgroundColor: 'rgb(245,245,245)'}}>
            {team && gold && <>
                <div className="Reports-title">Team</div> 
                <div className="Reports-item">Sim{team.team && team.team} {teams.map(t => {
                    if(t.team === team.team){
                        return(<span style={{fontWeight: '100'}}>{' '}${t.lastSoldPrice.toFixed(2)}</span>)
                    }})}    
                </div><br />
                <div className="Reports-title">2021 Projected Win Payouts</div> 
                <div className="Reports-item">{team.projectedPayout && team.projectedPayout}</div><br />
                <div className="Reports-title">2020 Record</div>
                <div className="Reports-item" style={{fontWeight: '100'}}> {team.record && team.record}</div><br />
                <div className="Reports-title">On The Field</div> 
                <div className="Reports-body"> {team.field && team.field}</div><br />
                <div className="Reports-title">In The Market </div> 
                <div className="Reports-body">{team.market && team.market}</div><br />
                {/*teams.map(t => {
                    if(t.team === team.team){
                        return <Polls t={t} report={true} />
                    }
                })*/}
            </>}
            {team && !gold && <div style={{minHeight: '100vh'}}>
                <div className="Reports-title">Team</div> 
                <div className="Reports-item">Sim{team.team && team.team} {teams.map(t => {
                    if(t.team === team.team){
                        return(<span style={{fontWeight: '100'}}>{' '}${t.lastSoldPrice.toFixed(2)}</span>)
                    }})}    
                </div><br />
                <div className="Reports-title">2020 Record</div>
                <div className="Reports-item" style={{fontWeight: '100'}}> {team.record && team.record}</div><br />
                <div className="Polls-NoGOLD">
                        <span className="Polls-NoGOLD-locked">Locked</span><br />
                        <span className="Polls-NoGOLD-cta">Get GOLD for Free Trading, Personalized Daily Reports, and Analyst Team Reports</span><br />
                        <Link to="/Subscription"><button className="Polls-NoGOLD-btn">Get GOLD</button></Link>
                    </div>
                
            </div>}
            {!team && noReport && <div style={{minHeight: '100vh'}}>
                <div className="Reports-title">Team</div> 
                <div className="Reports-item">Sim{id} {teams.map(t => {
                    if(t.team === id){
                        return(<span style={{fontWeight: '100'}}>{' '}${t.lastSoldPrice.toFixed(2)}</span>)
                    }})}    
                </div><br />
                <div className="Reports-title">Report Coming Soon</div> 
            </div>}
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(withRouter(TeamReports))