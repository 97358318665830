import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import Bits from '../BitsDesktop/Bits'
import Learn from './Learn'
import logo1 from '../../assets/Logo1.png'

const LearnPage = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{paddingLeft: isMobile ? '0px':'200px', paddingRight: isMobile ? '0px':'300px'}}>
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '2vh'}}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                        <span style={{}}>Learn</span>
                    </div>
                </div>
            : null}
            <Learn />
            <Bits />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(LearnPage)