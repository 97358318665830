import React, {useState} from 'react'
import {db, firebaseAnalytics} from '../../../services/firebase'
import moment from 'moment'

const AddNews = () => {

    //State
    const [body, setBody] = useState('')
    const [date, setDate] = useState('')
    const [author, setAuthor] = useState('')
    const [title, setTitle] = useState('')
    const [type, setType] = useState('')
    const [url, setUrl] = useState('')
    const [link, setLink] = useState('')

    const today = moment().format('YYYYMMDD')
    const time = moment().format('YYYYMMDDHHmm')
    

    //Functionality
    const loadArticle = () => {
        db.collection('Analysis').doc(`${today}-${title}`).set({
            title: title,
            body: body,
            date: today,
            time: time,
            author: author,
            type: 'News',
            url: url
        }).then(res => {alert('Created')}).catch(err => {alert('error, article didnt submit')})
    }
    const loadAffiliateArticle = () => {
        db.collection('News').doc(`${today}-${title}`).set({
            title: title,
            date: today,
            time: time,
            author: author,
            type: 'News',
            link: link
        }).then(res => {alert('Created')}).catch(err => {alert('error, article didnt submit')})
    }

    let inputObj = {
        width: '100%'
    }

    return(
        <div style={{padding: '10%', paddingTop: '5%', paddingBottom: '20%'}} className="EnterArticle">
            <h3>Add News Article</h3><br />
            Title: 
            <br /><input style={inputObj} className="EA-text" id="title" onChange={(e)=>{setTitle(e.target.value)}} />
            <br />Author: 
            <br /><input style={inputObj}  className="EA-text" id="author" onChange={(e)=>{setAuthor(e.target.value)}} />
            <br />URL (no spaces): 
            <br /><input style={inputObj}  className="EA-text" id="url" onChange={(e)=>{setUrl(e.target.value)}} />
            <br />Body (must be in html): 
            <br /><textarea style={inputObj}  className="EA-text" id="body" onChange={(e)=>{setBody(e.target.value)}} />
            <br /><br /><br />
            <button onClick={loadArticle}>submit</button>
            <br />
            <hr />
            <h3>Links </h3>
            Title: 
            <br /><input style={inputObj}  className="EA-text" id="title" onChange={(e)=>{setTitle(e.target.value)}} />
            <br />Author: 
            <br /><input style={inputObj}  className="EA-text" id="author" onChange={(e)=>{setAuthor(e.target.value)}} />
            <br />Link: 
            <br /><input style={inputObj}  className="EA-text" id="link" onChange={(e)=>{setLink(e.target.value)}} />
            <br /><br /><br />
            <button onClick={loadAffiliateArticle}>submit</button>
        </div>
    )
}

export default AddNews