import * as types from "../types";
import {getAllTrades} from '../../routes/trading/trading'

export const getTrades = () => (dispatch) => {
  getAllTrades().then(res => {
    dispatch({
      type: types.ADD_TRADES,
      payload: res
    })
  })
};
