import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Deposit from './Deposit';
import CollectData from './DataCollect'
import PriceMovement from './PriceMovement'
import "./welcome.css"
import Purchase from './Purchase';
import Bar from './Bar'
import {db} from '../../services/firebase'
import moment from 'moment'
import {firebaseAnalytics} from '../../services/firebase'
import {Link} from 'react-router-dom'
import Popup from 'reactjs-popup'
import {getAccountInfo, updateAccountInfo} from '../../routes/user/account'


const WelcomeTour = ( props) => {

    let Logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Funnamed.png?alt=media&token=6a070d2c-624f-4bb7-8acc-d5ad380bc39c'

    const user = props.user
    const history = props.history
    const info = props.info

    const updateUser = (address, birthday, email, first_name, last_name, phone_number, state, username, zip_code) => {
        if(props.token){
            /*let address = props.info ? (props.info.address ? props.info.address : null) : null
            let birthday = props.info ? (props.info.birthday ? props.info.birthday : null) : null
            let email = props.info ? (props.info.info.email ? props.info.email : null) : null
            let first_name = props.info ? (props.info.first_name ? props.info.first_name : null) : null
            let last_name = props.info ? (props.info.last_name ? props.info.last_name : null) : null
            let phone_number = props.info ? (props.info.phone_number ? props.info.phone_number : null) : null
            let state = props.info ? (props.info.state ? props.info.state : null) : null
            let username = props.info ? (props.info.username ? props.info.username : null) : null
            let zip_code = props.info ? (props.info.zip_code ? props.info.zip_code : null) : null*/
            updateAccountInfo(props.token, address, birthday, email, first_name, last_name, phone_number, state, username, zip_code).then(result => {
                let carry 
                if(result.username){
                    carry = {type: 'success', date: result} //good
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.username && result.data.msg.query.username[0] ){
                    carry = {type: 'err', data: result.data.msg.query.username[0]} //don't include a username
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.email && result.data.msg.query.email[0] ){
                    carry = {type: 'err', data: result.data.msg.query.email[0]} //don't include a email
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.birthday && result.data.msg.query.birthday[0] ){
                    carry = {type: 'err', data: result.data.msg.query.birthday[0]} //bad birthday needs to be YYYY-MM-DD
                }
            })
        }
    }

    //Pages
    const [currentPage, setCurrentPage] = useState(1)

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')


    //Next click handler
    const nextClickHandler = async () => {
        if(error){
            setError(false)
            setErrorMsg('')
        }
        if(currentPage === 1){

            if(firstName.length > 0 && firstName !== 'Last' && lastName.length > 0 && lastName !== 'Last'){
                updateUser(null, null, null, firstName, lastName, phone, null, null, null)
                setCurrentPage(2)
            }
            else{
                setError(true)
                setErrorMsg('Fill out the fields, please.')
            }
            
        }
        else if(currentPage === 2) {
        
            setCurrentPage(currentPage+1)
        }else if(currentPage === 3) {
            //send to portfolio
            history.replace('/')

            //window.location.href = window.location.href.toString().substring(0, window.location.href.toString().length - 7) 
        }
    }
    //Previous Click Handler
    const prevClickHandler = () => {
        if(currentPage !== 1){
            setCurrentPage(currentPage-1)
        }
    }
    //Skip click handler
    const skipClickHandler = () => {
        history.replace("/")
    }

    //Data Collect
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const getFirstName = (first) => setFirstName(first)
    const getLastName = (last) => setLastName(last)
    const getPhone = (num) => setPhone(num)
    useEffect(()=>{
        if(info && info.first_name && info.first_name !== 'First'){
            setFirstName(info.first_name)
        }
        if(info && info.last_name && info.last_name !== 'Last'){
            setLastName(info.last_name)
        }
        if(info && info.phone_number){
            setPhone(info.phone_number)
        }
    },[info])

    //Give Welcome Payout

    const skipNext = () => {
        if(currentPage === 3){
            return "Skip"
        }
        else if(currentPage === 4){
            return "Skip"
        }
        else if(currentPage === 3){
            return "Skip"
        }
        else{
            return "Next"
        }
    }

    //Analytics
    useEffect(()=>{
        firebaseAnalytics.logEvent("welcome_visit", {
           //user: user && user.email 
        })
    },[])

    return (
        <>
        <div className="WelcomeTour">
        <div><img style={{width: '90%', paddingLeft: '5%'}} src={Logo}></img></div>
        <hr />
            {/*Welcome and Data*/}
            {currentPage === 1 && <CollectData getFirstName={getFirstName} getLastName={getLastName} getPhone={getPhone} firstName={firstName} lastName={lastName} phone={phone} />}
            {/*Can I Make Money? and Is it legit?*/}
            {currentPage === 2 && <PriceMovement />}
            {/*How it works*/}
            {currentPage === 3 && <Deposit nextClickHandler={nextClickHandler} />}
            {/*Deposit*/}
            {currentPage === 4 && <Purchase />}

            {/*<div className='welcome-txt'>{arr[currentPage - 1].infomation}</div>*/}
            <div className="welcome-btns">
                {error && <div style={{textAlign: 'center', color: 'red'}}>Error: {errorMsg}</div>}
                {currentPage !== 3 && <div><Button variant="secondary" style={{margin: 10, width: "30%", marginBottom: '1%', display: 'inline-block'}} onClick={nextClickHandler}>{skipNext()}</Button></div>}
                {currentPage == 3 && <div><button style={{fontWeight: '200', color: 'gray', backgroundColor: 'white', border: 'none', outline: 'none', textDecoration: 'underline', margin: 10, width: "30%", marginBottom: '1%', display: 'inline-block'}} onClick={nextClickHandler}>{skipNext()}</button></div>}
                {/*currentPage > 1 && <div><Button variant="secondary" style={{margin: 10, backgroundColor: 'white', color: 'gray', marginBottom: 3, display: 'inline-block'}} onClick={prevClickHandler}>Prev</Button></div>*/} 
                {currentPage === 3 && <div><Button variant="secondary" style={{margin: 10, backgroundColor: 'white', color: 'gray', marginBottom: 3, display: 'inline-block'}} onClick={prevClickHandler}>Need More Info?</Button></div>} 
                {/*<div><Button variant="success" disabled={currentPage === 5} style={{margin: 10, width: "30%"}} onClick={skipClickHandler}>Skip</Button></div>*/}
                {/*<div style={{marginTop: '15px', marginBottom: '15px', fontWeight: '200'}}><Link onClick={()=>{history.push('/Trade')}}>Skip Onboarding</Link></div>*/}
                <div style={{marginBottom: '5%'}}>{currentPage === 3 ? '2' : currentPage === 1 ? '1' : '1.5'}&nbsp;of&nbsp;2</div>
            </div>
        </div>
        </>
    )
} 

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user,
    info: state.info.info,
    token: state.token.token
});

export default connect(mapStateToProps)(withRouter(WelcomeTour))
