import React, { useState, useEffect } from 'react'
import './Account.css'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {db} from '../../services/firebase'
import moment from 'moment'
import firebase from 'firebase'
import {getSubscription, createSubscription} from '../../routes/user/subscription'
import GoldYearly from './PayPal/Subscriptions/GoldYearly'
import axios from 'axios'

const SubscriptionTwo = (props) => {

    let logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa'

    const user = props.user

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const [gold, setGold] = useState(props.myuser && props.myuser.fees ? false : true)//user.gold ? user.gold : false)
    const [receipts, setReceipts] = useState([])
    const [subscription, setSubscription] = useState(false)

    //show
    const [showReceipts, setShowReceipts] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [showPurchase, setShowPurchase] = useState(false)
    const [showTrial, setShowTrial] = useState(false)
    const [subCanceled, setSubCanceled] = useState(false)


    //getSubscription
    const pullSubscription = () => {
        if(props.token){
            getSubscription(props.token).then(res => {
                console.log(res)
                if(res.length > 0){
                    res.map(r => {
                        console.log(r)
                        if(r.status === 'ACTIVE'){
                            setSubscription(r)
                            setGold(true)
                        }
                    })
                }
            })
        }
    }
    useEffect(()=>{!subscription && pullSubscription()}, [props.token])

    //create Subscription
    const createSubscription = () => {
        
    }

    //cancel subscription
    const cancelSubscription = async () => {
        const infoOptions = {
            method: 'POST',
            url: `https://api-m.paypal.com/v1/billing/subscriptions/${subscription.subscription_id}/cancel`,
            headers: {
                Authorization: `Bearer Abs1nhbO9RnoxfJdKHOaSEJdU22l836957p94JDViTWBo_AyUCs_I4XUXxTbJsmrRkztBemol2bQkBxO`,
            }
        }
        let info 
        await axios.request(infoOptions).then(function (response) {
            let result = response.data
            info = result
            console.log(response)
            alert('Subscription Cancelled.')
        }).catch(err => {console.log(err);console.log(err.response);info = err.response; alert('Error cancelling subscription. Please contact customer-service@simbull.app to cancel.')})
        
        return info
    }

    //get receipts
    const getReceipts = () => {

    }
    //useEffect(()=>{getReceipts()},[])

    

    return(
        <div className="SubscriptionTwo">

            {/*Title */}
            <div className="subtwo-title">SimBull GOLD</div>
            <div className="subtwo-subtitle">Free Trading, Personalized Daily Report, Team Analyst Reports</div>
            

            {/*Purchase Subscription */}
            {!gold && <div className="subtwo-box">
                <div className="subtwo-box-head">
                    GOLD
                    <span /*style={{textDecoration: 'line-through'}}*/><p className="subtwo-box-price">$4.16 per Month
                        <p style={{color: 'gray', fontSize: '.7rem', textDecoration: 'none'}}>$49.99 Billed Annually</p>
                    </p></span>
                    {/*<p className="subtwo-box-price"><span style={{color: 'green'}}>${(purchasePrice / 12).toFixed(2)} per Month
                        <p style={{color: 'gray', fontSize: '.7rem'}}>${purchasePrice} Billed Annually<br />Limited Time Offer</p>
                    </span></p>*/}
                </div>
                <div className="subtwo-box-img-div">
                    <img className="subtwo-box-img" src='https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Holder%2Ftickets.png?alt=media&token=22c7b60e-1530-40d2-aecf-3fc18bb28c9c' />
                </div>

                {true && <div style={{border: '1px solid black', padding: '3%'}}>
                    <div style={{fontSize: '1.2rem', fontWeight: '600'}}>SimBull GOLD</div>
                    <div style={{fontSize: '1rem', fontWeight: '200'}}>Unlimited Free Trading, Personalize Daily Report, Team Analyst Reports</div>
                    <div style={{fontSize: '1.1rem', fontWeight: '600', textAlign: 'center', padding: '3% '}}>
                        ${(4.16).toFixed(2)}/month
                        <p style={{color: 'gray', fontSize: '.7rem'}}>{/*$49.99*/} Billed Annually</p>
                    </div>                   
                    <GoldYearly />
                </div>}
                {/*false && !showTrial && user&& user.code && freeGoldSubscription.includes(user.code.toLowerCase()) && !user.goldTrial && <div className="subtwo-buy-btn-div"><button onClick={()=>setShowTrial(true)} className="subtwo-buy-btn" style={{backgroundColor: 'rgb(222,222,222)'}}>Free Week of GOLD</button></div>}
                {showTrial && <div style={{border: '1px solid black', padding: '3%'}}>
                    <div style={{fontSize: '1.2rem', fontWeight: '600'}}>SimBull GOLD</div>
                    <div style={{fontSize: '1rem', fontWeight: '200'}}>Unlimited Free Trading, Personalize Daily Report, Team Analyst Reports</div>
                    <div style={{fontSize: '1.1rem', fontWeight: '600', textAlign: 'center', padding: '3% '}}>Free</div>
                    <div className="subtwo-buy-btn-div"><button onClick={createTrialSubscription} className="subtwo-buy-btn" style={{width: '60%'}}>Start Free Week of GOLD</button></div>
                    <div style={{fontSize: '0.8rem'}}>**Will <strong>not</strong> renew automatically</div>
                </div>*/}
                <hr />
                <div className="subtwo-box-body">
                    <div className="subtwo-box-item">
                        1. Free Trading
                        <div className="subtwo-box-item-body">Be Ready to trade at a seconds notice. Skip the 1% trading fee and receive free trading on all your trades. </div>
                    </div>
                    <span style={{color: 'gray', fontSize: '.9rem'}}>Below Coming Soon!</span>
                    <div className="subtwo-box-item">
                        2. Personalized Daily Report
                        <div className="subtwo-box-item-body">Each morning, get a personalized daily report including an update on your account, win payouts, teams changes like price or new analyst reports, and any other thing you're interested in like a specific league's news or index prices.</div>
                    </div>
                    <div className="subtwo-box-item">
                        3. Team Analyst Reports
                        <div className="subtwo-box-item-body">Get in-depth reports and deep-dives for every team by analysts with a proven track record. </div>    
                    </div>
                </div>
            </div>}

            {/*Current Subscription */}
            {gold && <div className="subtwo-box">
                <div className="subtwo-box-head">
                    GOLD
                    <p className="subtwo-box-active">Active Member</p>
                    {subscription && subscription.subscription_id === null && <p className="subtwo-box-cancel">Expires on {subscription.expiration_date.substring(5,7)}/{subscription.expiration_date.substring(8,10)}/{subscription.expiration_date.substring(0,4)}</p>}
                    {subscription && subscription.subscription_id !== null && <p className="subtwo-box-length">Renews on {subscription.expiration_date.substring(5,7)}/{subscription.expiration_date.substring(8,10)}/{subscription.expiration_date.substring(0,4)}</p>}
                </div>
                <div className="subtwo-box-img-div">
                    <img className="subtwo-box-img" src='https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Holder%2Ftickets.png?alt=media&token=22c7b60e-1530-40d2-aecf-3fc18bb28c9c' />
                </div>
                <hr />
                <div className="subtwo-box-body">
                    <div className="subtwo-box-item">
                        1. Free Trading
                        <div className="subtwo-box-item-body">Be Ready to trade at a seconds notice. Skip the 1% trading fee and receive free trading on all your trades. </div>
                    </div>
                    <span style={{color: 'gray', fontSize: '.9rem'}}>Below Coming Soon!</span>
                    <div className="subtwo-box-item">
                        2. Personalized Daily Report
                        <div className="subtwo-box-item-body">Each morning, get a personalized daily report including an update on your account, win payouts, teams changes like price or new analyst reports, and any other thing you're interested in like a specific league news or index prices.</div>
                    </div>
                    <div className="subtwo-box-item">
                        3. Team Analyst Reports
                        <div className="subtwo-box-item-body">Get in-depth reports and deep-dives for every team by analysts with a proven track record. </div>    
                    </div>
                </div>
            </div>}

            {/* Past Receipts 
            {gold && <>
                <div className="subtwo-section">Receipts</div>
                <div className="subtwo-box">
                    {!showReceipts && <div className="subtwo-receipt-view"><button onClick={()=>setShowReceipts(true)} className="subtwo-receipt-view-btn">View All</button></div>}
                    {showReceipts && <div className="subtwo-receipt-view"><button onClick={()=>setShowReceipts(false)} className="subtwo-receipt-view-btn">Hide</button></div>}
                    {showReceipts && <table className="subtwo-receipt-table">
                        <tr className="subtwo-receipt-table-headers">
                            <th>Type<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Time<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Date<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Cost<hr className="subtwo-receipt-head-hr" /></th>
                        </tr>
                        
                        {receipts && receipts.length>0 ? receipts.map(r => {
                            return(
                                <tr>
                                    <td>GOLD<hr className="subtwo-receipt-hr" /></td>
                                    <td>{r.type}<hr className="subtwo-receipt-hr" /></td>
                                    <td>{r.date}<hr className="subtwo-receipt-hr" /></td>
                                    <td>${r.price}<hr className="subtwo-receipt-hr" /></td>
                                </tr>
                            )
                        }): <div>No Receipts</div>}
                    </table>}
                </div>
            </>}
            */}

            {/*Cancel Subscription */}
            {gold && subscription.subscription_id && subscription.subscription_id !== null && <>
                <div className="subtwo-box">
                    {!showCancel && <div className="subtwo-receipt-view"><button onClick={()=>setShowCancel(true)} className="subtwo-receipt-view-btn">Cancel GOLD</button></div>}
                    {showCancel && <div className="subtwo-receipt-view"><button onClick={()=>setShowCancel(false)} className="subtwo-receipt-view-btn">Hide</button></div>}
                    {showCancel && <div className="subtwo-receipt-table">
                        <div style={{fontWeight: '600', fontSize: '1.25rem'}}>Are you sure?</div>
                        <div className="subtwo-cancel-div">
                            You will lose: 
                            <div className="subtwo-cancel-body">1. Free Trading
                            <br />2. Personalized Daily Reports
                            <br />3. Team Analyst Reports</div>
                        </div>
                        <div><button onClick={cancelSubscription} className="subtwo-cancel-btn">Cancel GOLD</button></div>
                    </div>}
                </div>
            </>}
            

        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(withRouter(SubscriptionTwo))