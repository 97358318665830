import React, {useState, useEffect} from 'react'
import './functions.css'
import {Link, withRouter, useHistory} from 'react-router-dom'
import {firebaseAnalytics } from '../../services/firebase'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import {getOffersByTeam, makeTrade} from '../../routes/trading/trading'
import Loader from '../adHoc/Loader'
import Deposit from './Deposit'
import ReactPixel from 'react-facebook-pixel'
import amplitude from 'amplitude-js'

const BuyShare = (props) => {
    //props
    const t = props.t
    const user = props.user
    const closeBuy = props.closeBuy
    const welcome = props.welcome ? props.welcome : false
    const totals = props.totals ? props.totals : false

    const history = useHistory()

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    //states
    const [calc, setCalc] = useState('')
    const [buyShares, setBuyShares] = useState(0)
    const [calcUSD, setCalcUSD] = useState('')
    const [buyUSD, setBuyUSD] = useState(0)
    const [receipt, setReceipt] = useState(false)
    const [avgPrice, setAvgPrice] = useState()
    const [showLoad, setShowLoad] = useState(true)
    const [tradeLoad, setTradeLoad] = useState(false)
    const [estimatedCost, setEstimatedCost] = useState(0)
    const [url, setUrl] = useState(t && t.team && t.team.name ? `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t && t.team && t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`: '')
    const [openBuy, setOpenBuy] = useState(false)
    const [fee, setFee] = useState(props.myuser ? props.myuser.fees : true)
    const [feeAmt, setFeeAmt] = useState(props.myuser ? props.myuser.fees_amount : 0.01)
    //errors
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [unit, setUnit] = useState('usd')

    //fee calc
    useEffect(()=>{
        if(props.myuser && fee !== props.myuser.fees){
            setFee(props.myuser.fees)
            setFeeAmt(props.myuser.fees_amount)
        }
    },[props.myuser])

    //getImageURL
    useEffect(()=>{
        if(props.t && !url){
            setUrl(`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t && t.team && t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`)
        }
    }, [props.t])

    const [asks, setAsks] = useState([])
    //offers
    useEffect(()=>{
        if(asks.length === 0 && props.t){
            getOffersByTeam(t.team.id, "ASK", 50).then(res => {
                setShowLoad(false)
                let arr = res
                arr.sort(function (a, b){
                    return a.price - b.price
                })
                setAsks(arr)
            }).catch(err => console.log(err.response.data))
        }
    },[props.t])


    //Functionality
    //calc add digit
    const addDigit = (x) => {
        if(unit === 'shares'){
            let hold = calc
            if(x === '.' && hold[hold.length - 1] === '.'){
                return false 
            }
            else if(x === '.' && hold === ''){
                let thisNum = '0.'
                setCalc(thisNum)
            }
            else if(x === '0' && hold.length === 1 && hold === '0'){
                return false
            }
            else{
                let thisNum = hold.concat(x)
                setBuyShares(parseFloat(thisNum))
                setCalc(thisNum)
            }
        }
        else if(unit ==='usd'){
            let hold = calcUSD
            if(x === '.' && hold[hold.length - 1] === '.'){
                return false 
            }
            else if(x === '.' && hold === ''){
                let thisNum = '0.'
                setCalcUSD(thisNum)
            }
            else if(x === '0' && hold.length === 1 && hold === '0'){
                return false
            }
            else{
                let thisNum = hold.concat(x)
                setBuyUSD(parseFloat(thisNum))
                setCalcUSD(thisNum)
            }
        }
        
    }

    const deleteDigit = () => {
        if(unit === 'shares'){
            const editedText = calc.slice(0, -1)
            setBuyShares(parseFloat(editedText))
            setCalc(editedText)
        }
        else if(unit === 'usd'){
            const editedText = calc.slice(0, -1)
            setBuyUSD(parseFloat(editedText))
            setCalcUSD(editedText)
        }
        
    }

    const submitBuy = () => {
        setOpenBuy(true)
    }

    const [showDeposit, setShowDeposit] = useState(false)

    const confirmBuy = () => {
        if(totals && !totals.cash_balance){
            setShowDeposit(true)
            setOpenBuy(false)
        }
        else if(props.t && props.token){
            setTradeLoad(true)
            makeTrade(props.token, "BUY", buyShares, t.team.id).then(res => {
                setTradeLoad(false)
                if(res.response && res.response.status === 400){
                    if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.amount && res.response.data.msg.query.amount[0]){
                        setError(true)
                        setErrorMsg(res.response.data.msg.query.amount[0])
                    }
                    else if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.price && res.response.data.msg.query.price[0]){
                        setError(true)
                        setErrorMsg(res.response.data.msg.query.price[0])
                    }
                    else {
                        setError(true)
                        setError(`Error: Sorry, please try again.`)
                        if(res.response.data && res.response.data.msg && typeof res.response.data.msg === 'string'){
                            setErrorMsg(res.response.data.msg)
                        }
                    }
                    setOpenBuy(false)
                    setBuyShares(0)
                    setCalc('0')
                }
                else if(res.response && res.response.status === 401){
                    setError(true)
                    setErrorMsg("Sorry, please refresh and try again")
                    setOpenBuy(false)
                    setBuyShares(0)
                    setCalc('0')
                }
                else if(res.status === 401){
                    setError(true)
                    setErrorMsg("Sorry, please refresh and try again")
                    setOpenBuy(false)
                    setBuyShares(0)
                    setCalc('0')
                }
                if(res.status && res.status === 200){
                    let receiptAmt = 0
                    let reciptCost = 0
                    res.data.map(r => {
                        if(r.type !== "FEE"){
                            receiptAmt += r.amount
                            
                        }
                        reciptCost += ((r.amount * r.price))
                    })
                    history.replace({pathname: `/Receipts`, state: { shares: receiptAmt, totalCost: parseFloat(reciptCost).toFixed(2), team: t.team.name, type: "Purchased", link: "Trade", welcome: false }})
                }
                else if(res.status && res.status === 400){
                    setError(true)
                    setErrorMsg(res.data.msg)
                    setOpenBuy(false)
                    setBuyShares(0)
                    setCalc('0')
                }
                else if(res.status && (res.status === 500 || res.status === 504)){
                    setError(true)
                    setErrorMsg('Service error. Please try again.')
                    setOpenBuy(false)
                    setBuyShares(0)
                    setCalc('0')
                }
                
            }).catch(err => {
                setTradeLoad(false)
                setErrorMsg(err && err.response && err.response.data ? err.response.data : err.response.msg ? err.response.msg : '')
                setError(true)
                setOpenBuy(false)
            })
        }
        else return alert('Error. Refresh and try again.')
    }
    

    useEffect(()=>{
        let buyShares1 = parseFloat(buyShares)
        if(buyShares1 && buyShares1 !== NaN){
            let cost = 0
            let shares = 0
            asks.map(a => {
                if(shares < buyShares1){
                    if(a.remaining_amount < (buyShares1 - shares)){
                        cost += ((a.price * a.remaining_amount) * (fee ? (feeAmt + 1) : 1))
                        shares += a.remaining_amount
                    }else{
                        cost += (((buyShares1 - shares) * a.price) * (fee ? (feeAmt + 1) : 1) )
                        shares += (buyShares1 - shares)
                    }
                }
            })
            setEstimatedCost(cost)
        }
    }, [buyShares])

    useEffect(()=>{
        if(openBuy && unit === 'usd'){
            getOffersByTeam(t.team.id, "ASK").then(res => {
                setShowLoad(false)
                let arr = res
                arr.sort(function (a, b){
                    return a.price - b.price
                })
                let cost = 0
                let shares = 0
                arr.map(a => {
                    if(cost < buyUSD){
                        if((a.remaining_amount * a.price * (fee ? (feeAmt + 1) : 1)) < (buyUSD - cost)){
                            cost += ((a.price * a.remaining_amount)  * (fee ? (feeAmt + 1) : 1))
                            shares += a.remaining_amount
                        }else{
                            let diff = buyUSD - cost
                            cost += diff
                            shares += ((diff * (fee ? (100 / (100+100 *feeAmt)) : 1)  ) / a.price)
                            setBuyShares(shares)
                            setCalc(shares.toString())
                            setEstimatedCost(cost)
                        }
                    }
                })
            }).catch(err => console.log(err.response.data))
        }
    },[openBuy])

    const buyMax = () => {
        setBuyUSD(props.totals ? parseFloat((props.totals.cash_balance - 0.01).toFixed(2)) : 0)
        setCalcUSD(props.totals ? (props.totals.cash_balance - 0.01).toFixed(2).toString() : '0')
    }

    //letting you use the keyboard
    const handleKeyDown = (event) => {
        const keys = ['1','2','3','4','5','6','7','8','9','0','.']

        if(keys.includes(event.key)){
            addDigit(event.key !== '.' ? parseInt(event.key) : event.key)
        }
        else if(event.code === 'Backspace'){
            deleteDigit()
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
    
        // cleanup this component
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    });

    const close = () => {
        setOpenBuy(false)
        setCalc('')
        setCalcUSD('')
        setBuyShares(0)
        setBuyUSD(0)
    }

    const switchView = () => {
        if(unit === 'usd'){
            setUnit('shares')
        }
        else if(unit === 'shares'){
            setUnit('usd')
        }
        setCalc('')
        setCalcUSD('')
        setBuyShares(0)
        setBuyUSD(0)
    }

    return(
        <div id="BuyShareDiv" className="TradePopup" style={{background: 'black'}}>
            {showDeposit && <Deposit />}
            {showLoad && <Loader />}
            <div className="SellShare">
                <Link onClick={closeBuy}><i className="fa fa-times-circle"></i></Link>
                <div className="ss-title">Buy</div>
                <div className='ss-team'><img className="ss-img"  src={url} alt='team' /><span className="tc-name-sim">Sim</span>{t && t.team && t.team.name}</div>
                <div className="h-bidask">
                    <div className="h-bid">
                        Bid
                        <br />
                        <div className="h-bid-p green">{t && t.bidding_price.toFixed(2)}</div>
                    </div>
                    <div className="h-price">
                        ${t && t.asking_price.toFixed(2)}
                    </div>
                    <div className="h-ask">
                        Ask
                        <br />
                        <div className="h-ask-p red">{t && t.asking_price.toFixed(2)}</div>
                    </div>   
                </div>
                <div className='ss-cash'>Cash Available: ${props.totals && props.totals.cash_balance.toFixed(2)}</div>
                <div className='ss-cash'>Estimated Cost: ${estimatedCost.toFixed(2)}</div>
                {unit === 'shares' && <>
                    
                    <div className="ss-shareAmt2 underline" style={{marginLeft: '20%'}}>{calc ? calc : '0'}</div><div style={{width: '27%', display: 'inline-block', paddingLeft: '10px', textDecoration: 'none', fontSize: '1rem'}}>shares</div>
                    <div style={{textAlign: 'left', width: '20%', display: 'inline-block'}}><button onClick={switchView} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'white'}}>USD</button></div>
                </>}
                {unit === 'usd' && <>
                <div style={{textAlign: 'right', width: '20%', display: 'inline-block',}}><button onClick={ () => {buyMax(); amplitude.getInstance().logEvent("buy_max")}} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'black'}}>Max</button></div>
                    <div className="ss-shareAmt2 underline" style={{width: '60%', textAlign: 'center', marginLeft: '0%'}}>${calcUSD ? calcUSD : '0'}</div>
                    <div style={{textAlign: 'left', width: '20%', display: 'inline-block'}}><button onClick={() => {switchView(); amplitude.getInstance().logEvent("buy_shares")}} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'black'}}>shares</button></div>
                </>}
                <div className="ss-btn-div">
                    {error && <div style={{textAlign: 'center', color: 'red'}}>Error: {errorMsg}</div>}
                    <div className="center center-function"><button className="ss-submit" onClick={submitBuy} style={{backgroundColor: '#c4b454', borderRadius: '2rem'}}>Buy</button></div>
                    {[1,2,3,4,5,6,7,8,9].map(item => 
                        <button key={item} className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit(item.toString())}}>{item}</button>
                    )}
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit(".")}}>.</button>
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit("0")}}>0</button>
                    <button className="ss-btn-2" style={{backgroundColor: 'rgb(30,30,30)', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{deleteDigit()}}><i className="fa fa-arrow-left"></i></button>
                </div>
            </div> 

            <Popup open={openBuy} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    {tradeLoad && <Loader />}
                    <div style={{textAlign: 'center', fontSize: '2.5em'}}>Confirm Purchase</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Team</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>Sim{t&& t.team.name}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Shares</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>{buyShares && buyShares.toFixed(2)} Share{buyShares ===1 ? '' : 's'}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Avg Price Per Share</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${((estimatedCost * (fee ? (100 / (100+100 *feeAmt)) : 1))/parseFloat(buyShares)).toFixed(2)}</div>
                        {fee && <>
                            <div onClick={() => amplitude.getInstance().logEvent("team_get_gold")} style={{color: 'gray', fontWeight: '300'}}>Trade Fee ({(feeAmt * 100).toFixed(2)}%) <br /> <Link to="/Subscription" style={{fontWeight: '200', fontSize: '.7em'}}>Get Free Trading</Link></div>
                            <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(estimatedCost*(1/101)).toFixed(2)}</div>
                        </>}
                        <div style={{color: 'gray', fontWeight: '300'}}>Total</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(estimatedCost).toFixed(2)}</div>
                        {!tradeLoad && <div style={{textAlign: 'center'}}>
                            <button onClick={() => {confirmBuy(); amplitude.getInstance().logEvent(
                                "confirm_buy", {
                                    "shares": buyShares,
                                    "team": t.team.name,
                                    "price": ((estimatedCost * (fee ? (100 / (100+100 *feeAmt)) : 1))/parseFloat(buyShares)).toFixed(2),
                                    "total": (estimatedCost).toFixed(2),
                                    "fee": fee
                                }
                            )}} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Confirm</button><br />
                            <button onClick={close} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>}
                    </div>
                    <div style={{textAlign: 'center', fontSize: '.5em', fontWeight: '300', width: '80%', marginLeft: '10%'}}>**Prices may change due to market fluctuations. Trade will execute at the best available price.</div>
                </div>
            </Popup>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    totals: state.totals.totals,
    token: state.token.token,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(withRouter(BuyShare))