import React, {useState, useEffect} from 'react'
import '../Analysis.css'
import {db, firebaseAnalytics} from '../../../services/firebase'
import ReactHtmlParser from 'react-html-parser'
import Article from '../Article'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

const News = (props) => {
    //props
    const user = props.user

    //states
    const [content, setContent] = useState([])
    const [moreDate, setMoreDate] = useState(moment().subtract(21, 'd').format('YYYYMMDD'))
    const [moreNum, setMoreNum] = useState(14)
    const [contentTemp, setContentTemp] = useState([])
    const [limit, setLimit] = useState(10)   

    //Functionality
    const getContent = () => {
        const content1 = []
        db.collection('News').orderBy('date', 'desc').where('date', '>', moreDate).get().then(snapshot => {
            snapshot.forEach(doc => {
                const data = doc.data()
                content1.push(data)
            })
            content1.sort(function(a, b) {
                return b.time - a.time;
            });
            setContent(content1.slice(0, content1.length < 10 ? content1.length : limit))
            setContentTemp(content1)
            setMoreDate(moment().subtract(14).format('YYYYMMDD'))
        }).catch(err => { console.log(err) })
    }
    useEffect(() => {
        getContent()
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const getMoreContent = () => {
        const content2 = content
        db.collection('News').orderBy('date', 'desc').where('date', '>', moreDate).get().then(snapshot => {
            snapshot.forEach(doc => {
                const data = doc.data()
                content2.push(data)
            })
            content2.sort(function(a, b) {
                return b.date - a.date;
            });
            setContent(content2)
            setMoreDate(moment().subtract(moreNum + 7).format('YYYYMMDD'))
            setMoreNum(moreNum + 7)
        }).catch(err => { console.log(err) })
    }


    //Analytics
    useEffect(()=>{firebaseAnalytics.logEvent("news_visit")},[])

    const onSeeMore = () => {
        setContent(contentTemp.slice(0, contentTemp.length - limit < 10 ? contentTemp.length : limit + 10))
        setLimit(limit + 10)
    }

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])


    return(
        <div className="Analysis DesktopContainer">
            <Helmet>
                <title>News</title>
            </Helmet>
            {content.length > 0 && content.map(c => {
                return(
                    <div key={c.title} className="anal-card">
                        {c.url && <Link to={{
                                    pathname: `/content/${c.url}`,
                                    state: {
                                        article: c,
                                        user: user
                                    }
                                }}>
                                    <hr />
                                    <div className="anal-title blk-text">{c.title}</div>
                                    <div className="anal-sub blk-text">{c.author} - {`${c.date.substring(4, 6)}/${c.date.substring(6, 8)}/${c.date.substring(0, 4)}`}</div>
                                </Link>
                        }
                        {!c.url && <a href={c.link} target="_blank" className="blk-text">
                                    <hr />
                                    <div className="anal-title blk-text">{c.title}</div>
                                    <div className="anal-sub blk-text">{c.author} - {`${c.date.substring(4, 6)}/${c.date.substring(6, 8)}/${c.date.substring(0, 4)}`}</div>
                                </a>
                        }
                    </div>
                )
            })}
            {content.length < contentTemp.length && 
            <div style={{textAlign: "center"}}>
                <button className={"tb-sort-div-btn"} onClick={onSeeMore}>See More</button>
            </div>}            
            <hr />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps)(News)