import * as types from "../types";
import {getTeamsValue} from '../../routes/teams/teams'

export const getTeamsValues = () => (dispatch) => {

  getTeamsValue().then(res => {
    dispatch({
      type: types.GET_TEAMS_VALUE,
      payload: res
    })
  })
};
