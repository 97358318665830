import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import {getGraphInfo} from './Functions'
import TeamGraph from '../../Holdings/TeamGraph'
import {HorizontalBar} from 'react-chartjs-2'
import ProgressBar from "@ramonak/react-progress-bar";

const BarTeam = (props) => {
    const user = props.user

    /*const data = {
        labels: ['', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            },
        ],
        };

        const options = {
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
            position: 'right',
            },
            title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart',
            },
        },
        };*/
    
    //Chart
    const options = {
        legend: {
            display: false,
            /*position: "right"*/
        },
        elements: {
            arc: {
            borderWidth: 0
            }
        },
        scales: {
            y: {
                label: false
            }
        }

    };
    const data = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ['Buy', 'Hold', 'Sell'],
        datasets: [
            {
                data: [60,20,20],
                backgroundColor:['rgb(0,150,0, .5)', 'rgb(150,150,150, .5)', 'rgb(200,0,0, .5)'],
                hoverBackgroundColor: ['lightgreen', 'lightgray', 'lightred']
            }
        ],
        
        
    };

    return(
        <>
            <div style={{marginBottom: '5px'}}><ProgressBar labelColor="black" labelAlignment={'left'} completed={60} maxCompleted={100} customLabel="Buy" bgColor={'rgb(0,150,0, .30)'} baseBgColor={'rgb(245,245,245)'} borderRadius={'.3em'} /></div>
            <div style={{marginBottom: '5px'}}><ProgressBar labelColor="black" labelAlignment={'left'} completed={10} maxCompleted={100} customLabel="Hold" bgColor={'rgb(150,150,150, .30)'} baseBgColor={'rgb(245,245,245)'} borderRadius={'.3em'} /></div>
            <div style={{marginBottom: '5px'}}><ProgressBar labelColor="black" labelAlignment={'left'} completed={30} maxCompleted={100} customLabel="Sell" bgColor={'rgb(200,0,0, .30)'} baseBgColor={'rgb(245,245,245)'} borderRadius={'.3em'} /></div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(BarTeam)