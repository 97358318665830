import React, {useState, useEffect} from 'react'

const BracketHeadings = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        {!isMobile && <div style={{padding: isMobile ? '10px': '30px', paddingTop: '5px', paddingBottom: '0px'}}>
            <div style={{display: 'inline-block',width: '50%', textAlign: 'center', fontSize: '.8em', fontWeight: '300', color: 'gray'}}>
                <div style={{display: 'inline-block'}} className="firstRound">First Round<span style={{display: 'block',fontSize: '.8em'}}>March 17-18</span></div>
                <div style={{display: 'inline-block'}} className="round">Second Round<span style={{display: 'block',fontSize: '.8em'}}>March 19-20</span></div>
                <div style={{display: 'inline-block'}} className="round">Sweet Sixteen<span style={{display: 'block',fontSize: '.8em'}}>March 24-25</span></div>
                <div style={{display: 'inline-block'}} className="round">Elite Eight<span style={{display: 'block',fontSize: '.8em'}}>March 26-27</span></div>
                <div style={{display: 'inline-block'}} className="round"></div>
            </div>
            <div style={{display: 'inline-block',width: '50%', textAlign: 'center', fontSize: '.8em', fontWeight: '300', color: 'gray'}}>
                <div style={{display: 'inline-block'}} className="round"></div>
                <div style={{display: 'inline-block'}} className="round">Elite Eight<span style={{display: 'block',fontSize: '.8em'}}>March 26-27</span></div>
                <div style={{display: 'inline-block'}} className="round">Sweet Sixteen<span style={{display: 'block',fontSize: '.8em'}}>March 24-25</span></div>
                <div style={{display: 'inline-block'}} className="round">Second Round<span style={{display: 'block',fontSize: '.8em'}}>March 19-20</span></div>
                <div style={{display: 'inline-block'}} className="firstRound">First Round<span style={{display: 'block',fontSize: '.8em'}}>March 17-18</span></div>
            </div>
        </div>}
        {isMobile && <div style={{padding: isMobile ? '10px': '30px', paddingTop: '5px', paddingBottom: '0px'}}>
            <div style={{display: 'inline-block',width: '100%', textAlign: 'center', fontSize: '.7em', fontWeight: '300', color: 'gray'}}>
                <div style={{display: 'inline-block', width: '25%'}} >First Round<span style={{display: 'block',fontSize: '.8em'}}>March 17-18</span></div>
                <div style={{display: 'inline-block', width: '25%'}} >Second Round<span style={{display: 'block',fontSize: '.8em'}}>March 19-20</span></div>
                <div style={{display: 'inline-block', width: '25%'}} >Sweet Sixteen<span style={{display: 'block',fontSize: '.8em'}}>March 24-25</span></div>
                <div style={{display: 'inline-block', width: '25%'}} >Elite Eight<span style={{display: 'block',fontSize: '.8em'}}>March 26-27</span></div>
            </div>
        </div>}
        </>
    )
}

export default BracketHeadings