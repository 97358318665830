import React, {useState, useEffect} from 'react'
import {sendBracketDetails, sendTeamDetails, isPick} from './util'
import moment from 'moment'
import {firebaseAnalytics} from '../../services/firebase'

const Scores = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(()=>{
      firebaseAnalytics.logEvent('SimBull16_Scores_visit')
    },[])

    const [games, setGames] = useState(props.games)
    const teams = props.teams
    const currentPicks = props.currentPicks

    const [currentRound, setCurrentRound] = useState('First Round')

    let btnObj = {
        width: isMobile ? '30.3%' : '13.66%',
        marginLeft: isMobile ? '1.5%' : '1.5%',
        marginRight: isMobile ? '1.5%' : '1.5%',
        marginBottom: isMobile ? '5px' : '0px',
        backgroundColor: 'rgb(240,240,240)',
        border: 'none',
        outline: 'none',
        color: 'gray',
        fontSize: isMobile ? '.7rem' : '1rem'
    }

    useEffect(()=>{
        let date = moment().format('YYYYMMDDHH')
        console.log(date)
        if(date < '2022031905'){
            setCurrentRound('First Round')
        }
        else if(date < '2022032105'){
            setCurrentRound('Second Round')
        }
        else if(date < '2022032605'){
            setCurrentRound('Sweet Sixteen')
        }
        else if(date < '2022032805'){
            setCurrentRound('Elite Eight')
        }
        else if(date < '2022040305'){
            setCurrentRound('Final Four')
        }
        else{
            setCurrentRound('Championship')
        }
    },[])

    games.sort(function(a, b) {
        let dateA = moment(`${a.timestamp}.000Z`)
        let dateB = moment(`${b.timestamp}.000Z`)
        return dateA - dateB
    })
    
    return(
        <div style={{backgroundColor: 'rgb(240,240,240)', minHeight: '100vh', paddingBottom: '10vh', paddingTop: '10px',}}>
            <hr style={{margin: '0'}} />
            <div style={{textAlign: 'center', width: isMobile ? '99%' : '90%', marginLeft: isMobile ? '.5%' : '5%', }}>
                <button style={btnObj} onClick={()=>setCurrentRound('First Round')}>
                    {currentRound === 'First Round' ? <strong style={{color: 'black'}}>First Round</strong> : 'First Round'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>March 17th-18th</span></>}
                </button>
                <button style={btnObj} onClick={()=>setCurrentRound('Second Round')}>
                    {currentRound === 'Second Round' ? <strong style={{color: 'black'}}>Second Round</strong> : 'Second Round'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>March 19th-20th</span></>}
                </button>
                <button style={btnObj} onClick={()=>setCurrentRound('Sweet Sixteen')}>
                    {currentRound === 'Sweet Sixteen' ? <strong style={{color: 'black'}}>Sweet Sixteen</strong> : 'Sweet Sixteen'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>March 24th-25th</span></>}
                </button>
                <button style={btnObj} onClick={()=>setCurrentRound('Elite Eight')}>
                    {currentRound === 'Elite Eight' ? <strong style={{color: 'black'}}>Elite Eight</strong> : 'Elite Eight'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>March 26th-27th</span></>}
                </button>
                <button style={btnObj} onClick={()=>setCurrentRound('Final Four')}>
                    {currentRound === 'Final Four' ? <strong style={{color: 'black'}}>Final Four</strong> : 'Final Four'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>April 2nd</span></>}
                </button>
                <button style={btnObj} onClick={()=>setCurrentRound('Championship')}>
                    {currentRound === 'Championship' ? <strong style={{color: 'black'}}>Championship</strong> : 'Championship'}
                    {!isMobile && <><br /><span style={{fontSize: '.8em'}}>April 4th</span></>}
                </button>
            </div>
            <hr style={{margin: '0', marginBottom: '10px'}} />

            <div style={{paddingLeft: isMobile ? '5px' : '10%', paddingRight: isMobile ? '5px' : '10%',}}>
            {games && games.map(g => {

                let game_details = sendBracketDetails(g.id)
                let game_round = game_details ? (game_details[0] ? game_details[0] : '') : ''
                let game_region = game_details ? (game_details[1] ? game_details[1] : '') : ''

                let date = (g.timestamp && g.timestamp !== null) ? moment(`${g.timestamp}.000Z`).format('ddd HH:mm') : ''

                if(g.home_team_id && g.away_team_id && teams && game_round === currentRound){
                    let home_team = sendTeamDetails(teams, g.home_team_id)
                    let away_team = sendTeamDetails(teams, g.away_team_id)
                    
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '48%' : '23%', backgroundColor: 'white', marginLeft: '1%', marginRight: '1%', borderRadius: '.3em', boxShadow: '#c5b358 0px 1px 2px', marginBottom: '10px'}}>
                            <div style={{width: '100%', textAlign: 'left', paddingLeft: '10px', fontSize: '.8em', color: 'gray', fontWeight: '200'}}>
                                <div style={{display: 'inline-block', width: '100%', textAlign: 'right', paddingRight: '5%'}}>{g.status === 'FINAL' ? 'Final' : (g.home_score === null && g.away_score === null) ? (g.timestamp ? date : '' ): g.period ? `${g.period}H` : '' } </div>
                            </div>
                            
                            <table style={{width: '100%'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{home_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{home_team.name}{isPick(g.home_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score < g.away_score ? '🔴': '🟢'} </span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%'}}>{g.home_score}</td>
                                </tr>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{away_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{away_team.name}{isPick(g.away_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score > g.away_score ? '🔴': '🟢'}</span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%'}}>{g.away_score}</td>
                                </tr>
                                
                            </table>
                            
                            <div style={{width: '100%', textAlign: 'center', fontSize: '.7em', color: 'gray', fontWeight: '200', borderTop: '1px solid rgb(240,240,240)'}}>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_round}</div>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_region} Region</div>
                            </div>
                        </div>
                    )
                }
                
            })}
            </div>
        
        </div>
    )
}

export default Scores 