import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics, auth} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import './playoffs.css'
import logo from '../assets/Logo1.png'
import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';
import {getSpins, runSpin} from '../../routes/spins/spins'
import { OneK } from '@mui/icons-material'

const Spinner = (props) => {

  let one = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fone.png?alt=media&token=e07fd6e2-f6fa-48e7-82f9-c7e630fab3ff'
  let point_one = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fpoint_one.png?alt=media&token=61cb9a58-717d-4ca0-bc8f-55bc1408970a'
  let point_two = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fpoint_two.png?alt=media&token=08bda98c-1910-422b-8567-3fe925e4c405'
  let point_three = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fpoint_three.png?alt=media&token=0013c7b9-e54e-43ef-9cff-856582a4ce42'
  let point_four = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fpoint_four.png?alt=media&token=d2b211d3-01c8-4bf7-b053-1caa056b78fd'
  let point_five = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2Fpoint_five.png?alt=media&token=35adb2a9-e831-4bf9-8acf-7c2822eff004'

  const history = useHistory()

  const goods = [
      {
        id: '7d24b681-82d9-4fc0-b034-c82f9db11a59',
        image: point_five,
        text: '.05 Share',
      },
      {
        id: '9da9a287-952f-41bd-8c7a-b488938d7c7a',
        image: point_one,
        text: '.01 Share',
      },
      {
        id: '04106f3f-f99f-47e4-a62e-3c81fc8cf794',
        image: one,
        text: '1 Share',
      },
      {
        id: '23c551bf-8425-4ffd-b7c2-77c87844f89d',
        image: point_one,
        text: '.01 Share',
      },
      {
        id: 'e4060930-538f-4bf7-ab8e-8d2aa05fba43',
        image: point_four,
        text: '0.04 Share',
      },
      {
        id: '9da9a287-952f-41bd-8c7a-b488938d7c7a',
        image: point_one,
        text: '.01 Share',
      },
      {
        id: '04103f3f-f99f-47e4-a62e-3c81fc8cf794',
        image: point_three,
        text: '.03 Share',
      },
      {
        id: '23c351bf-8425-4ffd-b7c2-77c87844f89d',
        image: point_one,
        text: '.01 Share',
      },
      {
        id: 'e4060330-538f-4bf7-ab8e-8d2aa05fba43',
        image: point_two,
        text: '0.02 Share',
      },
      {
        id: '9da9a387-952f-41bd-8c7a-b488938d7c7a',
        image: point_one,
        text: '.01 Share',
      },
      
    ];
    
  const prizes = [
      ...goods,
      ...goods,
      ...goods,
    ];
  
    const [done, setDone] = useState()
    const [spins, setSpins] = useState([])
    const [start, setStart] = useState(false)
    const [spin, setSpin] = useState({})
    const [winner, setWinner] = useState(false)
    const [team, setTeam] = useState('')
    const [amount, setAmount] = useState('')

    useEffect(()=>{
      if(props.token){
        getSpins(props.token).then(res => {setSpins(res);console.log(res)})
      }
    },[props.token])

    const handleStart = () => {
      runSpin(props.token, spins[0].id).then(res => {
        setStart(true)
        let s = res
        /*let s = {
          amount: 1,
          asset_id: 34
        }*/
        setSpin(s)
        props.teamsValue.map(t => {
          if(t.team.id === s.asset_id){
            setTeam(t.team.name)
          }
        })
        if(s.amount === 1){
          setWinner(22)
        }
        else if(s.amount === .01){
          setWinner(21)
        }
        else if(s.amount === .02){
          setWinner(28)
        }
        else if(s.amount === .03){
          setWinner(26)
        }
        else if(s.amount === .04){
          setWinner(24)
        }
        else if(s.amount === .05){
          setWinner(20)
        }
      })
    }

  const toPortfolio = () => {
    return history.replace({pathname:`/Portfolio`, state: {refresh: true}})
  }

  const finishSpin = () => {
    /*while(!team || !winner){
      console.log(team)
      console.log(winner)
    }*/
    if(team && winner/* && spin*/){
      setDone(true)
    }
  }
    
  return(
      <>
      <div className="Spinner" style={props.spins ? {width: '100%', marginLeft: '0%'} : {}}>

          <div style={{textAlign: 'center', fontSize: '1.2em', fontWeight:'300', marginBottom: '15px'}}>You have <strong style={{textDecoration: 'underline'}}>{start ? spins.length -1 : spins.length}</strong> spin{spins.length !== 1 ? 's' : ''}. <br />
          <span style={{fontWeight: '200', fontSize: '.8em', color: 'gray'}}>Pick games and tag us on socials to earn spins.</span></div>

          <div className="right-card" style={{textAlign: 'center'}}>
              <RoulettePro prizes={prizes} prizeIndex={winner} start={start} onPrizeDefined={finishSpin}/>
              {!start && props.teamsValue && props.teamsValue.length > 0 && spins.length > 0 && <button className="spin-btn" onClick={handleStart}>SPIN</button>}
              {start && !done && <div style={{textAlign: 'center', fontSize: '1.5em'}} >Spinning ...</div>}
              {start && done && <div style={{textAlign: 'center', fontSize: '1.5em'}}>You earned {spin && spin.amount ? spin.amount : '-'} Share{spin && spin.amount !== 1 ? 's' : ''}<br /><strong>Tap the card to reveal your team.</strong></div>}
          </div>
          <div className="right-card">
              <div className="container">
                  <div className="innercontainer">
                      <div className="front face">
                          <span className="card-title-spin">SimBull</span><br /><br />
                          <img className="simbull" src={logo} /><br /><br />
                          <span className="card-team-spin">Team Card</span>
                      </div>
                      <div className="back face">
                      
                          <div style={{textAlign: 'center'}}><img style={{width: '40%',marginLeft: '5px'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa`} /></div>
                          <hr style={{margin: '0', marginTop: '3px'}} />
                          {/*<img style={{width: '100%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/prizes%2F${teamName}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />*/}
                          {done && <div style={{fontSize: '1.4em', marginTop: '10px', fontWeight: '600'}}>Sim{team}<br />
                          <img style={{width: '60%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team}.png?alt=media&token=967ee100-bb99-42b6-973b-8fab427c1bed`} />
                          </div>}
                          {!done && <div style={{marginTop: '25px'}}>Use a spin to get a card</div>}
                      </div>
                  </div>
              </div>
          </div>
          {start && done &&<div style={{textAlign: 'center'}}><button className="spin-btn" onClick={toPortfolio}>Go to Portfolio</button></div>}
      </div>
      </>
  )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(Spinner)