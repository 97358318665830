import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../portfolio2.css'
import moment from 'moment'
import {getMyTransfers} from '../../../routes/user/user'

const Transfers = (props) => {
    //get props
    const user = props.user
    
    const [transfers, setTransfers] = useState([])

    const getTransfers = () => {
        if(props.location && props.location.state && props.location.state.transfers && transfers.length === 0){
            setTransfers(props.location.state.transfers)
        }
        else if(props.token && transfers.length === 0){
            getMyTransfers(props.token).then(res => {
                let arr = res
                arr.sort((a,b) => {
                    if(b.timestamp > a.timestamp){
                        return 1
                    }
                    else return -1
                })
                setTransfers(arr)
            }).catch(err => console.log(err))
        }
    }

    useEffect(()=>{
        getTransfers()
    },[])

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return(
        <div style={{}}>
            
            {transfers && transfers.length > 0 && transfers.map( t => {
                return(
                    <div style={{marginLeft: '3%', marginRight: '3%', color: 'white', textAlign: 'left', width: '100%'}}>
                        <div style={{display: 'inline-block', width: '70%', paddingLeft: '0%', fontSize: '1.3em', verticalAlign: 'top'}}>
                            <div style={{fontSize: '1.1em'}}><span style={{fontSize: '0.9em', color: t.type === "WITHDRAW" ? 'red' : 'green'}}>{t.type === 'DEPOSIT' ? 'Deposit ' : 'Withdraw '}</span></div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{`${t.timestamp.toString().substring(5, 7)}/${t.timestamp.toString().substring(8, 10)}/${t.timestamp.toString().substring(0, 4)}`}</div>
                        </div>
                        <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'bottom'}}>
                            <div style={{fontSize: '1.5em', color: 'white', fontWeight: '400'}}>${parseFloat(t.amount).toFixed(2)}</div>
                        </div>
                        <hr style={{color: 'gray', backgroundColor: 'gray'}} />

                    </div>
                )
            })}

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token,
});

export default connect(mapStateToProps)(Transfers)