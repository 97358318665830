import axios from 'axios'
import {route} from '../route'


//1. Get NFL Sportle
//2. Post NFL Sportle

/* Sportle */
export const get_nfl_sportle = async (token) => {
    const sportleOptions = {
        method: 'GET',
        url: `${route}/minigames/wordle/nfl`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            //"claimed": false,
        }
    }
    let sportle 
    await axios.request(sportleOptions).then(function (response) {
        let results = response.data
        sportle = results
    }).catch(err => {console.log(err); sportle = err.response})
    return sportle
}

/* Sportle */
export const guess_nfl_sportle = async (token, id) => {
    const sportleOptions = {
        method: 'POST',
        url: `${route}/minigames/wordle/nfl`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "id": id,
        }
    }
    let sportle 
    await axios.request(sportleOptions).then(function (response) {
        let results = response.data
        sportle = results
    }).catch(err => {console.log(err); sportle = err.response})
    return sportle
}