import axios from 'axios'
import {route} from '../route'

//1. Get Account Info
//2. Update Account Info

//1. Get Account Info
export const getAccountInfo = async (token) => {
 const infoptions = {
        method: 'GET',
        url: `${route}/@/personal_info`,
        params: {},
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let info 
    await axios.request(infoptions).then(function (response) {
        let result = response.data
        info = result
    }).catch(err => console.log(err))
    return info
}

//2. Update Account Info
export const updateAccountInfo = async (token, address, birthday, email, first_name, last_name, phone_number, state, username, zip_code, public_) => {
 const infoOptions = {
        method: 'POST',
        url: `${route}/@/personal_info`,
        params: {
            "address": address,
            "birthday": birthday,
            "email": email,
            "first_name": first_name,
            "last_name": last_name,
            "phone_number": phone_number,
            "state": state,
            "username": username,
            "zip_code": zip_code,
            "public": public_,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let info 
    await axios.request(infoOptions).then(function (response) {
        let result = response.data
        info = result
    }).catch(err => {info = err.response})
    return info
}