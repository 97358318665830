import React from 'react'
import '../../LandingPage3/landingpage.css'
import {Link} from 'react-router-dom'
import './Timeline.css'

const Footer = (props) => {

    let logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Funnamed.png?alt=media&token=6a070d2c-624f-4bb7-8acc-d5ad380bc39c'
    return(
        <div className='Footer3'>
            <div className="f-div1">
                <div className="f-img-div"><img className="f-img" src={logo} alt='logo' /></div>
                <div className="f-div-about">A Delaware Corportation. All Rights Reserved.</div>
            </div>
            <div className="f-div">
                <div className="f-div-head">How To Play</div>
                {/*<Link to='/Gettings-Started'><div className="f-div-item2">Getting Started</div></Link>
                <Link to='/F-A-Q'><div className="f-div-item2">FAQs</div></Link>*/}
                <Link to='/Can-I-Make-Money'><div className="f-div-item2">Can I Make Money?</div></Link>
                <Link to='/PayoutAssurance'><div className="f-div-item2">Win Payout Assurance</div></Link>
            </div>
            <div className="f-div">
                <div className="f-div-head">Contests</div>
                {/*<Link to='/Our-Story'><div className="f-div-item2">NFL Playoffs</div></Link>*/}
                <Link to='/Picks'><div className="f-div-item2">March Madness</div></Link>
                
            </div>
            <div className="f-div">
                <div className="f-div-head">Follow Us</div>
                <div className="f-icon-div">
                    <div className="f-icon" style={{color: 'white'}}><a href="https://www.facebook.com/SimBullExchange" aria-label="icon" alt='facebook' className="fa fa-facebook white"></a></div>
                    <div className="f-icon" style={{color: 'white'}}><a href="https://twitter.com/SimBullExchange" aria-label="icon" alt='twitter' className="fa fa-twitter white"></a></div>
                    <div className="f-icon" style={{color: 'white'}}><a href="https://www.instagram.com/simbullexchange" aria-label="icon" alt='instagram' className="fa fa-instagram white"></a></div>
                    <div className="f-icon" style={{color: 'white'}}><a href="https://www.linkedin.com/in/k-j-giles-431163151/" aria-label="icon" alt='linkedin' className="fa fa-linkedin white"></a></div>
                </div>
            </div>
            <br/>
            <br/>
            <hr style={{color: "white", backgroundColor: "white"}}/>
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
                <Link to='/Terms-Of-Use' style={{color: 'gray'}}>Terms of Use</Link> 
                <span style={{color: 'gray', marginLeft: '5px', marginRight: '5px'}}>|</span>
                <Link to='/Privacy-Policy' style={{color: 'gray'}}>Privacy Policy</Link>
            </div>
            <div className="f-copyright">Copyright 2022 SimBull Sports Exchange All Rights Reserved
            
                
            </div>
        </div>
    )
}

export default Footer
