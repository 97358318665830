import axios from 'axios'
import {route} from '../route'

//1. Get Games for Analysis
//2. Get Games By Team

/* Get Games for Analysis */
export const getGamesForAnalysis = async (start, end) => {
    const gamesOptions = {
        method: 'GET',
        url: `${route}/games`,
        params: {
            "before_date": end,
            "after_date": start
        }
    }
    let games = []
    await axios.request(gamesOptions).then(function (response) {
        let results = response.data
        games = results
    }).catch(err => console.log(err))
    return games
}

/* Get Games By Team */
export const getGamesByTeam = async (id) => {
    const gamesOptions = {
        method: 'GET',
        url: `${route}/games`,
        params: {
            "team_id": id
        }
    }
    let games = []
    await axios.request(gamesOptions).then(function (response) {
        let results = response.data
        games = results
    }).catch(err => console.log(err))
    return games
}

