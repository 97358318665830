import * as types from "../types";

export default function (state = {}, action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_TOKEN:
            return {
                ...state,
                token: payload
            };
        default:
            return state;
    }
}