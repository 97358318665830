import React, {useState, useEffect} from 'react'
import '../Holdings/Holdings.css'
import {Link} from 'react-router-dom'
import Logo from '../assets/Logo1.png'
import TopBarDesktop from './TopBarDesktop'
import { BellFill } from 'react-bootstrap-icons'
import {useLocation, useHistory} from 'react-router-dom'
import './newHomeBar.css'
import { height } from '@mui/system'
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PieChartIcon from '@mui/icons-material/PieChart';
import ArticleIcon from '@mui/icons-material/Article';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import RedeemIcon from '@mui/icons-material/Redeem';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Ads from './ads'
import moverCard from './moverCard'
import MoverCard from './moverCard'
import {connect} from 'react-redux'
import amplitude from 'amplitude-js'

// get dimensions of the window
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return (
        width,
        height
    );
}

// shorten email if more than 23 characthers to ... at end'
const shortenEmail = (email) => {
    if (email.length > 23) {
        return email.substring(0, 20) + '...'
    } else {
        return email
    }
}


const HomeBar = (props) => {

    let teams = props.teamsValue.sort(function(a,b){return Math.abs(b.change.day) - Math.abs(a.change.day)})
    const history = useHistory()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    let Money = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fmoney.png?alt=media&token=5e122b7d-84b0-4253-bec0-6422dc640196'
    let Market = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fequity.png?alt=media&token=6aa20e12-54a1-4e7b-811e-eabb3d4d0bef'
    let Find = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fresearch.png?alt=media&token=97389ac3-3fdf-40e8-9ba6-eb3a416ac922'
    let List = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Ffiles.png?alt=media&token=7862c524-3258-4aa2-9bd7-d6f724548d02'
    let Friends = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Ffriends.png?alt=media&token=3cc13a66-be57-44c9-96a6-0a948ea7bf3b'

    const [path, setPath] = useState('')
    let location = useLocation();
    useEffect(() => {
        if(location.pathname){
            setPath(location.pathname)
        }
        
    }, [location, props]);

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        <div className="leftbar">
            <div style={{width: "100%", display: "flex",  alignItems: "center", flexDirection: "column", backgroundColor: "transparent",}}>
                <div style={{width: "80%", backgroundColor: "black", alignItems: "center", justifyContent: "flex-start", display: "flex", height: '90px'}}>
                <   img src={"https://simbull.com/Logo1.png"} style={{width: 46, height: 35}}/>
                    <h1 style={{color: "#c4b454", fontSize: "1.5rem", marginTop: '8px', fontFamily: "'Lato', sans-serif", fontWeight: 400, paddingLeft: 10}}>SimBull</h1>
                </div>
                    <Link to="/" className="row-board">
                        <HomeIcon onClick={() => amplitude.getInstance().logEvent("dashboard_home")} className="icon" style={{fontSize: 20}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_home")}  className="row-txt">Home</h1>
                    </Link>
                    <Link to="/TradeDesktop" className="row-board">
                        <AnalyticsIcon onClick={() => amplitude.getInstance().logEvent("dashboard_trade")} className="icon" style={{fontSize: 20}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_trade")}  className="row-txt">Trade</h1>
                    </Link>
                    <Link to="/Assets" className="row-board">
                        <PieChartIcon  onClick={() => amplitude.getInstance().logEvent("dashboard_assets")} className="icon" style={{fontSize: 20}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_assets")}  className="row-txt">Assets</h1>
                    </Link>
                    <Link to="/LearnPage" className="row-board">
                        <ArticleIcon onClick={() => amplitude.getInstance().logEvent("dashboard_learn")} className="icon" style={{fontSize: 18}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_learn")}  className="row-txt">Learn</h1>
                    </Link>
                    <Link to="/More" className="row-board">
                        <MenuIcon onClick={() => amplitude.getInstance().logEvent("dashboard_more")} className="icon" style={{fontSize: 20}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_learn")}  className="row-txt">More</h1>
                    </Link>
                    <Link to='/Help' className="row-board" style={{marginTop: '50px'}}>
                        <ChatIcon onClick={() => amplitude.getInstance().logEvent("dashboard_contact")} className="icon" style={{fontSize: 20}}/>
                        <h1 onClick={() => amplitude.getInstance().logEvent("dashboard_contact")}  className="row-txt">Contact Us</h1>
                    </Link>
                    
            </div>
            
        </div>
        <div className='topbar'>
            <div className="topbar-title">
                <h1 style={{fontSize: 20}}>{(path.includes('Assets')) ? 'Assets' : (path.includes('Trade')) ? 'Trade' : (path.includes('More')) ? 'More' : (path.includes('GOLD')) ? <>SimBull <span style={{color: '#c4b454'}}>GOLD</span></> : (path.includes('Deposit')) ? 'Deposit' : (path.includes('Withdrawal')) ? 'Withdraw' : (path.includes('Settings')) ? 'Settings' : (path.includes('Transactions')) ? 'Transactions' : (path.includes('Help')) ? 'Help' : (path.includes('/teams/')) ? '' : (path.includes('Redeem')) ? 'Redeem Rewards' : (path.includes('Rewards')) ? 'Rewards' : 'Home'}</h1>
            </div>
            <div className="topbar-middle">
                <div onClick={() => amplitude.getInstance().logEvent("dashboard_free_stock")} className="free-stuff">
                    <Link to="/Rewards" className="free-stuff-item-stock">
                        <h1 style={{fontSize: 10, color: "black"}}>Free Stock!</h1>
                        <RedeemIcon style={{fontSize: 15, color: "black", marginBottom: 6, marginLeft: 6}}/>
                    </Link>
                    <Link to="/Deposit" className="free-stuff-item-transfer">
                        <h1 style={{fontSize: 10, color: 'white'}}>Transfer Funds $</h1>
                    </Link>
                </div>
                <div className="topbar-right">
                    
                <div onClick={() => amplitude.getInstance().logEvent("dashboard_notifications")} className="noti-bell">
                    <NotificationsNoneOutlinedIcon style={{fontSize: 27, color: "black"}}/>
                </div>
                <Link to="/Settings" className="account-section">
                    <div className="account-inner">
                        <div className='account-main'>
                            <div className="account-img" style={{color: 'white'}}>
                                <PermIdentityOutlinedIcon style={{fontSize: 27, color: "white",}}/>
                            </div>
                            <div onClick={() => amplitude.getInstance().logEvent("dashboard_account")} className="account-info">
                                <h1 style={{color: "white", fontSize: 12}}>@{props.info && props.info.username}</h1>
                                <h1 style={{color: "white", fontSize: 9}}>{props.info && shortenEmail(props.info.email)}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="account-outer">
                    <MoreVertOutlinedIcon style={{color: "#c4b454", fontSize: 25}}/>
                    </div>
                </Link>
            </div>
            </div>
            {/* <div className="topbar-right">
                <div className="noti-bell">
                    <NotificationsNoneOutlinedIcon style={{fontSize: 27, color: "white"}}/>
                </div>
                <div className="account-section">
                    
                </div>
            </div> */}
            
            
        </div>
        <div className='rightbar'>
            {props.myuser && props.myuser.fees && <div className="rightbar-ad">
                <Ads/>
            </div>}
            <div className="rightbar-movers">
                <div className="movers-title" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h1 style={{fontSize: 23, color: "white", fontWeight: '500', textAlign: 'left', marginLeft: '8px'}}>Top Movers</h1>
                    <button onClick={()=>{history.push('/TradeDesktop'); amplitude.getInstance().logEvent("movers_see_all")}} style={{backgroundColor: 'black', border: 'none', outline: 'none', fontSize: '.9rem', color: '#c4b454', marginRight: '8px'}}>See All</button>
                    
                </div>
                <div className="movers-list">
                    {teams && teams.length > 0 && teams.map(t => {
                        if(props.teamsValue.indexOf(t) < 10){
                            return <MoverCard t={t} />
                        }
                    })}
                </div>
            </div>
        </div>
        {isMobile ? 
            <div style={{position: 'fixed',  bottom: 0, width: '100vw', textAlign: 'center', backgroundColor: 'black',minHeight: '6vh'}}>
                <Link to="/" style={{display: 'inline-block', width: '25%', color: "white",}}>
                    <HomeIcon style={{fontSize: 24}}/>
                    <div>Home</div>
                </Link>
                <Link to="/TradeDesktop" style={{display: 'inline-block', width: '25%', color: "white",}}>
                    <AnalyticsIcon style={{fontSize: 24}}/>
                    <div>Trade</div>
                </Link>
                <Link to="/Assets" style={{display: 'inline-block', width: '25%', color: "white",}}>
                    <PieChartIcon style={{fontSize: 24}}/>
                    <div>Assets</div>
                </Link>
                <Link to="/More" style={{display: 'inline-block', width: '25%', color: "white",}}>
                    <MenuIcon style={{fontSize: 24}}/>
                    <div>More</div>
                </Link>
            </div>
        : null}
        </>
        
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
    info: state.info.info,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(HomeBar)