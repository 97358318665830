import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {firebaseAnalytics} from '../../services/firebase'

function TikTokForm(props) {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://app.getresponse.com/view_webform_v2.js?u=MocGs&webforms_id=hcgh2";
        script.async = true;
      
        document.body.appendChild(script);

        firebaseAnalytics.logEvent('tiktokform')
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        <div>
            {/*<Helmet>
                <script type="text/javascript" src="https://app.getresponse.com/view_webform_v2.js?u=MocGs&webforms_id=hcgh2" data-webform-id="hcgh2"></script>
            </Helmet>*/}
        </div>
    );
}

export default TikTokForm;