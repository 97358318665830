import React, {useState, useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import './portfolio.css'
import 'chartjs-plugin-datalabels';



const PortfolioChart = (props) => {
  const labels = props.labels
  const prices = props.prices
  const [chartData, setChartData] = useState()

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const chart = () => {
    setChartData({
      labels: labels,
      datasets: [
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: props.color ? props.color : 'green',
          borderColor: props.color ? props.color : 'green',
          borderWidth: 3,
          data: prices,
          yAxisID: 'price1'
        },
      ]
    })
  }
  useEffect(() => {
    chart()
  }, [props])

  return (
    <div style={{zIndex: '9999'}}>
      {chartData && <Line 
        data={chartData}
        options={{
          title:{
            display:false,
            text:' ',
            fontSize:15
          },
          legend:{
              display: false
          },
          plugins: {
            datalabels: {
              display: false,
            }
          },
          layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0
              }
          },
          elements: {
              point: {
                  radius: 0
              }
          },
          scales:
            {
              yAxes: [{
                display : props.off ? false : isMobile ? false : true,
                position: 'right',
                id: 'price1',
              }],
              xAxes: [{
                  display : false
              
              }],
          },
              tooltips: {
                  'enabled': props.off ? false : true, 
                  mode: 'nearest',
                  intersect: false
              },
              hover: {
                  mode: 'index',
                  intersect: false
              }
        }}
      />}
    </div>
  )
}

export default PortfolioChart