import React, {useState, useEffect} from 'react'
import './portfolio.css'
import { connect } from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {getDates} from './getDates'

const PeriodChange = (props) => {

    const user = props.user
    const teams = props.teams
    const userPrices = props.userPrices
    const totals = props.totals
    

    //get states
    const [percentReturn, setPercentReturn] = useState(0)
    const [change, setChange] = useState(0)
    const [period, setPeriod] = useState(props.period ? props.period : 'ALL')

    //Functions
    useEffect(()=>{
        if(props.period){
            setPeriod(props.period)
        }
    }, [props.period])


    const getPercentReturn = () => {
        if(totals && totals.total_deposited && userPrices && userPrices.length > 0){
            if(period === 'ALL'){
                return setPercentReturn((totals.total_value + totals.total_withdrawn)/(totals.total_deposited))
            }
            else{
                let info = getDates(userPrices[0].timestamp.substring(0,10), userPrices)
                let prices = info[1]
                let dates = info[0]
                if(period === '1Y' && userPrices.length > 365){
                    let date = moment().subtract(365, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setPercentReturn((totals.total_value - prices[index])/prices[index])
                    }
                }
                else if(period === '3M' && userPrices.length > 90){
                    let date = moment().subtract(90, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setPercentReturn((totals.total_value - prices[index])/prices[index])
                    }
                }
                else if(period === '1M' && userPrices.length > 30){
                    let date = moment().subtract(30, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setPercentReturn((totals.total_value - prices[index])/prices[index])
                    }
                }
                else if(period === '1W' && userPrices.length > 7){
                    let date = moment().subtract(7, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setPercentReturn((totals.total_value - prices[index])/prices[index])
                    }
                }
                else{
                    return setPercentReturn((totals.total_value + totals.total_withdrawn)/(totals.total_deposited))
                }
            }
            
        }
        else return setPercentReturn(0)
    }


    const getAmountGain = () => {
        if(totals && totals.total_deposited && userPrices && userPrices.length > 0){
            if(period === 'ALL'){
                return setChange((totals.total_change))
            }
            else{
                let info = getDates(userPrices[0].timestamp.substring(0,10), userPrices)
                let prices = info[1]
                let dates = info[0]
                if(period === '1Y' && userPrices.length > 365){
                    let date = moment().subtract(364, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setChange((totals.total_value - prices[index]))
                    }
                }
                else if(period === '3M' && userPrices.length > 90){
                    let date = moment().subtract(90, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setChange((totals.total_value - prices[index]))
                    }
                }
                else if(period === '1M' && userPrices.length > 30){
                    let date = moment().subtract(30, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setChange(totals.total_value - prices[index])
                    }
                }
                else if(period === '1W' && userPrices.length > 7){
                    let date = moment().subtract(7, 'd').format('YYYY-MM-DD')
                    let index = dates.indexOf(date)
                    if(index !== -1){
                        return setChange(totals.total_value - prices[index])
                    }
                }
                else{
                    return setChange((totals.total_change))
                }
            }
            
        }
        else return setChange(0)
    }

    useEffect(()=>{
        getPercentReturn()
        getAmountGain()
    },[props.totals, props.userPrices, props.period, period])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <div>
            <hr className="p-graph-top" style={{margin: '10px', padding: '0', }} />
            {props && !props.welcome && <div className={percentReturn >=0 ? "p-graph-return green" : "p-graph-return red" } style={{textAlign: props.assets ? 'right' : 'left', paddingRight: props.assets ? '36px' : '0px'}}>{change > -0.001 ? '+' : '-'}${Math.abs(change.toFixed(2))} [{percentReturn>=0 && '+'}{(percentReturn * 100).toFixed(2)}%] - {period}</div>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    userPrices: state.userPrices.userPrices,
    totals: state.totals.totals
});

export default connect(mapStateToProps)(PeriodChange)