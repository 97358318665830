import React, {useState, useEffect} from 'react'
import './portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import Deposit from './Deposit'


const BuyingPower = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    /*Three*/
    const [totals, setTotals] = useState(props.totals)
    useEffect(()=>{
        if(props.totals){
            setTotals(props.totals)
        }
    },[props.totals])
    /*End Three*/
    
    const [showBP, setShowBP] = useState(false)

    const closeBP = () => {
        setShowBP(false)
        props.closeDeposit()
    }

        useEffect(()=>{
        if(props.show){
            setShowBP(true)
        }
    },[props.show])

    useEffect(()=>{
        if(showBP === 'modalOpen'){
            firebaseAnalytics.logEvent("buyingPower_click", {
            user: user && user.email 
            })
        }
    },[showBP])

    let cash = totals ? (totals.cash_balance ? totals.cash_balance : 0) : 0

    return(
        <>
           <div style={{fontWeight: '300', textAlign: 'left'}}>
                {!props.off && <hr style={{padding: '0', margin: '0', marginLeft: '10px', marginRight: '10px', marginTop: '0px'}} />}
                 <button onClick={()=>setShowBP(true)} style={{width: '100%', border: 'none', outline: 'none', backgroundColor: 'white', textAlign: 'left'}}>
                <div style={{textAlign: 'left', display: 'inline-block', marginTop: '0px', paddingLeft: '0px', color: 'rgb(150,150,150)', fontSize: '.9rem'}}>Buying Power</div>
                <div style={{display: 'inline-block', color: 'rgb(120,120,120)', width: '100%', textAlign: 'left', paddingRight: '0px',  fontSize: '1.5rem', fontWeight: '500'}}>${cash.toFixed(2)}{' '} {' '}<span className="fas fa-chevron-right" style={{marginLeft: '3px', color: 'rgb(120,120,120)'}}></span></div>
                </button>
                {!props.off && <hr style={{padding: '0', margin: '0', marginLeft: '10px', marginRight: '10px', marginTop: '7px'}} />}
            </div>

            <div className={showBP ? 'modalOpen' : 'modal'}>
                <Deposit closeBP={closeBP} />
            </div>
        </>

    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
});

export default connect(mapStateToProps)(BuyingPower)