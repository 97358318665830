import React, {useState, useEffect} from 'react'
import Popup from 'reactjs-popup'
import Status from '../Status'

const Success = (props) => {

    const [show, setShow] = useState(false)
    useEffect(()=>{
        if(props.correct){
            setShow(true)
        }
    },[props.correct])

    return(
        <>
        <Popup open={show} modal>
            <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '90vw', marginLeft: '0vw', padding: '20px', textAlign: 'center', marginTop: '2vh', marginBottom: '0vh', backgroundColor: '#fffafa', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                
                <div style={{padding: '3x', borderRadius: '.3em', border: '1px solid green'}}>

                <div style={{paddingBottom: '0px', fontSize: '1.3rem', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '15px'}}><strong>Touchdown!</strong></div>

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px'}}>
                    <strong>Nice,</strong> you really know your stuff.
                </div>

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '1px', paddingLeft: '15px', paddingRight: '15px'}}>
                    Now let's see if you can do it again tomorrow...
                </div>

                <Status popup={true} saveStreak={props.saveStreak} saveCorrect={props.saveCorrect} saveGuesses={props.saveGuesses} />

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '0px', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px'}}>
                    A new <strong>SPORTLE</strong> will be available each day!
                </div>

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />


                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>setShow(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>close</button>
                </div>

                </div>
                
            </div>
        </Popup>
        </>
    )
}

export default Success