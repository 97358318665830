import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom'
import './Trade.css'

const Search = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const [searchTeam, setSearchTeam] = useState('')
    const updateTeam = (val) => {
        setSearchTeam(val)
        props.teamSearch(val)
    }

    const [oneDay, setOneDay] = useState(moment().subtract(2, 'd').format('YYYYMMDD'))
    const getPercentChange = (t) => {
        return (t.lastSoldPrice - t.prices[oneDay])/t.prices[oneDay]*100
    }

    return(
        <div className='Search'>

            {searchTeam && <div style={{padding: '5%', borderTop: '1px solid black', backgroundColor: 'rgb(235,235,235)', minHeight: '15vh', maxHeight: '86vh', width: '102%', marginLeft: '-2%'}}>
                {teams && teams.length > 0 && teams.map(t => {
                    if(t.team.toLowerCase().includes(searchTeam.toLowerCase())){
                        return(
                            <>
                            <div className="DesktopContainer">
                            <Link to={`/Teams/${t.team}`} style={{color: 'black'}}>
                            <div style ={{width: '100%', backgroundColor: 'white', margin: '6px', padding: '10px'}}>

                                <div style={{width: '10%',paddingLeft: '1%', display: 'inline-block'}}><img style={{width: '100%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                                <div style={{width: '50%', paddingLeft: '1%', display: 'inline-block',fontSize: '1.1em', fontWeight: '400', }}>Sim{t.team}</div>
                                <div style={{textAlign: 'center',  width: '15%', display: 'inline-block', fontWeight: '200', fontSize: '0.9em'}}>${t.lastSoldPrice.toFixed(2)}</div>
                                <div style={{textAlign: 'right',width: '25%',paddingRight: '5px', display: 'inline-block', fontSize: '1.05em', fontWeight: '300', color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                
                            </div>
                            </Link>
                            </div>
                            </>
                        )
                    }
                })}

                <div style={{textAlign: 'center', paddingTop: '15px', fontSize: '1.2em'}}>No More Results...</div>

            </div>}

            <input onChange={(e)=>updateTeam(e.target.value)} value={searchTeam} style={{zIndex: '1', border: '.5px solid lightgray', borderRadius: '0.2em', width: '85%', marginLeft: '7.5%', fontSize: '1.25em', textAlign: 'center'}} placeholder='search' />
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Search)