import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../portfolio2.css'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import DownloadOffers from './DownloadOffers'
import {getMyOffers} from '../../../routes/user/user'
import {deleteOffer} from '../../../routes/trading/trading'
import Popup from 'reactjs-popup'
import Loader from '../../adHoc/Loader'

const AllOffers = (props) => {
    //get props
    const user = props.user

    const history = useHistory()

    const [offers, setOffers] = useState([])
    const [asks, setAsks] = useState([])
    const [bids, setBids] = useState([])
    const [hasBids, setHasBids] = useState(0)
    const [hasAsks, setHasAsks] = useState(0)

    const [edit, setEdit] = useState(false)
    const [deletedOffers, setDeletedOffers] = useState([])
    const [offersCount, setOffersCount] = useState(0)

    const [view, setView] = useState('ALL')
    const [loading, setLoading] = useState(false)


    const getOffers = (res) => {
            let arr = res
            let arrCount = res.length
            let hasBids1 = 0
            let hasAsks1 = 0
            let bids1 = []
            let asks1 = []
            res.map(o => {
                if(o.type === "BID"){
                    hasBids1++
                    bids1.push(o)
                }else if(o.type === "ASK"){
                    hasAsks1++
                    asks1.push(o)
                }
            })
            bids1.length > 0 && bids1.sort((a,b) => {
                return b.price - a.price
            })
            asks1.length > 0 && asks1.sort((a,b) => {
                return a.price - b.price
            })
            setBids(bids1)
            setAsks(asks1)
            setOffers(arr)
            setHasBids(hasBids1)
            setHasAsks(hasAsks1)
    }
    useEffect(()=>{
        if(props.offers && props.teamsValue.length > 0){
            getOffers(props.offers)
        }else if(offers.length === 0 && props.token && props.teamsValue.length > 0){
            getMyOffers(props.token).then(res => {
                getOffers(res)
            })
        }
        else return false
    },[ props.offers, props.t, props.token, props.teamsValue])

    const deleteOneOffer = (o) => {
        deleteOffer(props.token, o.id).then(res => {
            const arr = deletedOffers
            setDeletedOffers([])
            arr.push(o)
            setDeletedOffers(arr)
            return null
        }).catch(err => {
            console.log(err.response)
        })
    }

    const deleteAll = () => {
        if(offers && offers.length > 0 && props.token){
            setLoading(true)
            offers.map(o => {
                if(!deletedOffers.includes(o)){
                    deleteOffer(props.token, o.id).then(res => {
                        return res
                    }).catch(err => {
                        console.log(err.response)
                    })
                }
            })
            setDeletedOffers(offers)
            setLoading(false)
        }
    }

    /*const deleteAll = () => {
        let batch = db.batch()
        let arr = []
        offers.map(o => {
            if(o.bidTeam){
                let bidRef = db.collection('Bids').doc(`${o.bidTeam}-${o.bidOwner}-$${o.bidPrice.toFixed(2)}-${o.bidNum}`)
                batch.delete(bidRef)
            }
            else{
                let askRef = db.collection('Asks').doc(`${o.askShare}-${o.askOwner}`)
                let shareRef = db.collection('Shares').doc(o.askShare)
                batch.delete(askRef)
                batch.update(shareRef, {
                    onTradingBlock: false,
                    currentAsk: null
                })
            }
            arr.push(o)
        })
        batch.commit().then(res => {
            setDeletedOffers(arr)
            setOffersCount(0)
        })
    }*/

    let getTeam = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    let buttonStyle = {
        border: '1px solid gray',
        outline: 'none',
        borderRadius: '.5em',
        width: '30%',
        marginLeft: '1.5%',
        marginRight: '1.5%',
        backgroundColor: 'white',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '1.1em',
        color: 'gray'
    }
    let selectedButtonStyle = {
        border: '3px solid #c5b358',
        outline: 'none',
        borderRadius: '.5em',
        width: '30%',
        marginLeft: '1.5%',
        marginRight: '1.5%',
        backgroundColor: 'white',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '1.1em',
        color: 'black'
    }

    const OfferList = (props) => {
        let o = props.o
        if(deletedOffers.indexOf(o) === -1){
            let t = getTeam(o.asset_id)
            return(
                <div key={o.id} style={{marginLeft: '3%', marginRight: '3%'}}>
                    <div style={{display: 'inline-block', width: '70%', paddingLeft: '2%', fontSize: '1.3em', verticalAlign: 'top'}}>
                        <div style={{fontSize: '1.1em'}}><span style={{fontSize: '0.9em', color: 'gray'}}>{o.type === 'ASK' ? 'Ask: ' : 'Bid: '}</span>
                        Sim{t.team.name} </div>
                        <div style={{fontSize: '0.8em', color: 'gray'}}>{`${o.timestamp.substring(5, 7)}/${o.timestamp.substring(8, 10)}/${o.timestamp.substring(0, 4)}`}</div>
                    </div>
                    <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'bottom'}}>
                        <div style={{fontSize: '1.1em', color: 'black'}}>${(o.price).toFixed(2)}</div>
                        <div style={{fontSize: '0.8em', color: 'gray'}}>{o.remaining_amount.toFixed(4)} Shares</div>
                    </div>
                    {edit && <div style={{textAlign: 'center'}}>
                        <button onClick={()=>{deleteOneOffer(o)}} style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                    </div>}
                    <hr />

                    
                    
                </div>
            )
        }
        else return null
    }

    return(
        <div className="DesktopContainer" style={{ margin: '0', marginTop: '7vh', paddingBottom: '10vh'}}>
            <div><button style={{display: 'inline', fontSize: '1rem', fontWeight: '200', border: 'none', outline: 'none', textDecoration: 'underline', backgroundColor: 'white'}} onClick={()=>history.goBack()}>Back</button></div>
            <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '77%', paddingLeft: '5px'}}>Current Offers ({offers.length - deletedOffers.length})
                {' '}{props.teamsValue && <DownloadOffers teamsValue={props.teamsValue ? props.teamsValue : []} offers={offers} type={`_SimBull_Offers`} />}
            </div> 
                <div style={{width: '20%',textAlign: 'right', display: 'inline-block', }}>
                    <button style={{textAlign: 'right', fontSize: '.7em', border: 'none', outline: 'none', backgroundColor: 'white', textDecoration: 'underline', color: 'gray'}} onClick={()=>setEdit(!edit)}>{edit ? 'close': 'edit'}</button>
                </div>
            </div>
            {edit && <div style={{textAlign: 'center'}}>
                <button onClick={deleteAll} style={{outline: 'none', border: '1px solid darkred', borderRadius: '.3em', backgroundColor: 'white', color: 'darkred', fontSize: '1.1em', minWidth: '50%', marginTop: '8px', marginBottom :'8px'}}>Delete All Offers</button>
            </div>}
            <div>
                <button onClick={()=>setView('ALL')} style={view === "ALL" ? selectedButtonStyle : buttonStyle}>ALL</button>
                <button onClick={()=>setView('BID')} style={view === "BID" ? selectedButtonStyle : buttonStyle}>BID</button>
                <button onClick={()=>setView('ASK')} style={view === "ASK" ? selectedButtonStyle : buttonStyle}>ASK</button>
            </div>
            <hr style={{marginTop: '3px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
            {props.teamsValue && props.teamsValue.length > 0 && offers && offers.length > 0 && <>
                {view === "ALL" && offers.map(o => {
                    return <OfferList o={o} />
                })}
                {view === "BID" && bids.length > 0 && bids.map(o => {
                    return <OfferList o={o} />
                    })}
                {view === "ASK" && asks.length > 0 && asks.map(o => {
                    return <OfferList o={o} />
                    })}
            </>}
            {offers.length === 0 && <div style={{textAlign: 'center'}}>No Offers</div>}

            <Popup open={loading} modal>
                    <Loader />
            </Popup>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token
});

export default connect(mapStateToProps)(AllOffers)