import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Logo from '../assets/Logo1.png'
import './landingpage.css'
import { Link } from 'react-router-dom'
import Ticker from '../Analysis3/Ticker'

const NavBar = () => {
    return(
        <div>
        <Navbar className='NavBar' expand="lg" fixed="top">
            <Navbar.Brand href="/"><img src={Logo} className='nav-logo' alt='logo' /> <span className="robo400" style={{color:"white", fontSize: "1.2rem"}}>SimBull</span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" color="white" className="toggle-nav" style={{backgroundColor: "gray"}}/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto nav-size">
                    <NavDropdown title={<span className="roboNormal">Learn More{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop">
                        {/*<NavDropdown.Item className="roboNormal nav-drop-size" href="/TodaysTrades">Today's Trades</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/ExcelTrading">Excel Trading</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/Teams">All Teams</NavDropdown.Item>*/}
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/Can-I-Make-Money">Can I Make Money?</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/PayoutAssurance">Win Payout Assurance</NavDropdown.Item>
                     </NavDropdown>
                    <NavDropdown title={<span className="roboNormal">About Us{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop">
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/Our-Story"><span style={{textDecoration: "none"}}>Our Story</span></NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal nav-drop-size" href="/ContactUs">Contact Us</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="robo200">
                        <Link to="/login" className="roboNormal">
                            Login
                        </Link>
                    </Nav.Link>
                    <Nav.Link className="robo200">
                        <Link to={{pathname: '/login', state: true}} className="roboNormal">
                            Signup
                        </Link>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            
        </Navbar>
    </div>
    )
}

export default NavBar