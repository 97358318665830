import React, {useEffect} from 'react'
import './Account.css'
import {Link, useHistory} from 'react-router-dom'
import {auth, firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux';
import {getToken} from '../../redux/actions/token'


const Account = (props) => {
    const user = props.user

    const dispatch = useDispatch()
    const history = useHistory()

    const logOut = () => {
        //auth.signOut()
        window.localStorage.removeItem("access_token")
        window.localStorage.removeItem("refresh_token")
        dispatch(getToken('logout'))
        history.replace('/')
    }

    useEffect(()=>{
        firebaseAnalytics.logEvent("settings_visit", {
           user: user && user.email 
        })
    },[])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    let teammatesTestArr = ['dallasjklein@gmail.com', 'kenneth.giles@simbull.app', 'kjgiles97@gmail.com', 'jcsalow@gmail.com', 'jkleincr@gmail.com', 'mie.stecher.andersen@gmail.com', 'dallas.klein@simbull.app', 'patton.fitzpatrick@simbull.app', 'rjw1400@gmail.com', 'jeanne.a.giles@gmail.com', 'joshua.h.giles@gmail.com', 'kevin.anthony.giles@gmail.com', 'elizbamf21@gmail.com', 'stiedesteven@yahoo.com']

    return(
        <div className="Account">
            <Helmet>
                <title>Account</title>
            </Helmet>
            <Link to='/Account' onClick={props.closeBar}><div className="a-item">My Account</div></Link>
            <Link to='/Transfers' onClick={props.closeBar}><div className="a-item">Cash Transfers</div></Link>
            <Link to='/Subscription' onClick={props.closeBar}><div className="a-item">SimBull GOLD</div></Link>
            <Link to='/payoutassurance' onClick={props.closeBar}><div className="a-item">Payout Assurance</div></Link>
            {/*<Link to='/Gift-Share' onClick={props.closeBar}><div className="a-item">Gift Share</div></Link>*/}
            {/*<Link to='/Teammates' onClick={props.closeBar}><div className="a-item">Teammates</div></Link>*/}
            {/*<Link to='/Referrals' onClick={props.closeBar}><div className="a-item">Referrals</div></Link>*/}
            {/*<Link to='/Text' onClick={props.closeBar}><div className="a-item">Text Alerts</div></Link>*/}
            {/*<Link to='/Notifications' onClick={props.closeBar}><div className="a-item">Notifications</div></Link>*/}
            {/*<Link to='/Leaderboard' onClick={props.closeBar}><div className="a-item">Leaderboard</div></Link>*/}
            <hr />
            <Link to='/Tutorials' onClick={props.closeBar}><div className="a-item">Tutorials</div></Link>
            <Link to='/FAQ' onClick={props.closeBar}><div className="a-item">FAQs</div></Link>
            <Link to='/Contact-Us' onClick={props.closeBar}><div className="a-item">Contact Us</div></Link>
            {/* <Link to='/GiveReview' onClick={props.closeBar}><div className="a-item">Give Us a Review</div></Link>*/}
            <Link to='/TermsOfUse' onClick={props.closeBar}><div className="a-item">Terms of Service</div></Link>
            <Link to='/Privacy' onClick={props.closeBar}><div className="a-item">Privacy Policy</div></Link>
            <hr />
            <button onClick={logOut} className="a-item a-btn">Log Out</button>
            <br />
            <br />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token
});

export default connect(mapStateToProps)(Account)
