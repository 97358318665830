import React, { useEffect, useState } from 'react'
import './Account.css'
import {Link} from 'react-router-dom'
import Header from './Header'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

const UserAccount = (props) => {
    const user = props.user

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

        //states
    const [email, setEmail] = useState(null)
    const [favoriteTeam, setFavoriteTeam] = useState(null)
    const [username, setUsername] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [value, setValue] = useState()
    const [birthday, setBirthday] = useState(null)
    const [address, setAddress] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [state, setState] = useState(null)
    const [public_, setPublic] = useState(null)

    useEffect(()=>{
        if(props.info && firstName === null){
            setEmail(props.info.email ? props.info.email : null)
            setUsername(props.info.username ? props.info.username : null)
            setFirstName(props.info.first_name ? props.info.first_name : null)
            setLastName(props.info.last_name ? props.info.last_name : null)
            setValue(props.info.phone_number ? props.info.phone_number : null)
            setBirthday(props.info.birthday ? props.info.birthday : null)
            setAddress(props.info.address ? props.info.address : null)
            setZipCode(props.info.zip_code ? props.info.zip_code : null)
            setState(props.info.state ? props.info.state : null)
            setPublic(props.info.public ? props.info.public : null)
        }
    },[props.info])

    return(
        <>
        
        <div className="UserAccount DesktopContainer">
            <div className="ua-div">
                <div className="ua-left">Email</div>
                <div className="ua-right">{email}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">Username</div>
                <div className="ua-right">{username}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">Visibility</div>
                <div className="ua-right">{public_ ? 'Public' : 'Private'}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">First Name</div>
                <div className="ua-right">{firstName}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">Last Name</div>
                <div className="ua-right">{lastName ? lastName : '---'}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">Phone</div>
                <div className="ua-right">{value ? value : '---'}</div>
            </div>
            {/*<div className="ua-div">
                <div className="ua-left">Birthday</div>
                <div className="ua-right">{birthday ? birthday : '---'}</div>
    </div>*/}
            <div className="ua-div">
                <div className="ua-left">Address</div>
                <div className="ua-right">{address ? address : '---'}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">Zip Code</div>
                <div className="ua-right">{zipCode ? zipCode : '---'}</div>
            </div>
            <div className="ua-div">
                <div className="ua-left">State</div>
                <div className="ua-right">{state ? state : '---'}</div>
            </div>
            <div className='ua-edit-div'><Link to='/EditAccount' style={{color: 'black'}}>Edit</Link></div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    info: state.info.info,
});

export default connect(mapStateToProps)(UserAccount)