
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import TeamCard from './TeamCard'
import './LandingPage3.css'

const Teams = (props) => {

    let Pic = 'PFF-Logo-Black.png'

    let payouts1 = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fpayout1.png?alt=media&token=e44d03ee-f7f0-4319-88b0-42a83f9395ff'
    let payouts2 = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fpayouts2.png?alt=media&token=732355fe-56d7-47d9-a007-e0e9a8f8cab7'
    let payouts3 = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fpayouts3.png?alt=media&token=8f6c7f12-ce66-496b-b8ad-b4adbe555f77'

    const user = props.user
    const teams = props.teams
    const teamsValue = props.teamsValue

    const [under30, setUnder30] = useState([])
    const [mostTraded, setMostTraded] = useState([])

    const [weekAgo, setWeekAgo] = useState(moment().subtract(7, 'd').format('YYYYMMDD'))

    const [showCards, setShowCards] = useState('under30')
    const [showCards2, setShowCards2] = useState('nfl')


    const [cart, setCard] = useState([])
    const addTeamToCart = (team) => {

    }
    //delete also
    const deleteTeamFromCart = () => {

    }

    const returnDaysHeld = () => {
        var a = moment('20200903');
        var b = moment().format('YYYYMMDD');
        return 0 - a.diff(b, 'days')   // =1
    }

    teamsValue && teamsValue.sort((a,b)=>{
        return b.change.week - a.change.week
    })


    return(
        <div style={{paddingLeft: '3%', paddingTop: '10px', width: '90%', marginLeft: '5%', marginTop: '35px'}}>
            <div className='teams-title'>1. Buy a Team <span className="title-emoji"></span></div>
            <div className="league-btns">
                <button className={showCards === 'under30' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('under30')}>Under $30</button>
                <button className={showCards === 'nfl' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('nfl')}>SimNFL</button>
                <button className={showCards === 'cfb' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('cfb')}>SimCFB</button>
                <button className={showCards === 'mlb' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('mlb')}>SimMLB</button>
                <button className={showCards === 'nba' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('nba')}>SimNBA</button>
                <button className={showCards === 'nhl' ? 'filterBTN-selected' : 'filterBTN'} onClick={()=>setShowCards('nhl')}>SimNHL</button>
            </div>
            <div style={{whiteSpace: 'nowrap', overflowY: 'scroll'}}>
                {showCards === 'under30' && teamsValue && teamsValue.map(t => {
                    if(t.asking_price < 30){
                        if(t.change.week > 0 || Math.random() < .21){
                            return(
                                <TeamCard t={t} />
                            )
                        }
                    }
                    
                })}
            </div>
            <div style={{whiteSpace: 'nowrap', overflowY: 'scroll'}}>
                {showCards !== 'under30' && teamsValue && teamsValue.map(t => {
                    if(t.team.league === showCards.toUpperCase()){
                        if(t.change.week > 0 || Math.random() < .21){
                            return(
                                <TeamCard t={t} />
                            )
                        }
                    }
                    
                })}
            </div>
            

            <div  className='teams-title' style={{marginTop: '55px'}}>2. Collect Win Payouts <span  className="title-emoji"></span></div>
            <div style={{fontSize: '1.3rem', fontStyle: 'italic'}}>Get cash when your teams win.</div>
            {/* A gif or 3 iphone screenshots of collecting win payout? */}
                <div className="payouts-div">
                    <img className="payout-img" src={payouts1} />
                    <img className="payout-img" src={payouts2} />
                    <img className="payout-img" src={payouts3} />
                </div>

            <div className='teams-title' style={{marginTop: '55px'}}>3. Hold Forever{/*<span style={{fontSize: '2rem'}}>💎🙌</span>*/} or Sell to the Market {/*<span className="title-emoji">📈💲</span>*/}</div>
            <div className="teams-third-title">Sell your share and get paid out quickly, or continue collecting win payouts, without expiration.</div>
            <div style={{width: '80%', marginLeft: '10%', marginTop: '10px'}}>
                <div className="threecard">
                    <div className="threecard-title">Longest Share Held</div>
                    <hr />
                    <div className="threecard-body">
                        <span style={{fontSize: '1.35rem'}}>Sim49ers<br /></span>
                        <br />
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>September 3rd, 2020<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$19.66<br /></span>
                        <br />
                        <span style={{fontSize: '1rem', marginTop: '5px'}}>Days Held:<br /></span>
                        <div style={{fontSize: '1.75rem', fontWeight: '600', color: 'darkgray', marginTop: '5px'}}>{returnDaysHeld()}<br /></div>
                    </div>
                </div>
                <div className="threecard">
                    <div className="threecard-title">Best Trade</div>
                    <hr />
                    <div className="threecard-body">
                        <span style={{fontSize: '1.35rem'}}>SimBengals<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>September 13th, 2020<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$14.84<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '200', color: 'gray'}}>to<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>April 29th, 2021<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$60.00<br /></span>
                        <div style={{fontSize: '1.75rem', fontWeight: '600', color: 'green', marginTop: '5px'}}>+306%</div>
                    </div>
                </div>
                <div className="threecard">
                    <div className="threecard-title">Worst Trade</div>
                    <hr />
                    <div className="threecard-body">
                        <span style={{fontSize: '1.35rem'}}>SimRams<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>October 10th, 2020<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$27.50<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '200', color: 'gray'}}>to<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>December 20th, 2020<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$16.00<br /></span>
                        <div style={{fontSize: '1.75rem', fontWeight: '600', color: 'red', marginTop: '5px'}}>-41.81%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(Teams)