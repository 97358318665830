import React, {useState, useEffect} from 'react'
import './TeammateFeed.css'
import TeammateItem from './TeammateItem'
import { connect } from 'react-redux'
import {getTeammatesSummary, getTeammatesTransactions} from './utils'
import {firebaseAnalytics} from '../../services/firebase'

const TeammateFeed = (props) => {

    const [feed, setFeed] = useState('all')
    
    useEffect(() => {
        firebaseAnalytics.logEvent("teammates_feed_visit")
    }, [])

    

    return(
        <>
        <div className="TeammateFeed">

            {feed === 'all' && <>

                <div className="TeammateFeed-Header">All Activity <button style={{paddingRight: '10px', color: 'gray',  float: 'right', fontSize: '1rem', fontWeight: '300', backgroundColor: '#fffafa', marginRight: '3px' }}  className="activity-shadow" onClick={()=>setFeed('teammates')}>Teammates</button></div>

                {props.trades && props.trades.length && props.trades.map(t => {
                    return <TeammateItem publicAccounts={props.publicAccounts} token={props.token} feed={feed} key={t.id} teamsValue={props.teamsValue} i={t} teammates={props.teammates} changeTeammate={props.changeTeammate} />
                })}

            </>}

            {feed === 'teammates' && <>

                <div className="TeammateFeed-Header">Teammates' Activity <button style={{paddingRight: '10px', color: 'gray', border: 'none', outline: 'none',  float: 'right', fontSize: '1rem', fontWeight: '300', backgroundColor: '#fffafa', marginRight: '3px'}}   className="activity-shadow" onClick={()=>setFeed('all')}>All</button></div>

                {props.transactions && props.transactions.length && props.transactions.map(t => {
                        return <TeammateItem feed={'teammates'} key={t.id} teamsValue={props.teamsValue} i={t} teammates={props.teammates} changeTeammate={props.changeTeammate} />
                })}

                {(!props.transactions || props.transactions.length === 0) && <>
                    <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>No history</div>
                </>}

            </>}
            

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(TeammateFeed)