import React, {useState, useEffect} from 'react'
import Popup from 'reactjs-popup'

const FirstVisit = (props) => {

    const [show, setShow] = useState(false)
    useEffect(()=>{
        if(props.hasPlayed){
            //do nothing
        }
        else setShow(true)
    },[props.hasPlayed])

    return(
        <>
        <Popup open={show} modal>
            <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '80vw', marginLeft: '0vw', padding: '20px', textAlign: 'center', marginTop: '2vh', marginBottom: '0vh', backgroundColor: '#fffafa', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                
                <div style={{paddingBottom: '0px', fontSize: '1.3rem'}}><strong>Welcome to Sportle!</strong></div>

                <hr />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '10px'}}>
                    Guess the <strong>SPORTLE</strong> in six or less tries.
                </div>

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '10px'}}>
                    Each guess must be one of the hundreds of active NFL players. Hit the search button to submit.
                </div>

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '10px'}}>
                    After each guess, the tiles that are correct will turn green.
                </div>

                <hr />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '0px', textAlign: 'center'}}>
                    A new <strong>SPORTLE</strong> will be available each day!
                </div>

                <hr />


                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>setShow(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>close</button>
                </div>

                
                
            </div>
        </Popup>
        </>
    )
}

export default FirstVisit