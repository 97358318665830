import * as types from "../../types";

export default function (state = [], action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_USER_PRICES:
            return {
                ...state,
                userPrices: payload
            };
        default:
            return state;
    }
}