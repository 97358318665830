import React, {useState, useEffect} from 'react'
import './Guess.css'

const Guess = (props) => {

    const [players, setPlayers] = useState(props.players)
    const [current, setCurrent] = useState('')
    useEffect(()=>{setPlayers(props.players)},[props.players])
    const [placeholder, setPlaceholder] = useState(props.placeholder)
    useEffect(()=>{setPlaceholder(props.placeholder)},[props.placeholder])

    let remaining = current === '' ? [] : players.filter(player => {
            if (player.name.toLowerCase().includes(current.toLowerCase())) {
                return player;
            }
        })

    return(
        <>
        <div className="Guess">
            <div className="Guess-Header">Guess Today's Player</div>
            <div className="Guess-Input-Div">
                <input className="Guess-Input" placeholder={placeholder} value={current} onChange={(e)=>setCurrent(e.target.value)} />
                <button onClick={()=>{props.guess(current); setCurrent('');setPlaceholder('')}} className="Guess-btn"><i class="fas fa-search"></i></button>
            </div>

            
            {remaining.length !== 416 && remaining.map((player, index) => {
                    if(index < 10 && current.toLowerCase() !== player.name.toLowerCase()){
                        return(
                            <div className="Guess-Search-Player">
                                <button className="Guess-Search-Player-btn" onClick={()=>setCurrent(player.name)}>{player.name}</button>
                            </div>
                        )
                    }
                })
            }

        </div>
        </>
    )
}

export default Guess