import React, {useState, useEffect, useRef} from 'react'
import {firebaseAnalytics} from '../../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../portfolio2.css'
import '../../Trade2/Trade.css'
import Team from '../Team'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Overlay} from 'react-bootstrap'
import PieChart from './PieChart'
import '../../Account2/Friends/Friends.css'

const AllTeams = (props) => {
    //get props
    const user = props.user

    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])
    useEffect(()=>{
        if(teams.length === 0){
            setTeams(props.teamsValue)
        }
    },[props.teamsValue])
    const [yesterday, setYesterday] = useState(moment().subtract(1, 'd').format('YYYYMMDD'))

    /*Sorts*/
    const [type, setType] = useState('all')
    const [league, setLeague] = useState('ALL')
    const [period, setPeriod] = useState('1D')
    const [price, setPrice] = useState('High')
    const [teamName, setTeamName] = useState('AtoZ')
    const [holdings, setHoldings] = useState('Largest')
    const [showTimePeriod, setShowTimePeriod] = useState(false)
    const target = useRef(null);

    const [showPie, setShowPie] = useState(true)

    const [oneDay, setOneDay] = useState(moment().subtract(1, 'd').format('YYYYMMDD'))
    const [oneWeek, setOneWeek] = useState(moment().subtract(7, 'd').format('YYYYMMDD'))
    const [oneMonth, setOneMonth] = useState(moment().subtract(31, 'd').format('YYYYMMDD'))
    /*End Sorts*/

    const getShareAmount = (t) => {
        let count = 0
        let eq = {}
        props.equity.length > 0 && props.equity.map(e => {
            if(e.asset_id && t.team && (e.asset_id === t.team.id)){
                count = e.amount
                eq = e
            }
        })
        return [count, eq]
    }

    /*Sorts*/
    teams.sort(function(a, b) {
        //Initial All Sorted by one day change
        if(type === 'all'){
            return Math.abs(b.change.day) - Math.abs(a.change.day)
        }
        //Sort by change
        else if(type === 'period'){
            if(period === '1D'){
                return Math.abs(b.change.day) - Math.abs(a.change.day)
            }
            else if(period === '1W'){
                return Math.abs(b.change.week) - Math.abs(a.change.week)
            }
            else if(period === '1M'){
                return Math.abs(b.change.month) - Math.abs(a.change.month)
            }
        }
        //Sort by price
        else if(type === 'price'){
            if(price === 'High'){
                return b.asking_price - a.asking_price
            }
            else if(price === 'Low'){
                return a.asking_price - b.asking_price
            }
        }
        //sort by name
        else if(type === 'teamName'){
            if(teamName === 'AtoZ'){
                if(a.team.name < b.team.name) { return -1; }
                if(a.team.name > b.team.name) { return 1; }
            }
            else if(teamName === 'ZtoA'){
                if(b.team.name < a.team.name) { return -1; }
                if(b.team.name > a.team.name) { return 1; }
            }
        }
        //sort by amt of holdings
        else if(type === 'holdings'){
            if(holdings === 'Largest'){
                return (getShareAmount(b) * b.asking_price) - (getShareAmount(a) * a.asking_price)
            }
            else if(holdings === 'Smallest'){
                return (getShareAmount(a) * a.asking_price) - (getShareAmount(b) * b.asking_price)
            }
        }
    })
    /*End Sorts*/


    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    

    return(
        <div className="DesktopContainer" style={{ margin: '0', marginTop: '7vh', paddingBottom: '20vh'}}>
            {/*Portfolio League Chart*/}
            <div style={{marginBottom: '10px'}}>
                {showPie ? <PieChart portfolio2={true} /> : <button onClick={()=>setShowPie(true)}>Show Pie</button>}
            </div>
            <div style={{display: 'inline-block', width: '50%', fontSize: '1.6em', fontWeight: '500', paddingLeft: '5px'}}>All Teams</div>
                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', paddingRight: '3%', fontSize: '1.2em', fontWeight: '200', color: 'rgb(100,100,100)'}}><Link style={{color: 'rgb(90,90,90)'}} ref={target} onClick={()=>{setShowTimePeriod(!showTimePeriod)}}>
                        {type === 'all' ? `Change (1D)`: type === 'period' ? `Change (${period})` : type === 'holdings' ? `${holdings}` : type === 'price' ? `Price ${price}`: `Name ${teamName.substring(0,1)} to ${teamName.substring(3,4)}`}{' '}<i style={{marginLeft: '5px'}} className="fas fa-ellipsis-v"></i>
                    </Link>
                    <Overlay className="FC-E-Overlay" target={target.current} show={showTimePeriod} placement="bottom">
                        {(props) => (
                            <div className='FC-E-return-div'  id="overlay-example" {...props}>
                                    {/*<button className="FC-E-item-return-item" onClick={()=>{setPeriod('All'); setShowTimePeriod(false)}} >All</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('1Y'); setShowTimePeriod(false)}} >1Y</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('3M'); setShowTimePeriod(false)}} >3M</button>
                                    */}
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1D'); setShowTimePeriod(false); setType('period')}} > Change (1D)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1W'); setShowTimePeriod(false); setType('period')}} > Change (1W)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1M'); setShowTimePeriod(false); setType('period')}} > Change (1M)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPrice('High'); setShowTimePeriod(false); setType('price')}} > Price High</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPrice('Low'); setShowTimePeriod(false); setType('price')}} > Price Low</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setTeamName('AtoZ'); setShowTimePeriod(false); setType('teamName')}} > Name (A to Z)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setTeamName('ZtoA'); setShowTimePeriod(false); setType('teamName')}} > Name (Z to A)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setHoldings('Largest'); setShowTimePeriod(false); setType('holdings')}} >Largest</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setHoldings('Smallest'); setShowTimePeriod(false); setType('holdings')}} >Smallest</button>
                            </div>
                        )}
                    </Overlay>  
                </div>  
            
            <hr style={{marginTop: '3px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
            {teams && props.equity && props.equity.length > 0 && teams.length > 0 && teams.map(t => {
                if(league === 'ALL' || t.team.league === league){
                    if(getShareAmount(t)[0] > 0){
                        return <Team time={period} t={t} e={getShareAmount(t)[1]} />
                    }
                }
            })}

            <div className="LeagueFilter">
                <button onClick={()=>setLeague('ALL')} className={league === 'ALL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>All</button>
                <button onClick={()=>setLeague('NBA')} className={league === 'NBA' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNBA</button>
                <button onClick={()=>setLeague('NFL')} className={league === 'NFL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNFL</button>
                <button onClick={()=>setLeague('NHL')} className={league === 'NHL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNHL</button>
                <button onClick={()=>setLeague('CFB')} className={league === 'CFB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimCFB</button>
                <button onClick={()=>setLeague('MLB')} className={league === 'MLB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimMLB</button>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
});

export default connect(mapStateToProps)(AllTeams)