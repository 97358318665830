import axios from 'axios'
import {route} from '../route'


/* Teams */
export const get_nba_teams = async () => {
    const teamsOptions = {
        method: 'GET',
        url: `${route}/teams`,
        params: {
            "league": 'NBA'
        }
    }
    let teams = []
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams
}

/* Games */
export const get_nba_games = async () => {
    const teamsOptions = {
        method: 'GET',
        url: `${route}/games`,
        params: {
            "league": 'NBA'
        }
    }
    let teams = []
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams
}