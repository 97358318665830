import React, {useEffect, useState} from 'react'
import readXlsxFile from 'read-excel-file'
import { db } from '../../services/firebase'
import { connect } from 'react-redux'
import './Trade.css'


const ExcelOffers = (props) => {

    const user = props.user

    const email = user.email

    const [asksUploaded, setAsksUploaded] = useState(false)
    const [bidsUploaded, setBidsUploaded] = useState(false)
    const [batchUploaded, setBatchUpload] = useState(false)

    const [askTeams, setAskTeams] = useState([])
    const [bidTeams, setBidTeams] = useState([])

    const [tryAgain, setTryAgain] = useState(false)

    let askTeams1 = []
    let bidTeams1 = []

    const [loading, setLoading] = useState(false)
    const [complete, setCompleted] = useState(false)
    const [showResults, setShowResults] = useState(false)

    const [status, setStatus] = useState('')

    const [update, setUpdate] = useState(false)

    let input = document.getElementById('input')

    const readFile = async () => {

        setLoading(true)

        let promises = []

        input = document.getElementById('input')
        console.log(input)

        if(input && input.files && input.files[0]){

            if(tryAgain){
                setTryAgain(false)
            }

            //Read Asks
            let askRead = await readXlsxFile(input.files[0], {sheet: 'Asks'}).then( (rows) => {
                rows.map( async row => {

                    //team
                    if(row.length > 1){
                        let team = row[0]
                        team = team.substring(3, team.length)

                        let counter = 0
                        //get available shares
                        let asksPull = await db.collection('Shares').where('owner', '==', email).where('team', '==', team).get()
                        
                        let availableShares = []
                        let askBatch = db.batch()
                        asksPull.docs.map(doc => {
                            const data = doc.data()
                            if(!data.onTradingBlock){
                                availableShares.push(data.shareID)
                                counter++
                                if(row[counter]){
                                    //create ask
                                    let share = data.shareID
                                    let price = row[counter]
                                    let shareRef = db.collection('Shares').doc(share)
                                    askBatch.update(shareRef, {
                                        onTradingBlock: true,
                                        currentAsk: parseFloat(price)
                                    })
                                    let askRef = db.collection('Asks').doc(`${share}-${email}`)
                                    askBatch.set(askRef, {
                                        askNum: counter,
                                        askPrice: parseFloat(price),
                                        askOwner: email,
                                        askShare: share,
                                        date: '20210101',
                                        team: team,
                                        subscription: false
                                    })                                }
                            }
                        })
                        let askUpload = askBatch.commit().then(res => {console.log(`${team}: Successful Ask Upload (${counter})`); askTeams1.push(team); setStatus(`${team}: Successful Ask Upload (${counter})`)})
                        promises.push(askUpload)
                        promises.push(asksPull)
                        
                    }

                    console.log(`Ask: ${rows.indexOf(row)}`)
                    console.log(rows.length - 1)
                    if(rows.indexOf(row) === rows.length - 1){
                        setCompleted(true)
                    }
                })
            }).catch(err => console.log(err))
            promises.push(askRead)

            //Read Bids
            let bidRead = await readXlsxFile(input.files[0], {sheet: "Bids"}).then((rows) => {
                rows.map(row => {
                    //team
                    if(row.length > 1){

                        let bidBatch = db.batch()

                        let counter = 0
                        let team = row[0]
                        team = team.substring(3, team.length)
                        row.map(r => {
                            if(row.indexOf(r) !== 0){
                                let price = parseFloat(r)
                                //create bid
                                let bidRef = db.collection('Bids').doc(`${team}-${email}-$${price.toFixed(2)}-${counter}`)
                                bidBatch.set(bidRef, {
                                    bidNum: counter,
                                    bidOwner: email,
                                    bidPrice: parseFloat(price.toFixed(2)),
                                    bidTeam: team,
                                    date: "20210101",
                                    subscription: true, 
                                    time: 'January 01, 2021 at 9:53:35 AM UTC+1'
                                })
                                counter++
                            }
                        })

                        let bidUpload = bidBatch.commit().then(res => {console.log(`${team}: Successful Bid Upload ${counter}`); bidTeams1.push(team); setStatus(`${team}: Successful Bid Upload (${counter})`)})
                        promises.push(bidUpload)
                    }

                    console.log(`Bid: ${rows.indexOf(row)}`)
                    console.log(rows.length - 1)
                    if(rows.indexOf(row) === rows.length - 1){
                        setCompleted(true)
                    }
                })
            }).catch(err => console.log(err))
            promises.push(bidRead)

        }

        else setTryAgain(true)
        
        

        await Promise.all(promises)

        setBatchUpload(true)

        setAskTeams(askTeams1)
        setBidTeams(bidTeams1)

        return setCompleted(true)
    }

    /*Delete All Bids */
    const [runBids, setRunBids] = useState(false)
    const [runAsks, setRunAsks] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const deleteAllBids = async () => {
        setDeleting(true)
        setRunAsks('loading')
        setRunBids('loading')
        //delete asks
        let askPull = await db.collection('Asks').where('askOwner', '==', email).get().catch(err => console.log(err))
        let sharePull = await db.collection('Shares').where('owner', '==', email).where('onTradingBlock', '==', true).get().catch(err => console.log(err))
        if(askPull.docs.length < 500){
                let askBatch = db.batch()   
                askPull.docs.map(doc => {
                    const data = doc.data()
                    let askRef = db.collection('Asks').doc(`${data.askShare}-${data.askOwner}`)
                    askBatch.delete(askRef)
                })
                askBatch.commit().then(res => {console.log(`Asks Deleted`) } )
        }
        else{
            let asksArr = askPull.docs
            let numDocs = asksArr.length
            let cycles = Math.floor(numDocs / 500) + 1
            let arr = []
            for(let i = 0; i < cycles; i++){
                
                let askBatch = db.batch()

                for(let j = 0; j < 500; j++){
                    if(i != cycles - 1){
                        let index = (i * 500)  + j
                        arr.push(index)

                        const data = asksArr[index].data()
                        let askRef = db.collection('Asks').doc(`${data.askShare}-${data.askOwner}`)
                        askBatch.delete(askRef)
                    }
                    else{
                        let index = (i * 500)  + j
                        if(index < numDocs){
                            arr.push(index)

                            const data = asksArr[index].data()
                            let askRef = db.collection('Asks').doc(`${data.askShare}-${data.askOwner}`)
                            askBatch.delete(askRef)
                        }
                    }
                }

                askBatch.commit().then(res => {console.log(`Asks Deleted (${i + 1} of ${cycles})`)})
            }

        }

        if(sharePull.docs.length < 500){

            let shareBatch = db.batch()   
            sharePull.docs.map(doc => {
                const data = doc.data()
                let shareRef = db.collection('Shares').doc(data.shareID)
                shareBatch.update(shareRef, {
                    onTradingBlock: false,
                    currentAsk: null
                })
            })
            shareBatch.commit().then(res => {console.log(`Shares Update`); setRunAsks('complete')}).catch(err => console.log(err))
        }
        else{

            let sharesArr = sharePull.docs
            let numDocsShares = sharesArr.length
            let cyclesShares = Math.floor(numDocsShares / 500) + 1
            let arrShares = []
            for(let i = 0; i < cyclesShares; i++){
                
                let shareBatch = db.batch()

                for(let j = 0; j < 500; j++){
                    if(i != cyclesShares - 1){
                        let index = (i * 500)  + j
                        arrShares.push(index)

                        const data = sharesArr[index].data()
                        let shareRef = db.collection('Shares').doc(data.shareID)
                        shareBatch.update(shareRef, {
                            onTradingBlock: false,
                            currentAsk: null
                        })
                    }
                    else{
                        let index = (i * 500)  + j
                        if(index < numDocsShares){
                            arrShares.push(index)

                            const data = sharesArr[index].data()
                            let shareRef = db.collection('Shares').doc(data.shareID)
                            shareBatch.update(shareRef, {
                            onTradingBlock: false,
                            currentAsk: null
                        })
                        }
                    }
                }

                shareBatch.commit().then(res => {console.log(`Shares Updated (${i + 1} of ${cyclesShares})`)})

                if(i === cyclesShares - 1){
                    setRunAsks('complete')
                }
            }

        }

        //delete bids
        let bidPull = await db.collection('Bids').where('bidOwner', '==', email).get().catch(err => console.log(err))
        console.log(bidPull)
        if(bidPull.docs.length < 500){
            let bidBatch = db.batch()
            bidPull.docs.map(doc => {
                let bidRef = db.collection('Bids').doc(doc.id)
                bidBatch.delete(bidRef)
            })
            bidBatch.commit().then(res => {console.log(`Bids Deleted`); setRunBids('complete')})
        }else {

            let bidsArr = bidPull.docs
            let numDocs = bidsArr.length
            let cycles = Math.floor(numDocs / 500) + 1
            let arr = []
            for(let i = 0; i < cycles; i++){
                
                let bidBatch = db.batch()

                for(let j = 0; j < 500; j++){
                    if(i != cycles - 1){
                        let index = (i * 500)  + j
                        arr.push(index)

                        const doc = bidsArr[index]
                        let bidRef = db.collection('Bids').doc(doc.id)
                        bidBatch.delete(bidRef)
                    }
                    else{
                        let index = (i * 500)  + j
                        if(index < numDocs){
                            arr.push(index)

                            const doc = bidsArr[index]
                            let bidRef = db.collection('Bids').doc(doc.id)
                            bidBatch.delete(bidRef)
                        }
                    }
                }

                bidBatch.commit().then(res => {console.log(`Bids Deleted (${i + 1} of ${cycles})`)})

                if(cycles - 1 === i){
                    setRunBids('complete')
                }
            }
            
        }

    }
    /*End Delete All Bids*/


    useEffect(()=>{
        let numDocs = 2224
        let cycles = Math.floor(numDocs / 500) + 1
        let arr = []
            for(let i = 0; i < cycles; i++){
                for(let j = 0; j < 500; j++){
                    if(i != cycles - 1){
                        let index = (i * 500)  + j
                        arr.push(index)
                    }
                    else{
                        let index = (i * 500)  + j
                        if(index < numDocs){
                            arr.push(index)
                        }
                    }
                }
            }
        console.log(arr)
    },[])
    

    return(
    <>
        <div className="DesktopContainer" style={{paddingBottom: '10vh'}}>
            <div style={{color: 'gray', fontSize: '0.9em', paddingTop: '9vh'}}>User</div>
            <div style={{fontSize: '1.1em', marginBottom: '25px'}}>{email}</div>

            <h2>Delete All Offers</h2>
            <p>By clicking the button below, you will delete all your outstanding offers</p>
            <div><button onClick={deleteAllBids}>Delete All Offers</button></div>
            {deleting && <div>Delete Started</div>}
            {runAsks === 'loading' && <div>Asks Uploading</div>}
            {runBids === 'loading' && <div>Bids Uploading</div>}
            {runAsks === 'complete' && runBids === 'complete' && <h4 style={{color: 'green'}}>Delete Complete</h4>}

            <h2 style={{marginTop: '25px'}}>Upload New Offers</h2>
            <p style={{marginLeft: '3px', marginRight: '3px'}}>Upload an Excel File with sheet names: 'Asks' and 'Bids'. 
            <br/>Place the team name in the first column and the rest of the offers in the same row following it. <br />The name of the team should be exactly how you see it online, like 'SimIOWA-FB', anything different won't work.<br />Start in the first row, with no headers.</p>
            For Example: 
            <table style={{marginBottom: '15px'}}>
                <tr><td style={{outline: '1px solid black'}}>SimIOWA-FB</td><td style={{outline: '1px solid black'}}>44.34</td><td style={{outline: '1px solid black'}}>45.64</td></tr>
                <tr><td style={{outline: '1px solid black'}}>SimCardinalsSTL</td><td style={{outline: '1px solid black'}}>32.14</td><td style={{outline: '1px solid black'}}>35.00</td></tr>
            </table>
            <div>Install Excel File Here: <input type="file" id="input" /><button onClick={readFile}>submit</button></div>
            {tryAgain && <div style={{color: 'red'}}>Try Again, please</div>}
            

            {loading && !complete && <div>Loading...</div>}
            {status && <div>Last Done: {status}</div>}
            {batchUploaded && <h4 style={{color: 'green'}}>Offer's Created</h4>}
        </div>
    </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(ExcelOffers)