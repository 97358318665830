import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import RewardHeader from '../../assets/stock_rewards.png'
import EastIcon from '@mui/icons-material/East';
import amplitude from 'amplitude-js'

const Widget = (props) => {

    const available = props.available

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px'}}>
            
            <div style={{color: 'white', fontSize: '1.4rem', fontWeight: '400', marginLeft: isMobile ? '4px' : '0px'}}>Rewards</div>  
                
            <div style={{border: '1px solid white', borderRadius: '1rem', margin: '8px', padding: '20px'}}>

                <div style={{width: '60%', marginLeft: '20%', marginRight: '20%', borderBottom: '2px solid #c4b454', textAlign: 'center'}}>
                    <img style={{width: '80%'}} src={RewardHeader} />
                </div>
            
                <div style={{width: isMobile? '90%':'60%', borderRadius: '1rem', margin: isMobile? '5%':'20%', marginBottom: '5%', marginTop: '10%', backgroundColor: 'rgb(30,30,30)', color: 'white', paddingTop: '10%', paddingBottom: '10%'}}>
                    <div style={{textAlign: 'center', fontWeight: '200', fontSize: '1.5rem'}}>Redeemable Rewards</div>
                    <div style={{textAlign: 'center', fontWeight: '600', fontSize: '3.5rem'}}>{available}</div>
                </div>
                {available > 0 ? 
                    
                    <div onClick={() => amplitude.getInstance().logEvent("home_redeem")} style={{textAlign: 'center', color: '#c4b454', fontWeight: '200', fontSize: '1rem'}}>
                        <Link to={{pathname: "/Redeem", state: {spins: props.spins, available: available}}} style={{color: '#c4b454',}}>Redeem a Stock Reward <EastIcon style={{marginLeft: '4px'}} /></Link>
                        {/*<Link to={{pathname: "/Redeem", state: {spins: 1, available: 1}}} style={{color: '#c4b454',}}>Redeem a Stock Reward <EastIcon style={{marginLeft: '4px'}} /></Link>*/}
                    </div>
                    
                :
                    <div style={{textAlign: 'center', color: '#c4b454', fontWeight: '200', fontSize: '1rem'}}>
                        <Link to="/Rewards" style={{color: '#c4b454',}}>Find out how to get free stock rewards <EastIcon style={{marginLeft: '4px'}} /></Link>
                    </div>
                }

            </div>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    watchlist: state.watchlist.watchlist,
});

export default connect(mapStateToProps)(Widget)