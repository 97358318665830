import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { CSVLink, CSVDownload } from "react-csv"

const DownloadOffers = (props) => {
    const user = props.user
    const offers = props.offers

    const [data, setData] = useState([])

    let getTeam = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    useEffect(()=>{
        let offers1 = []

        offers.map(o => {
            
            let t = getTeam(o.asset_id)

            offers1.push({
                team: `Sim${t && t.team.name}`,
                type: `${o.type}`,
                shares: o.remaining_amount,
                price: `$${(o.price).toFixed(4)}`,
                date: `${o.timestamp.substring(5, 7)}/${o.timestamp.substring(8, 10)}/${o.timestamp.substring(0, 4)}`,
                
            })

        })

        setData(offers1)

    },[props.offers])

    return(
        <>
         <CSVLink data={data} filename={`MyOffers_SimBull${props.type}.csv`}><span className="fas fa-download" style={{color: 'rgb(80,80,80)', marginRight: '3px', fontWeight: '200', fontSize: '0.9em'}}></span></CSVLink>
        </>
        
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(DownloadOffers)
