import React, {useState, useEffect} from 'react';
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import {FacebookIcon} from 'react-share'
import './Receipts.css'
import {storageRef} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { withRouter } from 'react-router-dom';

const OffersReceipt = (props) => {
    let data = props.location && props.location.state ? props.location.state : {shares: "", totalCost: "", team: "", type: "", link: "", perShare: ""}

    //props
    const shares = data.shares
    const avgCost = data.totalCost
    const team = data.team
    const type = data.type
    const link = data.link
    const perShare = data.perShare

    //state
    const [url, setUrl] = useState('')

    useEffect(()=>{
        storageRef.child('jerseys').child(`${team}.png`).getDownloadURL().then(res => {return setUrl(res)});
    })

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div className="transaction-recipt">
            <Helmet>
                <title>Share {type}!</title>
                <meta property="og:title" content="SimBull" />
                <meta property="og:description" content="The Stock Market for Sports. Trade Teams like Stocks." />
                <meta property="og:image" content={url} />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content="https://www.simbull.app" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="transaction-recipt-modal">
                <div className="transaction-recipt-head">Offer to {type}!</div>
                <div className="transaction-recipt-body">You created an offer to {type} {shares} share{shares === 1 ? "" : "s"} of the team <span className="tr-name-sim">Sim</span>{team} for a price per share of ${perShare}{`, for a total of $${avgCost}`}!</div>
                <div className="transaction-recipt-img"> <div className='ss-team'><img className="ss-img"  src={url} alt='team' /><span className="tc-name-sim">Sim</span>{team}</div></div>
                <div className="share-buttons-div">
                <button className="share-btn">
                    <FacebookShareButton
                        url={"https://www.simbull.app"}
                        quote={`I just created an offer to ${type} a share of the Sim${team} on SimBull - The Stock Market for Sports! I can trade the Sim${team} just like a stock and I'll get cash anytime they win. Go ${team}!`}
                        hashtag="#SimBull"
                    >
                        Share on Facebook
                    </FacebookShareButton>
                </button>
                <button className="share-btn">
                    <TwitterShareButton
                        url={"https://www.simbull.app"}
                        title={`I just created an offer to ${type} a share of the Sim${team} on SimBull - The Stock Market for Sports! I can trade the Sim${team} just like a stock and I'll get cash anytime they win. Go ${team}!`}
                    >
                        Share on Twitter
                    </TwitterShareButton>
                </button>
                {/*<div className="share-btn">
                    <EmailShareButton
                        url={"https://www.simbull.app"}
                        title='I bought a share on SimBull'
                        body='I just bought a share of the SimRams on SimBull - The Stock Market for Sports!'
                    >
                        Share on Email
                    </EmailShareButton>
                </div>*/}
                <button onClick={()=>{return window.location.href = `https://simbull.app/${link}`}} className="share-close-btn">Close</button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(OffersReceipt); 