import axios from 'axios'
import {route} from '../route'


/*1. Get Teammates (PLURAL) */
export const get_teammates = async (token) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let teammates 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        teammates = results
        console.log(response)
    }).catch(err => console.log(err))
    return teammates
}


/*2. Get Teammates (PLURAL) Transactions */
export const get_teammates_transactions = async (token) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/transactions`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let transactions 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        transactions = results
    }).catch(err => console.log(err))
    return transactions
}


/*3. Get Teammates (PLURAL) Summary */
export const get_teammates_summary = async (token) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/summary`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let summary 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        summary = results
    }).catch(err => console.log(err))
    return summary
}


/*4. Get Teammate (SINGULAR) Transactions */
export const get_teammate_transactions = async (token, user_id) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/${user_id}/transactions`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let transaction
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        transaction = results
    }).catch(err => console.log(err))
    return transaction
}


/*5. Get Teammate (SINGULAR) Equity */
export const get_teammate_equity = async (token, user_id) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/${user_id}/equity`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let equity
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        equity = results
    }).catch(err => console.log(err.response))
    return equity
}

/*5. Get Teammate (SINGULAR) Summary */
export const get_teammate_summary = async (token, user_id) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/${user_id}/summary`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let summary
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        summary = results
    }).catch(err => console.log(err))
    return summary
}
