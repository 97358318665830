import React, { useState, useRef, useEffect } from "react";
import "./landingPage.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signup } from "../../routes/auth/auth";
import { getToken } from "../../redux/actions/token";
import { firebaseAnalytics } from "../../services/firebase";
import Hero from "./hero-img.PNG";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import amplitude from 'amplitude-js'


const Top = () => {
  let bengals_bitcoin_sp =
    "https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ftop_landing_page.png?alt=media&token=5a6eb76a-a831-47e2-bbed-bd7008e083b8";

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const emailField = useRef(null);
  const passwordField = useRef(null);
  

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let history = useHistory();
  const dispatch = useDispatch();

  const handleEmail = (e) => {
    if (
      username === email &&
      !e.target.value.includes("@") &&
      !email.includes("@")
    ) {
      setUsername(e.target.value);
    }
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSignupRes = (res) => {
    let carry;
    //mail with no '@', already used email
    //short(long) username, already used username
    //short password
    if (res.status === 200) {
      carry = { type: "success", data: res }; //res returns {refresh_token: 'token', access_token: 'token'}
    } else if (res.status === 400) {
      if (res.data && res.data.msg && !res.data.msg.query) {
        //already used email -or- already user username
        carry = { type: "err", msg: res.data.msg };
        if ((carry.msg = "username is taken")) {
          let rand = (Math.random() * 1000).toString().substring(0, 3);
          signup(email, `user${rand}`, password, "First", "Last").then(
            (res) => {
              let result = handleSignupRes(res);
              if (result.type === "success") {
                dispatch(getToken(res));
                firebaseAnalytics.logEvent("signup_1");
                history.replace("/");
              } else if (result.type === "err") {
                setError(true);
                setErrorMsg(result.msg);
              } else {
                setError(true);
                setErrorMsg(
                  "Unknown Error, please refresh and try again or contact us at customer-service@simbull.app"
                );
              }
            }
          );
        }
      } else if (
        /*res.data.msg.query.email[0]*/ res.data &&
        res.data.msg &&
        res.data.msg.query &&
        res.data.msg.query.email &&
        res.data.msg.query.email[0]
      ) {
        carry = { type: "err", msg: res.data.msg.query.email[0] }; //short(long) or missing email
      } else if (
        /*res.data.msg.query.username[0]*/ res.data &&
        res.data.msg &&
        res.data.msg.query &&
        res.data.msg.query.username &&
        res.data.msg.query.username[0]
      ) {
        carry = {
          type: "err",
          msg: `Username ${res.data.msg.query.username[0]}`,
        }; //short(long) or missing username
        let rand = (Math.random() * 1000).substring(0, 3);
        signup(email, `user${rand}`, password, "First", "Last").then((res) => {
          let result = handleSignupRes(res);
          if (result.type === "success") {
            dispatch(getToken(res));
            firebaseAnalytics.logEvent("signup_1");
            history.replace("/");
          } else if (result.type === "err") {
            setError(true);
            setErrorMsg(result.msg);
          } else {
            setError(true);
            setErrorMsg(
              "Unknown Error, please refresh and try again or contact us at customer-service@simbull.app"
            );
          }
        });
      } else if (
        /*res.data.msg.query.password[0]*/ res.data &&
        res.data.msg &&
        res.data.msg.query &&
        res.data.msg.query.password &&
        res.data.msg.query.password[0]
      ) {
        carry = {
          type: "err",
          msg: `Password ${res.data.msg.query.password[0]}`,
        }; //short(long) or missing password
      } else if (
        /*res.data.msg.query.first_name[0]*/ res.data &&
        res.data.msg &&
        res.data.msg.query &&
        res.data.msg.query.first_name &&
        res.data.msg.query.first_name[0]
      ) {
        carry = { type: "err", msg: res.data.msg.query.first_name[0] }; //short(long) or missing first_name
      } else if (
        /*res.data.msg.query.last_name[0]*/ res.data &&
        res.data.msg &&
        res.data.msg.query &&
        res.data.msg.query.last_name &&
        res.data.msg.query.last_name[0]
      ) {
        carry = { type: "err", msg: res.data.msg.query.last_name[0] }; //short(long) or missing last_name
      }
    } else if (res.access_token && res.refresh_token) {
      carry = { type: "success", data: res };
    } else if (res.status === 500) {
      carry = { type: "err", msg: "Error, please refresh" };
    } else {
      carry = {
        type: "err",
        msg: "Unknown Error, please refresh and try again or contact us at customer-service@simbull.app",
      };
    }
    return carry;
  };

  const handleClick = (e) => {
    signup(email, username, password, "First", "Last").then((res) => {
      let result = handleSignupRes(res);
      if (result.type === "success") {
        dispatch(getToken(res));
        firebaseAnalytics.logEvent("signup_1");
        history.replace("/");
      } else if (result.type === "err") {
        setError(true);
        setErrorMsg(result.msg);
      } else {
        setError(true);
        setErrorMsg(
          "Unknown Error, please refresh and try again or contact us at customer-service@simbull.app"
        );
      }
    });

    /*else{
            alert('Please fill out both fields.')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
        }*/
  };

  //capture autofill
  useEffect(() => {
    let interval = setInterval(() => {
      if (emailField.current) {
        setEmail(emailField.current.value);
        setPassword(passwordField.current.value);
        //do the same for all autofilled fields
        clearInterval(interval);
      }
    }, 100);
  });

  return (
    <>
      <div className="Top">
        <div className="Top-CTA">
          <h1 className="Top-CTA-title">
            The Only Stock Market for Sports Teams.
          </h1>

          {false && (
            <>
              <h4 className="Top-CTA-subtitle">
                Get $10 in any NFL, NBA, NHL, MLB, or CFB team stock just for
                signing up.
              </h4>
              <div className="Top-CTA-email">Enter your email:</div>
              <input className="Top-CTA-input" placeholder="your@email.com" />
              <button className="Top-CTA-btn">Sign Up</button>{" "}
            </>
          )}

          <h4
            className="Top-CTA-subtitle"
            style={{
              marginTop: "0px",
              marginBottom: "15px",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            Download the IOS mobile app now. New users get FREE team stock
            valued up to $150.
          </h4>
          <div
            className="top-top"
          >
              <div onClick={() => amplitude.getInstance().logEvent('app_store_landing')} className="svg">
              <a
          class='app store'
          href="https://apps.apple.com/app/apple-store/id1628754092"
          target='_blank'
          rel="noopener"
          aria-label='app store'
        >
                <svg
                  width="100px"
                  height="35px"
                  viewBox="0 0 97 26"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs />
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="App-Store"
                      transform="translate(-10.000000, -7.000000)"
                      fill="#FFFFFF"
                    >
                      <g id="Group">
                        <g transform="translate(9.000000, 6.000000)">
                          <g
                            id="icon"
                            transform="translate(0.777778, 1.111111)"
                          >
                            <path
                              d="M16.499154,11.8220832 C16.473036,9.01365198 18.875889,7.64734103 18.9857646,7.58373089 C17.6249284,5.66409886 15.5156774,5.40181596 14.7744675,5.38090303 C13.0029488,5.20052908 11.2845667,6.40650762 10.3821459,6.40650762 C9.46171272,6.40650762 8.07205674,5.39833047 6.5743264,5.42795711 C4.64700054,5.45671238 2.84396018,6.53634201 1.8550799,8.21286132 C-0.185724032,11.6312528 1.33632303,16.6547111 3.2915681,19.417831 C4.26964094,20.7719428 5.41252717,22.2820301 6.90845626,22.2288764 C8.37196305,22.1713659 8.91863913,21.3261352 10.6847541,21.3261352 C12.4346579,21.3261352 12.948011,22.2288764 14.4736606,22.1940215 C16.0443411,22.1713659 17.0332214,20.8355529 17.9770707,19.469242 C19.1073482,17.9199429 19.5612605,16.3906855 19.5792729,16.312262 C19.5432481,16.3009342 16.529775,15.1881924 16.499154,11.8220832"
                              id="Fill-2"
                            />
                            <path
                              d="M13.6171714,3.5632215 C14.4043129,2.61081215 14.9428834,1.31508231 14.7933805,0.00018229782 C13.6540968,0.0489791175 12.2293166,0.762632605 11.4088522,1.69412904 C10.6829528,2.51496125 10.0345068,3.86035928 10.202022,5.1255911 C11.4818024,5.21795651 12.7958063,4.50081754 13.6171714,3.5632215"
                              id="Fill-3"
                            />
                          </g>
                          <g
                            id="App-Store"
                            transform="translate(26.555556, 10.888889)"
                          >
                            <path
                              d="M10.7699064,12.6027504 L8.77027078,12.6027504 L7.67491774,9.20644132 L3.86759736,9.20644132 L2.82419434,12.6027504 L0.877389216,12.6027504 L4.65036975,1.04018177 L6.98019623,1.04018177 L10.7699064,12.6027504 L10.7699064,12.6027504 Z M7.34472691,7.78153807 L6.35415442,4.76317594 C6.24937387,4.45386767 6.05213988,3.72751455 5.76333297,2.58411657 L5.72811261,2.58411657 C5.61188544,3.07588196 5.42609807,3.80223509 5.171631,4.76317594 L4.19778819,7.78153807 L7.34472691,7.78153807 L7.34472691,7.78153807 Z"
                              id="Fill-4"
                            />
                            <path
                              d="M20.453743,8.33151597 C20.453743,9.74946848 20.0636776,10.8702765 19.2826662,11.6930713 C18.5835422,12.4255063 17.7144799,12.7912894 16.678121,12.7912894 C15.5589942,12.7912894 14.7542091,12.3977033 14.2655267,11.6079246 L14.2655267,15.9825514 L12.3882817,15.9825514 L12.3882817,7.00566087 C12.3882817,6.11509634 12.364508,5.20194188 12.3187215,4.26532864 L13.9696757,4.26532864 L14.0744562,5.58770837 L14.1096766,5.58770837 C14.7357184,4.59114494 15.6857875,4.09329764 16.9607643,4.09329764 C17.9575004,4.09329764 18.7895813,4.48167066 19.455246,5.25928555 C20.1200302,6.03863812 20.453743,7.0621357 20.453743,8.33151597 L20.453743,8.33151597 Z M18.5412778,8.39928576 C18.5412778,7.58778598 18.3563709,6.91877653 17.9847962,6.3922574 C17.5788816,5.84401719 17.0338466,5.56859381 16.3505717,5.56859381 C15.887424,5.56859381 15.4665408,5.72151026 15.0905635,6.02386778 C14.7137057,6.32709414 14.4671632,6.7241556 14.3518165,7.21678983 C14.293703,7.44529566 14.2646462,7.632097 14.2646462,7.77980038 L14.2646462,9.17168759 C14.2646462,9.77640263 14.4530751,10.2881514 14.8299329,10.706934 C15.2067907,11.1239788 15.6963536,11.3333701 16.2986217,11.3333701 C17.0056703,11.3333701 17.5559883,11.0631598 17.9495758,10.5270834 C18.3440438,9.98926927 18.5412778,9.28029302 18.5412778,8.39928576 L18.5412778,8.39928576 Z"
                              id="Fill-5"
                            />
                            <path
                              d="M30.1719195,8.33151597 C30.1719195,9.74946848 29.7818541,10.8702765 29.0008427,11.6930713 C28.3008381,12.4255063 27.4326564,12.7912894 26.3954169,12.7912894 C25.2771707,12.7912894 24.4723856,12.3977033 23.9828226,11.6079246 L23.9828226,15.9825514 L22.1055777,15.9825514 L22.1055777,7.00566087 C22.1055777,6.11509634 22.081804,5.20194188 22.0360175,4.26532864 L23.6869716,4.26532864 L23.7917522,5.58770837 L23.8269725,5.58770837 C24.4521338,4.59114494 25.4022029,4.09329764 26.6780603,4.09329764 C27.6739158,4.09329764 28.5068772,4.48167066 29.1734224,5.25928555 C29.8373261,6.03863812 30.1719195,7.0621357 30.1719195,8.33151597 L30.1719195,8.33151597 Z M28.2585737,8.39928576 C28.2585737,7.58778598 28.0727863,6.91877653 27.7012116,6.3922574 C27.2961775,5.84401719 26.752023,5.56859381 26.0687481,5.56859381 C25.6056005,5.56859381 25.1847172,5.72151026 24.8069789,6.02386778 C24.4310016,6.32709414 24.1844592,6.7241556 24.0691125,7.21678983 C24.0118794,7.44529566 23.9810616,7.632097 23.9810616,7.77980038 L23.9810616,9.17168759 C23.9810616,9.77640263 24.170371,10.2881514 24.5454678,10.706934 C24.9223256,11.12311 25.4118885,11.3333701 26.0159176,11.3333701 C26.7238467,11.3333701 27.2741648,11.0631598 27.6668718,10.5270834 C28.0613397,9.98926927 28.2585737,9.28029302 28.2585737,8.39928576 L28.2585737,8.39928576 Z"
                              id="Fill-6"
                            />
                            <path
                              d="M41.037399,9.36022661 C41.037399,10.3437574 40.689598,11.1439622 39.995757,11.7617099 C39.2332363,12.4359324 38.1687011,12.7739125 36.8039124,12.7739125 C35.5430236,12.7739125 34.5321995,12.5341117 33.7696788,12.0536413 L34.2037696,10.5097065 C35.0270454,10.9901769 35.9313281,11.2308466 36.915737,11.2308466 C37.6236661,11.2308466 38.1739842,11.072717 38.5666911,10.7590646 C38.9611591,10.4436744 39.1592736,10.024023 39.1592736,9.49924157 C39.1592736,9.02919726 38.9954989,8.63474234 38.6714717,8.3141391 C38.3465639,7.99527356 37.8094535,7.69639141 37.0557379,7.42357457 C35.0041522,6.66854962 33.9774789,5.56338075 33.9774789,4.11154335 C33.9774789,3.16276631 34.3402485,2.38602027 35.0631463,1.7786987 C35.7869246,1.17224597 36.7466793,0.86901961 37.9406493,0.86901961 C39.0069456,0.86901961 39.895379,1.05234558 40.6006666,1.41812867 L40.1295944,2.92817858 C39.4656907,2.57369045 38.7163776,2.39644639 37.8781332,2.39644639 C37.2159905,2.39644639 36.6956098,2.55718243 36.3249155,2.87691682 C36.0123349,3.16276631 35.8529628,3.51117253 35.8529628,3.92474201 C35.8529628,4.38088482 36.0334671,4.75970056 36.3935952,5.05597618 C36.7052954,5.33053071 37.2741041,5.62854401 38.0964994,5.9482784 C39.1055626,6.34968407 39.8460705,6.81712185 40.3215453,7.3540671 C40.7996616,7.89101235 41.037399,8.56089064 41.037399,9.36022661"
                              id="Fill-7"
                            />
                            <path
                              d="M47.2599552,5.65547816 L45.1907594,5.65547816 L45.1907594,9.70341978 C45.1907594,10.7329993 45.5552901,11.2464857 46.2861124,11.2464857 C46.6215863,11.2464857 46.8998271,11.2186827 47.1199543,11.1604702 L47.1719044,12.5671278 C46.8020906,12.7035362 46.3151692,12.7721748 45.7129012,12.7721748 C44.9706322,12.7721748 44.3921378,12.5480132 43.9730156,12.1022965 C43.5556544,11.655711 43.3469738,10.9076368 43.3469738,9.85459854 L43.3469738,5.65200278 L42.1151419,5.65200278 L42.1151419,4.26185327 L43.3469738,4.26185327 L43.3469738,2.73529533 L45.1907594,2.18618628 L45.1907594,4.26185327 L47.2599552,4.26185327 L47.2599552,5.65547816"
                              id="Fill-8"
                            />
                            <path
                              d="M56.5775002,8.36540087 C56.5775002,9.64694495 56.2068059,10.6991144 55.464537,11.5219091 C54.6888086,12.3681626 53.6568522,12.7912894 52.3704288,12.7912894 C51.1289112,12.7912894 50.1418608,12.3855395 49.4057554,11.5740397 C48.66965,10.7625399 48.3015972,9.73904236 48.3015972,8.50441582 C48.3015972,7.21244561 48.6828576,6.1541943 49.4400952,5.33139955 C50.1990939,4.50860481 51.2222452,4.09590417 52.5086687,4.09590417 C53.7493057,4.09590417 54.7442807,4.50339175 55.4988768,5.31402268 C56.2191331,6.10206369 56.5775002,7.11774168 56.5775002,8.36540087 L56.5775002,8.36540087 Z M54.6315756,8.4079742 C54.6315756,7.64426081 54.4642789,6.98915285 54.128805,6.44091263 C53.7352175,5.77885393 53.1708113,5.44869342 52.44175,5.44869342 C51.6906759,5.44869342 51.1121816,5.77885393 50.7203551,6.44091263 C50.3831202,6.98915285 50.2167041,7.65555577 50.2167041,8.44272793 C50.2167041,9.20731017 50.3831202,9.86415581 50.7203551,10.4097895 C51.1262697,11.072717 51.6924369,11.4037464 52.4267813,11.4037464 C53.1435156,11.4037464 53.7061607,11.0648975 54.1138363,10.3941503 C54.4581153,9.83287745 54.6315756,9.17342527 54.6315756,8.4079742 L54.6315756,8.4079742 Z"
                              id="Fill-9"
                            />
                            <path
                              d="M62.6820682,5.89441011 C62.4962808,5.86052521 62.2972858,5.84401719 62.0903662,5.84401719 C61.4299846,5.84401719 60.9192894,6.08816219 60.5600418,6.58166527 C60.2474612,7.016087 60.0916111,7.56519605 60.0916111,8.2281236 L60.0916111,12.6027504 L58.2143662,12.6027504 L58.2143662,6.89097354 C58.2143662,5.93090153 58.1958755,5.05423849 58.1597746,4.26619749 L59.7948796,4.26619749 L59.8635593,5.86139405 L59.9155093,5.86139405 C60.1145043,5.31315384 60.4262045,4.87091253 60.8541318,4.54075202 C61.273254,4.24186987 61.7240745,4.09329764 62.210996,4.09329764 C62.3844562,4.09329764 62.5411868,4.10633029 62.6803072,4.12718253 L62.6820682,5.89441011"
                              id="Fill-10"
                            />
                            <path
                              d="M71.0777203,8.03958458 C71.0777203,8.37148277 71.0557076,8.65125036 71.0090406,8.87975619 L65.3781864,8.87975619 C65.3984381,9.70341978 65.6722763,10.3342001 66.1944181,10.7686218 C66.6690124,11.156126 67.2836076,11.350747 68.0364427,11.350747 C68.8702846,11.350747 69.6310442,11.2204204 70.3151996,10.956292 L70.6092896,12.2421803 C69.8097876,12.5853735 68.8650015,12.7574045 67.7766926,12.7574045 C66.4664953,12.7574045 65.438061,12.3768511 64.6896284,11.6174819 C63.9438374,10.8572439 63.5687406,9.83461514 63.5687406,8.55567758 C63.5687406,7.29932996 63.9156611,6.25237361 64.6130242,5.41741505 C65.3420855,4.52598168 66.3273749,4.08026499 67.5671314,4.08026499 C68.7848752,4.08026499 69.706768,4.52598168 70.3319293,5.41741505 C70.8285363,6.12378478 71.0777203,6.99957897 71.0777203,8.03958458 L71.0777203,8.03958458 Z M69.2876458,7.55911415 C69.3008534,7.00913625 69.1775822,6.53648541 68.9231151,6.13507974 C68.5982073,5.61898673 68.0980783,5.36180907 67.428011,5.36180907 C66.8134158,5.36180907 66.3141673,5.61290483 65.9337875,6.11683403 C65.6212068,6.51737086 65.4354195,6.99957897 65.3781864,7.55737646 L69.2876458,7.55911415 L69.2876458,7.55911415 Z"
                              id="Fill-11"
                            />
                          </g>
                          <g
                            id="Available-on-the"
                            transform="translate(27.444444, 0.222222)"
                          >
                            <path
                              d="M5.74792773,7.03658129 L4.75982626,7.03658129 L4.21933387,5.31398068 L2.33955264,5.31398068 L1.82382914,7.03658129 L0.862265755,7.03658129 L2.72523952,1.17113069 L3.87522333,1.17113069 L5.74792773,7.03658129 L5.74792773,7.03658129 Z M4.05656693,4.59122373 L3.56738151,3.05962465 C3.51607454,2.90269851 3.42142203,2.53773315 3.27546254,1.95486465 L3.25777048,1.95486465 C3.19761748,2.20684319 3.111811,2.57270526 2.98265897,3.05962465 L2.50231958,4.59122373 L4.05656693,4.59122373 L4.05656693,4.59122373 Z"
                              id="Fill-12"
                            />
                            <path
                              d="M10.4062467,2.80764611 L8.82634592,7.03658129 L7.92493553,7.03658129 L6.39634167,2.80764611 L7.39240457,2.80764611 L8.10539453,5.04406772 C8.22570053,5.41800029 8.32831447,5.77489516 8.40527492,6.11385561 L8.43092841,6.11385561 C8.49992744,5.80986727 8.60254138,5.4529724 8.7308088,5.04406772 L9.43495273,2.80764611 L10.4062467,2.80764611"
                              id="Fill-13"
                            />
                            <path
                              d="M13.7695071,7.03658129 L13.7005081,6.54966191 L13.6748546,6.54966191 C13.3917816,6.94152889 12.9795567,7.13253041 12.4558717,7.13253041 C11.7092669,7.13253041 11.1767359,6.60167171 11.1767359,5.88788198 C11.1767359,4.84409899 12.0693003,4.30427308 13.6147016,4.30427308 L13.6147016,4.22625838 C13.6147016,3.66939477 13.3227826,3.39051461 12.7477907,3.39051461 C12.3355657,3.39051461 11.9746477,3.49543094 11.6579599,3.70436688 L11.4695395,3.08652627 C11.8561109,2.84261822 12.3364503,2.72066419 12.9034808,2.72066419 C13.9933116,2.72066419 14.5435346,3.3035327 14.5435346,4.47016643 L14.5435346,6.02777042 C14.5435346,6.45460951 14.5612267,6.78549947 14.6036876,7.03747801 L13.7695071,7.03658129 L13.7695071,7.03658129 Z M13.6403551,4.93018419 C12.6097926,4.93018419 12.0949537,5.18305944 12.0949537,5.78296564 C12.0949537,6.22684243 12.3612192,6.44384886 12.7300986,6.44384886 C13.2024766,6.44384886 13.6403551,6.0788835 13.6403551,5.58299691 L13.6403551,4.93018419 L13.6403551,4.93018419 Z"
                              id="Fill-14"
                            />
                            <path
                              d="M16.6081979,2.11089406 C16.2994715,2.11089406 16.0588595,1.86698601 16.0588595,1.54506325 C16.0588595,1.2231405 16.3074329,0.988199652 16.6258899,0.988199652 C16.9434624,0.988199652 17.2008818,1.2231405 17.1920358,1.54506325 C17.1920358,1.88492043 16.9523084,2.11089406 16.6081979,2.11089406 L16.6081979,2.11089406 Z M16.162358,2.80764611 L17.0894219,2.80764611 L17.0894219,7.03658129 L16.162358,7.03658129 L16.162358,2.80764611 Z"
                              id="Fill-15"
                            />
                            <polygon
                              id="Fill-16"
                              points="18.7365525 0.867142348 19.6636164 0.867142348 19.6636164 7.03658129 18.7365525 7.03658129"
                            />
                            <path
                              d="M23.6036378,7.03658129 L23.5337542,6.54966191 L23.5089853,6.54966191 C23.2259124,6.94152889 22.8136874,7.13253041 22.2900025,7.13253041 C21.5433976,7.13253041 21.0108666,6.60167171 21.0108666,5.88788198 C21.0108666,4.84409899 21.903431,4.30427308 23.4488323,4.30427308 L23.4488323,4.22625838 C23.4488323,3.66939477 23.1569134,3.39051461 22.5819215,3.39051461 C22.1696965,3.39051461 21.8087785,3.49543094 21.4920906,3.70436688 L21.3027856,3.08562955 C21.6893571,2.8417215 22.1696965,2.71976747 22.736727,2.71976747 C23.8265578,2.71976747 24.3776654,3.30263598 24.3776654,4.46926971 L24.3776654,6.0268737 C24.3776654,6.45371279 24.3953575,6.78460275 24.4360492,7.03658129 L23.6036378,7.03658129 L23.6036378,7.03658129 Z M23.4744858,4.93018419 C22.4439234,4.93018419 21.9290845,5.18305944 21.9290845,5.78296564 C21.9290845,6.22684243 22.19535,6.44384886 22.5642294,6.44384886 C23.0366074,6.44384886 23.4744858,6.0788835 23.4744858,5.58299691 L23.4744858,4.93018419 L23.4744858,4.93018419 Z"
                              id="Fill-17"
                            />
                            <path
                              d="M28.1328048,7.13253041 C27.5410055,7.13253041 27.1040116,6.87965515 26.8200541,6.38376856 L26.802362,6.38376856 L26.7492858,7.03658129 L25.96022,7.03658129 C25.9849889,6.69762084 25.9938349,6.31382434 25.9938349,5.89684918 L25.9938349,0.867142348 L26.922668,0.867142348 L26.922668,3.42638344 L26.9394755,3.42638344 C27.214587,2.95650176 27.6604269,2.72156091 28.2699183,2.72156091 C29.2748272,2.72156091 29.9789711,3.59227679 29.9789711,4.86203341 C29.9789711,6.17483262 29.1969821,7.13253041 28.1328048,7.13253041 L28.1328048,7.13253041 Z M27.9443844,3.45956211 C27.4109688,3.45956211 26.922668,3.9294438 26.922668,4.58225652 L26.922668,5.32205117 C26.922668,5.90491967 27.3614311,6.38376856 27.9275769,6.38376856 C28.6219902,6.38376856 29.0359844,5.80986727 29.0359844,4.8961088 C29.0350998,4.04332734 28.6051828,3.45956211 27.9443844,3.45956211 L27.9443844,3.45956211 Z"
                              id="Fill-18"
                            />
                            <polygon
                              id="Fill-19"
                              points="31.3509903 0.867142348 32.2771695 0.867142348 32.2771695 7.03658129 31.3509903 7.03658129"
                            />
                            <path
                              d="M37.3229446,5.14808733 L34.5399838,5.14808733 C34.5585605,5.94885899 35.0804762,6.40080626 35.8527346,6.40080626 C36.2658441,6.40080626 36.6435696,6.33086204 36.9779495,6.20083753 L37.1221398,6.85365025 C36.7276069,7.02761408 36.2640749,7.114596 35.7235826,7.114596 C34.4179086,7.114596 33.6456503,6.27885223 33.6456503,4.98309071 C33.6456503,3.68643247 34.4364853,2.71169698 35.620084,2.71169698 C36.6833767,2.71169698 37.3547904,3.51246864 37.3547904,4.72214497 C37.3574442,4.88714159 37.3494827,5.03510052 37.3229446,5.14808733 L37.3229446,5.14808733 Z M36.4737258,4.47823691 C36.4737258,3.82542419 36.1464228,3.36450971 35.5537388,3.36450971 C35.0212078,3.36450971 34.601906,3.8343914 34.5399838,4.47823691 L36.4737258,4.47823691 L36.4737258,4.47823691 Z"
                              id="Fill-20"
                            />
                            <path
                              d="M42.9419424,7.13253041 C41.7229596,7.13253041 40.9330092,6.20980473 40.9330092,4.95708581 C40.9330092,3.65146036 41.7397671,2.72066419 43.0118261,2.72066419 C44.2122323,2.72066419 45.0207593,3.59945055 45.0207593,4.88803831 C45.0207593,6.20980473 44.1874634,7.13253041 42.9419424,7.13253041 L42.9419424,7.13253041 Z M42.9773266,3.40755231 C42.3076821,3.40755231 41.8786497,4.04243062 41.8786497,4.93018419 C41.8786497,5.80090006 42.3165282,6.43577837 42.9684805,6.43577837 C43.6204329,6.43577837 44.0583113,5.75696074 44.0583113,4.91224977 C44.0583113,4.05139782 43.6292789,3.40755231 42.9773266,3.40755231 L42.9773266,3.40755231 Z"
                              id="Fill-21"
                            />
                            <path
                              d="M50.0046122,7.03658129 L49.0793176,7.03658129 L49.0793176,4.60826143 C49.0793176,3.8603963 48.79536,3.48556702 48.2371756,3.48556702 C47.6878372,3.48556702 47.3092271,3.96441591 47.3092271,4.52127951 L47.3092271,7.03658129 L46.3839324,7.03658129 L46.3839324,4.01642571 C46.3839324,3.64249315 46.3733172,3.24165896 46.3485483,2.80674939 L47.1641522,2.80674939 L47.2074978,3.45956211 L47.2340359,3.45956211 C47.4808401,3.00761484 47.9886022,2.71976747 48.5556326,2.71976747 C49.4296203,2.71976747 50.0046122,3.3985851 50.0046122,4.5033451 L50.0046122,7.03658129"
                              id="Fill-22"
                            />
                            <path
                              d="M56.0703345,3.51246864 L55.0503873,3.51246864 L55.0503873,5.56595922 C55.0503873,6.08874743 55.2299617,6.34969318 55.5899951,6.34969318 C55.7527621,6.34969318 55.8916447,6.33175876 56.0022201,6.30575386 L56.0278736,7.0195436 C55.8474146,7.08948782 55.605918,7.12445993 55.3078068,7.12445993 C54.5762402,7.12445993 54.1410155,6.71555525 54.1410155,5.64487064 L54.1410155,3.51246864 L53.5332933,3.51246864 L53.5332933,2.80764611 L54.1410155,2.80764611 L54.1410155,2.03287936 L55.0503873,1.7539992 L55.0503873,2.80674939 L56.0703345,2.80674939 L56.0703345,3.51246864"
                              id="Fill-23"
                            />
                            <path
                              d="M60.9798808,7.03658129 L60.0519323,7.03658129 L60.0519323,4.62619584 C60.0519323,3.86936351 59.7679747,3.48646374 59.2106749,3.48646374 C58.7303355,3.48646374 58.2844956,3.8173537 58.2844956,4.48720412 L58.2844956,7.03658129 L57.3565472,7.03658129 L57.3565472,0.867142348 L58.2844956,0.867142348 L58.2844956,3.40755231 L58.3021877,3.40755231 C58.5932221,2.94663783 59.0151777,2.71976747 59.55567,2.71976747 C60.4393884,2.71976747 60.9798808,3.41562279 60.9798808,4.52127951 L60.9798808,7.03658129"
                              id="Fill-24"
                            />
                            <path
                              d="M65.7169294,5.14808733 L62.9348532,5.14808733 C62.9525453,5.94885899 63.474461,6.40080626 64.247604,6.40080626 C64.6615981,6.40080626 65.038439,6.33086204 65.3710497,6.20083753 L65.5161246,6.85365025 C65.1224763,7.02761408 64.6580597,7.114596 64.1166827,7.114596 C62.8118934,7.114596 62.0396351,6.27885223 62.0396351,4.98309071 C62.0396351,3.68643247 62.8295855,2.71169698 64.0131842,2.71169698 C65.0773615,2.71169698 65.7478905,3.51246864 65.7478905,4.72214497 C65.7514289,4.88714159 65.7425829,5.03510052 65.7169294,5.14808733 L65.7169294,5.14808733 Z M64.866826,4.47823691 C64.866826,3.82542419 64.5412921,3.36450971 63.9486082,3.36450971 C63.4160772,3.36450971 62.9958908,3.8343914 62.9348532,4.47823691 L64.866826,4.47823691 L64.866826,4.47823691 Z"
                              id="Fill-25"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                </a>
              </div>
              
            <Link  to="/signup" style={{ textDecoration: "none" }}>
              <div onClick={() => amplitude.getInstance().logEvent('signup_landing')} className="go-to">
                <h1
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    fontWeight: "300",
                    textDecoration: "none",
                  }}
                >
                  Sign Up on Desktop
                </h1>
                <ArrowForwardIcon style={{ color: "white" }} />
              </div>
            </Link>
          </div>
          {/*<h4 className="Top-CTA-subtitle" style={{marginTop: '0px', marginBottom: '15px'}}>Start Trading any NFL, NBA, NHL, MLB, or CFB team stock for just $1.</h4>*/}
          {error && <div style={{ color: "red" }}>Error: {errorMsg}</div>}
        </div>
        <div className="Top-Img">
          <div>
            <img className="op-img" src={Hero} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Top;
