import React, {useState, useRef, useEffect} from 'react'
import '../landingPage.css'
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom'
import {signup} from '../../../routes/auth/auth'
import {getToken} from '../../../redux/actions/token'
import {firebaseAnalytics} from '../../../services/firebase'
import moment from 'moment'

const Top = () => {

    let bengals_bitcoin_sp = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ftop_landing_page.png?alt=media&token=5a6eb76a-a831-47e2-bbed-bd7008e083b8'

    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const emailField = useRef(null)
    const passwordField = useRef(null)

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    let history = useHistory()
    const dispatch = useDispatch()
    
    const handleEmail = (e) => {
        if(username === email && (!e.target.value.includes('@') && !email.includes('@'))){
            setUsername(e.target.value)
        }
        setEmail(e.target.value)
        
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSignupRes = (res) => {
        let carry
        //mail with no '@', already used email
        //short(long) username, already used username
        //short password
        if(res.status === 200){
            carry = {type: 'success', data: res} //res returns {refresh_token: 'token', access_token: 'token'}
        }else if(res.status === 400){
            if(res.data && res.data.msg && !res.data.msg.query){ //already used email -or- already user username
                carry = {type: 'err', msg: res.data.msg}
                if(carry.msg = 'username is taken'){
                    let rand = (Math.random() * 1000).toString().substring(0,3)
                    signup(email, `user${rand}`, password, 'First', 'Last').then(res => {
                        let result = handleSignupRes(res)
                        if(result.type === 'success'){
                            dispatch(getToken(res))
                            firebaseAnalytics.logEvent('signup_ads')
                            history.replace('/')
                        }
                        else if(result.type === 'err'){
                            setError(true)
                            setErrorMsg(result.msg)
                        }
                        else {
                            setError(true)
                            setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
                        }
                    })
                    }
            }
            else if(/*res.data.msg.query.email[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.email && res.data.msg.query.email[0]){
                carry = {type: 'err', msg: res.data.msg.query.email[0]}//short(long) or missing email
            }
            else if(/*res.data.msg.query.username[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.username && res.data.msg.query.username[0]){
                carry = {type: 'err', msg: `Username ${res.data.msg.query.username[0]}`} //short(long) or missing username
                let rand = (Math.random() * 1000).substring(0,3)
                signup(email, `user${rand}`, password, 'First', 'Last').then(res => {
                    let result = handleSignupRes(res)
                    if(result.type === 'success'){
                        dispatch(getToken(res))
                        firebaseAnalytics.logEvent('signup_ads')
                        history.replace('/')
                    }
                    else if(result.type === 'err'){
                        setError(true)
                        setErrorMsg(result.msg)
                    }
                    else {
                        setError(true)
                        setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
                    }
                })
            }
            else if(/*res.data.msg.query.password[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.password && res.data.msg.query.password[0]){
                carry = {type: 'err', msg: `Password ${res.data.msg.query.password[0]}`} //short(long) or missing password
            }
            else if(/*res.data.msg.query.first_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.first_name && res.data.msg.query.first_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.first_name[0]} //short(long) or missing first_name
            }
            else if(/*res.data.msg.query.last_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.last_name && res.data.msg.query.last_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.last_name[0]} //short(long) or missing last_name
            }
        }
        else if(res.access_token && res.refresh_token){
            carry = {type: 'success', data: res}
        }
        else if(res.status === 500){
            carry = {type: 'err', msg: 'Error, please refresh'}
        }
        else {
            carry = {type: 'err', msg: 'Unknown Error, please refresh and try again or contact us at customer-service@simbull.app'}
        }
        return carry
    }

    const handleClick = (e) => {

        signup(email, username, password, 'First', 'Last').then(res => {
            let result = handleSignupRes(res)
            if(result.type === 'success'){
                dispatch(getToken(res))
                firebaseAnalytics.logEvent('signup_ads')
                history.replace('/')
            }
            else if(result.type === 'err'){
                setError(true)
                setErrorMsg(result.msg)
            }
            else {
                setError(true)
                setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
            }
        })
        
        /*else{
            alert('Please fill out both fields.')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
        }*/
    }


    //capture autofill 
    useEffect(() => {
        let interval = setInterval(() => {
          if (emailField.current) {
            setEmail(emailField.current.value)
            setPassword(passwordField.current.value)
            //do the same for all autofilled fields
            clearInterval(interval)
          }
        }, 100)
        
    })

    return(
        <>
        <div className="Top">
            <div className="Top-CTA">
                <h1 className="Top-CTA-title2">{(moment().format('YYYYMMDD') >= '20220313') ? 'Your picks are in.' : `While you're here...`}</h1>

                {false && <><h4 className="Top-CTA-subtitle">Get $10 in any NFL, NBA, NHL, MLB, or CFB team stock just for signing up.</h4>
                <div className="Top-CTA-email">Enter your email:</div>
                <input className="Top-CTA-input" placeholder="your@email.com" />
                <button className="Top-CTA-btn">Sign Up</button> </>}

                <h4 className="Top-CTA-subtitle2" style={{marginTop: '0px', marginBottom: '15px', lineHeight: '1.5'}}>SimBull is the only place where you can buy {'&'} sell sports teams like stocks. </h4>
                
                {true && <h4 className="Top-CTA-subtitle" style={{marginTop: '0px', marginBottom: '15px', fontSize: '.9rem', fontWeight: '400', lineHeight: '1.5'}}>Get the first $10 free {'&'} try it out now. Join <strong>12,000+ bullish investors</strong> and see why experts are calling the <strong>Stock Market for Sports</strong> the future of investing</h4>}
                <button className="Top-CTA-btn2" style={{backgroundColor: '#e0cb07', color: 'black', }} onClick={()=>history.push('/Portfolio')}>Claim Free Stock <i class="fa fa-arrow-right" style={{fontSize: '1em', marginLeft: '5px', fontWeight: '400'}}></i></button>
                
                <div className="Top-CTA-free" style={{textAlign: 'center'}}>No Sign-Up. No Credit Card. No B.S.</div>
            </div>
            <div className="Top-Img">
                <div>
                    <img className="top-img-src" src={bengals_bitcoin_sp} />
                </div>
            </div>
        </div>
        </>
    )
}

export default Top