import React, {useEffect, useState} from 'react'
import { db } from '../../services/firebase'
import './Account.css'
import Header from './Header'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import WithdrawReceipt from '../Receipts/WithdrawReceipt'
import WithdrawConfirm from '../Receipts/WithdrawConfirm'
import ReactPixel from 'react-facebook-pixel'
import {postWithdraw} from '../../routes/transfers/withdraw'
import Popup from 'reactjs-popup'
import logo1 from '../assets/Logo1.png'

const WithdrawDesktop = (props) => {
    //props
    const user = props.user
    const history = useHistory()

    //states
    const [amount, setAmount] = useState(0)
    const [paypalEmail, setPaypalEmail] = useState('')
    const [text, setText] = useState("")
    const [show, setShow] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [deposit, setDeposit] = useState(false)

    const [totals, setTotals] = useState(props.totals ? props.totals : {})
    useEffect(()=>{
        if(totals === {} && props.totals){
            setTotals(props.totals)
        }
    },[props.totals])

    const hide = () => {
        setShow(false)
    }
    const showShow = () => {
        setShow(true)
    }
    const hideConfirm = () => {
        setConfirm(false)
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    //Functionality
    const submitWithdraw = () => {
        if(parseFloat(amount) < 15){
            setError(true)
            setErrorMsg(`Sorry, we have a minimum withdrawl of $15`)
        }
        else if(!paypalEmail){
            setError(true)
            setErrorMsg('Please enter valid email')
        }
        else if(totals.cash_balance && totals.cash_balance >= parseFloat(amount)){
            let fee = parseFloat(amount) * 0.03
            let getting = parseFloat(amount) * .97
            postWithdraw(props.token, parseFloat(amount), paypalEmail).then(res => {
                if(res.response){
                    if(res.response.data && res.response.data.msg){
                        setError(true)
                        setErrorMsg(res.response.data.msg)
                    }
                }else if(res.paypal_email){
                    setConfirm(false)
                    setShow(true)
                }
            })
             
        } else{ 
            setError(true)
            setErrorMsg('Not enough cash')
        }
    }


    const addDigit = (digit) => {
        if(digit !== '$'){
            setAmount(digit)   
        }
    }


    const doConfirm = () => {
        if(parseFloat(amount) < 15){
            setError(true)
            setErrorMsg(`Sorry, we have a minimum withdrawl of $15`)
        }
        else if(!paypalEmail){
            setError(true)
            setErrorMsg('Please enter valid email')
        }
        else if(totals.cash_balance && (totals.cash_balance >= parseFloat(amount))){
            let fee = parseFloat(amount) * 0.03
            let getting = parseFloat(amount) * .97
            setConfirm(true)
        }
        else{
            setError(true)
            setErrorMsg('Not enough cash')
        }
        
    }

    const inputStyle = {
        marginRight: '5%',
        transform: 'scale(2)',
        marginBottom: '5%'
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    

    return(
        <>
        
        <div style={{backgroundColor: 'black', paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', paddingBottom: isMobile?'7vh':'200px', textAlign: 'center' }}>
                
                {isMobile ? 
                    <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '2vh', textAlign: 'left'}}>
                        <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                        <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                            <span style={{}}>Withdraw</span>
                        </div>
                    </div>
                : null}

                <div style={{backgroundColor: 'rgb(30,30,30)', display: 'inline-block', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '2rem', marginBottom: '12px'}}>
                    <div style={{display: 'inline-block'}}><button onClick={()=>history.push('/Deposit')} style={{border: 'none', outline: 'none', borderRadius: '1rem', color: 'gray', backgroundColor: 'rgb(30,30,30)', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Deposit</button></div>
                    <div style={{display: 'inline-block'}}><button style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: '#c4b454', color: 'black', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Withdraw</button></div>
                </div>
                <div className='transfer-deposit-head' style={{color: 'white'}}>One-Time Withdrawal</div>
                <span style={{color: 'white'}}>You will receive funds in a paypal account within one business day.</span>
                <br />
                <br />
                <div style={{color: 'gray', marginTop: '12px'}}>Please enter your PayPal account email where you can collect the funds:<br /><input className="withdraw-body-input" style={{backgroundColor: 'rgb(80,80,80)', color: 'white', border: 'none', outline: 'none', width: isMobile ? '80%':'60%'}} value={paypalEmail} onChange={(e)=>{setPaypalEmail(e.target.value)}} placeholder="yourname@email.com" /></div><br />
                <div className='withdraw-cash' style={{color: 'gray'}}>Minimum: $15</div>
                <div className='withdraw-cash' style={{color: 'gray'}}>Cash Availble: ${totals && totals.cash_balance.toFixed(2)}</div>
                    <input className="withdraw-input" style={{backgroundColor: 'rgb(80,80,80)', color: 'white', padding: '20px', outline: 'none', border: 'none'}} value={amount} onChange={(e)=>{addDigit(e.target.value)}} />
                    <br />
                    
                    <br />
                    <div className='withdraw-cash' style={{color: 'gray'}}>Bonuses can only be cashed out after maintaining an account balance of an average of $100 for 90 days with $100 in trading volume.</div>
                    <br />
                    <div className='withdraw-cash' style={{color: 'gray'}}>Processing Fee: 3% </div>
                    
                    <br />
                    {error && <div style={{textAlign: 'center', color: 'red'}}>{errorMsg}</div>}
                    <button onClick={doConfirm} style={{backgroundColor: '#c4b454', borderRadius: '1rem', paddingLeft: '40px', paddingRight: '40px', paddingTop: '8px', paddingBottom: '8px', outline: 'none', border: 'none'}}>Submit Withdrawal</button>
                </div>
            {show && <WithdrawReceipt amount={amount} text={text} hide={hide} paypalEmail={paypalEmail} />}
            

            <Popup open={confirm} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(30,30,30)', border: '5px solid black', boxShadow: '0 0 2px 4px #c5b358',}}>
                    <div style={{textAlign: 'center', fontSize: '2.5em', color: 'white'}}>Confirm Withdrawal</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Withdrawl Amount</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500', color: 'white'}}>${(parseFloat(amount).toFixed(2))}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Withdrawl Fee (3%) </div>
                        <div style={{fontSize: '1.5em', fontWeight: '500', color: 'white'}}>${(parseFloat(amount*.03).toFixed(2))}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Total Pending</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500', color: 'white'}}>${(parseFloat(amount*.97).toFixed(2))}</div>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={submitWithdraw} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Confirm</button><br />
                            <button onClick={()=>setConfirm(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', backgroundColor: 'rgb(30,30,30)', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>
                    </div>
                </div>
            </Popup>

        
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token,
    totals: state.totals.totals
});

export default connect(mapStateToProps)(withRouter(WithdrawDesktop))