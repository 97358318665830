import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import {getAllTradesByTeam} from '../../../routes/trading/trading'

const Trades = (props) => {

    const user = props.user

    const team = props.team

    const [trades, setTrades] = useState([])

    const getTrades = () => {
        if(team.team && team.team.id && trades.length === 0){
            let team_id = team.team.id
            let times = []
            let timesObj = {}
            let transactions1 = []
            getAllTradesByTeam(team_id, 20).then(res => {
                let arr = res
                let _buys = 0;
                let _sells = 0;
                res.map((t) => {
                    if (t.type === "BUY") {
                    _buys += t.amount * t.price;
                    } else if (t.type === "SELL") {
                    _sells += t.amount * t.price;
                    }
                });
                props.updateTradeBuyPercent(_buys / (_buys + _sells));
                arr.sort((a,b) => {
                    if(b.timestamp > a.timestamp){
                        return 1
                    }
                    else return -1
                })
                arr.map(t => {
                    if(!times.includes(t.timestamp)){
                        times.push(t.timestamp)
                        timesObj[t.timestamp] = t
                        timesObj[t.timestamp].fullPaid = (t.amount * t.price)
                    }
                    else{
                        timesObj[t.timestamp].fullPaid += (t.amount * t.price)
                        timesObj[t.timestamp].amount += t.amount
                    }
                })
                times.map(t => {
                    transactions1.push(timesObj[t])
                })
                if(arr.length > 5){
                    setTrades(arr.slice(0,6))
                }
                else{
                    setTrades(arr)
                }
            }).catch(err => console.log(err))
        }
    }
    useEffect(()=>{
        if(team && team.team && team.team.id){
            getTrades()
        }
    },[team])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    return(
        <>
            <div style={{marginTop: '15px', paddingLeft: '0%', paddingRight: '1%', fontWeight: '300', color: 'white'}}>
                <div  style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px', }}>Latest Market Trades</div>
                
                {trades && trades.length > 0 && trades.map(t => {
                    return(
                        <div key={t.id}>
                            <div key={t.share} style={{display: 'inline-block', width: '70%', paddingLeft: '1%'}}>
                                <div style={{fontSize: '1.3em'}}>{t.type === 'BUY' ? 'Buy: ' : 'Sell:'}{' '}Sim{team.team.name}</div>
                                <div style={{fontSize: '0.8em', color: 'gray'}}>{t.timestamp.substring(5,7)}/{t.timestamp.substring(8,10)}/{t.timestamp.substring(2,4)}</div>
                            </div>
                            <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '1%'}}>
                                <div style={{fontSize: '1.15em'}}>${(t.price * t.amount).toFixed(2)}</div>
                                <div style={{fontSize: '.75em', color: 'gray'}}>{t.amount.toFixed(2)} {isMobile ? 'x ': 'Shares at '}${t.price.toFixed(2)} {isMobile ? '': 'per Share'}</div>
                            </div>
                            <hr style={{margin: '0', padding: '0', marginBottom: '5px', marginTop: '5px', backgroundColor: 'rgb(30,30,30)'}} />
                        </div>
                    )
                }) }
                
            </div>
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token
});

export default connect(mapStateToProps)(Trades)
