import React, {useState, useEffect} from 'react'
import './SimBull16.css'
import {firebaseAnalytics} from '../../services/firebase'

const Rules = () => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(()=>{
      firebaseAnalytics.logEvent('SimBull16_Rules_visit')
    },[])

    return(<>
          <div style={{textAlign: 'center'}}><img style={{width: isMobile ? '100%' : '80%'}} src='https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FScreen%20Shot%202022-03-01%20at%201.56.57%20PM.png?alt=media&token=07f88c28-b8a1-4e12-ad80-5fd3d1dc1179' /></div>
        <div style={{padding: '15px', backgroundColor: 'rgb(240,240,240)', fontFamily: 'Roboto Slab, serif', paddingLeft: isMobile ? '15px' : '25%', paddingRight: isMobile ? '15px' : '25%'}}>
        
        {/*<ul>
          <li><span style={{color: '#212529'}}>Pick 1 Team for Each Seed before Noon EST March 17th, 2022</span></li>
          <li><span style={{color: '#212529'}}>Grand Prize of $25,000 if all your teams win in the First Round </span><span style={{color: '#b7b7b7'}}>(March 17-18)</span></li>
          <li><span style={{color: '#212529'}}>1 Win = 1 Point throughout the entirety of the tournament</span></li>
          <li><span style={{color: '#212529'}}>Each Player gets two (2) Re-Picks between the end of the Second Round and the start of the Sweet Sixteen </span><span style={{color: '#b7b7b7'}}>(March 21-24)</span></li>
          <li><span style={{color: '#212529'}}>Payouts:</span></li>
          <ul>
            <li><span style={{color: '#212529'}}>1st Place in Total Points wins $1,000</span></li>
            <li><span style={{color: '#212529'}}>2nd Place wins $250</span></li>
            <li><span style={{color: '#212529'}}>3rd-5th Place wins $100</span></li>
            <li><span style={{color: '#212529'}}>6th-10th Place wins $50</span></li>
            <li><span style={{color: '#212529'}}>11th-50th wins $20</span></li>
            <li><span style={{color: '#212529'}}>51st-100th wins $10</span></li>
          </ul>
        </ul>*/}
        <div style={{fontSize: '.6em', paddingTop: '30px', paddingBottom: '10vh'}}>
        <p><span style={{color: '#212529'}}>TERMS AND CONDITIONS (includes the 'Rules' below):&nbsp;</span><br />
        <span style={{color: '#212529'}}>All participants (herein “participant(s)” or “entrant(s)”) agree to abide by these terms and conditions (including the “RULES”) and any legal disclaimers below, and by the decisions of SimBull Sports Exchange, Inc, (“SimBull”) an official corporation incorporated under the laws of Delaware.</span><br />
        <span style={{color: '#212529'}}>These terms are final and binding on all matters pertaining to the “SimBull 16 March Madness Pick’em” (herein “Challenge”). Any and all legal disputes arising from the Challenge will be resolved in Delaware courts under the laws of the state chosen by SimBull.</span><br />
        <span style={{color: '#212529'}}>Must be 18 years or older to enter. Entries by individuals under the age of 18 years old will be automatically void. Entrants must be able to prove their age if SimBull requests verification.</span><br />
        <span style={{color: '#212529'}}>This Challenge is in no way affiliated with the National College Athletic Association, Any Colleges or Universities, Tik Tok, Facebook, Instagram, Twitter, or any other business.</span><br />
        <span style={{color: '#212529'}}>Except where prohibited by law, a Winner's acceptance of a Prize constitutes permission for the SimBull to use Winner's name, photograph, likeness, statements, biographical information, voice, and address (city and state) worldwide and in all forms of media, in perpetuity, without further compensation. Entrants agree to SimBull’s use of their names and likeness for advertising and publicity purposes without additional compensation, unless prohibited by law. All federal, state, local laws and regulations apply.</span><br />
        <span style={{color: '#212529'}}>Any and all taxes, if any, in relation to acceptance of a Prize are the sole responsibility of each Winner. No substitution or transfer of a Prize is permitted except by CBSI. CBSI reserves the right to substitute a Prize for one of equal or greater value.</span><br />
        <span style={{color: '#212529'}}>RULES:</span><br />
        <span style={{color: '#212529'}}>1. Maximum of one entry/submission per person (this means a person may only create one official bracket). Multiple submissions by the same person makes their entry automatically void. Creating more than one email address by the same person with the purpose of submitting multiple entries is a violation of state and federal law and is prohibited by the Rules of this Challenge</span><br />
        <span style={{color: '#212529'}}>2. College basketball game picks must occur prior to the start of each respective game to be valid.</span><br />
        <span style={{color: '#212529'}}>3. For every game picked correctly, participants will earn a ‘win’..</span><br />
        <span style={{color: '#212529'}}>4. To win the “Grand Prize” of the Challenge, a participant must correctly pick sixteen (16) NCAA Tournament games in the Round of 64 with each team being a unique seed. If a pick made&nbsp; ties the outcome of a game, that pick will count as a loss. There will be one “Grand Prize” winner of the Challenge, unless more than one participant correctly picks the sixteen games (a “tie”). If a tie occurs, the “Grand Prize” will be split equally among the winners.</span><br />
        <span style={{color: '#212529'}}>5. The “Grand Prize” is $25,000 in “SimBull Cash” paid out in equal installments over 12 months..&nbsp;</span><br />
        <span style={{color: '#212529'}}>6. “SimBull Cash” is money that will be deposited into a participant’s SimBull account which can be used to buy and sell teams in the SimBull Market at the participants discretion.</span><br />
        <span style={{color: '#212529'}}>7. Grand Prize winnings $25,000 in SimBull Cash MUST be held in the participant’s SimBull account for at least 245 days before a participant has the opportunity to withdraw the money into their personal bank account.</span><br />
        <span style={{color: '#212529'}}>8. Participants are also eligible to win a prize in SimBull Cash&nbsp; based on their total number&nbsp; of wins from their teams picked in the Challenge. 1st Place will receive $1000. 2nd Place will receive $250. 3rd-5th Place will receive $100. 6th-10th Place will receive $50. 11th-50th will receive $20. 51st-100th Place will receive $10.&nbsp;</span><br />
        <span style={{color: '#212529'}}>9. Participants must provide an email address and will be notified of their winnings by April 14th, 2022 through email. All participants grant SimBull access to contact them through email. If the recipient does not claim their prize by May 15th, 2022, the prize will no longer be available.</span><br />
        <span style={{color: '#212529'}}>10. SimBull reserves the right to modify, suspend, cancel or terminate in the event that non-authorized human intervention, a bug or virus, fraud, or other causes beyond reasonable control impact or corrupt the security, fairness, proper conduct, or administration.</span><br />
        <span style={{color: '#212529'}}>11. Participants may be removed at any time from the Challenge at the discretion of SimBull.</span><br />
        <span style={{color: '#212529'}}>Any dispute or questions can be made to customer-service@simbull.app.</span></p>
        </div>
      </div>
    </>)
}

export default Rules