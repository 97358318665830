import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import './Spins.css'



const Head = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    let logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa'

    return(
        <div className='Head' style={{backgroundColor: 'black', marginTop:'0px', paddingTop: '10px', paddingBottom: '10px'}}>

            <div style={{textAlign: 'center', fontSize: '1em', fontWeight: '300', marginTop: '15px', color: 'white'}}>Spins Sponsored By</div>
            <a href="https://fanatics.93n6tx.net/ORyG2r">
            <div>
                <img src={logo} style={{width: '80%', marginLeft: '10%', marginTop: '10px'}} />
            </div>
            {/*<div style={{textAlign: 'center', fontWeight: '200', width: '80%', marginLeft: '10%', marginTop: '10px', color: 'gray'}}>Click here to start shopping at Fanatics.com for Free Shipping over $99</div>*/}
            </a>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Head)