import React, {useState, useEffect} from 'react'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import {firebaseAnalytics} from '../../services/firebase'
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'


const Leaderboard = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });


    useEffect(()=>{
      firebaseAnalytics.logEvent('SimBull15_Leaderboard_visit')
    },[])

    const [leaderboard, setLeaderboard] = useState([])
    useEffect(()=> {
        if(props.leaderboard && props.leaderboard !== leaderboard && props.leaderboard.length > 0){
            props.leaderboard.sort((a,b) => {
                return b.score - a.score
            })
            setLeaderboard(props.leaderboard)
        }
    },[props.leaderboard])

    console.log(leaderboard)

    const returnAmount = (num) => {
        if(num === 1){
            return 500
        }
        else if(num === 2){
            return 250
        }
        else if(num >2 && num < 6){
            return 100
        }
        else if(num >5 && num < 11){
            return 50
        }
        else if(num >10 && num < 51){
            return 20
        }
        else if(num >50 && num < 101){
            return 10
        }
        else if(num > 100 && num < 201){
            return 5
        }
        else return 0
    }
    useEffect(()=>{

        
        let arr = []
        for(let i = 1; i < 101; i++){
            arr.push({
                rank: i,
                username: `user00${i}`,
                round1: 0,
                round2: 0,
                round3: 0,
                round4: 0,
                score: 0,
                current_payout: returnAmount(i)
            })
        }
        setLeaderboard(arr)
    },[])

    const copyLink = () => {
        var textField = document.createElement('textarea')
        textField.innerText = `https://SimBull.com/Picks`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        alert('Copied!')
    }

    const [showUser, setShowUser] = useState(false)

    return(
        <>

        <div style={{minHeight: '100vh',padding: '5px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '15vh', backgroundColor: 'rgb(240,240,240)',  paddingTop: isMobile ? '5px' : '5px', fontFamily: 'Roboto Slab, serif'}}>
        <div style={{backgroundColor: 'white', width: isMobile ? '100%' : '90%', marginBottom: '10vh', marginLeft: isMobile ? '0%' : '5%', borderRadius: '.8em', border: '1px solid gray', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <div style={{textAlign: 'center', fontSize: isMobile ? '2em' : '3em', fontWeight: '500', padding: '5px', paddingBottom: '0px'}}>Leaderboard</div>
            
            <div style={{textAlign: 'center', fontSize: '.75em'}}>
            
            <button style={{margin: '5px', outline: 'none', borderRadius: '.3rem', backgroundColor: 'white', border: '1px solid gray', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 9px'}}>
                <FacebookShareButton
                    url={"www.simbull.com/Picks"}
                    quote={`SimBull.com is running a NBA Playoff Challenge! Choose a team from each matchup and get a point each time they win a game. There's $3,000 in prizes available!`}
                    hashtag="#SimBull"
                >
                    Share on Facebook
                </FacebookShareButton>
            </button>
            <button style={{margin: '5px', outline: 'none', borderRadius: '.3rem', backgroundColor: 'white', border: '1px solid gray', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 9px'}}>
                <TwitterShareButton
                    url={"www.simbull.com/Picks"}
                    title={`SimBull.com is running a NBA Playoff Challenge! Choose a team from each matchup and get a point each time they win a game. There's $3,000 in prizes available!`}
                >
                    Share on Twitter
                </TwitterShareButton>
            </button>
            <button onClick={copyLink} style={{margin: '5px', outline: 'none', borderRadius: '.3rem', backgroundColor: 'white', border: '1px solid gray', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 9px'}}>
                Copy Link
            </button>
            
            </div>

            <table style={{width: isMobile ? '99%': '90%', marginLeft: isMobile ? '.5%' : '5%', textAlign: 'center'}}>
                <tr style={{fontSize: isMobile ? '.6em' : '.75em', verticalAlign: 'bottom', fontWeight: '200', color: 'gray'}}>
                <td>Rank {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                <td>Username {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                    {!isMobile && <>
                        <td>First <br /> Round {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                        <td>Conf<br /> Semis {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                        <td>Conf<br /> Finals {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                        <td>NBA <br />Finals {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                        
                    </>}
                <td>Current <br />Wins {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                <td>Position<br /> Payout {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                </tr>
                
                {leaderboard && leaderboard.map(a => {
                    let rank = leaderboard.indexOf(a) + 1
                    
                    //let theirs = (a.repicks && a.repicks.length > 0) ? a.repicks : a.picks
                    
                    return(
                        <>
                        
                            <tr style={{fontWeight: '400', paddingBottom: '3px', color: 'black'}}>
                                
                                <td style={{color: 'gray', width: isMobile ? '7.5%' : '5%'}}>{rank}</td> 
                                <td style={{fontSize: '.9em', width: isMobile ? '32.5%' : '20%', fontWeight: '500'}}>
                                    <Link onClick={()=>setShowUser(a.user_id)} style={{color: 'black'}}>{a.username}</Link>
                                </td> 
                                    {!isMobile && <>
                                        <td style={{fontWeight: '200', width: '7.5%'}}>{a.round1}</td>
                                        <td style={{fontWeight: '200', width: '7.5%'}}>{a.round2}</td>
                                        <td style={{fontWeight: '200', width: '7.5%'}}>{a.round3}</td>
                                        <td style={{fontWeight: '200', width: '7.5%'}}>{a.round4}</td>
                                    </>}
                                <td style={{width: isMobile ? '15%' : '7.5%'}}>{a.score}</td> 
                                <td style={{width: isMobile ? '15%' : '7.5%'}}>${returnAmount(rank)}</td>
                                
                                {/*<Popup open={showUser === a.user_id} modal>
                                    <div style={{background: 'rgba(240,240,240, 1)', margin: '5px', padding: '5px', boxShadow: 'rgba(0, 0, 0, 0.8) 0px 20px 200px', borderRadius: '.3em'}}>
                                        <TheirTeams secondPop={true} teams={props.teams} games={props.games} picks={theirs} />
                                        <div style={{textAlign: 'center'}}>
                                            <button onClick={()=>setShowUser(false)} style={{backgroundColor: 'rgb(240,240,240)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                                        </div>
                                    </div>
                                </Popup>*/}
                            </tr>
                        
                        
                        </>
                    )
                })}

                

            </table>

            {leaderboard.length === 0 &&<div style={{margin: '25px', textAlign: 'center', fontWeight: '200', fontSize: '.8em'}}>~Leaderboard will fill once the first game finishes~</div>}

            {leaderboard.length === 0 && <div style={{textAlign: 'center', margin: '25px', border: '1px solid rgb(240,240,240)', borderRadius: '.3rem', width: isMobile ? '90%' : '30%', marginLeft: isMobile ? '5%' : '35%'}}>
                    
                    <div style={{fontSize: '1.5rem', marginBottom: '20px', fontWeight: '300', marginTop: '10px'}}>Prizes and Payouts</div>
                    <table style={{width: '100%', marginBottom: '15px'}}>
                        <tr>
                            <td>1st Place <hr /></td>
                            <td style={{fontWeight: '600'}}>$500 <hr /></td>
                        </tr>
                        <tr>
                            <td>2nd Place <hr /></td>
                            <td style={{fontWeight: '600'}}>$250 <hr /></td>
                        </tr>
                        <tr>
                            <td>3rd-5th <hr /></td>
                            <td style={{fontWeight: '600'}}>$100 <hr /></td>
                        </tr>
                        <tr>
                            <td>6th-10th <hr /></td>
                            <td style={{fontWeight: '600'}}>$50 <hr /></td>
                        </tr>
                        <tr>
                            <td>11th-50th <hr /></td>
                            <td style={{fontWeight: '600'}}>$20 <hr /></td>
                        </tr>
                        <tr>
                            <td>51st-100th</td>
                            <td style={{fontWeight: '600'}}>$10<hr /></td>
                        </tr>
                        <tr>
                            <td>101st-200th</td>
                            <td style={{fontWeight: '600'}}>$5</td>
                        </tr>
                    </table>
                </div>}
        </div>
        </div>
        </>
    )
}

export default Leaderboard
