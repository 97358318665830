import * as types from "../../types";
import {getUserPrices} from '../../../routes/user/user'

export const getUserPriceInfo = (token) => (dispatch) => {

  getUserPrices(token).then(res => {
    dispatch({
      type: types.GET_USER_PRICES,
      payload: res,
    })

  })

    



};
