import Helmet from "react-helmet";
import React, {useCallback, useRef, useState, useEffect} from "react";

import "./portfolio2.css";
import {Form, InputGroup, Button, Row, Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import logo1 from '../assets/Logo1.png'

const Rewards = () => {

    const user = useSelector(state => state.myuser?.myuser || {});
    
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return <>
            <div style={{paddingLeft: isMobile ? '12px':'298px', paddingRight: isMobile ? '12px':'398px',  minHeight: '100vh', paddingTop: isMobile? '0px':'100px'}}>

                {isMobile ? 
                    <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '4vh', textAlign: 'left'}}>
                        <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                        <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500', overflow: 'visible'}}>
                            <span style={{fontSize: '1.15rem'}}>Rewards</span>
                        </div>
                    </div>
                : null}

                <div style={{ backgroundColor: 'rgb(30,30,30)', borderRadius: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="signup-title" style={{color: 'gray'}}>
                        <span style={{color: 'white', fontWeight: '500'}}>Refer a Friend</span><br />
                        You both get <span className="signup-highlight"><b>free stock.</b></span>
                    </div>

                    <div className="signup-referral-info">
                        <h5 style={{color: 'gray'}}>Invite a friend. <span className="signup-highlight">Get a free team stock.</span></h5>
                        <p>Invite friend to SimBull. Once they sign up and deposit at least $10, you'll get a random free team stock.</p>
                        <h5 style={{color: 'gray'}}>100% chance to get a free stock</h5>
                        <p>Every time a new friend signs-up and deposits at least $10, you win a random team stock valued <span className="signup-highlight">UP TO $150!</span></p>
                        <h5 style={{color: 'gray'}}>Unlimited invites. Unlimited free stock.</h5>
                        <p>Invite as many friends as you want and build your portfolio as the market grows! Tell your friends to use this code on signup.</p>
                    </div>

                    {user && <Row noGutters className="signup-referral-panel" style={{padding: 'auto'}}>
                        <Col xs="12">
                            <InputGroup className="signup-form-input">
                                <Form.Control readOnly type="text" value={user.referral_code || 'XXXXXX'} />
                            </InputGroup>
                        </Col>
                        <Col xs={navigator.share ? "6" : "12"}>
                            <Button onClick={e => {
                                const textField = document.createElement('textarea');
                                textField.style.opacity = 0;
                                textField.innerText = user.referral_code;
                                document.body.appendChild(textField)
                                textField.select();
                                document.execCommand('copy')
                                textField.remove();
                                e.target.innerText = 'Copied!';
                            }} className="btn-form-signup">Copy</Button>
                        </Col>
                        {navigator.share && <Col xs="6">
                            <Button onClick={() => {
                                navigator.share({
                                    text: `SimBull: Sports Stock Market | Buy and Sell your favorite sports teams like stocks. Get a free stock worth up to $150 when you download SimBull on the Apple App Store and sign up with code ${user.referral_code}! Download here: https://apps.apple.com/app/apple-store/id1628754092?pt=125012269&ct=appRefer&mt=8`
                                })
                            }} className="btn-form-signup">Share</Button>
                        </Col>}
                    </Row>}
                </div>
                </div>
            
    </>
}

export default Rewards;