import axios from 'axios'
import {route} from '../route'
import ReactPixel from 'react-facebook-pixel'

/* signup */
export const signup = async (email, username, password, first_name, last_name, phone) => {
    ReactPixel.track('CompleteRegistration')
    let token = ''
    let refresh = ''
    const signupOptions = {
        method: 'POST',
        url: `${route}/register`,
        params: {
            "email": email,
            "username": username,
            "password": password,
            "first_name": first_name,
            "last_name": last_name,
            "phone": phone,
            "platform": "WEBSITE"
        },
    }
    let carry
    await axios.request(signupOptions).then(function (response) {
        let results = response
        token = results.data.access_token 
        refresh = results.data.refresh_token
        //pass both of these to localStorage with timestamp on it
        carry = {access_token: token, refresh_token: refresh}
    }).catch(err => carry = err.response)
    return carry
}

export const newSignup = (email, username, password, first_name, last_name, code) => {
    ReactPixel.track('CompleteRegistration')
    const signupOptions = {
        method: 'POST',
        url: `${route}/register`,
        params: {
            "email": email,
            "username": username,
            "password": password,
            "first_name": first_name,
            "last_name": last_name,
            "referral_code": code,
            "platform": "WEBSITE"
        },
    }
    return axios.request(signupOptions);
}

export const startPhoneVerification = (token, number) => {
    return axios.request({
        method: 'GET',
        url: `${route}/phone_verification`,
        params: {
            phone_number: number
        },
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}

export const completePhoneVerification = (token, code) => {
    return axios.request({
        method: 'POST',
        url: `${route}/phone_verification`,
        params: {code},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

/* Login */
export const login = async (email, password) => {
    let token = ''
    let refresh = ''
    const loginOptions = {
        method: 'GET',
        url: `${route}/login`,
        params: {
            "password": password,
            "email": email,
            "platform": "WEBSITE"
        },
    }
    let carry 
    await axios.request(loginOptions).then(function (response) {
        let results = response
        token = results.data.access_token 
        refresh = results.data.refresh_token
        carry = {access_token: token, refresh_token: refresh}
        //pass both of these to localStorage with timestamp on it
    }).catch(err => carry = err.response)
    return carry
}

/* Login with Emaul */
export const loginWithEmail = async (email, password) => {
    let token = ''
    let refresh = ''
    const loginOptions = {
        method: 'GET',
        url: `${route}/login`,
        params: {
            "password": password,
            "email": email,
            "platform": "WEBSITE"
        },
    }
    let carry 
    await axios.request(loginOptions).then(function (response) {
        let results = response
        token = results.data.access_token 
        refresh = results.data.refresh_token
        carry = {access_token: token, refresh_token: refresh}
        //pass both of these to localStorage with timestamp on it
    }).catch(err => carry = err.response)
    return carry
}

/* Login with Username */
export const loginWithUsername = async (username, password) => {
    let token = ''
    let refresh = ''
    const loginOptions = {
        method: 'GET',
        url: `${route}/login`,
        params: {
            "password": password,
            "username": username,
            "platform": "WEBSITE"
        },
    }
    let carry 
    await axios.request(loginOptions).then(function (response) {
        let results = response
        token = results.data.access_token 
        refresh = results.data.refresh_token
        carry = {access_token: token, refresh_token: refresh}
        //pass both of these to localStorage with timestamp on it
    }).catch(err => carry = err.response)
    return carry
}

