export const getWinStandings = (team, teams) => {
    let league_teams = []
    teams && teams.length > 0 && teams.map(t => {
        if(t.team.league === team.team.league){
            league_teams.push(t)
        }
    })
    
    league_teams.sort((a, b) => {
        let aWins = (a.team && a.team.season_wins) ? a.team.season_wins : 0
        let bWins = (b.team && b.team.season_wins) ? b.team.season_wins : 0
        if (aWins === bWins) {
            return 0;
        } else {
            return aWins > bWins ? -1 : 1;
        }
    })
    
    return league_teams.indexOf(team) + 1
}
export const getWinStandings_Conference = (team, teams) => {
    let league_teams = []
    teams && teams.length > 0 && teams.map(t => {
        if(t.team.conference === team.team.conference){
            league_teams.push(t)
        }
    })

    league_teams.sort((a, b) => {
        let aWins = (a.team && a.team.season_wins) ? a.team.season_wins : 0
        let bWins = (b.team && b.team.season_wins) ? b.team.season_wins : 0
        if (aWins === bWins) {
            return 0;
        } else {
            return aWins > bWins ? -1 : 1;
        }
    })
    
    return league_teams.indexOf(team) + 1
}
export const getPriceStandings = (team, teams) => {
    let league_teams = []
    teams && teams.length > 0 && teams.map(t => {
        if(t.team.league === team.team.league){
            league_teams.push(t)
        }
    })
    league_teams.sort((a, b) => {
        if (a.asking_price === b.asking_price) {
            return 0;
        } else {
            return a.asking_price > b.asking_price ? -1 : 1;
        }
    })
    return league_teams.indexOf(team) + 1
}
export const getPriceStandings_Conference = (team, teams) => {
    let league_teams = []
    teams && teams.length > 0 && teams.map(t => {
        if(t.team.conference === team.team.conference){
            league_teams.push(t)
        }
    })
    league_teams.sort((a, b) => {
        if (a.asking_price === b.asking_price) {
            return 0;
        } else {
            return a.asking_price > b.asking_price ? -1 : 1;
        }
    })
    return league_teams.indexOf(team) + 1
}

export const giveNumberPlace = (num) => {
    let _st = [1, 21, 31, 41, 51, 61, 71, 81, 91]
    let _nd = [2, 22, 32, 42, 52, 62, 72, 82, 92]
    let _rd = [3, 23, 33, 43, 53, 63, 73, 83, 93]
    let correct = 'th'
    if(_st.includes(num)){
        correct = 'st'
    }
    else if(_nd.includes(num)){
        correct = 'nd'
    }
    else if(_rd.includes(num)){
        correct = 'rd'
    }
    return correct
}