import { combineReducers } from "redux";

import teams from './teams';
import user from './user';
import teams3 from './teams3';
import user3 from './user3';
import trades from './trades';
import teamsValue from './teamsValue'
import equity from './user/equity'
import totals from './user/totals'
import userPrices from './user/userPrices'
import token from './token'
import info from './user/info'
import myuser from './user/myuser'
import watchlist from './user/watchlist'

export default combineReducers({ teams, user, teams3, user3, trades, teamsValue, equity, totals, userPrices, token, info, myuser, watchlist });