import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import './portfolio2.css'
import moment from 'moment'
import {Link} from 'react-router-dom'



const Notifications = (props) => {

    const [notifs, setNotifs]  = useState([])
    const getNotifs = () => {
        let notifs1 = []
        db.collection('Notifications').where('active', '==', true).get().then(snapshot => {
            snapshot.forEach(doc=> {
                const data = doc.data()
                notifs1.push(data)
            })
            setNotifs(notifs1)
        })
    }
    useEffect(()=>{
        getNotifs()
    },[])

    notifs.sort((a,b) => {
        return b.priority - a.priority
    })

    return(
        <>
        {/*random > 1 || user.phone ?<div style={{margin: '10px'}}> */
             /*<div className="NotificationBox">
                <div style={{fontSize: '0.8em', fontWeight: '600', color: 'gray', paddingTop: '5px', paddingBottom: '10px'}}>SimBull Price Alert</div>
                <div style={{fontSize: '1.1em'}}>Sim{team.team} (Sim{team.league}) {returnPercentChange() === 0 ? `haven't changed` : returnPercentChange() < 0 ? `are down ${returnPercentChange().toFixed(2)}%` : `are up +${returnPercentChange().toFixed(2)}%`} in the last 24 hours {returnPercentChange() === 0 ? 'at' : 'to'} ${team.lastSoldPrice.toFixed(2)}</div>
                <Link to={`/Teams/${team.team}`}><div style={{fontSize: '0.9em', color: '#c5b358', paddingTop: '10px'}}>View Sim{team.team} <span className="fas fa-chevron-right"></span></div></Link>
            </div>
        </div>
        :
        <>
       <div style={{margin: '10px'}}>
            <div className="NotificationBox">
                <div style={{fontSize: '0.8em', fontWeight: '600', color: 'gray', paddingTop: '5px', paddingBottom: '10px'}}>Text Alerts</div>
                <div style={{fontSize: '1.1em'}}>New: Sign up for text alerts to get a message each time your shares trade!</div>
                <Link to={`/Text`}><div style={{fontSize: '0.9em', color: '#c5b358', paddingTop: '10px'}}>Sign Up Now <span className="fas fa-chevron-right"></span></div></Link>
            </div>
        </div>*/}
        {notifs && notifs.length > 0 && 
            <div className="portfolioDiv">
                <div style={{display: 'flex'}}>
                    
                {notifs.map(n => {
                    return(
                            <div className="NotificationBox">
                                <div style={{fontSize: '0.8em', fontWeight: '600', color: 'gray', paddingTop: '5px', paddingBottom: '5px'}}>{n.type}</div>
                                <div style={{fontSize: '1.1em', whiteSpace: 'normal'}}>{n.body}</div>
                                <Link to={`${n.link}`}><div style={{fontSize: '0.9em', color: '#c5b358', paddingTop: '5px', marginBottom: '5px'}}>{n.cta}<span className="fas fa-chevron-right"></span></div></Link>
                                
                            </div>
                    )
                })}
                </div>
            </div>
        }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Notifications)