import {get_nfl_sportle, guess_nfl_sportle} from '../../routes/sportle/sportle'

export const getNFLsportle = async (token) => {
    return await get_nfl_sportle(token)
}

export const guessNFLsportle = async (token, id) => {
    return await guess_nfl_sportle(token, id)
}


export const getAbbr = (team, teamsValue) => {
    let abbr = ''
    if(teamsValue && teamsValue.length > 0){
        teamsValue.map(t => {
            if(team === t.team.name){
                abbr = t.team.abbr
            }
        })
    }
    return abbr
}

export const getStreak = () => {
    let streak = window.localStorage.getItem('simbull_sportle_streak')
    return streak ? streak : 0
}
export const getCorrect = () => {
    let correct = window.localStorage.getItem('simbull_sportle_correct')
    return correct ? correct : 0
}
export const getGuesses = () => {
    let guesses = window.localStorage.getItem('simbull_sportle_guesses')
    return guesses ? guesses : 0
}

export const resetStreak = () => {
    window.localStorage.setItem('simbull_sportle_streak', 0)
}
export const addToStreak = () => {
    let newStreak = parseInt(getStreak()) + 1
    window.localStorage.setItem('simbull_sportle_streak', newStreak)
}

export const addToCorrect = () => {
    let newCorrect = parseInt(getCorrect()) + 1
    window.localStorage.setItem('simbull_sportle_correct', newCorrect)
}

export const addToGuesses = () => {
    let newGuesses = parseInt(getGuesses()) + 1
    console.log(getGuesses())
    console.log(newGuesses)
    window.localStorage.setItem('simbull_sportle_guesses', newGuesses)
}