import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {getMyOffersByTeam} from '../../../routes/user/user'

const Offers = (props) => {

    const user = props.user

    const t = props.t ? props.t : false

    const [offers, setOffers] = useState([])

    const [deletedOffers, setDeletedOffers] = useState([])
    const [offersCount, setOffersCount] = useState(0)
    const [edit, setEdit] = useState(false)

    const getOffers = () => {
        if(props.t && props.token && props.t.team && props.t.team.id){
            getMyOffersByTeam(props.token, props.t.team.id).then(res => {
                setOffers(res)
            })
        }
        
    }
    useEffect(()=>{
        getOffers()
    },[props.t, props.token])

    const deleteOneOffer = (o) => {
        if(o.bidTeam){
            //delete bid
            db.collection('Bids').doc(`${o.bidTeam}-${o.bidOwner}-$${o.bidPrice.toFixed(2)}-${o.bidNum}`).delete().then(res => {
                let arr = deletedOffers
                arr.push(o)
                setDeletedOffers(arr)
                setOffersCount(offersCount - 1)
            }).catch(err => console.log(err))
            
        }
        else{
            //delete ask
            db.collection('Asks').doc(`${o.askShare}-${o.askOwner}`).delete().then(res => {
                db.collection('Shares').doc(o.askShare).update({
                    onTradingBlock: false,
                    currentAsk: null
                })
                let arr = deletedOffers
                arr.push(o)
                setDeletedOffers(arr)
                setOffersCount(offersCount - 1)
            }).catch(err => console.log(err))
            
        }
    }

    return(
        <>
        {offers && offers.length > 0 && (offers.length - deletedOffers.length !== 0) && <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'rgb(50,50,50)'}}>
                <div  style={{fontSize: '1.5em', fontWeight: '400', display: 'inline-block', width:'60%', color: 'black', marginBottom: '5px'}}>My Offers</div>
                <div  style={{textAlign: 'right', fontSize: '1em', fontWeight: '300', display: 'inline-block', width:'40%', color: 'gray'}}><button style={{border: 'none', outline: 'none', backgroundColor: 'white', textDecoration: 'underline', color: 'gray'}} onClick={()=>setEdit(!edit)}>{edit ? 'close': 'edit'}</button></div>
                <div className="portfolioDiv">
                    {offersCount === (offers.length - deletedOffers.length) && offers && offers.length > 0 && offers.map(o => {
                        if(deletedOffers.indexOf(o) === -1){
                            return(
                                <div className="portfolioBox">
                                    <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.date.substring(4, 6)}/${o.date.substring(6, 8)}/${o.date.substring(0, 4)}`}</div>
                                    <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{o.team ? o.team : o.bidTeam}</div>
                                    <div style={{fontSize: '1.15em', color: 'gray'}}>
                                        {o.bidTeam ? 'Bid: ' : 'Ask: '}{' '}
                                        ${o.askPrice ? o.askPrice.toFixed(2) : o.bidPrice.toFixed(2)}
                                    </div>
                                    {edit && <div style={{textAlign: 'center'}}>
                                        <button onClick={()=>{deleteOneOffer(o)}} style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                                    </div>}
                                </div>
                            )
                        }
                    })}
                    

                    {offers.length > 0 && <div className="portfolioBox">
                        <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px', color: 'white'}}>-></div>
                        <Link to={`/AllOffers/${props.team.team}`}><div style={{color: 'black',fontSize: '1.3em', fontWeight: '300', marginBottom: '3px', textDecoration: 'underline'}}>View All</div></Link>
                        <div style={{fontSize: '1.15em', color: 'white'}}>-></div>
                    </div>}

                </div>
                
        </div>}
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token
});

export default connect(mapStateToProps)(Offers)
