export const sendTeamDetails = (teams, id) => {
    let team
    teams && teams.length > 0 && teams.map(t => {
        if(t.id === id){
            team = t
        }
    })
    return team
}

export const isPick = (team_id, picks) => {
    let pick = false
    picks && picks.length > 0 && picks.map(p => {
        if(p.team === team_id){
            pick = true
        }
    })
    return pick
}

export const getTeamSeed = (id) => {
    if(id === 66 || id === 158){
        return 1 //Heat Suns
    }
    else if(id === 27 || id === 64){
        return 2 //Bucks Grizzlies
    }
    else if(id === 2 || id === 185){
        return 3 //76ers Warriors
    }
    else if(id == 39 || id === 76){
        return 4 //Celtics Jazz
    }
    else if(id === 29 || id === 102){
        return 5 //Bulls Mavericks
    }
    else if(id === 38 || id === 110){
        return 6 //Cavaliers Nuggets
    }
    else if(id === 138 || id === 167){
        return 7 //Raptors Timberwolves
    }
    else if(id === 109 || id === 42){
        return 8 //Nets Clippers
    }
    else if(id === 67 || id === 88){
        return 9 //Hornets Lakers
    }
    else if(id === 65 || id === 127){
        return 10 //Hawks Pelicans
    }
    else{
        return '-'
    }
}