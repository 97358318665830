import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import './LandingPage3.css'
import Head from '../Payouts3/Head'

const Reserves = (props) => {

    let Fanatics = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FFanaticsLogo.png?alt=media&token=c61c1b1e-064b-47d8-a8ac-8d65ef531a71'

    return(
        <div className="Reserves">
            <hr />
            <div style={{marginLeft: '20%', marginRight: '20%'}}>
                <div style={{fontWeight: '600', fontSize: '2rem'}}>Win Payouts Assurance</div>
                <div style={{fontWeight: '300', fontSize: '1.2rem'}}>Each quarter, financial assurances regarding win payouts are provided by a Certified Public Accountant (CPA). 
                <br />
                <br />The latest report can be found <Link to="/PayoutAssurance">here.</Link></div>
                <div className="FAQs3" style={{marginBottom: '50px', marginTop: '30px'}}>
                    <div style={{textAlign: 'center', fontSize: '1.3em', fontWeight: '200', marginTop: '15px'}}>Win Payouts Sponsored By</div>
                        <a href="https://fanatics.93n6tx.net/ORyG2r">
                        <div>
                            <img src={Fanatics} style={{width: '100%', marginLeft: '0%', marginTop: '10px'}} />
                        </div>
                        <div style={{textAlign: 'center', fontWeight: '200', width: '92%', marginLeft: '4%', marginTop: '10px', color: 'gray', fontSize: '.85em'}}>Click here to start shopping at Fanatics.com for Free Shipping over $99</div>
                        </a>
                    </div>
                </div>
            <hr />
        </div>
    )
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Reserves)