import React, {useState, useEffect} from 'react'
import './Analysis.css'
import {db, firebaseAnalytics} from '../../services/firebase'
import ReactHtmlParser from 'react-html-parser'
import Article from './Article'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import firebase from 'firebase' 
import axios from 'axios'
import {getIndexByID} from '../../routes/teams/indexes'
import Indexes from './Indexes'
import Games from './Games'
import Marquee from 'react-fast-marquee'

const TwoAnalysis = (props) => {
    //props
    const user = props.user
    const teams = props.teams
    const [teamsValue, setTeamsValue] = useState(props.teamsValue ? props.teamsValue : [])

    useEffect(()=>{
        if(props.teamsValue && teamsValue.length === 0){
            setTeamsValue(props.teamsValue)
        }
    },[props.teamsValue])


    //Analysis States
    const [content, setContent] = useState([])
    //Analysis Functionality
    const getContent = () => {
        const content1 = []
        db.collection('Analysis').orderBy('dateTime', 'desc').limit(20).get().then(snapshot => {
            snapshot.forEach(doc => {
                const data = doc.data()
                content1.push(data)
            })
            content1.sort(function(a, b) {
                if(b.dateTime && b.datTime){
                    return b.dateTime - a.dateTime;
                }
                else{
                    return b.date - a.date
                }
            });
            setContent(content1)
        }).catch(err => { console.log(err) })
    }
    useEffect(()=>{getContent()},[])

    //count views
    const logView = (c) => {
        db.collection('Analysis').doc(`${c.title}`).update({
            views: firebase.firestore.FieldValue.increment(1)
        }).catch(err => console.log('err'))
        db.collection('Analysis').doc(`${c.date}-${c.author}`).update({
            views: firebase.firestore.FieldValue.increment(1)
        }).catch(err => console.log('err'))
    }

    //Analytics
    useEffect(()=>{
        window.scrollTo(0,0)
        ReactPixel.track('View', {user_role: 'member'})
        firebaseAnalytics.logEvent("analysis_visit")
    }, [])


    return(
        <>
            {/*<div className="marqueeDesktop">
                
                <span className='marquee-text'>{(teamsValue) && teamsValue.map(t => {
                        return(<div style={{display: 'inline-block'}} key={t.team.name}><strong>{`Sim${t.team.name}`}</strong>{' '}{`$${t.asking_price.toFixed(2)} `}{' '}{'|'} {' '}</div>)
                    
                })}</span>
                
            </div>*/}
       <div className="TwoAnalysis">
            {/* Ticker */}
            {/*<div className="marquee">
                <Link to="/Trade/Trade">
                    <span className='marquee-text'>{teamsValue&& teamsValue.map(t => {
                            return(<div style={{display: 'inline-block'}} key={t.team.name}>{' '}<strong>{`Sim${t.team.name}`}</strong>{' '}{`$${t.asking_price.toFixed(2)} `}{' '}{'|'} {' '}</div>)
                        
                    })}</span>
                    <hr className="marquee-line" />
                </Link>
            </div>*/}
            <Marquee
                gradientWidth={2}
                speed={60}
                style={{zIndex: '5'}}
            >
                {teamsValue &&teamsValue.map(t => {
                    return(<div style={{color: '#c5b358', marginLeft: '3px', paddingTop: '1px', paddingBottom: '1px'}}>{' '}<strong>{`Sim${t.team.name}`}</strong>{' '}{`$${t.asking_price.toFixed(2)} `}{' '}{'|'} {' '}</div>)
                })
                }
            </Marquee>

            <Indexes />

            <Games /> 

            {/* Analysis */}
            <div className='TA-Analysis DesktopContainer'>
                <div className="TA-A-Title">Top Analysis</div>
                {/*<div key='simcfb' className="anal-card TA-A-Card">
                    <Link to={{
                        pathname: `/SimBullSix`,
                        state: {
                            user: user
                        }
                    }}>
                        <hr className="TA-A-hr" />
                        <div className="anal-title blk-text">🏈💵 Introducing the SimBull Six! 💵🏈</div>
                        <div className="anal-sub blk-text">Announcement - By: SimBull - 09/07/2021</div>
                    </Link>
                </div>*/}
                {content.length > 0 && content.map(c => {
                    return(
                        <div key={c.title} className="anal-card TA-A-Card">
                            {c.url && <Link to={{
                                        pathname: `/content/${c.url}`,
                                        state: {
                                            article: c,
                                            user: user
                                        }
                                    }}>
                                        <hr className="TA-A-hr" />
                                        <div className="anal-title blk-text">{c.title}</div>
                                        <div className="anal-sub blk-text">{c.type} - By: {c.author} - {`${c.date.substring(4, 6)}/${c.date.substring(6, 8)}/${c.date.substring(0, 4)}`}</div>
                                    </Link>
                            }
                            {!c.url && <Link onClick={()=>{window.open(c.link); logView(c)}} className="blk-text">{/*<a href={c.link} id="analysis-link" target='_blank' className="blk-text">*/}
                                        <hr className="TA-A-hr" />
                                        <div className="anal-title blk-text">{c.title}</div>
                                        <div className="anal-sub blk-text">{c.type} - By: {c.author} - {`${c.date.substring(4, 6)}/${c.date.substring(6, 8)}/${c.date.substring(0, 4)}`}</div>
                                    </Link>
                            }
                        </div>
                    )
                })}
                
                <hr className="TA-A-hr" style={{marginLeft: '2%',marginRight:'2%'}} />
                <div style={{textAlign: 'center'}}><button className="TA-A-btn" onClick={()=>props.history.push({pathname: `/Analysis`, state: []})}>See All</button></div>
            </div>

            <hr />

       </div>
       </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(TwoAnalysis)