import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'

const Returns = (props) => {

    const user = props.user

    const team = props.team

    console.log(props.trades)

    //States
    const [paidForShares, setPaidForShares] = useState(0)
    const [winPayoutsValue, setWinPayoutsValue] = useState(0)
    const [shares, setShares] = useState(0)
    const [returns, setReturn] = useState(0)
    const [userID, setUserID] = useState(0)
    const [myTrades, setMyTrades] = useState([])
    const userData = props.myuser

    useEffect(()=>{
        if(userID === 0 && props.token){
            let user_id = jwt_decode(props.token).sub
            setUserID(user_id)
        }
        
    },[props.token])

    useEffect(()=>{
        setMyTrades(props.trades)
    },[props.trades])
    

    //Functions
    const getShares = () => {
        console.log('running this oneeee')
        console.log(props.trades)
        let num = props.numOfShares
        let currentAmt = 0
        let currentPaid = 0
        myTrades.map((t) => {
            if (currentAmt < num && currentAmt + t.amount <= num) {
              let amt = t.amount;
              let paid = t.price * t.amount;
              if (t.buyer_id === userData.id) {
                currentAmt += amt;
                currentPaid += paid;
                console.log(paid)
              }
            } else if (currentAmt < num && currentAmt + t.amount > num) {
              let partialAmt = num - currentAmt;
              let partialPaid = partialAmt * t.price;
              if (t.buyer_id === userData.id) {
                currentAmt += partialAmt;
                currentPaid += partialPaid;
                console.log(partialPaid)
              }
            }
          });
        console.log(currentPaid)
        console.log(num)
        setPaidForShares(currentPaid)
        setShares(num)
        setReturn((num * props.t.asking_price - currentPaid))

    }
    useEffect(() => {
        if(myTrades && myTrades.length > 0){
            if(paidForShares === 0 && shares === 0){getShares()}
        }
    }, [myTrades])


    return(
        <>
        {team && <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'white'}}>
            <div style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>Earnings</div>
            <div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Today's Earnings</div>
            <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{props.t.change.day >= 0 ? '+' :'-'}${props.t.change ? Math.abs((props.t.change.day*props.t.asking_price)).toFixed(2) : '--'} <span style={{fontWeight: '200'}}>({props.t.change ? (props.t.change.day*100).toFixed(2) : '--'}%)</span></div>
            {/*<div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Payouts Earned</div>
            <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{winPayoutsValue >= 0 ? '+' :'-'}${Math.abs(winPayoutsValue).toFixed(2)} <span style={{fontWeight: '200'}}>(+{((winPayoutsValue/paidForShares)*100).toFixed(2)}%)</span></div>*/}
            <div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Total Earned</div>
            <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{returns >= 0 ? '+' :'-'}${Math.abs(returns).toFixed(2)} <span style={{fontWeight: '200'}}>({(((returns) / paidForShares) *100).toFixed(2)}%)</span></div>
        </div>}
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(Returns)
