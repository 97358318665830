import React, {useState, useEffect} from 'react'
import './SimBull16.css'
import {Link} from 'react-router-dom'
import {sendBracketDetails, sendTeamDetails, hasLoss, returnCircle} from './util'
 
const RightBracket = (props) => {

    let rounds = props.rounds
    let games = props.games
    let region = props.region
    let mm_teams = props.teams

    let makeNewPick = props.makeNewPick
    
    const [newPicks, setNewPicks] = useState(props.newPicks)
    useEffect(()=> {if(props.newPicks !== newPicks){setNewPicks(props.newPicks)}},[props.newPicks])
    const [currentPicks, setCurrentPicks] = useState(props.currentPicks)
    useEffect(()=> {if(props.currentPicks !== currentPicks){setCurrentPicks(props.currentPicks)}},[props.currentPicks])
 
    const addTeam = (team_id, seed) => {
        makeNewPick({
            team: team_id,
            seed: seed,
        })
    }

    return(
        <>
        <main id="tournament"> 
            <ul class="round round-5" style={{color: 'rgb(240,240,240)'}}>
                <li class="spacer">&nbsp;</li>
                
                <li class="game game-top" style={{borderBottom: '1px solid rgb(240,240,240)'}}></li>
                
                <li class="spacer">&nbsp;</li>
            </ul>   
            {mm_teams && mm_teams.length > 0 &&rounds && rounds.map(r => {
                return(
                    <ul class={`round round-${rounds.indexOf(r) + 1} ${r === 'First Round' ? 'firstRound' : ''}`}>
                        {games && games.map(g => {
                            let game_details = sendBracketDetails(g.id)
                            let game_round = game_details ? (game_details[0] ? game_details[0] : '') : ''
                            let game_region = game_details ? (game_details[1] ? game_details[1] : '') : ''
                            if(r === game_round && region === game_region){
                                
                                let home_team = sendTeamDetails(mm_teams, g.home_team_id)
                                let away_team = sendTeamDetails(mm_teams, g.away_team_id)
                                return(
                                    <> 
                                        <li class="spacer">&nbsp;</li>
                                        <Link onClick={()=>addTeam(home_team.id, home_team.seed)} style={{color: 'black'}}>
                                        <li class="game game-top rightAlign">
                                                <span className="gameSpan"></span>  
                                                <span style={{fontSize: '.6em'}}>{' '}{(newPicks.some(p => p.team === home_team.id) && '🟠')}{(currentPicks.some(p => p.team === home_team.id) ? returnCircle(g, 'home', home_team.id, true, props.originalPicks) : returnCircle(g, 'home', home_team.id, false, props.originalPicks))}{' '}</span>
                                                <span
                                                    style={{fontWeight: (newPicks.some(p => p.team === home_team.id) || currentPicks.some(p => p.team === home_team.id)) ? '600' : '400', fontStyle: newPicks.some(p => p.team === home_team.id) ? 'italic' : 'none' }}
                                                >
                                                {home_team.name !== null ? home_team.name : ''} {''}</span>
                                                <span  className="seed">{' '}{home_team.seed ? home_team.seed : ''}</span> 
                                                
                                            
                                        </li>
                                        </Link>

                                        <li class="game gameRight-spacer">&nbsp;</li>

                                        <Link onClick={()=>addTeam(away_team.id, away_team.seed)} style={{color: 'black'}}>
                                        <li class="game game-bottom rightAlign"> 
                                            
                                            <span className="gameSpan"></span> 
                                            <span style={{fontSize: '.6em'}}>{' '}{((newPicks.some(p => p.team === (away_team.id)) && '🟠'))}{(currentPicks.some(p => p.team === away_team.id) ? returnCircle(g, 'away', away_team.id, true, props.originalPicks) : returnCircle(g, 'away', away_team.id, false, props.originalPicks))}{' '}</span>
                                            <span
                                                style={{fontWeight: (newPicks.some(p => p.team === away_team.id) || currentPicks.some(p => p.team === away_team.id)) ? '600' : '400', fontStyle: newPicks.some(p => p.team === away_team.id) ? 'italic' : 'none' }}
                                            >
                                            {away_team !== null ? away_team.name : ''} {''}</span>
                                            <span  className="seed">{' '}{away_team.seed ? away_team.seed : ''}</span>
                                            
                                        </li>
                                        </Link>
                                    </>
                                )
                            }
                        })}
                        <li class="spacer">&nbsp;</li>
                    </ul>
                )
            })}
        </main>
        </>
    )
}

export default RightBracket