import axios from 'axios'
import {route} from '../route'

//1. User's Watchlist
//2. Add to User's Watchlist
//3. Delete from User's Watchlist

/* 1. User's Watchlist */
export const getMyWatchlist = async (token)  => {
    const userOptions = {
        method: 'GET',
        url: `${route}/@/watchlist`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        
    }
    let user
    await axios.request(userOptions).then(function (response) {
        let results = response.data
        user = results
    }).catch(err => {console.log('error');console.log(err); console.log(err.msg)})
    return user
}

/* 2. Add to User's Watchlist */
export const addToMyWatchlist = async (token, team_id)  => {
    const userOptions = {
        method: 'POST',
        url: `${route}/@/watchlist`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "id": team_id
        }
        
    }
    let user
    await axios.request(userOptions).then(function (response) {
        let results = response.data
        user = results
    }).catch(err => console.log(err))
    return user
}

/* 3. Delete from User's Watchlist */
export const deleteFromMyWatchlist = async (token, team_id)  => {
    const userOptions = {
        method: 'DELETE',
        url: `${route}/@/watchlist`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "id": team_id
        }
        
    }
    let user
    await axios.request(userOptions).then(function (response) {
        let results = response.data
        user = results
    }).catch(err => console.log(err))
    return user
}