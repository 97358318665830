import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom'
import '../portfolio2.css'
import {Link} from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import logo from '../../assets/Logo1.png'
import {getToken} from '../../../redux/actions/token'


const More = (props) => {
    //get props

    const dispatch = useDispatch()
    const history = useHistory()

    const logOut = () => {
        //auth.signOut()
        window.localStorage.removeItem("access_token")
        window.localStorage.removeItem("refresh_token")
        dispatch(getToken('logout'))
        history.replace('/')
    }

    const Items = [
        {
            head: 'Get SimBull GOLD',
            sub: 'Free trading, Premium Data, and Early Access',
            link: '/GOLD',
        },
        {
            head: 'SimBull Learn',
            sub: 'FAQs and Bullish Bits',
            link: '/LearnPage',
        },
        {
            head: 'Cash Transfers',
            sub: 'Deposit and Withdraw',
            link: '/Deposit',
        },
        {
            head: 'Settings',
            sub: 'Account and Notifications',
            link: '/Settings',
        },
        {
            head: 'Transaction History',
            sub: 'Transfers, Trades, Win Payouts, and Bonuses',
            link: '/Transactions',
        },
        {
            head: 'Rewards',
            sub: 'Refer a Friend to Get Free Shares',
            link: '/Rewards',
        },
        {
            head: 'Help',
            sub: 'Contact Us',
            link: '/Help',
        },
    ]
    const Item = ({_item}) => {
        return(
            <div>
            <Link style={{color: _item.head.includes('GOLD') ? '#c4b454' : 'white', }} to={_item.link}>
                <div style={{display: 'inline-block', width: '90%', paddingBottom: '6px'}}>
                    <div style={{fontSize: '1.3rem'}}>{_item.head}</div>
                    <div style={{fontSize: '.9rem', color: 'rgb(100,100,100)', marginTop: '-4px',}}>{_item.sub}</div>
                </div>
                <div style={{display: 'inline-block', width: '10%', textAlign: 'right', color: 'gray', verticalAlign: 'top'}}>
                    <KeyboardArrowRightIcon color={'rgb(100,100,100)'} fontSize={'large'} style={{marginTop: '10px'}} />
                </div>
            </Link>
            <hr style={{margin: 0, padding: 0, backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '100%', marginLeft: '0%', marginBottom: '6px', }} />
            </div>
        )
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '12px':'296px', paddingRight: isMobile ? '12px':'396px', minHeight: '100vh'}}>
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '2vh' }}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                        <span style={{}}>More</span>
                    </div>
                </div>
            : null}

            
            {(isMobile && props.info) ? 
                <div style={{paddingTop: '0px', paddingBottom: '40px'}}>
                    <div style={{fontSize: '1.8rem', color: 'white', fontWeight: '600'}}>@{props.info.username}</div>
                    <div style={{fontSize: '.9rem', color: 'gray', fontWeight: '500', marginLeft: '4px'}}>{props.info.email}</div>
                </div>
            : null}


            {Items.map(i => {
                return <Item _item={i} />
            })}

            <div style={{textAlign: 'center'}}>
                <button onClick={logOut} style={{width: '80%', backgroundColor: '#212121', border: 'none', outline: 'none', color: 'rgb(100,100,100)', borderRadius: 40, marginTop: '20px', padding: '6px', fontSize: '1.1rem', fontWeight: '500'}}>
                    Logout
                </button>
            </div>

            <div style={{color: 'rgb(100,100,100)', textAlign: 'center', marginTop: '24px'}}>
                <Link to='/Privacy' style={{color: 'rgb(100,100,100)'}}>Privacy Policy{''}</Link>
                <span style={{marginLeft: '4px', marginRight: '4px'}}>{''} | {''}</span>
                <Link to="/TermsOfUse" style={{color: 'rgb(100,100,100)'}}>{''}Terms of Service</Link>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token,
    info: state.info.info,
});

export default connect(mapStateToProps)(More)