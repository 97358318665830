import React from 'react'
import './welcome.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import logo from '../assets/Logo1.png'

const Bar = () => {
    return(
        <div className=""
            style={{backgroundColor: 'black', height: '10vh', textAlign: 'center'}}
        >
            <img src={logo} className="tb-logo2" alt='logo' style={{height: '9vh', paddingTop: '1vh'}} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(Bar))