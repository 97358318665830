import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import {getMyTradesByTeam} from '../../../routes/user/user'
import {getTrades} from './Functions'

const History = (props) => {

    const user = props.user
    const team = props.team

    const [trades, setTrades] = useState([])
    const [userID, setUserID] = useState(0)

    useEffect(()=>{
        if(props.token){
            let user_id = jwt_decode(props.token).sub
            setUserID(user_id)
        }
    },[props.token])

    useEffect(()=>{
        if(props.trades && trades.length === 0){
            setTrades(props.trades)
        }
    },[props.trades])


    return(
        <>
        <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'white'}}>
                <div  style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>My History</div>
                
                {trades && trades.length > 0 && trades.map(t => {
                    if(trades.indexOf(t) < 10){
                        return(
                        <div key={t.id}>
                            <div key={t.price} style={{display: 'inline-block', width: '11%', paddingLeft: '2%', fontSize: '1.5em', verticalAlign: 'top', paddingTop: '5px'}}><span className={t.buyer_id === userID ? "fas fa-plus" : "fas fa-minus"} style={{color: 'white'}}></span></div>
                            <div style={{display: 'inline-block', width: '59%'}}>
                                <div style={{fontSize: '1.3em'}}>{' '}Sim{team.team.name}</div>
                                <div style={{fontSize: '0.8em', color: 'white'}}>{t.timestamp.substring(5,7)}/{t.timestamp.substring(8,10)}/{t.timestamp.substring(2,4)}</div>
                            </div>
                            <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '1%'}}>
                                <div style={{fontSize: '1em'}}>${t.price.toFixed(2)}</div>
                                <div style={{fontSize: '.75em'}}>{t.amount.toFixed(2)} Shares</div>
                            </div>
                            <hr style={{margin: '0', padding: '0', marginBottom: '5px', marginTop: '5px'}} />
                        </div>
                        )
                    }
                    
                })
                
                }
                
        </div>
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token
});

export default connect(mapStateToProps)(History)
