import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Team from './BuyTeam'

const New = (props) => {

    const teamsValue = props.teamsValue

    const [league, setLeague] = useState('NFL')
    const [team, setTeam] = useState('49ers')

    return(<>
        <div style={{paddingBottom: '15vh'}}>
            <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%', paddingLeft: '5px', marginTop: '10px',}}>Buy fractional shares just $1</div>
            <div style={{color: 'gray', fontWeight :'300', paddingLeft: '5px'}}>Popular Teams</div>
            <hr style={{marginTop: '5px', marginBottom: '5px'}} />
            {teamsValue && teamsValue.length > 0 && teamsValue.map(t => {
                if(t.team.name === 'Cowboys' || t.team.name === 'Yankees' || t.team.name === 'Lakers' || t.team.name === 'LSU-FB'){
                    return <Team key={t.team.id} t={t} />
                }
            })}
            <div style={{color: 'gray', fontWeight :'300', paddingLeft: '5px', marginTop: '10px'}}>Find your favorite team</div>
            <hr style={{marginTop: '5px', marginBottom: '5px'}} />
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
                <div style={{display: 'inline-block', width: '20%', marginLeft: '3%'}}>League: </div>
                <select onChange={(e)=>setLeague(e.target.value)} style={{width: '74%', display: 'inline-block', marginRight: '3%'}}>
                    <option value="NFL">NFL</option>
                    <option value="NBA">NBA</option>
                    <option value="CFB">CFB</option>
                    <option value="MLB">MLB</option>
                    <option value="NHL">NHL</option>
                </select>
            </div>
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
                <div style={{display: 'inline-block', width: '20%', marginLeft: '3%'}}>Team: </div>
                <select onChange={(e)=>setTeam(e.target.value)} style={{width: '74%', display: 'inline-block', marginRight: '3%'}}>
                    {teamsValue && teamsValue.length > 0 && teamsValue.map(t => {
                        if(t.team.league === league){
                            return <option value={t.team.name}>{t.team.name}</option>
                        }
                    })}
                </select>
            </div>
            
            {teamsValue && teamsValue.length > 0 && teamsValue.map(t => {
                if(t.team.name === team){
                    return <Team key={t.team.id} t={t} buy={true} />
                }
            })}
        </div>
    </>)
}

const mapStateToProps = (state) => ({
    teamsValue: state.teamsValue.teamsValue,
    
});

export default connect(mapStateToProps)(New)