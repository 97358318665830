import React, {useContext} from 'react';
import PayPalBtn2 from './PayPalBtn2'
import moment from 'moment'
import {db} from '../../../../services/firebase'
import firebase from 'firebase'
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel'
import {createSubscription} from '../../../../routes/user/subscription'
import {useHistory} from 'react-router-dom'

function GoldYearly(props) {

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      'plan_id': 'P-32935296EJ884421KMA5W4MI',
    });
  };

  const paypalOnError = (err) => {
    alert('Error! Please try again.')
  }
    
  let history = useHistory()
  const paypalOnApprove = (data, detail) => {
    ReactPixel.track('Subscribe', {
            value: 49.99,
            type: 'subscription',
            frequency: 'yearly'
    })
    console.log(data)
    console.log(detail)
    //create subscription
    createSubscription(props.token, data.subscriptionID).then(res => {

      console.log(data)
      console.log(detail)
      alert('Subscription Created! Enjoy your trading!')

      //return history.push('/Portfolio')

    }).catch(err => alert(`There was an error. Please try again.`))
  };


  return (
    <div className="App">
              <PayPalBtn2
                amount = "49.99"
                currency = "USD"
                createSubscription={paypalSubscribe}
                onApprove={paypalOnApprove}
                catchError={paypalOnError}
                onError={paypalOnError}
                onCancel={paypalOnError}
            />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.token.token,
});

export default connect(mapStateToProps)(GoldYearly);