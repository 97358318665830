import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import './portfolio2.css'
import moment from 'moment'
import {Link} from 'react-router-dom'




const Team = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const t = props.t

    const [change, setChange] = useState(t && t.change ? t.change.day : 0)

    useEffect(()=>{
        if(props.t && props.t.change){
            if(props.time === '1D'){
                setChange(t.change.day)
            }else if(props.time === '1W'){
                setChange(t.change.week)
            }else if(props.time === '1M'){
                setChange(t.change.month)
            }
        }
    },[props])

    return(
        <div style={{marginLeft: '3%', marginRight: '3%'}}>
            {props.buy && <hr style={{padding: '0', margin: '5px'}} />}
            <Link to={`/Teams/${t.team.name}`} style={{color: 'black'}}>
            <div style={{display: 'inline-block', width: '10%', verticalAlign: 'top', textAlign: 'right'}}>
                <div><img className="team-img-port2" src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                
            </div>
            <div style={{display: 'inline-block', width: '51%', paddingLeft: '2%', fontSize: '1.3em', verticalAlign: 'top'}}>
                <div>Sim{t.team.name}</div>
            </div>
            <div style={{display: 'inline-block', width: '39%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'top'}}>
                <div style={{fontSize: props.buy ? '1.1em': '1.3em'}}>{props.buy ? <button style={{border: 'none', outline: 'none', backgroundColor: '#e0cb07', borderRadius: '.3rem', padding: '3px 10px 3px 10px', color: 'black', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px', fontWeight: '500'}}>Buy Now<i class="fa fa-arrow-right" style={{fontSize: '1em', marginLeft: '3px', fontWeight: '200'}}></i></button> : `$${(t.asking_price).toFixed(2)}` }</div>
            </div>
            <hr style={{padding: '0', margin: '5px'}} />
            </Link>
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Team)