import React, {useState, useEffect} from 'react'
import '../TeammateList.css'
import '../Teammate.css'
import Teammate from './Teammate'
import { connect } from 'react-redux'

const TeammateNewList = (props) => {  

    return(
        <div style={{paddingBottom: '10vh'}}>

            <div className="TeammateList-SubHeader">Others Popular on SimBull</div>

            {props.newTeammates && props.newTeammates.length > 0 && props.newTeammates.map(s => {
                return <div key={`${s.id}-${Math.random()}`}><Teammate token={props.token} teammates={props.teammates} rerunTeammates={props.rerunTeammates} changeTeammate={props.changeTeammate} s={s} /></div>
            })}


        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(TeammateNewList)