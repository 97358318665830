import axios from 'axios'
import {route} from '../route'

/* 1. Search for a username */
export const search_for_teammates = async (token, username) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/search`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "query": username
        }
    }
    let teammates 
    await axios.request(teammateOptions).then(function (response) {

        let results = response.data
        teammates = results
    }).catch(err => console.log(err.response))
    return teammates
}

/* 2. Get Random Accounts */
export const search_for_me = async (token) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/search`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let teammates 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        teammates = results
    }).catch(err => console.log(err.message))
    return teammates
}
