import React, {useEffect, useState} from 'react'
import './landingpage.css'
import NavBar from './NavBar'
import Header from '../Account2/Header'
import {db} from '../../services/firebase'
import moment from 'moment'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import Footer from './Footer'

const ContactUs = (props) => {
    //props

    const history = useHistory()

    //states
    const [name, setName] = useState( '')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    useEffect(()=>{
      if(props.match.path === '/ContactUs'){
        ReactPixel.track('View', {user_role: 'guest'})
      }else{
        ReactPixel.track('View', {user_role: 'member'})
      } 
    },[])

    //Functionality
    const submitContact = () => {
        let today = moment().format('YYYYMMDD')
        db.collection('ContactUs').doc(`${today}-${email}-${name}`).set({
            email: email,
            name: name,
            message: message,
            date: today
        }).then(res => {
            alert('Message Received! We will get back to you as soon as possible. Likely within an hour, but definitely within 24 hours. ')
            return history.replace('/')
        })
    }
    return(
        <>
        {props.location.pathname === '/ContactUs' &&  <NavBar />}
        {props.location.pathname === '/Contact-Us' &&  <Header header='' link={props.history.goBack} />}
        <div className="ContactUs">
            <div className="c-Head">Contact Us</div><br />
            <div className='c-txt'>Have a question, comment, or suggestion? We love hearing from you!</div><br />
            <div className='c-txt'>Please email us at customer-service@simbull.app</div><br />
            {/*}
            <div className="c-name">Name: <br />
                <input className='c-input' value={name} onChange={(e)=>{setName(e.target.value)}} />    
            </div>
            <div className="c-email">Email: <br />
                <input className='c-input' value={email} onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <div className="c-message">Message: <br />
                <textarea className='c-input' value={message} onChange={(e)=>{setMessage(e.target.value)}} /><br /><br />
            </div>
            <div className="c-btn-div">
                <button onClick={submitContact} className="c-btn">Contact Us</button>
            </div>
        */}
        </div>
        {props.location.pathname === '/ContactUs' &&  <><hr /><Footer /></>}
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(ContactUs))