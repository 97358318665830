import axios from 'axios'
import {route} from '../route'

//1. Withdraw Request

//1. Get All Offers
export const postWithdraw = async (token, amount, paypal_email) => {
    const withdrawOptions = {
        method: 'POST',
        url: `${route}/withdrawal_request`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            amount: amount,
            paypal_email: paypal_email,
        }
    }
    let withdraw = []
    await axios.request(withdrawOptions).then(function (response) {
        let results = response.data
        withdraw = results
    }).catch(err => withdraw = err)
    return withdraw
}