import React, {useEffect, useState} from 'react'
import './Account.css'
import Header from './Header'
import {Link, withRouter} from 'react-router-dom'
import ReactPayPal from './PayPal/ReactPayPal'
import PsWeekly25 from './PayPal/PS-Weekly25'
import PsWeekly10 from './PayPal/PS-Weekly10'
import PsMonthly250 from './PayPal/PS-Monthly250'
import PsMonthly100 from './PayPal/PS-Monthly100'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import {db} from '../../services/firebase'
import moment from 'moment'
import { Button } from 'react-bootstrap'

const Transfers = (props) => {
    //props
    const user = props.user

    //states
    const [amount, setAmount] = useState(250)
    const [promo, setPromo] = useState('')
    const [checkout, setCheckout] = useState(false)
    const [reoccurringDeposit, setReoccurringDeposit] = useState(false)

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    const updateAmount = (value) => {
        value = value.replace(/,/g, "");
        value = value.replace("$", "");
        setAmount(value)
    }

    const showCheckout = () => {
        let numType = parseFloat(amount)
        if(numType < 10 || numType === undefined){
            alert('$10 minimum')
        }else if(numType > 5000){
            alert(`Maximum Deposit Exceed`)
        }else if(isNaN(numType)){
            alert('Try a real number')
        }else{setCheckout(true)}
    }

    return(
        <>
        {checkout === true ? (
              <div className="payment-div DesktopContainer">
                <ReactPayPal promo={promo} amount={amount} />
              </div>
            ) : (
                    <>
                        <div className="Transfers DesktopContainer">
                        <div className='transfer-deposit'>
                            <div className='transfer-deposit-head'>One-Time Deposit</div>
                            <span>Your account will be credited immediately. <br />Payments are processed by PayPal or Credit/Debit Card.</span>
                            {!checkout && <div className='transfer-deposit-body'>
                                <div>
                                    <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Choose an Amount</div>
                                    <div style={{marginLeft: '10%', marginRight: '10%'}}>
                                        <button onClick={()=>setAmount(100)} className={amount === 100 ? "deposit-btn-gold" : "deposit-btn"}>$100</button><button onClick={()=>setAmount(250)} className={amount === 250 ? "deposit-btn-gold" : "deposit-btn"}>$250</button><br />
                                        <button onClick={()=>setAmount(500)} className={amount === 500 ? "deposit-btn-gold" : "deposit-btn"}>$500</button><button onClick={()=>setAmount(1000)} className={amount === 1000 ? "deposit-btn-gold" : "deposit-btn"}>$1000</button>
                                    </div>
                                </div>
                                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Other Amount: </div><input className="deposit-input"  placeholder='$' onChange={(e)=>{setAmount(e.target.value)}} value={amount} />
                                <br />
                                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Promo: </div><input className="deposit-input" onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                <br />
                                <Button variant="secondary" style={{margin: 10, width: "40%", marginBottom: '1%', display: 'inline-block', backgroundColor: 'green', fontSize: '1.5rem'}} onClick={showCheckout}>Deposit</Button>
                            </div>}
                            {/*<div className='transfer-deposit-body'>
                                Amount:<br /><input className="transfer-deposit-input" onChange={(e)=>{updateAmount(e.target.value)}} value={amount} placeholder='$' />
                                <br />
                                Promo:<br /> <input className="transfer-promo-input" onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                <br />
                                <button className="transfer-depsoit-btn" onClick={showCheckout}>Continue to Deposit</button>
                            </div>*/}
                        </div>
                        {/*<div className='transfer-deposit'>
                            <div className='transfer-deposit-head'>Start a Reoccurring Deposit</div>
                            
                            <div className='transfer-deposit-body'>
                                <PsWeekly10 />
                                <hr />
                                <PsWeekly25 />
                                <hr />
                                <PsMonthly100 />
                                <hr />
                                <PsMonthly250 />
                                <hr />
                            </div>
                            
                        </div>
                        {reoccurringDeposit && <>
                            <div className="reoccurringDepositDiv">
                                    You have a Reoccurring Deposit of ${reoccurringDeposit.amount} {reoccurringDeposit.frequency}. <br />
                                    <span className="reoccurringDepositSpan">Your next deposit will be {reoccurringDeposit.dow}, {reoccurringDeposit.nextDate.substring(4,6)}-{reoccurringDeposit.nextDate.substring(6,8)}-{reoccurringDeposit.nextDate.substring(0,4)}.</span>
                            </div>
                            <Link onClick={cancelReoccurringDeposit}><div className='transfer-div'>Cancel Reoccurring Deposit</div></Link>
                        </>} */}
                        <Link to ='/Withdraw'><div className='transfer-div'>Withdraw</div></Link>
                        {/*<Link to ='/RequestPennant'><div className='transfer-div'>Request your Pennant</div></Link>*/}
                        </div>
                    </>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(Transfers))