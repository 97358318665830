import React, {useState, useEffect} from 'react'
import './portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import PortfolioChart from './PortfolioChart'
import PeriodChange from './PeriodChange'
import {getDates, weeklyGraph} from './getDates'
import Loader from '../adHoc/Loader'
import amplitude from 'amplitude-js'

const Graph = (props) => {
    //get props
    const user = props.user
    const teams = props.teams
    const userPrices = props.userPrices
    
    //States
    const [prices, setPrices] = useState([])
    const [labels, setLabels] = useState([])
    const [showBy, setShowBy] = useState("1M")

    /*const [hourly, setHourly] = useState({})
    useEffect(async ()=>{
        let res = await weeklyGraph(props.token, 'HOURLY')
        setHourly(res)
        console.log(res)
    },[])*/

    //Functions
    //get an array of labels for the graph
    //get an array of the prices for the graph
    const getGraphInfo = (data) => {
        setShowBy(data)
        let length = 6
        if(data === '1M'){
            length = 29
        }
        else if(data === '3M'){
            length = 90
        }
        else if(data === '1Y'){
            length = 364
        }
        else if(data === 'ALL'){
            length = userPrices.length
        }
        let pricesArr = []
        let labelArr = []
        if(userPrices && userPrices.length > 0){
            /*let datesArr = []
            //for(let i = 1; i < )
            let showArr = userPrices
            if(userPrices.length > length){
                showArr = userPrices.slice(userPrices.length - length, userPrices.length)
            }
            showArr.map(p => {
                pricesArr.push(p.value.toFixed(2))
                //labelArr.push(p.timestamp.substring(0,10))
                labelArr.push(p.timestamp)
            })*/

            let hodlerArr = getDates(userPrices[0].timestamp.substring(0,10), userPrices)
            if(hodlerArr[0].length > length){
                hodlerArr[0] = hodlerArr[0].slice(hodlerArr[0].length - length, hodlerArr[0].length)
                hodlerArr[1] = hodlerArr[1].slice(hodlerArr[1].length - length, hodlerArr[1].length)
            }
            pricesArr = hodlerArr[1]
            labelArr = hodlerArr[0]
        }
        if(props.totals && props.totals.total_value){
            pricesArr.push(props.totals.total_value.toFixed(2))
            labelArr.push('Now')
        }
        setPrices(pricesArr)   
        setLabels(labelArr)
    }
    useEffect(() => {
        getGraphInfo(showBy)
    }, [props])

    useEffect(()=>{
        firebaseAnalytics.logEvent("portfolio_visit", {
           user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        {prices && prices.length > 1 && labels && labels.length > 1 && 
        <div style={{marginBottom: '0', paddingBottom: '0', height: '100%', zIndex: '1000'}}>
            {!props.off && <PeriodChange period={showBy} assets={props.assets ? true : false} />}
            {(prices && labels && prices.length > 0 && labels.length > 0) ? <PortfolioChart off={props.off} prices={prices} labels={labels} />  : <div style={{minHeight: '15vh'}}><Loader /></div>}
            {!props.off && <div style={{marginLeft: '12.5%', marginRight: '12.5%'}}>
                <button className="graph-btn1" onClick={() => {getGraphInfo("ALL"); amplitude.getInstance().logEvent("assets_graph_all")}} style={{color: showBy === "ALL" ? "#c5b358" : "rgb(100,100,100)"}}>ALL</button>
                <button className="graph-btn1" onClick={() => {getGraphInfo("1Y"); amplitude.getInstance().logEvent("assets_graph_1y")}} style={{color: showBy === "1Y" ? "#c5b358" : "rgb(100,100,100)"}}>1Y</button>
                <button className="graph-btn1" onClick={() => {getGraphInfo("3M"); amplitude.getInstance().logEvent("assets_graph_3m")}} style={{color: showBy === "3M" ? "#c5b358" : "rgb(100,100,100)"}}>3M</button>
                <button className="graph-btn1" onClick={() => {getGraphInfo("1M"); amplitude.getInstance().logEvent("assets_graph_1m")}} style={{color: showBy === "1M" ? "#c5b358" : "rgb(100,100,100)"}}>1M</button>
                <button className="graph-btn1" onClick={() => {getGraphInfo("1W"); amplitude.getInstance().logEvent("assets_graph_1w")}} style={{color: showBy === "1W" ? "#c5b358" : "rgb(100,100,100)"}}>1W</button>
            </div>}
        </div>
        }

        {(!prices || prices.length <=1 || !labels || labels.length <= 1)&& !isMobile &&
            <div style={{display: 'flex', justifyContent: 'space-around', color: 'gray', paddingTop: '100px', height: '100%', borderRadius: '2rem', border: '1px solid gray'}}>
                Your graph will appear once it has enough data points
            </div>
        }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    userPrices: state.userPrices.userPrices,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(Graph)