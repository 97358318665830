import * as types from "../types";

const initialState = {teamsValue: []};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_TEAMS_VALUE:
            return {
                ...state,
                teamsValue: payload
            };
        default:
            return state;
    }
}