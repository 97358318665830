import React from 'react'
import ResponsiveAppBar from './ResponsiveAppBar'
import {html} from './index'
import ReactHtmlParser from 'react-html-parser'
import NavBar from './NavBar'
import Top from './Top'
import Footer from './Footer'
import SocialProof from './SocialProof'


const LandingPage4 = () => {
    return(
        <>
        <NavBar />
        <Top />
        {/*<SocialProof />
        <hr />
        <Footer />*/}
        </>
    )   
}

export default LandingPage4