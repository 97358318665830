import React, {useState, useRef, useEffect} from 'react'
import '../LandingPage4/landingPage.css'
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom'
import {signup} from '../../routes/auth/auth'
import {getToken} from '../../redux/actions/token'
import {firebaseAnalytics} from '../../services/firebase'
import './SimBull16.css'

const Signup = (props) => {

    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const emailField = useRef(null)
    const passwordField = useRef(null)

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    let history = useHistory()
    const dispatch = useDispatch()
    
    const handleEmail = (e) => {
        if(username === email && (!e.target.value.includes('@') && !email.includes('@'))){
            setUsername(e.target.value)
        }
        setEmail(e.target.value)
        
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSignupRes = (res) => {
        let carry
        //mail with no '@', already used email
        //short(long) username, already used username
        //short password
        if(res.status === 200){
            carry = {type: 'success', data: res} //res returns {refresh_token: 'token', access_token: 'token'}
        }else if(res.status === 400){
            if(res.data && res.data.msg && !res.data.msg.query){ //already used email -or- already user username
                carry = {type: 'err', msg: res.data.msg}
                if(carry.msg = 'username is taken'){
                    let rand = (Math.random() * 1000).toString().substring(0,3)
                    signup(email, `user${rand}`, password, 'First', 'Last').then(res => {
                        let result = handleSignupRes(res)
                        if(result.type === 'success'){
                            dispatch(getToken(res))
                            if(props.submit){
                                props.submitPicks(res).then(result => {
                                    history.push('/PicksWelcome')
                                })
                            }
                            else{
                                window.location.href = 'https://simbull.com/picks'
                            }
                            
                        }
                        else if(result.type === 'err'){
                            setError(true)
                            setErrorMsg(result.msg)
                        }
                        else {
                            setError(true)
                            setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
                        }
                    }).catch(err => alert('Error occurring. Please refresh and try again.'))
                    }
            }
            else if(/*res.data.msg.query.email[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.email && res.data.msg.query.email[0]){
                carry = {type: 'err', msg: res.data.msg.query.email[0]}//short(long) or missing email
            }
            else if(/*res.data.msg.query.username[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.username && res.data.msg.query.username[0]){
                carry = {type: 'err', msg: `Username ${res.data.msg.query.username[0]}`} //short(long) or missing username
                let rand = (Math.random() * 1000000000).substring(0,8)
                signup(email, `user${rand}`, password, 'First', 'Last').then(res => {
                    let result = handleSignupRes(res)
                    if(result.type === 'success'){
                        dispatch(getToken(res))
                        if(props.submit){
                            props.submitPicks(res).then(result => {
                                history.push('/PicksWelcome')
                            }).catch(err => {
                                alert('Try to pick again')
                            })
                        }
                        else{
                            window.location.href = 'https://simbull.com/picks'
                        }
                    }
                    else if(result.type === 'err'){
                        setError(true)
                        setErrorMsg(result.msg)
                    }
                    else {
                        setError(true)
                        setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
                    }
                }).catch(err => alert('Error occurring. Please refresh and try again.'))
            }
            else if(/*res.data.msg.query.password[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.password && res.data.msg.query.password[0]){
                carry = {type: 'err', msg: `Password ${res.data.msg.query.password[0]}`} //short(long) or missing password
            }
            else if(/*res.data.msg.query.first_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.first_name && res.data.msg.query.first_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.first_name[0]} //short(long) or missing first_name
            }
            else if(/*res.data.msg.query.last_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.last_name && res.data.msg.query.last_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.last_name[0]} //short(long) or missing last_name
            }
            else {
                setError(true)
                setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
            }
        }
        else if(res.access_token && res.refresh_token){
            carry = {type: 'success', data: res}
        }
        else if(res.status === 500){
            carry = {type: 'err', msg: 'Error, please refresh'}
        }
        else {
            carry = {type: 'err', msg: 'Unknown Error, please refresh and try again or contact us at customer-service@simbull.app'}
        }
        return carry
    }

    const handleClick = (e) => {

        firebaseAnalytics.logEvent('signup_picks')

        signup(email, username, password, 'First', 'Last').then(res => {
            let result = handleSignupRes(res)
            if(result.type === 'success'){
                dispatch(getToken(res))
                
                if(props.submit){
                    props.submitPicks(res).then(result => {
                        history.push('/PicksWelcome')
                    }).catch(err => alert('Picks not registering. Please refresh and try again.'))
                }
                else{
                    window.location.href = 'https://simbull.com/picks'
                }
            }
            else if(result.type === 'err'){
                setError(true)
                setErrorMsg(result.msg)
            }
            else {
                setError(true)
                setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
            }
        }).catch(err => alert('Error occurring. Please refresh and try again.'))
        
        /*else{
            alert('Please fill out both fields.')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
        }*/
    }


    //capture autofill 
    useEffect(() => {
        let interval = setInterval(() => {
          if (emailField.current) {
            setEmail(emailField.current.value)
            setPassword(passwordField.current.value)
            //do the same for all autofilled fields
            clearInterval(interval)
          }
        }, 100)
        
    })

    return(
        <>
        <div className={`Bracket-signup ${props.visible ? 'visible' : ''}`} style={{marginBottom: props.visible ? '10px' : ''}}>

                {error && <div style={{color: 'red'}}>Error: {errorMsg}</div>}
                <div className="Top-CTA-email">Enter your Email:</div>
                <input className="Top-CTA-input" placeholder="your@email.com" style={{width: '100%'}} email={email} onChange={handleEmail} />
                <div className="Top-CTA-email">Enter your password:</div>
                <input className="Top-CTA-input-signup" placeholder="Password" type="password" value={password} onChange={handlePassword} />
                <button className="Top-CTA-btn-signup" onClick={handleClick}>Sign Up <i class="fa fa-arrow-right" style={{fontSize: '1em', marginLeft: '5px', fontWeight: '400'}}></i></button>

                <div className="Top-CTA-free" style={{textAlign: 'center', marginTop: '3px'}}>Free to Sign-Up. No Credit Card. No B.S.</div>
        </div>
        </>
    )
}

export default Signup