import React from 'react'
import './newHomeBar.css'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import Vector from './Vector.png'
import {useHistory} from 'react-router-dom'
import amplitude from 'amplitude-js'

function Ads() {
    const history = useHistory()
  return (
    <div className="ads">
        {/*<div className="ads-header">
            <div className="ads-arrows">
                <ArrowBackOutlinedIcon style={{fontSize: 20, color: "white"}}/>
                <ArrowForwardOutlinedIcon style={{fontSize: 20, color: "white"}}/>
            </div>
            <div className="ads-close">
                <CancelPresentationOutlinedIcon style={{fontSize: 20, color: "white"}}/>
            </div>
  </div>*/}
        <div className="ads-content" style={{marginTop: '8px'}}>
            <div className="ads-content-left">
                <div className="ads-content-inner">
                    <h1 style={{fontSize: 13, color: "white"}}>Get Free Trading with GOLD!</h1>
                </div>
                <div className="ads-content-middle">
                    <p style={{fontSize: 10, color: "rgba(112, 112, 112, 1)", fontWeight: "bold"}}>
                        Trade for free with better information, plus a whole lot more.
                    </p>
                </div>
                <div className="ads-content-button">
                    <button onClick={()=>{history.push('/GOLD'); amplitude.getInstance().logEvent("dashboard_ads")}} className="ads-btn" style={{border: 'none', outline: 'none', marginBottom: '8px'}}>
                        <span style={{fontSize: 12, color: "black", marginTop: 0}}>Learn More</span>
                    </button>
                </div>
            </div>
            <div className="ads-content-right">
                <img src={Vector} alt="vector"/>
            </div>
        </div>
    </div>
  )
}

export default Ads;