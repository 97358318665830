import { BiotechSharp } from '@mui/icons-material'
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import TeamCard from './BitCard'
import {_bits} from './bits_data'


const Learn = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px', paddingBottom: isMobile ? '8vh':'0px'}}>
            <div style={{color: 'white', fontSize: '1.4rem'}}>Bullish Bits</div>
            {_bits.map(b => {
                return <TeamCard bit={b} key={`${b.title}1`} />
            })}
        </div> 
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(Learn)