import Helmet from "react-helmet";
import React, {useCallback, useRef, useState} from "react";

import AuthCode from "react-auth-code-input";

import Navbar from "../LandingPage4/v2/NavBar2";

import "./signup.css";
import {Form, InputGroup, Button, Row, Col} from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import {completePhoneVerification, newSignup, startPhoneVerification} from "../../routes/auth/auth";
import {getToken} from "../../redux/actions/token";
import {useDispatch, useSelector} from "react-redux";
import {updateAccountInfo} from "../../routes/user/account";
import {get_my_user} from "../../redux/actions/user/myuser";

const formatDate = date => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

const SignupPage = () => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [apiErrors, setApiErrors] = useState({});

    const [acknowledged, setAcknowledged] = useState(false);
    const [token, setToken] = useState(null);
    const [translation, setTranslation] = useState(0);
    const [phoneSubmitted, setPhoneSubmitted] = useState(false);
    const authCodeRef = useRef(null);

    const user = useSelector(state => state.myuser?.myuser || {});
    const dispatch = useDispatch();
    const history = useHistory();

    // const location = useLocation();
    // const appView = (new URLSearchParams(location.search)).get('appview') === '1';
    // const exitApp = () => {
    //     window.ReactNativeWebView?.postMessage(token ? `FINISH|${token.access_token}|${token.refresh_token}` : 'CLOSE');
    // }

    const exitWeb = () => {
        dispatch(getToken(token));
        history.push({pathname: '/Redeem', state: {onboarding: true}});
    }

    const dataValid = useCallback(() => Object.keys(formData).length >= 4 && Object.values(errors).filter(x => x).length === 0 && acknowledged, [acknowledged, formData, errors]);

    const handleChange = (property, optional = false) => event => {
        const value = property === 'code' ? event : event?.target?.value;
        formData[property] = value;
        setFormData(formData);

        let currentError;

        if (property !== 'phone' && !optional && (!value || value === ''))
            currentError = `Please enter a valid ${property}.`;
        else if (property === 'email' && value && value.indexOf('@') === -1)
            currentError = "Please enter a real email address.";
        else if (property === 'password' && value && value.length < 8)
            currentError = "Password must be at least 8 characters long.";
        else if (property === 'first_name' && value)
            currentError = "Your first name is required.";
        else if (property === 'last_name' && value)
            currentError = "Your first name is required.";
        else if (property === 'referral' && value  && value.length < 2)
            currentError = "Please enter a valid referral code.";
        else if (property === 'birthday' && value && !value.match(/\d{4}[-/]\d{1,2}[-/]\d{1,2}/))
            currentError = "Please enter a valid birthday.";
        else if (property === 'birthday' && value && (new Date() - new Date(value)) / 31536000000 < 18)
            currentError = "You must be 18 years or older to use SimBull!";
        else if (property === 'phone') {
            setPhoneSubmitted(false);
            if (value && !value.match(/^[0-9-()+ ]{7,}$/))
                currentError = "Please enter a valid phone number.";
        }
        else if (property === 'code' && value && !value.match(/^[0-9]{6}$/))
            currentError = "Please enter a valid code";

        setErrors({...errors, [property]: currentError});
    }

    const submitInfo = () => {
        setApiErrors({...apiErrors, "info": undefined});

        const username = `${formData.email.replace(/[._@-]+/g, '').slice(0, 6)}${(Math.random() * 10000).toString().slice(0, 3)}`;
        newSignup(formData.email, username, formData.password, formData.firstName, formData.lastName, formData.referral)
            .then(res => {
                setToken(res.data);
                setTranslation(100);
                dispatch(get_my_user(res.data.access_token));
                updateAccountInfo(res.data.access_token, null,  formData.birthday); // Response isn't needed, just send the data
            })
            .catch(err => {
                var msg = {
                    "email is taken": "This email address is already in use.",
                    "username is taken": "This username is already in use.",
                    "invalid referral code": "Your referral code is invalid."
                }[err?.response?.data?.msg] || "An unknown error occurred.";
                setApiErrors({...apiErrors, "info": msg});
            });
    }

    const handlePhoneButton = () => {
        if (errors.phone || !formData.phone) return;
        setApiErrors({...apiErrors, "phone": undefined});
        startPhoneVerification(token.access_token, formData.phone).then(() => {
            setPhoneSubmitted(true);
            authCodeRef.current?.focus();
        }).catch(err => {
            setPhoneSubmitted(true);
            setErrors({...errors, "phone": {
                "invalid phone number": "Please enter a valid phone number.",
                "phone number already verified": "Please enter a valid phone number",
                "phone verification request on cooldown": "You've requested too many codes recently, please wait and try again."
            }[err?.response?.data?.msg] || "An unknown error occurred."});
        });
    }

    const handleCodeButton = () => {
        if (errors.code || !formData.code) return;
        completePhoneVerification(token.access_token, formData.code).then(res => {
            setTranslation(200);
        }).catch(err => {
            setErrors({...errors, "code": {
                "invalid verification code": "Please enter a valid verification code.",
                "expired verification code": "This code expired, please try again."
            }[err?.response?.data?.msg] || "An unknown error occurred."});
        })
    }

    return <>
        <Helmet>
            <title>Sign Up to SimBull</title>
            <meta property="og:title" content="Sign Up to SimBull | Create a free account in seconds!" />
            <meta property="og:description" content="Buy and sell shares of your favorite sports teams teams just like the stock market. Create a free account in seconds!" />
            <meta property="og:url" content="https://www.simbull.app/signup" />
            <meta property="og:type" content="website" />
        </Helmet>
        {/*{!appView && <Navbar />}*/}
        <Navbar />
        <div className="signup-wrapper">
            <div className="signup-container" style={{transform: `translateX(-${translation}vw)`}}>
                <div>
                    <div className="signup-title" style={{color: 'white'}}>
                        Create a Free Account and<br />
                        <span className="signup-subtitle">Get Started in Seconds</span>
                    </div>

                    <InputGroup className="signup-form-input">
                        <InputGroup.Text><i className="far fa-envelope" /></InputGroup.Text>
                        <Form.Control type="email" onChange={handleChange('email')} placeholder="Email Address" isInvalid={errors.email} isValid={formData.email && !errors.email} />
                        {errors.email && <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>}
                    </InputGroup>
                    <InputGroup className="signup-form-input">
                        <InputGroup.Text><i className="fas fa-lock" /></InputGroup.Text>
                        <Form.Control type="password" onChange={handleChange('password')} placeholder="Password" isInvalid={errors.password} isValid={formData.password && !errors.password} />
                        {errors.password && <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>}
                    </InputGroup>
                    <InputGroup className="signup-form-input">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="fas fa-user-plus" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" onChange={handleChange('firstName')} placeholder="Legal First Name" isInvalid={errors.firstName} isValid={formData.firstName && !errors.firstName} />
                        <Form.Control className="secondary" type="text" onChange={handleChange('lastName')} placeholder="Legal Last Name" isInvalid={errors.lastName} isValid={formData.lastName && !errors.lastName} />
                        {(errors.firstName || errors.lastName) && <Form.Control.Feedback type="invalid">
                            {errors.firstName || errors.lastName}
                        </Form.Control.Feedback>}
                    </InputGroup>
                    <InputGroup className="signup-form-input">
                        <InputGroup.Text><i className="fas fa-plus" /></InputGroup.Text>
                        <Form.Control type="text" onChange={handleChange('referral', true)} placeholder="Referral Code (Optional)" isInvalid={errors.referral} isValid={formData.referral && !errors.referral} />
                        {errors.referral && <Form.Control.Feedback type="invalid">
                            {errors.referral}
                        </Form.Control.Feedback>}
                    </InputGroup>
                    <InputGroup className="signup-form-input">
                        <InputGroup.Text><i className="fas fa-calendar" /></InputGroup.Text>
                        <Form.Control defaultValue="2004-01-01" type="date" max={formatDate(new Date())} onChange={handleChange('birthday')} placeholder="Birthday (MM/DD/YYYY)" isInvalid={errors.birthday} isValid={formData.birthday && !errors.birthday} />
                        {errors.birthday && <Form.Control.Feedback type="invalid">
                            {errors.birthday}
                        </Form.Control.Feedback>}
                    </InputGroup>

                    <div className="signup-form-checkbox">
                        <input id="signupAcknowledgement" type="checkbox" checked={acknowledged} />
                        <label onClick={() => setAcknowledged(!acknowledged)} htmlFor="signupAcknowledgement">I certify that I am 18 years of age or older, and I agree to the Privacy Policy and Terms of Service</label>
                        <span onClick={() => setAcknowledged(!acknowledged)} />
                    </div>

                    <div className="signup-form-info">
                        <Link to="/Terms-of-Use" target="_blank">Terms of Service</Link>
                        <span>|</span>
                        <Link to="/Privacy-Policy" target="_">Privacy Policy</Link>
                    </div>

                    {apiErrors.info && <p className="signup-form-feedback">{apiErrors.info}</p>}
                    <Button onClick={submitInfo} className="btn-form-signup" size="lg" block disabled={!dataValid()}>
                        Sign Up
                    </Button>
                    {/*{appView && <div className="signup-form-info">*/}
                    {/*    <button role="link" onClick={exitApp}>Cancel Registration</button>*/}
                    {/*</div>}*/}
                </div>
                <div>
                    <div className="signup-title">
                        Verify your Phone Number
                        <p>Enter your phone number so we can send you an authentication code.</p>
                    </div>

                    <InputGroup className="signup-form-input">
                        <InputGroup.Prepend>
                            <InputGroup.Text><i className="fas fa-phone" /> <span style={{fontFamily: 'monospace', marginLeft: '.4em', fontSize: '0.9em'}}>+1</span></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="tel" onChange={handleChange('phone')} placeholder="Phone Number" isInvalid={errors.phone} isValid={formData.phone && !errors.phone} />
                        <InputGroup.Append>
                            <Button onClick={handlePhoneButton} className="btn-form-signup">{phoneSubmitted ? 'Resend Code' : 'Send Code'}</Button>
                        </InputGroup.Append>
                        {errors.phone && <Form.Control.Feedback type="invalid">
                            {errors.phone}
                        </Form.Control.Feedback>}
                    </InputGroup>

                    {errors.code && <p className="signup-form-feedback">{errors.code}</p>}
                    <AuthCode
                        ref={authCodeRef}
                        disabled={!phoneSubmitted}
                        onChange={handleChange('code')}
                        autoFocus={false}
                        allowedCharacters="numeric"
                        placeholder="0"
                        containerClassName="signup-code-container"
                        inputClassName="signup-code-input"
                    />

                    <Button onClick={handleCodeButton} className="btn-form-signup" size="lg" block disabled={errors.code || !formData.code}>
                        Submit
                    </Button>
                </div>
                <div>
                    <div className="signup-title">
                        Refer a Friend<br />
                        You both get <span className="signup-highlight"><b>free stock.</b></span>
                    </div>

                    <div className="signup-referral-info">
                        <h5>Invite a friend. <span className="signup-highlight">Get a free team stock.</span></h5>
                        <p>Invite friend to SimBull. Once they sign up and deposit at least $10, you'll get a random free team stock.</p>
                        <h5>100% chance to get a free stock</h5>
                        <p>Every time a new friend signs-up and deposits at least $10, you win a random team stock valued <span className="signup-highlight">UP TO $150!</span></p>
                        <h5>Unlimited invites. Unlimited free stock.</h5>
                        <p>Invite as many friends as you want and build your portfolio as the market grows! Tell your friends to use this code on signup.</p>
                    </div>

                    {user && <Row noGutters className="signup-referral-panel">
                        <Col xs="12">
                            <InputGroup className="signup-form-input">
                                <Form.Control readOnly type="text" value={user.referral_code || 'XXXXXX'} />
                            </InputGroup>
                        </Col>
                        <Col xs={navigator.share ? "6" : "12"}>
                            <Button onClick={e => {
                                const textField = document.createElement('textarea');
                                textField.style.opacity = 0;
                                textField.innerText = user.referral_code;
                                document.body.appendChild(textField)
                                textField.select();
                                document.execCommand('copy')
                                textField.remove();
                                e.target.innerText = 'Copied!';
                            }} className="btn-form-signup">Copy</Button>
                        </Col>
                        {navigator.share && <Col xs="6">
                            <Button onClick={() => {
                                navigator.share({
                                    text: `SimBull: Sports Stock Market | Buy and Sell your favorite sports teams like stocks. Get a free stock worth up to $150 when you download SimBull on the Apple App Store and sign up with code ${user.referral_code}! Download here: https://apps.apple.com/app/apple-store/id1628754092?pt=125012269&ct=appRefer&mt=8`
                                })
                            }} className="btn-form-signup">Share</Button>
                        </Col>}
                    </Row>}

                    {/*<Button className="btn-form-signup" onClick={() => appView ? exitApp() : exitWeb()}>Start Investing in Sports</Button>*/}
                    <Button className="btn-form-signup" onClick={exitWeb}>Start Investing in Sports</Button>
                </div>
            </div>
        </div>
    </>
}

export default SignupPage;