import React, {useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import {Overlay} from 'react-bootstrap'
import './TimeSorter.css'

const TimeSorter = (props) => {

    const [showTimePeriod, setShowTimePeriod] = useState(false)
    const target = useRef(null);

    const changePeriod = props.changePeriod
    const periods = props.periods
    const period = props.period

    return(
        <>
        <div className='Teammate-TimeSorter'>
            <Link  className="Teammate-TimeSorter-Display" ref={target} onClick={()=>{setShowTimePeriod(!showTimePeriod)}}>
                {period}{' '}<i style={{marginLeft: '5px'}} className="fas fa-ellipsis-v"></i>
            </Link>
            <Overlay className="Teammate-TimeSorter-Drop" target={target.current} show={showTimePeriod} placement="bottom">
                {(props) => (
                    <div className='Teammate-TimeSorter-Div'  id="overlay-example" {...props}>
                            {periods && period.length > 0 && periods.map(p => {
                                return(
                                    <button className="Teammate-TimeSorter-Item" onClick={()=>{changePeriod(p);setShowTimePeriod(false)}}>{p}</button>
                                )
                            })} 
                    </div>
                )}
            </Overlay>
        </div>
        </>
    )
}

export default TimeSorter