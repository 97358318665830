import React, {useState, useEffect, useRef} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import {getGraphInfo} from './Functions'
import TeamGraph from '../../Holdings/TeamGraph'
import DoughnutTeam from './DoughnutTeam'
import BarTeam from './BarTeam'
import Returns from './Returns'
import History from './History'
import Offers from './Offers'
import GoldReport from './GoldReport'
import Trades from './Trades'
import BuyShare from '../../Functionality2/BuyShare'
import SellShare from '../../Functionality2/SellShare'
import {Overlay, Tooltip} from 'react-bootstrap'
import CreateBid from '../../Functionality2/CreateBid'
import CreateAsk from '../../Functionality2/CreateAsk'
import OrderBook from './OrderBook'
import Stats from './Stats'
import Popup from 'reactjs-popup'
import MyOffers from './MyOffers'
import {useLocation} from 'react-router-dom'
import {getOffersByTeam} from '../../../routes/trading/trading'
import {getTeamPriceHistory, getTeamsWinHistory} from '../../../routes/teams/teams'
import {getTrades} from './Functions'
import {getDates, weeklyGraph} from './getDates'
import Loader from '../../adHoc/Loader'
import {
    getWinStandings,
    getPriceStandings,
    getWinStandings_Conference,
    getPriceStandings_Conference,
    giveNumberPlace,
  } from "./utils";
import WatchlistStar from '../WatchlistDesktop/WatchlistStar'
import amplitude from 'amplitude-js'
import logo1 from '../../assets/Logo1.png'

const TeamPage = (props) => {
    const user = props.user
    let _id = props && props.match && props.match.params && props.match.params.id

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const location = useLocation()

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    const [teamsValue, setTeamsValue] = useState(props.teamsValue ? props.teamsValue : [])
    const [equity, setEquity] = useState(props.equity)
    useEffect(()=> {
        if(teamsValue.length === 0 && props.teamsValue.length > 0){
            setTeamsValue(props.teamsValue)
        }
    }, [props.teamsValue, _id])
    useEffect(()=> {
        if(equity.length === 0 && props.equity && props.equity.length > 0){
            setEquity(props.equity)
        }
        else if(props.equity){
            setEquity(props.equity)
        }
    }, [props.equity, _id])

    const [team, setTeam] = useState({})
    const [t, setT] = useState({})
    const [shares, setShares] = useState(0)
    const [priceHistory, setPriceHistory] = useState([])
    const [week, setWeek] = useState({prices: [], labels: []})
    const [tradeBuyPercent, setTradeBuyPercent] = useState(1)
    const updateTradeBuyPercent = (x) => setTradeBuyPercent(x)
    useEffect(()=>{
        teamsValue.map(async te => {
            if(te.team.name === props.match.params.id){
                setT(te)
                getTeamPriceHistory(te.team.id).then(res => {
                    setPriceHistory(res)
                })
                let weeks = await weeklyGraph(te.team.id)
                setWeek(weeks)
            }
        })
    },[teamsValue, _id])
    //get the specific number of shares you own of this iteam
    function getNumOfShares() {
        let numOfShares = 0
        if(t && t.team && t.team.id){
            equity && equity.length > 0 && equity.map(e => {
                if(e.asset_id === t.team.id){
                    numOfShares = e.amount
                }
            })
        }
        return(numOfShares)
    }
    useEffect(()=>{
        if(t && equity && equity.length > 0 ){
            if(shares === 0){
                let _shares = getNumOfShares()
                setShares(_shares)
            }
            
        }
        
    },[t, equity, _id])
    useEffect(()=>{
        setShares(0)
    },[_id])

    const [showBy, setShowBy] = useState("1M")
    const [labelArr, setLabelArr] = useState([])
    const [pricesArr, setPricesArr] = useState([])
    const runGraph = (time) => {
        setShowBy(time)
        let length = 29
        if(time === '1D'){
            setPricesArr(week[2])
            setLabelArr(week[3])
        }
        else if(time === '1W'){
            setPricesArr(week[0])
            setLabelArr(week[1])
        }
        else{
            if(time === '3M'){
                length = 90
            }
            else if(time === '1Y'){
                length = 364
            }
            else if(time === 'ALL'){
                length = priceHistory.length
            }
            let pricesArr1 = []
            let labelArr1 = []
            if(priceHistory && priceHistory.length > 0){
                /*let showArr = priceHistory
                if(priceHistory.length > length){
                    showArr = priceHistory.slice(priceHistory.length - length, priceHistory.length)
                }
                showArr.map(p => {
                    pricesArr1.push(p.price.toFixed(2))
                    labelArr1.push(p.timestamp.substring(0,10))
                })*/
                let hodlerArr = getDates(priceHistory[0].timestamp.substring(0,10), priceHistory)
                if(hodlerArr[0].length > length){
                    hodlerArr[0] = hodlerArr[0].slice(hodlerArr[0].length - length, hodlerArr[0].length)
                    hodlerArr[1] = hodlerArr[1].slice(hodlerArr[1].length - length, hodlerArr[1].length)
                }
                pricesArr1 = hodlerArr[1]
                labelArr1 = hodlerArr[0]
            }
            /*if(t){
                pricesArr1.push(t.asking_price.toFixed(2))
                labelArr1.push(moment().format('YYYY-MM-DD'))
            }*/
            setPricesArr(pricesArr1)
            setLabelArr(labelArr1)
        }
        
    }
    useEffect(()=>{
        if(t && priceHistory){
            runGraph('1M')
        }
    },[t, priceHistory])

    useEffect(()=>{window.scrollTo(0,0)},[])

    /*Buy Share*/
    const [showBuy, setShowBuy] = useState(false)
    const closeBuy = () => {setShowBuy(false)}
    /*Sell Share */
    const [showSell, setShowSell] = useState(false)
    const closeSell = () => {setShowSell(false)}

    useEffect(()=>{
        if(!showBuy && location && location.search && location.search === '?deposit'){
            setShowBuy(true)
        }
    },[location])
    
    /*Offers*/
    const [showAsk, setShowAsk] = useState(false)
    const [showBid, setShowBid] = useState(false)
    const closeBid = () => {window.scrollTo(0,0);setShowBid(false)}
    const closeAsk = () => {window.scrollTo(0,0);setShowAsk(false)}
    

    //Team 
    //Record
    //Price
    //Graph
    //Offers --> GOLD wall?
    //Buy/Sell Buttons with emphasis on buy and no sell 
    //Returns and Performance if I own it //Robinhood
    //Range Picks
    //Stats: High, Low, Season Payout, Team Cap, Price/Win, Win Payouts % //Coinbase
    //Recent Trades

    //Week's Volume
    //buy sell hold //both display and input
    //team chat box //put at bottom for scrollers
    //loop articles in for this team
    //gold report //robinhood --> What if anyone could submit a report? And the top voted one at x times receive some money?
    //my history //robinhood your transactions with the stock

    const getTeamReturn = (_period) => {
        let period = _period ? _period : showBy
        let teamReturn = ''
        let changeObj = t.change;
        if(period === '1D'){
            teamReturn = `${changeObj.day >= 0 ? '+' : ''}${(changeObj.day * 100).toFixed(2)}%`
        }
        else if(period === '1W'){
            teamReturn = `${changeObj.week >= 0 ? '+' : ''}${(changeObj.week * 100).toFixed(2)}%`
        }
        else if(period === '1M'){
            teamReturn = `${changeObj.month >= 0 ? '+' : ''}${(changeObj.month * 100).toFixed(2)}%`
        }
        else if(period === '3M'){
            teamReturn = `${changeObj.three_month >= 0 ? '+' : ''}${(changeObj.three_month * 100).toFixed(2)}%`
        }
        else if(period === '1Y'){
            teamReturn = `${changeObj.year >= 0 ? '+' : ''}${(changeObj.year * 100).toFixed(2)}%`
        }
        else {
            teamReturn = `${changeObj.total >= 0 ? '+' : ''}${(changeObj.total * 100).toFixed(2)}%`
        }
        return teamReturn
    }
    const getTeamChange = (_period)=>{
        let period = _period ? _period : showBy
        let teamReturn = ''
        let changeObj = t.change;
        if(period === '1D'){
            teamReturn = `${changeObj.day >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.day))).toFixed(2)}`
        }
        else if(period === '1W'){
            teamReturn = `${changeObj.week >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.week))).toFixed(2)}`
        }
        else if(period === '1M'){
            teamReturn = `${changeObj.month >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.month))).toFixed(2)}`
        }
        else if(period === '3M'){
            teamReturn = `${changeObj.three_month >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.three_month))).toFixed(2)}`
        }
        else if(period === '1Y'){
            teamReturn = `${changeObj.year >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.year))).toFixed(2)}`
        }
        else {
            teamReturn = `${changeObj.total >= 0 ? '+' : '-'}$${Math.abs(t.asking_price - (t.asking_price / (1 + changeObj.total))).toFixed(2)}`
        }
        return teamReturn
    }
    /* My Team Trades */
    const [trades, setTrades] = useState([])
    useEffect(()=>{
        if(props.token && t && t.team && t.team.id && trades.length === 0 ){
            getTrades(props.token, t).then(res => {
                setTrades(res)
            })
        }
    },[t, props.token, _id])
    const [returns, setReturns] = useState(0)
    const [paidForShares, setPaidForShares] = useState(0)
    const getReturn = (res) => {
        let num = shares
        let currentAmt = 0
        let currentPaid = 0
        let _trades = res ? res : trades
        _trades.map((t) => {
            if (currentAmt < num && currentAmt + t.amount <= num) {
              let amt = t.amount;
              let paid = t.price * t.amount;
              
              if (t.buyer_id === props.myuser?.id) {
                currentAmt += amt;
                currentPaid += paid;
              }
            } else if (currentAmt < num && currentAmt + t.amount > num) {
              let partialAmt = num - currentAmt;
              let partialPaid = partialAmt * t.price;
              if (t.buyer_id === props.myuser?.id) {
                currentAmt += partialAmt;
                currentPaid += partialPaid;
              }
            }
          });
        setPaidForShares(currentPaid)
        setReturns((num * t.asking_price - currentPaid))
    }
    useEffect(()=>{
        if(shares !== 0 && trades && trades.length > 0 && returns === 0){
            getReturn()
        }
    },[shares, trades, _id])

    const [winHistory, setWinHistory] = useState([])
    useEffect(()=>{
        console.log(t)
        if(t && t.team?.id && winHistory.length === 0){
            getTeamsWinHistory(t.team.id).then(res => {
                setWinHistory(res.sort(function(a, b) {
                    return b.year - a.year;
                }))
            })
        }
    },[t, _id])

    const [ranBuyNow, setRanBuyNow] = useState(false)
    useEffect(()=>{
        if(location.state && location.state.buyNow && !ranBuyNow){
            setShowBuy(true)
        }
    },[location.state])

    let componentRef = useRef()
    const TeamLook = React.forwardRef((props, ref) => {
        return(
            <>

            {isMobile ? 
            <div style={{paddingLeft: isMobile ? '12px':'48px', paddingRight: isMobile ? '12px':'48px', paddingTop: '4px', paddingBottom: '12px', backgroundColor: 'black', width: '100%', verticalAlign: 'bottom'}} ref={ref}>
            <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                
                
                    <div style={{color: 'white', fontSize: '1.75rem', fontWeight: '600', display: 'inline-block', overflow: 'visible'}}>
                        Sim{t && t.team && t.team.name} 
                        <span style={{fontSize: '1rem', fontWeight: '300', color: 'darkgray', marginLeft: '8px'}}>
                        ${t.team && t.team.league}-{t.team && t.team.abbr}
                    </span>
                    </div>
                    
                    <div style={{ color: '#c4c4c4', fontSize: '1.75rem', overflow: 'visible'}}>
                        ${t && t.asking_price && t.asking_price.toFixed(2)} {t && <WatchlistStar _id={t.team.id} />}
                    </div>

                    <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '55%', marginLeft: '.5%'}} />

                    
                
            </div>
            <div style={{width: '100%', display: 'inline-block', textAlign: 'right', paddingTop: '00px', height: '100%'}}>
                
                <div style={{height: '100%', verticalAlign: 'top', marginTop: '20px', display: 'inline-block', fontWeight: '400', textAlign: 'right', paddingRight: '36px', color: '#c4b454'}}>
                    {t ? getTeamChange() : '+0.00'} [{t ? getTeamReturn() : '+0.00%'}] - {showBy}
                </div>
                <div style={{display: 'inline-block', width: '100%', marginLeft: '0px', height: '100%'}}>
                    {/*Graph*/}
                    {labelArr && pricesArr && <TeamGraph labels={labelArr} prices={pricesArr} show={true} className="te-graph" />}
                    <div style={{width: '100%', marginLeft: '0%', marginRight: '0%', display: 'inline-block'}}>
                        <button className="graph-btn1" onClick={() => runGraph("ALL")} style={{border: showBy === "ALL" ? "1px solid #c5b358" : "none", width: '16.66%'}}>ALL</button>
                        <button className="graph-btn1" onClick={() => runGraph("1Y")} style={{border: showBy === "1Y" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1Y</button>
                        <button className="graph-btn1" onClick={() => runGraph("3M")} style={{border: showBy === "3M" ? "1px solid #c5b358" : "white", width: '16.66%'}}>3M</button>
                        <button className="graph-btn1" onClick={() => runGraph("1M")} style={{border: showBy === "1M" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1M</button>
                        <button className="graph-btn1" onClick={() => runGraph("1W")} style={{border: showBy === "1W" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1W</button>
                        <button className="graph-btn1" onClick={() => runGraph("1D")} style={{border: showBy === "1D" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1D</button>
                    </div>
                </div>
            </div>
        </div>
        :
        <div style={{paddingLeft: isMobile ? '12px':'48px', paddingRight: isMobile ? '12px':'48px', paddingTop: '4px', paddingBottom: '12px', backgroundColor: 'black', width: '100%', verticalAlign: 'bottom'}} ref={ref}>
                <div style={{width: '25%', display: 'inline-block', verticalAlign: 'top'}}>
                    
                    
                        <div style={{color: 'white', fontSize: '1.75rem', fontWeight: '600', display: 'inline-block', overflow: 'visible'}}>
                            Sim{t && t.team && t.team.name} 
                            <span style={{fontSize: '1rem', fontWeight: '300', color: 'darkgray', marginLeft: '8px'}}>
                            ${t.team && t.team.league}-{t.team && t.team.abbr}
                        </span>
                        </div>
                        
                        <div style={{ color: '#c4c4c4', fontSize: '1.75rem', overflow: 'visible'}}>
                            ${t && t.asking_price && t.asking_price.toFixed(2)} {t && <WatchlistStar _id={t.team.id} />}
                        </div>

                        <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '55%', marginLeft: '.5%'}} />

                        <div style={{ verticalAlign: 'bottom',color: 'white', marginTop: '0px', }}>
                            <div style={{marginBottom: '1px'}}>
                                <span style={{color: '#c4c4c4', fontSize: '.75rem', fontWeight: '300'}}>Record (W - L)</span>
                                <br />
                                <span style={{color: 'white', fontSize: '.9rem', marginLeft: '0px'}}>{t && t.team && t.team.season_wins
                                    ? t.team.season_wins
                                    : "0"}{" "}
                                    -{" "}
                                    {t && t.team && t.team.season_losses
                                    ? t.team.season_losses
                                    : "0"}
                                </span>
                            </div>
                            <div style={{marginBottom: '1px', overflow: 'visible'}}>
                                <span style={{color: '#c4c4c4', fontSize: '.75rem', fontWeight: '300' }}>{t && t.team && t.team.league} Standings</span>
                                <br />
                                <span style={{color: 'white', fontSize: '.9rem', marginLeft: '0px'}}>{teamsValue
                                    ? getWinStandings(t, teamsValue)
                                    : ""}
                                {teamsValue
                                    ? giveNumberPlace(
                                        getWinStandings(t, teamsValue)
                                    )
                                    : ""}{" "}
                                in {t && t.team.league} |{" "}
                                {teamsValue
                                    ? getWinStandings_Conference(t, teamsValue)
                                    : ""}
                                {teamsValue
                                    ? giveNumberPlace(
                                        getWinStandings_Conference(t, teamsValue)
                                    )
                                    : ""}{" "}
                                in {t && t.team.conference}</span>
                            </div>
                            <div style={{marginBottom: '1px'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.75rem', fontWeight: '300' }}>{t && t.team && t.team.league} Prices</span>
                                <br />
                                <span style={{color: 'white', fontSize: '.9rem', marginLeft: '0px'}}>
                                    {teamsValue
                                        ? getPriceStandings(t, teamsValue)
                                        : ""}
                                    {teamsValue
                                        ? giveNumberPlace(
                                            getPriceStandings(t, teamsValue)
                                        )
                                        : ""}{" "}
                                    in {team && t.team.league} |{" "}
                                    {teamsValue
                                        ? getPriceStandings_Conference(t, teamsValue)
                                        : ""}
                                    {teamsValue
                                        ? giveNumberPlace(
                                            getPriceStandings_Conference(
                                            t,
                                            teamsValue
                                            )
                                        )
                                        : ""}{" "}
                                    in {t && t.team.conference}
                                </span>
                            </div>
                            <div style={{marginBottom: '1px'}}>
                                <span style={{color: '#c4c4c4', fontSize: '.75rem', fontWeight: '300'}}>Trade Activity</span>
                                <br />
                                <span style={{color: 'white', fontSize: '.9rem', marginLeft: '0px', color: 'gray'}}>
                                    <span
                                        style={{
                                        color: tradeBuyPercent >= 0.4955 ? "green" : "gray",
                                        }}
                                    >
                                        {tradeBuyPercent && (tradeBuyPercent * 100).toFixed(1)}% buy
                                    </span>{" | "}
                                    <span
                                        style={{ color: tradeBuyPercent < 0.4955 ? "red" : "gray" }}
                                    >
                                        {tradeBuyPercent &&
                                        ((1 - tradeBuyPercent) * 100).toFixed(1)}
                                        % sell
                                    </span>
                                </span>
                            </div>
                            <div style={{marginBottom: '1px'}}>
                                <span style={{color: '#c4c4c4', fontSize: '.75rem', fontWeight: '300' }}>Supply | Market Cap</span>
                                <br />
                                <span style={{color: 'white', fontSize: '.9rem', marginLeft: '0px'}}>{t && t.team && t.team.shares} | ${t && t.team && (t.team.shares * t.asking_price).toFixed(0)}</span>
                            </div>
                        </div>
                    
                </div>
                <div style={{width: '75%', display: 'inline-block', textAlign: 'right', paddingTop: '20px', height: '100%'}}>
                    
                    <div style={{height: '100%', verticalAlign: 'top', marginTop: '20px', display: 'inline-block', fontWeight: '400', textAlign: 'right', paddingRight: '36px', color: '#c4b454'}}>
                        {t ? getTeamChange() : '+0.00'} [{t ? getTeamReturn() : '+0.00%'}] - {showBy}
                    </div>
                    <div style={{display: 'inline-block', width: '100%', marginLeft: '10px', height: '100%'}}>
                        {/*Graph*/}
                        {labelArr && pricesArr && <TeamGraph labels={labelArr} prices={pricesArr} show={true} className="te-graph" />}
                        <div style={{width: '95%', marginLeft: '2.5%', marginRight: '0%', display: 'inline-block'}}>
                            <button className="graph-btn1" onClick={() => runGraph("ALL")} style={{border: showBy === "ALL" ? "1px solid #c5b358" : "none", width: '16.66%'}}>ALL</button>
                            <button className="graph-btn1" onClick={() => runGraph("1Y")} style={{border: showBy === "1Y" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1Y</button>
                            <button className="graph-btn1" onClick={() => runGraph("3M")} style={{border: showBy === "3M" ? "1px solid #c5b358" : "white", width: '16.66%'}}>3M</button>
                            <button className="graph-btn1" onClick={() => runGraph("1M")} style={{border: showBy === "1M" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1M</button>
                            <button className="graph-btn1" onClick={() => runGraph("1W")} style={{border: showBy === "1W" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1W</button>
                            <button className="graph-btn1" onClick={() => runGraph("1D")} style={{border: showBy === "1D" ? "1px solid #c5b358" : "white", width: '16.66%'}}>1D</button>
                        </div>
                    </div>
                </div>
            </div>
            }
            
            </>
        )
    })

    return(
        <>
        {(t && t.team && t.team.name) ? <div style={{paddingTop: '1vh', paddingBottom: '10vh', paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', backgroundColor: 'black', color: 'white'}}>
            
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '1vh', textAlign: 'left'}}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500', overflow: 'visible'}}>
                        <span style={{fontSize: '1.15rem'}}></span>
                    </div>
                </div>
            : null}
                
            <TeamLook ref={componentRef} />

            {/*Trade Buttons*/}
            <div style={{width: '90%', marginLeft: '5%', marginRight: '5%', paddingTop: '12px', paddingBottom: '12px',}}>
                <button onClick={()=>{setShowBuy(true); amplitude.getInstance().logEvent("team_buy", {name: t.team.name})}} style={{width: shares > 0 ? '22%' : '47%', marginLeft: '1.5%', marginRight: '1.5%', border: 'none', outline: 'none', backgroundColor: 'rgb(30,30,30)', borderRadius: '1rem', color: '#c4b454', padding: '5px', fontSize: '1.2rem', boxShadow: 'rgb(100,100,100) 0px 1px 2px 0px', fontWeight: '600'}}>Buy</button>
                <button onClick={()=>{setShowBid(true); amplitude.getInstance().logEvent("team_bid", {name: t.team.name})}} style={{width: shares > 0 ? '22%' : '47%', marginLeft: '1.5%', marginRight: '1.5%', border: 'none', outline: 'none', backgroundColor: 'rgb(30,30,30)', borderRadius: '1rem', color: 'white', padding: '5px', fontSize: '1.2rem', boxShadow: 'rgb(100,100,100) 0px 1px 2px 0px', fontWeight: '600'}}>Bid</button>
                {shares > 0 && <button onClick={()=>{setShowAsk(true); amplitude.getInstance().logEvent("team_ask", {name: t.team.name})}} style={{width: shares > 0 ? '22%' : '47%', marginLeft: '1.5%', marginRight: '1.5%', border: 'none', outline: 'none', backgroundColor: 'rgb(30,30,30)', borderRadius: '1rem', color: 'white', padding: '5px', fontSize: '1.2rem', boxShadow: 'rgb(100,100,100) 0px 1px 2px 0px', fontWeight: '600'}}>Ask</button>}
                {shares > 0 && <button onClick={()=>{setShowSell(true); amplitude.getInstance().logEvent("team_sell", {name: t.team.name})}} style={{width: shares > 0 ? '22%' : '47%', marginLeft: '1.5%', marginRight: '1.5%', border: 'none', outline: 'none', backgroundColor: 'rgb(30,30,30)', borderRadius: '1rem', color: 'white', padding: '5px', fontSize: '1.2rem', boxShadow: 'rgb(100,100,100) 0px 1px 2px 0px', fontWeight: '600'}}>Sell</button>}
            </div>
            {/*End Trade Buttons*/}
            {showBuy && <BuyShare t={t} closeBuy={closeBuy} />}
            {showSell && <SellShare shares={getNumOfShares()} t={t} closeSell={closeSell} />}
            {showAsk && <CreateAsk shares={getNumOfShares()} team={t} closeAsk={closeAsk} />}
            {showBid && <CreateBid team={t} closeBid={closeBid}  />}
            

            {/*Your Position and Returns*/}
            {shares !== 0 ? <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'white'}}>
                <div style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>Your Position</div>
                <div style={{textAlign: isMobile ? 'left' : 'center'}}>
                    <div style={{display: 'inline-block', width: !isMobile ? '25%': '50%'}}>
                        <div style={{fontSize: '.9rem', color: 'gray'}}>Shares</div>
                        <div style={{fontSize: '1.1rem'}}>{t && getNumOfShares().toFixed(2)}</div>
                    </div>
                    <div style={{display: 'inline-block', width: !isMobile ? '25%': '50%'}}>
                        <div style={{fontSize: '.9rem', color: 'gray'}}>Market Value</div>
                        <div style={{fontSize: '1.1rem'}}>${t.asking_price && (t.asking_price * getNumOfShares()).toFixed(2)}</div>
                    </div>
                    <div style={{display: 'inline-block', width: !isMobile ? '25%': '50%'}}>
                        <div style={{fontSize: '.9rem', color: 'gray'}}>Avg Cost</div>
                        <div style={{fontSize: '1.1rem'}}>${t.asking_price && (paidForShares / getNumOfShares()).toFixed(2)}</div>
                    </div>
                    <div style={{display: 'inline-block', width: !isMobile ? '25%': '50%'}}>
                        <div style={{fontSize: '.9rem', color: 'gray'}}>Portfolio Diversity</div>
                        <div style={{fontSize: '1.1rem'}}>{t.asking_price && ((t.asking_price * getNumOfShares() / (props.totals ? props.totals.total_value : 1))*100).toFixed(2)}%</div>
                    </div>
                </div>
                <div style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px', marginTop: '15px'}}>Earnings</div>
                <div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Today's Share Price +/-</div>
                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{t ? getTeamChange('1D') : '+0.00'} <span style={{fontWeight: '200'}}>[{t ? getTeamReturn('1D') : '+0.00%'}]</span></div>
                {/*<div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Payouts Earned</div>
                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{winPayoutsValue >= 0 ? '+' :'-'}${Math.abs(winPayoutsValue).toFixed(2)} <span style={{fontWeight: '200'}}>(+{((winPayoutsValue/paidForShares)*100).toFixed(2)}%)</span></div>*/}
                <div style={{display: 'inline-block', width: '50%', marginBottom: '10px'}}>Total Share Price +/-</div>
                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', fontWeight: '400'}}>{returns >= 0 ? '+' :'-'}${Math.abs(returns).toFixed(2)} <span style={{fontWeight: '200'}}>[{(((returns) / paidForShares) *100).toFixed(2)}%]</span></div>
                <div style={{color: 'gray', textAlign: 'center', fontSize: '.7rem'}}>Team Shares offered by SimBull Sports Exchange, Inc are not securities and are not FDIC insured or protected by SIPC</div>
            </div>: null}

            {/*<MyOffers t={t} refresh={props.location && props.location.state && props.location.state.refresh ? true : false} />*/}

            {isMobile ? 
                <div style={{ verticalAlign: 'bottom',color: 'white', marginTop: '15px', paddingLeft: '3%', paddingRight: '3%',}}>
                    <div style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>Team Stats</div>
                    <div style={{marginBottom: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                        <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300'}}>Record (W - L)</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', fontWeight: '300'}}>{t && t.team && t.team.season_wins
                            ? t.team.season_wins
                            : "0"}{" "}
                            -{" "}
                            {t && t.team && t.team.season_losses
                            ? t.team.season_losses
                            : "0"}
                        </span>
                    </div>
                    <div style={{marginBottom: '4px', overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>{t && t.team && t.team.league} Standings</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', fontWeight: '300'}}>{teamsValue
                            ? getWinStandings(t, teamsValue)
                            : ""}
                        {teamsValue
                            ? giveNumberPlace(
                                getWinStandings(t, teamsValue)
                            )
                            : ""}{" "}
                        in {t && t.team.league} |{" "}
                        {teamsValue
                            ? getWinStandings_Conference(t, teamsValue)
                            : ""}
                        {teamsValue
                            ? giveNumberPlace(
                                getWinStandings_Conference(t, teamsValue)
                            )
                            : ""}{" "}
                        in {t && t.team.conference}</span>
                    </div>
                    <div style={{marginBottom: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>{t && t.team && t.team.league} Prices</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', fontWeight: '300'}}>
                            {teamsValue
                                ? getPriceStandings(t, teamsValue)
                                : ""}
                            {teamsValue
                                ? giveNumberPlace(
                                    getPriceStandings(t, teamsValue)
                                )
                                : ""}{" "}
                            in {team && t.team.league} |{" "}
                            {teamsValue
                                ? getPriceStandings_Conference(t, teamsValue)
                                : ""}
                            {teamsValue
                                ? giveNumberPlace(
                                    getPriceStandings_Conference(
                                    t,
                                    teamsValue
                                    )
                                )
                                : ""}{" "}
                            in {t && t.team.conference}
                        </span>
                    </div>
                    <div style={{marginBottom: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300'}}>Trade Activity</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', color: 'gray', fontWeight: '300'}}>
                            <span
                                style={{
                                color: tradeBuyPercent >= 0.4955 ? "green" : "gray",
                                }}
                            >
                                {tradeBuyPercent && (tradeBuyPercent * 100).toFixed(1)}% buy
                            </span>{" | "}
                            <span
                                style={{ color: tradeBuyPercent < 0.4955 ? "red" : "gray" }}
                            >
                                {tradeBuyPercent &&
                                ((1 - tradeBuyPercent) * 100).toFixed(1)}
                                % sell
                            </span>
                        </span>
                    </div>
                    <div style={{marginBottom: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Circulating Supply</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', fontWeight: '300'}}>{t && t.team && t.team.shares}</span>
                    </div>
                    <div style={{marginBottom: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Market Cap</span>
                        
                        <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px', fontWeight: '300'}}>${t && t.team && (t.team.shares * t.asking_price).toFixed(0)}</span>
                    </div>
                </div>
            : null}
            
            <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'white', marginBottom: '30px'}}>
                <div style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>Past Records</div>
                <div>
                {winHistory && winHistory.length > 0 && winHistory.map(w => {
                    if(winHistory.indexOf(w) < 12){
                        return(
                            <div style={{display: 'inline-block', width: isMobile ? '45%' : '20%',marginRight: '3%', marginLeft: '2%', borderBottom: '1px solid rgb(30,30,30)'}}>
                                <div style={{display: 'inline-block', width: '50%',color: 'gray'}}>{w.year}</div>
                                <div style={{display: 'inline-block', width: '50%', textAlign: 'right'}}>{w.wins}-{w.losses}</div>
                            </div>
                        )
                    }
                })}  
                </div>
            </div>
            

            {/*Buy Sell Hold --> Copy Polls?
            <div style={{marginTop: '12px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'rgb(50,50,50)'}}>
                <div style={{fontSize: '1.5em', fontWeight: '400', color: 'black', marginBottom: '5px'}}>Sentiment</div>
                <div style={{display: 'inline-block', width: '100%', paddingLeft: '.1%', paddingRight: '.1%'}}><BarTeam /></div>
                <div style={{display: 'inline-block', width: '100%', textAlign: 'center', color: 'gray', fontSize: '0.8em', }}><button style={{border: 'none', outline: 'none', backgroundColor: 'rgb(245,245,245)', borderRadius: '.5em', color: 'gray'}}>vote</button></div>
            </div>*/}

            {/*Stats
            <Stats info={props.info} team={t} />*/}

            {/*My Offers
            <Offers team={team} t={t} />*/}
            
            
            

            {/*GOLD Info
            <GoldReport team={team} />*/}

            {/*Articles*/}

            {/*Trades*/}
            <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'rgb(50,50,50)', marginBottom: '30px'}}>
                <Trades team={t} updateTradeBuyPercent={updateTradeBuyPercent} />
            </div>

            {/*Order Book*/}
            <OrderBook t={t} />

            {/*My History*/}
            {trades && trades.length > 0 && <History team={t} trades={trades} />}

            {shares === 0 ? <div style={{color: 'gray', textAlign: 'center', fontSize: '.7rem', marginTop: '8px'}}>Team Shares offered by SimBull Sports Exchange, Inc are not securities and are not FDIC insured or protected by SIPC</div> : null}
            
        </div>: 
            <Loader />
        }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
    token: state.token.token,
    info: state.info.info,
    myuser: state.myuser.myuser,
    totals: state.totals.totals,
});

export default connect(mapStateToProps)(TeamPage)