import React, {useState, useEffect} from 'react'
import './Sportle.css'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import {firebaseAnalytics} from '../../services/firebase'
import {getNFLsportle, guessNFLsportle, getAbbr, getStreak, getCorrect, getGuesses, resetStreak, addToStreak, addToCorrect, addToGuesses} from './utils'
import Guess from './Guess'
import Status from './Status'
import FirstVisit from './Popups/FirstVisit'
import Failed from './Popups/Failed'
import Success from './Popups/Success'
import {Helmet} from "react-helmet";

const Sportle = (props) => {

    const [hasPlayed, setHasPlayed] = useState(true)
    useEffect(()=>{
        let hasPlayed1 = window.localStorage.getItem('simbull_sportle_played')
        console.log(hasPlayed1)
        if(hasPlayed1 === null){
            setHasPlayed(false)
            window.localStorage.setItem('simbull_sportle_played', true)
        }
    },[])

    const [saveStreak, setSaveStreak] = useState(getStreak() ? parseInt(getStreak()) : 0)
    const [saveCorrect, setSaveCorrect] = useState(getCorrect() ? parseInt(getCorrect()) : 0)
    const [saveGuesses, setSaveGuesses] = useState(getGuesses() ? parseInt(getGuesses()) : 0)

    const [correct, setCorrect] = useState(false)

    const [error, setError] = useState(false)

    const [guesses, setGuesses] = useState([])

    const [players, setPlayers] = useState([])

    const [placeholder, setPlaceholder] = useState('')

    useEffect(async ()=>{
        //do dates
        let date = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Chicago"}))
        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        let dateStr = [year, month, day].join('-');

        let lastPlayed = window.localStorage.getItem('simbull_sportle_last_played_date')

        if(lastPlayed === null || lastPlayed !== dateStr){
            window.localStorage.setItem('simbull_sportle_last_played_date', dateStr) 
            window.localStorage.setItem('simbull_sportle_current_guesses', '') 
        }
        else if(lastPlayed === dateStr){
            let current_guesses = window.localStorage.getItem('simbull_sportle_current_guesses')
            console.log(current_guesses)
            if(current_guesses && (current_guesses !== null || current_guesses !== '')){
                let holder = JSON.parse(current_guesses)
                setGuesses(holder)
                let gotRight = false
                holder && holder.length > 0 && holder.map(h => {
                    if(h.response.name){
                        gotRight = true
                    }
                })
                if(gotRight){
                    setCorrect(true)
                }
            }
            else{
                console.log('empty')
            }
            
            
        }

    },[])

    const guess = async (current) => {
        if(guesses.length > 5){
            alert(`Whomp Whomp. You couldn't guess it correctly in six tries. Try again tomorrow!`)
        }
        else{
            let player = false
            players.map(p => {
                if(current.toLowerCase() === p.name.toLowerCase()){
                    player = p
                }
            })
            if(player){
                let getIt = await guessNFLsportle(props.token, player.id)
                firebaseAnalytics.logEvent("sportle_guess")
                if(guesses.length === 5){
                    resetStreak()
                    setSaveStreak(0)
                }
                setGuesses([...guesses, getIt])
                let stringArr = await JSON.stringify([...guesses, getIt])
                window.localStorage.setItem('simbull_sportle_current_guesses', stringArr)
                addToGuesses()
                setSaveGuesses(saveGuesses + 1)
                if(getIt.response.name){
                    firebaseAnalytics.logEvent("sportle_correct")
                    setCorrect(true)
                    addToCorrect()
                    setSaveCorrect(saveCorrect + 1)
                    addToStreak()
                    setSaveStreak(saveStreak + 1)
                }
                return true
            }
            else return setError(true)
        }
    }

    const correctPick = () => {

    }

    useEffect(async ()=>{
        //get players right away 
        getNFLsportle(props.token).then(res => {
            setPlayers(res)
            let rand = Math.floor(Math.random() * 410)
            if(res.length > rand){
                if(res[rand] && res[rand].name){
                    setPlaceholder(res[rand].name)
                }
            }
        })
    },[])

    useEffect(() => {
        firebaseAnalytics.logEvent("sportle_visit")
        window.scrollTo(0,0)
    }, [])

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Sportle by SimBull | Guess Today's NFL Player</title>
        </Helmet>
        <div className="Sportle">

            {guesses.length < 6 && !correct && <Guess placeholder={placeholder} guess={guess} players={players} />}
            {(guesses.length === 6 || correct) && <>
            
                <div className="Guess-Header">Guess Today's NFL Player</div>
                {correct && <div style={{color: 'green', fontSize: '1.3rem', fontWeight: '300', marginTop: '-6px'}}>Successful!</div>}
                {!correct && <div style={{color: 'red', fontSize: '1.3rem', fontWeight: '300', marginTop: '-6px'}}>Unsuccessful!</div>}
                
            </>}

            <div>
                {guesses && guesses.length > 0 &&<div>
                    <div className="sportle-heading">Conf</div>
                    <div className="sportle-heading">Div</div>
                    <div className="sportle-heading">Team</div>
                    <div className="sportle-heading">Pos</div>
                    <div className="sportle-heading">Side</div>
                    <div className="sportle-heading">Age</div>
                    <div className="sportle-heading">#</div>
                </div>}
                {guesses && guesses.length > 0 && guesses.map(g => {
                    return(
                        <div className='sportle-card' key={g.guess.name}>
                        <div className="sportle-name">{g.guess.name}</div>
                        <div style={{verticalAlgin: 'bottom'}}>
                            <div className={g.response.conference ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{g.guess.conference}</div>
                            </div>
                            <div className={g.response.nfl_division ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div style={{fontWeight: '400'}}>{g.guess.nfl_division.substring(3, g.guess.nfl_division.length)}</div>
                            </div>
                            <div className={g.response.team ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{getAbbr(g.guess.team, props.teamsValue)}</div>
                            </div>
                            <div className={g.response.position ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{g.guess.position}</div>
                            </div>
                            <div className={g.response.role ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{g.guess.role === 'OFFENSE' ? 'O' : 'D'}</div>
                            </div>
                            <div className={g.response.age === true ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{g.guess.age}</div>
                                <div>{g.response.age === 'lower' ? <i class="fa fa-chevron-down" style={{fontSize: '1rem'}}></i> : g.response.age === 'higher' ? <i class="fa fa-chevron-up" style={{fontSize: '1rem'}}></i> : ''}</div>
                            </div>
                            <div className={g.response.number === true ? 'sportle-correct' : 'sportle-incorrect'}>
                                <div>{g.guess.number}</div>
                                <div>{g.response.number === 'lower' ? <i class="fa fa-chevron-down" style={{fontSize: '1rem'}}></i> : g.response.number === 'higher' ? <i class="fa fa-chevron-up" style={{fontSize: '1rem'}}></i> : ''}</div>
                            </div>
                        </div>
                        </div>
                    )
                })}
            </div>

            <Status saveStreak={saveStreak} saveCorrect={saveCorrect} saveGuesses={saveGuesses} />

            <div style={{marginTop: '20px', fontSize: '.6em', color: 'gray'}}>
            <span style={{fontSize: '1.2em'}}>Thanks for playing!</span>
            <br />
             Made by Fans at SimBull
            </div>

            <Popup open={error} modal>
                <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '80vw', marginLeft: '0vw', height: '30vh', padding: '20px', textAlign: 'center', marginTop: '2vh', marginBottom: '0vh', backgroundColor: 'rgb(250,250,250)', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                    
                    <div style={{paddingBottom: '20px'}}><strong>Whoops</strong><br /><br /> This isn't a real name or not one of the players in the game.</div>


                    <div style={{textAlign: 'center'}}>
                        <button onClick={()=>setError(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>close</button>
                    </div>

                    
                    
                </div>
            </Popup>

            <FirstVisit hasPlayed={hasPlayed} />

            <Failed guesses={guesses} correct={correct} />
            <Success correct={correct} saveStreak={saveStreak} saveCorrect={saveCorrect} saveGuesses={saveGuesses} />

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(Sportle)