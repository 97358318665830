import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import {db} from '../../services/firebase'
import firebase from 'firebase'

const SuperBowlPick2021 = (props) => {

    const user = props.user

    const teams = props.teams

    const [selected, setSelected] = useState('')
    useEffect(()=>{
        if(user){
            if(user.superBowl2021){
                setSelected(user.superBowl2021)
            }
        }
    }, [user])

    const [payouts, setPayouts] = useState([])
    const [collected, setCollected] = useState([])
    const [uncollected, setUncollected] = useState([])
    const [userCollected, setUserCollected] = useState(false)
    const [payout, setPayout] = useState({})
    
    const getPayouts = () => {
        let collected1 = []
        let uncollected1 = []
        db.collection('Payouts2').where('type', '==', 'SB2021').get().then(snapshot => {
            snapshot.forEach(doc => { 
                const data = doc.data()
                if(data.collected){
                    collected1.push(data)
                    if(data.collectedBy === user.email){
                        setUserCollected(true)
                    }
                }
                else{
                    uncollected1.push(data)
                }
            })
            setCollected(collected1)
            setUncollected(uncollected1)
        })
    }
    useEffect(()=>{
        getPayouts()
    },[])
    
    const getMyPayout = () => {
        let rand = Math.random()
        rand = (rand * 100000000000000000).toString()
        rand.substring(0,10)
        if(rand > '5'){
            db.collection('Payouts2').where('collected', '==', false).orderBy('id', 'desc').limit(1).get().then(snapshot => {
                snapshot.forEach(doc => {
                    if(doc.exists){
                        setPayout(doc.data())
                    }
                })
            })
        }
        else{
            db.collection('Payouts2').where('collected', '==', false).orderBy('id').limit(1).get().then(snapshot => {
                snapshot.forEach(doc => {
                    if(doc.exists){
                        setPayout(doc.data())
                    }
                })
            })
        }
        
    }
    useEffect(()=>{getMyPayout()},[])

    const makePick = () => {
        let pick = document.getElementById('teamSelect').value
        if(pick){
            db.collection('Users').doc(user.email).update({
                superBowl2021: pick
            }).then(res => alert('Picked'))
            user.superBowl2021 = pick
        }
    }

    const makePayouts = () => {
            let rand = Math.random()
            rand = (rand * 100000000000000000).toString()
            rand.substring(0,10)
            db.collection('Payouts2').doc(rand).set({
                type: 'SB2021',
                amount: 100,
                date: '20210909',
                collected: false,
                collectedBy: null,
                id: rand
            }).catch(err => console.log(err))
    }
   
   const collectPayout = () => {
       if(payout){
           db.collection('Payouts2').doc(payout.id).update({
               collected: true,
               collectedBy: user.email
           }).then(res => {
               db.collection('Users').doc(user.email).update({
                   cash: firebase.firestore.FieldValue.increment(payout.amount),
                   bonusesReceived: firebase.firestore.FieldValue.increment(payout.amount)
               }).then(sult => {
                   if(payout.amount === 100){
                       alert(`Yesss! You are the Grand Prize winner of the $100 Payout! What teams are you going to buy with it?`)
                       return window.location.href = window.location.href
                   }
                   else{
                       alert(`Yesss! You are a winner of a $1 Payout! Unfortunately, you were not the grand prize winner this time.`)
                       return window.location.href = window.location.href
                   }
               })
           })
       }
       else{
           alert('Sorry, payouts have all been collected!')
       }
   }


    return(
        <>
            <div style={{textAlign: 'center', color: 'black', backgroundColor: 'rgb(245,245,245)', padding: '3%', paddingTop: '10vh', paddingBottom: '10vh', minHeight: '100vh', }}>
                <div style={{backgroundColor: 'white', borderRadius: '1rem', width: '90%', marginLeft: '5%', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', padding: '10px'}}>
                    <div style={{fontSize: '2rem', textAlign: 'center', fontWeight: '300', marginBottom: '10px'}}>Pick your Champion for the 2021 Season!
                        <br /><span style={{fontSize: '1.25rem'}}>🏈🏈🏈</span>
                        <div style={{fontSize: '1.1rem', fontWeight: '200'}}>100 Payouts! One $100 Grand Prize! Make your pick to collect your payout!</div>
                    </div>
                    <div style={{textAlign: 'center', fontSize: '1.75rem', fontWeight: '500'}}>💰Payouts💰</div>
                    <table style={{ width: '80%', textAlign: 'center', marginLeft: '10%', marginRight: '10%', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', marginBottom: '15px', marginTop: '0px', textAlign: 'center'}}>
                        
                        <tr>
                            <th style={{width: '50%', textAlign: 'center', fontWeight: '200', backgroundColor: 'green', color: 'white'}}>Left to Collect</th>
                            <th style={{width: '50%', textAlign: 'center', fontWeight: '200', backgroundColor: 'red', color: 'white'}}>Collected</th>
                        </tr>
                        <tr>
                            <td style={{fontSize: '2.5rem', backgroundColor: 'green', fontWeight: '600', color: 'white'}}>{uncollected && uncollected.length}</td>
                            <td style={{fontSize: '2.5rem', backgroundColor: 'red', fontWeight: '600', color: 'white'}}>{collected && collected.length}</td>
                        </tr>
                    </table>
                    {/*<div>
                        <div style={{textAlign: 'center', fontWeight: '300', }}>Left to Collect</div>
                        <div style={{textAlign: 'center', fontWeight: '600', color: 'green', fontSize: '2.5remß'}}>{uncollected.length}</div>
                    </div>*/}
                        {user.superBowl2021 && <>
                                <div><span style={{fontSize: '0.8rem', color: 'gray', fontWeight: '300'}}>Your Pick:</span><br /> 
                                <span style={{fontSize: '2.25rem', color: 'black', fontWeight: '500'}}>{user.superBowl2021}</span></div>
                        </>}
                        <div style={{marginTop: '15px', marginBottom: '5px', fontSize: '1.2rem', fontWeight: '300', borderRadius: '1rem', with: '40%', textAlign: 'center'}}>
                            <select value={selected} onChange={(e)=>{setSelected(e.target.value)}}  style={{fontSize: '1rem', fontWeight: '200'}} id='teamSelect'>
                                {teams.map(t => {
                                    if(t.league === 'NFL'){
                                        return <option key={t.team}>{t.team}</option>
                                    }
                                })}
                            </select>
                            <br />
                            <button style={{border: 'none', backgroundColor: `${selected === user.superBowl2021 ? 'rgb(245,245,245)' : '#c5b358'}`, borderRadius: '0.8rem', width: '60%', fontSize: '1.1rem', marginTop: '10px'}} onClick={makePick}>Make Pick</button>
                        </div>
                        {!userCollected && user.superBowl2021 && uncollected.length !== 0 && <>
                            <div style={{textAlign: 'center'}}>
                                <button onClick={collectPayout} style={{border: 'none', backgroundColor: '#c5b358', borderRadius: '0.8rem', width: '60%', fontSize: '1.3rem', marginTop: '10px'}}>Collect <br /> 💰Payout💰</button>
                            </div>
                        </>}
                        {!userCollected && !user.superBowl2021 && uncollected.length === 0 && <>
                            <div style={{textAlign: 'center'}}>Sorry, all Payouts have been collected.</div>
                        </>}
                    </div>
                </div>
                
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(SuperBowlPick2021)