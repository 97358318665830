import React, {useEffect} from 'react'
import './welcome.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {firebaseAnalytics} from '../../services/firebase'

const PriceMovement = () => {

    let Trade = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FRodgersPackers1M.png?alt=media&token=eb00749f-84c4-43c8-ad05-e6b31d3761f1'
    let PFF = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FPFF-Logo-Black.png?alt=media&token=700af1f9-5190-4557-8b2d-2877d792ed0d'

    //Analytics
    useEffect(()=>{
        firebaseAnalytics.logEvent("welcomePriceMovement_visit", {
           //user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    const logLinkClick = () => {
        firebaseAnalytics.logEvent("welcome_pffLink", {
            //user: user && user.email 
         })
    }

    return(
        <div style={{textAlign:"left"}}>
            <h2 style={{textAlign: 'center', fontWeight: '600'}}>Can I Make Money?</h2>
            <div style={{textAlign: 'center', fontSize: '0.8em'}}>(One fan made 306% on a single trade)</div>
            <div className="trade-box">
                        <span style={{fontSize: '1.35rem'}}>SimBengals<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>September 13th, 2020<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$14.84<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '200', color: 'gray'}}>to<br /></span>
                        <span style={{fontSize: '1rem', fontWeight: '300'}}>April 29th, 2021<br /></span>
                        <span style={{fontSize: '1.2rem', fontWeight: '600'}}>$60.00<br /></span>
                        <div style={{fontSize: '1.75rem', fontWeight: '600', color: 'green', marginTop: '5px'}}>+306%</div>
                    </div>
            <div className="makemoney">When the Cincinnati Bengals drafted Joe Burrow, the best college QB, 
             SimBull users got excited about the team's potential so they <strong>bought shares in the SimBengals at a very low $14.84/share. </strong> 
             Seven months later, they <strong>sold the SimBengals for $60.00/share and made a 306% profit.</strong> </div>
            <hr />
            <h3 style={{textAlign: 'center', marginTop: '25px'}}>Is SimBull legit?</h3>
            <div style={{textAlign: 'center', fontSize: '0.8em'}}>(ask official NFL & NCAA data provider)</div>
            <div><img style={{width: '50%', marginLeft: '25%'}} src={PFF} /></div>
            <div className="makemoney"><button onClick={()=>{window.open("https://www.pff.com/news/bet-value-opportunities-with-pffs-new-nfl-stock-market-partner-simbull"); logLinkClick()}} style={{border: 'none', outline: 'none', backgroundColor: 'white', textDecoration: 'underline'}}>"There is little downside risk in the SimBull market, given that the payouts occur after each win. . ." </button>  <br />
            <strong>-Pro Football Focus Endorsement</strong></div><hr />
        </div>
    )
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(PriceMovement))