
import React, {useState, useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import './TeamCard.css'
import moment from 'moment'
import 'chartjs-plugin-datalabels';



const TeamGraph = (props) => {
  const labelArr = props.labels
  const pricesArr = props.prices
  const show = props.show ? props.show : true

  const [chartData, setChartData] = useState()

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const chart = () => {
    setChartData({
      labels: labelArr,
      datasets: [
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: '#c5b358',
          borderColor: '#c5b358',
          borderWidth: 3,
          data: pricesArr,
          yAxisID: 'price1'
        },
      ]
    })
  }
  useEffect(() => {
    chart()
  }, [props])

  return (
    <div style={{width: '100%', height: '100%'}}>
      <Line
        data={chartData}
        options={{
          title:{
            display:false,
            text:' ',
            fontSize:20
          },
          legend:{
              display: false
          },
          plugins: {
            datalabels: {
              display: false,
            }
          },
          layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0
              }
          },
          elements: {
              point: {
                  radius: 0
              }
          },
          scales:
          {
              yAxes: [{
                display : show ? true : isMobile ? false : true,
                position: 'right',
                id: 'price1',
              }],
              xAxes: [{
                  display : false
              
          }]
          }, 
          tooltips: {
              mode: 'nearest',
              intersect: false
           },
          // hover: {
          //    mode: 'index',
          //    intersect: false
          //}
        }}
      />
    </div>
  )
}

export default TeamGraph
