import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Modal_(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {setShow(false); props.hide()}

  useEffect(()=>{
    if(props.show !== show){
        setShow(props.show)
    }
  }, [props.show])

  const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

  return (
    <>
      <div style={{display: 'block', position: 'fixed', zIndex: '1', left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.8)'}}>
      <div style={{border: '1px solid white', borderRadius: '1rem', boxShadow: 'rgb(220,220,220) 0px 3px 15px 0px', backgroundColor: 'white', width: isMobile ? '90vw':'50%', marginLeft: isMobile ? '5vw':'25%', marginTop: '5%', maxHeight: '80%', overflow: 'auto', boxShadow: 'rgb(220,220,220) 0px 3px 15px 0px', padding: isMobile ? '8px' : '20px'}} >
        <div style={{fontSize: '1.5rem', fontWeight: '500', textAlign: 'center'}}>FAQ</div>
      <hr />
        <div style={{padding: '20px',  fontSize: '1.75rem', fontWeight: '600'}}>
            {props.item.question}
        </div>

        <div style={{padding: '20px', paddingTop: '0px', fontWeight: '300' }}>
            {props.item.para ? props.item.longAnswer.map(a => {
                return <p>{a}</p>
            })
            :
            props.item.longAnswer
            }
        <br />
            <button onClick={()=>props.hide()} style={{marginTop: '20px', width: '100%', textAlign: 'center', border: 'none', outline: 'none', fontWeight: '200',  textDecoration: 'underline', fontWeight: '.8rem', backgroundColor: 'white'}}>
            close
          </button>
            
        </div>

          
          </div>
      </div>
    </>
  );
}