import React, {useState, useEffect} from 'react'
import {calcRemaining} from './util'

const MyPosition = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [my, setMy] = useState((props.my && props.my.user_id) ? props.my : {
        picks: [],
        rank: 0,
        repicks: [],
        round1: 0,
        round2: 0,
        round3: 0,
        round4: 0,
        round5: 0,
        round6: 0,
        score: 0,
        user_id: 0,
        username: 'Me'
      }
    )
    useEffect(()=>{
        if(props.my !== my){
            setMy(props.my)
        }
    },[props.my])

    const [teamsRemaining, setTeamsRemaining] = useState(0)
    const [winsPossible, setWinsPossible] = useState(0)

    useEffect(()=>{
        if(props.my && props.games){
            if(teamsRemaining === 0 && winsPossible === 0){
                let remaining = calcRemaining(props.games, (props.my.repicks && props.my.repicks.length > 0) ? props.my.repicks : props.my.picks)
                let teamsRemaining1 = remaining[0] ? remaining[0] : 0
                let possibleWins = remaining[1] ? (remaining[1] + ((props.my && props.my.score) ? props.my.score : 0)) : 0

                setTeamsRemaining(teamsRemaining1)
                setWinsPossible(possibleWins)
            }
        }
    },[props.my])

    const returnAmount = (num) => {
        if(num === 1){
            return 1000
        }
        else if(num === 2){
            return 250
        }
        else if(num >2 && num < 6){
            return 100
        }
        else if(num >5 && num < 11){
            return 50
        }
        else if(num >10 && num < 51){
            return 20
        }
        else if(num >50 && num < 101){
            return 10
        }
        else return 0
    }

    return(
        <>
        <div style={{backgroundColor: 'rgb(240,240,240)', padding: '5px', paddingTop: isMobile ? '10px' : '10px', fontFamily: 'Roboto Slab, serif', }}>
            <div style={{backgroundColor: 'white', width: isMobile ? '100%' : '90%', marginLeft: isMobile ? '0%' : '5%', borderRadius: '.3em',  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                <table style={{width: isMobile ? '99%': '90%', marginLeft: isMobile ? '.5%' : '5%', textAlign: 'center'}}>
                    <tr style={{fontSize: isMobile ? '.6em' : '.75em', verticalAlign: 'bottom', fontWeight: '200', color: 'gray'}}>
                    <td>Rank {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                    <td>Username {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                        {!isMobile && <>
                            <td>First <br /> Round {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                            <td>Second<br /> Round {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                            <td>Sweet<br /> Sixteen {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                            <td>Elite <br />Eight {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                            <td>Final <br />Four {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                            <td>The <br /> Ship {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                        </>}
                    <td>Current <br />Wins {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td> 
                    <td>Teams <br />Left {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                    <td>Wins<br /> Possible {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                    <td>Position<br /> Payout {!isMobile && <hr style={{marginTop: '3px', marginBottom: '3px'}} />}</td>
                    </tr>
                    
                    <tr style={{fontWeight: '400', paddingBottom: '3px'}}>
                        <td style={{color: 'gray', width: isMobile ? '7.5%' : '5%'}}>{(my && my.rank) ? my.rank : '-'}</td> 
                        <td style={{fontSize: '.9em', width: isMobile ? '32.5%' : '20%', fontWeight: '500'}}>{( my && my.username) ? my.username : 'Me'}</td> 
                            {!isMobile && <>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{( my && my.round1) ? my.round1 : ''}</td>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{( my && my.round2) ? my.round2 : ''}</td>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{(my && my.round3) ? my.round3 : ''}</td>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{(my && my.round4) ? my.round4 : ''}</td>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{(my && my.round5) ? my.round5 : ''}</td>
                                <td style={{fontWeight: '200', width: '7.5%'}}>{(my && my.round6) ? my.round6 : ''}</td>
                            </>}
                        <td style={{width: isMobile ? '15%' : '7.5%'}}>{(my && my.score) ? my.score : 0}</td> 
                        <td style={{width: isMobile ? '15%' : '7.5%'}}>{teamsRemaining}</td>
                        <td style={{width: isMobile ? '15%' : '7.5%'}}>{winsPossible}</td>
                        <td style={{width: isMobile ? '15%' : '7.5%'}}>${returnAmount((my && my.rank) ? my.rank : 1000)}</td>
                    </tr>

                    

                </table>
            </div>
        </div>
        </>
    )
}

export default MyPosition