import React, {useState, useEffect, useRef} from 'react'
import './TeammateList.css'
import './Teammate.css'
import Teammate from './Teammate'
import TimeSorter from './TimeSorter'
import {getTeammates, follow, getTeammatesSummary} from './utils'
import { connect } from 'react-redux'
import Requests from './Requests/Requests'
import TeammateNewList from './Search/TeammateList'
import {firebaseAnalytics} from '../../services/firebase'
import ReactLoading from 'react-loading'
import {Overlay, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const TeammateList = (props) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const [period, setPeriod] = useState('1D')
    let periodList = ['1D', /*'1W', '1M', '3M', '1Y',*/ 'All']
    const changePeriod = (p) => {
        setPeriod(p)
    }    
    
    useEffect(async () => {
        firebaseAnalytics.logEvent("teammates_list_visit")
    }, [])

    const copyLink = () => {
        var textField = document.createElement('textarea')
        textField.innerText = `https://simbull.com`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        alert('Copied!')
    }

    const doToolTip = () => {
        if(showTooltip){
            setShowTooltip(false)
        }else{
            setShowTooltip(true)
        }
        setTimeout(function () {
            setShowTooltip(false)
        }, 5000);
    }

    useEffect(()=>{
        setTimeout(function () {
            if(showTooltip){
                setShowTooltip(false)
            }
        }, 3000);
    },[showTooltip])

    return(
        <>
        <div className="TeammateList">

        
            {props.newRequests > 0 && <Requests rerunTeammates={props.rerunTeammates} newRequests={props.newRequests} token={props.token} requests={props.requests} />}


            <div className="TeammateList-Header">Teammates
                <button onClick={()=>setShowTooltip(!showTooltip)} ref={target} style={{fontSize: '.7rem', fontWeight: '300', color: 'white', backgroundColor: 'rgba(0,0,0,.25)', borderRadius: '1rem', marginLeft: '5px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '1px', paddingBottom: '1px', border:'none', outline: 'none'}}>BETA</button> 
                <Overlay target={target.current} show={showTooltip} placement="bottom">
                    {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Find a bug? Have some feedback? <br /><br />Email us: <br /><br />customer-service@simbull.app
                    </Tooltip>
                    )}
                </Overlay>
            </div>
            <TimeSorter period={period} periods={periodList} changePeriod={changePeriod}  />

            <div style={{fontWeight: '300', marginBottom: '5px', textAlign: 'center', fontSize: '.8em', color: 'gray'}}>Want to change your username or make your account private? <Link style={{color: 'gray'}} to='/EditAccount'>Click Here</Link></div>

            {props.summary && props.summary.length > 0 && props.summary.map(s => {
                return <Teammate key={`${s.id}-${Math.random()}`} changeTeammate={props.changeTeammate} s={s} period={period} />
            })}

            {props.summary && props.summary.length > 0 && <>
                <div className="TeammateList-Header" style={{paddingTop: '10px'}}>Invite Teammates</div>
                <div style={{textAlign: 'center', marginBottom: '10px', marginTop: '5px', fontSize: '1.15rem', fontWeight: '300'}}>Know someone you want to be teammates with but they aren't on SimBull?</div>
                <div style={{textAlign: 'center', fontWeight: '200'}}>Send this link to them</div>
                <div style={{textAlign: 'center'}}><button onClick={copyLink} className="ref-copy-btn">Copy Link</button></div>
            </>}


            {/*<div className="TeammateList-SubHeader">Others Popular on SimBull</div>*/}

            {/*props.newTeammates && props.newTeammates.length > 0 && props.newTeammates.map(s => {
                return <Teammate changeTeammate={props.changeTeammate} s={s} period={period} />
            })*/}

            {(!props.summary || props.summary.length === 0) && !props.ranTeammates && <div style={{width: '20%', marginLeft: '40%'}}><ReactLoading width='100%' type='spokes' color='rgb(200,200,200)' /></div>}

            {(!props.summary || props.summary.length === 0) && props.ranTeammates && <TeammateNewList newTeammates={props.newTeammates} changeTeammate={props.changeTeammate} />}


        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(TeammateList)