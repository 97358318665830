import * as types from "../types";

const initialState = {trades: []};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.ADD_TRADES:
            return {
                ...state,
                trades: payload
            };
        default:
            return state;
    }
}