import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import amplitude from 'amplitude-js'
import {getOffersByTeam} from '../../../routes/trading/trading'

const OrderBook = (props) => {
    //props
    const t = props.t

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    //states
    const [bids, setBids] = useState([])
    const [bidAmt, setBidAmt] = useState({})
    const [asks, setAsks] = useState([])
    const [askAmt, setAskAmt] = useState({})

    //Functions
    /*const getOffers = (id) => {
        let bidArr = []
        let bidAmtObj = {}
        let askArr = []
        let askAmtObj = {}
        getOffersByTeam(id).then(res => {
            res.map(o => {
                if(o.type === 'BID'){
                    if(bidArr.includes(o.price)){
                        bidAmtObj[o.price] = bidAmtObj[o.price] + o.remaining_amount
                    }
                    else{
                        bidArr.push(o)
                        bidAmtObj[o.price] = o.remaining_amount
                    }
                }else{
                    if(askArr.includes(o.price)){
                        askAmtObj[o.price] = askAmtObj[o.price] + o.remaining_amount
                    }
                    else{
                        askArr.push(o)
                        askAmtObj[o.price] = o.remaining_amount
                    }
                }
            })
            bidArr.sort(function compare(a, b) {
                return b.price - a.price;
            })
            askArr.sort(function compare(a, b) {
                    return a.price - b.price;
                })
            setBids(bidArr)
            setAsks(askArr)
            setBidAmt(bidAmtObj)
            setAskAmt(askAmtObj)
        })
    }
    useEffect(() => {
        if(t && t.team && t.team.id && bids.length === 0 && asks.length === 0){
            getOffers(t.team.id)
        }
    }, [props.t])*/


    /*Order Book 2*/
    const [bidPrices, setBidPrice] = useState([
        null /*1 share*/,
        null /*2 share*/,
        null /*5 share*/,
        null /*10 share*/,
        null /*25 share*/,
      ]);
      const [askPrices, setAskPrice] = useState([
        null /*1 share*/,
        null /*2 share*/,
        null /*5 share*/,
        null /*10 share*/,
        null /*25 share*/,
      ]);
    const getOffers = async (id) => {
        let bidArr = [];
        let askArr = [];
    
        const getAvgSharePriceForOfferAmt = (amt, arr) => {
          let price = 0;
          let amount = 0;
          if (arr.length > 0) {
            arr.map((o) => {
              if (amount < amt) {
                if (amount + o.remaining_amount <= amt) {
                  amount = amount + o.remaining_amount;
                  price = price + o.remaining_amount * o.price;
                } else if (amount !== amt) {
                  let partialAmt = amt - amount;
                  amount = amount + partialAmt;
                  price = price + partialAmt * o.price;
                }
              }
            });
          }
          if (amount < amt) {
            return "N/A";
          } else {
            return `$${(price / amt).toFixed(2)} $${price.toFixed(2)}`;
          }
        };
    
        let res = await getOffersByTeam(id)
        if(res && res.length > 0){
          res.map((o) => {
            if (o.type === "BID") {
              bidArr.push(o);
            } else {
              askArr.push(o);
            }
          });
          bidArr.sort(function compare(a, b) {
            return b.price - a.price;
          });
          askArr.sort(function compare(a, b) {
            return a.price - b.price;
          });
          setBids(bidArr);
          setAsks(askArr);
          let bid1 = getAvgSharePriceForOfferAmt(1, bidArr);
          let bid2 = getAvgSharePriceForOfferAmt(2, bidArr);
          let bid5 = getAvgSharePriceForOfferAmt(5, bidArr);
          let bid10 = getAvgSharePriceForOfferAmt(10, bidArr);
          let bid25 = getAvgSharePriceForOfferAmt(25, bidArr);
          setBidPrice([bid1, bid2, bid5, bid10, bid25]);
          let ask1 = getAvgSharePriceForOfferAmt(1, askArr);
          let ask2 = getAvgSharePriceForOfferAmt(2, askArr);
          let ask5 = getAvgSharePriceForOfferAmt(5, askArr);
          let ask10 = getAvgSharePriceForOfferAmt(10, askArr);
          let ask25 = getAvgSharePriceForOfferAmt(25, askArr);
          setAskPrice([ask1, ask2, ask5, ask10, ask25]);
        }
        
    }
    useEffect(()=>{
        if(props.t && bids.length === 0 && asks.length === 0){
            getOffers(props.t.team.id)
        }
    },[props.t])


    return(
        <>
        <div  style={{paddingLeft: '3%', fontSize: '1.5em', fontWeight: '400', display: 'inline-block', width:'60%', color: 'white', marginBottom: '0px'}}>Order Book</div>
        <div onClick={() => amplitude.getInstance().logEvent("order_book_more")} style={{paddingRight: '3%', textAlign: 'right', fontSize: '1em', fontWeight: '300', display: 'inline-block', width:'40%', color: 'white'}}><Link to={`/OrderBook/${t && t.team && t.team.name}`}><button style={{border: 'none', outline: 'none', backgroundColor: 'black', textDecoration: 'underline', color: 'gray'}}>View More {' '}<span className="fas fa-chevron-right"></span></button></Link></div>
        {/*<div style={{textAlign: 'center', verticalAlign: 'top'}}>
            <div style={{display: 'inline-block', width: '50%', verticalAlign: 'top'}}>
                <div style={{fontWeight: '500'}}>Bids</div>
                {bids.length > 0 && bids.map(b => {
                    if(bids.indexOf(b) < 6){
                        return <div style={{fontWeight: '300', fontSize: '.9em'}} key={b.price}>{bidAmt[b.price] && bidAmt[b.price].toFixed(2)} x ${b.price.toFixed(2)}</div>
                    }
                    
                })}
                {bids.length === 0 && <div>(none)</div>}
            </div>
            <div style={{display: 'inline-block', width: '50%', verticalAlign: 'top'}}>
                <div style={{fontWeight: '500'}}>Asks</div>
                {asks.length > 0 && asks.map(a => {
                    if(asks.indexOf(a) < 6){
                        return <div style={{fontWeight: '300', fontSize: '.9em'}} key={a.price}>{askAmt[a.price] && askAmt[a.price].toFixed(2)} x ${a.price.toFixed(2)}</div>
                    }
                })}
                {asks.length === 0 && <div>(none)</div>}
            </div>
            </div>*/}
            <div style={{fontSize: '1.05rem', paddingLeft: '3%', paddingRight: '3%'}}>
        <div style={{textAlign: 'center', width: isMobile ? '100%' : '48%', display: 'inline-block', marginRight: isMobile ? '0%' : '2%', border: '1px solid rgb(30,30,30)'}}>
            <div style={{backgroundColor: 'rgb(30,30,30)'}}>Buy</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Shares</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Avg Price</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Total</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>1.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{bidPrices[0] ? bidPrices[0].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{bidPrices[0] ? bidPrices[0].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>2.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{bidPrices[1] ? bidPrices[1].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{bidPrices[1] ? bidPrices[1].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>5.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{bidPrices[2] ? bidPrices[2].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{bidPrices[2] ? bidPrices[2].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>10.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{bidPrices[3] ? bidPrices[3].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{bidPrices[3] ? bidPrices[3].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>25.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{bidPrices[4] ? bidPrices[4].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{bidPrices[4] ? bidPrices[4].split(" ")[1] : 'N/A'}</div>
        </div>
        <div style={{textAlign: 'center', width: isMobile ? '100%' : '48%', display: 'inline-block', marginLeft: isMobile ? '0%' : '2%', border: '1px solid rgb(30,30,30)'}}>
            <div style={{backgroundColor: 'rgb(30,30,30)'}}>Sell</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Shares</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Avg Price</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray', fontSize: '.95rem'}}>Total</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>1.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{askPrices[0] ? askPrices[0].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{askPrices[0] ? askPrices[0].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>2.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{askPrices[1] ? askPrices[1].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{askPrices[1] ? askPrices[1].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>5.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{askPrices[2] ? askPrices[2].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{askPrices[2] ? askPrices[2].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>10.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{askPrices[3] ? askPrices[3].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{askPrices[3] ? askPrices[3].split(" ")[1] : 'N/A'}</div>
            <div style={{width: '33%', display: 'inline-block', color: 'gray',}}>25.00</div><div style={{width: '33%', display: 'inline-block', color: 'gray',}}>{askPrices[4] ? askPrices[4].split(" ")[0] : 'N/A'}</div><div style={{width: '33%', display: 'inline-block',}}>{askPrices[4] ? askPrices[4].split(" ")[1] : 'N/A'}</div>
        </div>
        </div>
        </>
    )
}

export default OrderBook