import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux'
import '../portfolio2.css'
import {getToken} from '../../../redux/actions/token'
import {collectPayouts, collectSinglePayout} from '../../../routes/payouts/collect'


const Payouts = (props) => {

    const dispatch = useDispatch()

    const [value, setValue] = useState(0)
    const [uncollected, setUncollected] = useState(props.uncollected ? props.uncollected : [])

    let getTeamInfo = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    useEffect(()=>{
        if(uncollected && uncollected.length > 0){
            let _value = 0
            uncollected.map(p => {
                _value += p.reward
            })
            setValue(_value)
        }
    },[uncollected])

    const collectAllPayouts = (id) => {
        collectPayouts(props.token).then(res => {
            dispatch(getToken())
        })
        setUncollected([])
        setValue(0)
    }

    const collectOnePayout = (id) => {
        collectSinglePayout(props.token, id).then(res => {
            dispatch(getToken())
        })
        const newList = uncollected.filter((item) => item.id !== id);
        setUncollected(newList);
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{color: 'white', fontSize: '1.4rem', overflowX: 'visible', whiteSpace: 'nowrap', fontWeight: '400'}}>
                    {value === 0 ? '' : 'Uncollected'} Win Payouts
                    {value ? <span style={{marginLeft: '4px', fontWeight: '200'}}>(${value.toFixed(2)})</span>: null}
                </div>  
                {(!isMobile && uncollected.length !== 0) ? <button onClick={collectAllPayouts} style={{fontWeight: '200', fontSize: '1.2rem', color: '#c4b454', backgroundColor: 'black', border: 'none', outline: 'none'}}>Collect All</button>: null}
            </div>
            <div style={{overflow: 'auto', whiteSpace: 'nowrap'}}>
            {(uncollected && uncollected.length > 0) ? uncollected.map(p => {
                let t = getTeamInfo(p.asset_id)
                return(
                    <>
                    <div onClick={()=>collectOnePayout(p.id)} style={{minWidth: '150px', minHeight: '150px', backgroundColor: 'rgb(30,30,30)', margin: '8px',borderRadius: '1rem', display: 'inline-block', padding: '8px', textAlign: 'center'}}>
                        <div style={{
                            height: '50px',
                            width: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            backgroundColor: '#171717',
                            border: '0.25px solid #4B4B4B',
                            margin: 'auto',
                            marginTop: '8px',
                            marginBottom: '8px',
                            textAlign: 'center'
                        }}>
                            <img className="full-img" src={t && `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                        </div>
                        
                        <div style={{fontSize: '1rem', fontWeight: '500', marginBottom: '3px', color: 'white'}}>Sim{(t && t.team && t.team.name)}</div>
                        <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${p.timestamp.substring(5, 7)}/${p.timestamp.substring(8, 10)}/${p.timestamp.substring(0, 4)}`}</div>
                        <div style={{fontSize: '2rem', color: '#c4b454', fontWeight: '600'}}>${p.reward.toFixed(2)}</div>
                    </div>
                    
                    </>
                )
            })
            :
                null
            }
            </div>
            {(isMobile && uncollected.length > 2) ? <button onClick={collectAllPayouts} style={{fontWeight: '200', fontSize: '1.2rem', color: 'gray', marginLeft: '5%', padding: '4px', backgroundColor: 'rgb(30,30,30)', borderRadius: '2rem', width: '90%', border: 'none', outline: 'none'}}>Collect All</button> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    watchlist: state.watchlist.watchlist,
});

export default connect(mapStateToProps)(Payouts)