import React, {useState, useEffect} from 'react'
import './Requests.css'
import {Link} from 'react-router-dom'
import Popup from 'reactjs-popup'
import Toggle from './Toggle'
import {acceptFollowRequest, denyFollowRequest} from '../utils'

const Requests = (props) => {

    const [show, setShow] = useState(false)

    const acceptRequest = (token, user_id) => {

        acceptFollowRequest(token, user_id).then(res => {
            props.requests.map(r => {
                if(r.user_id === user_id){
                    r.status = "ACTIVE"
                }
            })
            
            props.rerunTeammates()
        })
    }

    const denyRequest = (token, user_id) => {

        denyFollowRequest(token, user_id).then(res => {
            props.requests.map(r => {
                if(r.user_id === user_id){
                    r.status = "DENIED"
                }
            })
            
        })
    }

    const isRequests = () => {
        let check = false
        props.requests.map(r => {
            if(r.status === 'REQUESTED'){
                check = true
            }
        })

        return check
    }

    let moreRequests = isRequests()

    return(
        <>

        {moreRequests && <>
        <Link onClick={()=>setShow(true)}>
        <div className="TeammateRequests">
            <div className="TeammateRequest-Message">
                New Teammate Requests {' '}
                <span className="TeammateRequest-Amt">({props.newRequests})</span>
            </div>
            <div style={{display: 'inline-block', width: '10%',fontSize: '1.05em', fontWeight: '200', color: 'gray', paddingRight: '2px', textAlign: 'right'}}><i className="fas fa-arrow-right"></i></div>
        </div>
        </Link>

        <Popup open={show} modal>
            <div className="Request-Popup">

                <div className="Request-Popup-Header">Teammate Requests</div>

                <hr />
                
                {props.requests.map(r => {
                    if(r.status === 'REQUESTED'){
                        return(
                            <Toggle token={props.token} acceptRequest={acceptRequest} denyRequest={denyRequest} request={r} />
                        ) 
                    }
                })}

                {!moreRequests && <div style={{textAlign: 'center', padding: '10px', fontWeight: '300'}}>No more requests</div>}

            

                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>setShow(false)} style={{backgroundColor: 'rgb(245, 245, 245)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                </div>
            </div>
        </Popup>
        </>}

        </>
    )
}

export default Requests