import React, {useState, useEffect} from 'react'
import './Analysts.css'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {db} from '../../../services/firebase'
import Slider from "react-input-slider";

const Analysts = (props) => {

    const [number, setNumber] = useState({ x: 50 });
    const [initial, setInital]  = useState({x : 50})
    const [change, setChange] = useState(false)

    const [g, setG] = useState(props.g ? props.g : null)
    useEffect(()=>{if(props.g){setG(props.g)}},[props.g])

    const makeGameChange = () => {
        let changeObj = {
            gameID: g.id,
            awayTeam: g.awayTeam,
            homeTeam: g.homeTeam,
            date: g.date,
            number: number
        }
        props.getChildData(changeObj)
    }
    useEffect(()=>{
        if(change){
            makeGameChange()
        }  
    }, [props.sendData])

    useEffect(()=>{
        if(initial.x === number.x){
            change && setChange(false)
        }
        else{
            !change && setChange(true)
        }
    }, [number])

    const updateGame = () => {
        //enter new probability
    }

    return(<>
        <div style={{paddingLeft: '3%', paddingRight: '3%'}}>
            <div style={{width: '100%'}}>
                <div style={{width: '45%', display: 'inline-block', textAlign: 'left', fontSize: '1.25rem', fontWeight: '600'}}>
                    <span style={{fontStyle: 'italic'}}>Sim</span>{g.awayTeam}<br />
                    <img style={{width: '35%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${props.g && props.g.awayTeam}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                    <span style={{fontSize: '1.5rem', fontWeight: '200'}}>{g.awayScore}</span>
                </div>
                <div style={{width: '10%', display: 'inline-block', textAlign: 'center'}}>
                    {g.completed ? 'Final' : ''}
                </div>
                <div style={{width: '45%', display: 'inline-block', textAlign: 'right', fontSize: '1.25rem', fontWeight: '600'}}>
                    <span style={{fontStyle: 'italic'}}>Sim</span>{g.homeTeam}<br />
                    <span style={{fontSize: '1.5rem', fontWeight: '200'}}>{g.homeScore}</span>
                    <img style={{width: '35%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${props.g && props.g.homeTeam}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                </div>
                {/*<div>game:{g.id}</div>*/}
                {!g.completed && 
                <div style={{width: '100%', display: 'inline-block', textAlign: 'center', marginBottom: '5%', paddingTop: '1%', fontSize: '1.25rem'}}>
                    <div style={{width: '10%', textAlign: 'left', display: 'inline-block'}} >{100 - number.x}%</div>
                    <div style={{width: '80%', display: 'inline-block', verticalAlign: 'top'}}>
                        &nbsp;
                        &nbsp;
                        <Slider
                            axis="x"
                            x={number.x}
                            onChange={({ x }) => setNumber((number) => ({ ...number, x }))}
                        />
                        &nbsp;
                        &nbsp;
                    </div>
                    <div style={{width: '10%', textAlign: 'right', display: 'inline-block', paddingTop: '1%', fontSize: '1.35rem'}} >{number.x}%</div>
                </div>
                }
                
                {change && <div style={{textAlign: 'center'}}>
                    <button onClick={updateGame} style={{textAlign: 'center', borderRadius: '1rem'}}>Save Change</button>
                </div>}
            </div>
        </div>
    </>)
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(withRouter(Analysts))