import React, {useEffect, useState} from 'react'
import './Account.css'
import {Link, withRouter, useHistory} from 'react-router-dom'
import Header from './Header'
import { db } from '../../services/firebase'
import { connect } from 'react-redux'
import firebase from 'firebase'
import {getAccountInfo, updateAccountInfo} from '../../routes/user/account'
import PhoneInput from 'react-phone-number-input'
import Popup from 'reactjs-popup'
import DatePicker from "react-date-picker";

const EditAccount = (props) => {
    //props

    const history = useHistory()
    
    const [confirmation, setConfirmation] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    //states
    const [email, setEmail] = useState(null)
    const [favoriteTeam, setFavoriteTeam] = useState(null)
    const [username, setUsername] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [value, setValue] = useState()
    const [birthday, setBirthday] = useState(null)
    const [address, setAddress] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [state, setState] = useState(null)
    const [public_, setPublic] = useState(null)

    useEffect(()=>{
        if(props.info && firstName === null){
            setEmail(props.info.email ? props.info.email : null)
            setUsername(props.info.username ? props.info.username : null)
            setFirstName(props.info.first_name ? props.info.first_name : null)
            setLastName(props.info.last_name ? props.info.last_name : null)
            setValue(props.info.phone_number ? props.info.phone_number : null)
            setBirthday(props.info.birthday ? props.info.birthday : null)
            setAddress(props.info.address ? props.info.address : null)
            setZipCode(props.info.zip_code ? props.info.zip_code : null)
            setState(props.info.state ? props.info.state : null)
            setPublic(props.info.public ? props.info.public : 1)
            if(props.info.public == 0){
                setPublic(0)
            }   
        }
    },[props.info])

    useEffect(()=>{
        console.log(public_)
    },[public_])

    //Functionality
    const updateUserAccount = () => {
        if(props.token){
            updateAccountInfo(props.token, address, birthday, email, firstName, lastName, value, state, username, zipCode, public_).then(result => {
                let carry 
                if(result.username){
                    carry = {type: 'success', date: result} //good
                    setConfirmation(true)
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.password && result.data.msg.query.password[0] ){
                    carry = {type: 'err', data: `Password: ${result.data.msg.query.password[0]}`} //don't include a username
                    setError(true)
                    setErrorMsg(result.data.msg.query.password[0])
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.email && result.data.msg.query.email[0] ){
                    carry = {type: 'err', data: `Email: ${result.data.msg.query.email[0]}`} //don't include a email
                    setError(true)
                    setErrorMsg(result.data.msg.query.email[0])
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.phone_number && result.data.msg.query.phone_number[0] ){
                    carry = {type: 'err', data: `Phone: ${result.data.msg.query.phone_number[0]}`} //don't include a email
                    setError(true)
                    setErrorMsg(result.data.msg.query.phone_number[0])
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.birthday && result.data.msg.query.birthday[0] ){
                    carry = {type: 'err', data: `Birthday: ${result.data.msg.query.birthday[0]}`} //don't include a email
                    setError(true)
                    setErrorMsg(result.data.msg.query.birthday[0])
                }
                return carry
            })
        }
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
        
        <div className="EditAccount DesktopContainer">
            <div className="ua-div-e">
                <div className="ua-left">Email</div>
                <input className="ua-right" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Username</div>
                <input className="ua-right" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Visibility</div>
                <select className="ua-right" value={public_} onChange={(e)=>setPublic(e.target.value)}>
                    <option value={1}>Public</option>
                    <option value={0}>Private</option>
                </select>
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">First Name</div>
                <input className="ua-right" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Last Name</div>
                <input className="ua-right" value={lastName} onChange={(e)=>{setLastName(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Phone</div>
                <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={setValue}/>
            </div>
            {/*<hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Birthday <span style={{fontWeight: '200', fontSize: '.9em'}}>(MM/DD/YYYY)</span></div>
                 <div className="ua-right"><DatePicker value={birthday} onChange={(date) => setBirthday(date)} /></div>
                {/*<input className="ua-right" value={birthday} onChange={(e)=>{setBirthday(e.target.value)}} />
            </div>*/}
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Address</div>
                <input className="ua-right" value={address} onChange={(e)=>{setAddress(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">Zip Code</div>
                <input className="ua-right" value={zipCode} onChange={(e)=>{setZipCode(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className="ua-div-e">
                <div className="ua-left">State</div>
                <input className="ua-right" value={state} onChange={(e)=>{setState(e.target.value)}} />
            </div>
            <hr className="ua-hr" />
            <div className='ua-edit-div'>
                {error && <div style={{textAlign: 'center', color: 'red'}}>{errorMsg}</div>}
                <Link onClick={updateUserAccount} style={{color: 'black'}}>Save</Link></div>

            <Popup open={confirmation} modal>
                <div style={{width: isMobile ? '90vw': '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    <div style={{textAlign: 'center', fontSize: '2.5em'}}>Account Updated!</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                    
                        <div style={{textAlign: 'center'}}>
                            
                            <button onClick={()=>history.replace('/')} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>
                    </div>
                    
                </div>
            </Popup>

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token,
    info: state.info.info
});

export default connect(mapStateToProps)(withRouter(EditAccount))