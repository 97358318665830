import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import './Trade.css'
import {Link, useHistory} from 'react-router-dom'
import {Overlay} from 'react-bootstrap'
import Loader from '../adHoc/Loader'
import '../Account2/Friends/Friends.css'
import TeamCard from '../PortfolioDesktop/WatchlistDesktop/TeamCard'
import amplitude from 'amplitude-js'

const FullLeague = (props) => {

    //get props
    const user = props.user
    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])

    useEffect(()=>{
        if(props.teamsValue && teams.length === 0){
            setTeams(props.teamsValue)
        }
    },[props.teamsValue])

    const history = useHistory()

    const [searchTeam, setSearchTeam] = useState(props.searchTeam ? props.searchTeam : '')
    useEffect(()=>{
        props.searchTeam && setSearchTeam(props.searchTeam)
    },[props])
    useEffect(()=>{
        let val = props.searchTeam
        if(val === '' || !val){
            setSearchTeam('')
        }
    }, [props])
    const teamSearch = (val) => {
        if(val === '' || !val){
            setSearchTeam('')
        }
        setSearchTeam(val)
    }

    let league1 = 'NFL'
    if(props && props.match && props.match.params && props.match.params.id && props.match.params.id.length > 4){
        league1 = props.match.params.id.substring(3, props.match.params.id.length)
    }

    let leagues = ['NFL', 'NBA', 'CFB', 'NHL', 'MLB']
    const [league, setLeague] = useState(league1)
    const [showLeagues, setShowLeagues] = useState(3)

    const [type, setType] = useState('period')
    const [period, setPeriod] = useState('1D')
    const [price, setPrice] = useState('Low')
    const [teamName, setTeamName] = useState('AtoZ')
    const [showTimePeriod, setShowTimePeriod] = useState(false)
    const target = useRef(null);

    teams.sort(function(a, b) {
        if(type === 'period'){
            if(period === '1D'){
                return Math.abs(b.change.day) - Math.abs(a.change.day)
            }
            else if(period === '1W'){
                return Math.abs(b.change.week) - Math.abs(a.change.week)
            }
            else if(period === '1M'){
                return Math.abs(b.change.month) - Math.abs(a.change.month)
            }
            else if(period === '3M'){
                return Math.abs(b.change.threeMonth) - Math.abs(a.change.threeMonth)
            }
            else if(period === '1Y'){
                return Math.abs(b.change.year) - Math.abs(a.change.year)
            }
            else if(period === 'All'){
                return Math.abs(b.change.total) - Math.abs(a.change.total)
            }
        }
        else if(type === 'price'){
            if(price === 'High'){
                return b.asking_price - a.asking_price
            }
            else if(price === 'Low'){
                return a.asking_price - b.asking_price
            }
        }
        else if(type === 'teamName'){
            if(teamName === 'AtoZ'){
                if(a.team.name < b.team.name) { return -1; }
                if(a.team.name > b.team.name) { return 1; }
            }
            else if(teamName === 'ZtoA'){
                if(b.team.name < a.team.name) { return -1; }
                if(b.team.name > a.team.name) { return 1; }
            }
        }
        
    })

    const getPercentChange = (t) => {
        if(period === '1D'){
            return t.change.day * 100
        }
        else if(period === '1W'){
            return t.change.week * 100
        }
        else if(period === '1M'){
            return t.change.month * 100
        }
        else if(period === '3M'){
            return t.change.threeMonths * 100
        }
        else if(period === '1Y'){
            return t.change.year * 100
        }
        else if(period === 'All'){
            return t.change.total * 100
        }
        
    }

    useEffect(()=>{
        !props.market && window.scrollTo(0,0)
    },[])

    useEffect(()=>{
        if(history.location.search){
            let sortArr = history.location.search.split('&')
            let leagueSort = sortArr[0].split('=')[1]
            let typeSort = sortArr[1].split('=')[1]
            let descSort = sortArr[2].split('=')[1]
            setLeague(leagueSort)
            setType(typeSort)
            if(typeSort === 'period'){
                setPeriod(descSort)
            }else if(typeSort === 'price'){
                setPrice(descSort)
            }else if(typeSort === 'teamName'){
                setTeamName(descSort)
            }
        }
    },[])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(()=>{
        /*let desc = type === 'period' ? period : type === 'price' ? price : type === 'teamName' ? teamName : ''
        !props.market && history.replace(`/Trade/Teams?league=${league}&type=${type}&desc=${desc}`)*/
    }, [league, type, period, teamName, price])

    const returnPayoutText = (_league) => {
        if(_league === 'ALL'){
            return 'Earn Win Payouts from $0.05-$0.50/win'
        }else if(_league === 'NFL'){
            return 'NFL Win Payouts = $0.50/team win'
        }
        else if(_league === 'NBA'){
            return 'NBA Win Payouts = $0.10/team win'
        }
        else if(_league === 'CFB'){
            return 'CFB Win Payouts = $0.50/team win'
        }
        else if(_league === 'MLB'){
            return 'MLB Win Payouts = $0.05/team win'
        }
        else if(_league === 'NHL'){
            return 'NHL Win Payouts = $0.10/team win'
        }
        else return 'Earn Win Payouts from $0.05-$0.50/win'
    }


    return(
        <div style={{ paddingBottom: '25vh', paddingLeft: isMobile ? '0px' :'228px', paddingRight: isMobile ? '0px' :'300px',}}>

            <div style={isMobile ? {overflow: 'auto', whiteSpace: 'nowrap', padding: '0px', marginLeft: isMobile ? '0%':'-2.6%',  paddingTop: '12px'} : {padding: '0px', marginLeft: isMobile ? '0%':'-2.6%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: '12px'}}>
                <button onClick={() => {setLeague('ALL'); amplitude.getInstance().logEvent("trade_all")}} className={league === 'ALL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '15%', padding: '0px'}}>All</button>
                <button onClick={() => {setLeague('NFL'); amplitude.getInstance().logEvent("trade_nfl")}} className={league === 'NFL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '20%', padding: '0px'}}>SimNFL</button>
                <button onClick={() => {setLeague('NBA'); amplitude.getInstance().logEvent("trade_nba")}} className={league === 'NBA' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '20%', padding: '0px'}}>SimNBA</button>
                
                
                <button onClick={()=> {setLeague('CFB'); amplitude.getInstance().logEvent("trade_cfb")}} className={league === 'CFB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '20%', padding: '0px'}}>SimCFB</button>
                <button onClick={()=> {setLeague('MLB'); amplitude.getInstance().logEvent("trade_mlb")}} className={league === 'MLB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '20%', padding: '0px'}}>SimMLB</button>
                <button onClick={()=> {setLeague('NHL'); amplitude.getInstance().logEvent("trade_nhl")}} className={league === 'NHL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"} style={!isMobile ? {} : {width: '20%', padding: '0px'}}>SimNHL</button>
                <div style={{display: 'inline-block', minWidth: '17%',paddingLeft: '3%', textAlign: 'right', paddingRight: '3%', fontSize: '.9em', paddingTop: '7px', fontWeight: '200', color: 'rgb(100,100,100)'}}>
                    {/*<DownloadTeams />{' '*/}
                    <Link style={{color: 'rgb(90,90,90)'}} ref={target} onClick={()=>{setShowTimePeriod(!showTimePeriod)}}>
                        {type === 'period' ? `Change (${period})`: type === 'price' ? `Price ${price}`: `Name ${teamName.substring(0,1)} to ${teamName.substring(3,4)}`}{' '}<i style={{marginLeft: '5px'}} className="fas fa-ellipsis-v"></i>
                    </Link>
                    <Overlay className="FC-E-Overlay" target={target.current} show={showTimePeriod} placement="bottom">
                        {(props) => (
                            <div className='FC-E-return-div'  id="overlay-example" {...props}>
                                    {/*<button className="FC-E-item-return-item" onClick={()=>{setPeriod('All'); setShowTimePeriod(false)}} >All</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('1Y'); setShowTimePeriod(false)}} >1Y</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('3M'); setShowTimePeriod(false)}} >3M</button>
                                    */}
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setPrice('High'); setShowTimePeriod(false); setType('price'); amplitude.getInstance().logEvent("sort_high")}} > Price High</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setPrice('Low'); setShowTimePeriod(false); setType('price'); amplitude.getInstance().logEvent("sort_low")}} > Price Low</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setTeamName('AtoZ'); setShowTimePeriod(false); setType('teamName'); amplitude.getInstance().logEvent("sort_a-z")}} > Name (A to Z)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setTeamName('ZtoA'); setShowTimePeriod(false); setType('teamName'); amplitude.getInstance().logEvent("sort_z-a")}} > Name (Z to A)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setPeriod('1M'); setShowTimePeriod(false); setType('period'); amplitude.getInstance().logEvent("sort_1m")}} > Change (1M)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setPeriod('1W'); setShowTimePeriod(false); setType('period'); amplitude.getInstance().logEvent("sort_1w")}} > Change (1W)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em', backgroundColor: 'black', color: 'white',}} onClick={()=>{setPeriod('1D'); setShowTimePeriod(false); setType('period'); amplitude.getInstance().logEvent("sort_1d")}} > Change (1D)</button>
                                
                            </div>
                        )}
                    </Overlay>  
                </div>         
            </div>

                
               


                {/*<div style={{display: 'inline-block', width: '59%',fontSize: '1.15em', fontWeight: '200', color: 'gray', paddingLeft: '2px'}}>SimNFL</div>
                <Link to="/league/SimNFL"><div style={{display: 'inline-block', width: '39%',fontSize: '1.05em', fontWeight: '200', color: 'gray', paddingRight: '2px', textAlign: 'right'}}>View All {' '}<i className="fas fa-arrow-right"></i></div></Link>*/}
            <div style={{ marginTop: '3px', padding: '1px', overflow: 'visible',width: '75%', marginLeft: '12.5%', textAlign: 'center', fontSize: '.9rem', color: 'rgb(60,60,60)', borderTop: '.5px solid rgb(60,60,60)', borderBottom: '.5px solid rgb(60,60,60)'}}>{returnPayoutText(league)}</div>

            <div style={{ marginBottom: '4px', marginTop: '3px', paddingLeft: isMobile ? '4px':'40px', paddingRight: isMobile ? '4px':'60px'}}>

                {/*<hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%',}} />*/}

                <table style={{width: '100%',  textAlign: 'center'}}>
                {type && price && period && (teams && teams.length > 0) ? teams.map(t => {
                    if(t.team.league === league ||  league === 'ALL'){
                        return(
                            <>
                            <TeamCard key={t.team.id} id={t.team.id} />
                            </>
                        )
                    }
                }): 
                    <Loader />
                }
                </table>

            </div>

            {/*<Search teamSearch={teamSearch} />*/}

            

            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
    trades: state.trades.trades
});

export default connect(mapStateToProps)(FullLeague)