import React, {useState, useEffect, useRef} from 'react'
import '../LandingPage3/landingpage.css'
import './LandingPage3.css'
import {db, auth} from '../../services/firebase'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Form, FormLabel, Row, ToastBody } from 'react-bootstrap'
import {useHistory, Link} from 'react-router-dom'
import Ticker from '../Analysis3/Ticker'
import {signup} from '../../routes/auth/auth'
import {usernameRequest} from '../../routes/auth/username'
import Popup from 'reactjs-popup'
import {getToken} from '../../redux/actions/token'
import { useDispatch, useSelector } from 'react-redux';
 
const Signup = (props) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const emailField = useRef(null)
    const passwordField = useRef(null)

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')


    const [userCheck, setUserCheck] = useState(false)
    useEffect(()=>{
        if(username.length > 3){
            usernameRequest(username).then(res => {
                setUserCheck(res)
            })
        }
       
    },[username])

    let history = useHistory()
    const dispatch = useDispatch()
    
    const handleEmail = (e) => {
        if(username === email && (!e.target.value.includes('@') && !email.includes('@'))){
            setUsername(e.target.value)
        }
        setEmail(e.target.value)
        
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handleSignupRes = (res) => {
        let carry
        //mail with no '@', already used email
        //short(long) username, already used username
        //short password
        if(res.status === 200){
            carry = {type: 'success', data: res} //res returns {refresh_token: 'token', access_token: 'token'}
        }else if(res.status === 400){
            if(res.data && res.data.msg && !res.data.msg.query){ //already used email -or- already user username
                carry = {type: 'err', msg: res.data.msg}
            }
            else if(/*res.data.msg.query.email[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.email && res.data.msg.query.email[0]){
                carry = {type: 'err', msg: res.data.msg.query.email[0]}//short(long) or missing email
            }
            else if(/*res.data.msg.query.username[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.username && res.data.msg.query.username[0]){
                carry = {type: 'err', msg: `Username ${res.data.msg.query.username[0]}`} //short(long) or missing username
            }
            else if(/*res.data.msg.query.password[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.password && res.data.msg.query.password[0]){
                carry = {type: 'err', msg: `Password ${res.data.msg.query.password[0]}`} //short(long) or missing password
            }
            else if(/*res.data.msg.query.first_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.first_name && res.data.msg.query.first_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.first_name[0]} //short(long) or missing first_name
            }
            else if(/*res.data.msg.query.last_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.last_name && res.data.msg.query.last_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.last_name[0]} //short(long) or missing last_name
            }
        }
        else if(res.access_token && res.refresh_token){
            carry = {type: 'success', data: res}
        }
        else if(res.status === 500){
            carry = {type: 'err', msg: 'Error, please refresh'}
        }
        else {
            carry = {type: 'err', msg: 'Unknown Error, please refresh and try again or contact us at customer-service@simbull.app'}
        }
        return carry
    }

    const handleClick = (e) => {
        e.preventDefault()

        signup(email, username, password, 'First', 'Last').then(res => {
            let result = handleSignupRes(res)
            if(result.type === 'success'){
                dispatch(getToken(res))
                history.replace('/')
            }
            else if(result.type === 'err'){
                setError(true)
                setErrorMsg(result.msg)
            }
            else {
                setError(true)
                setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
            }
        })
        
        /*else{
            alert('Please fill out both fields.')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
        }*/
    }

    useEffect(() => {
        let interval = setInterval(() => {
          if (emailField.current) {
            setEmail(emailField.current.value)
            setPassword(passwordField.current.value)
            //do the same for all autofilled fields
            clearInterval(interval)
          }
        }, 100)
    })

    return(
        <>
        <div className="signup" style={{marginTop: "-10%"}}>
            <hr />
            <div className="FAQs3">
            <div className="signup-title robo900" style={{fontWeight: '400'}}>Create a Free Account and <br /><span style={{color: "#c5b358", fontSize: '1.4em', fontWeight: '800'}}>Get Started in Seconds</span></div>
            {/* <form onSubmit={handleClick}> */}
            {error && <div style={{color: 'red'}}>Error: {errorMsg}</div>}
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="formGridEmail" style={{textAlign: "center"}}>
                        <Form.Label className="robo400">Email</Form.Label>
                        <Form.Control onChange={handleEmail} value={email} className="signup-bg" type="email"  style={{textAlign: 'center', width: '70%', marginLeft: '15%'}} />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} controlId="formGridEmail" style={{textAlign: "center"}}>
                        <Form.Label className="robo400">Username</Form.Label>
                        {username.length > 3 && !userCheck && <div><small style={{color: 'red'}}>Username Taken</small></div>}
                        <Form.Control onChange={handleUsername} value={username} className="signup-bg"  style={{textAlign: 'center', width: '70%', marginLeft: '15%'}} />
                    </Form.Group>
                </Row>
               
                <Row>
                    <Form.Group as={Col} controlId="formGridEmail" style={{textAlign: "center"}}>
                        <Form.Label className="robo400">Password</Form.Label>
                        <Form.Control onChange={handlePassword} value={password} className="signup-bg" type="password" style={{textAlign: 'center', width: '70%', marginLeft: '15%'}} />
                    </Form.Group>
                </Row>
                <span className='signup-terms robo400'>By creating an account, you agree to SimBull's<a href='/Terms-Of-Use'> Terms</a></span>
                <br/>
                <div className="account-form">
                    <button className="topScrollBtn1 robo400" onClick={handleClick}>Go To the Market</button>
                </div>
            </Form>
            </div>
            {props.show && <div style={{textAlign: 'center'}}>Need to {props.show === 'login' ? 'Signup' : 'Login'}? <Link onClick={props.switchView}>{props.show === 'login' ? 'Signup' : 'Login'} Here</Link></div>}
            <hr />
        </div>

        </>
    )
}

export default Signup