import axios from 'axios'
import {route} from '../route'

/* signup */
export const usernameRequest = async (username) => {
    let token = ''
    let refresh = ''
    const usernameOptions = {
        method: 'Get',
        url: `${route}/username_available`,
        params: {
            "username": username,
        }
    }
    let carry
    await axios.request(usernameOptions).then(function (response) {
        let results = response
        carry = results.data
    }).catch(err => carry = err.response)
    return carry
}

