import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {reset_password} from '../../../routes/auth/reset_password'
import Popup from 'reactjs-popup'
import '../Account.css'

const ResetPassword = () => {

    const history = useHistory()
    const location1 = useLocation()

    const [location, setLocation] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [completed, setCompleted] = useState(false)

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    const resetPassword = () => {
        if(password && location && password.length > 5){
            reset_password(password, location).then(res => {
                setCompleted(true)
            })
        }
        else {
            setError(true)
            setErrorMsg('Invalid Password')
        }
        
    }

    useEffect(()=>{
        if(location1 && location1.search && location === ''){
            setLocation(location1.search.substring(7, location1.search.length))
        }
    },[location1])

    return(
        <>
        <div className="email-capture DesktopContainer">
            <div onClick={()=>{return history.push('/')}} className="back-btn">Back</div>
            <h1 className="pass-head">Create new password</h1>
            <p className="reset-body">Your new password must be different from your old passwords. </p>
            <div className="reset-input-head">Password</div>
            <div className="reset-input-div">
                <input value={password} type="password" onChange={(e)=>setPassword(e.target.value)} className="pass-input" />
            </div>
            <div className="pass-under">Must be atleast 8 characters.</div>
            <div className="reset-input-head">Confirm Password</div>
            <div className="reset-input-div">
                <input value={confirm} type="password" onChange={(e)=>setConfirm(e.target.value)} className="pass-input" />
            </div>
            <div className="pass-under">Both passwords must match.</div>
            <div>
                <button onClick={resetPassword} className="reset-input-btn">Reset Password</button>
            </div>
        </div>

        <Popup open={completed} modal>
        <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
            <div style={{textAlign: 'center', fontSize: '2.5em'}}>Reset Password</div>
            <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                <div style={{fontSize: '1.5em', fontWeight: '500'}}>Successful</div>
                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>{return history.replace('/login')}} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Login</button><br />
                </div>
            </div>
            <div style={{fontSize: '.5em', fontWeight: '300', width: '80%', marginLeft: '10%'}}>**Prices may change due to market fluctuations. Trade will execute at the best available price.</div>
        </div>
        </Popup>
        </>
    )
}

export default ResetPassword