import * as types from "../../types";
import {getTotals} from '../../../routes/user/user'

export const getUserTotals = (token) => (dispatch) => {

  getTotals(token).then(res => {
    dispatch({
      type: types.GET_USER_TOTALS,
      payload: res
    })
  })

};
