import React, {useState, useEffect} from 'react'
import './functions.css'
import {Link, withRouter, useHistory} from 'react-router-dom'
import {db, storageRef} from '../../services/firebase'
import firebase from 'firebase'
import moment from 'moment'
import { connect } from 'react-redux'
import {makeOffer} from '../../routes/trading/trading'
import Popup from 'reactjs-popup'
import amplitude from 'amplitude-js'

const CreateBid = (props) => {
    //props
    const team = props.team
    const user = props.user
    const closeBid = props.closeBid

    const history = useHistory()

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

    //states
    const [calc, setCalc] = useState('')
    const [showShare, setShowShare] = useState(true)
    const [showPrice, setShowPrice] = useState(false)
    const [bidPrice, setBidPrice] = useState()
    const [bidShares, setBidShares] = useState()
    const [maxBuy, setMaxBuy] = useState(50)
    const [url, setUrl] = useState(team && team.team && team.team.name ? `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team && team.team && team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`: '')
    const [openBid, setOpenBid] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [openReceipt, setOpenReceipt] = useState(false)

    //getImageURL
    useEffect(()=>{
        if(props.team && !url){
            setUrl(`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team && team.team && team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`)
        }
    }, [props.team])

    //Functionality
    //calc add digit
    const addDigit = (x) => {
        if(x === '.' && showPrice && !bidPrice){
            setBidPrice(`0.`)
        }
        else if(x === '.' && showShare && !bidShares){
            setBidShares(`0.`)
        }
        else if(x === '.' && ( showPrice ? bidPrice.toString().substring(bidPrice.toString().length - 1, bidPrice.toString().length) === '.' : bidShares.toString().substring(bidShares.toString().length - 1, bidShares.toString().length) === '.')){
            return null
        }else{
            if(!showPrice){
                let hold = bidShares ? bidShares.toString() : '0'
                let thisNum = hold.concat(x)
                
                if(x === '.'|| x === '0'){
                    setBidShares(thisNum)
                }
                else{
                    setBidShares(parseFloat(thisNum))
                }
            }else{
                let hold = bidPrice ? bidPrice.toString() : '0'
                let thisNum = hold.concat(x)
                if(x === '.'|| x === '0'){
                    setBidPrice(thisNum)
                }
                else{
                    setBidPrice(parseFloat(thisNum))
                }
            }  
        }
    }

    const deleteDigit = () => {
        if(showShare){
            const editedText = calc.slice(0, -1)
            setBidShares(parseFloat(editedText))
            setCalc(editedText)
        }else if(showPrice){
            const editedText = calc.slice(0, -1)
            setBidPrice(parseFloat(editedText))
            setCalc(editedText)
        }
        
    }

    const handleError = () => {
        setOpenBid(false)
        setBidShares(0)
        setBidShares(0)
        setCalc('')
        setShowShare(true)
        setShowPrice(false)
    }

    const createBid = () => {
        if(parseFloat(bidPrice) !== 0 && parseFloat(bidShares) !== 0){
            if(team && props.token && props.totals /*&& props.totals.cash_balance > (bidPrice * bidShares).toFixed(2)*/ ){
                makeOffer(props.token, "BID", parseFloat(bidShares), team.team.id, parseFloat(bidPrice)).then(res => {
                    if(res.status === 400){
                        setError(true)
                        setErrorMsg(res.data.msg)
                        handleError()
                    }
                    else if(res.response && res.response.status === 400){
                        if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.amount && res.response.data.msg.query.amount[0]){
                            setError(true)
                            setErrorMsg(res.response.data.msg.query.amount[0])
                            handleError()
                        }
                        else if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.price && res.response.data.msg.query.price[0]){
                            setError(true)
                            setErrorMsg(res.response.data.msg.query.price[0])
                            handleError()
                        }
                        else {
                            setError(true)
                            setError(`Error: Sorry, please try again.`)
                            if(res.response.data && res.response.data.msg && typeof res.response.data.msg === 'string'){
                                setErrorMsg(res.response.data.msg)
                            }
                            handleError()
                        }
                        
                    }
                    else if(res.response && res.response.status === 401){
                            setError(true)
                            setErrorMsg("Sorry, please refresh and try again")
                            handleError()
                        }
                        else if(res.status && res.status === 401){
                            setError(true)
                            setErrorMsg("Sorry, please refresh and try again")
                            handleError()
                        }
                    else if(res.status && res.status === 200){
                        setOpenReceipt(true)
                    }
                    
                }).catch(err => {
                    setError(true)
                    setErrorMsg(`Error: Sorry, please try again.`)
                    handleError()
                })
            }
            else{
                setError(true)
                setErrorMsg(`You don't have enough cash to make a bid. You have ${props.totals ? props.totals.cash_balance : 0}, and you're trying to bid for ${(parseFloat(bidPrice) * parseFloat(bidShares)).toFixed(2)}`)
                handleError()
            }
        }
        else{
            setError(true)
            setErrorMsg(`Enter Inputs First`)
            handleError()
        }

    }

    //letting you use the keyboard
    const handleKeyDown = (event) => {
        const keys = ['1','2','3','4','5','6','7','8','9','0','.']
        if(keys.includes(event.key)){
            addDigit(event.key !== '.' ? parseInt(event.key) : event.key)
        }
        else if(event.code === 'Backspace'){
            deleteDigit()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
    
        // cleanup this component
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    });
    

    return(
        <div id="CreateBidDiv" className="TradePopup" style={{backgroundColor: 'black'}}>
            <div className="SellShare">
                <Link onClick={closeBid}><i className="fa fa-times-circle"></i></Link>
                <div className="ss-title">Offer to Buy</div>
                <div className='ss-team'><img className="ss-img"  src={url} alt='team' /><span className="tc-name-sim">Sim</span>{team && team.team && team.team.name}</div>
                <div className="h-bidask">
                    <div className="h-bid">
                        Bid
                        <br />
                        <div className="h-bid-p green">{team && team.bidding_price.toFixed(2)}</div>
                    </div>
                    <div className="h-price">
                        ${team && team.asking_price.toFixed(2)}
                    </div>
                    <div className="h-ask">
                        Ask
                        <br />
                        <div className="h-ask-p red">{team && team.asking_price.toFixed(2)}</div>
                    </div>   
                </div>
                <div className='ss-cash'>Cash Available: ${props.totals && props.totals.cash_balance && props.totals.cash_balance.toFixed(2)}</div>
                {/*!user.gold*/false && <Link to="/Subscription"><div onClick={() => amplitude.getInstance().logEvent("bid_gold")} style={{textAlign: 'center', fontWeight: '300', marginTop: '8px', fontSize: '0.9rem', color: 'gray'}}>Buy SimBull GOLD ($4.16/Month)<br /><span style={{fontSize: '.8rem'}}>Free Trading and More!</span></div></Link>}
                {showShare && <div className="ss-shareAmt"><span className="ss-share-label">Shares:</span> <span className="ss-shareAmt-underline">{bidShares ? bidShares : '0'}</span></div>}
                {showPrice && <div className="ss-shareAmt"><Link onClick={()=>{setShowPrice(false);setShowShare(true);setCalc('');setBidPrice();setBidShares()}}><i className="fa fa-undo"></i></Link><span className="ss-share-label">Price Per Share:</span> <span className="ss-shareAmt-underline">{bidPrice ? bidPrice : '0.00'}</span></div>}
                <div className="ss-btn-div">
                    {error && <div style={{textAlign: 'center', color: 'red'}}>Error: {errorMsg}</div>}
                    {showShare && <><button className="ss-submit" style={{backgroundColor: '#c4b454', borderRadius: '2rem'}} onClick={()=>{setShowPrice(true);setShowShare(false);setCalc(''); amplitude.getInstance().logEvent("bid_continue")}}>Next</button><br /></>}
                    {showPrice && <><button className="ss-submit" onClick={()=>setOpenBid(true)}>Create Offer to Buy</button><br /></>}
                    {[1,2,3,4,5,6,7,8,9].map(item => 
                        <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit(item.toString())}}>{item}</button>
                    )}
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit(".")}}>.</button>
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{addDigit("0")}}>0</button>
                    <button className="ss-btn-2" style={{backgroundColor: 'rgb(30,30,30)', color: 'white', border: 'none', outline: 'none'}} onClick={()=>{deleteDigit()}}><i className="fa fa-arrow-left"></i></button>
                </div>
            </div> 

            <Popup open={openBid} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    <div onClick={()=> amplitude.getInstance().logEvent("bid_confirm")} style={{textAlign: 'center', fontSize: '2.5em'}}>Confirm Bid</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Team</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>Sim{team && team.team.name}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Shares</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>{bidShares} Share{bidShares ===1 ? '' : 's'}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Avg Price Per Share</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${parseFloat(bidPrice).toFixed(2)}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Total</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(bidPrice) * parseFloat(bidShares)).toFixed(2)}</div>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={createBid} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Confirm</button><br />
                            <button onClick={()=>setOpenBid(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>
                    </div>
                </div>
            </Popup>


            <Popup open={openReceipt} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    <div style={{textAlign: 'center', fontSize: '2.5em'}}>Bid Created!</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Team</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>Sim{team && team.team.name}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Shares</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>{bidShares} Share{bidShares ===1 ? '' : 's'}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Avg Price Per Share</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${parseFloat(bidPrice).toFixed(2)}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Total</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(bidPrice) * parseFloat(bidShares)).toFixed(2)}</div>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={()=>{setOpenReceipt(false); closeBid(); return history.replace( {pathname:`/`, state: {refresh: true}} ) } } className="share-close-btn">Back to Portfolio</button><br />
                            <button onClick={ ()=>{setOpenReceipt(false); closeBid(); return history.replace( {pathname:`/Teams/${team.team.name}`, state: {refresh: true}} ) } } className="share-close-btn">Back to Team Card</button>
                        </div>
                    </div>
                </div>
            </Popup>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    totals: state.totals.totals,
    token: state.token.token

});

export default connect(mapStateToProps)(withRouter(CreateBid))