import React from 'react'
import './Teammate.css'
import {Link} from 'react-router-dom'

const Teammate = (props) => {

    const getOneDayReturn = () => {
        return props.s.one_day_return.change / (props.s.value - props.s.one_day_return.change)
    }

    let change = (props.period === '1D' && props.s.one_day_return && getOneDayReturn()) ? props.s.one_day_return.change : (props.s.total_return && props.s.total_return.change && props.s.total_return.final)

    //window.localStorage.setItem(props.s.username, JSON.stringify(props.s))

    //console.log(JSON.parse(window.localStorage.getItem(props.s.username)))

    return(
        <>
        {props.s.value ? 
            <Link onClick={()=>props.changeTeammate(props.s)}>
            <div className="Teammate">
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr style={{width: '100%'}}>
                        <td className="teammate-left">{props.s && props.s.username}</td>
                        <td className="teammate-right">
                            <div className="teammate-value">${(props.s && props.s.value) ? props.s.value.toFixed(0) : 0}</div> 
                            <span style={{marginLeft: '3px', marginRight: '3px', color: 'gray'}}>|</span>
                            <div className={`teammate-percent ${(change > -.01 && change < 0.01) ? 'graytxt' : change < -0.01 ? 'redtxt' : 'greentxt'}`}>{change < -0.01 ? '-' : '+'}{Math.abs(((change / Math.abs(props.s.value - change)) *100)).toFixed(2)}%</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </Link>
            :
            <Link onClick={()=>props.changeTeammate(props.s)}>
            <div className="Teammate">
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr style={{width: '100%'}}>
                        <td className="teammate-left">{props.s && props.s.username}</td>
                        <td className="teammate-right">
                            <div className="teammate-value">$0</div> 
                            <span style={{marginLeft: '3px', marginRight: '3px', color: 'gray'}}>|</span>
                            <div className={`teammate-percent ${change === 0 ? 'gray' : change < 0 ? 'redtxt' : 'greentxt'}`}>{change < 0 ? '' : '+'}{change.toFixed(2)}%</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </Link>
        }
        </>
    )
}

export default Teammate