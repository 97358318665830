import moment from 'moment'
import {getTeamPriceHistory} from '../../../routes/teams/teams'

export const getDates = (start, arr) => {

    const returnDaysHeld = () => {
        var a = moment(start);
        var b = moment().format('YYYY-MM-DD');
        return 0 - a.diff(b, 'days')   // =1
    }

    let dates = []

    for(let i = returnDaysHeld(); i>0; i--) {
        let d = moment().subtract(i, 'd').format('YYYY-MM-DD')
        dates.push(d)
    }

    let labels = []
    let prices = []

    let current = 0

    dates.map(d => {
        arr.map(a => {
            if(a.timestamp.substring(0,10) === d){
                current = a.price
            }
        })
        labels.push(d)
        prices.push(current.toFixed(2))
    })

    return[labels, prices]

}

export const weeklyGraph = async (id) => {
    var days = 7; // Days you want to subtract
    var date = new Date();
    var start = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    
    let res = await getTeamPriceHistory(id, 'HOUR', start)

    let prices = []
    let labels = []

    res.map(r => {
        let interval = 4
        let remainder = (res.length - 1) % interval
        if(res.indexOf(r) % interval === remainder){
            prices.push(r.price.toFixed(2))
            labels.push(moment(`${r.timestamp}.000Z`).format('MM/DD ha'))
        }
    })

    let dayPrices = []
    let dayLabels = []

    res.map(r => {
        let ind = res.indexOf(r)
        if(ind > res.length - 25){
            dayPrices.push(r.price.toFixed(2))
            dayLabels.push(moment(`${r.timestamp}.000Z`).format('MM/DD ha'))
        }
    })

    return [prices, labels, dayPrices, dayLabels]
}