export const ADD_USER = "ADD_USER";
export const ADD_TEAMS = "ADD_TEAMS";
export const ADD_USER3 = "ADD_USER3";
export const ADD_TEAMS3 = "ADD_TEAMS3";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const GET_TOKEN = "GET_TOKEN"
export const GET_TEAMS_VALUE = "GET_TEAMS_VALUE"
export const GET_USER_INFO = "GET_USER_INFO"
export const ADD_TRADES = "ADD_TRADES"
export const GET_USER_EQUITY = "GET_USER_EQUITY"
export const GET_USER_TOTALS = "GET_USER_TOTALS"
export const GET_USER_PRICES = "GET_USER_PRICES"
export const GET_INFO = "GET_INFO"
export const GET_MY_USER = "GET_MY_USER"
export const GET_USER_WATCHLIST = "GET_USER_WATCHLIST"