import React, {useState, useEffect} from 'react'
import './ProfileFeed.css'
import TeammateItem from '../TeammateItem'
import {firebaseAnalytics} from '../../../services/firebase'

const ProfileFeed = (props) => {

    const [feed, setFeed] = useState([])

    useEffect(() => {
        firebaseAnalytics.logEvent("teammate_card_feed_visit")
    }, [])

    const [needFollow, setNeedFollow] = useState(true)
    useEffect(()=>{
        setNeedFollow(props.needFollow)
    },[props.needFollow])

    return(
        <>
        <div className="ProfileFeed">
            {/*<div className="ProfileFeed-Header">Activity</div>*/}

            {props.transactions && props.transactions.length > 0 && props.transactions.map(i => {
                    return <div key={i.id}><TeammateItem feed='profile' teammateID={props.teammateID} username={props.username} teammates={props.teammates} teamsValue={props.teamsValue} i={i} /></div>
            })}

            {((!props.transactions || props.transactions.length === 0) && !needFollow) &&  <>
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Doesn't have any history</div>
            </>}

            {((!props.transactions || props.transactions.length === 0) && needFollow) &&  <>
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Follow to view history</div>
            </>}

        </div>
        </>
    )
}

export default ProfileFeed