import * as types from "../../types";
import {getMyUser} from '../../../routes/user/user'

export const get_my_user = (token) => (dispatch) => {

    getMyUser(token).then(res => {
        dispatch({
            type: types.GET_MY_USER,
            payload: res
        })
    })

};
