import React, {useState, useEffect} from 'react'
import './Profile/TeammateProfile.css'
import {follow, unfollow, following} from './utils'
import {connect} from 'react-redux'
import ReactLoading from 'react-loading'
import Popup from 'reactjs-popup'

const Follow = (props) => {

    const [loading, setLoading] = useState(false)

    const [amFollowing, setAmFollowing] = useState(false)

    const [unfollowPopup, setUnfollowPopup] = useState(false)

    const handleFollow = (token, username) => {
        if(props.click){
            return true
        }
        else if(amFollowing) {
            handleUnfollow(token, username)
            console.log('here')
        }
        else{
            setLoading(true)
            follow(token, username).then(res => {
                console.log(res)
                setAmFollowing(true)
                props.rerunTeammates(username).then(res => setLoading(false))
            })
        }
        
    }

    const handleUnfollow = (token, username) => {
        console.log('unfollow')
        setLoading(true)
        unfollow(token, username).then(res => {
            console.log(res)
            setAmFollowing(false)
            setLoading(false)
            props.rerunTeammates().then(res => setLoading(false))
        })
    }

    const followingFunc = () => {
        if(props.teammates && props.s.username){
            let ans = following(props.s.username, props.teammates)
            if(ans){
                setAmFollowing(true)
            }
        }
    }
    useEffect(()=>{
        followingFunc()
    },[props.teammates, props.s])
 
    return(
        <>
        <div>
            {loading && <div style={{width: '50%', marginLeft: '25%'}}><ReactLoading width='100%' type='spokes' color='rgb(200,200,200)' /></div> }
            {!loading && <button onClick={()=>handleFollow(props.token, props.s.username)} className={amFollowing ? "TeammateProfile-Member-Following-Btn" : "TeammateProfile-Member-Follow-Btn"}>{amFollowing ? 'Following' : 'Follow'}</button>}
        </div>

        <Popup open={unfollowPopup} modal>
            <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '90vw', marginLeft: '0vw', height: '50vh', marginTop: '2vh', marginBottom: '0vh', backgroundColor: 'rgb(250,250,250)', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                

                <h3>Do you want to unfollow {props.s.username}?</h3>

                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>handleUnfollow(props.token, props.s.username)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>Unfollow</button>
                    <button onClick={()=>setUnfollowPopup(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>Cancel</button>
                </div>

                
                
            </div>
        </Popup>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(Follow)