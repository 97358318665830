import React, {useState, useEffect} from 'react'
import './TeammateProfile.css'
import Value from './Value'
import ProfileFeed from './ProfileFeed'
import Teams from '../../Portfolio3/TeamsTeammates'
import {getTeammateTransactions, getTeammateEquity} from '../utils'
import Follow from '../Follow'
import {firebaseAnalytics} from '../../../services/firebase'

const TeammateProfile = (props) => {

    const [teammate, setTeammate] = useState()
    useEffect(async ()=>{
        let mate = props.teammate.username ? (window.localStorage.getItem(props.teammate.username) ? await JSON.parse(window.localStorage.getItem(props.teammate.username)) : props.teammate) : props.teammate
        setTeammate(mate.result)
    }, [])

    const returnMemberColor = (teammate) => {
        if(teammate.value){
            if(teammate.gold){
                return '#e0cb07'
            }
            else return 'rgb(230,230,230)'
        }
        else if(props.teammate.teammate){
            if(props.teammate.teammate.gold){
                return '#e0cb07'
            }
            else return 'rgb(230,230,230)'
        }
        else return '#fffafa'
        
    }

     const [show, setShow] = useState('list')

     const [transactions, setTransactions] = useState([])
     const [equity, setEquity] = useState([])

     const [needFollow, setNeedFollow] = useState(false)

     const [teammateID, setTeammateID] = useState()

     useEffect(()=>{
         if(props.token){
             if(props.teammate && props.teammate.teammate && props.teammate.teammate.follow_id){
                 
                getTeammateTransactions(props.token, props.teammate.teammate.follow_id).then(res => setTransactions(res))
                getTeammateEquity(props.token, props.teammate.teammate.follow_id).then(res => setEquity(res))
                setNeedFollow(false)
                setTeammateID(props.teammate.teammate.follow_id)
             }
             else if(props.teammate && props.teammate.teammate && props.teammate.teammate.id){
                //setNeedFollow(true)
                getTeammateTransactions(props.token, props.teammate.teammate.id).then(res => {
                    setTransactions(res)
                    if(res && res.length > 0){
                        //do nothing
                    }else setNeedFollow(true)
                })
                getTeammateEquity(props.token, props.teammate.teammate.id).then(res => setEquity(res))
                
                setTeammateID(props.teammate.teammate.id)
             }
             else {
                getTeammateTransactions(props.token, props.teammate.id).then(res => setTransactions(res))
                getTeammateEquity(props.token, props.teammate.id).then(res => setEquity(res))
                setNeedFollow(false)
                setTeammateID(props.teammate.id)
             }
         }
     },[props.token, props.teammate])

    useEffect(()=>{
        //console.log(transactions)
        //console.log(equity)
    },[transactions, equity])

    useEffect(() => {
        firebaseAnalytics.logEvent("teammate_card_visit")
    }, [])


    useEffect(()=>{
        //console.log(props)
    },[props])

    return(
        <>
        <div className="TeammateProfile">
            <div className="TeammateProfile-User">
                <div className="TeammateProfile-DisplayName">{props.teammate.username ? props.teammate.username : props.teammate.teammate ? props.teammate.teammate.username : ''}</div>
                <div className="TeammateProfile-Username">@{props.teammate.username ? props.teammate.username : props.teammate.teammate ? props.teammate.teammate.username : ''}</div>
            </div>
            <div className="TeammateProfile-Member">
                <div className="TeammateProfile-Member-Color" style={{color: returnMemberColor(props.teammate)}}>{props.teammate.gold ? 'GOLD' : (props.teammate.teammate && props.teammate.teammate.gold) ? 'GOLD' : 'NONE'}</div>
                <div className="TeammateProfile-Member-Follow">
                    <Follow teammates={props.teammates} rerunTeammates={props.rerunTeammates} changeTeammate={props.changeTeammate} s={props.teammate} />{/*<button className="TeammateProfile-Member-Follow-Btn">Follow</button>*/}
                </div>
            </div>
            <hr style={{margin: '5px'}} />
            {(props.teammate.value || props.teammate.value === 0) && <Value teammate={props.teammate} />}
            {(props.teammate.value || props.teammate.value === 0) ?
                needFollow
                ? 
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Follow to View Portfolio</div>
                : <>
            <div className="ProfileShow">
                <button onClick={()=>setShow('feed')} className={show === 'feed' ? "profile-show-btn psb-select" : "profile-show-btn"} style={{borderRadius: '.5rem 0rem 0rem .5rem'}}>
                    Feed
                </button>
                <button onClick={()=>setShow('list')} className={show === 'list' ? "profile-show-btn psb-select" : "profile-show-btn"} style={{borderRadius: '0rem .5rem .5rem 0rem'}}>
                    Portfolio
                </button>
            </div>
            {show === 'feed' && <ProfileFeed profile={true} publicAccounts={props.publicAccounts} teammateID={teammateID} username={props.teammate.username} needFollow={needFollow} teammates={props.teammates} teamsValue={props.teamsValue} transactions={transactions} />}
            {show === 'list' && <Teams needFollow={needFollow} teammates={props.teammates} teamsValue={props.teamsValue} transactions={transactions} equity2={equity} />}
                </>
            :
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Follow to View</div>
            }
        </div>
        </>
    )
}

export default TeammateProfile