import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import './portfolio2.css'
import Team from './Team'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import {getTeamsValue} from './Functions'


const Teams = (props) => {
    //get props
    const user = props.user

    const history = useHistory()

    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])
    const [equity, setEquity] = useState([])
    const [hasShares, setHasShares] = useState(false)

    useEffect(()=>{
        if(props.equity2){setEquity(props.equity2)}
        if(props.equity2 && props.equity2.length > 0){
            props.equity2.map(e => {
                if(e.amount > 0 && !hasShares){
                    setHasShares(true)
                }
            })
        }
    },[props.equity])

    useEffect(()=>{
        if(equity && equity.length === 0 && props.equity2){
            setEquity(props.equity2)
        }
        if(teams && teams.length === 0 && props.teamsValue){
            setTeams(props.teamsValue)
        }
    },[props])

    /*teams.sort(function(a,b){
        return Math.abs(b.lastSoldPrice - b.prices[yesterday]) - Math.abs(a.lastSoldPrice - a.prices[yesterday])
    })*/

    let myTeamsArr = []
    let myTeams = []

    let getTeamInfo = (id) => {
        let team
        teams && teams.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    useEffect(() => {
        firebaseAnalytics.logEvent("teammate_card_portfolio_visit")
    }, [])

    return(
    <>
        
        <div style={{margin: '5px', paddingTop: props.watchlist ? '0px' : '10px'}}>
            
            {teams && equity && teams.length > 0 && equity.length > 0 && <>
                {props.watchlist !== true && <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '73%', paddingLeft: '5px'}}>Teams</div> <div style={{width: '20%',textAlign: 'right', display: 'inline-block', paddingLeft: '5px'}}>{hasShares && <Link to='/MyTeams'><span style={{color: 'black', fontSize: '.8em'}} className="fa fa-ellipsis-v"></span></Link>}</div></div>}
                <hr style={{marginTop: '2px', marginBottom: '4px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
                {equity.map(e => {
                    if(e.amount > 0){
                        let t = getTeamInfo(e.asset_id)
                        if(t && t.team && t.team.id === e.asset_id){
                            return(
                                <Team key={t.team.id} t={t} e={e} />
                            )
                        }
                    }
                })}
            </>}

            {equity.length === 0 && !props.needFollow && <>
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Doesn't own any teams</div>
            </>}

            {equity.length === 0 && props.needFollow && <>
                <div style={{textAlign: 'center', marginTop: '25px', fontWeight: '300'}}>Follow to view portfolio</div>
            </>}
            
            
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    
});

export default connect(mapStateToProps)(Teams)