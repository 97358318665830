import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import TeamCard from './TeamCard'


const Watchlist = (props) => {

    const [watchlist, setWatchlist] = useState((props.watchlist && props.watchlist.teams) ? props.watchlist.teams : props.watchlist)

    useEffect(()=>{
        if(props.watchlist && props.watchlist.teams){
            setWatchlist(props.watchlist.teams)
        }
        else if(props.watchlist){
            setWatchlist(props.watchlist)
        }
    },[props.watchlist])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px', paddingBottom: isMobile ? '12px' : '0px'}}>
            {isMobile ?
            <div style={{color: 'white', fontSize: '1.4rem', overflowX: 'visible', whiteSpace: 'nowrap', fontWeight: '400', width: isMobile ? '30px' :'50px', marginLeft: '4px'}}>Your Watchlist</div>        
            : null}
            <table style={{width: '100%', color: 'rgb(100,100,100)', textAlign: 'center'}}>
                {isMobile ? null :<tr style={{fontWeight: '300', fontSize: '.85rem', verticalAlign: 'bottom'}}>
                    <td style={isMobile ? {} : {color: 'white', fontSize: '1.4rem', overflowX: 'visible', whiteSpace: 'nowrap', fontWeight: '400', width: isMobile ? '30px' :'50px'}}>{isMobile ? '' : 'Watchlist'}</td>        
                    <td style={{textAlign: 'left'}}>{isMobile ? 'Team' : ''}</td>
                    <td></td>
                    
                    <td>Price</td>
                    <td>1D</td>
                </tr>}
            {watchlist &&  watchlist.length > 0 && watchlist.map(w => {
                return <TeamCard key={w} id={w} />
            })}
            {(!watchlist || watchlist.length === 0) && props.teamsValue && props.teamsValue.map(t => {
                let teams = props.teamsValue
                if((teams.indexOf(t) === 10) || (teams.indexOf(t) === 22) || (teams.indexOf(t) === 60) || (teams.indexOf(t) === 88) || (teams.indexOf(t) === 101)){
                    return <TeamCard key={t.team.id} id={t.team.id} />
                }
            })}
            </table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    watchlist: state.watchlist.watchlist,
});

export default connect(mapStateToProps)(Watchlist)