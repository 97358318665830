import React, { useState, useEffect } from "react";
import "./Timeline.css";
import "./NumberProof";
import NumberProof from "./NumberProof";
import AboveFold from "./AboveFold";
import NavBar from "./NavBar";
import Logo from "../../assets/Logo1.png";
import Reviews from "./Reviews";
import Questions from "./Questions";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import First from "./first.png";
import Second from "./second.png";
import Third from "./last.png";
import amplitude from "amplitude-js";

const Timeline = (props) => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  let step1 =
    "https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fstep1.png?alt=media&token=d8af06b3-4c85-4bcc-b711-6a39399c0e3f";
  let step2 =
    "https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fstep2.svg?alt=media&token=b062da80-9eed-4cf5-9f95-4685257510e1";
  let step3 =
    "https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fstep3.png?alt=media&token=2203e23d-1b20-4aea-8bb5-0de66e669231";

  return (
    <>
      {/*<NavBar />

        <AboveFold />

        <NumberProof />*/}

      <div
        style={{
          padding: isMobile ? "25px" : "50px",
          textAlign: "center",
          paddingBottom: "50px",
          backgroundColor: "black",
          color: "white",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "40%",
            marginLeft: isMobile ? "0%" : "30%",
            textAlign: "center",
            fontSize: "1.85em",
            fontWeight: "600",
            backgroundColor: "black",
          }}
        >
          With Simbull, fortune favors you, the fan.
        </div>
      </div>
      <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
        <section class="timeline">
          <div class="timeline-item">
            <div class="timeline-img">1</div>

            {!isMobile && (
              <div class="timeline-content">
                <img
                  src={First}
                  style={{ width: "35%", position: "relative", left: "60%" }}
                />
              </div>
            )}
            <div class="timeline-content2">
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1.15em",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                {" "}
                TRADE TEAMS LIKE STOCKS. BUY LOW. SELL HIGH.
              </div>
              <div
                style={{ marginTop: "10px", fontSize: ".95em", color: "white" }}
              >
                Buy and sell virtual stock in NFL, College Football, NBA, MLB, &
                NHL teams. Prices are determined solely by SUPPLY & DEMAND. It's
                you vs. the market.{" "}
              </div>
              <Link
                onClick={() => {return window.open("https://apps.apple.com/app/apple-store/id1628754092")}}>
                <div
                  onClick={() => {amplitude
                    .getInstance()
                    .logEvent("free_stock_landing"); return window.open("https://apps.apple.com/app/apple-store/id1628754092")} }
                  id="arrow"
                  style={{
                    color: "#c4b454",
                    fontWeight: "550",
                    fontSize: ".8em",
                    marginTop: "10px",
                  }}
                >
                  Collect a free team stock valued up to $150 in the app
                  <i
                    class="fa fa-arrow-right"
                    style={{
                      fontSize: "1em",
                      marginLeft: "5px",
                      fontWeight: "400",
                    }}
                  ></i>
                </div>
              </Link>
            </div>

            {isMobile && (
              <div class="timeline-content">
                <img src={First} style={{ width: "100%" }} />
              </div>
            )}
          </div>

          <div class="timeline-item">
            <div class="timeline-img">2</div>

            <div class="timeline-content2left">
              <div
                style={{
                  fontSize: "1.15em",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                {" "}
                EARN CASH WIN-PAYOUT DIVIDENDS
              </div>
              <div
                style={{ marginTop: "10px", fontSize: ".95em", color: "white" }}
              >
                Every time a team you own wins a game, you receive a cash
                dividend. When they lose, you lose nothing. For every share you
                own, the payout is $0.50/win for NFL teams & CFB teams;
                $0.10/win for NBA & NHL; and $0.05/win for MLB.{" "}
              </div>
              <Link to="/signup">
                <div
                onClick={() => {amplitude
                    .getInstance()
                    .logEvent("buy_team_landing"); return window.open("https://apps.apple.com/app/apple-store/id1628754092")} }
                  id="arrow"
                  style={{
                    color: "#c4b454",
                    fontWeight: "550",
                    fontSize: ".8em",
                    marginTop: "10px",
                  }}
                >
                  Buy your first team, sit back, collect money
                  <i
                    class="fa fa-arrow-right"
                    style={{
                      fontSize: "1em",
                      marginLeft: "5px",
                      fontWeight: "400",
                    }}
                  ></i>
                </div>
              </Link>
            </div>

            <div class="timeline-content">
              <img src={Second} style={{ width: isMobile ? "100%" : "35%" }} />
            </div>
          </div>

          <div class="timeline-item">
            <div class="timeline-img">3</div>

            {!isMobile && (
              <div class="timeline-content">
                <img
                  src={Third}
                  style={{ width: "35%", position: "relative", left: "60%" }}
                />
              </div>
            )}

            <div class="timeline-content2">
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1.15em",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                {" "}
                PROFIT FROM YOUR SPORTS FANDOM
              </div>
              <div
                style={{ marginTop: "10px", fontSize: ".95em", color: "white" }}
              >
                Because SimBull is an open-market, YOU are in control of your
                money. It's you vs. the market, not you vs. SimBull. If you know
                sports, your teams will produce big returns.
              </div>
              <Link to="/signup">
                <div
                onClick={() => {amplitude
                    .getInstance()
                    .logEvent("buy_low_landing"); return window.open("https://apps.apple.com/app/apple-store/id1628754092")} }
                  id="arrow"
                  style={{
                    color: "#c4b454",
                    fontWeight: "550",
                    fontSize: ".8em",
                    marginTop: "10px",
                  }}
                >
                  Buy low so you can sell high
                  <i
                    class="fa fa-arrow-right"
                    style={{
                      fontSize: "1em",
                      marginLeft: "5px",
                      fontWeight: "400",
                    }}
                  ></i>
                </div>
              </Link>
            </div>

            {isMobile && (
              <div class="timeline-content">
                <img src={Third} style={{ width: "100%" }} />
              </div>
            )}
          </div>

          {!isMobile && (
            <div style={{ width: "100%" }}>
              <img src={Logo} style={{ width: "5%", marginLeft: "47.5%" }} />
            </div>
          )}
          {isMobile && (
            <div style={{ width: "100%" }}>
              <img
                src={Logo}
                style={{
                  width: "17%",
                  marginLeft: "-4%",
                  display: "flex",
                  marginLeft: "43%",
                }}
              />
            </div>
          )}
        </section>
      </div>

      <div style={{ textAlign: "center", backgroundColor: "black" }}>
        <button
          onClick={() => {
            window.open(
              "https://apps.apple.com/app/apple-store/id1628754092"
            ); amplitude
            .getInstance()
            .logEvent("free_stock_landing");
          }}
          className="bottom-btn"
        >
          Get a free team stock valued up to $150 on the app
        </button>
      </div>

      {/*<Reviews />

        <Questions />

        <Footer />*/}
    </>
  );
};

export default Timeline;
