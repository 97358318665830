import * as types from "../types";
import {db} from '../../services/firebase'
import {getTrades} from './trades'

export const getTeams = () => (dispatch) => {
  
  db.collection("Teams").onSnapshot((snapshot) => {
    let teams1 = [];
    let nflArr = [];
    let nbaArr = [];
    let mlbArr = [];
    let cfbArr = [];
    let nhlArr =[];

    snapshot.forEach((doc, i) => {
      const data = doc.data();

      teams1.push(data);
      data.league === "NBA" && nbaArr.push(data);
      data.league === "NFL" && nflArr.push(data);
      data.league === "MLB" && mlbArr.push(data);
      data.league === "CFB" && cfbArr.push(data);
      data.league === "NHL" && nhlArr.push(data);
      
    });

    dispatch({
      type: types.ADD_TEAMS,
      payload: {teams: teams1, nfl: nflArr, nba: nbaArr, mlb: mlbArr, cfb: cfbArr, nhl: nhlArr}
    })
  });
};
