import React, { useState, useEffect } from 'react'
import './Account.css'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {db} from '../../services/firebase'
import moment from 'moment'
import firebase from 'firebase'
import {getSubscription, createSubscription} from '../../routes/user/subscription'
import GoldYearly from './PaypalDesktop/Subscriptions/GoldYearly'
import axios from 'axios'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import logo1 from '../assets/Logo1.png'

const GOLD = (props) => {

    let logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa'

    const user = props.user

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const [gold, setGold] = useState(props.myuser && props.myuser.fees ? false : true)//user.gold ? user.gold : false)
    const [receipts, setReceipts] = useState([])
    const [subscription, setSubscription] = useState(false)

    //show
    const [showReceipts, setShowReceipts] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [showPurchase, setShowPurchase] = useState(false)
    const [showTrial, setShowTrial] = useState(false)
    const [subCanceled, setSubCanceled] = useState(false)


    //getSubscription
    const pullSubscription = () => {
        if(props.token){
            getSubscription(props.token).then(res => {
                console.log(res)
                if(res.length > 0){
                    res.map(r => {
                        console.log(r)
                        if(r.status === 'ACTIVE'){
                            setSubscription(r)
                            setGold(true)
                        }
                    })
                }
            })
        }
    }
    useEffect(()=>{!subscription && pullSubscription()}, [props.token])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    

    return(
        <div style={{paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', backgroundColor: 'black', minHeight: '100vh', paddingBottom: isMobile ? '7vh': 0}}>
            
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', }}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                        <span style={{}}>SimBull <span style={{color: '#c4b454'}}>GOLD</span></span>
                    </div>
                </div>
            : null}

            {/*Purchase Subscription */}
            {false && /*!gold &&*/ <div className="subtwo-box">
                <div className="subtwo-box-head">
                    GOLD
                    <span /*style={{textDecoration: 'line-through'}}*/><p className="subtwo-box-price">$4.16 per Month
                        <p style={{color: 'gray', fontSize: '.7rem', textDecoration: 'none'}}>$49.99 Billed Annually</p>
                    </p></span>
                    {/*<p className="subtwo-box-price"><span style={{color: 'green'}}>${(purchasePrice / 12).toFixed(2)} per Month
                        <p style={{color: 'gray', fontSize: '.7rem'}}>${purchasePrice} Billed Annually<br />Limited Time Offer</p>
                    </span></p>*/}
                </div>
                <div className="subtwo-box-img-div">
                    <img className="subtwo-box-img" src='https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Holder%2Ftickets.png?alt=media&token=22c7b60e-1530-40d2-aecf-3fc18bb28c9c' />
                </div>

                {true && <div style={{border: '1px solid black', padding: '3%'}}>
                    <div style={{fontSize: '1.2rem', fontWeight: '600'}}>SimBull GOLD</div>
                    <div style={{fontSize: '1rem', fontWeight: '200'}}>Unlimited Free Trading, Personalize Daily Report, Team Analyst Reports</div>
                    <div style={{fontSize: '1.1rem', fontWeight: '600', textAlign: 'center', padding: '3% '}}>
                        ${(4.16).toFixed(2)}/month
                        <p style={{color: 'gray', fontSize: '.7rem'}}>{/*$49.99*/} Billed Annually</p>
                    </div>                   
                    <GoldYearly />
                </div>}
                {/*false && !showTrial && user&& user.code && freeGoldSubscription.includes(user.code.toLowerCase()) && !user.goldTrial && <div className="subtwo-buy-btn-div"><button onClick={()=>setShowTrial(true)} className="subtwo-buy-btn" style={{backgroundColor: 'rgb(222,222,222)'}}>Free Week of GOLD</button></div>}
                {showTrial && <div style={{border: '1px solid black', padding: '3%'}}>
                    <div style={{fontSize: '1.2rem', fontWeight: '600'}}>SimBull GOLD</div>
                    <div style={{fontSize: '1rem', fontWeight: '200'}}>Unlimited Free Trading, Personalize Daily Report, Team Analyst Reports</div>
                    <div style={{fontSize: '1.1rem', fontWeight: '600', textAlign: 'center', padding: '3% '}}>Free</div>
                    <div className="subtwo-buy-btn-div"><button onClick={createTrialSubscription} className="subtwo-buy-btn" style={{width: '60%'}}>Start Free Week of GOLD</button></div>
                    <div style={{fontSize: '0.8rem'}}>**Will <strong>not</strong> renew automatically</div>
                </div>*/}
                <hr />
                <div className="subtwo-box-body">
                    <div className="subtwo-box-item">
                        1. Free Trading
                        <div className="subtwo-box-item-body">Be Ready to trade at a seconds notice. Skip the 1% trading fee and receive free trading on all your trades. </div>
                    </div>
                    <span style={{color: 'gray', fontSize: '.9rem'}}>Below Coming Soon!</span>
                    <div className="subtwo-box-item">
                        2. Personalized Daily Report
                        <div className="subtwo-box-item-body">Each morning, get a personalized daily report including an update on your account, win payouts, teams changes like price or new analyst reports, and any other thing you're interested in like a specific league's news or index prices.</div>
                    </div>
                    <div className="subtwo-box-item">
                        3. Team Analyst Reports
                        <div className="subtwo-box-item-body">Get in-depth reports and deep-dives for every team by analysts with a proven track record. </div>    
                    </div>
                </div>
            </div>}

            {/*Current Subscription */}
            {!gold && <div style={{paddingBottom: '0px', textAlign: 'center'}}>
                
                <p style={{color: 'rgb(100,100,100)', fontSize: '1.65rem', marginTop: '10px'}}>SimBull GOLD gives you free trading and so much more.</p>
                
                
                <div style={{border: '1px solid white', borderRadius: '1rem', padding: '20px', textAlign: 'center', margin: '20px', width: isMobile ? '90%':'60%', marginLeft: isMobile ? '5%' : '20%'}}>
                <p style={{color: 'white', fontSize: '1.3rem', fontWeight: '600' }}>Buy now for less than $5 per month</p>
                    <div style={{width: isMobile ? '80%':'65%', textAlign:'left', marginLeft: '0%'}}><GoldYearly /></div>
                    <p style={{textAlign: 'center', color: 'gray', fontWeight: '200'}}>$50 Charged Annually</p>
                </div>

                <div style={{color: 'white', textAlign: 'left', width: isMobile ? '80%':'50%', marginLeft: isMobile ? '10%':'25%',}}>
                    <div style={{ verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <AttachMoneyIcon style={{fontSize: '3rem'}}  />    
                        </div>
                        
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            No-fee trading: <span style={{fontWeight: '200'}}>Skip the 1.5% trading fee on all trades.</span>
                        </div>
                    </div>
                    <div style={{ verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <LibraryBooksOutlinedIcon style={{fontSize: '3rem'}} />
                        </div>
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            Premium Data: <span style={{fontWeight: '200'}}>View all the latest trades and the full order book.</span>
                        </div>
                    </div>

                    <div style={{verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <CampaignOutlinedIcon style={{fontSize: '3rem'}}  />
                        </div>
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            Early Access: <span style={{fontWeight: '200'}}>Be the first to know and try out new features.</span>
                        </div>
                    </div>
                </div>

                
                
            </div>}

            {/*Current Subscription */}
            {gold && <div style={{paddingBottom: '0px', textAlign: 'center'}}>
                
                <p style={{color: 'rgb(100,100,100)', fontSize: '1.65rem', marginTop: '10px'}}>SimBull GOLD gives you free trading and so much more.</p>
                <div style={{width: isMobile ? '80%':'50%', textAlign: 'center', margin: '30px', marginLeft: isMobile ? '10%':'25%', marginRight: isMobile ? '10%':'25%', border: '1px white solid', borderRadius: '1rem', padding: '24px'}}>
                    {subscription && <p style={{color: '#c4b454', fontWeight: '700', fontSize: '1.3rem'}}>Member since {subscription.timestamp.substring(5,7)}/{subscription.timestamp.substring(8,10)}/{subscription.timestamp.substring(0,4)}</p>}
                    {subscription && subscription.subscription_id === null && <p style={{color: 'gray', fontSize: '1rem', }}>Expires on {subscription.expiration_date.substring(5,7)}/{subscription.expiration_date.substring(8,10)}/{subscription.expiration_date.substring(0,4)}</p>}
                    {subscription && subscription.subscription_id !== null && <p style={{color: 'gray', fontSize: '1rem', marginTop: '-15px'}}>Rewnews on {subscription.expiration_date.substring(5,7)}/{subscription.expiration_date.substring(8,10)}/{subscription.expiration_date.substring(0,4)}</p>}
                </div>

                <div style={{color: 'white', textAlign: 'left', width: isMobile ? '80%':'50%', marginLeft: isMobile ? '10%':'25%',}}>
                    <div style={{ verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <AttachMoneyIcon style={{fontSize: '3rem'}}  />    
                        </div>
                        
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            No-fee trading: <span style={{fontWeight: '200'}}>Skip the 1.5% trading fee on all trades.</span>
                        </div>
                    </div>
                    <div style={{ verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <LibraryBooksOutlinedIcon style={{fontSize: '3rem'}} />
                        </div>
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            Premium Data: <span style={{fontWeight: '200'}}>View all the latest trades and the full order book.</span>
                        </div>
                    </div>

                    <div style={{verticalAlign: 'bottom', marginBottom: '16px'}}>
                        <div style={{display: 'inline-block', width: '30%',  textAlign: 'center', verticalAlign: 'top', marginTop: '16px'}}>
                            <CampaignOutlinedIcon style={{fontSize: '3rem'}}  />
                        </div>
                        <div style={{display: 'inline-block', width: '70%', fontSize: '1.2rem',  marginTop: '12px', verticalAlign: 'top', fontWeight: '600'}}>
                            Early Access: <span style={{fontWeight: '200'}}>Be the first to know and try out new features.</span>
                        </div>
                    </div>
                </div>

                
                
            </div>}

            {/* Past Receipts 
            {gold && <>
                <div className="subtwo-section">Receipts</div>
                <div className="subtwo-box">
                    {!showReceipts && <div className="subtwo-receipt-view"><button onClick={()=>setShowReceipts(true)} className="subtwo-receipt-view-btn">View All</button></div>}
                    {showReceipts && <div className="subtwo-receipt-view"><button onClick={()=>setShowReceipts(false)} className="subtwo-receipt-view-btn">Hide</button></div>}
                    {showReceipts && <table className="subtwo-receipt-table">
                        <tr className="subtwo-receipt-table-headers">
                            <th>Type<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Time<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Date<hr className="subtwo-receipt-head-hr" /></th>
                            <th>Cost<hr className="subtwo-receipt-head-hr" /></th>
                        </tr>
                        
                        {receipts && receipts.length>0 ? receipts.map(r => {
                            return(
                                <tr>
                                    <td>GOLD<hr className="subtwo-receipt-hr" /></td>
                                    <td>{r.type}<hr className="subtwo-receipt-hr" /></td>
                                    <td>{r.date}<hr className="subtwo-receipt-hr" /></td>
                                    <td>${r.price}<hr className="subtwo-receipt-hr" /></td>
                                </tr>
                            )
                        }): <div>No Receipts</div>}
                    </table>}
                </div>
            </>}
            */}

            {/*Cancel Subscription */}
            {gold && subscription.subscription_id && subscription.subscription_id !== null && <>
                <div style={{backgroundColor: 'black', color: 'white'}}>
                    {!showCancel && <div className="subtwo-receipt-view"><button onClick={()=>setShowCancel(true)} style={{backgroundColor: '#212121', color: 'gray', borderRadius: '2rem', padding: '12px', border: 'none', outline: 'none', width: '40%', marginBottom: '40px', marginTop: '32px'}}>Cancel GOLD</button></div>}
                    {showCancel && <div className="subtwo-receipt-view"><button onClick={()=>setShowCancel(false)} style={{textAlign:'center', backgroundColor: 'black', border: 'none', outline: 'none', color: 'gray'}}>Hide</button></div>}
                    {showCancel && <div className="subtwo-receipt-table">
                        <div style={{fontWeight: '600', fontSize: '1.25rem'}}>Are you sure?</div>

                        <div><button onClick={()=>{return alert('Error cancelling subscription. Please contact customer-service@simbull.app to cancel.')}} className="subtwo-cancel-btn">Cancel GOLD</button></div>
                    </div>}
                </div>
            </>}
            

        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(withRouter(GOLD))