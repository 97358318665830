import axios from 'axios'
import {route} from '../route'

/*1. Follow  Teammate */
export const follow_teammate = async (token, username) => {
    const teammateOptions = {
        method: 'POST',
        url: `${route}/teammates/follow/${username}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            
        }
    }
    let follow 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        follow = results
    }).catch(err => console.log(err))
    return follow
}

/*2. Unfollow Teammate  */
export const unfollow_teammate = async (token, username) => {
    const teammateOptions = {
        method: 'DELETE',
        url: `${route}/teammates/follow/${username}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            
        }
    }
    let follow 
    await axios.request(teammateOptions).then(function (response) {
        console.log(response)
        let results = response.data
        follow = results
    }).catch(err => console.log(err))
    return follow
}

/*3. Search for a Teammate  */
export const search_teammate = async (username) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/search`,
        headers: {
            
        },
        params: {
            "query": username
        }
    }
    let search 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        search = results
        console.log(response)
    }).catch(err => console.log(err))
    return search
}
