import React from 'react'
import './newsletter.css'
import { addToNewsletter } from '../../../routes/user/user'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import amplitude from 'amplitude-js'

function Newsletter() {

    const history = useHistory()

    const [email, setEmail] = useState('')
    const [accepted, setAccepted] = useState(false)

    const validate = (email) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            return false;
        }
        return true;
    }

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {
        if (validate(email)){
            await addToNewsletter(email)
            setEmail('')
            setAccepted(true)
            amplitude.getInstance().logEvent('newsletter_signup')
        }
    }

    


  return (
    <>
    
    <div className="news-container">
        
        <div className="news-box">
        
            <div className="news-left">
                <div className="news-title">
                    <h1 className="news-head">Want to learn more?</h1>
                </div>
                <div className="news-main">
                    <h1 className="news-body">Subscribe to our newsletter for more information about SimBull.</h1>
                </div>
            </div>
            <div className="news-right">
                <div className="input-box">
                   <input onChange={handleChange} value={email} className="news-input"  type="text" placeholder="Enter your email address" />
                   {accepted ? <div style={{textAlign: 'center', color: 'green',}}>Subscribed!</div> :<button onClick={() => handleSubmit()} className="news-button">Subscribe</button>}
                </div>
                
                
            </div>
            
            
        </div>
        
    </div>
    </>
  )
}

export default Newsletter