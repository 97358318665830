import React, {useState, useEffect} from 'react'
import '../Portfolio3/portfolio.css'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import moment from 'moment'
import { connect } from 'react-redux'
import WithdrawReceipt from './WithdrawReceipt'

const WithdrawConfirm = (props) => {
    //props
    const user = props.user

    //states
    const amount = useState(parseFloat(props.amount).toFixed(2))
    const fee = useState((parseFloat(props.amount) * 0.03).toFixed(2))
    const withR = useState((parseFloat(props.amount) * 0.97).toFixed(2))
    const paypalEmail = useState(props.paypalEmail)
    const [showConfirm, setShowConfirm] = useState(false)
    const [show, setShow] = useState(false)

    //Functionality
    const completeWithdraw = () => {
        let today = moment().format('YYYYMMDD')
        let newAmount = parseFloat(amount)
        let rand = Math.random() * 1000000000
        let email = user.email
        let payoutAmt = props.amount * 0.97
        let payEmail = `${paypalEmail}`
        db.collection('PayoutRequests').doc(`${today}-Cash-${email}-${rand}`).set({
            user: user.email,
            amount: props.amount,
            payoutAmount: payoutAmt,
            paypalEmail: payEmail,
            text: props.text,
            type: 'cash',
            completed: false,
            date: today,
            userCode: user.code,
            userObj: user
        }).then(res => {
            setShow(true)
            //props.closeConfirm()
            // alert(`You will receive your cash ($${amount}) through Paypal to ${paypalEmail} within 24 hours once you have responded to our email confirming your email.`)
        }).catch(err => { 
            console.log(err.message)
            alert('Sorry an error occurred. Please try again or make withdrawal request to customer-service@simbull.app.')
        })
    }


    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <div className="CollectPayouts2">
            <div className="cp-close"><i className="fa fa-times-circle" onClick={props.hide}></i></div>
            <div className="cp-title">Withdrawal Confirmation</div>
            {/* <div className="cp-title-num">( {amount} )</div> */}
            <div className="cp-card-holder">
                {/*<div className="cp-card-total" style={{fontWeight: "normal"}}>
                    You will receive your cash (<strong>${amount}</strong>) through Paypal to <strong>{paypalEmail}</strong> within 24 hours once you have responded to our email confirming your email.
                </div>
                <hr className="cp-card-total-hr" />*/}
                <div className="cp-card-total">
                    <div className='cp-card-total-left'>Requested Amount</div>
                    <div className='cp-card-total-right'>${amount}</div>
                    <div className='cp-card-total-left'>Processing Fee (3%)</div>
                    <div className='cp-card-total-right'>-${fee}</div>
                    <hr className="cp-card-total-hr" />
                    <div className='cp-card-total-left'>Withdrawal Received</div>
                    <div className='cp-card-total-right'>${withR}</div>
                </div>
            </div>
            <div className="align-bottom">
                {/* <div className="cp-question-div">
                    <div className="cp-question">
                        How did you hear about SimBull 
                    </div>
                    <div className="cp-answers">
                        <input className="cp-answers-input" type="checkbox" id="watch" name="watch" value="watch" />
                        <label className="cp-answers-txt" for="watch"> Twitter</label><br />
                        <input className="cp-answers-input" type="checkbox" id="follow" name="follow" value="follow" />
                        <label className="cp-answers-txt" for="follow"> Tik Tok</label><br />
                        <input className="cp-answers-input" type="checkbox" id="checked" name="checked" value="checked" />
                        <label className="cp-answers-txt" for="checked"> Friend</label><br />
                        <input className="cp-answers-input" type="checkbox" id="nope" name="nope" value="nope" />
                        <label className="cp-answers-txt" for="nope">Other</label><br />
                    </div>
                </div> */}
                <div className="cp-card-btn-div"><button onClick={completeWithdraw} className="cp-card-btn-2">Complete</button></div>
            </div>
            {show && <WithdrawReceipt amount={amount} text={props.text} hide={props.hide}/>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps)(WithdrawConfirm)