import React, {useEffect, useState} from 'react'
import '../Account2/Account.css'
import Header from '../Account2/Header'
import {Link, withRouter} from 'react-router-dom'
import ReactPayPal from '../Account2/PayPal/ReactPayPal'
import PsWeekly25 from '../Account2/PayPal/PS-Weekly25'
import PsWeekly10 from '../Account2/PayPal/PS-Weekly10'
import PsMonthly250 from '../Account2/PayPal/PS-Monthly250'
import PsMonthly100 from '../Account2/PayPal/PS-Monthly100'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import {db, firebaseAnalytics} from '../../services/firebase'
import moment from 'moment'
import { Button } from 'react-bootstrap'

const Deposit = (props) => {
    //props
    const user = props.user

    //states
    const [amount, setAmount] = useState(250)
    const [promo, setPromo] = useState('')
    const [checkout, setCheckout] = useState(false)
    const [reoccurringDeposit, setReoccurringDeposit] = useState(false)

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
        if(user && user.email){
            firebaseAnalytics.logEvent("portfolioDeposit_visit", {
                user: user.email
            })
        }else{
            firebaseAnalytics.logEvent("portfolioDeposit_visit")
        }
    }, [])

    const updateAmount = (value) => {
        value = value.replace(/,/g, "");
        value = value.replace("$", "");
        setAmount(value)
    }

    const showCheckout = () => {
        let numType = parseFloat(amount)
        if(numType < 10 || numType === undefined){
            alert('$10 minimum')
        }else if(numType > 5000){
            alert(`Maximum Deposit Exceed`)
        }else if(isNaN(numType)){
            alert('Try a real number')
        }else{setCheckout(true)}
    }

    return(
        <>
        {checkout === true ? (
              <div className="payment-div DesktopContainer" style={{backgroundColor: 'white', minHeight: '90vh'}}>
                <ReactPayPal portfolio={true} promo={promo} amount={amount} />
              </div>
            ) : (
                    <>
                        <div className="Transfers DesktopContainer" style={{paddingTop: '0vh', backgroundColor: 'white'}}>
                            <div className='transfer-deposit' style={{minHeight: '100vh'}}>
                                {!checkout && <div className='transfer-deposit-body'>
                                    <div>
                                        <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px', paddingTop: '6vh'}}>Choose an Amount</div>
                                        <div style={{marginLeft: '10%', marginRight: '10%'}}>
                                            <button onClick={()=>setAmount(100)} className={amount === 100 ? "deposit-btn-gold" : "deposit-btn"}>$100</button><button onClick={()=>setAmount(250)} className={amount === 250 ? "deposit-btn-gold" : "deposit-btn"}>$250</button><br />
                                            <button onClick={()=>setAmount(500)} className={amount === 500 ? "deposit-btn-gold" : "deposit-btn"}>$500</button><button onClick={()=>setAmount(1000)} className={amount === 1000 ? "deposit-btn-gold" : "deposit-btn"}>$1000</button>
                                        </div>
                                    </div>
                                    <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Other Amount: </div><input className="deposit-input"  placeholder='$' onChange={(e)=>{setAmount(e.target.value)}} value={amount} />
                                    <br />
                                    <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Promo: </div><input className="deposit-input" onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                    <br />
                                    <Button variant="secondary" style={{margin: 10, width: "40%", marginBottom: '1%', display: 'inline-block', backgroundColor: 'green', fontSize: '1.5rem'}} onClick={showCheckout}>Deposit</Button>
                                </div>}
                                {/*<div className='transfer-deposit-body'>
                                    Amount:<br /><input className="transfer-deposit-input" onChange={(e)=>{updateAmount(e.target.value)}} value={amount} placeholder='$' />
                                    <br />
                                    Promo:<br /> <input className="transfer-promo-input" onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                    <br />
                                    <button className="transfer-depsoit-btn" onClick={showCheckout}>Continue to Deposit</button>
                                </div>*/}
                                <div style={{textAlign: 'center'}}><button onClick={props.closeBP} style={{border: 'none', outline: 'none', textDecoration: 'underline', textAlign: 'center', marginTop: '15px', color: 'gray', backgroundColor: 'white'}}>close</button></div>
                            </div>

                            

                        </div>
                       
                    </>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(Deposit))