import React, {useState, useEffect} from 'react'
import '../Portfolio3/portfolio.css'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'

const WithdrawReceipt = (props) => {
    //props
    const user = props.user

    const history = useHistory()

    //states
    const amount = useState(props.amount)
    const fee = useState((parseFloat(props.amount) * 0.03).toFixed(2))
    const withR = useState((parseFloat(props.amount) * 0.97).toFixed(2))
    const [paypalEmail, setPaypalEmail] = useState(props.paypalEmail ? props.paypalEmail : user.email ? user.email : '')

    useEffect(()=>{
        setPaypalEmail(props.paypalEmail)
    }, [props.paypalEmail])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <div className="CollectPayouts2">
            <div className="cp-close"><i className="fa fa-times-circle" onClick={props.hide}></i></div>
            <div className="cp-title">Withdrawal Receipt</div>
            {/* <div className="cp-title-num">( {amount} )</div> */}
            <div className="cp-card-holder">
                <div className="cp-card-total" style={{fontWeight: "normal"}}>
                    You will receive your cash (<strong>${withR}</strong>) through Paypal to <strong>{paypalEmail}</strong> within one business day.
                </div>
                <hr className="cp-card-total-hr" />
                <div className="cp-card-total">
                    <div className='cp-card-total-left'>Amount</div>
                    <div className='cp-card-total-right'>${withR}</div>
                </div>
            </div>
            <div className="align-bottom">
                {/* <div className="cp-question-div">
                    <div className="cp-question">
                        How did you hear about SimBull 
                    </div>
                    <div className="cp-answers">
                        <input className="cp-answers-input" type="checkbox" id="watch" name="watch" value="watch" />
                        <label className="cp-answers-txt" for="watch"> Twitter</label><br />
                        <input className="cp-answers-input" type="checkbox" id="follow" name="follow" value="follow" />
                        <label className="cp-answers-txt" for="follow"> Tik Tok</label><br />
                        <input className="cp-answers-input" type="checkbox" id="checked" name="checked" value="checked" />
                        <label className="cp-answers-txt" for="checked"> Friend</label><br />
                        <input className="cp-answers-input" type="checkbox" id="nope" name="nope" value="nope" />
                        <label className="cp-answers-txt" for="nope">Other</label><br />
                    </div>
                </div> */}
                <div className="cp-card-btn-div"><button onClick={()=>{return history.replace('/')}} className="cp-card-btn">Done</button></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps)(WithdrawReceipt)