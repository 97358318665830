import React, {useState, useEffect} from 'react';
import {Pie} from 'react-chartjs-2';
import { connect } from 'react-redux';
import '../portfolio2.css'
import 'chartjs-plugin-datalabels';


const PieChart = (props) => {
  const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])
  const user = props.user
  const [data, setData] = useState([0,0,0,0, 0, 0])
  const [chartData, setChartData] = useState()
  const [total, setTotal] = useState([0,0,0,0, 0, 0])
  const [shares, setShares] = useState([0,0,0,0, 0, 0])

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(()=>{
    if(teams.length === 0 && props.teamsValue && props.teamsValue.length){
      setTeams(props.teamsValue)
    }
  },[props.teamsValue])

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  

  const sumAndPercentOfLeagues = () => {
    let mlbTotal = 0, mlbMoney = 0, nbaTotal = 0, nbaMoney = 0, nflTotal = 0, nflMoney = 0, cfbTotal = 0, cfbMoney = 0, nhlTotal = 0, nhlMoney = 0;
    teams.map(t => {
      props.equity && props.equity.length > 0 && props.equity.map(e => {
        if(e.asset_id === t.team.id && e.amount > 0){
            if(t.team.league === 'MLB'){
                mlbTotal = mlbTotal + e.amount
                mlbMoney = mlbMoney + (t.asking_price * e.amount)
            }
            else if(t.team.league === 'NBA'){
                nbaTotal = nbaTotal + e.amount
                nbaMoney = nbaMoney + (t.asking_price * e.amount)
            }
            else if(t.team.league === 'NFL'){
                nflTotal = nflTotal + e.amount
                nflMoney = nflMoney + (t.asking_price * e.amount)
            }
            else if(t.team.league === 'CFB'){
                cfbTotal = cfbTotal + e.amount
                cfbMoney = cfbMoney + (t.asking_price * e.amount)
            }
            else if(t.team.league === 'NHL'){
              nhlTotal = nhlTotal + e.amount
              nhlMoney = nhlMoney + (t.asking_price * e.amount)
          }
        }
      })
    })
    const total = mlbMoney + nflMoney + nbaMoney + cfbMoney + nhlMoney + (props.totals ? props.totals.cash_balance : 0)
    const mlbPercent = (mlbMoney / total) * 100
    const nbaPercent = (nbaMoney / total) * 100
    const nflPercent = (nflMoney / total) * 100
    const cfbPercent = (cfbMoney / total) * 100
    const nhlPercent = (nhlMoney / total) * 100
    const cashPercent = ((props.totals ? props.totals.cash_balance : 0) / total) * 100
    setTotal([nflMoney.toFixed(2), nbaMoney.toFixed(2), mlbMoney.toFixed(2), cfbMoney.toFixed(2), nhlMoney.toFixed(2), (props.totals ? props.totals.cash_balance : 0).toFixed(2)])
    setShares([nflTotal.toFixed(2), nbaTotal.toFixed(2), mlbTotal.toFixed(2), cfbTotal.toFixed(2), nhlTotal.toFixed(2), 0])
    setData([parseFloat(nflPercent).toFixed(2), parseFloat(nbaPercent).toFixed(2), parseFloat(mlbPercent).toFixed(2), parseFloat(cfbPercent).toFixed(2), parseFloat(nhlPercent).toFixed(2), parseFloat(cashPercent.toFixed(2))])
    chart()
  }
 
  useEffect(() => {
    if(teams.length !== 0 && props.equity.length !== 0 && props.totals && props.totals.cash_balance){
      sumAndPercentOfLeagues()
    }
  }, [props, teams])

  const chartColors = ["black", "grey", "#c5b358", "rgb(240,240,240)", "orange", "#85bb65"]
  const labelsChart = ["SimNFL", "SimNBA", "SimMLB", "SimCFB", "SimNHL", "Cash"]

  const chart = () => {
    setChartData({
      maintainAspectRatio: false,
      responsive: false,
      labels: labelsChart,
      datasets: [
            {
                data,
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors
            }
        ]
    })
  }
  useEffect(()=>{chart()}, [data, total, shares])

  const newData = (data, index) => {
    return `$${total[index]} - ${data}%`
  }

  const newTitle = (data, index) => {
    return `${data}${data === 'Cash' ? '' : ` - ${shares[index]} shares`}`
  }
  
  return (
    <div>
      {chartData && 
      <Pie
        data={chartData}
        options={{
            legend: { display: true, position: "bottom" },
            plugins: {
              datalabels: {
                display: false,
                formatter: function(value, context) {
                  return labelsChart[context.dataIndex] + ": " + value + '%';
                },
                color: "white",
              }
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  let value = data.datasets[0].data[tooltipItem.index] 
                  return newData(value, tooltipItem.index)
                },
                title: function(tooltipItem, data) {
                  let value = data.labels[tooltipItem[0].index]
                  return newTitle(value, tooltipItem[0].index)
                },
              },
              backgroundColor: "#5a6e7f",
            },
          }}
      />}
    </div>
  )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
    totals: state.totals.totals
});

export default connect(mapStateToProps)(PieChart)