import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../portfolio2.css'
import moment from 'moment'
import {getPayouts} from '../../../routes/user/user'

const WinPayouts = (props) => {
    //get props
    const [payouts, setPayouts] = useState([])
    const [ran, setRan] = useState(false)

    const getMyPayouts = () => {
        if(props.location && props.location.state && props.location.state.payouts && !ran){
            setPayouts(props.location.state.payouts)
            setRan(true)
        }
        else{
            if(props.token && !ran){
                setRan(true)
                getPayouts(props.token).then(res => {
                    setPayouts(res)
                })
            }
        }
    }
    useEffect(()=>{ 
        getMyPayouts()
    },[props])
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const getTeamName = (id) => {
        let team = ''
        if(props.teamsValue){
            props.teamsValue.map(t => {
                if(t.team.id === id){
                    team = t.team.name
                }
            })
        }
        return team
    }

    payouts.sort(function(a, b) {
        if(b.timestamp < a.timestamp){
            return -1
        }
        else if(b.timestamp > a.timestamp){
            return 1
        }
        else return 0
    });

    return(
        <div className="DesktopContainer" style={{ margin: '0', marginTop: '7vh', paddingBottom: '10vh'}}>
            <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '73%', paddingLeft: '5px'}}>Win Payouts</div> <div style={{width: '20%',textAlign: 'right', display: 'inline-block', paddingLeft: '5px'}}>{/*<span className="fa fa-ellipsis-v"></span>*/}</div></div>
            <hr style={{marginTop: '3px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
            {payouts && payouts.length > 0 && payouts.map( p => {
                return(
                    <div style={{marginLeft: '3%', marginRight: '3%'}}>
                        <div style={{display: 'inline-block', width: '57%', paddingLeft: '2%', fontSize: '1.3em', verticalAlign: 'top'}}>
                            <div style={{fontSize: '1.1em'}}>Sim{getTeamName(p.asset_id)} </div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{`${p.timestamp.substring(5, 7)}/${p.timestamp.substring(8, 10)}/${p.timestamp.substring(0, 4)}`}</div>
                        </div>
                        <div style={{display: 'inline-block', width: '36%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'bottom'}}>
                            <div style={{fontSize: '1.5em', color: 'green'}}>+${(p.reward).toFixed(2)}</div>
                            
                        </div>
                        <hr />

                        
                        
                    </div>
                )
            })}


        </div>
    )
}

const mapStateToProps = (state) => ({
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
});

export default connect(mapStateToProps)(WinPayouts)