import React, {useEffect, useState} from 'react'
import './welcome.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TeamCard from '../Holdings/TeamCard';
import {firebaseAnalytics} from '../../services/firebase'

const Purchase = (props) => {

    const user = props.user
    const teams = props.teams

    const [newTeam, setNewTeam] = useState(false)

    //Analytics
    useEffect(()=>{
        firebaseAnalytics.logEvent("welcomePurchase_visit", {
           //user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    return(
        <>
        {user.cash > 1 && <div>Now, put that cash to work and buy a share of your favorite team by clicking the buy button!<br/><br /></div>}
        {user.cash === 0 && <div>This is a team card, where you can find all the info about this team. Go back a page to deposit so you can make your first purchase!</div>}
        {user.cash === 1 && <div>This is a team card, where you can find all the info about this team. Go back a page to deposit so you can make your first purchase!</div>}
        {!newTeam && <div style={{textAlign: 'left'}}>
            {teams.map(t => {
                if(t.team === '49ers'){
                    return <TeamCard key={t.team} t={t} welcome={true} />
                }
            })}
        </div>
        }
        {newTeam && <div style={{textAlign: 'left'}}>
            {newTeam && teams.map(t => {
                if(newTeam === t.team ){
                    return <TeamCard key={t.team} t={t} welcome={true} />
                }
            })} 
        </div>
        }
        <div><hr />
            Choose different team: <br />
            <select value={newTeam} onChange={(e)=>{setNewTeam(e.target.value)}}>
                        {teams.map(t=> {
                            return <option value={t.team} key={t.team}>Sim{t.team}</option>
                        })}
            </select>
            <hr />
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(Purchase))