import axios from 'axios'
import {route} from '../route'

//1. Get Subscription 
//2. Create Subscription 

//1. Get Subscription 
export const getSubscription = async (token) => {
 const subscriptionOptions = {
        method: 'GET',
        url: `${route}/@/subscriptions`,
        params: {},
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let subscription 
    await axios.request(subscriptionOptions).then(function (response) {
        let result = response.data
        subscription = result
    }).catch(err => {subscription = err.response})
    return subscription
}

//2. Create Subscription 
export const createSubscription = async (token, id) => {
 const subscriptionOptions = {
        method: 'POST',
        url: `${route}/@/subscriptions`,
        params: {
            "subscription_id": id
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let subscription 
    await axios.request(subscriptionOptions).then(function (response) {
        let result = response.data
        subscription = result
    }).catch(err => {subscription = err.response})
    return subscription
}