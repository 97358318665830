import React, {useState,useEffect} from 'react'
import './Analysis.css'
import ReactHtmlParser from 'react-html-parser'
import {db, firebaseAnalytics} from '../../services/firebase'
import ReactPixel from 'react-facebook-pixel'
import firebase from 'firebase'

const Article = (props) => {

    let unloaded = {
        author: 'Loading...',
        body: "<p>Loading...</p>",
        date: "20200101",
        title: "Loading...",
        type: "Article",
        url: "loading"
    }
    let notFound = {
        author: '',
        body: "<p>Whoops, it looks like this link isn't good anymore. It could be a typo or we may have removed the article.</p>",
        date: "20200101",
        title: "Article Not Found",
        type: "Aricle",
        url: "notFound"
    }

    //props
    const [article, setArticle] = useState(props.location.state ? props.location.state.article : unloaded)
    const [view, setView] = useState(true)
    

    //Analytics
    useEffect(()=>{firebaseAnalytics.logEvent("article_visit")},[])
    useEffect(()=>{ReactPixel.track('View', {user_role: 'member'})}, [])

    //Getting either the link or the clicked article (I think clicked article)
    useEffect(()=>{
        if(props.location.state){
            setArticle(props.location.state.article)
            if(view){
                db.collection('Analysis').doc(`${props.location.state.article.date}-${props.location.state.article.author}`).update({
                    views: firebase.firestore.FieldValue.increment(1)
                }).catch(err => console.log(err))
                setView(false)
            }
        }
    },[props])
    //Getting either the link or the clicked article
    useEffect(()=>{
        if(props.match.params.id && !props.location.state){
            
            db.collection('Analysis').where('url', '==', props.match.params.id).get().then(snapshot => {
                snapshot.forEach(doc => {
                    setArticle(doc.data())
                    if(view){
                        db.collection('Analysis').doc(doc.id).update({
                            views: firebase.firestore.FieldValue.increment(1)
                        }).catch(err => console.log(err))
                    }
                })
            }).catch(err => { console.log(err) })
        }
    },[])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <div className="Article DesktopContainer">
            {article.title && <>
                <div className="anal-title-2 blk-text">{article.title}</div>
                <div className="anal-sub blk-text">{article.type} - By: {article.author} - {`${article.date.substring(4, 6)}/${article.date.substring(6, 8)}/${article.date.substring(0, 4)}`}</div>
                <br />
                <div>{ReactHtmlParser(article.body)}</div>
            </>}
        </div>
    )
}

export default Article