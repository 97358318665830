import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Modal_ from './Modal'
import amplitude from 'amplitude-js'

const TeamCard = (props) => {

    const [show, setShow] = useState(false)
    const doHide = () => setShow(false)

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        <div style={{width: '100%', padding: '20px', display: 'flex', color: 'white', justifyContent: 'space-between'}}>
            <div style={{width: isMobile ? '70%':'80%',}} onClick={() => amplitude.getInstance().logEvent(
                "learn", {
                   "question": props.item.question
                }
            )}>
                <div>{props.item.question}</div>
                <div style={{color: 'rgb(80,80,80)', fontSize: '.8rem'}}>{props.item.shortAnswer}</div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', fontSize: '.8rem', color: '#c4b454', width: isMobile ? '30%':'20%', }}> 
                <button onClick={()=>setShow(true)} style={{border: 'none', outline: 'none', backgroundColor: 'black', color: '#c4b454'}}>read more <ChevronRightIcon/> </button>
            </div>
        </div>
        {show && <Modal_ show={show} hide={doHide} item={props.item} />}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(TeamCard)