import * as types from "../../types";
import {getAccountInfo} from '../../../routes/user/account'
import {getUser} from '../user'

export const getInfo = (token) => (dispatch) => {

  getAccountInfo(token).then(res => {
    dispatch(getUser(null,null,res.email))
    dispatch({
      type: types.GET_INFO,
      payload: res
    })
  })

};
