import React, {useState, useEffect} from 'react'
import './Analysis.css'
import moment from 'moment'
import { connect } from 'react-redux'
import {getGamesForAnalysis} from '../../routes/games/games'
import Loader from '../adHoc/Loader'

const Games = (props) => {
    //props
    const user = props.user

    const [games, setGames] = useState([])

    //Games States
    const [showGames, setShowGames] = useState('today')

    const [tomorrow, setTomorrow] = useState(moment().subtract(-1, 'd').format('YYYY-MM-DD'))
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'))
    const [yesterday, setYesterday] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD'))
    
    const amountOfShares = (team_id) => {
        let count = ''
        if(props.equity){
            props.equity.map(e => {
                if(e.asset_id === team_id && e.amount !== 0){
                    count = e.amount
                }
            })
        }
        if(count !== ''){
            return <span style={{fontWeight: '200', fontSize: '.9rem'}}>({count.toFixed(0)})</span>
        }
        else return ''
        
    }

    const getTime = (time) => {
        let newTime
        
        if(parseInt(time.substring(0,2)) > 6){
            
            let numTime = time.substring(0,2)
            numTime = parseFloat(numTime) - 6
            newTime = `${numTime}:${time.substring(3,5)}`
            /*if(newTime.length > 5){
                newTime = newTime.substring(1, newTime.length)
            }*/
        }
        else if(time.substring(0,2) === '00'){
            newTime = `18:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '01'){
            newTime = `19:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '02'){
            newTime = `20:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '03'){
            newTime = `21:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '04'){
            newTime = `22:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '05'){
            newTime = `23:${time.substring(3,5)}`
        }
        else if(time.substring(0,2) === '06'){
            newTime = `00:${time.substring(3,5)}`
        }
        return newTime
    }

    const getTeamName = (id) => {
        let team = ''
        if(props.teamsValue){
            props.teamsValue.map(t => {
                if(t.team.id === id){
                    team = t.team.abbr ? t.team.abbr : t.team.name
                }
            })
        }
        return team
    }

    const [noGames, setNoGames] = useState(true)
    useEffect(()=>{
        //getGamesForAnalysis('2022-01-26', '2022-01-26').then(res => {setGames(res); console.log(res)})
        getGamesForAnalysis(yesterday, tomorrow).then(res => {setGames(res)})
        setTimeout(() => {
            setNoGames(false)
        }, 3000);
    },[])

    const getGamesDate = (g) => {
        let dates = g.timestamp.substring(0,10)
        let times = g.timestamp.substring(g.timestamp.length -8, g.timestamp.length-6)
        let morning = ['00','01','02','03','04','05', '06']
        if(dates === yesterday){
            if(morning.includes(times)){
                //do nothing
            }
            else return('yesterday')
        }
        else if(dates === today){
            if(morning.includes(times)){
                return('yesterday') // actually yesterdays late games
            }
            else return('today') //todays afternoon evening
        }
        else if(dates === tomorrow){
            if(morning.includes(times)){
                return('today') // actually today late games
            }
            else return('tomorrow') //tomorrows afternoon evening
        }else return('none')
    }

    const displayTime = (g) => {
        let times = g.timestamp.substring(g.timestamp.length -8, g.timestamp.length-3)
        return getTime(times)
    }

    let leagues = ['NFL', 'NBA', 'MLB', 'CFB', 'NHL']

    return(
        <>
            {/* Games */}
            <div>
                <button onClick={()=>setShowGames('today')} className={showGames === 'today' ? "TA-Game-Title TAGT-on" : "TA-Game-Title TAGT-off"} style={{display: 'inline-block'}}>Today's Games<br />
                {showGames === 'today' && <hr className="TA-Game-hr" />}
                </button>
                <button onClick={()=>setShowGames('yesterday')} className={showGames === 'yesterday' ? "TA-Game-Title TAGT-on" : "TA-Game-Title TAGT-off"} style={{display: 'inline-block'}}>Yesterday's Games<br />
                {showGames === 'yesterday' && <hr className="TA-Game-hr" />}
                </button>
                
                    {(games && games.length > 0) ? leagues.map(l =>{

                        return(
                            <div key={l} className="TA-Games">
                                {games.map(g => {

                                    let time = ''
                                    let status = (g.away_score !== 0 || g.home_score !== 0) ? (g.status === 'FINAL' ? g.status : 'Playing') : g.status === 'SCHEDULED' ? displayTime(g) : g.status

                                    if(l === g.league && (showGames === 'today' ? (getGamesDate(g) === 'today') : (getGamesDate(g) === 'yesterday'))){
                                        return(
                                            <div key={g.id} className="TA-GameCard">
                                                <div className="TA-GC-Innings">{status}</div>
                                                <div className="TA-GC-Team">
                                                    <div className="TA-GC-TeamName">{amountOfShares(g.away_team_id)}{' '}{getTeamName(g.away_team_id)}</div>
                                                    <div className="TA-GC-TeamScore">{(g.away_score !== 0 || g.home_score !== 0) ? g.away_score : ''}</div>
                                                </div>
                                                <div className="TA-GC-Team">
                                                    <div className="TA-GC-TeamName">{amountOfShares(g.home_team_id)}{' '}{getTeamName(g.home_team_id)}</div>
                                                    <div className="TA-GC-TeamScore">{(g.away_score !== 0 || g.home_score !== 0) ? g.home_score : ''}</div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })}
                            </div>
                        )                        
                    }) : <>
                        {noGames && <>
                            <br />
                            <div className="TA-GameCard">
                                <Loader />
                            </div>
                            <br />
                            <div className="TA-GameCard">
                                <Loader />
                            </div>
                        </>}
                        </>
                    }

                <hr className="TA-Game-hr" style={{width: '90%', marginTop: '1%'}} />
            </div>

            
       </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity
});

export default connect(mapStateToProps)(Games)