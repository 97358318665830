import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import {getGraphInfo} from './Functions'
import TeamGraph from '../../Holdings/TeamGraph'
import {Pie} from 'react-chartjs-2'

const DoughnutTeam = (props) => {
    const user = props.user
    
    //Chart
    const options = {
        legend: {
            display: false,
            position: "right"
        },
        elements: {
            arc: {
            borderWidth: 0
            }
        },

    };
    const data = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ['Buy', 'Hold', 'Sell'],
        datasets: [
            {
                data: [60,20,20],
                backgroundColor:['rgb(0,150,0, .5)', 'rgb(150,150,150, .5)', 'rgb(200,0,0, .5)'],
                hoverBackgroundColor: ['lightgreen', 'lightgray', 'lightred']
            }
        ],
        
        
    };

    return(
        <>
            <Pie data={data} options={options} />
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(DoughnutTeam)