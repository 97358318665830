import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../LandingPage3/landingpage.css'
import './landingPage.css'
import { Link } from 'react-router-dom'
import Ticker from '../Analysis3/Ticker'
import {useHistory} from 'react-router-dom'

const NavBar = () => {

    const history = useHistory()

    let Logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa'

    return(
        <div>
        <Navbar style={{backgroundColor: 'rgba(255,255,255,0.7)'}} className='py-lg-5 NavBar2' expand="lg" fixed="top">
            <Navbar.Brand href="/"><img src={Logo} className='nav-logo' alt='logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" color="white" className='nav-toggle' />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto nav-size2">
                   {/* <NavDropdown title={<span className="roboNormal2">Learn More{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop2">
                        {/*<NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/TodaysTrades">Today's Trades</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/ExcelTrading">Excel Trading</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Teams">All Teams</NavDropdown.Item>*/}
                        {/*<NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Can-I-Make-Money">Can I Make Money?</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/PayoutAssurance">Win Payout Assurance</NavDropdown.Item>
                     </NavDropdown>
                    <NavDropdown title={<span className="roboNormal2">About Us{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop2">
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Our-Story"><span style={{textDecoration: "none"}}>Our Story</span></NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/ContactUs">Contact Us</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="robo200">
                        <Link to={{pathname: '/login', state: true}} className="roboNormal2">
                            Sign Up
                        </Link>
                    </Nav.Link>
                    {/*<Nav.Link className="robo200">
                        <Link to="/login" className="roboNormal2">
                            Log In
                        </Link>
                    </Nav.Link>*/}
                    <button className="signup-btn2" onClick={()=>history.replace('/Login')}>Sign Up</button>
                    <button className="login-btn2" onClick={()=>history.replace('/Login')}>Log In</button>
                </Nav>
            </Navbar.Collapse>
            
        </Navbar>
    </div>
    )
}

export default NavBar