import axios from 'axios'
import {route} from '../route'

//1. Get All Offers
//2. Get Offers By Team
//3. Get All Trades
//4. Get All Trades By Team
//5. Create Offer
//6. Make Trade
//7. Delete Offer

//1. Get All Offers
export const getAllOffers = async (token) => {
    const offersOptions = {
        method: 'GET',
        url: `${route}/offers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "completed": false
        }
    }
    let offers = []
    await axios.request(offersOptions).then(function (response) {
        let results = response.data
        offers = results
    }).catch(err => console.log(err))
    return offers
}

//2. Get Offers By Team
export const getOffersByTeam = async (asset_id, type, limit) => {
    const offersOptions = {
        method: 'GET',
        url: `${route}/offers`,
        params: {
            "asset_id": asset_id,
            "completed": false,
            "type": type,
            "limit": limit
        }
    }
    let offers = []
    await axios.request(offersOptions).then(function (response) {
        let results = response.data
        offers = results
    }).catch(err => console.log(err))
    return offers
}

//3. Get All Trades
export const getAllTrades = async () => {
    const tradesOptions = {
        method: 'GET',
        url: `${route}/trades`,
        headers: {
            //Authorization: `Bearer ${token}`,
        },
        params: {
            "limit": 100
        }
    }
    let trades = []
    await axios.request(tradesOptions).then(function (response) {
        let results = response.data
        trades = results
    }).catch(err => console.log(err))
    return trades
}

//4. Get All Trades By Team
export const getAllTradesByTeam = async (asset_id, limit) => {
    const tradesOptions = {
        method: 'GET',
        url: `${route}/trades`,
        headers: {
            //Authorization: `Bearer ${token}`,
        },
        params: {
            "asset_id": asset_id,
            "limit": limit
        }
    }
    let trades = []
    await axios.request(tradesOptions).then(function (response) {
        let results = response.data
        trades = results
    }).catch(err => console.log(err))
    return trades
}

//5. Create Offer
export const makeOffer = async (token, type, amount, id, price) => {
    const offersOptions = {
        method: 'POST',
        url: `${route}/offers`,
        params: {
            "amount": amount,
            "asset_id": id,
            "price": price,
            "type": type
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let offers = []
    await axios.request(offersOptions).then(response => {
        let results = response
        offers = results
    }).catch(function (error) {
        offers = error
        });
    return offers
}

//6. Make Trade
export const makeTrade = async (token, type, amount, id) => {
    
    const tradeOptions = {
        method: 'POST',
        url: `${route}/trade`,
        params: {
            "amount": amount,
            "asset_id": id,
            "type": type
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let trade = []
    await axios.request(tradeOptions).then(function (response) {
        let results = response
        trade = results
    }).catch(function (error) {trade = error.response});
    return trade
}

// 6. Make Trade (Variant: Axios Promise Return Type)
export const makeTradeAlt = (token, asset_id, amount, wants_buy= true) => {
    return axios.request({
       method: 'POST',
       url: `${route}/trade`,
       params: {
           asset_id,
           amount,
           type: wants_buy ? 'BUY' : 'SELL'
       },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

//7. Delete Offer
export const deleteOffer = async (token, id) => {
    const offersOptions = {
        method: 'DELETE',
        url: `${route}/offers/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
        
    }
    let offers = {}
    await axios.request(offersOptions).then(response => {
        let results = response
        offers = results
    }).catch(function (error) {
        offers = error
        });
    return offers
}