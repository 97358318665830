import React from 'react'
import '../TeammateItem.css'
import moment from 'moment'
import {getUsername, getTeam, getPayoutAmount, getTeammateObj, getTeammateObj_teammate, getTeamChange} from '../utils'
import {Link} from 'react-router-dom'

const Payout = (props) => {
    const item = props.item

    let teammate = props.teammates ? getTeammateObj_teammate(item.seller_id, props.teammates) : false

    const switchTeammate = () => {
        if(props.feed !== 'profile' || props.feed !== 'all'){
            props.changeTeammate(getTeammateObj_teammate(item.seller_id, props.teammates))
        }
        
    }

    let team = getTeamChange(item.payout.asset_id, props.teamsValue)

    return(
        <div className="teammate-item-card">
            <table style={{width: '100%'}}>
                <tr style={{width: '100%'}} className="tic-user">
                    <td>
                        <Link style={{color: 'black'}} onClick={switchTeammate}>
                            {item.seller_id && props.teammates && teammate.username}
                            {' '}
                            {props.feed !== 'profile' && <>
                                <span style={{fontWeight: '200', fontSize: '.8em'}}>{' '}${teammate &&teammate.value && teammate.value.toFixed(0)} 
                                {' '}
                                {teammate && teammate.one_day_return && <span style={{fontSize: '.9em', color: `${teammate.one_day_return.change === 0 ? 'gray' : teammate.one_day_return.change < 0 ? 'red' : 'green'}`}}>[ {teammate.one_day_return.change < 0 ? '-' : '+'}{Math.abs((teammate.one_day_return.change/teammate.value) * 100).toFixed(2)}% Today ]</span>}
                                </span>
                            </>}    
                        </Link>
                    </td>
                    <td style={{textAlign: 'right'}}><i class="fas fa-coins" style={{color: '#e0cb07'}}></i></td>
                </tr>
            </table>

            <div className="tic-body">
                <span>Earned{' '}</span>
                {item.amount && (item.price / .1).toFixed(2)}{' '}
                Win Payouts of <strong>
                    <Link style={{color: 'black'}} to={`/Teams/${getTeam(item.payout.asset_id, props.teamsValue)}`}>
                        Sim{props.teamsValue && props.item.payout && props.item.payout.asset_id && getTeam(item.payout.asset_id, props.teamsValue)} {' '} 
                    </Link></strong>
                    {' '}
                    {team ? <span style={{fontSize: '.8em', fontWeight: '300', color: `${team.change.day === 0 ? 'gray' : team.change.day < 0 ? 'red' : 'green'}`}}>[{team.change.day < 0 ? '-' : '+'}{Math.abs((team.change.day/team.asking_price) * 100).toFixed(2)}%] </span> : ''}
                    {' '}
                for {' '}
                <strong>${item.price && item.price.toFixed(2)}.{' '}</strong>
                
            </div>

            <div className="tic-date">{moment(`${item.timestamp}.000Z`).format('MM/DD/YY HH:mm')}</div>

        </div>
    )
}

export default Payout