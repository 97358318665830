import React, {useEffect} from 'react'
import './landingpage.css'
import NavBar from './NavBar'
import Headshots from './Headshots'
import Footer from './Footer'
import ReactPixel from 'react-facebook-pixel'
import Helmet from 'react-helmet'
  
const OurStory = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'guest'})
    },[])
    
    return(
        <>
        <Helmet>
            <title>SimBull</title>
            <meta property="og:title" content="SimBull’s Story | Learn About SimBull from the Co-Founders!" />
            <meta property="og:description" content="SimBull was started by a group of friends who wanted to blend sports gambling and stock market investing. Learn more about our story here!" />
            <meta property="og:url" content="https://www.simbull.app/Our-Story" />
            <meta property="og:type" content="website" />
        </Helmet>
        <NavBar />
        <div className="OurStory">
            
            <div className='os-body' style={{fontStyle: 'italic'}}>
                Our Mission is to Help You Profit Off Sports Knowledge.
            </div>
            <br />
            <div className='os-title'>Our Vision</div>
            <br />
            <div className='os-body'>
                A liquid, well-functioning stock market for sports where people can trade teams like stocks, a community of sports fans interacting through their teammates profile which act as their status and reputation on platform, and let athletes and fans easily create, share and monetize their informative, entertaining, and/or predictive sports content.
            </div>
            <br />
            <div className='os-title'>Our Story</div>
            <br />
            <div className='os-body'>
                One winter day, Kenneth, SimBull Co-Founder and CEO, was watching the NFL Draft Combine and absolutely fanboying over Joe Burrow. 
                Unfortunately, there was no way to bet on the Bengals being good in a few years.
            </div>
            <br />
            <div className='os-body'>
                But, then a thought popped into his head, “What if there was a long term market for sports where you could trade teams like stocks?” 
                From that day on, he has been on a mission to create a way for people to profit and enjoy sports in the long term without the high fees of gambling. 
            </div>
            <br />
            <div className='os-body'>
                Since then, we have been working hard to figure out how to make that dream into a reality. 
            </div>
            <br />
            <div className='os-body'>
                We are a group of friends who are avid sports gamblers, investors, and finance professionals trying to build the best long-term sports market that eliminates the sportsbook’s edge so we can enjoy sports like never before.
            </div>
            <Headshots />
            <hr />
            <div className="os-video-intro">Listen to Kenneth giving you more of the story.</div>
            <iframe className='os-video' width="420" height="420" title='infomercial'
                src="https://www.youtube.com/embed/XevUHWF32PM">
            </iframe>
            <hr />
            
        </div>
        <Footer />
        </>
    )
}

export default OurStory