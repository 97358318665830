import React, {useEffect, useState} from 'react'
import { db } from '../../services/firebase'
import './Account.css'
import Header from './Header'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WithdrawReceipt from '../Receipts/WithdrawReceipt'
import WithdrawConfirm from '../Receipts/WithdrawConfirm'
import ReactPixel from 'react-facebook-pixel'

const Withdraw = (props) => {
    //props
    const user = props.user

    //states
    const [amount, setAmount] = useState(0)
    const [paypalEmail, setPaypalEmail] = useState(user.email ? user.email : '')
    const [text, setText] = useState("")
    const [show, setShow] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const hide = () => {
        setShow(false)
    }
    const showShow = () => {
        setShow(true)
    }
    const hideConfirm = () => {
        setConfirm(false)
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    //Functionality
    const submitWithdraw = () => {
        let bonusReady = user.tenure > 90 ? true : false
        let withdrawable = (user.cash ? parseFloat(user.cash.toFixed(2)): 0)-(!bonusReady ? parseFloat(user.bonusesReceived) : 0)
        let amountNum = parseFloat(amount)
        let minAmt = withdrawable
        if(amountNum > minAmt || amountNum < 15){
            alert(`Sorry, we have a minimum withdrawl of $15 and you are only eligible to withdraw your cash amount: $${user.cash.toFixed(2)} ${bonusReady ? '' : `minus your bonuses: $${user.bonusesReceived}`}. To withdraw bonuses, you must have an average account value of $50 for 90 days and $100 of trading volume.`)
        }
        else {
            let profits = document.getElementById('profits').checked
            let cash = document.getElementById('cash').checked
            let website = document.getElementById('website').checked
            let fees = document.getElementById('fees').checked
            let notMe = document.getElementById('notMe').checked
            let confusing = document.getElementById('confusing').checked
            let other = document.getElementById('other').checked
            let withdrawalCount = user.withdrawalCount ? user.withdrawalCount + 1 : 1
            if(profits || cash || website || fees || notMe || confusing || other){
                let fee = parseFloat(amount) * 0.03
                let getting = parseFloat(amount) * .97
                let answer = window.confirm(`Please confirm you would like to withdraw $${parseFloat(amount).toFixed(2)} minus a 3% processing fee of $${fee.toFixed(2)} to receive $${getting.toFixed(2)}.`)
                if(answer){
                    let today = moment().format('YYYYMMDD')
                    db.collection('Users').doc(user.email).update({
                        withdrawalCount: withdrawalCount
                    })
                    db.collection('PayoutRequests').doc(`${today}-Cash-${user.email}-${amount}`).set({
                        user: user.email,
                        amount: amount,
                        paypalEmail: paypalEmail,
                        text: text,
                        type: 'cash',
                        completed: false,
                        date: today,
                        user: user.email,
                        payoutAmount: amount * .97,
                        userCode: user.code ? user.code : '',
                        userObj: user,
                        zProfits: profits,
                        zCash: cash,
                        zWebsite: website,
                        zFees: fees,
                        zNotMe: notMe,
                        zConfusing: confusing,
                        zOther: other
                    }).then(res => {
                        setShow(true)
                        //alert(`You will receive your cash ($${getting.toFixed(2)}) through Paypal to ${paypalEmail} within 24 hours once you have responded to our email confirming your email.`)
                    }).catch(err => { alert(`Sorry there was an error. Please try again or email: customer-service@simbull.app`) })
                } 
            }
            else{
                alert(`Please check atleast one reason first!`)
            }
        }
    }

    /*const completeWithdraw = () => {
        if(profits || cash || website || fees || notMe || confusing || other){
            let today = moment().format('YYYYMMDD')
            db.collection('PayoutRequests').doc(`${today}-Cash-${user.email}-${amount}`).set({
                user: user.email,
                amount: amount,
                payoutAmount: amount * 0.97,
                paypalEmail: paypalEmail,
                text: text,
                type: 'cash',
                completed: false,
                date: today,
                userCode: user.code ? user.code : '',
                user: user
            }).then(res => {
                setShow(true)
                setConfirm(false)
                // alert(`You will receive your cash ($${amount}) through Paypal to ${paypalEmail} within 24 hours once you have responded to our email confirming your email.`)
            }).catch(err => { 
                console.log(err)
                alert('Sorry an error occurred. Please try again or make withdrawal request to customer-service@simbull.app.')
            })
        }
        else{
            alert(`Please check atleast one reason first!`)
        }
        
    }*/

    const closeConfirm = () => {
        setConfirm(false)
    }

    const inputStyle = {
        marginRight: '5%',
        transform: 'scale(2)',
        marginBottom: '5%'
    }
    

    return(
        <>
        
        <div className="Withdraw DesktopContainer">
            <div className='withdraw-div'>
                <div className='withdraw-head'>Withdraw</div>
                <span>Payout will be sent within 48 hours and be processed by PayPal.</span>
                <div className='withdraw-body'>
                <div>Please enter the your PayPal email:<br /><input className="withdraw-body-input" value={paypalEmail} onChange={(e)=>{setPaypalEmail(e.target.value)}} /></div><br />
                <div className='withdraw-cash'>Minimum: $15</div>
                <div className='withdraw-cash'>Cash Availble: ${(user.cash ? user.cash.toFixed(2): 0)-(user.tenure < 90 ? user.bonusesReceived : 0)}</div>
                    <input className="withdraw-input" value={amount} onChange={(e)=>{setAmount(e.target.value)}} />
                    <br />
                    <div style={{fontSize: '1.5rem', textAlign: 'left'}}>Please tell us why you want to withdraw so we can make it better.</div>
                    <div style={{
                        textAlign: 'left',
                        margin: '2%',
                        marginLeft: '8%',
                        fontSize: '1.2rem'
                    }}>
                        <input type='checkbox' id="profits" style={inputStyle} /><span>Withdrawing Profits</span><br />
                        <input type='checkbox' id="cash" style={inputStyle} /><span>Need the Cash</span><br />
                        <input type='checkbox' id="website" style={inputStyle} /><span>Website Sucks</span><br />
                        <input type='checkbox' id="fees" style={inputStyle} /><span>Too Many Fees</span><br />
                        <input type='checkbox' id="notMe" style={inputStyle} /><span>Just not for Me</span><br />
                        <input type='checkbox' id="confusing" style={inputStyle} /><span>Too Confusing</span><br />
                        <input type='checkbox' id="other" style={inputStyle} /><span>Other</span><br />
                    </div>
                    <textarea className="withdraw-textarea" value={text} onChange={(e)=>{setText(e.target.value)}} />
                    <br />
                    <div className='withdraw-cash'>Bonuses can only be cashed out after maintaining an account balance of an average of $50 for 90 days with $100 in trading volume.</div>
                    <br />
                    <div className='withdraw-cash'>Processing Fee: 3% </div>
                    <br />
                    <button onClick={submitWithdraw} className="withdraw-btn">Withdraw</button>
                </div>
            </div>
            {show && <WithdrawReceipt amount={amount} text={text} hide={hide} paypalEmail={paypalEmail} />}
            {/*confirm && <WithdrawConfirm amount={amount} text={text} hide={hideConfirm} paypalEmail={paypalEmail} closeConfirm={closeConfirm} />*/}
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(Withdraw))