import React, {useState, useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import '../Portfolio3/portfolio.css'
import 'chartjs-plugin-datalabels';



const IndexChart = (props) => {
  const labels = props.labels
  const prices = props.prices
  
  //const labels = ['Sept 3rd', 'Sept 2nd', 'Sept 1st', 'Aug 31st', 'Aug 30th']
  //const prices = [1298, 1287, 1290, 1286, 1282, 1279]
  
  const [chartData, setChartData] = useState()

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const chart = () => {
    setChartData({
      labels: labels,
      datasets: [
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: '#c5b358',
          borderColor: '#c5b358',
          borderWidth: 2,
          data: prices,
          //yAxisID: 'price1'
        },
      ]
    })
  }
  useEffect(() => {
    chart()
  }, [props])

  return (
    <div style={{paddingBottom: '0px', marginBottom: '0px', zIndex:10000}}>
      {chartData && <Line
        data={chartData}
        options={{
          title:{
            display:false,
            text:' ',
            fontSize:10
          },
          legend:{
              display: false
          },
          plugins: {
            datalabels: {
              display: false,
            }
          },
          layout: {
              padding: {
                  left: 10,
                  right: 10,
                  top: 20,
                  bottom: 20
              }
          },
          elements: {
              point: {
                  radius: 0
              }
          },
          scales:
          {
              yAxes: [{
                display : false,//isMobile ? false : true,
                //position: 'right',
                //id: 'price1',
              }],
              xAxes: [{
                  display : false
              
          }]
          },
              tooltips: {
                  mode: 'nearest',
                  intersect: false,
                  "enabled": true,
                displayColors: false,
                caretSize: 0,
                titleFontSize: 9,
                bodyFontSize: 11,
                bodySpacing: 0,
                titleSpacing: 0,
                xPadding: 2,
                yPadding: 2,
                cornerRadius: 2,
                titleMarginBottom: 2,
                  
              },
              hover: {
                  mode: 'index',
                  intersect: false
              }
        }}
      />}
      
    </div>
  )
}

export default IndexChart