import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import './portfolio2.css'
import {getPayouts} from '../../routes/user/user'
import Graph from './Graph'



const Value = (props) => {
    //get props
    const user = props.user
    const teams = props.teams
    const spin = props.spin ? props.spin : false

    console.log(props.totals)

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /*Three */
    const [totals, setTotals] = useState(props.totals ? props.totals : {})

    useEffect(()=>{
        if(props.totals){
            setTotals(props.totals)
        }
    },[props.totals])

    const [payouts, setPayouts] = useState(0)
    useEffect(()=>{
        if(props.token){
            getPayouts(props.token).then(res => {
                let total = 0;
                res.forEach(element => {
                    total += element.reward
                }
            )
            setPayouts(total)
            })
        }
    },[])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    let expand = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fexpand-arrows.png?alt=media&token=382b087d-ac9d-4d0a-8721-9b51544d60ac'

    //Your balance <div style={{}} id={spin ? 'spinner' : ''}>${numberWithCommas(totals.total_value ? totals.total_value.toFixed(2) : 0)}</div>
    //cash
    //change <span style={{fontSize: '1.75em', fontWeight: '500'}}>{totals.total_change >= 0 ? '+' : '-'}${numberWithCommas(totals.total_change ? Math.abs(totals.total_change).toFixed(2) : 0)}</span><br />
    //percentage change {totals && totals.total_deposited ? <><span>{totals.total_change > 0 ? <span className="fas fa-caret-up" style={{color: 'green', fontSize: '1.1em'}}></span> : <span className="fas fa-caret-down" style={{color: 'red', fontSize: '1.1em'}}></span>} {totals.total_change >= 0 ? '+' : '-'}{Math.abs(((totals.total_value + totals.total_withdrawn-totals.total_deposited)/totals.total_deposited)*100).toFixed(2)}%</span></>: <span><span className="fas fa-caret-up" style={{color: 'green', fontSize: '1.1em'}}></span>+0.00%</span>}

    return(
        <div style={{paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px', paddingTop: '4px', paddingBottom: isMobile ? '0px':'12px', backgroundColor: 'black', width: '100%', height: '100%', display: isMobile ? 'inline':'flex', flexDirection: 'row', }}>
            <div style={{ width: isMobile ? '100%':'30%', verticalAlign: 'top', }}>

                {isMobile ? <>
                    <div style={{fontSize: '1rem', fontWeight: '500', color: '#c4c4c4', marginLeft: '12px'}}>
                        Your balance
                    </div>
                    <div style={{marginLeft: '12px'}}>
                        <div style={{color: 'white', fontSize: '1.75rem', fontWeight: '600', display: 'inline-block'}}>
                            ${numberWithCommas(totals.total_value ? totals.total_value.toFixed(2) : 0)}
                        </div>                        
                        <div style={{display: 'inline-block', marginLeft: '0px', fontSize: '.9rem', color: '#c4c4c4', marginLeft: '4px'}}>
                            Cash
                            ${totals && totals.cash_balance ? totals.cash_balance.toFixed(2) : 0.00}
                        </div>
                    </div>
                    <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />
                    <div style={{ verticalAlign: 'bottom',color: 'white', marginTop: '12px',  marginLeft: '12px', marginRight: '12px'}}>
                        <div style={{marginBottom: '4px', display: 'inline-block', width: '33.33%', textAlign: 'left'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300'}}>Win Payouts</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>${payouts ? payouts.toFixed(2) : '0.00'}</span>
                        </div>
                        <div style={{marginBottom: '4px', display: 'inline-block', width: '33.33%', textAlign: 'center', borderLeft: '.5px solid rgb(100,100,100)', borderRight: '.5px solid rgb(100,100,100)',}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Share Price +/-</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>{(totals && (totals.total_change - payouts) < 0) ? '-':''}${(totals) ? (totals.total_change - payouts).toFixed(2) : '0.00'}</span>
                        </div>
                        <div style={{marginBottom: '4px', display: 'inline-block', width: '33.33%', textAlign: 'right'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Total Return</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>{totals ? totals.total_change < 0 ? '-': '' : null}${totals ? Math.abs(totals.total_change).toFixed(2) : '0.00'}</span>
                        </div>
                    </div>
                    <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%', marginTop: '0px', paddingTop: '0px'}} />
                </>
                :
                <>
                    <div style={{fontSize: '1rem', fontWeight: '500', color: '#c4c4c4'}}>
                        Your balance
                    </div>
                    <div>
                        <div style={{color: 'white', fontSize: '1.75rem', fontWeight: '600', display: 'inline-block'}}>
                            ${numberWithCommas(totals.total_value ? totals.total_value.toFixed(2) : 0)}
                        </div>
                        <br />
                        <div style={{display: 'inline-block', marginLeft: '0px', fontSize: '.9rem', color: '#c4c4c4'}}>
                            Cash <br />
                            <span style={{color: 'white', fontSize: '1.1rem'}}>${totals && totals.cash_balance ? totals.cash_balance.toFixed(2) : 0.00}</span>
                        </div>
                    </div>
                    <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '55%', marginLeft: '.5%'}} />
                    <div style={{ verticalAlign: 'bottom',color: 'white', marginTop: '32px', }}>
                        <div style={{marginBottom: '4px'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300'}}>Win Payouts</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>${payouts ? payouts.toFixed(2) : '0.00'}</span>
                        </div>
                        <div style={{marginBottom: '4px'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Share Price +/-</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>{(totals && (totals.total_change - payouts) < 0) ? '-':''}${(totals) ? (totals.total_change - payouts).toFixed(2) : '0.00'}</span>
                        </div>
                        <div style={{marginBottom: '4px'}}>
                            <span style={{color: '#c4c4c4', fontSize: '.95rem', fontWeight: '300' }}>Total Return</span>
                            <br />
                            <span style={{color: 'white', fontSize: '1.1rem', marginLeft: '0px'}}>{totals ? totals.total_change < 0 ? '-': '' : null}${totals ? Math.abs(totals.total_change).toFixed(2) : '0.00'}</span>
                        </div>
                    </div>
                </>}
                
                
            </div>
            <div style={{width: isMobile ? '95%':'70%', marginLeft: isMobile ? '2.5%':'0px', paddingTop: isMobile ? '0px':'12px', }}>
            <Graph off={false} prices={props.prices ? props.prices : []} assets={true} />
                
            </div>

                    

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(Value)