import * as types from "../../types";

const initialState = {watchlist: []}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_USER_WATCHLIST:
            return {
                ...state,
                watchlist: payload
            };
        default:
            return state;
    }
}