import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import {buttonStyle, selectedButtonStyle} from '../Trade2/Trade2'
import '../Trade2/Trade.css'
import { get_nba_teams, get_nba_games } from '../../routes/nba_playoffs/nba_playoffs'
import {firebaseAnalytics} from '../../services/firebase'
//Market
import AllTrades from '../Trade2/AllTrades'
import FullLeague from '../Trade2/FullLeague'
import FullList from '../Trade2/FullList'
//Pages
import Rules from '../SimBull16/Rules'
import Scores from './Scores'
import Leaderboard from './Leaderboard'

const SimBull15 = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    useEffect(()=>{window.scrollTo(0,0)},[])


    let mobileBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FSB%2016%20march%20madness%20desktop%20banner%20(1500%20%C3%97%201000px)%20(750%20%C3%97%20500px)%20(600%20%C3%97%20400px)%20(300%20%C3%97%20200px)%20(1).svg?alt=media&token=d1a0e5b9-521d-41c7-9c61-f20352b2adc1'
    let desktopBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FSB%2016%20march%20madness%20desktop%20banner.svg?alt=media&token=f131b24b-6aed-45ff-b1e7-3e11532422d1'

    const [page, setPage] = useState('Bracket')
    const [display, setDisplay] = useState('trades')

    const [teams, setTeams] = useState([])
    const [games, setGames] = useState([])
    const [leaderboard, setLeaderboard] = useState([])

    useEffect(()=>{
        get_nba_teams().then(res => setTeams(res))
        get_nba_games().then(res => setGames(res))

        firebaseAnalytics.logEvent('SimBull15_visit')
    },[])

    

    return(
        <>
        <div style={{paddingTop: isMobile ? `6vh` : `0vh`, backgroundColor: 'black'}}>
            <img style={{width: '100%'}} src={isMobile ? mobileBanner : desktopBanner} />
        </div>

        <div style={{textAlign: 'center', padding: '15px', paddingBottom: '5px', backgroundColor: 'black', color: 'white', fontSize: isMobile ? '.6rem' : '1rem', fontWeight: '600'}}>
            <Link onClick={()=>setPage('Bracket')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Bracket' ? '2px solid #c5b358' : ''}}>My Picks</div></Link>
            <Link onClick={()=>setPage('Leaderboard')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Leaderboard' ? '2px solid #c5b358' : ''}}>Leaderboard</div></Link>
            <Link onClick={()=>setPage('Rules')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Rules' ? '2px solid #c5b358' : ''}}>Rules</div></Link>
            <Link onClick={()=>setPage('Scores')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Scores' ? '2px solid #c5b358' : ''}}>Scores</div></Link>
            <Link onClick={()=>setPage('Market')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Market' ? '2px solid #c5b358' : ''}}>Market</div></Link>
        </div>
        
        {page === "Bracket" && <div>Bracket</div>} {/* <Bracket sendPicks={sendPicks} teams={teams} token={props.token} regions={regions} rounds={rounds} roundsRight={roundsRight} finalRounds={finalRounds} games={games} my={my} />}*/}
        {page === 'Leaderboard' && <Leaderboard teams={teams} games={games} leaderboard={leaderboard} />}
        {page === 'Rules' && <Rules />}
        {page === 'Scores' && <Scores teams={teams} games={games} />}
        {page === "Market" && <div style={{backgroundColor: 'rgb(240,240,240)', minHeight: '100vh'}}>
            <div className='DesktopContainer' style={{textAlign: 'center'}}>
                <button style={display === 'movers' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('movers')}>Movers</button>
                <button style={display === 'trades' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('trades')}>Trades</button>
                <button style={display === 'teams' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('teams')}>Teams</button>
            </div>

                {display === 'movers' && <FullList market={true} />}
                {display === 'trades' && <AllTrades market={true} />}
                {display === 'teams' && <FullLeague market={true} />}

        </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    totals: state.totals.totals,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(SimBull15)