import React, {useEffect, useState} from 'react'
import { db } from '../../services/firebase'
import './Account.css'
import Header from './Header'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WithdrawReceipt from '../Receipts/WithdrawReceipt'
import WithdrawConfirm from '../Receipts/WithdrawConfirm'
import ReactPixel from 'react-facebook-pixel'
import {postWithdraw} from '../../routes/transfers/withdraw'
import Popup from 'reactjs-popup'

const Withdraw = (props) => {
    //props
    const user = props.user

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    //states
    const [amount, setAmount] = useState(0)
    const [paypalEmail, setPaypalEmail] = useState('')
    const [text, setText] = useState("")
    const [show, setShow] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [deposit, setDeposit] = useState(false)

    const [totals, setTotals] = useState(props.totals ? props.totals : {})
    useEffect(()=>{
        if(totals === {} && props.totals){
            setTotals(props.totals)
        }
    },[props.totals])

    const hide = () => {
        setShow(false)
    }
    const showShow = () => {
        setShow(true)
    }
    const hideConfirm = () => {
        setConfirm(false)
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    //Functionality
    const submitWithdraw = () => {
        if(parseFloat(amount) < 15){
            setError(true)
            setErrorMsg(`Sorry, we have a minimum withdrawl of $15`)
        }
        else if(!paypalEmail){
            setError(true)
            setErrorMsg('Please enter valid email')
        }
        else if(totals.cash_balance && totals.cash_balance >= parseFloat(amount)){
            let five = document.getElementById('five').checked
            let four = document.getElementById('four').checked
            let three = document.getElementById('three').checked
            let two = document.getElementById('two').checked
            let one = document.getElementById('one').checked
            let fee = parseFloat(amount) * 0.03
            let getting = parseFloat(amount) * .97
            if(one || two || three || four || five){
                postWithdraw(props.token, parseFloat(amount), paypalEmail).then(res => {
                    if(res.response){
                        if(res.response.data && res.response.data.msg){
                            setError(true)
                            setErrorMsg(res.response.data.msg)
                        }
                    }else if(res.paypal_email){
                        setConfirm(false)
                        setShow(true)
                    }
                    
                })
            }
            else{
                setError(true)
                setErrorMsg('Please fill out required fields')
            }
             
        } else{ 
            setError(true)
            setErrorMsg('Not enough cash')
        }
    }


    const doConfirm = () => {
        if(parseFloat(amount) < 15){
            setError(true)
            setErrorMsg(`Sorry, we have a minimum withdrawl of $15`)
        }
        else if(!paypalEmail){
            setError(true)
            setErrorMsg('Please enter valid email')
        }
        else if(totals.cash_balance && totals.cash_balance >= parseFloat(amount)){
            let five = document.getElementById('five').checked
            let four = document.getElementById('four').checked
            let three = document.getElementById('three').checked
            let two = document.getElementById('two').checked
            let one = document.getElementById('one').checked
            let fee = parseFloat(amount) * 0.03
            let getting = parseFloat(amount) * .97
            if(one || two || three || four || five){
                setConfirm(true)
            }
            else{
                setError(true)
                setErrorMsg('Please fill out required fields')
            }
        }
        else{
            setError(true)
            setErrorMsg('Not enough cash')
        }
        
    }

    const inputStyle = {
        marginRight: '5%',
        transform: 'scale(2)',
        marginBottom: '5%'
    }

    

    return(
        <>
        
        <div className="Withdraw DesktopContainer">
            <div className='withdraw-div'>
                <div className='withdraw-head'>Withdraw</div>
                <span>Payout will be sent within 24 hours and be processed by PayPal.</span>
                <div className='withdraw-body'>
                <div>Please enter your PayPal email:<br /><input className="withdraw-body-input" value={paypalEmail} onChange={(e)=>{setPaypalEmail(e.target.value)}} /></div><br />
                <div className='withdraw-cash'>Minimum: $15</div>
                <div className='withdraw-cash'>Cash Availble: ${totals && totals.cash_balance}</div>
                    <input className="withdraw-input" value={amount} onChange={(e)=>{setAmount(e.target.value)}} />
                    <br />
                    <div style={{fontSize: '1.5rem', textAlign: 'left'}}>What would you rate SimBull?
                        <br /><span style={{fontSize: '1rem', textAlign: 'left', color: 'red'}}> *required</span>
                    </div>
                    <div style={{
                        textAlign: 'left',
                        margin: '2%',
                        marginLeft: '8%',
                        fontSize: '1.2rem'
                    }}>
                        <input type='checkbox' id="five" style={inputStyle} /><span>5 ⭐️⭐️⭐️⭐️⭐️</span><br />
                        <input type='checkbox' id="four" style={inputStyle} /><span>4 ⭐️⭐️⭐️⭐️</span><br />
                        <input type='checkbox' id="three" style={inputStyle} /><span>3 ⭐️⭐️⭐️</span><br />
                        <input type='checkbox' id="two" style={inputStyle} /><span>2 ⭐️⭐️</span><br />
                        <input type='checkbox' id="one" style={inputStyle} /><span>1 ⭐️</span><br />
                    </div>
                    <div style={{fontSize: '1.5rem', textAlign: 'left'}}>Any Comments?</div>
                    <textarea className="withdraw-textarea" value={text} onChange={(e)=>{setText(e.target.value)}} />
                    <br />
                    <div style={{fontSize: '1.5rem', textAlign: 'left'}}>Will you deposit again in the future?
                        <br /><span style={{fontSize: '1rem', textAlign: 'left', color: 'red'}}> *required</span>
                    </div>
                    <div style={{textAlign: 'left', paddingLeft: '8%'}}>
                        <button style={deposit === 'yes' ? {border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: '#c5b358', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', marginRight: '10px'}: {border: 'none', outline: 'none', borderRadius: '.7rem', backgroundColor: 'lightgray', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', marginRight: '10px'}} onClick={()=>setDeposit('yes')}>Yes</button>
                        <button style={deposit === 'no' ? {border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: '#c5b358', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px'}: {border: 'none', outline: 'none', borderRadius: '.7rem', backgroundColor: 'lightgray', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px'}} onClick={()=>setDeposit('no')}>No</button>
                    </div>
                    <br />
                    <div className='withdraw-cash'>Bonuses can only be cashed out after maintaining an account balance of an average of $100 for 90 days with $100 in trading volume.</div>
                    <br />
                    <div className='withdraw-cash'>Processing Fee: 3% </div>
                    
                    <br />
                    {error && <div style={{textAlign: 'center', color: 'red'}}>{errorMsg}</div>}
                    <button onClick={doConfirm} className="withdraw-btn">Withdraw</button>
                </div>
            </div>
            {show && <WithdrawReceipt amount={amount} text={text} hide={hide} paypalEmail={paypalEmail} />}
            

            <Popup open={confirm} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    <div style={{textAlign: 'center', fontSize: '2.5em'}}>Confirm Withdraw</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Withdrawl Amount</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(amount).toFixed(2))}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Withdrawl Fee (3%) </div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(amount*.03).toFixed(2))}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Total Pending</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(amount*.97).toFixed(2))}</div>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={submitWithdraw} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Confirm</button><br />
                            <button onClick={()=>setConfirm(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>
                    </div>
                </div>
            </Popup>

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token,
    totals: state.totals.totals
});

export default connect(mapStateToProps)(withRouter(Withdraw))