import * as types from "../types";
import moment from 'moment'
import {doRefresh} from '../../routes/user/token'
import {useDispatch} from 'react-redux'
import {getUserEquity} from './user/equity'
import {getWatchlist} from './watchlist'
import {getUserTotals} from './user/totals'
import {get_my_user} from './user/myuser'
import {getInfo} from './user/info'
import {getUserPriceInfo} from './user/userPrices'
import jwt_decode from 'jwt-decode'
import {getMyWatchlist} from '../../routes/user/watchlist'



export const getToken = (token) => (dispatch) => {

    const useOldToken = () => {
        let access = window.localStorage.getItem('access_token')
        let refresh = window.localStorage.getItem('refresh_token')
        var decoded = jwt_decode(access);
        var refreshDecoded = jwt_decode(refresh);
        /*if(access && decoded.exp > ts){
            dispatch(getToken(access))
        }
        else */if(refresh && refreshDecoded.exp > ts){
            doRefresh(refresh).then(res => {
                let newToken = {access_token: res}
                dispatch(getToken(newToken))
            }).catch(err => console.log(err))
        }
    }

    var ts = Math.round((new Date()).getTime() / 1000);
    if(token === 'logout'){
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('refresh_token')
        dispatch({
            type: types.GET_TOKEN,
            payload: ''
        })
    }
    else if(token){
        let useToken = token
        if(token && token.access_token && token.access_token.access_token){
            useToken = token.access_token.access_token
        }
        else if(token && token.access_token){
            useToken = token.access_token
        }
        if(useToken && jwt_decode(useToken, {headers: true}).exp > ts){
            window.localStorage.setItem("access_token", useToken)
            if(token.refresh_token){
                window.localStorage.setItem("refresh_token", token.refresh_token)
            }
            dispatch({
                type: types.GET_TOKEN,
                payload: useToken
            })
            dispatch(getUserTotals(useToken))
            dispatch(getUserEquity(useToken))
            dispatch(getUserPriceInfo(useToken))
            dispatch(get_my_user(useToken))
            dispatch(getInfo(useToken))
            dispatch(getWatchlist(useToken))
            return 'success'
        }
        else{
            if(window.localStorage.getItem('access_token') && window.localStorage.getItem('refresh_token')){
                useOldToken()
            }
            
        }
    }
    else{
        if(window.localStorage.getItem('access_token') && window.localStorage.getItem('refresh_token')){
            useOldToken()
        }
    }
    return 'end'
}