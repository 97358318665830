import React, {useState, useEffect} from 'react'
import '../Analysis.css'
import {db, firebaseAnalytics} from '../../../services/firebase'
import ReactHtmlParser from 'react-html-parser'
import Article from '../Article'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import firebase from 'firebase'
import axios from 'axios'

const NFLgame = (props) => {

    const [game, setGame] = useState(props.game ? props.game : {})

    const [liveGame, setLiveGame] = useState({})

    const getInfo = async () => {

        for(let i = 0; i < 19; i++ ){
            let thursday = moment('2021-09-13').subtract(-i, 'weeks').format('YYYY-MM-DD')

            const options5 = {
                method: 'GET',
                url: 'https://sportspage-feeds.p.rapidapi.com/games',
                params: {/*gameId: game.gameId,*/date: thursday, league: 'NFL',},
                headers: {
                    'x-rapidapi-host': 'sportspage-feeds.p.rapidapi.com',
                    'x-rapidapi-key': 'e51d024ba9msh58758a87cca713fp1c2411jsn2697c71ac948'
                }
            }

            console.log('1')

            await axios.request(options5).then(function (response) {


                console.log(response)
                response.data.results.map(g => {
                    db.collection('NFL2021Games').doc(g.gameId.toString()).set({
                        details: g.details,
                        gameId: g.gameId,
                        lastUpdated: g.lastUpdated,
                        odds: g.odds ? g.odds : {},
                        schedule: g.schedule,
                        scoreboard: g.scoreboard ? g.scoreboard : null,
                        status: g.status,
                        summary: g.summary,
                        teams: g.teams,
                        venue: g.venue
                    }, {merge: true}).then(res => {
                        console.log(g)
                    }).catch(err => console.log(err))
                    console.log(g)
                })
                //setGame(response.data.results[0])
                //setLiveGame(response.data.results[0])

            }).catch(function (error) {
                console.error(error);
            });
        }
        
        

    }
    useEffect(()=>{
        //getInfo()
    },[])

    

    return(
        <div style={{
            width: '90%',
            marginLeft: '5%',
            textAlign: 'left',
            boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)',
            marginTop: '10px'
        }}>
            <div style={{
                backgroundColor: 'rgb(43, 43, 43)',
                color: 'white',
                fontSize: '1.2rem',
                fontWeight: '600',
                padding: '5px',
                paddingLeft: '10px'
            }}>
                <div style={{
                    fontWeight: '400'
                }}>{`${game.schedule.date.toString().substring(0,10)} ${game.schedule.date.toString().substring(11,16)}`}</div>
                <div style={{
                    fontSize: '1rem',
                    fontWeight: '300'
                }}>{game.venue.name}</div>
            </div>
            <div style={{
                color: 'rgb(0, 0, 55)'
            }}>
                <div style={{verticalAlgin: 'top'}}>
                    <div style={{
                        display: 'inline-block',
                        width: '80%'
                    }}>
                        <div style={{
                            fontSize: '1.55rem',
                            fontWeight: '400',
                            paddingLeft: '15px'
                        }}>{game.teams.away.location}</div>
                        <div style={{
                            fontSize: '1.2rem',
                            fontWeight: '400',
                            paddingLeft: '15px'
                        }}>{game.teams.away.mascot} 
                            {game.odds && game.odds[0] && game.odds[0].spread &&  game.odds[0].spread.current &&  <span style={{fontWeight: '300', fontSize: '1.05rem', color: 'gray', marginLeft: '7.5px'}}>{game.odds[0].spread.current.away > 0 ? `+${game.odds[0].spread.current.away}` : game.odds[0].spread.current.away}</span>}
                        </div>
                    </div>
                    <div style={{
                        display: 'inline-block',
                        width: '20%',
                        verticalAlgin: 'top',
                        fontWeight: '500',
                        fontSize: '3rem',
                        color: 'black'
                    }}>{game.scoreboard ? game.scoreboard.score.away : '-'}</div>
                </div>
                <div>
                    <div style={{
                        display: 'inline-block',
                        width: '80%'
                    }}>
                        <div style={{
                            fontSize: '1.55rem',
                            fontWeight: '400',
                            paddingLeft: '15px'
                        }}>{game.teams.home.location}</div>
                        <div style={{
                            fontSize: '1.2rem',
                            fontWeight: '400',
                            paddingLeft: '15px'
                        }}>
                            {game.teams.home.mascot}
                            {game.odds && game.odds[0] && game.odds[0].spread &&  game.odds[0].spread.current && <span style={{fontWeight: '300', fontSize: '1.05rem', color: 'gray', marginLeft: '7.5px'}}>{game.odds[0].spread.current.home > 0 ? `+${game.odds[0].spread.current.home}` : game.odds[0].spread.current.home}</span>}
                        </div>
                    </div>
                    <div style={{
                        display: 'inline-block',
                        width: '20%',
                        verticalAlgin: 'top',
                        fontWeight: '500',
                        fontSize: '3rem',
                        color: 'black'
                    }}>{game.scoreboard ? game.scoreboard.score.home : '-'}</div>
                </div>
                 
                <div style={{
                    textAlign: 'right',
                    paddingRight: '15px',
                }}>
                    {game.odds && game.odds[0] && game.odds[0].total && <span style={{fontWeight: '300', fontSize: '1.25rem', color: 'gray', marginLeft: '7.5px'}}>Total O/U: {game.odds[0].total.current.total}</span>}
                </div>

            </div>
            
            {/*liveGame && <>
                <div>{liveGame.teams && liveGame.teams.away.mascot} @ {liveGame.teams && liveGame.teams.home.mascot}</div>
                <div>{liveGame.venue && liveGame.venue.name}</div>
            </>}*/}
        </div>
        
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(NFLgame)