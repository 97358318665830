import * as types from "../types";

const initialState = {teams3: []};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.ADD_TEAMS3:
            return {
                ...state,
                teams3: payload
            };
        default:
            return state;
    }
}