import axios from 'axios'
import {route} from '../route'

/*1. Teammate Follow Requests */
export const get_follow_request = async (token) => {
    const teammateOptions = {
        method: 'GET',
        url: `${route}/teammates/followers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            
        }
    }
    let requests 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        requests = results
    }).catch(err => console.log(err))
    return requests
}

/*2. Accept Teammate Follow Requests */
export const accept_follow_request = async (token, user_id) => {
    const teammateOptions = {
        method: 'POST',
        url: `${route}/teammates/followers/${user_id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            
        }
    }
    let requests 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        requests = results
    }).catch(err => console.log(err))
    return requests
}

/*3. Deny Teammate Follow Requests */
export const deny_follow_request = async (token, user_id) => {
    const teammateOptions = {
        method: 'DELETE',
        url: `${route}/teammates/followers/${user_id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            
        }
    }
    let requests 
    await axios.request(teammateOptions).then(function (response) {
        let results = response.data
        requests = results
    }).catch(err => console.log(err))
    return requests
}