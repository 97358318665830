import * as types from "../types";

const initialState = {teams: {teams: [], nfl: [], nba: [], mlb: [], cfb: [], nhl: []}};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.ADD_TEAMS:
            return {
                ...state,
                teams: payload
            };
        default:
            return state;
    }
}