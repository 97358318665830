import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import StarIcon from '@mui/icons-material/Star';
import {useHistory} from 'react-router-dom'



const TeamCard = (props) => {
    //get props
    const history = useHistory()
    const teams = props.teamsValue

    /*Three */
    const [team, setTeam] = useState(false)

    useEffect(()=>{
        if(props.equity && teams && !team){
            teams.map(t => {
                if(t.team.id === props.equity.asset_id){
                    setTeam(t)
                }
            })
        }
    },[props.teams, props.equity])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        {team && <tr style={{width: '100%',  color: 'gray',}}>
            <td onClick={()=> history.push(`/teams/${team.team.name}`)} style={{width: isMobile ? '30px':'50px'}}>
                <div style={{
                    height: isMobile ? '30px':'50px',
                    width: isMobile ? '30px':'50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#171717',
                    border: '0.25px solid #4B4B4B',
                    margin: '8px',
                }}>
                    <img className="full-img" src={team && `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                </div>
            </td>
            <td onClick={()=> history.push(`/teams/${team.team.name}`)} style={{textAlign: 'left', overflowX: 'visible'}}>
                <div style={{fontSize: '1.1rem', color: 'white', fontWeight: '600'}}>Sim{team && team.team && team.team.name}</div>
                <div style={{fontSize: '.9rem', color: 'darkgray'}}>${team && team.team && team.team.league}-{team && team.team && team.team.abbr} { isMobile ? <span style={{fontSize: '.95rem',paddingLeft: '0px', fontWeight: '400', color: 'rgb(100,100,100)'}}>{' '}|{' '}${team && team.asking_price.toFixed(2)}{' '}</span>:null}</div>
            </td>
            
            {isMobile ? null : <td onClick={()=> history.push(`/teams/${team.team.name}`)}>{props.equity && props.equity.amount.toFixed(4)}</td>}
            <td style={isMobile ? {color: 'darkgray'} : {}} onClick={()=> history.push(`/teams/${team.team.name}`)}>{props.equity && props.equity.amount && team && team.asking_price && `$${(props.equity.amount * team.asking_price).toFixed(2)}`}</td>
            {isMobile ? null : <td onClick={()=> history.push(`/teams/${team.team.name}`)}><span style={{fontSize: '.95rem',paddingLeft: '10px', paddingRight: '10px', paddingTop: '4px', paddingBottom: '4px',backgroundColor: '#212121', color: 'white', borderRadius: '1rem', fontWeight: '600'}}>${team && team.asking_price.toFixed(2)}{' '}</span></td>}
            <td onClick={()=> history.push(`/teams/${team.team.name}`)}><span style={{fontSize: '.95rem', color: team.change && team.change.day >= 0 ? 'rgb(0,200,0)' : 'rgb(200,0,0)', backgroundColor: team.change && team.change.day >= 0 ? 'rgb(0,60,0)' : 'rgb(60,0,0)', borderRadius: '1rem', paddingLeft: '10px', paddingRight: '10px', paddingTop: '4px', paddingBottom: '4px'}}>{team.change && `${team.change.day >= 0 ? '+' : '-'}${Math.abs(team.change.day * 100).toFixed(2)}%`}</span></td>
            {isMobile ? null :<td onClick={()=> history.push({pathname: `/teams/${team.team.name}`, state: { buyNow: true }})} style={{textAlign: 'right'}}><span style={{fontSize: '1.1rem', fontWeight: '600', color: '#c4b454', marginRight: '0px'}}>BUY</span></td>}

        </tr>}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(TeamCard)