import axios from 'axios'
import {route} from '../route'

//1. Team Value Summary
//2. Team Price History
//3. Team Win History

/*1. Team Value Summary */
export const getTeamsValue = async (token) => {
    const teamsOptions = {
        method: 'GET',
        url: `${route}/teams/value_summary`,
    }
    let teams = []
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams
}

/*2. Team Price History */
export const getTeamPriceHistory = async (id, interval, start, end) => {
    let period = interval ? interval : 'DAY'
    const teamsOptions = {
        method: 'GET',
        url: `${route}/teams/${id}/price_history`,
        params: {
            "interval": period,
            "after": start,
        }
    }
    let teams = []
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams
}

/*3. Team Win History */
export const getTeamsWinHistory = async (id) => {
    const teamsOptions = {
        method: 'GET',
        url: `${route}/teams/${id}/win_history`,
    }
    let teams = []
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams

}
