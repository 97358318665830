import axios from 'axios'
import { route } from '../route'

// Login function
export const sendSupportTicket = async (token, content, email) => {
    const loginOptions = {
        method: 'POST',
        url: `${route}/support`,
        params: {
            "content": content,
            "email": email,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let carry 
    await axios.request(loginOptions).then(function (response) {
        carry = response.data
    }).catch(err => carry = err.response)
    return carry
}