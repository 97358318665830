import React, {useState, useEffect, useRef} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import './Trade.css'
import TeamGraph from './TeamGraph'
import {Link, useHistory} from 'react-router-dom'
import {Overlay} from 'react-bootstrap'
import moment from 'moment'
import DownloadTeams from './DownloadTeams'
import { getTeamsValue } from '../Portfolio3/Functions'
import Loader from '../adHoc/Loader'
import '../Account2/Friends/Friends.css'

const FullList = (props) => {
    //get props
    const user = props.user
    const [teams, setTeams] = useState(props.teams)

    const history = useHistory()

    const [searchTeam, setSearchTeam] = useState(props.searchTeam ? props.searchTeam : '')
    useEffect(()=>{
        props.searchTeam && setSearchTeam(props.searchTeam)
    },[props])
    useEffect(()=>{
        let val = props.searchTeam
        if(val === '' || !val){
            setSearchTeam('')
        }
    }, [props])

    let leagues = ['NFL', 'NBA', 'CFB', 'NHL', 'MLB']
    const [league, setLeague] = useState('ALL')
    const [showLeagues, setShowLeagues] = useState(3)

    const [period, setPeriod] = useState('1D')
    const [showTimePeriod, setShowTimePeriod] = useState(false)
    const target = useRef(null);

    const [oneDay, setOneDay] = useState(moment().subtract(2, 'd').format('YYYYMMDD'))
    const [oneWeek, setOneWeek] = useState(moment().subtract(8, 'd').format('YYYYMMDD'))
    const [oneMonth, setOneMonth] = useState(moment().subtract(31, 'd').format('YYYYMMDD'))
    const [threeMonth, setThreeMonth] = useState(moment().subtract(92, 'd').format('YYYYMMDD'))
    const [oneYear, setOneYear] = useState(moment().subtract(365, 'd').format('YYYYMMDD'))

  

    /*useEffect(()=>{
        setTeams(false)
        props.teams.sort(function(a, b) {
            if(period === '1D' && b.prices[oneDay] && a.prices[oneDay]){
                return Math.abs((b.lastSoldPrice - b.prices[oneDay])/b.prices[oneDay]) - Math.abs((a.lastSoldPrice - a.prices[oneDay])/a.prices[oneDay])
            }
            else if(period === '1W' && b.prices[oneWeek] && a.prices[oneWeek]){
                return Math.abs((b.lastSoldPrice - b.prices[oneWeek])/b.prices[oneWeek]) - Math.abs((a.lastSoldPrice - a.prices[oneWeek])/a.prices[oneWeek])
            }
            else if(period === '1M' && b.prices[oneMonth] && a.prices[oneMonth]){
                return Math.abs((b.lastSoldPrice - b.prices[oneMonth])/b.prices[oneMonth]) - Math.abs((a.lastSoldPrice - a.prices[oneMonth])/a.prices[oneMonth])
            }
            else if(period === '3M' && b.prices[threeMonth] && a.prices[threeMonth]){
                return Math.abs((b.lastSoldPrice - b.prices[threeMonth])/b.prices[threeMonth]) - Math.abs((a.lastSoldPrice - a.prices[threeMonth])/a.prices[threeMonth])
            }
            else if(period === '1Y' && b.prices[oneYear] && a.prices[oneYear]){
                return Math.abs((b.lastSoldPrice - b.prices[oneYear])/b.prices[oneYear]) - Math.abs((a.lastSoldPrice - a.prices[oneYear])/a.prices[oneYear])
            }
            else if(period === 'All'){
                return Math.abs((b.lastSoldPrice - b.prices[oneYear])/b.prices[oneYear]) - Math.abs((a.lastSoldPrice - a.prices[oneYear])/a.prices[oneYear])
            }
        })
        setTeams(props.teams)
    }, [period])*/
    

    const getPercentChange = (t) => {
        if(period === '1D'){
            return t.change.day * 100
        }
        else if(period === '1W'){
            return t.change.week * 100
        }
        else if(period === '1M'){
            return t.change.month * 100
        }
        else if(period === '3M'){
            return t.change.threeMonths * 100
        }
        else if(period === '1Y'){
            return t.change.year * 100
        }
        else if(period === 'All'){
            return t.change.total * 100
        }
    }

    useEffect(()=>{
        !props.market && window.scrollTo(0,0)
    },[])
    
    const changePeriod = (period) => {
        setPeriod(period)
        setShowTimePeriod(false)
        !props.market && history.replace(`/Trade/Movers?sort=${period}`)
    }

    useEffect(()=>{
        if(history.location && history.location.search){
            let thisPeriod = history.location.search.substring(history.location.search.length - 2, history.location.search.length)
            if(period !== thisPeriod){
                setPeriod(thisPeriod)
            }
        }
    },[period])

    /*Three */
    const [teams1, setTeams1] = useState(props.teamsValue ? props.teamsValue : [])
    useEffect(()=>{
        if(teams1.length === 0 && props.teamsValue.length > 0){
            setTeams1(props.teamsValue)
        }
    },[props.teamsValue])

    teams1.sort(function(a, b) {
        if(period === '1D'){
            return Math.abs(b.change.day) - Math.abs(a.change.day)
        }
        else if(period === '1W'){
            return Math.abs(b.change.week) - Math.abs(a.change.week)
        }
        else if(period === '1M'){
            return Math.abs(b.change.month) - Math.abs(a.change.month)
        }
        else if(period === '3M'){
            return Math.abs(b.change.threeMonth) - Math.abs(a.change.threeMonth)
        }
        else if(period === '1Y'){
            return Math.abs(b.change.year) - Math.abs(a.change.year)
        }
        else if(period === 'All'){
            return Math.abs(b.change.total) - Math.abs(a.change.total)
        }
    })
    /*End Three */

    return(
        <div className='DesktopContainer' style={{paddingBottom: '10vh'}}>

                <div style={{display: 'inline-block', width: '70%', fontSize: '1.7em', fontWeight: '600', paddingLeft: '2px'}}>Top Movers</div>
                <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '3%', fontSize: '1.5em', fontWeight: '200', color: 'rgb(100,100,100)'}}>
                    <DownloadTeams />{' '}
                    <Link style={{color: 'rgb(90,90,90)'}} ref={target} onClick={()=>{setShowTimePeriod(!showTimePeriod)}}>
                        {period}{' '}<i style={{marginLeft: '5px'}} className="fas fa-ellipsis-v"></i>
                    </Link>
                    <Overlay className="FC-E-Overlay" target={target.current} show={showTimePeriod} placement="bottom">
                        {(props) => (
                            <div className='FC-E-return-div'  id="overlay-example" {...props}>
                                    {/*<button className="FC-E-item-return-item" onClick={()=>{setPeriod('All'); setShowTimePeriod(false)}} >All</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('1Y'); setShowTimePeriod(false)}} >1Y</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('3M'); setShowTimePeriod(false)}} >3M</button>
                                    */}<button className="FC-E-item-return-item" onClick={()=>{changePeriod('1M')}} >1M</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{changePeriod('1W')}} >1W</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{changePeriod('1D')}} >1D</button>
                                
                            </div>
                        )}
                    </Overlay>  </div>          


                {/*<div style={{display: 'inline-block', width: '59%',fontSize: '1.15em', fontWeight: '200', color: 'gray', paddingLeft: '2px'}}>SimNFL</div>
                <Link to="/league/SimNFL"><div style={{display: 'inline-block', width: '39%',fontSize: '1.05em', fontWeight: '200', color: 'gray', paddingRight: '2px', textAlign: 'right'}}>View All {' '}<i className="fas fa-arrow-right"></i></div></Link>*/}
            
            <div style={{ marginBottom: '4px', marginTop: '5px', paddingBottom: '15vh'}}>

                {false && teams && teams.length > 0 && teams.map(t => {
                    if(t.league === league ||  league === 'ALL'){
                        return(
                            <>
                            <Link to={`/Teams/${t.team}`} style={{color: 'black'}}>
                            <div style ={{width: '100%'}}>

                                <div style={{width: '5%',paddingLeft: '1%', display: 'inline-block'}}><img style={{width: '100%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                                {/*<div style={{width: '50%', paddingLeft: '1%', display: 'inline-block',fontSize: '1.1em', fontWeight: '400', }}>Sim{t.team}</div>
                                <div style={{textAlign: 'center',  width: '19%', display: 'inline-block', fontWeight: '200', fontSize: '0.9em'}}>${t.lastSoldPrice.toFixed(2)}</div>
                                <div style={{textAlign: 'right',width: '25%',paddingRight: '5px', display: 'inline-block', fontSize: '1.05em', fontWeight: '300', color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                */}
                                <div className="full-name">Sim{t.team}</div>
                                <div className="full-price">
                                    <span className="full-span" style={{margin: '0', marginRight: '1px'}}>{t.highestBidPrice.toFixed(2)}</span>{' '}
                                    ${t.lastSoldPrice.toFixed(2)}{' '}
                                    <span className="full-span" style={{margin: '0', marginLeft: '1px'}}>{t.lowestAskPrice.toFixed(2)}</span>
                                </div>
                                <div className="full-percent" style={{color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                
                            </div>

                            <hr style={{color: 'rgb(250,250,250)', margin: '2px', padding: '0', fontWeight: '200'}} />
                            </Link>
                            </>
                        )
                    }
                })}

                {(teams1 && teams1.length > 0) ? teams1.map(t => {
                    if(t.team.league === league ||  league === 'ALL'){
                        return(
                            <>
                            <Link to={`/Teams/${t.team.name}`} style={{color: 'black'}}>
                            <div style ={{width: '100%'}}>

                                <div style={{width: '5%',paddingLeft: '1%', display: 'inline-block'}}><img style={{width: '100%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                                {/*<div style={{width: '50%', paddingLeft: '1%', display: 'inline-block',fontSize: '1.1em', fontWeight: '400', }}>Sim{t.team}</div>
                                <div style={{textAlign: 'center',  width: '19%', display: 'inline-block', fontWeight: '200', fontSize: '0.9em'}}>${t.lastSoldPrice.toFixed(2)}</div>
                                <div style={{textAlign: 'right',width: '25%',paddingRight: '5px', display: 'inline-block', fontSize: '1.05em', fontWeight: '300', color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                */}
                                <div className="full-name">Sim{t.team.name}</div>
                                <div className="full-price">
                                    <span className="full-span" style={{margin: '0', marginRight: '1px'}}>{t.bidding_price.toFixed(2)}</span>{' '}
                                    ${t.asking_price.toFixed(2)}{' '}
                                    <span className="full-span" style={{margin: '0', marginLeft: '1px'}}>{t.asking_price.toFixed(2)}</span>
                                </div>
                                <div className="full-percent" style={{color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                
                            </div>

                            <hr style={{color: 'rgb(250,250,250)', margin: '2px', padding: '0', fontWeight: '200'}} />
                            </Link>
                            </>
                        )
                    }
                }):
                    <Loader />
                }

            </div>

            <div className="LeagueFilter">
                <button onClick={()=>setLeague('ALL')} className={league === 'ALL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>All</button>
                <button onClick={()=>setLeague('NBA')} className={league === 'NBA' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNBA</button>
                <button onClick={()=>setLeague('NFL')} className={league === 'NFL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNFL</button>
                <button onClick={()=>setLeague('NHL')} className={league === 'NHL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNHL</button>
                <button onClick={()=>setLeague('CFB')} className={league === 'CFB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimCFB</button>
                <button onClick={()=>setLeague('MLB')} className={league === 'MLB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimMLB</button>
            </div>
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
});

export default connect(mapStateToProps)(FullList)