import React from 'react'
import '../Analysis3/Analysis.css'
import {Link} from 'react-router-dom'

const SimNHLRelease = () => {

    let atlanticTeams = [
        {
            team: 'Bruins',
            high: 45.42,
            
        },
        {
            team: 'Canadiens',
            high: 39.97,
            
        },
        {
            team: 'RedWings',
            high: 26.34,
            
        },
        {
            team: 'Senators',
            high: 27.25,
            
        },
        {
            team: 'PanthersNHL',
            high: 42.69,
            
        },
        {
            team: 'Lightning',
            high: 48.14,
            
        },
        {
            team: 'MapleLeafs',
            high: 46.32,
            
        },
        {
            team: 'Sabres',
            high: 24.52,
        
        },
    ]

    let metroTeams = [
        {
            team: 'Flyers',
            high: 39.06,
            
        },
        {
            team: 'Capitals',
            high: 39.06,
            
        },
        {
            team: 'Devils',
            high: 28.16,
            
        },
        {
            team: 'BlueJackets',
            high: 28.16,
            
        },
        {
            team: 'RangersNHL',
            high: 41.78,
            
        },
        {
            team: 'Hurricanes',
            high: 45.42,
            
        },
        {
            team: 'Islanders',
            high: 41.78,
            
        },
        {
            team: 'Penguins',
            high: 43.60,
            
        },
    ]
    let centralTeams = [
        {
            team: 'Wild',
            high: 42.69,
            
        },
        {
            team: 'Predators',
            high: 34.52,
            
        },
        {
            team: 'Coyotes',
            high: 28.16,
            
        },
        {
            team: 'Blackhawks',
            high: 29.97,
            
        },
        {
            team: 'Blues',
            high: 37.24,
            
        },
        {
            team: 'Stars',
            high: 37.24,
            
        },
        {
            team: 'Avalanche',
            high: 49.96,
            
        },
        {
            team: 'JetsNHL',
            high: 36.33,
            
        },
    ]
    let pacificTeams = [
        {
            team: 'Oilers',
            high: 40.87,
            
        },
        {
            team: 'Ducks',
            high: 29.97,
            
        },
        {
            team: 'Sharks',
            high: 29.97,
            
        },
        {
            team: 'Kraken',
            high: 34.52,
            
        },
        {
            team: 'Flames',
            high: 38.15,
            
        },
        {
            team: 'Canucks',
            high: 31.79,
            
        },
        {
            team: 'KingsNHL',
            high: 33.61,
            
        },
        {
            team: 'GoldenKnights',
            high: 49.05,
          
        },
    ]

    atlanticTeams.sort(function(a, b) {
        return b.high - a.high;
    });
    metroTeams.sort(function(a, b) {
        return b.high - a.high;
    });
    centralTeams.sort(function(a, b) {
        return b.high - a.high;
    });
    pacificTeams.sort(function(a, b) {
        return b.high - a.high;
    });

    return(
        <div className="Article DesktopContainer">
            <div className="anal-title-2 blk-text">SimNHL is Coming <span style={{color: 'green'}}></span></div>
            <div className="anal-sub blk-text">Announcement - By: SimBull - 10/02/2021</div>
            <br />
            <div>
                <a href="#Big10" style={{color: 'black',textDecoration: 'underline'}}>SimAtlantic</a>{' '}|{' '}
                <a href="#SEC" style={{color: 'black',textDecoration: 'underline'}}>SimMetropolitan</a>{' '}|{' '}
                <a href="#ACC" style={{color: 'black',textDecoration: 'underline'}}>SimCentral</a>{' '}|{' '}
                <a href="#Big12" style={{color: 'black',textDecoration: 'underline'}}>SimPacific</a>{' '}|{' '}
            </div>
            <br />
            <div>
                <div>
                    <strong>The SimNHL Initial Share Offering will open Thursday, October 7th at 5pm EST. First 50 Shares will be at 10% OFF, with the following 50 shares at full price.</strong>
                </div>
                <br />
                <div>
                    100 shares of 32 teams will be sold at their initial share offering price so make sure to buy them early before they sell out! The initial share offering period ends October 11th and live trading of the SimNHL market opens October 12th.
                </div>
                <br />
                <div>
                    <strong>Win Payouts will match the SimNBA at $0.10 per share.</strong>
                </div>
                <br />
                <div id="Big10">
                    We are excited to offer you our fifth league on SimBull! As always, we love to hear your feedback so please reach out with any suggestions, questions, or comments.
                </div>
                <br />
                <div>
                    *Limit 10 Shares Per Team Per Person During ISO
                </div>
                <br />
                
                <div>
                    <div id='SEC' className="conferenceDiv">
                        <span className="conferenceTitle">SimAtlantic</span>
                        {atlanticTeams.map(t => {
                            return <li>Sim{t.team} <span style={{fontWeight: '350'}}> {' '}<strike>${(t.high * 1.1).toFixed(2)}</strike></span> <span style={{color: 'green'}}>${t.high} (10% OFF)</span></li>
                        })}
                        </div>
                    <div id="ACC" className="conferenceDiv">
                        <span className="conferenceTitle">SimMetropolitan</span>
                        {metroTeams.map(t => {
                            return <li>Sim{t.team} <span style={{fontWeight: '350'}}> {' '}<strike>${(t.high * 1.1).toFixed(2)}</strike></span> <span style={{color: 'green'}}>${t.high} (10% OFF)</span></li>
                        })}
                    </div>
                    <div id="Big12" className="conferenceDiv">
                        <span className="conferenceTitle">SimCentral</span>
                        {centralTeams.map(t => {
                            return <li>Sim{t.team} <span style={{fontWeight: '350'}}> {' '}<strike>${(t.high * 1.1).toFixed(2)}</strike></span> <span style={{color: 'green'}}>${t.high} (10% OFF)</span></li>
                        })}
                    </div>
                    <div className="conferenceDiv">
                        <span className="conferenceTitle">SimPacific</span>
                        {pacificTeams.map(t => {
                            return <li>Sim{t.team} <span style={{fontWeight: '350'}}> {' '}<strike>${(t.high * 1.1).toFixed(2)}</strike></span> <span style={{color: 'green'}}>${t.high} (10% OFF)</span></li>
                        })}
                    </div>
                    

                </div>

            </div>
        </div>
    )
}

export default SimNHLRelease