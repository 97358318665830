import React, {useState, useEffect} from 'react'
import { CSVLink } from "react-csv";
import { connect } from 'react-redux'

const DownloadTeams = (props) => {

    const teamsValue = props.teamsValue

    const [data, setData] = useState([])

    useEffect(()=>{
        let teams1 = []

        teamsValue.map(t => {

            teams1.push({
                team: `Sim${t.team.name}`,
                league: t.team.league,
                bid: t.bidding_price,
                ask: t.asking_price,
                return1D: `${t.change.day.toFixed(2)}%`,
                return1W: `${t.change.week.toFixed(2)}%`,
                return1M: `${t.change.month.toFixed(2)}%`,
                return3M: `${t.change.three_month.toFixed(2)}%`,
                return1Y: `${t.change.year.toFixed(2)}%`,
                returnAll: `${t.change.total.toFixed(2)}%`,
    
            })

        })

        setData(teams1)

    },[props.teamsValue])

    return(
        <>
         <CSVLink style={{color: 'rgb(80,80,80)'}} data={data} filename={"SimBull_Teams.csv"}><span className="fas fa-download" style={{color: 'rgb(80,80,80)', marginRight: '3px', fontWeight: '200', fontSize: '0.9em'}}></span></CSVLink>
        </>
        
    )
}

const mapStateToProps = (state) => ({
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(DownloadTeams)