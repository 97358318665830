import React, {useState, useEffect} from 'react'
import '../Teammate.css'
import {getTeammateSummary} from '../utils'
import {Link} from 'react-router-dom'
import Follow from '../Follow'

const Teammate = (props) => {


    const [started, setStarted] = useState(false)
    const [summary, setSummary] = useState()//props.s && props.s.username && window.localStorage.getItem(props.s.username)) && JSON.parse(window.localStorage.getItem(props.s.username))
    const [ran, setRan] = useState(false)

    useEffect(async ()=>{
        if(props.token && props.s && started === false){
            setStarted(true)
            let sum = await getTeammateSummary(props.token, props.s.id)
            //console.log(sum)
            setRan(true)
            setSummary(sum)
            //window.localStorage.setItem(props.s.username, JSON.stringify(sum))
        }
        
    },[props.token, props.s])

    let change = (summary && summary.total_return && summary.total_return.final) ? summary.total_return.final : 0

    const switchTeammate = () => {
        let holder = summary ? summary : {teammate: props.s}
        holder.teammate = props.s
        props.changeTeammate(holder)
    }

    return(
        <>

            <Link onClick={switchTeammate}>
            <div className="Teammate">
                <table style={{width: '100%'}}>
                    <tr style={{width: '100%'}}>
                        
                        <td className="teammate-left">{props.s && props.s.username} 
                        <span style={{color: 'gray', fontWeight: '200'}}>{(props.s.first_name !== '' && props.s.first_name !== 'First') ? ` - ${props.s.first_name}` : '' }</span> 
                        </td>
                        
                        <td className="teammate-right">
                            {summary ? <>
                                <div className="teammate-value">${(summary && summary.value) ? summary.value.toFixed(0) : 0}</div> 
                                <span style={{marginLeft: '3px', marginRight: '3px', color: 'gray'}}>|</span>
                                <div className={`teammate-percent ${(change > -.01 && change < 0.01) ? 'graytxt' : change < -0.01 ? 'redtxt' : 'greentxt'}`}>{change < -0.01 ? '-' : '+'}{Math.abs(((change / ((summary.value - change === 0) ? 1000000 : Math.abs(summary.value - change))  ) *100)).toFixed(2)}%</div>
                            </>
                            :
                            <>
                            {ran && <Follow click={true} teammates={props.teammates} rerunTeammates={props.rerunTeammates} changeTeammate={props.changeTeammate} s={props.s} />}
                            </>
                            }
                        </td>
                    </tr>
                </table>
            </div>
            </Link>

        </>
    )
}

export default Teammate