import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import './Trade.css'
import {Link} from 'react-router-dom'

const GOLD = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const report = props.report ? props.report : false

    return(
        <>
        {user && !user.gold && <div className='GOLD' style={{marginTop: '10px'}}>

            <Link to="/Subscription"><div className="recurring" style={{textAlign: 'center', width: '80%', marginLeft: '10%'}}>
                <button style={{border: 'none', outline: 'none', backgroundColor: 'white', fontWeight: '300', fontSize: '1em'}}>
                    {report ? 'Get Analyst Reports with SimBull GOLD' : 'Get Free Trading with SimBull GOLD'}
                </button>
            </div></Link>            

            
            
            
        </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(GOLD)