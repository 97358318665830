import React from 'react'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import {getMyTradesByTeam} from '../../../routes/user/user'

//get an array of labels for the graph
//get an array of the prices for the graph
export const getGraphInfo = (t, data) => {
    let tenure = t && t.prices && Object.keys(t.prices).length - 1
    //let tenure = t.tenure
    let datesArr = []
    let labelArr = []
    let pricesArr = []

    //Start Date of 1Y,1M,1W or 3M
    let startDate = moment().subtract(data !== "3M" ? 1 : 3, data === "1Y" ?  'year' : data === "1M" ? 'month' : data === "1W" ? 'week' : 'months')

    //Current Date
    const currentDate = moment()

    for(let i = tenure; i>0; i--) {
        let d = moment().subtract(i, 'd').format('YYYYMMDD')
        if(data === "ALL") {
            datesArr.push(d)
            labelArr.push(moment().subtract(i, 'd').format('MMM DD'))
            pricesArr.push(t.prices[d] && t.prices[d].toFixed(2))
        } else {
            const giveDate = moment(d,'YYYYMMDD')
            if(giveDate >= startDate && giveDate <= currentDate) {
                labelArr.push(moment().subtract(i, 'd').format('MMM DD'))
                pricesArr.push(t.prices[d] && t.prices[d].toFixed(2))
            }
        }  
    }
    return [pricesArr, labelArr]
}

/* Get My Trades with this Team */
export const getTrades = async (token, team) => {
    if(token && team.team && team.team.id){
        let user_id = jwt_decode(token).sub
        let team_id = team.team.id
        let times = []
        let timesObj = {}
        let transactions1 = []
        let res = await getMyTradesByTeam(user_id, team_id).catch(err => console.log(err))
        let arr = res
        arr.sort((a,b) => {
            if(b.timestamp > a.timestamp){
                return 1
            }
            else return -1
        })
        arr.map(t => {
            if(!times.includes(t.timestamp)){
                times.push(t.timestamp)
                timesObj[t.timestamp] = t
                timesObj[t.timestamp].fullPaid = (t.amount * t.price)
            }
            else{
                timesObj[t.timestamp].fullPaid += (t.amount * t.price)
                timesObj[t.timestamp].amount += t.amount
            }
        })
        times.map(t => {
            transactions1.push(timesObj[t])
        })
        return arr
    }
    else return []
}