import React, {useState, useEffect} from 'react'
import LeftBracket from './LeftBracket'
import RightBracket from './RightBracket'
import FinalFour from './FinalFour'
import BracketHeadings from './BracketHeadings'
import MyTeams from './MyTeams'
import Signup from './Signup'
import {post_picks, get_my_picks} from '../../routes/march_madness/march_madness'
import {sendTeamDetails} from './util'
import Popup from 'reactjs-popup'
import Sticky from 'react-sticky-el'

const Bracket = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [originalPicks, setOriginalPicks] = useState([])
    const [newPicks, setNewPicks] = useState([])
    const [currentPicks, setCurrentPicks] = useState([])
    useEffect(()=>{
        if(props.teams && props.teams.length > 0 && props.token){
            get_my_picks(props.token).then(res => {
                let myPicks = []
                if(res && res.repicks && res.repicks.length > 0){
                    let picks = res.repicks.split(',')
                    picks.map(t => {
                        let team = sendTeamDetails(props.teams, parseInt(t))
                        myPicks.push({
                            team: parseInt(t),
                            seed: parseInt(team.seed)
                        })
                    })
                    if(res.picks){
                        let picks1 = res.picks.split(',')
                        setOriginalPicks(picks1)
                    }
                }
                else if(res && res.picks){
                    let picks = res.picks.split(',')
                    picks.map(t => {
                        let team = sendTeamDetails(props.teams, parseInt(t))
                        myPicks.push({
                            team: parseInt(t),
                            seed: parseInt(team.seed)
                        })
                    })
                }
                setCurrentPicks(myPicks)
                props.sendPicks(myPicks)
            })
        }
    },[props.teams, props.token])

    const makeNewPick = (pick) => {

        const doPick = () => {
            if(pick.team !== null && pick.team !== '' && pick.team !== undefined && pick.seed !== null && pick.seed !== '' && pick.seed !== undefined){
                if (newPicks.some(p => p.seed === pick.seed) || currentPicks.some(p => p.seed === pick.seed)) {

                    let prev 
                    let allPicks = [...newPicks, ...currentPicks]
                    allPicks.map(a => {
                        if(a.seed === pick.seed){
                            prev = a.team
                        }
                    })
                    
                    

                    let newArr = newPicks

                    //change to window.confirm and remove the previous seed if they want to change
                    let ans = window.confirm(`You've already chosen ${allPicks.length > 0 ? sendTeamDetails(props.teams, prev).name : ''} for the ${pick.seed} seed. Do you want to change it to ${sendTeamDetails(props.teams, pick.team).name}?`)
                    if(ans){
                        /*newPicks.map(p => {
                            if(pick.seed === p.seed && pick.team !== p.team){
                                newArr.filter((all) => {return all !== p})
                            }
                        })*/
                        currentPicks.map(p => {
                            if(pick.seed === p.seed && pick.team !== p.team){
                                removePick(p)
                            }
                        })
                        //setNewPicks([...newArr, pick])
                        setNewPicks([...newPicks.filter((p) => {return (p.seed !== pick.seed && p.team !== pick.team)}), pick])
                    }
                }
                else{
                    setNewPicks([...newPicks, pick])
                }   
            }
        }
        console.log(new Date().getTime()/1000)
        if(parseInt(new Date().getTime() / 1000) < 1647532800){
            doPick()
        }
        else if(parseInt(new Date().getTime() / 1000) > 1647532800 && parseInt(new Date().getTime() / 1000) < 1647853200){
            alert(`Sorry the initial picking period is over. Please wait until the re-pick period before the Sweet Sixteen to make your two re-picks.`)
        }
        else if(parseInt(new Date().getTime() / 1000) > 1647853200 && parseInt(new Date().getTime() / 1000) < 1648137600){
            if(newPicks.length < 2){
                doPick()
            }
            else{
                alert(`Sorry you already have two teams selected to be re-picked. Either submit them and re-pick again or refresh the page to re-pick two new teams.`)
            }
            
        }
        else{
            alert(`Sorry you can't make picks or change your picks anymore.`)
        }
    }

    const removePick = (pick) => {
        if(currentPicks.indexOf(pick) !== -1){
            setCurrentPicks(currentPicks.filter((p) => {return p !== pick}))
        }
        /*else if(newPicks.indexOf(pick) !== -1){
            setNewPicks(newPicks.filter((p) => {return p !== pick}))
        }*/
    }

    const [loading, setLoading] = useState(false)

    const submitPicks = async (tok) => {
        if(parseInt(new Date().getTime() / 1000) < 1647532800){
            setLoading(true)
            let ids = []
            currentPicks && currentPicks.map(p => {
                ids.push(p.team)
            })
            newPicks && newPicks.map(p => {
                ids.push(p.team)
            })
            console.log(tok)
            if(tok && tok.access_token){
                await post_picks(tok.access_token, ids, false).then(res => {
                    console.log(res)
                    if(res && res.status && res.status === 200){
                        setLoading(false)
                        setCurrentPicks([...currentPicks, ...newPicks])
                        props.sendPicks([...currentPicks, ...newPicks])
                        setNewPicks([])
                        
                    }
                })
                return true
            }
            else if(props.token){
                post_picks(props.token, ids, false).then(res => {
                    if(res.status === 200){
                        setLoading(false)
                        setCurrentPicks([...currentPicks, ...newPicks])
                        setNewPicks([])
                        
                    }
                    
                })
            }
        }
        else if(parseInt(new Date().getTime() / 1000) > 1647532800 && parseInt(new Date().getTime() / 1000) < 1647853200){
            alert(`Sorry the initial picking period is over. Please wait until the re-pick period before the Sweet Sixteen to make your two re-picks.`)
        }
        else if(parseInt(new Date().getTime() / 1000) > 1647853200 && parseInt(new Date().getTime() / 1000) < 1648137600){
            setLoading(true)
            let ids = []
            currentPicks && currentPicks.map(p => {
                ids.push(p.team)
            })
            newPicks && newPicks.map(p => {
                ids.push(p.team)
            })
            if(props.token){
                post_picks(props.token, ids, true).then(res => {
                    if(res.status === 200){
                        setLoading(false)
                        setCurrentPicks([...currentPicks, ...newPicks])
                        setNewPicks([])
                        
                    }
                    else {
                        alert(`Whoops. You've already chosen your two repicks. If you would like to change them, change your re-picks back to the original pick and you'll be able to re-pick new teams.`)
                    }
                }).catch(err => console.log(err.msg))
            }
            else if(tok && tok.access_token){
                await post_picks(tok.access_token, ids, true).then(res => {
                    if(res.status === 200){
                        setLoading(false)
                        setCurrentPicks([...currentPicks, ...newPicks])
                        setNewPicks([])
                        
                    }
                })
                return true
            }
        }
        else{
            alert(`Sorry you can't make or change your picks anymore.`)
        }
        
       
    }


    let regions = props.regions
    let rounds = props.rounds
    let roundsRight = props.roundsRight
    let finalRounds = props.finalRounds
    let games = props.games

    const [showTeams, setShowTeams] = useState(false)

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
        setScrolling(e.target.documentElement.scrollTop > scrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    },[]);

    const [showPicker, setShowPicker] = useState(false)

    useEffect(() => {
        if(!isMobile){
            if(scrollTop > window.innerHeight * 1.1 && !showPicker){
                setShowPicker(true)
            }
            else if(scrollTop < window.innerHeight * 1.1 && showPicker) {
                setShowPicker(false)
            }
        }   
        else{
            if(scrollTop > window.innerHeight * 1.3 && !showPicker){
                    setShowPicker(true)
            }
            else if(scrollTop < window.innerHeight * 1.3 && showPicker) {
                setShowPicker(false)
            }
        }
       
    }, [scrollTop])

    return(
        <>
        <div style={{backgroundColor: 'rgb(240,240,240)', paddingTop: '5px', fontFamily: 'Roboto Slab, serif'}}>

            <MyTeams originalPicks={originalPicks} teams={props.teams} newPicks={newPicks} currentPicks={currentPicks} makeNewPick={makeNewPick} submitPicks={submitPicks} my={props.my} games={games} />
            {/*<Signup />*/}
                {showPicker && <div className={props.token ? "viewpicks" : 'viewpicks_nouser'} id="vp">
                    <button onClick={()=>setShowTeams(!showTeams)} style={{border: 'none', outline: 'none', borderRadius: '.3rem', border: '1px solid #c5b358', fontWeight: '500', background: 'rgba(224, 224, 224, 1)', padding: '5px 10px 5px 10px',boxShadow: 'rgba(0, 0, 0, 0.8) 0px 2px 10px',}}>
                        <span><i class="fa fa-arrow-down" style={{fontSize: '1em', marginRight: '5px', fontWeight: '400'}}></i>My Picks</span>
                    </button>
                </div>}


            <Popup open={showTeams} modal>
                <div style={{background: 'rgba(240,240,240, 1)', margin: '5px', padding: '5px', boxShadow: 'rgba(0, 0, 0, 0.8) 0px 20px 200px', borderRadius: '.3em'}}>
                    <MyTeams originalPicks={originalPicks} secondPop={true} teams={props.teams} newPicks={newPicks} currentPicks={currentPicks} makeNewPick={makeNewPick} submitPicks={submitPicks} my={props.my} games={games} />
                    <div style={{textAlign: 'center'}}>
                        <button onClick={()=>setShowTeams(false)} style={{backgroundColor: 'rgb(240,240,240)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                    </div>
                </div>
            </Popup>
            

            <hr style={{marginBottom: '10px', marginTop: '5px', marginLeft: isMobile ? '10px': '30px', marginRight: isMobile ? '10px': '30px',}}  />

            <BracketHeadings />

            <hr style={{marginBottom: '0px', marginTop: '12px', marginLeft: isMobile ? '10px': '30px', marginRight: isMobile ? '10px': '30px',}}  />


            {props.teams.length > 0 ? <>
            

            {/*<div style={{textAlign: 'center', fontSize: '1.25rem', fontWeight: '300', color: 'black'}}>Click on new teams to re-pick two of your seeds before the Sweet Sixteen starts!</div>*/}
            
            <div style={{padding: isMobile ? '10px': '30px', paddingTop: '5px'}}>
                <div style={{display: 'inline-block', width: `${isMobile ? '99%' : '49%'}`, marginRight: '1%'}}>
                    <div style={{fontSize: '2rem', fontWeight: '500'}}>West Region</div>
                    <LeftBracket 
                        originalPicks={originalPicks}
                        rounds={rounds} 
                        games={games} 
                        region={'West'}
                        newPicks={newPicks}
                        currentPicks={currentPicks}
                        makeNewPick={makeNewPick}
                        removePick={removePick}
                        teams={props.teams}
                     />
                </div>
                <div style={{display: 'inline-block', width: `${isMobile ? '99%' : '49%'}`,marginLeft: '1%'}}>
                    <div style={{textAlign: 'right', fontSize: '2rem', fontWeight: '500'}}>Midwest Region</div>
                    <RightBracket 
                        originalPicks={originalPicks}
                        rounds={roundsRight} 
                        games={games} 
                        region={'Midwest'} 
                        newPicks={newPicks}
                        currentPicks={currentPicks}
                        makeNewPick={makeNewPick}
                        removePick={removePick}
                        teams={props.teams}
                    />
                </div>
                
                {!isMobile && <FinalFour
                    originalPicks={originalPicks}
                    rounds={['Final Four']}  
                    games={games} 
                    newPicks={newPicks}
                    currentPicks={currentPicks}
                    makeNewPick={makeNewPick}
                    removePick={removePick}
                    teams={props.teams}
                 />}

                <div style={{display: 'inline-block', width: `${isMobile ? '99%' : '49%'}`, marginRight: '1%'}}>
                    <div style={{fontSize: '2rem', fontWeight: '500'}}>East Region</div>
                    <LeftBracket 
                        originalPicks={originalPicks}
                        rounds={rounds}  
                        games={games} 
                        region={'East'}
                        newPicks={newPicks}
                        currentPicks={currentPicks}
                        makeNewPick={makeNewPick}
                        removePick={removePick}
                        teams={props.teams}
                     />
                </div>
                <div style={{display: 'inline-block', width: `${isMobile ? '99%' : '49%'}`, marginLeft: '1%'}}>
                    <div style={{textAlign: 'right', fontSize: '2rem', fontWeight: '500'}}>South Region</div>
                    <RightBracket 
                        originalPicks={originalPicks}
                        rounds={roundsRight} 
                        games={games} 
                        region={'South'} 
                        newPicks={newPicks}
                        currentPicks={currentPicks}
                        makeNewPick={makeNewPick}
                        removePick={removePick}
                        teams={props.teams}
                    />
                </div>

                {isMobile && <FinalFour
                    originalPicks={originalPicks}
                    rounds={['Final Four']}  
                    games={games} 
                    newPicks={newPicks}
                    currentPicks={currentPicks}
                    makeNewPick={makeNewPick}
                    removePick={removePick}
                    teams={props.teams}
                 />}
            </div>

            </>
            : 
            <div style={{textAlign: 'center', padding: '10%'}}>Make your picks once the entire bracket is released!</div>
            }

        </div>
        </>
    )
}

export default Bracket