import React, {useState} from 'react'
import './styles.css'
import {useHistory} from 'react-router-dom'
import CheckEmail from './CheckEmail'
import ResetPassword from './ResetPassword'
import {email_capture} from '../../../routes/auth/reset_password'
import '../Account.css'

const EmailCapture = () => {

    const history = useHistory()

    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)

    const sendInstructions = () => {
        if(email.length > 4 && email.includes('@') && email.includes('.')){
            email_capture(email).then(res => console.log(res))
            setEmailSent(true)
        }
        else alert('Error, please try again with a better email')
    }

    const anotherOne = () => {setEmailSent(false);setEmail('')};

    return(
        <>
        {emailSent ? <CheckEmail email={email} anotherOne={anotherOne} /> : 
        <div className="email-capture DesktopContainer" style={{backgroundColor: 'black', minHeight: '100vh'}}>
            <button onClick={()=>{return history.push('/Login')}} className="back-btn" style={{backgroundColor: 'black', outline: 'none', border: 'none'}}>Back</button>
            <h1 className="reset-head" style={{color: 'white'}}>Reset Password</h1>
            <p className="reset-body">Enter the email associated with your account and we'll send you an email with instructions to reset your password. </p>
            <div className="reset-input-head">Email</div>
            <div className="reset-input-div">
                <input className="reset-input" value={email} onChange={(e)=>setEmail(e.target.value)}  />
            </div>
            <div className="reset-input-div">
                <button className="reset-input-btn" onClick={sendInstructions}>Send Instructions</button>
            </div>
        </div>
        }
        </>
        
    )
}

export default EmailCapture