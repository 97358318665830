import React, {useState, useEffect} from 'react'
import Popup from 'reactjs-popup'
import Signup from './Signup'
import { connect } from 'react-redux'
import {sendTeamDetails, hasLoss, isOriginal} from './util'
import MyPosition from './MyPosition'
import {Link} from 'react-router-dom'


const MyTeams = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);
    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });


    const [token, setToken] = useState(props.token)
    const [signup, setSignup] = useState(false)
    useEffect(()=>{if(props.token !== token){setToken(props.token)}},[props.token])

    const [seed, setSeed] = useState('')
    const [showSeed, setShowSeed] = useState(false)
    
    const teams = props.teams
    const makeNewPick = props.makeNewPick
    const addTeam = (team_id, seed) => {
        makeNewPick({
            team: team_id,
            seed: seed,
        })
        setShowSeed(false)
    }

    const [myTeams, setMyTeams] = useState([])
    useEffect(()=>{
        if(props.teams && props.teams.length > 0){
            let fullArr = [{seed: 1}, {seed: 2}, {seed: 3}, {seed: 4}, {seed: 5}, {seed: 6}, {seed: 7}, {seed: 8}, {seed: 9}, {seed: 10}, {seed: 11}, {seed: 12}, {seed: 13}, {seed: 14}, {seed: 15}, {seed: 16},]
            let newArr = []
            if(props.newPicks.length > 0){
                props.newPicks.map(obj => newArr.push({ ...obj, type: 'new' }))
            }
            if(props.currentPicks.length > 0){
                props.currentPicks.map(obj => newArr.push({ ...obj, type: 'current' }))
            }
            newArr.sort((a, b) => {
                return a.seed - b.seed;
            })
            newArr.map(a => {
                fullArr[a.seed - 1] = a
            })
            setMyTeams(fullArr)
        }
    },[props.newPicks, props.currentPicks])

    const enterPicks = () => {
        if(props.token){
            props.submitPicks()
        }else{
            setSignup(true)
        }
    }

    const checkStatus = (team) => {
        let status = 'none'
        myTeams && myTeams.length > 0 && myTeams.map(t => {
            if(t.team === team.id){
                status = t.type
            }
        })
        return status
    }

    return(<div style={{paddingLeft: isMobile ? '1px': '15px', paddingRight: isMobile ? '1px': '15px', paddingTop: '0px'}}>
    
        {!token && !props.secondPop && <Signup visible={true} submit={true} submitPicks={props.submitPicks} />}

        <MyPosition games={props.games} my={props.my} />

        {/*<div style={{paddingLeft: '15px', fontSize: '1.5em', fontWeight: '500'}}>My Teams</div>*/}
        <div style={{marginTop: '5px'}}>
            {props.teams && props.teams.length > 0 && myTeams && myTeams.map(t => {
                if(t.team){
                    let team = sendTeamDetails(props.teams, t.team)
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '49%' : '24%', marginLeft: '.5%', marginRight: '.5%', backgroundColor: 'white', borderRadius: '.2em', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: '5px'}}>
                            <Link onClick={()=>{setSeed(team.seed);setShowSeed(true)}}>
                            <table style={{width: '100%', color: 'black'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.9em', color: 'gray',width: isMobile ? '8px':'20px', paddingRight: '3px'}}>{team.seed}</td>
                                    <td style={{whiteSpace: 'nowrap', fontSize: isMobile ? '.8em' : '1em'}}>{team.name} {' '} {!isMobile && <span style={{fontSize: isMobile ? '.75em':'.9em', fontWeight: '200'}}>{t.region ? `(${t.region})`: ''}</span>}</td>
                                    <td style={{color: t.type === 'current' ? (hasLoss(props.games, t.team) ? 'red' : 'green') : 'orange', fontSize: isMobile ? '.6em' : '.75em', textAlign: 'right'}}>{t.type === 'new' ? 'Pending' : (hasLoss(props.games, t.team) ? 'Lost' : isOriginal(props.originalPicks, t.team) ? 'Active' : 'Re-Pick')}</td>
                                </tr>
                            </table>
                            </Link>
                        </div>
                    )
                }
                else{
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '49%' : '24%', marginLeft: '.5%', marginRight: '.5%', backgroundColor: 'white', borderRadius: '.2em', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: '5px'}}>
                            <Link onClick={()=>{if(!props.secondPop){setSeed(t.seed);setShowSeed(true)}}}>
                            <table style={{width: '100%', color: 'black'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.9em', color: 'gray',width: isMobile ? '8px':'20px', paddingRight: '3px'}}>{t.seed}</td>
                                    <td style={{whiteSpace: 'nowrap', fontSize: isMobile ? '.8em' : '1em'}}> {' '} {!isMobile && <span style={{fontSize: isMobile ? '.75em':'.9em', fontWeight: '200'}}>{t.region ? `(${t.region})`: ''}</span>}</td>
                                    <td style={{color: 'red', fontSize: isMobile ? '.6em' : '.75em', textAlign: 'right'}}>-</td>
                                </tr>
                            </table>
                            </Link>
                        </div>
                    )
                }
            })}

            {props.newPicks && props.newPicks.length > 0 && <div style={{textAlign: 'center'}}>
                <button className="picks-submit-btn" onClick={enterPicks}>Submit Picks</button>
            </div>}
        </div>

        <Popup open={signup} modal>
            <div className='signup_modal' style={{ backgroundColor: 'rgb(250,250,250)', border: '1px solid black', boxShadow: '0 0 1px 2px #c5b358',}}>
                <div style={{width: '100%', marginLeft: '0%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>


                    <div style={{textAlign: 'center', fontWeight: '600', fontSize: '1.5rem', padding: '10px'}}>You're almost ready to go...<br /><span style={{fontSize: '1.25rem'}}>We just need to attach your picks to an account.</span></div>

                    <Signup submit={true} submitPicks={props.submitPicks} />
                
                    <div style={{textAlign: 'center'}}>
                        <button onClick={()=>setSignup(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                    </div>
                </div>
                
            </div>
        </Popup>

        <Popup open={showSeed} modal>
            <div className='signup_modal' style={{ backgroundColor: 'white', border: '1px solid rgb(250,250,250)', boxShadow: 'rgba(0, 0, 0, 0.8) 0px 20px 200px', borderRadius: '.3rem'}}>
                <div style={{width: '100%', marginLeft: '0%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>


                    <div style={{textAlign: 'center', fontWeight: '300', fontSize: '1.5rem', padding: '10px'}}>Select your {seed} seed</div>

                    {teams && teams.length > 0 && teams.map(t => {
                        if(t.seed === seed){
                            console.log(t)
                            console.log(props.games)
                            return(
                                <button onClick={()=>addTeam(t.id,t.seed)} style={{border: '1px solid rgb(200,200,200)', borderRadius: '.3rem', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', outline: 'none', backgroundColor: 'white', width: '80%', marginBottom: '10px', padding: '10px'}}>
                                    <table style={{width: '100%', color: 'black', textAlign: 'left'}}>
                                        <tr style={{width: '100%'}}>
                                            <td style={{textAlign: 'center', fontSize: '.9em', color: 'gray',width: isMobile ? '8px':'20px', paddingRight: '3px'}}>{seed}</td>
                                            <td style={{whiteSpace: 'nowrap', fontSize: isMobile ? '1em' : '1.1em'}}>{t.name} {' '} {!isMobile && <span style={{fontSize: isMobile ? '.9em':'1.1em', fontWeight: '200'}}>{t.region ? `(${t.region})`: ''}</span>}</td>
                                            <td style={{color: checkStatus(t) === 'new' ? 'orange' : checkStatus(t) === 'current' ? (hasLoss(props.games, t.id) ? 'red' : 'green') : 'black', fontSize: isMobile ? '.6em' : '.75em', textAlign: 'right'}}>{checkStatus(t) === 'new' ? 'Pending' :  checkStatus(t) === 'current' ? (hasLoss(props.games, t.id) ? 'Lost' : 'Active') : (hasLoss(props.games, t.id) ? 'Out' : 'In')}</td>
                                        </tr>
                                    </table>
                                </button>
                            )
                        }
                    })}
                
                    <div style={{textAlign: 'center'}}>
                        <button onClick={()=>setShowSeed(false)} style={{backgroundColor: 'white', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                    </div>
                </div>
                
            </div>
        </Popup>

    
    </div>)
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    totals: state.totals.totals
});

export default connect(mapStateToProps)(MyTeams)
