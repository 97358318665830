import React, {useState, useEffect} from 'react'
import '../TeammateItem.css'
import moment from 'moment'
import {getUsername, getTeam, getTeammateObj, getTeammateObj_teammate, following_by_id, following, public_by_id, getTeamChange} from '../utils'
import {Link} from 'react-router-dom'

const Trade = (props) => {

    const item = props.item

    const [name, setName] = useState()

    const [teammate, setTeammate] = useState()//props.username && window.localStorage.getItem(props.username)) && JSON.parse(window.localStorage.getItem(props.username))

    useEffect(()=>{
        if(props.teamsValue && props.teamsValue.length > 0 && item){
            setName(getTeam(item.asset_id, props.teamsValue))
        }
    },[props.teamsValue])

    let isBuyer = following_by_id(item.buyer_id, props.teammates)

    let isSimBull = (id) => {if(id !== 0){return false}else return true}

    const feedPerson = (item) => {
        let isSeller = isSimBull(item.seller_id)
        let isBuyer2 = isSimBull(item.buyer_id)
        if(!isSeller && !isBuyer2){
            if(item.type === 'BUY'){
                return item.buyer_id
            }
            else return item.seller_id
        }else{
            if(isBuyer2){
                return item.seller_id
            }
            else return item.buyer_id
        }
    }

    useEffect(async ()=>{
        if(!teammate){
            let id = feedPerson(item)
            let mate
            if(props.publicAccounts){
                props.publicAccounts.map(a => {
                    if(a.teammate.id === id){
                        mate = a
                    }
                })
                setTeammate(mate)
            }
        }
    },[props.publicAccounts])
    useEffect(async ()=>{
        /*if(props.feed !== 'all' && !following(props.username, props.teammates) && !following_by_id(isBuyer ? item.buyer_id : item.seller_id, props.teammates) && !following_by_id(feedPerson(item), props.teammates) && !public_by_id(feedPerson(item), props.publicAccounts) && !teammate && !props.publicAccounts){
            let sum = await getTeammateObj(feedPerson(item), false, props.token)
            if(sum && sum.username){window.localStorage.setItem(sum.username, JSON.stringify(sum))}
            console.log(sum)
            setTeammate(sum)
        }*/
    },[])

    useEffect(()=>{
        if(!teammate){
            if(props.feed !== 'all' && props.feed !== 'profile'){
                if(props.teammates){
                    let mate = getTeammateObj_teammate(isBuyer ? item.buyer_id : item.seller_id, props.teammates)
                    setTeammate(mate)
                }
            }
        }
    })

    const switchTeammate = () => {
        let holder = teammate
        holder.teammate = {id: feedPerson(item)}
        props.changeTeammate(holder)
    }

    let team = getTeamChange(item.asset_id, props.teamsValue)

    return(
        <div className="teammate-item-card">
            <table style={{width: '100%'}}>
                <tr style={{width: '100%'}} className="tic-user">
                    <td>
                        {(props.feed && props.feed === 'all') ? 
                            <>
                            {!teammate && <span>private user</span>}
                            <Link style={{color: 'black'}} onClick={switchTeammate}>
                                
                                {teammate && teammate.username} 
                                {' '}
                                
                                    <span style={{fontWeight: '200', fontSize: '.8em'}}>{teammate && `$`}{teammate &&teammate.value && teammate.value.toFixed(0)} 
                                    {' '}
                                    {teammate && teammate.one_day_return && <span style={{fontSize: '.9em', color: `${teammate.one_day_return.change === 0 ? 'gray' : teammate.one_day_return.change < 0 ? 'red' : 'green'}`}}>[ {teammate.one_day_return.change < 0 ? '-' : '+'}{Math.abs((teammate.one_day_return.change/teammate.value) * 100).toFixed(2)}% Today ]</span>}
                                    </span>
                                
                            </Link>    
                            </>
                        :
                            <Link style={{color: 'black'}} onClick={props.feed !== 'profile' ? ()=>props.changeTeammate(getTeammateObj_teammate(isBuyer ? item.buyer_id : item.seller_id, props.teammates)) : ()=>{return true} }>
                                {props.username 
                                    ? 
                                    props.username 
                                    : 
                                    <>
                                    {getUsername(isBuyer ? item.buyer_id : item.seller_id, props.teammates)}
                                    {' '}
                                
                                    <span style={{fontWeight: '200', fontSize: '.8em'}}>${teammate &&teammate.value && teammate.value.toFixed(0)} 
                                    {' '}
                                    {teammate && teammate.one_day_return && <span style={{fontSize: '.9em', color: `${teammate.one_day_return.change === 0 ? 'gray' : teammate.one_day_return.change < 0 ? 'red' : 'green'}`}}>[ {teammate.one_day_return.change < 0 ? '-' : '+'}{Math.abs((teammate.one_day_return.change/teammate.value) * 100).toFixed(2)}% Today ]</span>}
                                    </span>
                                    </>
                                }
                            </Link>    
                        }
                    </td>
                    <td style={{textAlign: 'right'}}>{props.teammateID  ? ((props.teammateID && props.teammateID === item.buyer_id) ? <i class="fa fa-plus" style={{color: 'green'}}></i> : <i class="fa fa-minus" style={{color: 'red'}}></i>) : feedPerson(item) === item.buyer_id ? <i class="fa fa-plus" style={{color: 'green'}}></i> : <i class="fa fa-minus" style={{color: 'red'}}></i>}</td>
                </tr>
            </table>

            <div className="tic-body">
                <span>{props.teammateID  ? ((props.teammateID && props.teammateID === item.buyer_id) ? 'Bought' : 'Sold') :  feedPerson(item) === item.buyer_id ? 'Bought' : 'Sold'}{' '}</span>
                {item.amount && item.amount.toFixed(2)}{' '}
                shares of {' '}
                    <strong><Link style={{color: 'black'}} to={`/Teams/${getTeam(props.item.asset_id, props.teamsValue)}`}>
                        Sim{name ? name : getTeam(item.asset_id, props.teamsValue)}</Link> {' '} {team ? <span style={{fontSize: '.8em', fontWeight: '300', color: `${team.change.day === 0 ? 'gray' : team.change.day < 0 ? 'red' : 'green'}`}}>[{team.change.day < 0 ? '-' : '+'}{Math.abs((team.change.day/team.asking_price) * 100).toFixed(2)}%] </span> : ''}
                        {' '}
                                
                        
                    </strong>
                {' '}for {' '}
                <strong>${item.price && item.price.toFixed(2)}{' '}</strong>
                per share.
                
                
            </div>

            <div className="tic-date">{moment(`${item.timestamp}.000Z`).format('MM/DD/YY HH:mm')}</div>

        </div>
    )
}

export default Trade