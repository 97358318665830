import React, {useState, useEffect} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Reviews.css'

const Reviews = () => {

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    let twitterPic = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fdave-testimonial-twitter-guy.png?alt=media&token=b7c3b517-942e-4c3e-bd13-a30f476515d5'
    let pffPic = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fpff-logo.png?alt=media&token=c5230ca7-539d-4392-b977-302af61be551'
    let gbetaPic ='https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fsharing-07-removebg-preview.png?alt=media&token=220748c5-03ca-4e3a-bd3e-3d6292f9d93f'
    let i80Pic = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Fsimple-w-tagline-bw-350w.png?alt=media&token=731c53c8-87ac-410c-9569-74ba24f3679f'
    let tiktokPic = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ftiktok-modern-logo-icon-on-transparent-background-png-removebg-preview.png?alt=media&token=c11cdf33-76d0-49ea-987c-f1de68314a4f'
    let fanatics = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ffanaticslogo.047a87b7.png?alt=media&token=b60176ef-18f3-4bcd-99f6-b113d2748047'

    const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button onClick={() => onClick()} />;
    };

    return(<>
        <div className="Reviews2">
            <div className='rev-title'><span style={{whiteSpace: "pre", color: "#c4b454"}}>The thrill of sports betting</span><span>&nbsp;with the&nbsp;</span><span style={{whiteSpace: "pre", color: "#c4b454"}}>profitability of the stock market.</span></div>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                //containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                //className="transition"
            >
                <div className="review-card">
                    <div className="review-body">"Our friends at Simbull have created a stock market for sports, allowing you to<strong>trade teams like stocks and even earn cash payouts when your teams win.</strong>  It is the <strong>perfect blend of sports and the stock markert </strong>and offers a brand-new way to <strong>invest in your favorite teams and profit off their success,</strong> making it one of the <strong>most exciting</strong> and <strong>innovative ways to speculate on football."</strong></div>
                    <div className="review-author">PRO FOOTBALL FOCUS (PFF)</div>
                </div>
                <div className="review-card">
                    <div className="review-body">"Dream come true being able to bet on sports teams but in a stock market format. Genius Idea."</div>
                    <div className="review-author">@JACOBJAMESMILLER, SIMBULL USER</div>
                </div>
                <div className="review-card">
                    <div className="review-body">"SimBull is a great alternative to sports betting and i'm enjoying the longer thrill of investing in my favorite up and coming teams. The win payours are a great touch as well. A very nice platform and idea that i'm super excited to see grow!"</div>
                    <div className="review-author">@SKYBRIDGEMBC, SIMBULL USER</div>
                </div>
                <div className="review-card">
                    <div className="review-body">"SimBull is the most fun way that i've found to make following my favorite teams more "interesting", and the payoffs seem to be virtually immediate. I'm surprised there aren't a lot more users of SimBukll!"</div>
                    <div className="review-author">@JEREMYFROMBOISE, SIMBULL USER</div>
                </div>
                
                
            </Carousel>

            
        </div>
    </>)

}

export default Reviews