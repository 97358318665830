import bengals_graph_learn from '../../assets/bengals_graph_learn.png'
import bengals_black from '../../assets/bengals_black.png'
import bengals_white from '../../assets/bengals_white.png'
import browns_black from '../../assets/browns_black.png'
import browns_white from '../../assets/browns_white.png'
import packers_black from '../../assets/packers_black.png'
import packers_white from '../../assets/packers_white.png'
import bears_black from '../../assets/bears_black.png'
import bears_white from '../../assets/bears_white.png'
import suns_black from '../../assets/suns_black.png'
import suns_white from '../../assets/suns_white.png'

export const _bits = [
    {
        title: 'SimBengals share price soars in 2021.',
        paragraphs: [`The Bengals have been volatile the last few years on the field and in the SimBull market. When SimBull launched in 2020, the SimBengals were HOT with the hype around 1st overall pick Joe Burrow. They quickly rose from $14.84 on September 8th, 2020 to over $21.25 on November 5th, 2020, however, the 43% increase in share price wouldn't last. Joe Burrow tore his ACL in week 11 of the 2020 season, the Bengals future was in doubt, and the SimBengals fell to a low of $9.00 in December of 2020.`, `Then, the SimBengals bull run started. Word leaked that Burrow had a successful reconstructive surgery and the concern about lasting affects went away. By the 2021 NFL draft, the SimBengals had soared to $32.35 per share and jumped to $45.83 after selecting WR Ja’Maar Chase 5th overall. It didn’t stop there. The SimBengals continued rising through the season, peaking at over $65.02 and taking over the top spot in the NFL market from the SimChiefs. `,],
        images_black: [bengals_black],
        images_white: [bengals_white],
        team: 'Bengals'
    },
    {
        title: 'SimBrowns price takes a hit with Deshaun Watson suspension looming.',
        paragraphs: [`The Browns made a historic trade for former Texans QB Deshaun Watson sending three 1st round picks and two 3rd round picks to the Texans in exchange for pro bowl QB. The SimBrowns quickly became the hottest team on the market rising from $38.86 on March 12th to $46.44 on March 31st, a 20% gain in just under 3 weeks. `, `Those gains didn’t last. Rumors started swirling that Watson would be suspended form the NFL indefinitely with a minimum of one year because of the 20+ sexual misconduct allegations looming over the QB’s head. The SimBrowns saw a sharp decline in share price falling to $36.82 as of June 28th, 2022.`, ],
        images_black: [browns_black],
        images_white: [browns_white],
        team: 'Browns'
    },
    {
        title: 'Rodgers uncertain future worries investors.',
        paragraphs: [`Aaron Rodgers won back to back MVP's in 2020 and 2021, but that hasn't come without stress for Packers fans. During the 2020 NFL draft, rumors swirled that Aaron Rodgers was not going to play for the Green Bay Packers ever again. That turned out to be incorrect, but a disgruntled Rodgers expressed his displeasure with the Packers front office. After winning the 2020 MVP but getting bounced in the NFC championship yet again, Rodgers future in Green Bay was uncertain. He ended up renegotiating his deal and signing an extension that tied him to Green Bay for at least 2 more years. SimBull investors are still uncertain about his future creating a volatile SimPackers share price the past 12 months.`, `Just like investors in the stock market, SimBull users like certainty and there is nothing certain surrounding Aaron Rodgers. The SimPackers enter the 2022 season as the 14th highest priced team in the NFL, despited having a back to back MVP at QB.`,],
        images_black: [packers_black],
        images_white: [packers_white],
        team: 'Packers'
    },
    {
        title: 'Bears trade up for Justin Fields, rise over 8%.',
        paragraphs: [`The Chicago Bears made the biggest move of the 2021 NFL draft, moving up 9 spots to take Ohio State QB Justin Fields with the 11th overall pick. The SimBull market LOVED the trade. The SimBears rose to $34.15 from $24.85, a 37% increase in just over 10 days.`, `The verdict is still out if Justin Fields is the QB of the future, but if he is, expect the SimBears to have a bullish rise in 2022.`,],
        images_black: [bears_black],
        images_white: [bears_white],
        team: 'Bears'
    },
    {
        title: 'Suns end with NBA best 64 wins. Share price sky rockets.',
        paragraphs: [`The Suns were the most impressive team in the NBA all season long and the SimSuns share price on SimBull reflected it. Coming off an NBA finals loss in June of 2020, the SimSuns were valued at $35.21 heading into the 2021 NBA season. That quickly changed and the SimSuns exploded to over $48 and overtaking the SimBucks as the highest priced team on the market.`, `The future of Deandre Ayton is uncertain, but the SimSuns remain one of the top teams in the NBA market with Chris Paul and Devin Booker leading the charge.`,],
        images_black: [suns_black],
        images_white: [suns_white],
        team: 'Suns'
    },
    /*{
        title: 'Early season Bulls don’t last. Share price sinks.',
        paragraphs: [`The Chicago Bulls were one of the biggest surprises in the NBA, sitting atop the Eastern Conference at the all-star break. New addition DeMar Derozan and all-star Zach Lavine led the Bulls to an impressive 38-21 record, driving the SimBull’s share price from $30.83 to $43.70.`, `That’s when the wheels started to fall off. The Bulls fell from the top seed to the play-in game and ended up falling to the Bucks in the first round of the playoffs. Their share price on SimBull followed suit, falling to $29.88 at the beginning of May.`,],
        images: [bengals_graph_learn],
    },
    {
        title: 'Celtics heroic rise provides value on SimBull.',
        paragraphs: [`The Celtics came up short in the NBA finals, but SimCeltics owners on SimBull are still happy. After a slow start to the season, the Celtics posted the best record in the NBA after the all-star break and their share price rose from $32.90 to $39.08 during that span.`, `The young core core of Tatum and Brown has SimBull users excited and the Celtics remain the 6th highest priced team in the NBA.`,],
        images: [bengals_graph_learn],
    },
    {
        title: 'Dodgers payroll lifts share price on SimBull.',
        paragraphs: [`The Dodgers routinely have the highest payroll in baseball and it has paid off in the SimBull market. Los Angeles is the highest priced team in the MLB at $41.88 and have maintained a steady share price over the past 6 months.`, `The Dodgers willingness to pay for stars gives SimBull users confidence they will be in contention every year for a World Series win. `, ],
        images: [bengals_graph_learn],
    },
    {
        title: 'Yankees fastest to 50 wins.',
        paragraphs: [`The Yankees had one of the best starts in MLB history, becoming the 2nd fastest team to 50 wins behind the 2001 Seattle Mariners. Aaron Judge is playing at an MVP level, Anthony Rizzo as 20 homers, and the Yankees are rolling.`, `They currently sit as the 2nd highest priced team on the SimBull market at $41.58, up from $34.54 in April.`,],
        images: [bengals_graph_learn],
    },
    {
        title: 'Reds historic losing streak sinks share price.',
        paragraphs: [`The Cincinnati Reds got off to one of the worst starts in baseball history with a 3-22 record. They have since maintained a 20 game below 0.500 record of 26-47 but the damage was done. SimBull users sold out of the Reds as quickly as possible, dropping the share price from $30.23 to $26.00 in less than a month.`, `The Reds are tied with the worst record in baseball and the future doesn't look bright. SimBull users seem to have the same sentiment as the SimReds are the cheapest team on the MLB market.`, ],
        images: [bengals_graph_learn],
    },*/
]