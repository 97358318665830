import React, {useState, useEffect} from 'react'
import {db} from '../../../services/firebase'
import {Link, useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
import '../portfolio2.css'
import {getOffersByTeam} from '../../../routes/trading/trading'

const FullOrderBook = (props) => {
    //props
    const team = props.match.params.id

    const history = useHistory()

    //states
    const [bids, setBids] = useState([])
    const [bidAmt, setBidAmt] = useState({})
    const [bidCum, setBidCum] = useState({})
    const [bidAmtCum, setBidAmtCum] = useState({})
    const [asks, setAsks] = useState([])
    const [askAmt, setAskAmt] = useState({})
    const [askCum, setAskCum] = useState({})
    const [askAmtCum, setAskAmtCum] = useState({})

    const [view, setView] = useState('bids')

    useEffect(() => {
        if(team && props.teamsValue && props.teamsValue.length !== 0 && bids && asks && bids.length === 0 && asks.length === 0){
           props.teamsValue.map(t => {
               if(team === t.team.name){
                    getOffersByTeam(t.team.id).then(res => {
                        let bidsUnique = []
                        let bidsObj = {}
                        let asksUnique = []
                        let asksObj = {}
                        res.map(o => {
                            if(o.type === "BID"){
                                if(bidsUnique.includes(`${o.price.toFixed(2)}`)){
                                    bidsObj[`${o.price.toFixed(2)}`]  = bidsObj[`${o.price.toFixed(2)}`] + o.remaining_amount
                                }
                                else{
                                    bidsUnique.push(`${o.price.toFixed(2)}`)
                                    bidsObj[`${o.price.toFixed(2)}`] = o.remaining_amount
                                }
                            }
                            else if(o.type === "ASK"){
                                if(asksUnique.includes(`${o.price.toFixed(2)}`)){
                                    asksObj[`${o.price.toFixed(2)}`] = asksObj[`${o.price.toFixed(2)}`] + o.remaining_amount
                                }
                                else{
                                    asksUnique.push(`${o.price.toFixed(2)}`)
                                    asksObj[`${o.price.toFixed(2)}`] = o.remaining_amount
                                }
                            }
                        })
                        bidsUnique.sort((a,b) => {
                            return b - a
                        })
                        asksUnique.sort((a,b) => {
                            return a - b
                        })
                        let bidCumAmt = 0
                        let bidCumAmtObj = {}
                        let bidCumSum = 0
                        let bidCumObj = {}
                        bidsUnique.map(o => {
                            bidCumAmt += bidsObj[o]
                            bidCumAmtObj[o] = bidCumAmt
                            bidCumSum += parseFloat(o) * bidsObj[o]
                            bidCumObj[o] = bidCumSum
                        })
                        let askCumAmt = 0
                        let askCumAmtObj = {}
                        let askCumSum = 0
                        let askCumObj = {}
                        asksUnique.map(o => {
                            askCumAmt += asksObj[o]
                            askCumAmtObj[o] = askCumAmt
                            askCumSum += parseFloat(o) * asksObj[o]
                            askCumObj[o] = askCumSum
                        })
                        setBids(bidsUnique)
                        setBidAmt(bidsObj)
                        setBidCum(bidCumObj)
                        setBidAmtCum(bidCumAmtObj)

                        setAsks(asksUnique)
                        setAskAmt(asksObj)
                        setAskCum(askCumObj)
                        setAskAmtCum(askCumAmtObj)
                    })
               }
           })
        }
    }, [props.teamValue])

    return(
        <>  
            <div className="fullOrderBook-mobile">
            
            <div  style={{paddingTop: '7vh',paddingLeft: '3%', marginBottom: '20px', fontSize: '2em', fontWeight: '300', display: 'inline-block', width:'100%', color: 'white', textAlign: 'left'}}>
                
                {team && <img style={{width: '9%', marginBottom: '1px'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />}
                {' '}Sim{team && team} Order Book
                <div><button style={{display: 'inline', fontSize: '1rem', fontWeight: '200', border: 'none', outline: 'none', textDecoration: 'underline', backgroundColor: 'black', color: 'white'}} onClick={()=>history.goBack()}>Back</button></div>
            </div>
            <div style={{textAlign: 'center', verticalAlign: 'top', paddingBottom: '15vh', }}>
                <div style={{marginBottom: '10px', display: 'inline-block', width: '50%',fontWeight: view === 'bids' ? '500' : '300', fontSize: view === 'bids' ? '1.5em' : '1.2em', color: view === 'bids' ? 'white' : 'gray', backgroundColor: view === 'bids' ? 'black' : 'rgb(30,30,30)'}}><button style={{width: '100%', borderBottom: view === 'asks' ? '.5px solid gray' : 'none', outline: 'none', backgroundColor: view === 'bids' ? 'black' : 'rgb(30,30,30)', color: view === 'bids' ? 'white' : 'gray'}} onClick={()=>setView('bids')}>Bids</button></div>
                <div style={{marginBottom: '10px', display: 'inline-block', width: '50%',fontWeight: view === 'asks' ? '500' : '300', fontSize: view === 'asks' ? '1.5em' : '1.2em', color: view === 'asks' ? 'white' : 'gray', backgroundColor: view === 'asks' ? 'black' : 'rgb(30,30,30)'}}><button style={{width: '100%', borderBottom: view === 'bids' ? '.5px solid gray' : 'none', outline: 'none', backgroundColor: view === 'asks' ? 'black' : 'rgb(30,30,30)', color: view === 'asks' ? 'white' : 'gray'}} onClick={()=>setView('asks')}>Asks</button></div>
                {view === 'bids' && <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                    
                    <table style={{width: '100%', fontSize:'.8em', fontWeight: '200', color: 'white'}}>
                    <tbody>
                    <tr style={{verticalAlign: 'bottom'}}><th>Amt</th><th>Price</th><th>Total</th><th>Cum.<br />Amt</th><th>Cum.<br />Total</th><th>Cum.<br />Avg</th></tr>
                    {bids.length > 0 && bids.map(b => {
                        return(
                            <tr style={{borderBottom: '1px solid rgb(235, 235, 235)'}}><td>{parseFloat(bidAmt[b]).toFixed(2)}</td><td>{parseFloat(b).toFixed(2)}</td><td>{((bidAmt[b] * parseFloat(b)).toFixed(2))}</td><td>{(bidAmtCum[b] ? bidAmtCum[b].toFixed(2) : '-')}</td><td>{(bidCum[b] ? bidCum[b].toFixed(2) : '-')}</td><td>{(bidCum[b] && bidAmtCum[b] ? (bidCum[b]/bidAmtCum[b]).toFixed(2) : '-')}</td></tr>
                        )
                        
                        //<div style={{fontWeight: '300', fontSize: '.9em'}} key={b}>{bidAmt[b]} x ${b.toFixed(2)}</div>
                    })}
                    </tbody>
                    </table>
                    {bids.length === 0 && <div>(none)</div>}
                </div>}
                {view === 'asks' && <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                    <table style={{width: '100%', fontSize:'.8em', fontWeight: '200', color: 'white'}}>
                    <tr style={{verticalAlign: 'bottom'}}><th>Amt</th><th>Price</th><th>Total</th><th>Cum.<br />Amt</th><th>Cum.<br />Total</th><th>Cum.<br />Avg</th></tr>
                    {asks.length > 0 && asks.map(a => {
                        return(
                            <tr style={{borderBottom: '1px solid rgb(235, 235, 235)'}}><td>{askAmt[a].toFixed(2)}</td><td>{parseFloat(a).toFixed(2)}</td><td>{(askAmt[a] * parseFloat(a)).toFixed(2)}</td><td>{(askAmtCum[a] ? askAmtCum[a].toFixed(2) : '-')}</td><td>{(askCum[a] ? askCum[a].toFixed(2) : '-')}</td><td>{(askCum[a] && askAmtCum[a] ? (askCum[a]/askAmtCum[a]).toFixed(2) : '-')}</td></tr>
                        )
                        
                        //<div style={{fontWeight: '300', fontSize: '.9em'}} key={b}>{bidAmt[b]} x ${b.toFixed(2)}</div>
                    })}
                    </table>
                    {asks.length === 0 && <div>(none)</div>}
                </div>}
            </div>
            </div>

            <div className="fullOrderBook-desktop" style={{color: 'white', paddingLeft: '248px', paddingRight: '348px', }}>
            <div  style={{paddingTop: '3vh',paddingLeft: '3%', marginBottom: '20px', fontSize: '2em', fontWeight: '300', display: 'inline-block', width:'100%', textAlign: 'left'}}>
                {team && <img style={{width: '4%', marginBottom: '5px'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />}
                {' '}Sim{team && team} Order Book
                <div><button style={{display: 'inline', fontSize: '1rem', fontWeight: '200', border: 'none', outline: 'none', textDecoration: 'underline', backgroundColor: 'black', color: 'white'}} onClick={()=>history.goBack()}>Back</button></div>
            </div>
            <hr style={{marginTop: '0'}} />
            <div style={{textAlign: 'center', verticalAlign: 'top', paddingBottom: '10vh', paddingLeft: '248px', paddingRight: '348px',}}>
                <div style={{marginBottom: '10px', display: 'inline-block', width: '50%'}}>
                    <div style={{fontSize: '1.3em', marginBottom: '10px', fontWeight: '300'}}>Bids | Offers to Buy</div>
                    <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                        
                        <table style={{width: '80%', marginLeft: '10%', fontSize:'.8em', fontWeight: '200'}}>
                        <tr style={{verticalAlign: 'bottom'}}><th>Amt</th><th>Price</th><th>Total</th><th>Cum.<br />Amt</th><th>Cum.<br />Total</th><th>Cum.<br />Avg</th></tr>
                        {bids.length > 0 && bids.map(b => {
                            return(
                                <tr key={b} style={{borderBottom: '1px solid rgb(235, 235, 235)'}}><td>{bidAmt[b] && bidAmt[b].toFixed(2)}</td>
                                <td>{parseFloat(b).toFixed(2)}</td>
                                <td>{(bidAmt[b] * parseFloat(b)).toFixed(2)}</td>
                                <td>{(bidAmtCum[b] ? bidAmtCum[b].toFixed(2) : '-')}</td>
                                <td>{(bidCum[b] ? bidCum[b].toFixed(2) : '-')}</td>
                                <td>{(bidCum[b] && bidAmtCum[b] ? (bidCum[b]/bidAmtCum[b]).toFixed(2) : '-')}</td>
                                </tr>
                            )
                            
                            //<div style={{fontWeight: '300', fontSize: '.9em'}} key={b}>{bidAmt[b]} x ${b.toFixed(2)}</div>
                        })}
                        </table>
                        {bids.length === 0 && <div>(none)</div>}
                    </div>
                </div>
                <div style={{marginBottom: '10px', display: 'inline-block', width: '50%'}}>
                <div style={{fontSize: '1.3em', marginBottom: '10px', fontWeight: '300'}}>Asks | Offers to Sell</div>
                    <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                        <table style={{width: '80%', marginLeft: '10%', fontSize:'.8em', fontWeight: '200'}}>
                        <tr style={{verticalAlign: 'bottom'}}><th>Amt</th><th>Price</th><th>Total</th><th>Cum.<br />Amt</th><th>Cum.<br />Total</th><th>Cum.<br />Avg</th></tr>
                        {asks.length > 0 && asks.map(a => {
                            return(
                                <tr key={a} style={{borderBottom: '1px solid rgb(235, 235, 235)'}}><td>{askAmt[a] && askAmt[a].toFixed(2)}</td>
                                <td>{parseFloat(a).toFixed(2)}</td>
                                <td>{(askAmt[a] * a).toFixed(2)}</td>
                                <td>{(askAmtCum[a] ? askAmtCum[a].toFixed(2) : '-')}</td>
                                <td>{(askCum[a] ? askCum[a].toFixed(2) : '-')}</td>
                                <td>{(askCum[a] && askAmtCum[a] ? (askCum[a]/askAmtCum[a]).toFixed(2) : '-')}</td>
                                </tr>
                            )
                            
                            //<div style={{fontWeight: '300', fontSize: '.9em'}} key={b}>{bidAmt[b]} x ${b.toFixed(2)}</div>
                        })}
                        </table>
                        {asks.length === 0 && <div>(none)</div>}
                    </div>
                </div>
            </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(FullOrderBook)