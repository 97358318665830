import React, {useState,useEffect} from 'react'
import '../Analysis.css'
import ReactHtmlParser from 'react-html-parser'
import {db, firebaseAnalytics} from '../../../services/firebase'
import ReactPixel from 'react-facebook-pixel'

const NewsArticle = (props) => {

    let unloaded = {
        author: 'Loading...',
        body: "<p>Loading...</p>",
        date: "20200101",
        title: "Loading...",
        type: "Article",
        url: "loading"
    }
    let notFound = {
        author: '',
        body: "<p>Whoops, it looks like this link isn't good anymore. It could be a typo or we may have removed the article.</p>",
        date: "20200101",
        title: "Article Not Found",
        type: "Aricle",
        url: "notFound"
    }

    //props
    const [article, setArticle] = useState(props.location.state ? props.location.state.article : unloaded)
    

    //Analytics
    useEffect(()=>{firebaseAnalytics.logEvent("article_visit")},[])
    useEffect(()=>{ReactPixel.track('View', {user_role: 'member'})}, [])

    //Getting either the link or the clicked article (I think clicked article)
    useEffect(()=>{
        if(props.location.state){
            setArticle(props.location.state.article)
        }
    },[props])
    //Getting either the link or the clicked article
    useEffect(()=>{
        if(props.match.params.id && !props.location.state){
            db.collection('News').where('url', '==', props.match.params.id).get().then(snapshot => {
                snapshot.forEach(doc => {
                    setArticle(doc.data())
                })
            }).catch(err => { console.log(err) })
        }
    },[])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <div className="Article DesktopContainer">
            {article.title && <>
                <div className="anal-title-2 blk-text">{article.title}</div>
                <div className="anal-sub blk-text">{article.author} - {`${article.date.substring(4, 6)}/${article.date.substring(6, 8)}/${article.date.substring(0, 4)}`}</div>
                <br />
                <div>{ReactHtmlParser(article.body)}</div>
            </>}
        </div>
    )
}

export default NewsArticle