import * as types from "../types";
import {getMyWatchlist} from '../../routes/user/watchlist'

export const getWatchlist = (token) => (dispatch) => {
  getMyWatchlist(token).then(res => {
    dispatch({
      type: types.GET_USER_WATCHLIST,
      payload: res
    })
  })
};
