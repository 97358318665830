import React, {useState, useEffect} from 'react'
import Popup from 'reactjs-popup'
import Signup from './Signup'
import { connect } from 'react-redux'
import {sendTeamDetails, hasLoss} from './util'
import MyPosition from './MyPosition'
import {Link} from 'react-router-dom'


const TheirTeams = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);
    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    console.log(props)
    
    const teams = props.teams

    const [myTeams, setMyTeams] = useState([])
    useEffect(()=>{
        if(props.teams && props.teams.length > 0){
            let fullArr = [{seed: 1}, {seed: 2}, {seed: 3}, {seed: 4}, {seed: 5}, {seed: 6}, {seed: 7}, {seed: 8}, {seed: 9}, {seed: 10}, {seed: 11}, {seed: 12}, {seed: 13}, {seed: 14}, {seed: 15}, {seed: 16},]
            let newArr = []
            if(props.picks.length > 0){
                let picks = props.picks.split(',')
                picks.map(t => {
                    let team = sendTeamDetails(props.teams, parseInt(t))
                    newArr.push({
                        team: parseInt(t),
                        seed: parseInt(team.seed)
                    })
                })
                picks.map(obj => newArr.push({ ...obj, type: 'current' }))
            }
            newArr.sort((a, b) => {
                return a.seed - b.seed;
            })
            newArr.map(a => {
                fullArr[a.seed - 1] = a
            })
            setMyTeams(fullArr)
        }
    },[props.currentPicks])

    const checkStatus = (team) => {
        let status = 'none'
        myTeams && myTeams.length > 0 && myTeams.map(t => {
            if(t.team === team.id){
                status = t.type
            }
        })
        return status
    }

    return(<div style={{paddingLeft: isMobile ? '1px': '15px', paddingRight: isMobile ? '1px': '15px', paddingTop: '0px'}}>

        {/*<div style={{paddingLeft: '15px', fontSize: '1.5em', fontWeight: '500'}}>My Teams</div>*/}
        <div style={{marginTop: '5px'}}>
            {props.teams && props.teams.length > 0 && myTeams && myTeams.map(t => {
                if(t.team){
                    let team = sendTeamDetails(props.teams, t.team)
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '49%' : '24%', marginLeft: '.5%', marginRight: '.5%', backgroundColor: 'white', borderRadius: '.2em', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: '5px'}}>
                            <table style={{width: '100%', color: 'black'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.9em', color: 'gray',width: isMobile ? '8px':'20px', paddingRight: '3px'}}>{team.seed}</td>
                                    <td style={{whiteSpace: 'nowrap', fontSize: isMobile ? '.8em' : '1em'}}>{team.name} {' '} {!isMobile && <span style={{fontSize: isMobile ? '.75em':'.9em', fontWeight: '200'}}>{t.region ? `(${t.region})`: ''}</span>}</td>
                                    <td style={{color: (hasLoss(props.games, t.team) ? 'red' : 'green'), fontSize: isMobile ? '.6em' : '.75em', textAlign: 'right'}}>{(hasLoss(props.games, t.team) ? 'Lost' : 'Active')}</td>
                                </tr>
                            </table>
                        </div>
                    )
                }
                else{
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '49%' : '24%', marginLeft: '.5%', marginRight: '.5%', backgroundColor: 'white', borderRadius: '.2em', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: '5px'}}>
                            <table style={{width: '100%', color: 'black'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.9em', color: 'gray',width: isMobile ? '8px':'20px', paddingRight: '3px'}}>{t.seed}</td>
                                    <td style={{whiteSpace: 'nowrap', fontSize: isMobile ? '.8em' : '1em'}}> {' '} {!isMobile && <span style={{fontSize: isMobile ? '.75em':'.9em', fontWeight: '200'}}>{t.region ? `(${t.region})`: ''}</span>}</td>
                                    <td style={{color: 'red', fontSize: isMobile ? '.6em' : '.75em', textAlign: 'right'}}>-</td>
                                </tr>
                            </table>
                        </div>
                    )
                }
            })}
        </div>

    
    </div>)
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(TheirTeams)
