import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import './portfolio2.css'
import Team from './Team'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import {getTeamsValue} from './Functions'


const Teams = (props) => {
    //get props
    const user = props.user

    const history = useHistory()

    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])
    const [equity, setEquity] = useState(props.equity ? props.equity : [])
    const [hasShares, setHasShares] = useState(false)

    useEffect(()=>{
        setEquity(props.equity)
        if(props.equity && props.equity.length > 0){
            props.equity.map(e => {
                if(e.amount > 0 && !hasShares){
                    setHasShares(true)
                }
            })
        }
    },[props.equity])

    useEffect(()=>{
        if(equity && equity.length === 0 && props.equity){
            setEquity(props.equity)
        }
        if(teams && teams.length === 0 && props.teamsValue){
            setTeams(props.teamsValue)
        }
    },[props])

    /*teams.sort(function(a,b){
        return Math.abs(b.lastSoldPrice - b.prices[yesterday]) - Math.abs(a.lastSoldPrice - a.prices[yesterday])
    })*/

    let myTeamsArr = []
    let myTeams = []

    let getTeamInfo = (id) => {
        let team
        teams && teams.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    return(
    <>
        <div style={{height: '1px', backgroundColor: 'rgb(180,180,180)', opacity: '.5'}}></div>
        <div style={{margin: '5px', paddingTop: props.watchlist ? '0px' : '10px'}}>
            {props.watchlist !== true && <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '73%', paddingLeft: '5px'}}>Teams</div> <div style={{width: '20%',textAlign: 'right', display: 'inline-block', paddingLeft: '5px'}}>{hasShares && <Link to='/MyTeams'><span style={{color: 'black', fontSize: '.8em'}} className="fa fa-ellipsis-v"></span></Link>}</div></div>}
            <hr style={{marginTop: '2px', marginBottom: '4px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
            {teams && equity && teams.length > 0 && equity.length > 0 && equity.map(e => {
                if(e.amount > 0){
                    let t = getTeamInfo(e.asset_id)
                    if(t && t.team && t.team.id === e.asset_id){
                        return(
                            <Team key={t.team.id} t={t} e={e} />
                        )
                    }
                }
            })}

            {equity && equity !== undefined && equity.length > 10 && <Link to="/MyTeams"><div style={{textAlign: 'center', color: 'black'}}>
                View All Teams <span className="fas fa-arrow-right"></span>
            </div></Link>}

            {teams && teams.length > 0 && equity.length === 0 && teams.map(t => {
                if((teams.indexOf(t) === 10) || (teams.indexOf(t) === 22) || (teams.indexOf(t) === 60) || (teams.indexOf(t) === 88) || (teams.indexOf(t) === 101)){
                    let num = teams.indexOf(t)
                    let e = {
                        asset_id: num,
                        amount: num * .01 * 4
                    }
                    return <div style={{opacity: '.2'}}><Team key={t.team.id} t={t} e={e} /></div>
                }
            })}
            {teams && teams.length > 0 && equity.length === 0 &&<div style={{textAlign: 'center'}}><button onClick={()=>history.replace('/Trade/Teams')} style={{border: 'none', outline: 'none', borderRadius: '.2em', backgroundColor: 'rgb(150,150,150)',boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 6px',color: 'white', fontWeight: '500', minWidth: '90%', fontSize: '1.2em', paddingLeft: '25px', paddingRight: '25px'}}>Buy Your First Team</button></div>}
            
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity
});

export default connect(mapStateToProps)(Teams)