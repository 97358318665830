import React from 'react'
import './landingpage.css'
import {Link} from 'react-router-dom'

const Footer = (props) => {

    let logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Funnamed.png?alt=media&token=6a070d2c-624f-4bb7-8acc-d5ad380bc39c'
    return(
        <div className={props.color ? "Footer gray2" : "Footer"}>
            <div className="f-div1">
                <div className="f-img-div"><img className="f-img" src={logo} alt='logo' /></div>
                <div className="f-div-about">SimBull has created a stock market for sports, where you can trade teams like stocks and earn win payouts.</div>
                <div className="f-div-address">Minneapolis, Minnesota, USA</div>
            </div>
            <div className="f-div">
                <div className="f-div-head">How To Play</div>
                <Link to='/Gettings-Started'><div className="f-div-item">Getting Started</div></Link>
                <Link to='/F-A-Q'><div className="f-div-item">FAQs</div></Link>
                <Link to='/Can-I-Make-Money'><div className="f-div-item">Can I Make Money?</div></Link>
                <Link to='/PayoutAssurance'><div className="f-div-item">Win Payout Assurance</div></Link>
            </div>
            <div className="f-div">
                <div className="f-div-head">About Us</div>
                <Link to='/Our-Story'><div className="f-div-item">Our Story</div></Link>
                <Link to='/Contact-Us'><div className="f-div-item">Contact Us</div></Link>
                <Link to='/Terms-Of-Use'><div className="f-div-item">Terms Of Use</div></Link>
                <Link to='/Privacy-Policy'><div className="f-div-item">Privacy Policy</div></Link>
            </div>
            <div className="f-div">
                <div className="f-div-head">Follow Us</div>
                <div className="f-icon-div">
                    <div className="f-icon"><a href="https://www.facebook.com/SimBullExchange" aria-label="icon" alt='facebook' className="fa fa-facebook"></a></div>
                    <div className="f-icon"><a href="https://twitter.com/SimBullExchange" aria-label="icon" alt='twitter' className="fa fa-twitter"></a></div>
                    <div className="f-icon"><a href="https://www.instagram.com/simbullexchange" aria-label="icon" alt='instagram' className="fa fa-instagram"></a></div>
                    <div className="f-icon"><a href="https://www.linkedin.com/in/k-j-giles-431163151/" aria-label="icon" alt='linkedin' className="fa fa-linkedin"></a></div>
                </div>
            </div>
            <br/>
            <br/>
            <hr style={{color: "white", backgroundColor: "white"}}/>
            <div className="f-copyright">Copyright 2021 SimBull Sports Exchange All Rights Reserved</div>
        </div>
    )
}

export default Footer
