import React from 'react'
import './newHomeBar.css'
import {useHistory} from 'react-router-dom'
import amplitude from 'amplitude-js'

function MoverCard(props) {

  const t = props.t
  const history = useHistory()
  
  return (
    <button onClick={()=>{history.push('/'); history.push(`/teams/${t.team.name}`); amplitude.getInstance().logEvent(
      "dashboard_movers", {
        "team": t.team.name,
      }
    )}} className="mover-card" style={{backgroundColor: 'black', border: 'none', outline: 'none'}}>
        <div className="mover-img">
            <img style={{width: '70%'}} src={t && `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
        </div>
        <div className="mover-top">
           <div className="mover-info-top">
                <h1 style={{fontSize: 14, color: "white", fontWeight: "bold"}}>Sim{t.team.name}</h1>
                <h1 style={{fontSize: 14, color: "white", fontWeight: "bold", alignSelf: "center"}}>${t.asking_price && t.asking_price.toFixed(2)}</h1>
            </div>
            <div className="mover-info-bottom">
                <h1 style={{fontSize: 11, color: "#707070", fontWeight: "bold"}}>${t.team && t.team.league}-{t.team && t.team.abbr}</h1>
                <h1 style={{fontSize: 12, color: t.change.day >= 0 ? "#00C566" : 'red', alignSelf: "center"}}>{`${t.change.day >= 0 ? '+' : '-'}`}{t.change.day && Math.abs(t.change.day *100).toFixed(2)}%</h1>
            </div> 
        </div>
        
    </button>
  )
}

export default MoverCard;