import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import './Trade.css'
import TeamCard from '../PortfolioDesktop/WatchlistDesktop/TeamCard'
import DownloadTeams from './DownloadTeams'

const Search = (props) => {
    //get props
    const user = props.user
    const teams = props.teams
    const teamsValue = props.teamsValue

    const [searchTeam, setSearchTeam] = useState('')
    const updateTeam = (val) => {
        setSearchTeam(val)
        props.teamSearch(val)
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{marginLeft: isMobile ? '0px' :'200px', marginRight: isMobile ? '0px' :'300px',}}>

            <input onChange={(e)=>updateTeam(e.target.value)} value={searchTeam} style={{marginBottom: '4px', color: 'white', zIndex: '1', border: '.5px solid lightgray', borderRadius: '1em', width: '80%', marginLeft: '10%', fontSize: '1.35em', textAlign: 'center', padding: '6px', backgroundColor: '#212121', border: 'none', outline: 'none'}} placeholder='search' />
            
            <div style={{display: 'inline-block', width: '5%', marginLeft: '2.5%', color: 'white'}}>
                <DownloadTeams />
            </div>

            {searchTeam && <div style={{padding: '16px', borderTop: '1px solid black', backgroundColor: 'black', minHeight: '15vh', maxHeight: '86vh', width: '102%', marginLeft: '-2%'}}>
                <table style={{width: isMobile ? '100%':'80%',  textAlign: 'center', marginLeft: isMobile ? '0%':'10%'}}>
                {teamsValue && teamsValue.length > 0 && teamsValue.map(t => {
                    if(t.team.name.toLowerCase().includes(searchTeam.toLowerCase())){
                        console.log(t)
                        return(
                            <>
                            <TeamCard id={t.team.id} />
                            {/*<div className="DesktopContainer">
                            <Link to={`/Teams/${t.team}`} style={{color: 'black'}}>
                            <div style ={{width: '100%', backgroundColor: 'white', margin: '6px', padding: '10px'}}>

                                <div style={{width: '10%',paddingLeft: '1%', display: 'inline-block'}}><img style={{width: '100%'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                                <div style={{width: '50%', paddingLeft: '1%', display: 'inline-block',fontSize: '1.1em', fontWeight: '400', }}>Sim{t.team}</div>
                                <div style={{textAlign: 'center',  width: '15%', display: 'inline-block', fontWeight: '200', fontSize: '0.9em'}}>${t.lastSoldPrice.toFixed(2)}</div>
                                <div style={{textAlign: 'right',width: '25%',paddingRight: '5px', display: 'inline-block', fontSize: '1.05em', fontWeight: '300', color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                
                            </div>
                            </Link>
                            </div>*/}
                            </>
                        )
                    }
                })}
                </table>
                <div style={{textAlign: 'center', paddingTop: '15px', fontSize: '1.2em'}}>No More Results...</div>

            </div>}

            
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
});

export default connect(mapStateToProps)(Search)