import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect, useDispatch } from 'react-redux'
import './Spins.css'
import Head from './Head'
import {Link, useHistory} from 'react-router-dom'
import firebase from 'firebase'
import moment from 'moment'
import {getSpins} from '../../routes/spins/spins'
import {getUserTotals} from '../../redux/actions/user/totals'
import {getToken} from '../../redux/actions/token'
import {runSpin} from '../../routes/spins/spins'

import Spinner from '../PlayoffPicks/Spinner2'



const Spins = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const history = useHistory()
    const dispatch = useDispatch()
    const welcome = props.welcome ? props.welcome : false

    //states
    const [spins, setSpins] = useState(props.location.state ? props.location.state : [])
    const [total, setTotal] = useState(0)

    const [allSpins, setAllSpins] = useState([])

    const [showLoad, setShowLoad] = useState(true)


    //Functionality
    useEffect(() => {
        if(spins.length === 0){
            if(props && props.location && props.location.state){
                setSpins(props.location.state)
            }
            else{
                props.token && getSpins(props.token).then(res => {
                    setSpins(res)
                })
            }
        }
    }, [props])

    
    useEffect(()=>{
        firebaseAnalytics.logEvent("payout_visit", {
           user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])


    let getTeamInfo = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team ? team : true
    }

    const collect = () => {
        runSpin(props.token).then(res => {
            //dispatch(getToken())
            //history.push(`/`)
        }).catch(err => console.log(err.response))
    }



    return(
        <div className='Payouts DesktopContainer'>
            <Helmet>
                <title>Spins | SimBull - The Stock Market for Sports</title>
            </Helmet>

            <div>
                <Head />

                <div style={{marginTop: '0px'}}>
                    <div>
                        <Spinner spins={true} />
                    </div>

                    <div style={{textAlign: 'center', paddingBottom: '50px', marginTop: '20px'}}>
                        {/*<button onClick={collect} style={{textAlign: 'center', minWidth: '70%', backgroundColor: '#c5b358', outline: 'none', border: 'none', fontSize: '1.35em', borderRadius: '.3em', boxShadow: '0px 2.5px 10px 5px #d1d1d1', color: 'black'}}>Collect Win Payouts</button>*/}
                        <div style={{marginTop: '12px'}}><Link to="/" style={{color: 'gray', fontSize: '.85em', fontWeight: '300'}}>Back to Portfolio</Link></div>
                    </div>

                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
});

export default connect(mapStateToProps)(Spins)