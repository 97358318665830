import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import GOLD from '../../Trade2/GOLD'

const GoldReport = (props) => {

    const user = props.user

    const team = props.team

    const [report, setReport] = useState({})

    const getReport = () => {
        db.collection('TeamReports').doc(team.team).get().then(doc => {
            setReport(doc.data())
        })
    }
    useEffect(()=>{
        if(team.team){
            getReport()
        }
    },[team])

    return(
        <>
        <div style={{marginTop: '15px', paddingLeft: '3%', paddingRight: '3%', fontWeight: '300', color: 'white'}}>
                <div  style={{fontSize: '1.5em', fontWeight: '400', color: 'white', marginBottom: '0px'}}>GOLD Report</div>
                {report && /*user.gold &&*/ <div style={{paddingLeft: '2%', paddingRight: '2%', fontSize: '0.9em'}}>{report.market}</div>}
                {!report && /*user.gold &&*/ <div style={{paddingLeft: '2%', paddingRight: '2%', fontSize: '0.9em', color: 'white'}}>
                    Report Coming Soon
                </div>}
                {/*!user.gold && */<GOLD report={true} />}
            </div>
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(GoldReport)
