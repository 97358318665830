import React, {useState, useEffect} from 'react'
import TeammateList from './TeammateList'
import TeammateFeed from './TeammateFeed'
import Search from './Search/Search'
import TeammateProfile from './Profile/TeammateProfile'
import './Teammates.css'
import {getTeammates, follow, getTeammatesSummary, getTeammateSummary, getTeammatesTransactions, getFollowRequests, searchUsername, getNewTeammates} from './utils'
import { connect, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import {firebaseAnalytics} from '../../services/firebase'
import {getTrades} from '../../redux/actions/trades'

const Teammates = (props) => {

    const dispatch = useDispatch()

    const [show, setShow] = useState('list')
    
    const [teammates, setTeammates] = useState([])
    const [transactions, setTransactions] = useState([])
    const [requests, setRequests] = useState([])
    const [newRequests, setNewRequests] = useState(0)

    const [showTeammate, setShowTeammate] = useState(false)
    const [teammate, setTeammate] = useState({})
    const changeTeammate = (teammate) => {setTeammate(teammate); setShowTeammate(true)}
    const [newTeammates, setNewTeammates] = useState([])

    const [ranTeammates, setRanTeammates] = useState(false)

    const [publicAccounts, setPublicAccounts] = useState([])
 
    useEffect(()=>{
        if(props.token){
            getTeammatesSummary(props.token).then(res => {
                setTeammates(res)
                setRanTeammates(true)
            })
            getTeammatesTransactions(props.token).then( res => setTransactions(res))
            getFollowRequests(props.token).then( res => {
                let count = 0
                res.length > 0 && res.map(r => {
                    if(r.status === "REQUESTED"){
                        count++
                    }
                })
                setNewRequests(count)
                setRequests(res)
            })
            if(newTeammates.length === 0){
                getNewTeammates(props.token).then(res => setNewTeammates(res))
            }
            if(props.trades.length === 0){
                dispatch(getTrades())
            }
            
        }
    },[props.token])

    useEffect(()=>{
        if(publicAccounts.length === 0 && props.trades.length > 0){
            let unique_ids = []
            props.trades.map(t => {
                if(t.buyer_id !== 0 && !unique_ids.includes(t.buyer_id)){
                    unique_ids.push(t.buyer_id)
                }
                if(t.seller_id !== 0 && !unique_ids.includes(t.seller_id)){
                    unique_ids.push(t.seller_id)
                }
            })
            let publicAccounts1 = []
            unique_ids.map(async (u) => {
                let sum1 = await getTeammateSummary(props.token, u)
                if(sum1){
                    sum1.teammate = {id: u}
                    publicAccounts1.push(sum1)
                }
            })
            //console.log(publicAccounts1)
            setPublicAccounts(publicAccounts1)
        }
    },[props.trades])

    useEffect(()=>{
        //console.log(publicAccounts)
    },[publicAccounts])

    const rerunTeammates = async (username) => {
        getTeammatesTransactions(props.token).then( res => setTransactions(res))
        let res = await getTeammatesSummary(props.token)
        res.length > 0 && res.map(r => {
            if(r.username === username){
                console.log(r)
                setTeammate(r)
            }
        })
        setTeammates(res)
        return 'updated'
    }

    useEffect(()=>{
        //console.log(requests)
    },[requests])

    useEffect(()=>{
        //console.log(transactions)
        //console.log(teammates)
        //console.log(newTeammates)
    },[transactions, teammates, newTeammates])

    useEffect(() => {
        firebaseAnalytics.logEvent("teammates_visit")
        window.scrollTo(0,0)
    }, [])

    return(
        <>
        <div className="Teammates">

            <div className="TeammateShow">
                <button onClick={()=>setShow('feed')} className={show === 'feed' ? "teammate-show-btn tsb-select" : "teammate-show-btn"} style={{borderRadius: '.5rem 0rem 0rem .5rem'}}>
                    Home
                </button>
                <button onClick={()=>setShow('list')} className={show === 'list' ? "teammate-show-btn tsb-select" : "teammate-show-btn"} style={{borderRadius: '0rem 0rem 0rem 0rem'}}>
                    Teammates
                </button>
                <button onClick={()=>setShow('search')} className={show === 'search' ? "teammate-show-btn tsb-select" : "teammate-show-btn"} style={{borderRadius: '0rem .5rem .5rem 0rem'}}>
                    Search
                </button>
            </div>


            {show === 'list' && <TeammateList ranTeammates={ranTeammates} rerunTeammates={rerunTeammates} newRequests={newRequests} summary={teammates} newTeammates={newTeammates} requests={requests} changeTeammate={changeTeammate} />}
            {show === 'feed' && <TeammateFeed publicAccounts={publicAccounts} trades={props.trades} transactions={transactions} teammates={teammates} changeTeammate={changeTeammate} token={props.token} />}
            {show === 'search' && <Search rerunTeammates={rerunTeammates} token={props.token} teammates={teammates} newTeammates={newTeammates} changeTeammate={changeTeammate} />}

            <Popup open={showTeammate} onClose={()=>setShowTeammate(false)} modal>
                <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '90vw', marginLeft: '0vw', height: teammate.value ? '96vh' : '50vh', marginTop: '2vh', marginBottom: '0vh', backgroundColor: 'rgb(250,250,250)', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                    
                    <div style={{textAlign: 'right'}}>
                        <button onClick={()=>setShowTeammate(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>close</button>
                    </div>

                    <TeammateProfile publicAccounts={publicAccounts} teammate={teammate} token={props.token} teammates={teammates} teamsValue={props.teamsValue} rerunTeammates={rerunTeammates} changeTeammate={changeTeammate}  />
                    
                </div>
            </Popup>

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue,
    trades: state.trades.trades,
});

export default connect(mapStateToProps)(Teammates)