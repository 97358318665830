import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import Search from './Search'
import './Trade.css'
import AllTrades from './AllTrades'
import FullLeague from './FullLeague'
import FullList from './FullList'
import {Link, Route, useHistory} from 'react-router-dom'
import { getTeamsValue } from '../Portfolio3/Functions'

export const buttonStyle = {
    border: '1px solid gray',
    outline: 'none',
    borderRadius: '.5em',
    width: '30%',
    marginLeft: '1.5%',
    marginRight: '1.5%',
    backgroundColor: 'white',
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: '1.1em',
    color: 'gray'
}
export const selectedButtonStyle = {
    border: '3px solid #c5b358',
    outline: 'none',
    borderRadius: '.5em',
    width: '30%',
    marginLeft: '1.5%',
    marginRight: '1.5%',
    backgroundColor: 'white',
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: '1.1em',
    color: 'black'
}

const Trade = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const history = useHistory()
    useEffect(()=>{
        if(display !== 'movers' && history.location.pathname === '/Trade/Movers'){
            setDisplay('movers')
        }else if(display !== 'trades' && history.location.pathname === '/Trade/Trades'){
            setDisplay('trades')
        }else if(display !== 'teams' && history.location.pathname === '/Trade/Teams'){
            setDisplay('teams')
        }
        else if(history.location.pathname === '/' || history.location.pathname === '/login'){
            history.push('/Trade/Trades')
        }
        else if(history.location.pathname === '/Trade'){
            history.push('/Trade/Trades')
        }
    },[])
    
    useEffect(()=>{
        firebaseAnalytics.logEvent("trade_visit", {
           user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    const [display, setDisplay] = useState('trades')

    const [searchTeam, setSearchTeam] = useState('')
    const teamSearch = (val) => {
        if(val === '' || !val){
            setSearchTeam('')
        }
        setSearchTeam(val)
    }

    return(
        <div style={{paddingTop: '0vh', paddingBottom: '18vh', fontSize: '100%'}} className='Trade'>
            <Helmet>
                <title>Trade | SimBull - Buy Teams Like Stocks</title>
            </Helmet>

            {/* Search Bar */}
            {display !== 'trades' && <Search teamSearch={teamSearch} />}

            <div className='Ticker'>

                <div className='DesktopContainer' style={{textAlign: 'center'}}>
                    <Link to="/Trade/Movers"><button style={display === 'movers' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('movers')}>Movers</button></Link>
                    <Link to="/Trade/Trades"><button style={display === 'trades' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('trades')}>Trades</button></Link>
                    <Link to="/Trade/Teams"><button style={display === 'teams' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('teams')}>Teams</button></Link>
                </div>

                <Route path="/Trade/Movers">
                    <FullList searchTeam={searchTeam} />
                </Route>
                <Route path="/Trade/Trades">
                    <AllTrades />
                </Route>
                <Route path="/Trade/Teams">
                    <FullLeague searchTeam={searchTeam} />
                </Route>

            </div>

            
            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Trade)