import React, {useState, useEffect} from 'react'
import './Analysts.css'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {db} from '../../../services/firebase'
import Slider from "react-input-slider";
import Game from './Game'
import moment from 'moment'

const Analysts = () => {

    const [YYYYMMDD, setYYYYMMDD] = useState()
    const [daysFrom, setDaysFrom] = useState(0)
    const [games, setGames] = useState([])
    const [updatedGames, setUpdatedGames] = useState([])
    const [gamesLoaded, setGamesLoaded] = useState([])
    const [gamesData, setGamesData] = useState([])
    

    const getGames = (date) => {
        db.collection('mlbGames').where('date', '==', date).get().then(snapshot => {
            let gameArr = []
            snapshot.forEach(doc => {
                const data = doc.data()
                gameArr.push(data)
            })
            setGamesLoaded([...gamesLoaded, date])
            setGamesLoaded([...gamesData, gameArr])
            setGames(gameArr)
            setUpdatedGames(gameArr)
        })
    }
    useEffect(()=>{
        let date = moment().subtract(daysFrom, 'days').format('YYYYMMDD')
        setYYYYMMDD(date)
        getGames(date)
    },[daysFrom])

    const getApiGames = () => {
        
    }

    const getOldGames = (date) => {
        setGames(gamesData[gamesLoaded.indexOf(date)])
    }

    const changeDay = (direction) => {
        let date = moment().subtract(daysFrom + direction, 'days').format('YYYYMMDD')
        setYYYYMMDD(date)
        gamesLoaded.indexOf(date) !== -1 ? getGames(date) : getOldGames(date)
        setDaysFrom(daysFrom + direction)
    }

    const getChildData = (newObj) => {
        let holderArr = updatedGames
        holderArr.push(newObj)
        setUpdatedGames(holderArr)
    }

    const updateAll = () => {
        //pull all the changed data in 
        //Put all on the same array
        //Update all the docs
    }

    return(<>
        <div className="Analysts" style={{paddingTop: '10vh', paddingBottom: '10vh',}}>
            <div style={{textAlign: 'center', fontSize: '1.3rem'}}>Analysts</div>
            <div style={{textAlign: 'center'}}>
                <button onClick={()=>{changeDay(1)}}>-</button><span>{YYYYMMDD && `${YYYYMMDD.substring(4,6)}/${YYYYMMDD.substring(6,8)}/${YYYYMMDD.substring(0,4)}`}</span><button onClick={()=>{changeDay(-1)}}>+</button>
            </div>
            {games && games.map(g => {
                return(
                    <div key={g} style={{width: '100%'}}>
                        <Game key={g} g={g} getChildData={getChildData} />
                    </div>
                )
            })}
            <div style={{textAlign: 'center'}}>
                <button style={{borderRadius: '1rem', padding: '3%'}} onClick={updateAll}>Update All</button>
            </div>
        </div>
    </>)
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(withRouter(Analysts))