import React, {useState,useEffect, useRef} from 'react'
import NavBar from './NavBar2'
import Top from './TopPicks'
import AboveFold from './AboveFold'
import NumberProof from './NumberProof'
import Timeline from './Timeline'
import Reviews from './Reviews'
import Questions from './Questions'
import Footer from './Footer'
import {firebaseAnalytics} from '../../../services/firebase'
import Popup from 'reactjs-popup'
import Signup from '../Signup'
import './Timeline.css'
import useMouseLeave from 'use-mouse-leave';
import {useHistory} from 'react-router-dom'

const LandingPage = () => {

    let headerImg = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FLandingPage%2Ftop_landing_page.png?alt=media&token=5a6eb76a-a831-47e2-bbed-bd7008e083b8'

    const [signup, setSignup] = useState(false)

    const seeSignup = () => setSignup(true)

    const history = useHistory()

    const [leaving, setLeaving] = useState(false)
    const [leavingCount, setLeavingCount] = useState(0)
    const [mouseLeft, ref] = useMouseLeave();
    useEffect(() => {
        if (mouseLeft) {
            // The mouse has just left our element, time to
            // run whatever it was we wanted to run on mouseleave:
            // ...
        }
    }, [mouseLeft]);

    useEffect(()=>{
        firebaseAnalytics.logEvent('landing_page_picks')
        window.scrollTo(0,0)
    },[])

    const doMouseLeave = () => {
            if(!leaving){
                seeSignup(true)
            }
            setLeavingCount(leavingCount + 1)
            setLeaving(true)
    }


    return(<div ref={ref} onMouseLeave={doMouseLeave}>

        <NavBar picks={true} />
        <Top picks={true} />
        <NumberProof picks={true} />
        <Timeline seeSignup={() => setSignup(true)} picks={true} />
        <Reviews picks={true} />
        <Questions seeSignup={() => setSignup(true)} picks={true} />
        <Footer picks={true} />

        <Popup open={signup} modal>
            <div className='signup_modal' style={{ backgroundColor: 'rgb(250,250,250)', border: '1px solid black', boxShadow: '0 0 1px 2px #c5b358',}}>
                <div style={{width: '100%', marginLeft: '0%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>

                <div style={{textAlign: 'center', fontWeight: '600', fontSize: '1.5rem'}}>{leaving ? `You're leaving a free $10 on the table...`: 'Almost there.'}</div>

                    {leavingCount == 1 ? <div style={{padding: '20px'}}>
                        We could have <strong>SWORN</strong> you were someone who wanted to make money. Our Bad. We'll just have to keep the $10 in our pocket then...
                    </div> : 
                        <div style={{padding: '20px'}}>
                        <h4 className="Top-CTA-subtitle" style={{marginTop: '0px', marginBottom: '15px', fontSize: '.9rem', fontWeight: '400'}}>Join <strong>12,000+ bullish investors</strong> and see why experts are calling the <strong>Stock Market for Sports</strong> the future of investing</h4>
                    </div>
                    }


                    <button className="Top-CTA-btn2" style={{backgroundColor: '#e0cb07', color: 'black', }} onClick={()=>history.push('/Portfolio')}>Claim Free Stock <i class="fa fa-arrow-right" style={{fontSize: '1em', marginLeft: '5px', fontWeight: '400'}}></i></button>
                
                    <div style={{textAlign: 'center'}}>
                        <button onClick={()=>setSignup(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                    </div>
                </div>
                
            </div>
        </Popup>

    </div>)
}

export default LandingPage