import React, {useState, useEffect} from 'react'
import './portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

const MyOffers = (props) => {

    const user = props.user
    const [teams, setTeams] = useState([])
    useEffect(()=>{
        setTeams(props.teamsValue)
    },[props.teamsValue])

    const [offers, setOffers] = useState([])
    const [deletedOffers, setDeletedOffers] = useState([])

    const [edit, setEdit] = useState(false)

    useEffect(()=>{
        if(props.offers){
            setOffers(props.offers)
        }
    },[props])

    let getTeamInfo = (id) => {
        let team
        teams && teams.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    //Excel Offer Testers
    let testers = ['tsteu35@gmail.com', 'dallasjklein@gmail.com','bigb19935@aol.com', 'dlong178@gmail.com', 'jsmead88@gmail.com', 'mchim00@yahoo.com', 'penguinn101@yahoo.com', 'rsbrooks@cinci.rr.com', 'verbleke@gmail.com', 'jcsalow@gmail.com']


    return(
        <>
        {false && offers && offers.length === 0 && <div style={{marginTop: '15px', marginLeft: '5px', paddingRight: '3%', fontWeight: '300', color: 'rgb(50,50,50)'}}>
                <div  style={{fontSize: '1.6em', fontWeight: '500', display: 'inline-block', width:'60%'}}>Offers {/*testers.includes(user.email) && <span style={{fontSize: '.8em', fontWeight: '300', color: 'gray'}}>| <Link style={{color: 'gray'}} to="/ExcelOffers">Excel <i className="fas fa-arrow-right" style={{fontSize: '.85em'}}></i></Link></span>*/}</div>
                <div style={{textAlign: 'left'}}>No Offers</div>
                </div>
        }

        {offers && offers.length > 0 && (offers.length - deletedOffers.length !== 0) && <div style={{marginTop: '15px', marginLeft: '5px', paddingRight: '3%', fontWeight: '300', color: 'rgb(50,50,50)'}}>
                <div  style={{fontSize: '1.6em', fontWeight: '500', display: 'inline-block', width:'60%'}}>
                    <Link to="/AllOffers" style={{color: 'black'}}>Offers { ' ' }
                    <span className="fas fa-arrow-right" style={{color: 'gray', fontSize: '.8em'}}></span>
                    </Link>
                </div>
                <div  style={{textAlign: 'right', fontSize: '1em', fontWeight: '300', display: 'inline-block', width:'40%', color: 'gray'}}>{/*<button style={{border: 'none', outline: 'none', backgroundColor: 'white', textDecoration: 'underline', color: 'gray'}} onClick={()=>setEdit(!edit)}>{edit ? 'close': 'edit'}</button>*/}</div>
                <div className="portfolioDiv" style={{marginLeft: '0px'}}>


                    {/*offersCount === (offers.length - deletedOffers.length) && offers && offers.length > 0 && offers.map(o => {
                        if(deletedOffers.indexOf(o) === -1){
                            return(
                            <div className="portfolioBox">
                                <Link to={`/Teams/${o.team ? o.team : o.bidTeam}`} style={{color: 'black'}}>
                                    <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.date.substring(4, 6)}/${o.date.substring(6, 8)}/${o.date.substring(0, 4)}`}</div>
                                    <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{o.team ? o.team : o.bidTeam}</div>
                                    <div style={{fontSize: '1.15em', color: 'gray'}}>
                                        {o.bidTeam ? 'Bid: ' : 'Ask: '}{' '}
                                        ${o.askPrice ? o.askPrice.toFixed(2) : o.bidPrice.toFixed(2)}
                                    </div>
                                </Link>
                                {edit && <div style={{textAlign: 'center'}}>
                                    <button onClick={()=>{deleteOneOffer(o)}} style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                                </div>}
                            </div>
                            
                            )
                        }
                        
                    })*/}

                    {teams && teams.length > 0 && offers && offers.length > 0 && offers.map(o => {
                        let t = getTeamInfo(o.asset_id)
                        if(offers.indexOf(o)< 10){
                            return(
                            <div className="portfolioBox">
                                <Link to={`/Teams/${t && t.team && t.team.name}`} style={{color: 'black'}}>
                                    <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.timestamp.substring(5, 7)}/${o.timestamp.substring(8, 10)}/${o.timestamp.substring(0, 4)}`}</div>
                                    <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{t && t.team && t.team.name}</div>
                                    <div style={{fontSize: '1.15em', color: 'gray'}}>
                                        {o.type === "BID" ? 'Bid: ' : 'Ask: '}{' '}
                                        {o.remaining_amount && o.remaining_amount.toFixed(4)} x ${o.price.toFixed(2)}
                                    </div>
                                </Link>
                                {edit && <div style={{textAlign: 'center'}}>
                                    <button style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                                </div>}
                            </div>
                            )
                        }
                    })}

                </div>
                
        </div>}
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
});

export default connect(mapStateToProps)(MyOffers)
