import React, {useState, useEffect} from "react";
import "./TeamReports.css";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {db, firebaseAnalytics} from '../../../services/firebase'

const InputReport = (props) => {

    //Things Needed
    //1. Current Price
    //2. 2020 Record
    //3. 2021 Project Win Payouts
    //4. Reports
    //5. On the field
    //6. In the market

    const user = props.user
    const teams = props.teams

    //Capture
    const [record, setRecord] = useState('')
    const [projectedPayout, setProjectedPayout] = useState('')
    const [field, setField] = useState('')
    const [market, setMarket] = useState('')

    //submit
    const submitReport = () => {
        let team = document.getElementById('teamSelect').value
        if(team){
            db.collection('TeamReports').doc(team).set({
                team: team,
                record: record,
                projectedPayout: projectedPayout,
                field: field,
                market: market,
            }, {merge: true}).then(res => {
                alert('Whoo')
                setRecord('')
                setProjectedPayout('')
                setField('')
                setMarket('')
            })
        }
        
    }

    return(
        <div className="InputReport" style={{paddingTop: '15vh'}}>
            <div className="InputReport-head">Team: <br />
                <select className="InputReport-input" id="teamSelect">
                    {teams && teams.map(t => {
                        return(
                            <option>{t.team}</option>
                        )
                    })}
                </select>
            </div>
            <div className="InputReport-head">2020 Record: <br />
                <input className="InputReport-input" onChange={(e)=>setRecord(e.target.value)} value={record} />
            </div>
            <div className="InputReport-head">2021 Projected Win Payouts: <br />
                <input className="InputReport-input" onChange={(e)=>setProjectedPayout(e.target.value)} value={projectedPayout} />
            </div>
            <div className="InputReport-head">On the field: <br />
                <textarea className="InputReport-input" onChange={(e)=>setField(e.target.value)} value={field} />
            </div>
            <div className="InputReport-head">In the market: <br />
                <textarea className="InputReport-input" onChange={(e)=>setMarket(e.target.value)} value={market} />
            </div>
            <div><button onClick={submitReport} className="InputReport-submit">Submit</button></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(withRouter(InputReport))