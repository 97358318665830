import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import './portfolio2.css'
import {getTotals} from '../../routes/user/user'
import Graph from './Graph'



const Value = (props) => {
    //get props
    const user = props.user
    const teams = props.teams
    const spin = props.spin ? props.spin : false

    console.log(props.totals)

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /*Three */
    const [totals, setTotals] = useState(props.totals ? props.totals : {})

    useEffect(()=>{
        if(props.totals){
            setTotals(props.totals)
        }
    },[props.totals])

    useEffect(()=>{
        if(props.token){
            getTotals(props.token).then(res => {setTotals(res)})
        }
    },[])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    let expand = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fexpand-arrows.png?alt=media&token=382b087d-ac9d-4d0a-8721-9b51544d60ac'

    //Your balance <div style={{}} id={spin ? 'spinner' : ''}>${numberWithCommas(totals.total_value ? totals.total_value.toFixed(2) : 0)}</div>
    //cash
    //change <span style={{fontSize: '1.75em', fontWeight: '500'}}>{totals.total_change >= 0 ? '+' : '-'}${numberWithCommas(totals.total_change ? Math.abs(totals.total_change).toFixed(2) : 0)}</span><br />
    //percentage change {totals && totals.total_deposited ? <><span>{totals.total_change > 0 ? <span className="fas fa-caret-up" style={{color: 'green', fontSize: '1.1em'}}></span> : <span className="fas fa-caret-down" style={{color: 'red', fontSize: '1.1em'}}></span>} {totals.total_change >= 0 ? '+' : '-'}{Math.abs(((totals.total_value + totals.total_withdrawn-totals.total_deposited)/totals.total_deposited)*100).toFixed(2)}%</span></>: <span><span className="fas fa-caret-up" style={{color: 'green', fontSize: '1.1em'}}></span>+0.00%</span>}

    return(
        <div style={isMobile ? {paddingLeft: '4px', paddingRight: '4px', paddingTop: '4px', paddingBottom: isMobile ? '0px':'12px', backgroundColor: 'black', width: '100%'} : {paddingLeft: '48px', paddingRight: '48px', paddingTop: '4px', paddingBottom: '12px', backgroundColor: 'black', width: '100%'}}>
            <div style={{width: isMobile ? '100%': '50%', display: 'inline-block', verticalAlign: 'top', padding: isMobile ? '16px' : '0px'}}>
                <div style={{fontSize: '1rem', fontWeight: '500', color: '#c4c4c4'}}>
                    Your balance
                </div>
                <div>
                    <div style={{color: 'white', fontSize: '1.75rem', fontWeight: '600', display: 'inline-block'}}>
                        ${numberWithCommas(totals.total_value ? totals.total_value.toFixed(2) : 0)}
                    </div>
                    {isMobile ?
                        <div style={{height: '100%', verticalAlign: 'bottom', paddingBottom: '8px', marginLeft: '8px',  display: 'inline-block', fontSize: '.9rem', fontWeight: '600', color: totals.total_change >= 0 ? 'green' : 'red'}}>
                            <span style={{}}>{totals.total_change >= 0 ? '+' : '-'}${numberWithCommas(totals.total_change ? Math.abs(totals.total_change).toFixed(2) : 0)}</span>
                            {' '}
                            {totals && totals.total_deposited ? <><span>({totals.total_change >= 0 ? '+' : '-'}{Math.abs(((totals.total_value + totals.total_withdrawn-totals.total_deposited)/totals.total_deposited)*100).toFixed(2)}%)</span></>: <span>+0.00%</span>}
                        </div>
                    :
                        <div style={{display: 'inline-block', marginLeft: '20px', color: 'darkgray'}}>
                        cash ${totals && totals.cash_balance ? totals.cash_balance.toFixed(2) : 0.00}
                    </div>}
                </div>
            </div>
            {isMobile ? null :
            (props.prices && props.prices.length >1) ? <div style={{width: '50%', display: 'inline-block', textAlign: 'right', maxWidth: '50%', paddingTop: '12px'}}>
                
                <div style={{height: '100%', verticalAlign: 'top', marginTop: '20px', display: 'inline-block', fontSize: '.9rem', fontWeight: '600', color: totals.total_change >= 0 ? 'green' : 'red'}}>
                    <span style={{}}>{totals.total_change >= 0 ? '+' : '-'}${numberWithCommas(totals.total_change ? Math.abs(totals.total_change).toFixed(2) : 0)}</span>
                    {' '}
                    {totals && totals.total_deposited ? <><span>({totals.total_change >= 0 ? '+' : '-'}{Math.abs(((totals.total_value + totals.total_withdrawn-totals.total_deposited)/totals.total_deposited)*100).toFixed(2)}%)</span></>: <span>+0.00%</span>}
                </div>
                <div style={{display: 'inline-block', width: '30%', marginLeft: '10px'}}>
                    <Graph off={true} prices={props.prices ? props.prices : []} />
                </div>
            </div> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(Value)