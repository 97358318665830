import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect, useDispatch } from 'react-redux'
import './Payouts.css'
import Head from './Head'
import {Link, useHistory} from 'react-router-dom'
import firebase from 'firebase'
import moment from 'moment'
import {getUncollectedPayouts} from '../../routes/user/user'
import {getUserTotals} from '../../redux/actions/user/totals'
import {getToken} from '../../redux/actions/token'
import {collectPayouts} from '../../routes/payouts/collect'



const Payouts = (props) => {
    //get props
    const user = props.user
    const teams = props.teams

    const history = useHistory()
    const dispatch = useDispatch()
    const welcome = props.welcome ? props.welcome : false

    //states
    const [payouts, setPayouts] = useState(props.location.state ? props.location.state : [])
    const [total, setTotal] = useState(0)

    const [allPayouts, setAllPayouts] = useState([])

    const [showLoad, setShowLoad] = useState(true)


    //Functionality
    useEffect(() => {
        if(payouts.length === 0){
            if(props && props.location && props.location.state){
                setPayouts(props.location.state)
                let tot = 0
                props.location.state.map(p => {
                    tot += p.reward
                })
                setTotal(tot)
            }
            else{
                props.token && getUncollectedPayouts(props.token).then(res => {
                    setPayouts(res)
                    let tot = 0
                    res.map(p => {
                        tot += p.reward
                    })
                    setTotal(tot)
                })
            }
        }
        if(total === 0 && payouts){
            let tot = 0
            props.location.state && props.location.state.map(p => {
                tot += p.reward
            })
            setTotal(tot)
        }
    }, [props])

    
    useEffect(()=>{
        firebaseAnalytics.logEvent("payout_visit", {
           user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])


    let getTeamInfo = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team ? team : true
    }

    const collect = () => {
        collectPayouts(props.token).then(res => {
            dispatch(getToken())
            history.push(`/`)
        }).catch(err => console.log(err.response))
    }



    return(
        <div className='Payouts DesktopContainer'>
            <Helmet>
                <title>Payouts | SimBull - The Stock Market for Sports</title>
            </Helmet>

            <div>
                <Head />

                <div style={{marginTop: '25px'}}>
                    <table style={{width: '94%', marginLeft: '3%'}}>
                        <tbody>
                            {payouts.length > 0 && payouts.map(p => {
                                let t = getTeamInfo(p.asset_id)
                                let league = t.team ? t.team.league : ''
                                if(!t.team){
                                    //console.log(t)
                                }
                                let amt = league === 'NFL' ? (p.reward / .5) : league === 'CFB' ? (p.reward / .5) : league === 'NBA' ? (p.reward / .1) : league === 'NHL' ? (p.reward / .1) : league === 'MLB' ? (p.reward / .05) : 1 
                                let pay = league === 'NFL' ? .5 : league === 'CFB' ? .5 : league === 'NBA' ? .1 : league === 'NHL' ? .1 : league === 'MLB' ? .05 : 1 
                                return(<>
                                    {(p.note && p.note === 'register reward') ?
                                    <tr style={{width: '100%'}}>
                                        <td>Welcome Bonus! <span style={{fontSize: '.8em', fontWeight: '300', color: 'rgb(100,100,100)'}}>{`${p.timestamp.substring(5, 7)}/${p.timestamp.substring(8, 10)}/${p.timestamp.substring(0, 4)}`}</span></td>
                                        <td style={{textAlign: 'right'}}>{amt.toFixed(2)} x ${p.reward.toFixed(2)} <span style={{color: 'green'}}>(${p.reward.toFixed(2)})</span></td>
                                    </tr>
                                     :
                                     (p.note && p.note === 'march madness 2022') ?
                                    <tr style={{width: '100%'}}>
                                        <td>Bracket Bonus! <span style={{fontSize: '.8em', fontWeight: '300', color: 'rgb(100,100,100)'}}>{`${p.timestamp.substring(5, 7)}/${p.timestamp.substring(8, 10)}/${p.timestamp.substring(0, 4)}`}</span></td>
                                        <td style={{textAlign: 'right'}}>1 x ${p.reward.toFixed(2)} <span style={{color: 'green'}}>(${p.reward.toFixed(2)})</span></td>
                                    </tr>
                                     :
                                    <tr style={{width: '100%'}}>
                                        <td>Sim{t.team &&  t.team.name} <span style={{fontSize: '.8em', fontWeight: '300', color: 'rgb(100,100,100)'}}>{`${p.timestamp.substring(5, 7)}/${p.timestamp.substring(8, 10)}/${p.timestamp.substring(0, 4)}`}</span></td>
                                        <td style={{textAlign: 'right'}}>{amt.toFixed(2)} x ${pay.toFixed(2)} <span style={{color: 'green'}}>(${p.reward.toFixed(3)})</span></td>
                                    </tr>
                                    }
                                </>)
                            })}
                            
                            
                            {/*<tr style={{width: '100%'}}>
                                <td>SimBucs <span style={{fontSize: '.8em', fontWeight: '300', color: 'rgb(100,100,100)'}}>12/05/21</span></td>
                                <td style={{textAlign: 'right', color: 'green'}}>1 x $0.50 ($0.50)</td>
                            </tr>*/}
                        </tbody>

                        <tr style={{width: '100%', fontWeight: '500', fontSize: '1.2em', color: 'black'}}>
                            <td><hr />Total</td>
                            <td style={{textAlign: 'right', fontWeight: '600', color: 'black'}}><hr />+${total.toFixed(2)}</td>
                        </tr>
                    </table>

                    <div style={{textAlign: 'center', paddingBottom: '50px', marginTop: '20px'}}>
                        <button onClick={collect} style={{textAlign: 'center', minWidth: '70%', backgroundColor: '#c5b358', outline: 'none', border: 'none', fontSize: '1.35em', borderRadius: '.3em', boxShadow: '0px 2.5px 10px 5px #d1d1d1', color: 'black'}}>Collect {(payouts.length === 1 && payouts[0].note && payouts[0].note === 'register reward') ? `Free $${payouts[0].reward} Bonus` : 'Win Payouts'}</button>
                        <div style={{marginTop: '12px'}}><Link to="/" style={{color: 'gray', fontSize: '.85em', fontWeight: '300'}}>Don't collect right now</Link></div>
                    </div>

                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
});

export default connect(mapStateToProps)(Payouts)