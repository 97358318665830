export const faqs = [
    {
        question: 'What are Team Shares?',
        shortAnswer: `SimBull's virtual representation of a sports team's performance.`,
        longAnswer: 'SimBull team shares are virtual representations of professional and collegiate sports teams that you can trade on SimBull, but represent no real ownership of the real life team. Every team share you own gives you the opportunity to earn a win payout when that team wins in real life. Team shares are NOT equity in the actual teams and have no relation to the teams themselves, but rather they are a virtual representation of teams that allow you to buy, sell and earn win payouts in the SimBull market.',
        type: "teamShares"
    },
    {
        question: 'What are Win Payouts?',
        shortAnswer: `Win Payouts is cash paid to users when their teams win.`,
        longAnswer: [`Win payouts are cash prizes paid to users when teams they own win a game. Win payouts provide economic value to the team shares and allow users to earn cash from owning team shares.
        `,
        
        `
        Payouts for each league are based on the number of games in a season. The less games that occur in a season, the higher the win payout and vice versa. This allows the average team in each league to earn a similar amount of win payouts in a season.
          `,
          `      
        NFL Win Payouts = $0.50
        `,
          `
        College Football Win Payouts = $0.50
        `,
          `
        NBA Win Payouts = $0.10
        `,
          `
        NHL Win Payouts = $0.10
        `,
          `
        MLB Win Payouts = $0.05
        `],
        type: "winPayouts",
        para: true,
    },
    {
        question: 'How do I make money?',
        shortAnswer: 'SimBull users earn money through win payouts and share price movement.',
        longAnswer: [`There are two ways to make money on SimBull: Win payouts and share price appreciation.
        `,
        `
        Win Payouts: Users who own shares are eligible to earn win payouts when a team they own wins a game. Win payouts provide economic value to owning team shares. Visit the Win Payouts section to learn more!
        `,
        `
        Share Price Appreciation/Depreciation: Team share prices fluctuate in value based on a number of factors including team performance, injuries, trades, free agent signings, public relations, and general team sentiment. Users are able to buy low and sell high if a team they own rises in value. This is also where users can lose money if they buy a team that decreases in value and sell for a loss.
        `,
        ],
        type: "makeMoney",
        para: true,
    },
    {
        question: 'What leagues are offered on SimBull?',
        shortAnswer: `The NFL, MLB, NBA, NHL and College Football.`,
        longAnswer: 'SimBull offers markets for virtual representations of team shares in the NFL, MLB, NBA, NHL and College Football. Our goal is to add every major sport in the United States and abroad. Stay tuned for our next league announcement.',
        type: "leaguesOffered"
    },
    {
        question: 'How many shares of each team are in the market?',
        shortAnswer: 'Current supply outstanding can be found on each of the team pages.',
        longAnswer: 'Current supply outstanding can be found on each of the team pages.',
        type: "shareNumber"
    },
    {
        question: 'How are new shares created?',
        shortAnswer: `When people add an equal amount of every team.`,
        longAnswer: [`In order for the SimBull user base to grow, more shares will need to be added to the market.
        `,
        `
        The price of a full SimPack (1 team share in every league) will be set at $2,720. This means the average team in an NFL SimPack would cost $2,720 / 32 = $80.00. Users will be able to buy fractional packs (0.1, 0.25, 0.5, 0.75, 1) that include less than one full team share of each team in a league.
        `,
          `
        SimPacks will likely not be bought by users until the average price of a team on SimBull is $85.00. Once the average price of a team share is above $85.00, users might choose to purchase a SimPacks because it offers better value than buying team shares from another user. New shares do not affect the amount of win payouts in any way. Functionality coming Fall 2022.`
        ],
        type: "newShares",
        para: true,
    },
    {
        question: 'How are team shares removed from the market?',
        shortAnswer: `By removing an equal amount of every team.`,
        longAnswer: 'Removing team shares from the market works similarly to creating new shares. If a user has an equal amount of every team share in a league and wants to fully cash out, they can exchange their team shares with SimBull for the current SimPacks price.',
        type: "removeShares"
    
    },
    
    {
        question: 'Does creating new shares lower the value of my current share?',
        shortAnswer: 'No, the payouts for each share will be the same.',
        longAnswer: 'SimPacks will likely not be purchased by users until the average price of all teams in a league is over $85.00 per share. If the average team is under $85.00 per share, users are better off buying shares from other users in the market. Once the average team reaches $85.00 per share in a league, users are better off buying SimPacks to introduce more shares into the market. This will allow the average team in a league to maintain a steady price of $85.00 per share. Team share prices will have market fluctuations as they perform better and worse, based on their ability to earn win payouts. New shares do not affect the amount of win payouts in any way, further holding the value of the team shares.',
        type: "mySharesValue"
    
    },
    {
        question: 'How long do team shares last?',
        shortAnswer: `Forever.`,
        longAnswer: 'SimBull shares last forever! Team shares transfer season to season allowing users to trade 24 hours a day, 7 days a week, and 365 days a year. This allows users to own team for one week, one month, one year, or beyond giving them more control over their shares! ',
        type: "shareLength"
    
    },
    {
        question: 'When can I trade teams?',
        shortAnswer: '',
        longAnswer: 'Trading is open 24 hours a day, 7 days a week, 365 days a year. Keep in mind, the user who owns the virtual share at the end of a game earns the win payout.',
        type: "marketHours"
    },
    {
        question: 'Who pays the win payouts?',
        shortAnswer: '',
        longAnswer: 'SimBull pays the win payouts to provide economic value to the team shares. Win payouts are paid from the trading fees, subscription revenue, and any other income that SimBull earns.',
        type: "winPayoutPayer"
    },
    {
        question: 'How are team share prices determined?',
        shortAnswer: '',
        longAnswer: [`Team share prices are determined by the supply and demand of the market. SimBull is a peer to peer market so when a buyer and seller agree on a price, that is the value of the team share. 
        `,
          `
        Because SimBull is a peer to peer market, there is no limit on price movement. The market has seen teams like the SimBengals rise over 100% in a year and other teams like the SimPackers fall over 20% after the Aaron Rodgers drama.`],
        type: "sharePriceDetermination",
        para: true
    },
    {
        question: 'How should I price a team share?',
        shortAnswer: '',
        longAnswer: 'Team shares can be priced based on the expected future wins of each team, overall team sentiment, or fandom. The more wins a team gets, the more win payouts they earn on SimBull. Since team shares last forever, it’s not just about the amount of wins in the next season, but also the amount of wins over the next few years too. For example, say the Rams and 49ers are both valued at $50.00 on SimBull and you expect the Rams to average 10 wins a year over the next 5 years and the 49ers to average 8 wins a year over the next 5 years. You would rather buy the SimRams on SimBull because they will earn you more win payouts for the same price.',
        type: "sharePriceExample"
    },
    {
        question: 'How should I price a team share? (Example)',
        shortAnswer: '',
        longAnswer: 'In another example, say you think Zach Wilson is going to be the next star quarterback in the NFL. Currently on SimBull, the SimJets are the 29th priced team in the NFL market but you think over the next 3 years they will be a top 10 team. You may want to buy the SimJets while they are cheap and not winning very many games, with the expectation their share price will rise once they start winning more games.',
        type: "sharePriceExample2"
    },
    {
        question: 'What is a bid?',
        shortAnswer: '',
        longAnswer: 'A bid is the most you (or another user) is willing to pay for a team share. Bids listed on the market means another user is wiling to buy the team share at that price.',
        type: "bid"
    },
    {
        question: 'What is an ask?',
        shortAnswer: '',
        longAnswer: 'An ask is the least you (or another user) is willing to sell a team share for. Asks listed on the market means is willing to sell the team at that price.',
        type: "ask"
    },
    {
        question: 'How do I buy and sell a team shares?',
        shortAnswer: '',
        longAnswer: [`Buying a team: A market buy allows you to immediate buy team shares at the best available price on the market. If you want to buy a team go to the Trade page → Choose your team → Click Trade → Click Buy → Enter the dollar amount you want to purchase → Submit your trade. This will match you with a seller and it will buy the team share at the best available price.
        `,
        `
Selling a team: A market sell allows you to immediate sell team shares at the best available price on the market. The process is similar for selling a team. Go to the Trade page or the Assets page → Choose your team → Click trade → Click Sell → Enter the amount`],
    type: "buySell",
    para: true,
},
    {
        question: 'Can I short teams?',
        shortAnswer: '',
        longAnswer: 'No, you are not able to short sell teams on SimBull. We may add that functionality in the future. ',
        type: "shorting"
    },
    {
        question: 'How do I deposit?',
        shortAnswer: '',
        longAnswer: 'Deposits are made through PayPal. Go to Accounts → Transfers → Add Cash and then select your preferred deposit method. SimBull uses PayPal to accept bank transfers and credit card transactions.',
        type: "deposit"
    },
    {
        question: 'How do I withdraw?',
        shortAnswer: '',
        longAnswer: 'Withdrawals are paid though PayPal. Go to accounts → Transfers → Request Your Money and then enter your withdrawal amount and PayPal email. Once we verify your request, funds will transfer in 1-3 business days. Withdrawals must wait seven days after the initial deposit to ensure deposited funds clear.',
        type: "withdraw"
    },
    {
        question: 'Is SimBull gambling?',
        shortAnswer: '',
        longAnswer: 'No, SimBull is considered a game of skill and does not fall under gambling laws. Users on SimBull do not make wagers on individual events and do not lose money to SimBull when teams they own lose, rather they trade teams based on their sports knowledge and the share prices in the market. Due to the long term and peer to peer nature of the market SimBull is classified as a game of skill.',
        type: "gambling"
    },
    {
        question: 'Is SimBull available in my state?',
        shortAnswer: '',
        longAnswer: 'SimBull operates in 41 states due to game of skill laws - with the exceptions being Arizona, Arkansas, Connecticut, Delaware, Louisiana, Montana, South Carolina, South Dakota, and Tennessee. We are hoping to add those states in the future!',
        type: "legalStates"
    },
    {
        question: 'Do I report my earnings to the IRS?',
        shortAnswer: '',
        longAnswer: 'Current tax law requires SimBull to provide you and the IRS with a 1099-MISC form if you earn more than $600 from the SimBull market in a calendar year. If that occurs, SimBull will ask for information in order to send the appropriate tax documents.',
        type: "IRS"
    },
    {
        question: 'Are there trading fees?',
        shortAnswer: '',
        longAnswer: 'Yes, SimBull charges a 1.5% trading fee on all trades executed in the market. Trading fees earn SimBull revenue that helps pay win payouts and fund the business! SimBull offers a $4.99/mo subscription for active users who wish to avoid the trading fee.',
        type: "tradingFees"
    },
    {
        question: 'Do you have a trading API?',
        shortAnswer: '',
        longAnswer: 'Yes. Email customer-service@simbull.app for more information.',
        type: "tradingAPI"
    },
    {
        question: 'Where are deposited funds held?',
        shortAnswer: '',
        longAnswer: 'User deposits are held in a separate account that differs from the SimBull business account. Users deposits are held in reserve and are not used to fund business expenses.',
        type: "depositedFundsHolding"
    },
    {
        question: 'How do I contact SimBull?',
        shortAnswer: '',
        longAnswer: 'Visit the help page or email us at customer-service@simbull.app!',
        type: "contact"
    },
    {
        question: 'Can I buy shares in individual players?',
        shortAnswer: '',
        longAnswer: 'No, SimBull only offers team shares right now. In the future we want to add individual player sports such as golf and UFC, along with individual players on team sports!',
        type: "individualPlayers"
    },
]