import axios from 'axios'
import {route} from '../route'

/* email_capture */
export const email_capture = async (email) => {
    const resetOptions = {
        method: 'GET',
        url: `${route}/password_reset`,
        params: {
            "email": email,
        },
    }
    let carry
    await axios.request(resetOptions).then(function (response) {
        let results = response
        carry = results
    }).catch(err => carry = err.response)
    return carry
}

/* reset_password */
export const reset_password = async (password, token) => {
    const resetOptions = {
        method: 'POST',
        url: `${route}/password_reset`,
        params: {
            "password": password,
            "token": token,
        },
    }
    let carry
    await axios.request(resetOptions).then(function (response) {
        let results = response
        carry = results
    }).catch(err => carry = err.response)
    return carry
}