import React, {useState, useEffect} from 'react'
import './Analysis.css'
import {db, firebaseAnalytics} from '../../services/firebase'
import ReactHtmlParser from 'react-html-parser'
import Article from './Article'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import firebase from 'firebase'
//Game Cards
import NFLgame from './Schedule/NFLgame'

const Schedule = () => {

    //Games
    const [nfl, setNfl] = useState([])
    const [cfb, setCfb] = useState([])
    const [mlb, setMlb] = useState([])
    const [nba, setNba] = useState([])

    const [league, setLeague] = useState('nfl')

    //Date
    const [nflWeek, setNflWeek] = useState(1)
    const [cfbDate, setCfbDate] = useState(3)
    const cfbDates = ['August 28th (Week 0)', 'September 4th (Week 1)', 'September 11th (Week 2)', 'September 18th (Week 3)', 'September 25th (Week 4)', 'October 2nd (Week 5)', 'October 9th (Week 6)', 'October 16th (Week 7)', 'October 23rd (Week 8)', 'October 30th (Week 9)', 'November 6th (Week 10)', 'November 13th (Week 11)', 'November 20th (Week 12)', 'November 27th (Week 13)']
    const [mlbNum, setMlbNum] = useState(3)
    const [mlbDate, setMlbDate] = useState(moment().format('YYYYMMDD'))
    useEffect(()=>{
        setMlbDate(moment().subtract(-mlbNum, 'd').format('YYYYMMDD'))
    },[mlbNum])
    let nbaNum = 0
    const [nbaDate, setNbaDate] = useState(moment().format('YYYYMMDD'))
    useEffect(()=>{
        setNbaDate(moment().subtract(-nbaNum, 'd').format('YYYYMMDD'))
    },[nbaNum])


    /*const giveWeek = () => {
        for(let i = 1; i < 19; i++){
            let thursday = moment('2021-09-09').subtract(1-i, 'weeks').format('YYYY-MM-DD')
            let sunday = moment('2021-09-12').subtract(1-i, 'weeks').format('YYYY-MM-DD')
            let monday = moment('2021-09-13').subtract(1-i, 'weeks').format('YYYY-MM-DD')
            
            weeks.[thursday] = i
            weeks.[sunday] = i
            weeks.[monday] = i
            //console.log(weeks[`${thursday}`])
        }
    }*/
    //useEffect(()=>{giveWeek()})

    //Get Games
    const fixWeeks = async () => {
        let weeks = {}
        for(let i = 0; i < 19; i++){
            let tuesday = await moment('2021-09-14').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let wednesday = await moment('2021-09-08').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let thursday = await moment('2021-09-09').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let friday = await moment('2021-09-10').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let saturday = await moment('2021-09-11').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let sunday = await moment('2021-09-12').subtract(-i, 'weeks').format('YYYY-MM-DD')
            //let monday = await moment('2021-09-13').subtract(-i, 'weeks').format('YYYY-MM-DD')
            
            weeks.[tuesday] = i + 1
            //weeks.[wednesday] = i + 1
            //weeks.[thursday] = i + 1
            //weeks.[friday] = i + 1
            //weeks.[saturday] = i + 1
            //weeks.[sunday] = i + 1
            //weeks.[monday] = i + 1

            //console.log(weeks[`${thursday}`])
        }
        let games1 = []
        await db.collection('NFL2021Games').get().then(snapshot => {
            snapshot.forEach(doc => {
                const data = doc.data()
                games1.push(data)
                //console.log(data.gameId)
                let date = data.schedule.date.toString().substring(0,10)
                let holder = weeks[`${date}`]
                if(holder && data.gameId){
                    console.log(`${holder}: ${date}`)
                    db.collection('NFL2021Games').doc(data.gameId.toString()).update({
                        week: holder
                    }).then(res => console.log(data.gameId)).catch(err => console.log(data.gameId))
                }
                //console.log(holder)
                
            })
        })
    }
    //useEffect(()=>{fixWeeks()},[])
    //Need to add week 0 and 1 to cfb2021games DB

    const [weeksAdded, setWeeksAdded] = useState([])
    const getNFLgames = (week) => {
        console.log(weeksAdded)
        let arr1 = weeksAdded
        if(weeksAdded.indexOf(week) === -1 && league === 'nfl'){
            console.log(weeksAdded.indexOf(week))
            arr1.push(week)
            setWeeksAdded(arr1)
            console.log(weeksAdded)
            db.collection('NFL2021Games').where('week', '==', week).get().then(snapshot => {
                let newGames = []
                snapshot.forEach(doc => {
                    const data = doc.data()
                    newGames.push(data)
                    console.log(data)
                })
                setNfl(nfl.concat(newGames))
            })
        }
    }
    useEffect(()=>{getNFLgames(nflWeek)},[nflWeek, league])

    let weeksAddedCFB = []
    const getCFBgames = (week) => {
        console.log(weeksAddedCFB)
        let arr1 = weeksAddedCFB
        if(weeksAddedCFB.indexOf(week) === -1 && league === 'cfb'){
            console.log(weeksAdded.indexOf(week))
            arr1.push(week)
            weeksAddedCFB = arr1
            console.log(weeksAddedCFB)
            db.collection('CFB2021Games').where('week', '==', week).get().then(snapshot => {
                let newGames = []
                snapshot.forEach(doc => {
                    const data = doc.data()
                    newGames.push(data)
                    console.log(data)
                })
                setCfb(cfb.concat(newGames))
            })
        }
    }
    useEffect(()=>{getCFBgames(cfbDate)},[cfbDate, league])

    const [weeksAddedMLB, setWeeksAddedMLB] = useState([])
    const getMLBgames = (week) => {
        console.log(weeksAddedMLB)
        let arr1 = weeksAddedMLB
        if(weeksAddedMLB.indexOf(week) === -1 && league === 'mlb'){
            console.log(weeksAdded.indexOf(week))
            arr1.push(week)
            setWeeksAddedMLB(arr1)
            console.log(weeksAddedMLB)
            db.collection('mlbGames').where('date', '==', mlbDate).get().then(snapshot => {
                let newGames = []
                snapshot.forEach(doc => {
                    const data = doc.data()
                    newGames.push(data)
                    console.log(data)
                })
                setMlb(mlb.concat(newGames))
            })
        }
    }
    useEffect(()=>{getMLBgames(mlbDate)},[mlbDate, league])

    return(
        <div style={{paddingTop: '10vh', paddingBottom: '10vh', textAlign: 'center'}}>

            {/*League Slider */}
            <div>
                <select onChange={(e)=>setLeague(e.target.value)}>
                    <option value="nfl">NFL</option>
                    <option value="cfb">CFB</option>
                    <option value="mlb">MLB</option>
                    <option value="nba">NBA</option>
                </select>
            </div>
            {/*End League Slider */}

            {/*Date Finder */}
            <div>
                {league === 'nfl' && <>
                    <button onClick={()=>{nflWeek !== 1 && setNflWeek(nflWeek - 1)}}>prev</button>
                    <span>Week: {nflWeek}</span>
                    <button onClick={()=>{nflWeek !== 18 && setNflWeek(nflWeek + 1)}}>next</button>
                </>}

                {league === 'cfb' && <>
                <button onClick={()=>{cfbDate !== 0 && setCfbDate(cfbDate - 1)}}>prev</button>
                    <span>{cfbDates[cfbDate]}</span>
                    <button onClick={()=>{cfbDate !== 13 && setCfbDate(cfbDate + 1)}}>next</button>
                </>}

                {league === 'mlb' && <>
                <button onClick={()=>{setMlbNum(mlbNum-1)}}>prev</button>
                    <span>{mlbDate}</span>
                    <button onClick={()=>{setMlbNum(mlbNum+1)}}>next</button>
                </>}

                {league === 'nba' && <>
                <button onClick={()=>{nbaNum--}}>prev</button>
                    <span>{nbaDate}</span>
                    <button onClick={()=>{nbaNum++}}>next</button>
                </>}

            </div>
            {/*End Date Finder */}

            {/* NFL Display Games */}
            {nfl && league === 'nfl' && nflWeek && nfl.map(g => {
                if(g.week === nflWeek){
                    return(
                        <div key={g.summary}><NFLgame game={g} /></div>
                    )
                }
            })}
            {/*End Display Games */}

            {/*CFB Display Games */}
            {cfb.map(g => {
                if(g.week === cfbDate && league === 'cfb'){
                    return(
                        <div>
                            {g.summary}
                        </div>
                    )
                }
            })}
            {/*End Display Games */}

            {/*MLB Display Games */}
            {mlb && league === 'mlb' && mlbDate && mlb.map(g => {
                if(g.date === mlbDate){
                    return(
                        <div>
                            {g.awayTeam} @ {g.homeTeam}
                        </div>
                    )
                }
            })}
            {/*End Display Games */}


        </div>
    )
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(Schedule)