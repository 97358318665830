import React, {useState, useEffect} from 'react'
import Popup from 'reactjs-popup'

const Failed = (props) => {

    const [show, setShow] = useState(false)
    useEffect(()=>{
        console.log(props.guesses)
        if(props.guesses && props.guesses.length > 5 && !props.correct){
            setShow(true)
        }
    },[props.guesses])

    return(
        <>
        <Popup open={show} modal>
            <div style={{overflowY: 'scroll', borderRadius: '.3em', width: '90vw', marginLeft: '0vw', padding: '20px', textAlign: 'left', marginTop: '2vh', marginBottom: '0vh', backgroundColor: '#fffafa', boxShadow: 'rgba(99, 99, 99, 0.7) 0px 20px 80px 40px'}}>
                
                <div style={{padding: '3x', borderRadius: '.3em', border: '1px solid red'}}>

                <div style={{paddingBottom: '0px', fontSize: '1.3rem', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '15px'}}><strong>Whomp Whomp!</strong></div>

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px'}}>
                    <strong>Sorry,</strong> you must've just been unlucky.
                </div>

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px'}}>
                    You already used up your six tries...
                </div>

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px'}}>
                    But I'm sure tomorrow you'll do better.
                </div>

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />

                <div style={{fontSize: '.9rem', fontWeight: '300', paddingBottom: '0px', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px'}}>
                    A new <strong>SPORTLE</strong> will be available each day!
                </div>

                <hr style={{marginLeft: '15px', marginRight: '15px'}} />


                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>setShow(false)} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '1px'}}>close</button>
                </div>

                </div>
                
            </div>
        </Popup>
        </>
    )
}

export default Failed