import React, {useState, useEffect} from 'react'
import { sendTeamDetails, isPick, getTeamSeed} from './util'
import moment from 'moment'
import {firebaseAnalytics} from '../../services/firebase'

const Scores = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(()=>{
      firebaseAnalytics.logEvent('SimBull15_Scores_visit')
    },[])

    const games = props.games ? props.games : []
    const teams = props.teams ? props.teams : []
    const currentPicks = props.currentPicks ? props.currentPicks : []
    const [view, setView] = useState('Upcoming')

    let completedGames = []
    let upcomingGames = []

    games.map(g => {
        if(g.status === 'FINAL'){
            completedGames.push(g)
        }
        else upcomingGames.push(g)
    })

    completedGames.sort(function(a, b) {
        let dateA = moment(`${a.timestamp}.000Z`)
        let dateB = moment(`${b.timestamp}.000Z`)
        return dateB - dateA
    })
    upcomingGames.sort(function(a, b) {
        let dateA = moment(`${a.timestamp}.000Z`)
        let dateB = moment(`${b.timestamp}.000Z`)
        return dateA - dateB
    })

    console.log(games)
    console.log(teams)

    let btnObj = {
        width: isMobile ? '30.3%' : '13.66%',
        marginLeft: isMobile ? '1.5%' : '1.5%',
        marginRight: isMobile ? '1.5%' : '1.5%',
        marginBottom: isMobile ? '5px' : '0px',
        backgroundColor: 'rgb(240,240,240)',
        border: 'none',
        outline: 'none',
        color: 'gray',
        fontSize: isMobile ? '.7rem' : '1rem'
    }

    
    
    return(
        <div style={{backgroundColor: 'rgb(240,240,240)', minHeight: '100vh', paddingBottom: '10vh', paddingTop: '10px',}}>
            <hr style={{margin: '0'}} />
            <div style={{textAlign: 'center', width: isMobile ? '99%' : '90%', marginLeft: isMobile ? '.5%' : '5%', }}>
                
                <button style={btnObj} onClick={()=>setView('Completed')}>
                    {view === 'Completed' ? <strong style={{color: 'black'}}>Completed</strong> : 'Completed'}
                </button>
                <button style={btnObj} onClick={()=>setView('Upcoming')}>
                    {view === 'Upcoming' ? <strong style={{color: 'black'}}>Upcoming</strong> : 'Upcoming'}
                </button>

            </div>
            <hr style={{margin: '0', marginBottom: '10px'}} />

            <div style={{paddingLeft: isMobile ? '5px' : '10%', paddingRight: isMobile ? '5px' : '10%',}}>
            {view === 'Completed' && completedGames && completedGames.map(g => {


                let day = (g.timestamp && g.timestamp !== null) ? moment(`${g.timestamp}.000Z`).format('ddd HH:mm') : ''
                let date = (g.timestamp && g.timestamp !== null) ? moment(`${g.timestamp}.000Z`).format('MM/DD') : ''

                if(g.home_team_id && g.away_team_id && teams){
                    let home_team = sendTeamDetails(teams, g.home_team_id)
                    let away_team = sendTeamDetails(teams, g.away_team_id)

                    home_team.seed = getTeamSeed(home_team.id)
                    away_team.seed = getTeamSeed(away_team.id)

                    let game_round = 'Regular Season'
                    let game_region = `${home_team.conference}ern`
                    
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '48%' : '23%', backgroundColor: 'white', marginLeft: '1%', marginRight: '1%', borderRadius: '.3em', boxShadow: '#c5b358 0px 1px 2px', marginBottom: '10px'}}>
                            <div style={{width: '100%', textAlign: 'left', paddingLeft: '10px', fontSize: '.8em', color: 'gray', fontWeight: '200'}}>
                                <div style={{display: 'inline-block', width: '50%', textAlign: 'left', paddingLeft: '5%'}}>{date} </div>
                                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', paddingRight: '5%'}}>{g.status === 'FINAL' ? 'Final' : (g.home_score === 0 && g.away_score === 0) ? day : g.period ? `${g.period}Q` : '' } </div>
                            </div>
                            
                            <table style={{width: '100%'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{home_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{home_team.name}{isPick(g.home_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score < g.away_score ? '🔴': '🟢'} </span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%', paddingRight: '1%'}}>{g.home_score === 0 ? '' : g.home_score}</td>
                                </tr>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{away_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{away_team.name}{isPick(g.away_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score > g.away_score ? '🔴': '🟢'}</span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%', paddingRight: '1%'}}>{g.away_score === 0 ? '' : g.away_score}</td>
                                </tr>
                                
                            </table>
                            
                            <div style={{width: '100%', textAlign: 'center', fontSize: '.7em', color: 'gray', fontWeight: '200', borderTop: '1px solid rgb(240,240,240)'}}>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_round}</div>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_region} Conference</div>
                            </div>
                        </div>
                    )
                }
                
            })}
            {view === 'Upcoming' && upcomingGames && upcomingGames.map(g => {


                let day = (g.timestamp && g.timestamp !== null) ? moment(`${g.timestamp}.000Z`).format('ddd HH:mm') : ''
                let date = (g.timestamp && g.timestamp !== null) ? moment(`${g.timestamp}.000Z`).format('MM/DD') : ''

                if(g.home_team_id && g.away_team_id && teams){
                    let home_team = sendTeamDetails(teams, g.home_team_id)
                    let away_team = sendTeamDetails(teams, g.away_team_id)

                    home_team.seed = getTeamSeed(home_team.id)
                    away_team.seed = getTeamSeed(away_team.id)

                    let game_round = 'Regular Season'
                    let game_region = `${home_team.conference}ern`
                    
                    return(
                        <div style={{display: 'inline-block', width: isMobile ? '48%' : '23%', backgroundColor: 'white', marginLeft: '1%', marginRight: '1%', borderRadius: '.3em', boxShadow: '#c5b358 0px 1px 2px', marginBottom: '10px'}}>
                            <div style={{width: '100%', textAlign: 'left', paddingLeft: '10px', fontSize: '.8em', color: 'gray', fontWeight: '200'}}>
                                <div style={{display: 'inline-block', width: '50%', textAlign: 'left', paddingLeft: '5%'}}>{date} </div>
                                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', paddingRight: '5%'}}>{g.status === 'FINAL' ? 'Final' : (g.home_score === 0 && g.away_score === 0) ? day : g.period ? `${g.period}Q` : '' } </div>
                            </div>
                            
                            <table style={{width: '100%'}}>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{home_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{home_team.name}{isPick(g.home_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score < g.away_score ? '🔴': '🟢'} </span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%', paddingRight: '1%'}}>{g.home_score === 0 ? '' : g.home_score}</td>
                                </tr>
                                <tr style={{width: '100%'}}>
                                    <td style={{textAlign: 'center', fontSize: '.7em', color: 'gray',width: '10%', paddingRight: '3px'}}>{away_team.seed}</td>
                                    <td style={{fontSize: isMobile ? '.75em' : '1em', fontWeight: '500'}}>{away_team.name}{isPick(g.away_team_id, currentPicks) ? <span style={{fontSize: '.6em'}}> {g.status === 'FINAL' && g.home_score > g.away_score ? '🔴': '🟢'}</span> : ''}</td>
                                    <td style={{color: 'gray', fontSize: isMobile ? '.75em' : '1em', textAlign: 'center',width: '10%', paddingRight: '1%'}}>{g.away_score === 0 ? '' : g.away_score}</td>
                                </tr>
                                
                            </table>
                            
                            <div style={{width: '100%', textAlign: 'center', fontSize: '.7em', color: 'gray', fontWeight: '200', borderTop: '1px solid rgb(240,240,240)'}}>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_round}</div>
                                <div style={{display: 'inline-block', width: '50%'}}>{game_region} Conference</div>
                            </div>
                        </div>
                    )
                }
                
            })}
            </div>
        
        </div>
    )
}

export default Scores 