import React, { useState, useEffect } from "react";
//import '../Acount.css'
import firebase from 'firebase'
import {db, firebaseAnalytics} from '../../../services/firebase'
import moment from 'moment'
import { connect, useDispatch } from "react-redux";
import ReactPixel from 'react-facebook-pixel'
import jwt_decode from 'jwt-decode'
import Popup from 'reactjs-popup'
import {useHistory} from 'react-router-dom'
import {getToken} from '../../../redux/actions/token'

function ReactPayPal(props) {
  const user = props.user

  const paypalRef = React.useRef();

  const history = useHistory()
  const dispatch = useDispatch()

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [paid, setPaid] = useState(false)

  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };


   // To show PayPal buttons once the component loads
   React.useEffect(() => {
    let user_id1 = jwt_decode(props.token).sub
    window.paypal
    .Buttons({
      createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: `SimBull Cash for user: ${user_id1}`,
                amount: {
                  currency_code: "USD",
                  value: props.amount
                }
              }
            ]
          });
      },
      onApprove: async function(data) {
          return fetch('https://api.simbull.com/paypal/capture_order', {
            method: "POST",
            headers: {
              'content-type': 'application/json',
              'Authorization': `Bearer ${props.token}`
            },
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function(res) {
            return res.json();
          }).then(function(details) {
            if(details.msg){
              setError(true)
              setErrorMsg('Error, please refresh and try again')
            }
            if(details.success){
              setPaid(true)
              ReactPixel.track('Purchase', {
                  value: props.amount, currency: 'USD'
              })
            }
          }).catch(err => {
            console.log(err)
            setError(true)
            setErrorMsg('Error, please try again')
          })
      },
          
      onError: (err) => {
        setError(true);
        setErrorMsg('Error in processing the payment! Please try again or contact customer-service@simbull.app')
      }
    })
    .render(paypalRef.current);
}, []);


const finishDeposit = () =>{
  if(props.buying){
    return window.location.href = `${window.location.href}?deposit`
  }
  else return window.location.href = 'https://simbull.com' 
  
}




  // Default Render
  return (
    <>
      {!error && !paid && <div className="Transfer-Paypal-Sheet" style={props && props.welcome && {paddingTop: '0%'}}>
        <div className="Transfer-Paypal-Sheet-Div">
          {props && !props.welcome && <div className="Transfer-Paypal-Sheet-Title">Deposit</div>}
          <div className="Transfer-Paypal-Sheet-Amount-txt">
            Amount: 
          </div>
          <div className="Transfer-Paypal-Sheet-Amount"><br></br>${props.amount}</div><hr />
          <p>Click the Payment Option You Prefer</p>
          <div ref={paypalRef} />
        </div>
      </div>}
      <Popup open={paid} modal>
        <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
            <div style={{textAlign: 'center', fontSize: '2.5em'}}>Deposit Confirmed!</div>
            <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                <div style={{color: 'gray', fontWeight: '300'}}>Deposit Amount</div>
                <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(parseFloat(props.amount).toFixed(2))}</div>
                <div style={{color: 'gray', fontWeight: '300'}}>Date</div>
                <div style={{fontSize: '1.5em', fontWeight: '500'}}>{moment().format('MM/DD/YYYY')}</div>
                <div style={{textAlign: 'center'}}>
                    <button onClick={finishDeposit} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                </div>
            </div>
        </div>
    </Popup>

    <Popup open={error} modal>
        <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px rgb(255,100,100, .7)',}}>
            <div style={{textAlign: 'center', fontSize: '2.5em'}}>Error!</div>
            <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                <div style={{fontSize: '1.5em', fontWeight: '500', textAlign: 'center'}}>{errorMsg}</div>
                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>{setError(false)}} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '1.1em', color: 'gray', marginTop: '10px'}}>close</button>
                </div>
            </div>
        </div>
    </Popup>
    </>
  );
}


const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.token.token,
});

export default connect(mapStateToProps)(ReactPayPal)
