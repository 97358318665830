import React, {useState, useEffect} from 'react'
import Bracket from './Bracket'
import { connect, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import AllTrades from '../Trade2/AllTrades'
import FullLeague from '../Trade2/FullLeague'
import FullList from '../Trade2/FullList'
import Leaderboard from './Leaderboard'
import Rules from './Rules'
import Scores from './Scores'
import {buttonStyle, selectedButtonStyle} from '../Trade2/Trade2'
import '../Trade2/Trade.css'
import {get_my_picks,get_my_mm_leaderboard,post_picks,get_mm_games,get_mm_leaderboard, get_mm_teams} from '../../routes/march_madness/march_madness'
import {sendTeamDetails} from './util'
import {firebaseAnalytics} from '../../services/firebase'

const SimBull16 = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    useEffect(()=>{window.scrollTo(0,0)},[])

    //let mobileBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FSB%2016%20march%20madness%20mobile%20banner%20(1500%20%C3%97%201000px)%20(750%20%C3%97%20500px)%20(600%20%C3%97%20400px)%20(300%20%C3%97%20200px).svg?alt=media&token=f849561a-7928-4cc5-9bdf-b73a50cc8b3b'
    //let desktopBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2Fsimbull16%20desktop%20banner.svg?alt=media&token=18fca7c5-a85d-4dac-bc66-ef6374476dd1'
    let mobileBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FSB%2016%20march%20madness%20desktop%20banner%20(1500%20%C3%97%201000px)%20(750%20%C3%97%20500px)%20(600%20%C3%97%20400px)%20(300%20%C3%97%20200px)%20(1).svg?alt=media&token=d1a0e5b9-521d-41c7-9c61-f20352b2adc1'
    let desktopBanner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/SimBull16%2FSB%2016%20march%20madness%20desktop%20banner.svg?alt=media&token=f131b24b-6aed-45ff-b1e7-3e11532422d1'

    let regions = ['West', 'East', 'Midwest', 'South']
    
    let rounds = ['First Round', 'Second Round', 'Sweet Sixteen', 'Elite Eight']
    let roundsRight = ['Elite Eight', 'Sweet Sixteen', 'Second Round', 'First Round']

    let finalRounds = ['Final Four', 'Championship']

    /*let games = [{gameID: "1",favorite_team:"Gonzaga",favorite_seed: "1",underdog_team:"Colgate",underdog_seed: "16",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "8",favorite_team:"Colorado St.",favorite_seed: "8",underdog_team:"Notre Dame",underdog_seed: "9",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "5",favorite_team:"Alabama",favorite_seed: "5",underdog_team:"Chattanooga",underdog_seed: "12",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "4",favorite_team:"Wisconsin",favorite_seed: "4",underdog_team:"South Dakota St.",underdog_seed: "13",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "3",favorite_team:"Texas Tech",favorite_seed: "3",underdog_team:"Montana St.",underdog_seed: "14",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "6",favorite_team:"LSU",favorite_seed: "6",underdog_team:"San Francisco",underdog_seed: "11",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "7",favorite_team:"Marquette",favorite_seed: "7",underdog_team:"Murray St.",underdog_seed: "10",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                {gameID: "2",favorite_team:"Duke",favorite_seed: "2",underdog_team:"Yale",underdog_seed: "15",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"West",},
                
                {gameID: "9",favorite_team:"Kentucky",favorite_seed: "1",underdog_team:"Longwood",underdog_seed: "16",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "16",favorite_team:"Seton Hall",favorite_seed: "8",underdog_team:"Indiana",underdog_seed: "9",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "13",favorite_team:"Texas",favorite_seed: "5",underdog_team:"North Texas",underdog_seed: "12",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "12",favorite_team:"Houston",favorite_seed: "4",underdog_team:"Vermont",underdog_seed: "13",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "11",favorite_team:"Providence",favorite_seed: "3",underdog_team:"Liberty",underdog_seed: "14",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "14",favorite_team:"Arkansas",favorite_seed: "6",underdog_team:"Oklahoma",underdog_seed: "11",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "15",favorite_team:"Wyoming",favorite_seed: "7",underdog_team:"Miami",underdog_seed: "10",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                {gameID: "10",favorite_team:"Purdue",favorite_seed: "2",underdog_team:"Cleveland St.",underdog_seed: "15",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"East",},
                
                {gameID: "17",favorite_team:"Auburn",favorite_seed: "1",underdog_team:"Southern",underdog_seed: "16",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "24",favorite_team:"Saint Mary's",favorite_seed: "8",underdog_team:"Loyola Chicago",underdog_seed: "9",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "21",favorite_team:"Michigan St.",favorite_seed: "5",underdog_team:"Davidson",underdog_seed: "12",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "20",favorite_team:"Villanova",favorite_seed: "4",underdog_team:"Toledo",underdog_seed: "13",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "19",favorite_team:"UCLA",favorite_seed: "3",underdog_team:"Seattle",underdog_seed: "14",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "22",favorite_team:"Xavier",favorite_seed: "6",underdog_team:"SMU",underdog_seed: "11",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "23",favorite_team:"Iowa",favorite_seed: "7",underdog_team:"Wake Forest",underdog_seed: "10",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                {gameID: "18",favorite_team:"Kansas",favorite_seed: "2",underdog_team:"Long Beach St.",underdog_seed: "15",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"Midwest",},
                
                {gameID: "25",favorite_team:"Arizona",favorite_seed: "1",underdog_team:"UNC Wilmington",underdog_seed: "16",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "32",favorite_team:"Boise St",favorite_seed: "8",underdog_team:"TCU",underdog_seed: "9",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "29",favorite_team:"Ohio State",favorite_seed: "5",underdog_team:"Oregon",underdog_seed: "12",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "28",favorite_team:"Tennessee",favorite_seed: "4",underdog_team:"Iona",underdog_seed: "13",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "27",favorite_team:"Illinois",favorite_seed: "3",underdog_team:"Wagner",underdog_seed: "14",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "30",favorite_team:"Uconn",favorite_seed: "6",underdog_team:"Iowa State",underdog_seed: "11",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "31",favorite_team:"USC",favorite_seed: "7",underdog_team:"North Carolina",underdog_seed: "10",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                {gameID: "26",favorite_team:"Baylor",favorite_seed: "2",underdog_team:"Texas St.",underdog_seed: "15",timestamp:"44637",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"First Round",region:"South",},
                
                {gameID: "33",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"West",},
                {gameID: "34",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"West",},
                {gameID: "35",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"West",},
                {gameID: "36",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"West",},
                {gameID: "37",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"East",},
                {gameID: "38",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"East",},
                {gameID: "39",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"East",},
                {gameID: "40",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"East",},
                {gameID: "41",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"Midwest",},
                {gameID: "42",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"Midwest",},
                {gameID: "43",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"Midwest",},
                {gameID: "44",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"Midwest",},
                {gameID: "45",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"South",},
                {gameID: "46",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"South",},
                {gameID: "47",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"South",},
                {gameID: "48",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44639",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Second Round",region:"South",},
                {gameID: "49",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"West",},
                {gameID: "50",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"West",},
                {gameID: "51",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"East",},
                {gameID: "52",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"East",},
                {gameID: "53",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"Midwest",},
                {gameID: "54",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"Midwest",},
                {gameID: "55",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"South",},
                {gameID: "56",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44644",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Sweet Sixteen",region:"South",},
                {gameID: "57",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44646",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Elite Eight",region:"West",},
                {gameID: "58",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44646",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Elite Eight",region:"East",},
                {gameID: "59",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44646",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Elite Eight",region:"Midwest",},
                {gameID: "60",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44646",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Elite Eight",region:"South",},
                {gameID: "61",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44653",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Final Four",region:"Final Four",},
                {gameID: "62",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44653",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Final Four",region:"Final Four",},
                {gameID: "63",favorite_team:"0",favorite_seed: "0",underdog_team:"0",underdog_seed: "0",timestamp:"44655",status: "SCHEDULED",favorite_score:"0",underdog_score: "0",round:"Championship",region:"Championship",},
    ]*/

    const [page, setPage] = useState('Bracket')
    const [display, setDisplay] = useState('trades')

    const [games, setGames] = useState([])
    const [teams, setTeams] = useState([])
    const [leaderboard, setLeaderboard] = useState([])
    const [my, setMy] = useState({})
    const [currentPicks, setCurrentPicks] = useState([])

    const sendPicks = (picks) => {
        setCurrentPicks(picks)
    }

    useEffect(()=>{
        get_mm_teams().then(res => {setTeams(res)})
        get_my_mm_leaderboard(props.token).then(res => {setMy(res)})
        //post_picks(props.token, [6,7,74], false).then(res => {console.log('post_picks');console.log(res)}).catch(err => console.log(err))
        get_mm_games().then(res => {setGames(res)})
        get_mm_leaderboard().then(res => {setLeaderboard(res)})
        firebaseAnalytics.logEvent('SimBull16_visit')
    },[])

    

    return(
        <>
        <div style={{paddingTop: isMobile ? `6vh` : `0vh`, backgroundColor: 'black'}}>
            <img style={{width: '100%'}} className="z10000" src={isMobile ? mobileBanner : desktopBanner} />
        </div>

        <div style={{textAlign: 'center', padding: '15px', paddingBottom: '5px', backgroundColor: 'black', color: 'white', fontSize: isMobile ? '.6rem' : '1rem', fontWeight: '600'}}>
            <Link onClick={()=>setPage('Bracket')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Bracket' ? '2px solid #c5b358' : ''}}>My Picks</div></Link>
            <Link onClick={()=>setPage('Leaderboard')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Leaderboard' ? '2px solid #c5b358' : ''}}>Leaderboard</div></Link>
            <Link onClick={()=>setPage('Rules')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Rules' ? '2px solid #c5b358' : ''}}>Rules</div></Link>
            <Link onClick={()=>setPage('Scores')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Scores' ? '2px solid #c5b358' : ''}}>Scores</div></Link>
            <Link onClick={()=>setPage('Market')}><div style={{color: 'white', display: 'inline-block', width: '20%', borderBottom: page === 'Market' ? '2px solid #c5b358' : ''}}>Market</div></Link>
        </div>
        
        {page === "Bracket" && <Bracket sendPicks={sendPicks} teams={teams} token={props.token} regions={regions} rounds={rounds} roundsRight={roundsRight} finalRounds={finalRounds} games={games} my={my} />}
        {page === 'Leaderboard' && <Leaderboard teams={teams} games={games} leaderboard={leaderboard} my={my} />}
        {page === 'Rules' && <Rules />}
        {page === 'Scores' && <Scores teams={teams} games={games} currentPicks={currentPicks} />}
        {page === "Market" && <div style={{backgroundColor: 'rgb(240,240,240)', minHeight: '100vh'}}>
            <div className='DesktopContainer' style={{textAlign: 'center'}}>
                <button style={display === 'movers' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('movers')}>Movers</button>
                <button style={display === 'trades' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('trades')}>Trades</button>
                <button style={display === 'teams' ? selectedButtonStyle : buttonStyle} onClick={()=>setDisplay('teams')}>Teams</button>
            </div>

                {display === 'movers' && <FullList market={true} />}
                {display === 'trades' && <AllTrades market={true} />}
                {display === 'teams' && <FullLeague market={true} />}

        </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    token: state.token.token,
    totals: state.totals.totals,
});

export default connect(mapStateToProps)(SimBull16)