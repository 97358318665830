import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {addToMyWatchlist, deleteFromMyWatchlist} from '../../../routes/user/watchlist'
import {getWatchlist} from '../../../redux/actions/user/watchlist'
import {useDispatch} from 'react-redux'


const WatchlistStar = (props) => {
    const dispatch = useDispatch()

    const [onWatchlist, setOnWatchlist] = useState(false)

    let id = props.id ? props.id : props._id

    console.log(props)
    console.log(id)

    useEffect(()=>{
        //intital watchlist setting
        let on = false
        if(props.watchlist && props.watchlist.teams){
            props.watchlist.teams.map(t => {
                if(t === id){
                    on = true
                }
            })
            setOnWatchlist(on)
        }
        else if(props.watchlist){
            props.watchlist.map(t => {
                if(t === id){
                    on = true
                }
            })
            setOnWatchlist(on)
        }
    },[props.watchlist])

    const updateWatchlist = () => {
        if(onWatchlist){
            setOnWatchlist(false)
            //remove from watchlist then dispatch
            deleteFromMyWatchlist(props.token, id).then(res => {
                //dispatch
                dispatch(getWatchlist(props.token))
            })
        }
        else{
            setOnWatchlist(true)
            //add to watchlist then dispatch
            addToMyWatchlist(props.token, id).then(res => {
                //dispatch
                dispatch(getWatchlist(props.token))
            })
        }
    }

    return(
        <>
        {props.id && <td onClick={updateWatchlist}>{onWatchlist ? <StarIcon style={{color: 'gold'}} /> : <StarOutlineIcon style={{color: 'white'}} />}</td>}
        {props._id && <span onClick={updateWatchlist}>{onWatchlist ? <StarIcon style={{color: 'gold', fontSize: '1.5rem', marginLeft: '8px', marginTop: '-4px'}} /> : <StarOutlineIcon style={{color: '#c4c4c4', fontSize: '1.5rem', marginLeft: '8px', marginTop: '-4px'}} />}</span>}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    watchlist: state.watchlist.watchlist
});

export default connect(mapStateToProps)(WatchlistStar)