import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import TeamCard from './TeamCard'
import {getTeam, sortGainers, sortLosers, sortHottest, sortHighPrice, sortLowPrice, sortAtoZ, sortZtoA, sortEquityValueHigh, sortEquityValueLow, sortEquityAmountHigh, sortEquityAmountLow} from '../utils'
import {Link} from 'react-router-dom'
import {getMyOffers} from '../../../routes/user/user'
import {deleteOffer} from '../../../routes/trading/trading'
import OfferCard from './OfferCard'

const Equity = (props) => {

    let teams = props.teamsValue

    const [sort, setSort] = useState('Largest')

    const [show, setShow] = useState("Assets")
    const showAssets = () => setShow("Assets"); const isAssets = () => {if(show === "Assets"){return true}else return false};
    const showOffers = () => setShow("Offers"); const isOffers = () => {if(show === "Offers"){return true}else return false};

    let gainers = "Gainers"
    let hottest = "Hottest"
    let losers = "Losers"
    let priceHigh = "Price: High"
    let priceLow = "Price: Low"
    let aToZ = "A to Z"
    let zToA = "Z to A"
    let equityHigh = "Largest"
    let equityLow = "Smallest"
    let equityAmountHigh = "LargestAmount"
    let equityAmountLow = "SmallestAmount"

    const [equity, setEquity] = useState([])
    const [updateEquity, setUpdateEquity] = useState(false)

    const runSort = (type) => {
        setEquity(false)
        setSort(type)
        if (type === gainers) {
            setEquity(sortGainers(props.equity, teams));
            setUpdateEquity(!updateEquity)
        }
        else if (type === losers) {
            setEquity(sortLosers(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if (type === hottest) {
            setEquity(sortHottest(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === priceHigh){
            setEquity(sortHighPrice(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === priceLow){
            setEquity(sortLowPrice(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === equityHigh){        
            setEquity(sortEquityValueHigh(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === equityLow){
            setEquity(sortEquityValueLow(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === equityAmountHigh){        
            setEquity(sortEquityAmountHigh(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === equityAmountLow){
            setEquity(sortEquityAmountLow(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === aToZ){
            setEquity(sortAtoZ(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
        else if( type === zToA){
            setEquity(sortZtoA(props.equity, teams))
            setUpdateEquity(!updateEquity)
        }
    }

    useEffect(()=>{
        if(props.equity && teams){
            runSort(equityHigh)
        }
    },[props.equity, teams])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [offers, setOffers] = useState([])
    const [asks, setAsks] = useState([])
    const [bids, setBids] = useState([])
    const [hasBids, setHasBids] = useState(0)
    const [hasAsks, setHasAsks] = useState(0)

    const [edit, setEdit] = useState(false)
    const [deletedOffers, setDeletedOffers] = useState([])


    const getOffers = (res) => {
            let arr = res
            let arrCount = res.length
            let hasBids1 = 0
            let hasAsks1 = 0
            let bids1 = []
            let asks1 = []
            res.map(o => {
                if(o.type === "BID"){
                    hasBids1++
                    bids1.push(o)
                }else if(o.type === "ASK"){
                    hasAsks1++
                    asks1.push(o)
                }
            })
            bids1.length > 0 && bids1.sort((a,b) => {
                return b.price - a.price
            })
            asks1.length > 0 && asks1.sort((a,b) => {
                return a.price - b.price
            })
            setBids(bids1)
            setAsks(asks1)
            setOffers(arr)
            setHasBids(hasBids1)
            setHasAsks(hasAsks1)
            console.log(arr)
    }
    useEffect(()=>{
        if(offers.length === 0 && props.token && props.teamsValue.length > 0){
            getMyOffers(props.token).then(res => {
                getOffers(res)
            })
        }
        else return false
    },[ props.offers, props.t, props.token, props.teamsValue])

    const deleteOneOffer = (o) => {
        deleteOffer(props.token, o.id).then(res => {
            const arr = deletedOffers
            setDeletedOffers([])
            arr.push(o)
            setDeletedOffers(arr)
            console.log(res)
            return null
        }).catch(err => {
            console.log(err.response)
        })
    }

    const deleteAll = () => {
        if(offers && offers.length > 0 && props.token){
            offers.map(o => {
                if(!deletedOffers.includes(o)){
                    deleteOffer(props.token, o.id).then(res => {
                        return res
                    }).catch(err => {
                        console.log(err.response)
                    })
                }
            })
            setDeletedOffers(offers)
        }
    }


    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '12px':'48px', paddingRight: isMobile ? '12px':'48px', paddingBottom: isMobile ? '8vh':'20px'}}>
            {isMobile ? <div style={{color: 'white', fontSize: '1.2rem', overflowX: 'visible', whiteSpace: 'nowrap', textAlign: 'left', marginLeft: '12px',}}>{(!equity || equity.length === 0) ? 'Buy A Team': <><Link onClick={showAssets} style={{color: isAssets() ? 'white' : 'rgb(100,100,100)', fontWeight: isAssets() ? '300' : '200'}}>Your Assets</Link><Link  onClick={showOffers} style={{color: isOffers() ? 'white' : 'rgb(100,100,100)', fontWeight: '200', marginLeft: '8px'}}>Your Offers</Link></>}</div> : null}    
            <table style={{width: '100%', color: 'rgb(100,100,100)', textAlign: 'center'}}>
                <tr style={{fontWeight: '300', fontSize: '.85rem', verticalAlign: 'bottom'}}>
                    <td style={{color: 'white', fontSize: '1.4rem', overflowX: 'visible', whiteSpace: 'nowrap'}}>{isMobile ? '':(!equity || equity.length === 0) ? 'Buy A Team': <Link onClick={showAssets} style={{color: isAssets() ? 'white' : 'rgb(100,100,100)'}}>Your Assets</Link>}</td>        
                    {isMobile ? <td></td> : <td style={{paddingLeft: '8px', color: 'rgb(100,100,100)', fontSize: '1.4rem', overflowX: 'visible', whiteSpace: 'nowrap', textAlign: 'left'}}><Link  onClick={showOffers} style={{color: isOffers() ? 'white' : 'rgb(100,100,100)', fontWeight: isOffers() ? '300' : '200', marginLeft: '8px'}}>Your Offers</Link></td>}
                    {isAssets()
                    ?
                    <>
                    {isMobile ? null : <td onClick={()=>runSort(sort === equityAmountHigh ? equityAmountLow : equityAmountHigh)}>Shares</td>}
                    <td onClick={()=>runSort(sort === equityHigh ? equityLow : equityHigh)}>Value</td>
                    {isMobile ? null : <td onClick={()=>runSort(sort === priceHigh ? priceLow : priceHigh)}>Price</td>}
                    <td onClick={()=>runSort(sort === gainers ? losers : gainers)}>1D</td>
                    </>
                    :
                    <>
                    <td onClick={()=>runSort(sort === equityAmountHigh ? equityAmountLow : equityAmountHigh)}>{isMobile ? 'Amt x Price' : 'Offer Amt'}</td>
                    {isMobile ? null : <td onClick={()=>runSort(sort === equityHigh ? equityLow : equityHigh)}>Offer Price</td>}
                    {isMobile ? null : <td onClick={()=>runSort(sort === priceHigh ? priceLow : priceHigh)}>Value</td>}
                    {isMobile ? null : <td onClick={()=>runSort(sort === gainers ? losers : gainers)}>Price</td>}
                    </>
                    }
                </tr>
            {isAssets() 
            ?
                <>
                    {equity && equity.map(q => {
                        let t = getTeam(q.asset_id, props.teamsValue)
                        if(q.amount > 0.001){
                            return <TeamCard key={t.team.id} equity={q} />
                        }
                    })}
                    {(!equity || equity.length === 0) && teams && teams.map(t => {
                        if((teams.indexOf(t) === 10) || (teams.indexOf(t) === 22) || (teams.indexOf(t) === 60) || (teams.indexOf(t) === 88) || (teams.indexOf(t) === 101)){
                            return <TeamCard key={t.team.id} equity={{asset_id: teams.indexOf(t), amount: 0.00}} />
                        }
                    })}
                </>
            :
                <>
                    {(offers && offers.length > 0) ? 
                        offers.map(o => {
                            if(deletedOffers.indexOf(o) === -1){
                                let t = getTeam(o.asset_id, props.teamsValue)
                                return <OfferCard deleteOneOffer={deleteOneOffer} key={o.id} team={t} offer={o} />
                            }
                        })
                    :
                        <div>No Offers</div>
                    }
                </>
            }
            
            </table>
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    equity: state.equity.equity,
});

export default connect(mapStateToProps)(Equity)