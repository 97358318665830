import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import './portfolio2.css'
import { useHistory } from 'react-router-dom'
import {sendSupportTicket} from '../../routes/user/help'
import { SettingsInputCompositeSharp } from '@mui/icons-material'
import logo1 from '../assets/Logo1.png'


const Help = (props) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [success, setSuccess] = useState(false)

    const [err, setErr] = useState(false)

    const getHelp = () => {
        if(name && email && message){
            sendSupportTicket(props.token, `${name}: ${message}`, email).then(res => {
                console.log(res)
                if(res.status && res.status == 400){
                    setErr('Sorry there was an error. You can email us directly at customer-service@simbull.app')
                    setSuccess(false)
                    let data = res.data
                    if(data && data.msg && data.msg.query && data.msg.query.email){
                        setErr(`This isn't a valid email, please try again`)
                    }
                    else if(data && data.msg && data.msg.query && data.msg.query.content){
                        setErr(`Error. Make sure to enter a message. You can email us directly at customer-service@simbull.app`)
                    }
                }
                else if(res.status && res.status !== 200){
                    setErr('Sorry there was an error. You can email us directly at customer-service@simbull.app')
                }
            })
            setSuccess(true)
        }
        else alert('Please fill out all the fields to submit.')
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', paddingBottom: '200px', textAlign: 'center', minHeight: '100vh', paddingTop: isMobile ? '0px':'100px'}}>
            
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '4vh', textAlign: 'left'}}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500', overflow: 'visible'}}>
                        <span style={{fontSize: '1.15rem'}}>Help</span>
                    </div>
                </div>
            : null}


            <div style={{width: '90%', marginLeft: '5%', borderRadius: '1rem', padding: '20px', backgroundColor: 'rgb(30,30,30)', color: 'white', }}>
                <div style={{textAlign: 'center', fontSize: '1.6em', fontWeight: '500', marginBottom: '12px'}}>Contact Us</div>
                <div style={{fontSize: '.8rem', fontWeight: '300', color: 'rgb(220,220,200)', textAlign: 'left', width: '80%', marginLeft: '11%'}}>Name</div>
                <input value={name} onChange={(e)=>setName(e.target.value)} style={{borderRadius: '.5rem', border: 'none', backgroundColor: 'rgb(80,80,80)', width: '80%', padding: '4px', color:'white', marginBottom: '12px' }} placeholder="your name" type="name" />
                <div style={{fontSize: '.8rem', fontWeight: '300', color: 'rgb(220,220,200)', textAlign: 'left', width: '80%', marginLeft: '11%'}}>Email</div>
                <input value={email} onChange={(e)=>setEmail(e.target.value)} style={{borderRadius: '.5rem', border: 'none', backgroundColor: 'rgb(80,80,80)', width: '80%', padding: '4px', color:'white', marginBottom: '12px' }} placeholder="yourname@email.com" type="name"  />
                <div style={{fontSize: '.8rem', fontWeight: '300', color: 'rgb(220,220,200)', textAlign: 'left', width: '80%', marginLeft: '11%'}}>Message</div>
                <textarea value={message} onChange={(e)=>setMessage(e.target.value)} style={{borderRadius: '.5rem', border: 'none', backgroundColor: 'rgb(80,80,80)', width: '80%', padding: '4px', color:'white', marginBottom: '12px' }} placeholder="type your message here..." type="name"  />
                <div><button onClick={getHelp} style ={{padding: '4px', paddingRight: '40px', paddingLeft: '40px', width: '60%', backgroundColor: '#c4b454', borderRadius: '1rem', border: 'none', outline: 'none'}}>Send</button></div>
                {success && <div style={{color: 'green', textAlign: 'center', fontWeight: '200'}}>Message Sent. We'll get back to you soon.</div>}
                {err && <div style={{color: 'red', textAlign: 'center', fontWeight: '200'}}>{err}</div>}
                <hr style={{backgroundColor: 'gray', marginTop: '40px'}} />
                <div style={{fontWeight: '300', fontSize: '.8rem', color: 'gray'}}>We are also available via email at:</div>
                <div>customer-service@simbull.app</div>
                <div style={{marginTop: '12px'}}><button style={{backgroundColor: 'rgb(30,30,30)', color: 'white', borderRadius: '2rem', padding: '6px', border: '1px solid white', fontSize: '.9rem', width: '40%'}}>Copy Email</button></div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token
});

export default connect(mapStateToProps)(Help)