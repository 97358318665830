import axios from 'axios'
import {route} from '../route'


//1. Get Spins
//2. Use Spins

/* Spins */
export const getSpins = async (token) => {
    const spinOptions = {
        method: 'GET',
        url: `${route}/@/spins`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "claimed": false,
        }
    }
    let spin 
    await axios.request(spinOptions).then(function (response) {
        let results = response.data
        spin = results
    }).catch(err => console.log(err))
    return spin
}

/* Use Spins */
export const runSpin = async (token, id) => {
    const spinOptions = {
        method: 'POST',
        url: `${route}/@/spins/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let spin 
    await axios.request(spinOptions).then(function (response) {
        let results = response.data
        spin = results
    }).catch(err => console.log(err))
    return spin
}