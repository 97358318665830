import React, {useState, useEffect} from 'react'
import './functions.css'
import {Link, withRouter, useHistory} from 'react-router-dom'
import {firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import {getOffersByTeam, makeTrade} from '../../routes/trading/trading'
import Loader from '../adHoc/Loader'
import amplitude from 'amplitude-js'

const SellShare = (props) => {
    //props
    const shares = props.shares
    const t = props.t
    const user = props.user
    const closeSell = props.closeSell

    const history = useHistory()

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    //states
    const [calc, setCalc] = useState('')
    const [url, setUrl] = useState(t && t.team && t.team.name ? `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t && t.team && t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`: '')
    const [showLoad, setShowLoad] = useState(true)
    const [tradeLoad, setTradeLoad] = useState(false)
    const [sellShares, setSellShares] = useState()
    const handleSellShares = (e) => {setSellShares(e.target.value)}
    const [calcUSD, setCalcUSD] = useState('')
    const [sellUSD, setSellUSD] = useState()
    const [openSell, setOpenSell] = useState(false)
    const [estimatedCost, setEstimatedCost] = useState(0)
    const [fee, setFee] = useState(props.myuser ? props.myuser.fees : true)
    const [feeAmt, setFeeAmt] = useState(props.myuser ? props.myuser.fees_amount : 0.01)

    const [unit, setUnit] = useState('usd')
    //errors
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    //fee calc
    useEffect(()=>{
        if(props.myuser && fee !== props.myuser.fees){
            setFee(props.myuser.fees)
            setFeeAmt(props.myuser.fees_amount)
        }
    },[props.myuser])

    //getImageURL
    useEffect(()=>{
        if(props.t && !url){
            setUrl(`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t && t.team && t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`)
        }
    }, [props.t])

    const [bids, setBids] = useState([])
    //offers
    useEffect(()=>{
        if(bids.length === 0 && props.t){
            getOffersByTeam(t.team.id, "BID", 50).then(res => {
                setShowLoad(false)
                let bidArr = res
                bidArr.sort(function compare(a, b) {
                    return b.price - a.price;
                })
                setBids(bidArr)
            }).catch(err => {
                setError(true)
                setErrorMsg(err.response.data)
            })
        }
    },[props.t])

    //Functionality
    //calc add digit
    const addDigit = (x) => {
        if(unit === 'shares'){
            let hold = calc
            if(x === '.' && hold[hold.length - 1] === '.'){
                return false 
            }
            else if(x === '.' && hold === ''){
                let thisNum = '0.'
                setCalc(thisNum)
            }
            else if(x === '0' && hold.length === 1 && hold === '0'){
                return false
            }
            else{
                let thisNum = hold.concat(x)
                setSellShares(parseFloat(thisNum))
                setCalc(thisNum)
            }
        }
        else if(unit === 'usd'){
            let hold = calcUSD
            if(x === '.' && hold[hold.length - 1] === '.'){
                return false 
            }
            else if(x === '.' && hold === ''){
                let thisNum = '0.'
                setCalcUSD(thisNum)
            }
            else if(x === '0' && hold.length === 1 && hold === '0'){
                return false
            }
            else{
                let thisNum = hold.concat(x)
                setSellUSD(parseFloat(thisNum))
                setCalcUSD(thisNum)
            }
        }
        
    }

    const deleteDigit = () => {
        if(unit === 'shares'){
            const editedText = calc.slice(0, -1)
            setSellShares(parseFloat(editedText))
            setCalc(editedText)
        }
        else if(unit === 'usd'){
            const editedText = calc.slice(0, -1)
            setSellUSD(parseFloat(editedText))
            setCalcUSD(editedText)
        }
        
    }

    const submitSell = () => {
        setOpenSell(true)
    }

    const handleError = () => {
        setOpenSell(false)
        setSellShares(0)
        setCalc('0')
        setSellUSD(0)
        setCalcUSD('0')
    }

    const confirmSell = async () => {
        if(props.t && props.token){
            setTradeLoad(true)
            makeTrade(props.token, "SELL", sellShares, t.team.id).then(res => {
                setTradeLoad(false)
                if(res.response && res.response.status === 400){
                    if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.amount && res.response.data.msg.query.amount[0]){
                        setError(true)
                        setErrorMsg(res.response.data.msg.query.amount[0])
                        handleError()
                    }
                    else if(res.response.data && res.response.data.msg && res.response.data.msg.query && res.response.data.msg.query.price && res.response.data.msg.query.price[0]){
                        setError(true)
                        setErrorMsg(res.response.data.msg.query.price[0])
                        handleError()
                    }
                    else {
                        setError(true)
                        setError(`Error: Sorry, please try again.`)
                        if(res.response.data && res.response.data.msg && typeof res.response.data.msg === 'string'){
                            setErrorMsg(res.response.data.msg)
                        }
                        handleError()
                    }
                    
                }
                else if(res.status === 400){
                    
                    setErrorMsg(res.data.msg)
                    setError(true)
                    handleError()
                }
                else if(res.response && res.response.status && res.response.status === 400){
                    
                    setErrorMsg(res.data.msg)
                    setError(true)
                    handleError()
                }
                else if(res.response && res.response.status === 401){
                    setError(true)
                    setErrorMsg("Sorry, please refresh and try again")
                    handleError()
                }
                else if(res.status && res.status === 401){
                    setError(true)
                    setErrorMsg("Sorry, please refresh and try again")
                    handleError()
                }
                else if(res.status === 500 || (res.response && res.response.status === 500)){
                    setErrorMsg(res.response.data.msg)
                    setError(true)
                    handleError()
                }
                else if(res.status && res.status === 200){
                    let receiptAmt = 0
                    let reciptCost = 0
                    res.data.map(r => {
                        if(r.type !== 'FEE'){
                            receiptAmt += r.amount
                        }
                        reciptCost += (r.type !== 'FEE' ? ((r.amount * r.price)) : ((-r.amount * r.price)))
                    })
                    history.replace({pathname: `/Receipts`, state: { shares: receiptAmt, totalCost: parseFloat(reciptCost).toFixed(2), team: t.team.name, type: "Sold", link: "Trade", welcome: false }})
                }
            }).catch(err => {
                setTradeLoad(false)
                setErrorMsg(err && err.response && err.response.data ? err.response.data : '')
                setError(true)
                handleError()
            })
        }
    }

    useEffect(()=>{
        let sellShares1 = parseFloat(sellShares)
        if(sellShares1 && sellShares1 !== NaN){
            let cost = 0
            let shares = 0
            bids.map(b => {
                if(shares < sellShares1){
                    if(b.remaining_amount < (sellShares1 - shares)){
                        cost += (b.price * b.remaining_amount * (fee ? (feeAmt +1) : 1))
                        shares += b.remaining_amount
                    }else{
                        cost += (((sellShares1 - shares) * b.price) * (fee ? (feeAmt +1) : 1))
                        shares += (sellShares1 - shares)
                    }
                }
            })
            setEstimatedCost(cost)
        }
    }, [sellShares])


    //letting you use the keyboard
    const handleKeyDown = (event) => {
        const keys = ['1','2','3','4','5','6','7','8','9','0','.']
        if(keys.includes(event.key)){
            addDigit(event.key !== '.' ? parseInt(event.key) : event.key)
        }
        else if(event.code === 'Backspace'){
            deleteDigit()
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
    
        // cleanup this component
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    });

    const sellMax = () => {
        setCalc(shares.toFixed(4))
        setSellShares(shares)
    }

    

    useEffect(()=>{
        if(openSell && unit === 'usd'){
            getOffersByTeam(t.team.id, "BID").then(res => {
                let bidArr = res
                bidArr.sort(function compare(a, b) {
                    return b.price - a.price;
                })
                let cost = 0
                let shares = 0
                bidArr.map(b => {
                    if(cost < sellUSD){
                        if((b.remaining_amount * b.price) < (sellUSD - cost)){
                            cost += ((b.price * b.remaining_amount) *  (fee ? (feeAmt +1) : 1))
                            shares += b.remaining_amount
                        }else{
                            let diff = sellUSD - cost
                            cost += diff
                            shares += (((diff * (fee ? (100 / (100+100 *feeAmt)) : 1)) / b.price))
                            setSellShares(shares)
                            setCalc(shares.toString())
                            setEstimatedCost(cost)
                        }
                    }
                })
            }).catch(err => {
                setError(true)
                setErrorMsg(err.response.data)
            })
        }
    },[openSell])

    const close = () => {
        setOpenSell(false)
        setCalc('')
        setCalcUSD('')
        setSellShares(0)
        setSellUSD(0)
    }

    const switchView = () => {
        if(unit === 'usd'){
            setUnit('shares')
        }
        else if(unit === 'shares'){
            setUnit('usd')
        }
        setCalc('')
        setCalcUSD('')
        setSellShares(0)
        setSellUSD(0)
    }

    return(
        <div id="SellShareDiv" className="TradePopup" style={{backgroundColor: 'black'}}>
            {showLoad && <Loader />}
            <div className="SellShare">
                <Link onClick={closeSell}><i className="fa fa-times-circle"></i></Link>
                <div className="ss-title">Sell</div>
                <div className='ss-team'><img className="ss-img"  src={url} alt='team' /><span className="tc-name-sim">Sim</span>{t && t.team.name}</div>
                <div className="h-bidask">
                    <div className="h-bid">
                        Bid
                        <br />
                        <div className="h-bid-p green">{t && t.bidding_price.toFixed(2)}</div>
                    </div>
                    <div className="h-price">
                        ${t && t.asking_price.toFixed(2)}
                    </div>
                    <div className="h-ask">
                        Ask
                        <br />
                        <div className="h-ask-p red">{t && t.asking_price.toFixed(2)}</div>
                    </div>   
                </div>
                
                <div className='ss-shares'>You own {shares && shares.toFixed(4)} shares</div>
                <div className='ss-cash'>Estimated Proceeds: ${unit === 'shares' ? estimatedCost.toFixed(2) : calcUSD}</div>

                {unit === 'shares' && <>
                    <div style={{textAlign: 'right', width: '20%', display: 'inline-block',}}><button onClick={sellMax} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'black'}}>Max</button></div>
                    <div className="ss-shareAmt2 underline">{calc ? calc : '0'}</div><div style={{width: '27%', display: 'inline-block', paddingLeft: '10px', textDecoration: 'none', fontSize: '1rem', backgroundColor: 'black'}}>shares</div>
                    <div style={{textAlign: 'left', width: '20%', display: 'inline-block'}}><button onClick={switchView} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'black'}}>USD</button></div>
                </>}
                {unit === 'usd' && <>
                    <div className="ss-shareAmt2 underline" style={{width: '60%', textAlign: 'center', marginLeft: '20%'}}>${calcUSD ? calcUSD : '0'}</div>
                    <div style={{textAlign: 'left', width: '20%', display: 'inline-block'}}><button onClick={switchView} style={{borderRadius: '1rem', border: '1px solid gray', padding: '7.5px', color: 'gray', backgroundColor: 'black'}}>shares</button></div>
                </>}

                <div className="ss-btn-div">
                    {error && <div style={{textAlign: 'center', color: 'red'}}>Error: {errorMsg}</div>}
                    <div className='center center-function'><button className="ss-submit" style={{backgroundColor: '#c4b454', borderRadius: '2rem',}} onClick={() => {submitSell(); amplitude.getInstance().logEvent("sell_continue")}}>Sell</button></div>
                    {[1,2,3,4,5,6,7,8,9,].map(item => 
                        <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none',}} onClick={()=>{addDigit(item.toString())}}>{item}</button>
                    )}
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none',}} onClick={()=>{addDigit(".")}}>.</button>
                    <button className="ss-btn" style={{backgroundColor: 'black', color: 'white', border: 'none', outline: 'none',}} onClick={()=>{addDigit("0")}}>0</button>
                    <button className="ss-btn-2" style={{backgroundColor: 'rgb(30,30,30)', color: 'white', border: 'none', outline: 'none',}} onClick={()=>{deleteDigit()}}><i className="fa fa-arrow-left"></i></button>
                </div>
            </div> 

            

            <Popup open={openSell} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0px 0px 5px 7.5px #c5b358',}}>
                    {tradeLoad && <Loader />}
                    <div onClick={() => amplitude.getInstance().logEvent("sell_confirm")} style={{textAlign: 'center', fontSize: '2.5em'}}>Confirm Sale</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                        <div style={{color: 'gray', fontWeight: '300'}}>Team</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>Sim{t&& t.team.name}</div>
                        <div onClick={()=> amplitude.getInstance().logEvent("sell_shares")} style={{color: 'gray', fontWeight: '300'}}>Shares</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>{sellShares && sellShares.toFixed(4)} Share{sellShares ===1 ? '' : 's'}</div>
                        <div style={{color: 'gray', fontWeight: '300'}}>Avg Price Per Share</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${((estimatedCost * (fee ? (100/101) : 1))/parseFloat(sellShares)).toFixed(2)}</div>
                        {fee && <>
                            <div style={{color: 'gray', fontWeight: '300'}}>Trade Fee ({(feeAmt * 100).toFixed(2)}%) <br /> <Link to="/Subscription" style={{fontWeight: '200', fontSize: '.7em'}}>Get Free Trading</Link></div>
                            <div style={{fontSize: '1.5em', fontWeight: '500'}}>${(estimatedCost*(1/101)).toFixed(2)}</div>
                        </>}
                        <div style={{color: 'gray', fontWeight: '300'}}>Total</div>
                        <div style={{fontSize: '1.5em', fontWeight: '500'}}>${estimatedCost.toFixed(2)}</div>
                        {!tradeLoad && <div style={{textAlign: 'center'}}>
                            <button onClick={() => {confirmSell(); amplitude.getInstance().logEvent("sell_confirm")}} style={{width: '60%', backgroundColor: '#c5b358', borderRadius: '.7em', fontWeight: '500', fontSize: '1.3em', marginTop: '20px'}}>Confirm</button><br />
                            <button onClick={close} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>}
                    </div>
                    <div style={{textAlign: 'center', fontSize: '.5em', fontWeight: '300', width: '80%', marginLeft: '10%'}}>**Prices may change due to market fluctuations. Trade will execute at the best available price.</div>
                </div>
            </Popup>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(withRouter(SellShare))