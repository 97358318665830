import React from 'react'
import './Questions.css'
import FAQs from '../../LandingPage3/FAQs'
import {Link} from 'react-router-dom'


const Questions = (props) => {
    return(<>
        <div style={{backgroundColor: "black",}}>
            <FAQs landing={true} />
        </div>
    </>)
}

export default Questions