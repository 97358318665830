import * as types from "../types";

export default function (state = {}, action) {
    const { type, payload } = action;

    switch (type) {
        case types.ADD_USER:
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
}