import * as types from "../types";
import {db} from '../../services/firebase'
import { getTeams } from "./teams";
import {getToken} from './token'
import {login, getTeamsValue} from '../../routes/auth/auth'

export const getUser = (authUser, auth, email) => (dispatch) => {
    //Logged In
    if(authUser && authUser.loggedIn){
      if(auth.currentUser.email !== 'ianzamora@yahoo.com'){//'ianzamora@yahoo.com'){
        db.collection('Users').doc(auth.currentUser.email).onSnapshot(doc => {
            dispatch({
                type: types.ADD_USER,
                payload: doc.data(),
            })
            dispatch(getTeams())
            
        })
        /*login(auth.currentUser.email, 'password').then(res => {
            dispatch(getToken(res))
        })*/
      }
      else{
        db.collection('Users').doc('dwfree@comcast.net').onSnapshot(doc => {
            dispatch({
                type: types.ADD_USER,
                payload: doc.data(),
            })
            dispatch(getTeams())
            
        })
        /*login('dwfree@comcast.net', 'password').then(res => {
            dispatch(getToken(res))
        })*/

      }
      
      

    }//Logged Out
    else{
          dispatch(getTeams())
          if(email){
            db.collection('Users').doc(email).onSnapshot(doc => {
              dispatch({
                  type: types.ADD_USER,
                  payload: doc.data(),
              })
              
          })
          }
    }
  }