import React, {useState, useEffect} from 'react'
import './SimBull16.css'
import {Link} from 'react-router-dom'
import {sendBracketDetails, sendTeamDetails, hasLoss, isOriginal, returnCircle} from './util'

const LeftBracket = (props) => {

    let rounds = props.rounds
    let games = props.games
    let region = props.region
    let mm_teams = props.teams

    let makeNewPick = props.makeNewPick
    
    const [newPicks, setNewPicks] = useState(props.newPicks)
    useEffect(()=> {if(props.newPicks !== newPicks){setNewPicks(props.newPicks)}},[props.newPicks])
    const [currentPicks, setCurrentPicks] = useState(props.currentPicks)
    useEffect(()=> {if(props.currentPicks !== currentPicks){setCurrentPicks(props.currentPicks)}},[props.currentPicks])
 
    const addTeam = (team_id, seed) => {
        makeNewPick({
            team: team_id,
            seed: seed,
        })
    }

    console.log(games)

    

    return(
        <>
        <main id="tournament">
        {mm_teams && mm_teams.length > 0 &&rounds && rounds.map(r => {
            return(
                <ul class={`round round-${rounds.indexOf(r) + 1} ${r === 'First Round' ? 'firstRound' : ''}`}>
                    {games && games.map(g => {
                        let game_details = sendBracketDetails(g.id)
                        let game_round = game_details ? (game_details[0] ? game_details[0] : '') : ''
                        let game_region = game_details ? (game_details[1] ? game_details[1] : '') : ''
                        if(r === game_round && region === game_region){
                            let home_team = sendTeamDetails(mm_teams, g.home_team_id)
                            let away_team = sendTeamDetails(mm_teams, g.away_team_id)
                            return(
                                <>
                                    <li class="spacer">&nbsp;</li>
                                    <Link onClick={()=>addTeam(home_team.id, home_team.seed)} style={{color: 'black'}}>
                                    <li class="game game-top">
                                        <span className="seed">{home_team.seed ? home_team.seed : ''}{' '}</span>
                                        <span
                                            style={{overflow: 'visible', zIndex: '100', fontWeight: (newPicks.some(p => p.team === home_team.id) || currentPicks.some(p => p.team === home_team.id)) ? '600' : '400', fontStyle: newPicks.some(p => p.team === home_team.id) ? 'italic' : 'none' }}
                                        >{home_team.name !== null ? home_team.name : ''} </span>
                                        <span style={{fontSize: '.6em'}}>{' '}{(newPicks.some(p => p.team === home_team.id) && '🟠')}{(currentPicks.some(p => p.team === home_team.id) ? returnCircle(g, 'home', home_team.id, true, props.originalPicks) : returnCircle(g, 'home', home_team.id, false, props.originalPicks))}{' '}</span>
                                        <span className="gameSpan"></span>
                                    </li>
                                    </Link>

                                    <li class="game game-spacer">&nbsp;</li>

                                    <Link onClick={()=>addTeam(away_team.id, away_team.seed)} style={{color: 'black'}}>
                                    <li class="game game-bottom ">
                                        <span className="seed">{away_team.seed ? away_team.seed : ''}{' '}</span>
                                        <span
                                            style={{fontWeight: (newPicks.some(p => p.team === away_team.id) || currentPicks.some(p => p.team === away_team.id)) ? '600' : '400', fontStyle: newPicks.some(p => p.team === away_team.id) ? 'italic' : 'none' }}
                                        >{away_team !== null ? away_team.name : ''}</span>
                                        <span style={{fontSize: '.6em'}}>{' '}{(newPicks.some(p => p.team === away_team.id) && '🟠')}{(currentPicks.some(p => p.team === away_team.id) ? returnCircle(g, 'away', away_team.id, true, props.originalPicks) : returnCircle(g, 'away', away_team.id, false, props.originalPicks))}{' '}</span>
                                        <span className="gameSpan"></span>
                                    </li>
                                    </Link>
                                </>
                            )
                        }
                    })}
                    <li class="spacer">&nbsp;</li>
                </ul>
            )
        })}
        <ul class="round round-5">
            {false && games && region === 'West' && games.map(g => {
                if(g.id === 61){

                    let name = 'Duke'
                    let id = 14
                    let seed = 2
                    return(
                            <>
                            <li class="spacer">&nbsp;</li>

                            <span className="seed">{seed ? seed : ''}{' '}</span>
                            <span
                                style={{overflow: 'visible', zIndex: '100', fontWeight: (newPicks.some(p => p.team === id) || currentPicks.some(p => p.team === id)) ? '600' : '400', fontStyle: newPicks.some(p => p.team === id) ? 'italic' : 'none' }}
                            >{name !== null ? name : ''} </span>
                            <span style={{fontSize: '.6em'}}>{' '}{(newPicks.some(p => p.team === id) && '🟠')}{(currentPicks.some(p => p.team === id) ? returnCircle(g, 'home', id, true, props.originalPicks) : returnCircle(g, 'home', id, false, props.originalPicks))}{' '}</span>
                            <span className="gameSpan"></span>

                            <li class="game game-top"></li>

                            
                            <li class="spacer">&nbsp;</li>
                            
                            
                            </>
                    )
                }
            })
                        
            }

        </ul>   
        </main>
        </>
    )
}

export default LeftBracket