import {
    get_teammates, 
    get_teammates_summary, 
    get_teammates_transactions,
    get_teammate_transactions,
    get_teammate_equity,
    get_teammate_summary,
} from '../../routes/teammates/teammates'

import {
    follow_teammate,
    unfollow_teammate,
    search_teammate,
} from '../../routes/teammates/follow_unfollow'

import {
    get_follow_request,
    accept_follow_request,
    deny_follow_request,
} from '../../routes/teammates/requests'

import {
    search_for_teammates,
    search_for_me,
} from '../../routes/teammates/search'

/* --------------- teammates.js --------------- */
/*1. Get Teammate List*/
export const getTeammates = async (token) => {
    let teammates = await get_teammates(token)
    return teammates
}
/*2. Get Teammates Transactions*/
export const getTeammatesTransactions = async (token) => {
    let transactions = await get_teammates_transactions(token)
    return transactions
}
/*3. Get Teammate Summarys*/
export const getTeammatesSummary = async (token) => {
    let summarys = await get_teammates_summary(token)
    return summarys
}
/*4. Get Teammate Transactions*/
export const getTeammateTransactions = async (token, user_id) => {
    let transactions = await get_teammate_transactions(token, user_id)
    return transactions
}
/*5. Get Teammate Equity*/
export const getTeammateEquity = async (token, user_id) => {
    let equity = await get_teammate_equity(token, user_id)
    return equity
}
/*6. Get Teammate Summary*/
export const getTeammateSummary = async (token, user_id) => {
    let summary = await get_teammate_summary(token, user_id)
    return summary
}
/* --------------- end teammates.js --------------- */


/* --------------- follow_unfollow.js --------------- */
/*1. Follow Teammate */
export const follow = async (token, username) => {
    let teammate = await follow_teammate(token, username)
    return teammate
}
/*2. Unfollow Teammate */
export const unfollow = async (token, username) => {
    let teammate = await unfollow_teammate(token, username)
    return teammate
}
/*3. Search Teammate */
export const searchTeammate = async (token, username) => {
    let teammate = await search_teammate(token, username)
    return teammate
}
/* --------------- end follow_unfollow.js --------------- */


/* --------------- requests.js --------------- */
/*1. Get My Teammate Requests */
export const getFollowRequests = async (token) => {
    let teammate = await get_follow_request(token)
    return teammate
}
/*2. Accept a Teammate Rquest */
export const acceptFollowRequest = async (token, user_id) => {
    let teammate = await accept_follow_request(token, user_id)
    return teammate
}
/*3. Deny a Teammate Request */
export const denyFollowRequest = async (token, user_id) => {
    let teammate = await deny_follow_request(token, user_id)
    return teammate
}
/* --------------- end requests.js --------------- */


/* --------------- search.js --------------- */
/*1. Search for a username */
export const searchUsername = async (token, username) => {
    let teammate = await search_for_teammates(token, username)
    return teammate
}
/*2. Get Random Accounts */
export const getNewTeammates = async (token) => {
    let teammate = await search_for_me(token)
    return teammate
}

/* --------------- end search.js --------------- */





/*adding commas to numbers, returns string*/
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/* Get Username From ID and Teammates List */
export const getUsername = (user_id, teammates) => {
    let user = ''
    teammates.map(t => {
        if(t.teammate.follow_id === user_id){
            user = t.username
            return t.username
        }
    })
    return user
}

/* Get Teammate From ID and Teammates List */
export const getTeammateObj = async (user_id, teammates, token) => {
    let user = ''
    if(teammates && teammates.length > 0){
        teammates.map(t => {
            if(t.teammate.follow_id === user_id){
                user = t
                return t
            }
        })
    }
    else{
        let sum = await getTeammateSummary(token, user_id)
        user = sum
        return sum
    }
    return user
}

/* Get Teammate From ID and Teammates List with For Sure Teammate */
export const getTeammateObj_teammate = (user_id, teammates, token) => {
    let user = ''
    if(teammates && teammates.length > 0){
        teammates.map(t => {
            if(t.teammate.follow_id === user_id){
                user = t
                return t
            }
        })
    }
    return user
}

/* Am I Following this Teammate From Username and Teammates List */
export const following = (username, teammates) => {
    let user = false
    teammates.map(t => {
        if(t.username === username){
            user = t
            return t
        }
    })
    return user
}

/* Am I Following this Teammate From Username and Teammates List */
export const following_by_id = (user_id, teammates) => {
    let user = false
    teammates.length > 0 && teammates.map(t => {
        if(t && t.follow_id && t.follow_id === user_id){
            user = true
            return true
        }
        else if(t && t.teammate && t.teammate.follow_id && t.teammate.follow_id === user_id){
            user = true
            return true
        }
    })
    return user
}
/* Is this Teammate Public From Username and Public Account List */
export const public_by_id = (user_id, teammates) => {
    let user = false
    teammates && teammates.length > 0 && teammates.map(t => {
        if(t && t.teammate && t.teammate.id === user_id){
            user = true
            return true
        }
    })
    return user
}

/* Get Team From ID and Team List */
export const getTeam = (team_id, teams) => {
    let team = ''
    teams.map(t => {
        if(t.team.id === team_id){
            team = t.team.name
            return t.team.name
        }
    })
    return team
}

/* Get Team From ID and Team List */
export const getTeamChange = (team_id, teams) => {
    let team = ''
    teams.map(t => {
        if(t.team.id === team_id){
            team = t
        }
    })
    return team
}

/* Get Payout Amount From ID and Team List */
export const getPayoutAmount = (team_id, teams) => {
    let team = ''
    teams.map(t => {
        if(t.team.id === team_id){
            team = t.team.league
        }
    })
    let amount = .5
    if(team === 'NBA' || team === 'NHL'){
        amount = 0.1
    }
    else if(team === 'MLB'){
        amount = 0.05
    }
    return amount
}
