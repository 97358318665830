import React from 'react'
import './NumberProof.css'

const NumberProof = () => {
    return(
        <div className="NumberProof">
            <div className="NP-div">
                <div className="NP-number">
                <img src="https://ucarecdn.com/741a4718-db1c-4bdc-989b-7b5c033e4afb/-/crop/644x370/151,211/-/preview/pro-football-focus-(2).png" alt="pro football focus" className="pff" />
                </div>

                <div className="NP-number">
                <img src="https://ucarecdn.com/e0daed4c-b14a-4255-adbd-949f63e5eb97/sporttechie-image.png" alt="pro football focus"  className="sportstechie" />
                </div>

                <div className="NP-number">
                    <img src="https://ucarecdn.com/94175e15-1e52-442a-b1c7-427f4c88046f/-/crop/500x117/0,162/-/preview/untitled-design-(3).png" alt="pro football focus"  className="techstars" />
                </div>
            </div>

        </div>
    )
}

export default NumberProof