import axios from 'axios'
import {route} from '../route'

//1. User's Value Summary
//2. User's Price History
//3. User's Equity
//4. User's Uncollected Payouts
//5. User's Payout History
//6. User's Offers (all)
//7. User's Offers By Team
//8. User's Trades
//9. User's Trades By Team
//10. User's Transfers
//11. My User

/* 1. Value Summary */
export const getTotals = async (token) => {
    const balanceOptions = {
        method: 'GET',
        url: `${route}/@/value_summary`,
        params: {},
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let amount = { cash_balance: 0, equity_worth: 0, total_change: 0, total_value: 0, total_withdrawn: 0, }
    await axios.request(balanceOptions).then(function (response) {
        let result = response.data
        amount = result
    }).catch(err => console.log(err))
    return amount
}

/* 2. User Price History */
export const getUserPrices = async (token, interval, start) => {
    let period = interval ? interval : 'DAY'
    const balanceOptions = {
        method: 'GET',
        url: `${route}/@/value_history`,
        params: {
            "interval": period,
            "after": start
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let array
    await axios.request(balanceOptions).then(function (response) {
        let result = response.data
        array = result
    }).catch(err => console.log(err))
    return array
}

/* 3. Equity */
export const getEquity = async (token) => {
    const equityOptions = {
        method: 'GET',
        url: `${route}/@/equity`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let equity = []
    await axios.request(equityOptions).then(function (response) {
        let results = response.data
        equity = results
    }).catch(error => console.error(error));
    return equity
}

/* 4. Uncollected Payouts */
export const getUncollectedPayouts = async (token) => {
    const payoutsOptions = {
        method: 'GET',
        url: `${route}/@/payouts`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "claimed": false,
        }
    }
    let payouts = []
    await axios.request(payoutsOptions).then(function (response) {
        let results = response.data
        payouts = results
    }).catch(err => console.log(err))
    return payouts
}

/* 5. Payout History */
export const getPayouts = async (token) => {
    const payoutsOptions = {
        method: 'GET',
        url: `${route}/@/payouts`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let payouts = []
    await axios.request(payoutsOptions).then(function (response) {
        let results = response.data
        payouts = results
    }).catch(err => console.log(err))
    return payouts
}


/* 6. My Open Offers */
export const getMyOffers = async (token)  => {
    const offersOptions = {
        method: 'GET',
        url: `${route}/@/offers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "completed": false
        }
    }
    let offers = []
    await axios.request(offersOptions).then(function (response) {
        let results = response.data
        offers = results
    }).catch(err => console.log(err))
    return offers
}

/* 7. My Open Offers by Team */
export const getMyOffersByTeam = async (token, asset_id)  => {
    const offersOptions = {
        method: 'GET',
        url: `${route}/@/offers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            "completed": false,
            "asset_id": asset_id
        }
    }
    let offers = []
    await axios.request(offersOptions).then(function (response) {
        let results = response.data
    }).catch(err => console.log(err))
    return offers
}

/* 8. My Trades */
export const getMyTrades = async (user_id) => {
    const tradesOptions = {
        method: 'GET',
        url: `${route}/trades`,
        headers: {
            //Authorization: `Bearer ${token}`,
        },
        params: {
            "user_id": user_id
        }
    }
    let trades = []
    await axios.request(tradesOptions).then(function (response) {
        let results = response.data
        trades = results
    }).catch(err => console.log(err))
    return trades
}

/* 9. My Trades By Team */
export const getMyTradesByTeam = async (user_id, asset_id, limit) => {
    const tradesOptions = {
        method: 'GET',
        url: `${route}/trades`,
        headers: {
            //Authorization: `Bearer ${token}`,
        },
        params: {
            "user_id": user_id,
            "asset_id": asset_id,
            "limit": limit
        }
    }
    let trades = []
    await axios.request(tradesOptions).then(function (response) {
        let results = response.data
        trades = results
    }).catch(err => console.log(err))
    return trades
}

/* 10. My Transfers */
export const getMyTransfers = async (token, after, before, date, type)  => {
    const transfersOptions = {
        method: 'GET',
        url: `${route}/@/transfers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        /*params: {
            "after_datetime": after,
            "before_datetime": before,
            "date": date, //YYYY-MM-DD
            "type": type, //DEPOSIT or WITHDRAW
            "after_datetime": after,
        }*/
    }
    let transfers = []
    await axios.request(transfersOptions).then(function (response) {
        let results = response.data
        transfers = results
    }).catch(err => console.log(err))
    return transfers
}

/* 11. My User */
export const getMyUser = async (token)  => {
    const userOptions = {
        method: 'GET',
        url: `${route}/@`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        
    }
    let user
    await axios.request(userOptions).then(function (response) {
        let results = response.data
        user = results
    }).catch(err => console.log(err))
    return user
}

/* 6. Add User to Mailing List */
export const addToNewsletter = async (email)  => {
    const emailOptions = {
        method: 'POST',
        url: `${route}/newsletter`,
        params: {
            "email": email
        }
    }
    let emailInfo = {}
    await axios.request(emailOptions).then(function (response) {
        let results = response.data
        emailInfo = results
    }).catch(err => console.log(err))
    console.log("Working")
    return emailInfo
}