import React from 'react'
import './Toggle.css'
import {Link} from 'react-router-dom'

const Toggle = (props) => {
   
    return(
        <>
        <div className="Toggle">
            <div className="Toggle-name">
                <Link style={{color: 'black'}}>{props.request.username}</Link>
            </div>
            <div className="Toggle-btns-div">
                <button onClick={()=>props.acceptRequest(props.token, props.request.user_id)} className="Toggle-btn" style={{border: '2px solid green', backgroundColor: 'rgb(245,245,245)', color: 'green', fontWeight: '600', width: '55%'}}>Accept</button>
                <button className="Toggle-btn" style={{backgroundColor: 'white', border: '1px solid red', color: 'red', fontWeight: '200', fontSize: '.7em'}}>Deny</button>
            </div>
        </div>
        </>
    )
}

export default Toggle