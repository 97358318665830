import React from 'react'
import './landingpage.css'

const Headshots = () => {

    let dallas = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fdallas-headshot.png?alt=media&token=704569f4-9cc7-4327-9056-979898edd287'
    let kj = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fkj-headshot.png?alt=media&token=240cd2e8-bbe3-4ca5-8634-82f91d78c794'
    let patton = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fpatton-headshot.png?alt=media&token=0ab4deac-21f5-44c9-ab9a-0d09f5e7279f'
    
    return(
        <div className="Headshots">
            <div className="h-div">
                <img src={kj} alt='headshot' className="h-img" />
                <div className="h-name">Kenneth Giles</div>
                <div className='h-details'>Co-Founder and CEO</div>
            </div>
            <div className="h-div">
                <img src={dallas} alt='headshot' className="h-img" />
                <div className="h-name">Dallas Klein</div>
                <div className='h-details'>Co-Founder and CTO</div>
            </div>
            <div className="h-div">
                <img src={patton} alt='headshot' className="h-img" />
                <div className="h-name">Patton Fitzpatrick</div>
                <div className='h-details'>Co-Founder and COO</div>
            </div>
        </div>
    )
}

export default Headshots