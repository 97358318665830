import React from 'react'
import '../TeammateItem.css'
import moment from 'moment'

const Move = (props) => {

    const item = props.item

    let obj = {
        user: 'Timmy',
        type: 'Move',
        period: 'day',
        move: 0.063,
        timestamp: '2022-03-14 13:00:00'
    }

    return(
        <div className="teammate-item-card">
            <table style={{width: '100%'}}>
                <tr style={{width: '100%'}} className="tic-user">
                    <td>{item.user}</td>
                    <td style={{textAlign: 'right'}}>{item.move > 0 ? <i class="fa fa-arrow-up" style={{color: 'green'}}></i> : <i class="fa fa-arrow-down" style={{color: 'red'}}></i>}</td>
                </tr>
            </table>

            <div className="tic-body">
                <span>{item.move > 0 ? 'Has Gained' : 'Is Down'}{' '}</span>
                <strong>{item.move && (item.move * 100).toFixed(2)}%{' '}</strong>
                {item.period === 'day' ? 'on the day.' : ''}
                
            </div>

            <div className="tic-date">{moment(`${item.timestamp}.000Z`).format('MM/DD/YY HH:mm')}</div>

        </div>
    )
}

export default Move