import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import './styles.css'
import '../Account.css'

const CheckEmail = (props) => {

    const history = useHistory()

    return(
        <div className="email-capture check DesktopContainer">
            <h1 className='check-head'>Check your mail</h1>
            <p className="reset-body">We have sent password recovery instructions to your email. </p>
            <div className="check-div">{props.email}</div>
            <div>
                <button onClick={()=>{return history.push('/')}} className="skip-btn">Skip, I'll comfirm later</button>
            </div>
            <div className='check-under'>Didn't receive an email? Check your spam folder, or <Link onClick={props.anotherOne}>try another email address</Link></div>
        </div>
    )
}

export default CheckEmail