import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../../LandingPage3/landingpage.css'
import '../landingPage.css'
import { Link } from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import amplitude from 'amplitude-js'

const NavBar = (props) => {

    const history = useHistory()

    let Logo = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Simbull%20Logo.png?alt=media&token=b00fbd28-0833-4b93-b2e6-ecf5def498fa'

    return(
        <div>
        <Navbar style={{backgroundColor: 'black',}} className='py-lg-5 NavBar2' expand="lg" fixed="top">
            <Navbar.Brand href="/" style={{backgroundColor: 'black', border: 'none', boxShadow: 'none'}}><img src="https://ucarecdn.com/9f499dfe-cb58-488d-8714-7aae21bfc58f/-/crop/600x223/0,95/-/preview/" className='nav-logo' alt='logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" color="white" style={{backgroundColor: 'rgb(60,60,60)', color: 'white'}} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                   {/* <NavDropdown title={<span className="roboNormal2">Learn More{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop2">
                        {/*<NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/TodaysTrades">Today's Trades</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/ExcelTrading">Excel Trading</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Teams">All Teams</NavDropdown.Item>*/}
                        {/*<NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Can-I-Make-Money">Can I Make Money?</NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/PayoutAssurance">Win Payout Assurance</NavDropdown.Item>
                     </NavDropdown>
                    <NavDropdown title={<span className="roboNormal2">About Us{'  '}{' '}{' '}<i className="fa fa-caret-down"  style={{fontSize: "1rem"}}></i></span>} id="basic-nav-dropdown" className="robo200 nav-drop2">
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/Our-Story"><span style={{textDecoration: "none"}}>Our Story</span></NavDropdown.Item>
                        <NavDropdown.Item className="roboNormal2 nav-drop-size2" href="/ContactUs">Contact Us</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="robo200">
                        <Link to={{pathname: '/login', state: true}} className="roboNormal2">
                            Sign Up
                        </Link>
                    </Nav.Link>
                    {/*<Nav.Link className="robo200">
                        <Link to="/login" className="roboNormal2">
                            Log In
                        </Link>
                    </Nav.Link>*/}
                    {/*<button className="Top-CTA-btn3">Get $500 risk-free</button>*/}
                    {!props.picks && <button className="login-btn3" style={{backgroundColor: 'black', color: 'white', border: '1px solid white'}} onClick={()=>{history.push('/Login'); amplitude.getInstance().logEvent("login_navbar")}}>Log In</button>}
                    {!props.picks && <button className="login-btn3" onClick={()=>{history.push('/Signup'); amplitude.getInstance().logEvent("signup_navbar")}}>Sign Up</button>}
                </Nav>
            </Navbar.Collapse>
            
        </Navbar>
    </div>
    )
}

export default NavBar