import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import './portfolio2.css'
import Team from './Team'
import Payouts from './Extended/WinPayouts'
import Trades from './Extended/Trades'
import Transfers from './Extended/Transfers'
import { useHistory } from 'react-router-dom'
import logo1 from '../assets/Logo1.png'


const History = (props) => {
    //get props
    const user = props.user
    const teams = props.teams
    const history = useHistory()

    const [show, setShow] = useState('Transfers')

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', paddingBottom: '200px', textAlign: 'center', minHeight: '100vh'}}>
            
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '2vh', textAlign: 'left'}}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500', overflow: 'visible'}}>
                        <span style={{fontSize: '1.15rem'}}>Transaction History</span>
                    </div>
                </div>
            : null}
            
            <div style={{backgroundColor: 'rgb(30,30,30)', display: 'inline-block', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '2rem', marginBottom: '12px', textAlign: 'center'}}>
                <div style={{display: 'inline-block'}}><button onClick={()=>{ setShow('Transfers') /*history.push('/MyTransfers')*/}} style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: show === 'Transfers' ? '#c4b454' : 'rgb(30,30,30)', fontWeight: '300', padding: '20px', color: show === 'Transfers' ? 'black' :'gray', paddingTop: '4px', paddingBottom: '4px'}}>Transfers</button></div>
                <div style={{display: 'inline-block'}}><button onClick={()=>{ setShow('Trades') /*history.push('/MyTrades')*/}} style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor:  show === 'Trades' ? '#c4b454' : 'rgb(30,30,30)', color: show === 'Trades' ? 'black' :'gray', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Trades</button></div>
                <div style={{display: 'inline-block'}}><button onClick={()=>{ setShow('Payouts') /*history.push('/MyPayouts')*/}} style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor:  show === 'Payouts' ? '#c4b454' : 'rgb(30,30,30)', color: show === 'Payouts' ? 'black' : 'gray', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Win Payouts</button></div>
            </div>

            {show === 'Transfers' && <Transfers />}
            {show === 'Trades' && <Trades />}
            {show === 'Payouts' && <Payouts />}

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
});

export default connect(mapStateToProps)(History)