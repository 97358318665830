import React, {useState, useEffect,} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import RewardHeader from '../../assets/stock_rewards.png'
import logo from '../../assets/Logo1.png'
import EastIcon from '@mui/icons-material/East';
import ReactCardFlip from 'react-card-flip';
import {getSpins, runSpin} from '../../../routes/spins/spins'
import logo1 from '../../assets/Logo1.png'
import Popup from 'reactjs-popup'
import {getOffersByTeam, makeTrade, makeTradeAlt} from "../../../routes/trading/trading";
import {getToken} from "../../../redux/actions/token";
import {useDispatch} from 'react-redux'
import amplitude from 'amplitude-js'

const Redeem = (props) => {
    console.log(props)
    const history = useHistory()
    const dispatch = useDispatch()

    const [available, setAvailable] = useState(props.location.state.available ? props.location.state.available : 0)
    const [redeem, setRedeem] = useState(false)
    const [card, setCard] = useState(false)
    const cards = [1, 2, 3]
    console.log(available)

    const doFlip = (_card) => {
        if (!card) {
            setCard(_card)
        }
    }

    const [spin, setSpin] = useState(/*{
        amount: .01,
        asset_id: 43,
        claimed: 1,
        id: 1111,
        timestamp: "2022-09-28T09:59:56.402Z",
        tx_id: 0,
        user_id: 0
    }*/)
    const [team, setTeam] = useState()

    let getTeamInfo = (id) => {
        let team
        props.teamsValue && props.teamsValue.map(t => {
            if (t.team.id === id) {
                team = t
            }
        })
        return team
    }

    useEffect(() => {
        if (spin) {
            setTeam(getTeamInfo(spin.asset_id))
        }
    }, [spin])

    const [spins, setSpins] = useState([])
    useEffect(() => {
        getSpins(props.token).then(res => {
            setSpins(res)
            setAvailable(res.length)
            console.log(res)
        })
    }, [])

    const redeemSpin = async () => {
        setRedeem(true)
        let _spin
        if (props.location && props.location.state && props.location.state.spins) {
            _spin = props.location.state.spins[0]
        } else if (spins.length > 0) {
            _spin = spins[0]
        } else {
            let _spins = await getSpins(props.token)
            console.log(_spins)
            _spin = _spins.length > 0 ? _spins[0] : {}
        }
        amplitude.getInstance().logEvent("doSpin")
        //return;
        runSpin(props.token, _spin.id).then(res => {
            setSpin(res)
            dispatch(getToken())
        })
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [onboarding, setOnboarding] = useState(false)
    useEffect(() => {
        if (props.location && props.location.state && props.location.state.onboarding) {
            setOnboarding(true)
        }
    }, [])

    const [swapState, setSwapState] = useState(null);
    const [swapData, setSwapData] = useState(null);
    const [swapLeague, setSwapLeague] = useState('NFL');
    const [swapTeam, setSwapTeam] = useState('');
    const [swapError, setSwapError] = useState(null);
    const [swapSoldFor, setSwapSoldFor] = useState(0);
    const fees = props.myuser?.fees;
    console.log(props.myuser)

    const handleSwap = async () => {
        amplitude.getInstance().logEvent("startSwap")
        setSwapError(null);
        let initialSold = swapState === 'INIT_AGAIN';
        if (!initialSold && swapState !== 'INIT') return console.warn('Bad state');
        setSwapState('TRADE');
        let sellData = null,
            buyData = null;

        let buy_for = 0;

        if (!initialSold) {
            try {
            // sellData = 'aa';
                sellData = (await makeTradeAlt(props.token, spin.asset_id, spin.amount, false)).data;
                amplitude.getInstance().logEvent(
                    "swap_sell", {
                        "shares": spin.amount,
                    }
                )
            } catch (err) {
                return setSwapError({
                    "attempting to sell more shares than are in circulation": "You cannot sell this amount.",
                    "not enough offers to fulfill sell": "Nobody is willing to trade for this share right now."
                }[err?.response?.data?.msg] || "An unknown error occurred.");
            }
            if (sellData) {
                initialSold = sellData.length > 0;
                console.log(sellData);
                let reciptCost = 0
                sellData.map(r => {
                    reciptCost += (r.type !== 'FEE' ? ((r.amount * r.price)) : ((-r.amount * r.price)))
                })
                buy_for = reciptCost
                setSwapSoldFor(reciptCost)
            }
        }

        console.log('hh');

        if (!initialSold) return setSwapError('Unable to swap your share at this time.');

        const offers = await getOffersByTeam(swapTeam.id, 'ASK');
        console.log(offers);
        offers.sort((a, b) => a.price - b.price);
        let c = 0,
            s = 0;

        if(fees){
            buy_for = buy_for / (props.myuser.fees_amount + 1)
        }
        for (let offer of offers) {
            if (c >= buy_for) continue;
            console.log('here')
            let offerCost = offer.remaining_amount * offer.price;
            console.log(offerCost)
            if (offerCost < buy_for - c) {
                c += offerCost;
                s += offer.remaining_amount;
                console.log(offer)
            } else {
                let diff = buy_for - c;
                c += diff;
                s += diff / offer.price;
                console.log(offer)
            }
        }

        console.log('Estimated Cost', c);
        console.log('Share Amount', s);

        try {
            buyData = (await makeTradeAlt(props.token, swapTeam.id, s, true)).data;
            amplitude.getInstance().logEvent(
                "swap_buy", {
                    "shares": s,
                    "team": swapTeam.name
                }
            )
            dispatch(getToken())
        } catch (err) {
            setSwapState('INIT_AGAIN');
            setSwapError(({
                "attempting to buy more shares than are in circulation": "You cannot buy this amount.",
                "not enough cash to afford buy": "You cannot buy this share.",
                "not enough offers to fulfill buy": "Nobody is willing to trade for this share right now."
            }[err?.response?.data?.msg] || "An unknown error occurred.") + " Choose another team and try again.");
            return;
        }

        /*buyData = [
            {amount: 0.5, price: 3.54},
            {amount: 3.4, price: 24.2}
        ]*/

        if (!buyData || buyData.length === 0) {
            setSwapState('INIT_AGAIN');
            setSwapError('Unable to swap your share at this time. Choose another team and try again.');
            return;
        }

        let totalShares = 0,
            totalPrice = 0;

        for (let purchase of buyData) {
            if(purchase.type !== 'FEE'){
                totalShares += purchase.amount;
                totalPrice += purchase.price;
            }   
        }

        setSwapData({amount: totalShares, price: totalPrice});
        setSwapState('COMPLETE');
    }

    console.log(swapTeam)

    return (
        <>
            <div style={{
                backgroundColor: 'black',
                paddingLeft: isMobile ? '12px' : '248px',
                paddingRight: isMobile ? '12px' : '348px',
                minHeight: '100vh',
                paddingBottom: '10vh'
            }}>

                {isMobile ?
                    <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '4vh', textAlign: 'left'}}>
                        <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{
                            height: '90%',
                            marginTop: '-2vh',
                            marginBottom: '0%',
                            marginLeft: '4px'
                        }}/></div>
                        <div style={{
                            display: 'inline-block',
                            width: '50%',
                            height: '6vh',
                            color: 'white',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            paddingTop: '2vh',
                            fontWeight: '500',
                            overflow: 'visible'
                        }}>
                            <span style={{fontSize: '1.15rem'}}>Redeem</span>
                        </div>
                    </div>
                    : null}

                {swapState != null
                    ? <>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            marginBottom: '1em',
                            color: '#fff'
                        }}>
                            <h3>Choose your favourite team</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: '1.5em'
                            }}>
                                {['NFL', 'CFB', 'NBA', 'MLB', 'NHL'].map((v, i) =>
                                    <button key={i} onClick={() => setSwapLeague(v)} className={`btn-sb-pill ${swapLeague === v ? 'active' : ''}`}>
                                        {v}
                                    </button>
                                )}
                            </div>
                            <select defaultValue="" onChange={e => setSwapTeam(e.target?.value ? getTeamInfo(parseInt(e.target.value)).team : '')} className="signup-form-input select-sb-input" style={{width: '60%'}} disabled={swapState === 'TRADE'}>
                                <option value="">
                                    {props.teamsValue.filter(t => t['team']['league'] === swapLeague).length} teams available
                                </option>
                                {props.teamsValue.filter(t => t['team']['league'] === swapLeague).map((v, i) => <option key={i} value={v['team']['id']}>
                                    Sim{v['team']['name']}
                                </option>)}
                            </select>
                            {swapError && <p>{swapError}</p>}
                            {swapState === 'COMPLETE' ? <>
                                <div className="redeem-swap-receipt">
                                    <img alt={`Sim${swapTeam.name} Jersey`} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${swapTeam.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                                    You swapped your free stock for {swapData.amount && swapData.amount.toFixed(2)} shares of the Sim{swapTeam.name}  worth ${swapData.price && (swapData.price * swapData.amount).toFixed(2)}!
                                </div>
                                <button onClick={() => history.push('/Rewards')} className="btn-sb-stack">
                                    I want more free stock
                                </button>
                                <button role="link" onClick={() => history.push('/Portfolio')} className="btn-anchor-link text-muted mt-2">
                                    I'm happy with my free share
                                </button>
                            </> : <>
                                <button onClick={handleSwap} className="btn-sb-stack"
                                        disabled={swapTeam === '' || swapState === 'TRADE'}>
                                    {swapTeam ? 'Swap Share to Sim' + swapTeam.name : 'Pick a team!'}
                                </button>
                                <button role="link" onClick={() => history.push('/Rewards')} className="btn-anchor-link text-muted mt-2">
                                    Nevermind, I want to keep my share.
                                </button>
                            </>}
                        </div>
                    </>
                    : redeem ? <>
                            {!card && <div>
                                <div style={{
                                    color: card ? 'black' : 'white',
                                    marginBottom: 8,
                                    fontSize: 18,
                                    fontWeight: '300',
                                    textAlign: 'center'
                                }}>Choose your <span style={{fontWeight: '500'}}>free stock reward</span></div>
                                <div style={{
                                    color: card ? 'black' : 'white',
                                    marginBottom: 28,
                                    fontSize: 18,
                                    fontWeight: '300',
                                    textAlign: 'center'
                                }}>from the three different options:
                                </div>
                            </div>}
                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                backgroundColor: "black",
                                width: "100%",
                                marginBottom: "3%"
                            }}>
                                {cards.map(c => {
                                    return (
                                        null
                                    )
                                })}
                                {(!card || card === 1) && <ReactCardFlip isFlipped={card === 1} style={{
                                    width: '25%',
                                    marginLeft: '4%',
                                    marginRight: '4%',
                                }}>
                                    {/*Front of Card*/}
                                    <div onClick={() => doFlip(1)} style={{
                                        marginTop: '15%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: '90%',
                                        marginLeft: '5%',
                                        marginRight: '5%',
                                        minHeight: isMobile ? '25vh' : '50vh'
                                    }}>
                                        <img style={{
                                            width: isMobile ? '90%' : '70%',
                                            marginTop: '30%',
                                            marginBottom: '20%'
                                        }} src={logo}/>
                                    </div>

                                    {/*Back of Card*/}
                                    <div style={{
                                        marginTop: card === 1 ? '0%' : '15%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: card === 1 ? (isMobile ? '80%' : '50%') : '90%',
                                        marginLeft: card === 1 ? (isMobile ? '10%' : '25%') : '5%',
                                        marginRight: '5%',
                                        minHeight: '50vh'
                                    }}>
                                        <div style={{
                                            marginBottom: '12px',
                                            marginTop: '12px',
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem'
                                            }}>Congratulations!</span>
                                        </div>
                                        <img
                                            src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team && team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`}
                                            style={{width: '40%', marginBottom: 8,}}/>
                                        <div style={{
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                    <span style={{
                                        color: "white",
                                        fontWeight: "300",
                                        fontSize: 7,
                                        textAlign: "center",
                                        marginBottom: 8,
                                    }}>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}>You won <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>{spin ? spin.amount : ''} share{spin && spin.amount !== 1 ? 's' : ''}
                                            <br/></span>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}> of the <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>Sim{team ? team.team.name : ''} <br/>
                                        <span style={{
                                            fontSize: '.8rem',
                                            fontWeight: '200'
                                        }}>(${team && team.team.league}-{team && team.team.abbr})</span></span>
                                        
                                    </span><br/><br/>
                                            <span style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem',
                                                textAlign: "center"
                                            }}>
                                        ${(spin && team) ? (spin.amount * team.asking_price).toFixed(2) : '0.00'} value
                                    </span>
                                        </div>
                                    </div>
                                </ReactCardFlip>}

                                {(!card || card === 2) && <ReactCardFlip isFlipped={card === 2} style={{
                                    width: '25%',
                                    marginLeft: '4%',
                                    marginRight: '4%',
                                }}>
                                    {/*Front of Card*/}
                                    <div onClick={() => doFlip(2)} style={{
                                        marginTop: '0%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: '90%',
                                        marginLeft: '5%',
                                        marginRight: '5%',
                                        minHeight: isMobile ? '25vh' : '50vh'
                                    }}>
                                        <img style={{
                                            width: isMobile ? '90%' : '70%',
                                            marginTop: '30%',
                                            marginBottom: '20%'
                                        }} src={logo}/>
                                    </div>

                                    {/*Back of Card*/}
                                    <div style={{
                                        marginTop: '0%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: card === 2 ? (isMobile ? '80%' : '50%') : '90%',
                                        marginLeft: card === 2 ? (isMobile ? '10%' : '25%') : '5%',
                                        marginRight: '5%',
                                        minHeight: '50vh'
                                    }}>
                                        <div style={{
                                            marginBottom: '12px',
                                            marginTop: '12px',
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem'
                                            }}>Congratulations!</span>
                                        </div>
                                        <img
                                            src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team && team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`}
                                            style={{width: '40%', marginBottom: 8,}}/>
                                        <div style={{
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                    <span style={{
                                        color: "white",
                                        fontWeight: "300",
                                        fontSize: 7,
                                        textAlign: "center",
                                        marginBottom: 8,
                                    }}>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}>You won <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>{spin ? spin.amount : ''} share{spin && spin.amount !== 1 ? 's' : ''}
                                            <br/></span>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}> of the <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>Sim{team ? team.team.name : ''} <br/>
                                        <span style={{
                                            fontSize: '.8rem',
                                            fontWeight: '200'
                                        }}>(${team && team.team.league}-{team && team.team.abbr})</span></span>
                                        
                                    </span><br/><br/>
                                            <span style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem',
                                                textAlign: "center"
                                            }}>
                                        ${(spin && team) ? (spin.amount * team.asking_price).toFixed(2) : '0.00'} value
                                    </span>
                                        </div>
                                    </div>
                                </ReactCardFlip>}

                                {(!card || card === 3) && <ReactCardFlip isFlipped={card === 3} style={{
                                    width: '25%',
                                    marginLeft: '4%',
                                    marginRight: '4%',
                                }}>
                                    {/*Front of Card*/}
                                    <div onClick={() => doFlip(3)} style={{
                                        marginTop: '15%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: '90%',
                                        marginLeft: '5%',
                                        marginRight: '5%',
                                        minHeight: isMobile ? '25vh' : '50vh'
                                    }}>
                                        <img style={{
                                            width: isMobile ? '90%' : '70%',
                                            marginTop: '30%',
                                            marginBottom: '20%'
                                        }} src={logo}/>
                                    </div>

                                    {/*Back of Card*/}
                                    <div style={{
                                        marginTop: card === 3 ? '0%' : '15%',
                                        backgroundColor: "#171717",
                                        textAlign: 'center',
                                        padding: '12px',
                                        border: '1px solid white',
                                        borderRadius: '1rem',
                                        width: card === 3 ? (isMobile ? '80%' : '50%') : '90%',
                                        marginLeft: card === 3 ? (isMobile ? '10%' : '25%') : '5%',
                                        marginRight: '5%',
                                        minHeight: '50vh'
                                    }}>
                                        <div style={{
                                            marginBottom: '12px',
                                            marginTop: '12px',
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem'
                                            }}>Congratulations!</span>
                                        </div>
                                        <img
                                            src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team && team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`}
                                            style={{width: '40%', marginBottom: 8,}}/>
                                        <div style={{
                                            width: "100%",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                    <span style={{
                                        color: "white",
                                        fontWeight: "300",
                                        fontSize: 7,
                                        textAlign: "center",
                                        marginBottom: 8,
                                    }}>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}>You won <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>{spin ? spin.amount : ''} share{spin && spin.amount !== 1 ? 's' : ''}
                                            <br/></span>
                                        <span style={{fontSize: '.8rem', fontWeight: '200'}}> of the <br/></span>
                                        <span style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            marginBottom: 2,
                                        }}>Sim{team ? team.team.name : ''} <br/>
                                        <span style={{
                                            fontSize: '.8rem',
                                            fontWeight: '200'
                                        }}>(${team && team.team.league}-{team && team.team.abbr})</span></span>
                                        
                                    </span><br/><br/>
                                            <span style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: '1.1rem',
                                                textAlign: "center"
                                            }}>
                                        ${(spin && team) ? (spin.amount * team.asking_price).toFixed(2) : '0.00'} value
                                    </span>
                                        </div>
                                    </div>
                                </ReactCardFlip>}
                            </div>
                            {card ? <div style={{textAlign: 'center'}}>
                                <div style={{
                                    color: 'gray',
                                    fontSize: 14,
                                    fontWeight: '300',
                                    marginTop: '8px',
                                    marginBottom: '3%'
                                }}>Must have $100 in trading volume and been 30 days to withdraw free stock.<br/><br/>
                                    <span style={{color: 'white', fontSize: 18, fontWeight: '400'}}>Not a
                                    fan of this team or league? Swap to your favorite!</span>
                                </div>
                                <button onClick={() => {setSwapState('INIT'); amplitude.getInstance().logEvent("swap_button")}} className="btn-sb-stack stack-top">
                                    Swap Share to my Favorite Team
                                </button>
                                <button onClick={() => {history.push('/Rewards'); amplitude.getInstance().logEvent("skip_swap")}} className="btn-sb-stack stack-bottom">
                                    Keep my Team
                                    <EastIcon style={{marginLeft: '4px'}}/></button>
                            </div> : null}
                        </>
                        : <>
                            <div style={{
                                width: isMobile ? '90%' : '60%',
                                marginLeft: isMobile ? '5%' : '20%',
                                marginRight: isMobile ? '5%' : '20%',
                                borderBottom: '2px solid #c4b454',
                                textAlign: 'center'
                            }}>
                                <img style={{width: '80%'}} src={RewardHeader}/>
                            </div>
                            <div style={{
                                width: isMobile ? '90%' : '60%',
                                borderRadius: '1rem',
                                margin: isMobile ? '5%' : '20%',
                                marginBottom: isMobile ? '15%' : '5%',
                                marginTop: isMobile ? '20%' : '10%',
                                backgroundColor: 'rgb(30,30,30)',
                                color: 'white',
                                paddingTop: '10%',
                                paddingBottom: '10%'
                            }}>
                                <div style={{textAlign: 'center', fontWeight: '200', fontSize: '1.5rem'}}>Redeemable
                                    Rewards
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    fontSize: '3.5rem'
                                }}>{props.location.state.available ? props.location.state.available : 0}</div>
                            </div>

                            {available
                                ? <div style={{textAlign: 'center'}}>
                                    {!(available && onboarding) ? <button onClick={redeemSpin} style={{
                                        width: isMobile ? '90%' : '75%',
                                        borderRadius: '2rem',
                                        padding: '16px',
                                        color: 'black',
                                        backgroundColor: '#c4b454',
                                        border: 'none',
                                        outline: 'none',
                                        fontWeight: '500',
                                        fontSize: '1.2rem'
                                    }}>Redeem a Stock Reward <EastIcon style={{marginLeft: '4px'}}/></button> : null}
                                </div>
                                : <div>
                                    <div style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: 20,
                                        marginBottom: 4,
                                    }}>Refer a friend.
                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        color: '#c4b454',
                                        fontSize: 20,
                                        fontWeight: 'bold'
                                    }}><span style={{color: 'white', fontWeight: '400'}}>You both earn</span> stock
                                        rewards.
                                    </div>
                                </div>
                            }
                            <Popup open={(available && onboarding)} modal>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    width: isMobile ? '90vw' : '50vw',
                                    minHeight: '80vh',
                                    backgroundColor: 'rgb(30,30,30)',
                                    borderRadius: '1rem',
                                    color: 'white',
                                    textAlign: 'center'
                                }}>
                                    <div style={{textAlign: 'center', fontSize: '2.5rem', fontWeight: '600'}}>We got
                                        you!
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: '1.2rem',
                                        fontWeight: '500',
                                        width: '80%',
                                        marginLeft: '10%'
                                    }}>We want to help you get your portfolio started with a<br/>
                                        <span style={{
                                            color: '#c4b454',
                                            fontSize: '1.5rem',
                                            fontWeight: '700',
                                            width: '80%',
                                        }}>Free Stock Reward!</span>
                                        <br/><br/>
                                        <span style={{color: 'rgb(150,150,150)'}}>It could be worth anywhere {isMobile ?
                                            <br/> : null}between $1.50 - $150!</span>
                                    </div>


                                    <div style={{color: 'rgb(150,150,150)', width: '90%', marginLeft: '5%'}}>You're free
                                        to trade this team to get your favorite team, or just hold it to collect win
                                        payouts!
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <button onClick={redeemSpin} style={{
                                            width: isMobile ? '90%' : '75%',
                                            borderRadius: '2rem',
                                            padding: '16px',
                                            color: 'black',
                                            backgroundColor: '#c4b454',
                                            border: 'none',
                                            outline: 'none',
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>Redeem a Stock Reward <EastIcon style={{marginLeft: '4px'}}/></button>
                                    </div>
                                </div>
                            </Popup>
                        </>
                }
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
    token: state.token.token,
    info: state.info.info,
    myuser: state.myuser.myuser,
});

export default connect(mapStateToProps)(Redeem)