import axios from 'axios'
import {route} from '../route'

/* 1. Collect Payouts */

export const collectPayouts = async (token) => {
    const payoutsOptions = {
        method: 'POST',
        url: `${route}/@/payouts`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let payouts = []
    await axios.request(payoutsOptions).then(function (response) {
        let results = response//.data
        payouts = results
    }).catch(err => {payouts = err})
    return payouts
}

export const collectSinglePayout = async (token, payout_id) => {
    const payoutsOptions = {
        method: 'POST',
        url: `${route}/@/payouts/${payout_id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    let payouts = []
    await axios.request(payoutsOptions).then(function (response) {
        let results = response//.data
        payouts = results
    }).catch(err => {payouts = err})
    return payouts
}