import React, {useState, useEffect} from 'react'
import './SimBull16.css'
import {Link} from 'react-router-dom'
import {sendBracketDetails, sendTeamDetails, hasLoss, isOriginal, returnCircle} from './util'

const FinalFour = (props) => {

    const [isMobile, setMobile] = useState(window.innerWidth < 1024);

    const updateMedia = () => {
        setMobile(window.innerWidth < 1024);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    let rounds = props.rounds
    let games = props.games
    let region = props.region
    let mm_teams = props.teams

    let makeNewPick = props.makeNewPick
    
    const [newPicks, setNewPicks] = useState(props.newPicks)
    useEffect(()=> {if(props.newPicks !== newPicks){setNewPicks(props.newPicks)}},[props.newPicks])
    const [currentPicks, setCurrentPicks] = useState(props.currentPicks)
    useEffect(()=> {if(props.currentPicks !== currentPicks){setCurrentPicks(props.currentPicks)}},[props.currentPicks])
 
    const addTeam = (team_id, seed) => {
        makeNewPick({
            team: team_id,
            seed: seed,
        })
    }


    return(
        <>
        {!isMobile && <div style={{textAlign: 'center', color: 'white'}}>
            <div style={{fontSize: '.8em', fontWeight: '300', color: 'gray', width: '50%', marginLeft: '25%'}}>
                <div style={{width: '33%', display: 'inline-block'}}>Final Four<span style={{display: 'block',fontSize: '.8em'}}>April 2</span></div>
                <div style={{width: '33%', display: 'inline-block'}}>Championship<span style={{display: 'block',fontSize: '.8em'}}>April 4</span></div>
                <div style={{width: '33%', display: 'inline-block'}}>Final Four<span style={{display: 'block',fontSize: '.8em'}}>April 2</span></div>
            </div>
            <div style={{display: 'inline-block', width: `${isMobile ? '50%' : '25%'}`}}>
                <main id="tournament">
                    <ul class="round roundFinal round-4" style={{color: 'black'}}>

                        Duke
                        <li class="game game-top "> </li>
                            
                        <li class="game game-spacer">&nbsp;</li>
                        
                        <li class="game game-bottom "></li>
                        North Carolina
                    </ul>   
                    <ul class="round roundFinal round-5"  style={{color: 'black'}}>
                        <li class="spacer">&nbsp;</li>
                        North Carolina
                        <li class="game game-top " style={{width: '90%', marginRight: '10%'}}></li>
                        
                        <li class="spacer">&nbsp;</li>
                        <li class="spacer">&nbsp;</li>
                    </ul> 
                </main> 
            </div>
            <div style={{display: 'inline-block', width: `${isMobile ? '50%' : '25%'}`}}>
                <main id="tournament">
                    <ul class="round roundFinal round-5"  style={{color: 'black'}}>
                        <li class="spacer">&nbsp;</li>
                        Kansas
                        <li class="game game-top " style={{width: '90%', marginLeft: '10%'}}></li>
                        
                        <li class="spacer">&nbsp;</li>
                        <li class="spacer">&nbsp;</li>
                    </ul>  
                    <ul class="round roundFinal round-4" style={{color: 'black'}}>
                        Kansas
                        <li class="game game-top "></li>
                        <li class="game gameRight-spacer">&nbsp;</li>
                        <li class="game game-bottom "></li>
                        Villanova
                        
                    </ul>   
                    
                </main> 
            </div>
        </div>}

        {isMobile && <div style={{textAlign: 'center', color: 'white', paddingBottom: '10vh'}}>
            <div style={{fontSize: '.8em', fontWeight: '300', color: 'gray', width: `100%`, }}>
                <div style={{width: '33%', display: 'inline-block'}}>Final Four<span style={{display: 'block',fontSize: '.8em'}}>April 2</span></div>
                <div style={{width: '33%', display: 'inline-block'}}>Championship<span style={{display: 'block',fontSize: '.8em'}}>April 4</span></div>
                <div style={{width: '33%', display: 'inline-block'}}>Final Four<span style={{display: 'block',fontSize: '.8em'}}>April 2</span></div>
            </div>
            <div style={{display: 'inline-block', width: `${isMobile ? '50%' : '25%'}`}}>
                <main id="tournament">
                    <ul class="round roundFinal round-4" style={{color: 'black'}}>

                        Duke
                        <li class="game game-top "> </li>
                        <li class="game game-spacer">&nbsp;</li>
                        <li class="game game-bottom "></li>
                        UNC
                    </ul>   
                    <ul class="round roundFinal round-5" style={{color: 'black'}}>
                        <li class="spacer">&nbsp;</li>
                        UNC
                        <li class="game game-top " style={{width: '90%', marginRight: '10%'}}></li>
                        
                        <li class="spacer">&nbsp;</li>
                        <li class="spacer">&nbsp;</li>
                    </ul>  
                </main> 
            </div>
            <div style={{display: 'inline-block', width: `${isMobile ? '50%' : '25%'}`}}>
                <main id="tournament">
                    <ul class="round roundFinal round-5" style={{color: 'black'}}>
                        <li class="spacer">&nbsp;</li>
                        Kansas
                        <li class="game game-top " style={{width: '90%', marginLeft: '10%'}}></li>
                        
                        <li class="spacer">&nbsp;</li>
                        <li class="spacer">&nbsp;</li>
                    </ul>  
                    <ul class="round roundFinal round-4"  style={{color: 'black'}}>
                        Kansas
                        <li class="game game-top "></li>
                        <li class="game gameRight-spacer">&nbsp;</li>
                        <li class="game game-bottom "></li>
                        Villanova
                    </ul>   
                    
                </main> 
            </div>
        </div>}
        </>
    )
}

export default FinalFour