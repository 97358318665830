import React, {useState, useEffect} from 'react'
import './Status.css'

const Status = (props) => {

    const [streak, setStreak] = useState(props.saveStreak)
    const [correct, setCorrect] = useState(props.saveCorrect)
    const [guesses, setGuesses] = useState(props.saveGuesses)

    useEffect(()=>{
        setStreak(props.saveStreak)
    },[props.saveStreak])

    useEffect(()=>{
        setCorrect(props.saveCorrect)
    },[props.saveCorrect])

    useEffect(()=>{
        setGuesses(props.saveGuesses)
    },[props.saveGuesses])

    return(
        <>
        <div className="Status" style={props.popup ? {width: '95%', margin: '2.5%'} : {}}>
            <table className="Status-Table">
            <tbody style={{width: '100%'}}>
                <tr className="Status-Row">
                    <td className="Status-Left">Streak (Days):</td>
                    <td className="Status-Right">{streak}{' '}🔥</td>
                </tr>
                <tr className="Status-Row">
                    <td className="Status-Left">Correct Guesses:</td>
                    <td className="Status-Right">{correct}{' '}✅</td>
                </tr>
                <tr className="Status-Row">
                    <td className="Status-Left">Total Guesses:</td>
                    <td className="Status-Right">{guesses}{' '}🔍</td>
                </tr>
                <tr className="Status-Row">
                    <td className="Status-Left">Average:</td>
                    <td className="Status-Right">{correct !== 0 ? (guesses/correct).toFixed(2) : 0}{' '}✴️</td>
                </tr>
            </tbody>
            </table>
        </div>
        </>
    )
}

export default Status