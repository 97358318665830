import React, {useState, useEffect} from 'react';
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import {FacebookIcon} from 'react-share'
import './Receipts.css'
import {storageRef} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {getToken} from '../../redux/actions/token'
import amplitude from 'amplitude-js';

const PurchaseReceipt = (props) => {
    let data = props.location && props.location.state ? props.location.state : {shares: "", totalCost: "", team: "", type: "", link: "", welcome: false}

    const dispatch = useDispatch()

    //props
    const shares = data.shares
    const avgCost = data.totalCost
    const team = data.team
    const type = data.type
    const link = data.link
    const welcome = data.welcome

    //state
    const [url, setUrl] = useState('')

    useEffect(()=>{
        storageRef.child('jerseys').child(`${team}.png`).getDownloadURL().then(res => {return setUrl(res)});
    },[])

    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(getToken())
    }, [])

    return (
        <div className="transaction-recipt">
            <Helmet>
                <title>Share {type}!</title>
                <meta property="og:title" content="SimBull" />
                <meta property="og:description" content="The Stock Market for Sports. Trade Teams like Stocks." />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content="https://www.simbull.app" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="transaction-recipt-modal">
                <div className="transaction-recipt-head">Share {type}!</div>
                <div className="transaction-recipt-body">You {type === "Purchased" ? "bought" : "sold"} {shares ? shares.toFixed(2) : ''} share{shares === 1 ? "" : "s"} of the <span className="tr-name-sim">Sim</span>{team} for a total {type === "Purchased" ? "cost" : "gain"} of ${avgCost && parseFloat(avgCost).toFixed(2)}!</div>
                <div className="transaction-recipt-img"> <div className='ss-team'><img className="ss-img"  src={url} alt='team' /><span className="tc-name-sim">Sim</span>{team}</div></div>
                <div className="share-buttons-div">
                <button onClick={() => amplitude.getInstance().logEvent("share_facebook")} className="share-close-btn">
                    <FacebookShareButton
                        url={"https://www.simbull.app"}
                        quote={`I just ${type === "Purchased" ? "bought" : "sold"} a share of the Sim${team} on SimBull - The Stock Market for Sports! I can trade the Sim${team} just like a stock and I'll get cash anytime they win. Go ${team}!`}
                        hashtag="#SimBull"
                    >
                        Share on Facebook
                    </FacebookShareButton>
                </button>
                <button onClick={() => amplitude.getInstance().logEvent("share_twitter")} className="share-close-btn">
                    <TwitterShareButton
                        url={"https://www.simbull.app"}
                        title={`I just ${type === "Purchased" ? "bought" : "sold"} the Sim${team} on SimBull - The Stock Market for Sports! I can trade the Sim${team} just like a stock and I'll get cash anytime they win. Go ${team}!`}
                    >
                        Share on Twitter
                    </TwitterShareButton>
                </button>
                {/*<div className="share-btn">
                    <EmailShareButton
                        url={"https://www.simbull.app"}
                        title='I bought a share on SimBull'
                        body='I just bought a share of the SimRams on SimBull - The Stock Market for Sports!'
                    >
                        Share on Email
                    </EmailShareButton>
                </div>*/}
                <button onClick={()=>{return props.history.push( {pathname:`/`, state: {refresh: true}} ) } } className="share-close-btn">Back to Portfolio</button><br />
                <button onClick={ ()=>{ return props.history.push( {pathname:`/Teams/${team}`, state: {refresh: true}} ) } } className="share-close-btn">Back to Team Card</button>
                {welcome && <button onClick={()=>{return props.history.replace({pathname: `/welcome`, state: { welcomePage: 6 }})}} className="share-close-btn">Close</button>}
                </div>
            </div>
        </div>
    );
}

export default withRouter(PurchaseReceipt);