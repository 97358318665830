export const sendBracketDetails = (id) => {
    
    if(id < 8){
        return ['First Round', 'West']
    }
    else if(id > 7 && id < 16){
        return ['First Round', 'East']
    }
    else if(id > 15 && id < 24){
        return ['First Round', 'South']
    }
    else if(id > 23 && id < 32){
        return ['First Round', 'Midwest']
    }
    else if(id > 31 && id < 36){
        return ['Second Round', 'West']
    }
    else if(id > 35 && id < 40){
        return ['Second Round', 'East']
    }
    else if(id > 39 && id < 44){
        return ['Second Round', 'South']
    }
    else if(id > 42 && id < 48){
        return ['Second Round', 'Midwest']
    }
    else if(id > 47 && id < 50){
        return ['Sweet Sixteen', 'West']
    }
    else if(id > 49 && id < 52){
        return ['Sweet Sixteen', 'East']
    }
    else if(id > 51 && id < 54){
        return ['Sweet Sixteen', 'South']
    }
    else if(id > 53 && id < 56){
        return ['Sweet Sixteen', 'Midwest']
    }
    else if(id === 56){
        return ['Elite Eight', 'West']
    }
    else if(id === 57){
        return ['Elite Eight', 'East']
    }
    else if(id === 58){
        return ['Elite Eight', 'South']
    }
    else if(id === 59){
        return ['Elite Eight', 'Midwest']
    }
    else if(id === 60){
        return ['Final Four', 'West/East']
    }
    else if(id === 61){
        return ['Final Four', 'South/Midwest']
    }
    else if(id === 62){
        return ['Championship', 'All']
    }

}

export const sendTeamDetails = (teams, id) => {
    if(id){
        let team
        teams && teams.length > 0 && teams.map(t => {
            if(t.id === id){
                team = t
                return t
            }
        })
        return team
    }
    else{
        return {
            seed: null,
            name: null,
        }
    }
    
}

export const isPick = (team_id, picks) => {
    let pick = false
    picks && picks.length > 0 && picks.map(p => {
        if(p.team === team_id){
            pick = true
        }
    })
    return pick
}

export const hasLoss = (games, team_id) => {
    let lost = false
    if(games && games.length > 0){
        games.map(g => {
            if(g.status && g.status.toLowerCase() === 'final'){
                if(g.home_team_id === team_id && g.home_score < g.away_score){
                    lost = true
                }
                else if(g.away_team_id === team_id && g.away_score < g.home_score){
                    lost = true
                }
            }
            
        })
    }
    return lost
}

export const isOriginal = (original, id) => {
    if(original && original.length > 0 && original.includes(id.toString())){
        return true
    }
    else return false
}

export const calcRemaining = (games, picks) => {
    games.sort((a,b) => {
        return a.id - b.id
    })
    let picksAlive = (picks && picks.length > 0) ? picks.split(',') : []
    let teams = picksAlive.length
    let possibleWins = []
    games && games.length > 0 && games.map(g => {
        if(g.status && g.status.toLowerCase() === 'final'){
            if(picksAlive.includes(g.home_team_id.toString()) && g.home_score < g.away_score){
                teams--
                picksAlive.filter(p => p === g.home_team_id.toString())
            }
            else if(picksAlive.includes(g.away_team_id.toString()) && g.away_score < g.home_score){
                teams--
                picksAlive.filter(p => p === g.away_team_id.toString())
            }
        }

        if( (g.status !== 'FINAL' && g.status !== 'final') && ( (g.home_team_id && picksAlive.includes(g.home_team_id.toString())) || (g.away_team_id && picksAlive.includes(g.away_team_id.toString())) ) ){
            possibleWins.push(g.id)
            let next2 = Math.floor((g.id + 64)/2)
            if(!possibleWins.includes(next2) && next2 < 64){
                possibleWins.push(next2)
            }
            let next3 = Math.floor((next2 + 64)/2)
            if(!possibleWins.includes(next3) && next3 < 64){
                possibleWins.push(next3)
            }
            let next4 = Math.floor((next3 + 64)/2)
            if(!possibleWins.includes(next4) && next4 < 64){
                possibleWins.push(next4)
            }
            let next5 = Math.floor((next4 + 64)/2)
            if(!possibleWins.includes(next5) && next5 < 64){
                possibleWins.push(next5)
            }
            let next6 = Math.floor((next5 + 64)/2)
            if(!possibleWins.includes(next6) && next6 < 64){
                //possibleWins.push(next6)
            }
        }
    })
    return [teams, possibleWins.length]
}

export const returnCircle = (game, local, id, current, originalPicks) => {
    if(current){
        if(local === 'home'){
            if(game.id > 47 && (game.home_score > game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && isOriginal(originalPicks, id) && (game.home_score > game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && !isOriginal(originalPicks, id)){
                return ' '
            }
            else return '🔴'
        }else if(local === 'away'){
            if(game.id > 47 && (game.home_score < game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && isOriginal(originalPicks, id) && (game.home_score < game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && !isOriginal(originalPicks, id)){
                return ' '
            }
            else return '🔴'
        }
    }
    else{
        if(local === 'home'){
            if(game.id < 48 && isOriginal(originalPicks, id) && (game.home_score > game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && isOriginal(originalPicks, id)){
                return '🔴'
            }
            else return ' '
        }else if(local === 'away'){
            if(game.id < 48 && isOriginal(originalPicks, id) && (game.home_score < game.away_score || game.status !== 'FINAL')){
                return '🟢'
            }
            else if(game.id < 48 && isOriginal(originalPicks, id)){
                return '🔴'
            }
            else return ' '
        }
        else return ' '

    }

}