import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './Account.css'
import Header from './Header'
import ReactPixel from 'react-facebook-pixel'
import {firebaseAnalytics} from '../../services/firebase'

const Tutorial = (props) => {

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const videos = [
        {
            title: 'What is SimBull?',
            link: 'XevUHWF32PM',
            id: 'what-is-simbull'
        },
        {
            title: 'How do I Make Money?',
            link: 'tKHG1vYPDiw',
            id: 'how-to-make-money'
        },
        {
            title: 'Portfolio',
            link: 'm3LtB1TUQuE',
            id: 'portfolio'
        },
        {
            title: 'My Teams',
            link: 'Lto2j5gH0Tg',
            id: 'myTeams'
        },
        {
            title: 'Trade Block',
            link: '99bgkS3IaXQ',
            id: 'trade-block'
        },
        {
            title: 'Buy and Sell',
            link: 'FHmKmOucVJU',
            id: 'trade-block'
        },
        {
            title: 'Offer to Buy or Sell',
            link: 'vEjQl-js06s',
            id: 'offers'
        },
        {
            title: 'Deposit or Withdraw',
            link: '3toTOZXCRZ8',
            id: 'deposit-withdraw'
        },
    ]

    //Analytics
    useEffect(()=>{
        if(props.location && props.location.state && props.location.state.welcome){
            firebaseAnalytics.logEvent("welcomeTutorial_visit", {
            //user: user && user.email 
            })
        }
    },[])

    return(
        <>
            {props.location && props.location.state && props.location.state.welcome && <div style={{textAlign: 'left', backgroundColor: 'rgb(235,235,235'}}><button style={{marginLeft: '3%', marginTop: '3%', border: 'none', backgroundColor: 'rgb(235, 235, 235'}} onClick={()=>{return props.history.replace({pathname: `/welcome`, state: { welcomePage: 6 }})}}>Back</button></div>}
            <div className="Tutorial DesktopContainer">
                {props.location && props.location.state && props.location.state.welcome && <Header header='' link={()=>{return props.history.replace({pathname: `/welcome`, state: { welcomePage: 6 }})}} />}
                
                <div className="tutorial-contents">
                    <hr />
                    Tutorial List
                    {videos.map(v => {
                        return(
                            <li className="tutorial-contents-item"><a href={`#${v.id}`} className="blk-txt">{v.title}</a></li>
                        )
                    })}
                </div>
                <hr />
                {videos.map(v => {
                    return(
                        <div className="tutorial-div" id={v.id}>
                            <div className="tutorial-div-title">
                                {v.title}
                            </div>
                            <iframe className='tutorial-video' width="420" height="420" title='infomercial'
                                src={`https://www.youtube.com/embed/${v.link}`}>
                            </iframe>
                        </div>
                    )
                })}
                
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(Tutorial))