import React, {useState, useEffect} from 'react'
import {Accordion, Card, Button} from 'react-bootstrap'
import './landingpage.css'
import NavBar from './NavBar'
import Header from '../Account2/Header'
import {Link} from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Footer from './Footer'
import {firebaseAnalytics} from '../../services/firebase'
import Helmet from 'react-helmet'

const FAQs = (props) => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    useEffect(()=>{
      if(props && props.match &&props.match.path === '/F-A-Q'){
        ReactPixel.track('View', {user_role: 'guest'})
      }else{
        ReactPixel.track('View', {user_role: 'member'})
      } 
    },[])

    const [view, setView] = useState()

    const faqArray = [
        
        {
            Q: [ 'What are team shares?' ],
            N: 1,
            A: [ "SimBull shares are virtual representations of team performane.", 
                <><br /><br /></>, 
                'SimBull team shares are virtual representations of professional and collegiate sports teams that you can trade on SimBull, but represent no real ownership of the real life team. Every team share you own gives you the opportunity to earn a win payout when that team wins in real life. Team shares are NOT equity in the actual teams and have no relation to the teams themselves, but rather they are a virtual representation of teams that allow you to buy, sell and earn win payouts in the SimBull market.', 
                ]
        },
        {
            Q: [ 'What are Win Payouts?' ],
            N: 2,
            A: [ 'Win Payouts is cash paid to users when their teams win.',
                <><br /><br /></>,
                'Win payouts are cash prizes paid to users when teams they own win a game. Win payouts provide economic value to the team shares and allow users to earn cash from owning team shares.',
                <><br /><br /></>,
                'Payouts for each league are based on the number of games in a season. The less games that occur in a season, the higher the win payout and vice versa. This allows the average team in each league to earn a similar amount of win payouts in a season.',
                <><br /><br /></>,
                'For SimNFL, Win Payouts are $0.50.',
                <><br /></>, 
                'For SimNBA, Win Payouts are $0.10.',
                <><br /></>, 
                'For SimMLB, Win Payouts are $0.05.',
                <><br /></>, 
                'For SimCFB, Win Payouts are $0.50.',
                <><br /></>, 
                'For SimNHL, Win Payouts are $0.10.',
                <><br /><br /></>, 
                'You will see a ‘collect win payouts’ button appear in your portfolio at the conclusion of a game if a team you owns wins. There is no limit on win payouts so the more team shares you own, the more payouts you will earn. ',
                <><br /><br /></>, 
                'We currently have an assurance from a CPA that we have enough funds in our payout pool to cover at least through 2024. This assurance will be updated quarterly and is located at: ',
                <><a href="https://www.simbull.app/PayoutAssurance">Payout Assurance Document</a></>, 
                ]
        },
        {
            Q: [ 'How do I make money?' ],
            N: 3,
            A: [ 'SimBull users earn money through win payouts and share price movement.',
                <><br /><br /></>,
                'There are two ways to make money on SimBull: Win payouts and share price appreciation.',
                <><br /><br /></>,
                'Win Payouts: Users who own shares are eligible to earn win payouts when a team they own wins a game. Win payouts provide economic value to owning team shares. Visit the Win Payouts section to learn more!',
                <><br /><br /></>,
                'Share Price Appreciation/Depreciation: Team share prices fluctuate in value based on a number of factors including team performance, injuries, trades, free agent signings, public relations, and general team sentiment. Users are able to buy low and sell high if a team they own rises in value. This is also where users can lose money if they buy a team that decreases in value and sell for a loss.',
                ]
        },
        {
            Q: 'What leagues are offered on SimBull?',
            N: 4,
            A: 'SimBull offers markets for virtual representations of team shares in the NFL, MLB, NBA, NHL and College Football. Our goal is to add every major sport in the United States and abroad. Stay tuned for our next league announcement.', 
        },
        {
            Q: 'How many shares of each team are in the market?',
            N: 5,
            A: 'Current supply outstanding can be found on each the team page.', 
        },
        {
            Q: [ 'How are new shares created?' ],
            N: 6,
            A: [ 'In order for SimBull the SimBull user base to grow, more shares will be need to be added to the market. This will happen through “SimPacks” (like buying a pack of cards). Users will be able to create new shares by purchasing SimPacks for different leagues on SimBull for a set price. When a user buys a SimPack from SimBull, they will receive an equal amount of team shares from each team. This is to make sure all teams have an equal number of shares outstanding.',
                <><br /><br /></>,
                'The price of a full SimPack (1 team share in every league) will be set at $2,720. This means the average team in an NFL SimPack would cost $2,720 / 32 = $80.00. Users will be able to buy fractional packs (0.1, 0.25, 0.5, 0.75, 1) that include less than one full team share of each team in a league.',
                <><br /><br /></>,
                'SimPacks will likely not be bought by users until the average price of a team on SimBull is $85.00. Once the average price of a team share is above $85.00, users might choose to purchase a SimPacks because it offers better value than buying team shares from another user. New shares do not affect the amount of win payouts in any way. Functionality coming Fall 2022.',
                ]
        },
        {
            Q: 'How are team shares removed from the market?',
            N: 7,
            A: 'Removing team shares from the market works similarly to creating new shares. If a user has an equal amount of every team share in a league and wants to fully cash out, they can exchange their team shares with SimBull for the current SimPacks price.', 
        },
        {
            Q: 'Does creating new shares lower the value of my current share?',
            N: 8,
            A: 'SimPacks will likely not be purchased by users until the average price of all teams in a league is over $85.00 per share. If the average team is under $85.00 per share, users are better off buying shares from other users in the market. Once the average team reaches $85.00 per share in a league, users are better off buying SimPacks to introduce more shares into the market. This will allow the average team in a league to maintain a steady price of $85.00 per share. Team share prices will have market fluctuations as they perform better and worse, based on their ability to earn win payouts. New shares do not affect the amount of win payouts in any way, further holding the value of the team shares.', 
        },
        {
            Q: 'How long do team shares last?',
            N: 9,
            A: 'SimBull shares last forever! Team shares transfer season to season allowing users to trade 24 hours a day, 7 days a week, and 365 days a year. This allows users to own team for one week, one month, one year, or beyond giving them more control over their shares! ', 
        },
        {
            Q: 'When can I trade teams?',
            N: 10,
            A: 'Trading is open 24 hours a day, 7 days a week, 365 days a year. Keep in mind, the user who owns the virtual share at the end of a game earns the win payout.', 
        },
        {
            Q: 'Who pays the win payouts?',
            N: 11,
            A: 'SimBull pays the win payouts to provide economic value to the team shares. Win payouts are paid from the trading fees, subscriptions revenue, and any other income that SimBull earns.', 
        },
        {
            Q: 'How are team share prices determined?',
            N: 12,
            A: 'Team share prices are determined by the supply and demand of the market. SimBull is a peer to peer market so when a buyer and seller agree on a price, that is the value of the team share. Because SimBull is a peer to peer market, there is no limit on price movement. The market has seen teams like the SimBengals rise over 100% in a year and other teams like the SimPackers fall over 20% after the Aaron Rodgers drama.', 
        },
        {
            Q: 'How should I price a team share?',
            N: 13,
            A: ['Team shares can be priced based on the expected future wins of each team, overall team sentiment, or fandom. The more wins a team gets, the more win payouts they earn on SimBull. Since team shares last forever, it’s not just about the amount of wins in the next season, but also the amount of wins over the next few years too. For example, say the Rams and 49ers are both valued at $50.00 on SimBull and you expect the Rams to average 10 wins a year over the next 5 years and the 49ers to average 8 wins a year over the next 5 years. You would rather buy the SimRams on SimBull because they will earn you more win payouts for the same price.',
        
                <><br /><br /></>,
            'In another example, say you think Zach Wilson is going to be the next start quarterback in the NFL. Currently on SimBull, the SimJets are the 29th priced team in the NFL market but you think over the next 3 years they will be a top 10 team. You may want to buy the SimJets while they are cheap and not winning very many games, with the expectation their share price will rise once they start winning more games.'],
        },
        {
            Q: 'What is a bid?',
            N: 14,
            A: 'A bid is the most you (or another user) is willing to pay for a team share. Bids listed on the market means another user is wiling to buy the team share at that price.', 
        },
        {
            Q: 'What is an ask?',
            N: 15,
            A: 'An ask is the least you (or another user) is willing to sell a team share for. Asks listed on the market means is willing to sell the team at that price.', 
        },
        {
            Q: [ 'How do I buy and sell a team shares?' ],
            N: 16,
            A: [ 'There are two ways to buy and sell team shares on SimBull.',
                <><br /><br /></>,
                'Buying a team: A market buy allows you to immediate buy team shares at the best available price on the market. If you want to buy a team go to the Trade page → Choose your team → Click Trade → Click Buy → Enter the dollar amount you want to purchase → Submit your trade. This will match you with a seller and it will buy the team share at the best available price.',
                <><br /><br /></>,
                'Selling a team: A market sell allows you to immediate sell team shares at the best available price on the market. The process is similar for selling a team. Go to the Trade page or the Assets page → Choose your team → Click trade → Click Sell → Enter the amount ',
                <><br /><br /></>,
                'Create a bid: Creating a bid requires another user to fulfill your bid in order for the trade to execute. For example, if the current price of a team share is $50.00 but you only want to buy it for $40.00, you can create a bid for $40.00. Later, if another user agrees to sell you the team share for $40.00 the trade will execute.',
                <><br /><br /></>,
                'Create an ask: Creating an as requires another user to fulfill your ask in order for the trade to execute. For example, if the current price of a team share is $50.00 but you only want to sell the team for $60.00, you can create an ask for $60.00. Later, if another user agrees to buy the team share from you for $60.00, the trade will execute.'
                ]
        },
        {
            Q: 'Can I short teams?',
            N: 17,
            A: 'No, you are not able to short sell teams on SimBull. We may add that functionality in the future. ', 
        },
        {
            Q: 'How do I deposit?',
            N: 18,
            A: 'Deposits are made through PayPal. Go to Accounts → Transfers → Add Cash and then select your preferred deposit method. SimBull uses PayPal to accept bank transfers and credit card transactions.', 
        },
        {
            Q: 'How do I withdraw?',
            N: 19,
            A: 'Withdrawals are paid though PayPal. Go to accounts → Transfers → Request Your Money and then enter your withdrawal amount and PayPal email. Once we verify your request, funds will transfer in 1-3 business days. Withdrawals must wait seven days after the initial deposit to ensure deposited funds clear.', 
        },
        {
            Q: 'Is SimBull gambling?',
            N: 20,
            A: 'No, SimBull is considered a game of skill and does not fall under gambling laws. Users on SimBull do not make wagers on individual events and do not lose money to SimBull when teams they own lose, rather they trade teams based on their sports knowledge and the share prices in the market. Due to the long term and peer to peer nature of the market SimBull is classified as a game of skill.', 
        },
        {
            Q: 'Is SimBull available in my state?',
            N: 21,
            A: 'SimBull operates in 41 states due to game of skill laws - with the exceptions being Arizona, Arkansas, Connecticut, Delaware, Louisiana, Montana, South Carolina, South Dakota, and Tennessee. We are hoping to add those states in the future!', 
        },
        {
            Q: 'Do I report my earnings to the IRS?',
            N: 22,
            A: 'Current tax law requires SimBull to provide you and the IRS with a 1099-MISC form if you earn more than $600 from the SimBull market in a calendar year. If that occurs, SimBull will ask for information in order to send the appropriate tax documents.', 
        },
        {
            Q: 'Are there trading fees?',
            N: 23,
            A: 'Yes, SimBull charges a 1.5% trading fee on all trades executed in the market. Trading fees earn SimBull revenue that helps pay win payouts and fund the business! SimBull offers a $4.99/mo subscription for active users who wish to avoid the trading fee.', 
        },
        {
            Q: 'Do you have a trading API?',
            N: 24,
            A: 'Yes. Email customer-service@simbull.app for more information.', 
        },
        {
            Q: 'Where are deposited funds held?',
            N: 25,
            A: 'User deposits are held in a separate account that differs from the SimBull business account. Users deposits are held in reserve and are not used to fund business expenses.', 
        },
        {
            Q: 'How do I contact SimBull?',
            N: 26,
            A: 'Visit the help page or email as at customer-service@simbull.app!', 
        },
        {
            Q: 'Can I buy shares of individual players?',
            N: 27,
            A: 'No, SimBull only offers team share right now. In the future we want to add individual player sports such as golf and UFC, along with individual players on team sports!', 
        },
        
    ]

    const openCards = (num) => {
        if(view === num){
            setView()
        }
        else{
            setView(num)
        }
    }

    //Analytics
    useEffect(()=>{
        if(props && props.location && props.location.state && props.location.state.welcome){
            firebaseAnalytics.logEvent("welcomeFAQ_visit", {
            //user: user && user.email 
            })
        }
    },[])

    return(
        <>
        <Helmet>
            <title>Learn more about SimBull’s sports stock market by reading our FAQ's</title>
            <meta property="og:title" content="xc " />
            <meta name="description" content="Learn more about SimBull’s sports stock market by reading our FAQ's" />
            <meta property="og:url" content="https://www.simbull.app/F-A-Q" />
            <meta property="og:type" content="website" />
        </Helmet>
        {props.location.pathname === "/FAQs" && <NavBar />}
        {props.location.pathname === "/F-A-Q" && <NavBar />}
        {props.location.pathname === "/FAQ" && <Header header='' link={props.history.goBack} />}
        {props.location && props.location.state && props.location.state.welcome && <Header header='' link={()=>{return props.history.replace({pathname: `/welcome`, state: { welcomePage: 6 }})}} />}
        {props.location && props.location.state && props.location.state.welcome && <button style={{marginLeft: '3%', marginTop: '3%', border: 'none', backgroundColor: 'rgb(253, 253, 253'}} onClick={()=>{return props.history.replace({pathname: `/welcome`, state: { welcomePage: 6 }})}}>Back</button>}
        
        <div className='FAQs robo400'>
            <div className="f-head robo900">FAQs</div>
                {faqArray.map(x => {
                    return(
                        <div>
                            <Link onClick={()=>{openCards(x.N)}}><div className="faq-head blk-txt1">{x.Q}</div></Link>
                            {view === x.N && <div className="faq-body">{x.A}</div>}
                        </div>
                    )
                })}
        </div>
        {props.location.pathname === "/FAQs" && <><hr /><Footer /></>}
        {props.location.pathname === "/F-A-Q" && <><hr /><Footer /></>}
        </>
    )
}

export default FAQs