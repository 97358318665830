import React from 'react'
import './Value.css'
import {numberWithCommas} from '../utils'

const Value = (props) => {

    const teammate = props.teammate


    const getTotalValue = () => {
        if(teammate.value){
            return teammate.value.toFixed(0)
        }else{
            return ''
        }
    }

    const getTotalReturnChangeAmount = () => {
        if(teammate.total_return && teammate.total_return.final){
            return teammate.total_return.final
        }else{
            return 0
        }
    }

    let totalReturnChangeAmount = getTotalReturnChangeAmount()

    const getTotalReturnChangePercent = () => {
        if(teammate.total_return && teammate.total_return.final){
            let percent1 = teammate.total_return.final / (teammate.value - teammate.total_return.final)
            percent1 = (percent1 === Infinity) ? 0 : percent1
            return  percent1
        }else{
            return 0
        }
    }
    

    return(
        <>
        <div className="TeammateValue">
            <span className="TeammateValue-Span">Total Value</span>
            <div className="TeammateValue-Amount">${getTotalValue()}</div>
        </div>
        <div className="TeammateValue-Change-Div">
            
            {teammate.total_return && <span className="TeammateValue-Return-Span">
                {totalReturnChangeAmount >= 0 ? '+' : '-'}${numberWithCommas(Math.abs(totalReturnChangeAmount).toFixed(0))}
            </span>}<br />
            {teammate.value ? <>
                <span>{totalReturnChangeAmount >= 0 ? 
                    <><span className="fas fa-caret-up TeammateValue-caret-up"></span> 
                    {totalReturnChangeAmount >= 0 ? '+' : '-'}{Math.abs(getTotalReturnChangePercent() * 100).toFixed(2)}%</>
                    : <span style={{color: 'red'}}>{totalReturnChangeAmount >= 0 ? '+' : '-'}{Math.abs(getTotalReturnChangePercent() * 100).toFixed(2)}%</span>} 
                    
                </span>
            </>: <><span className="fas fa-caret-up" style={{color: 'green', fontSize: '1.1em'}}></span>+0.00%</>}
        </div>
        </>
    )
}

export default Value