import React, {useState, useEffect} from 'react'
import './Search.css'
import {searchUsername} from '../utils'
import ReactLoading from 'react-loading'
import Teammate from './Teammate'
import TeammateNewList from './TeammateList'
import {firebaseAnalytics} from '../../../services/firebase'

const Search = (props) => {

    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState('')

    const [results, setResults] = useState([])

    const makeSearch = (str) => {
        if(str !== ''){
            setLoading(true)
            searchUsername(props.token, str).then(res => {
                setResults(res)
                setLoading(false)
            }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        firebaseAnalytics.logEvent("teammates_search_visit")
    }, [])

    return(
        <>
        <div className="TeammateSearch">
            <div className="Search-Div">
                <div className="Search-Header">Search</div>
                <div className="Search-Input-Div">
                    <input className="Search-Input" placeholder="name" onChange={(e)=>setSearch(e.target.value)} />
                    <button className="Search-btn" onClick={()=>makeSearch(search)}><i class="fas fa-search"></i></button>
                </div>

                {loading && <div style={{width: '15%', marginLeft: '42.5%'}}><ReactLoading width='100%' type='spokes' color='rgb(200,200,200)' /></div> }
                {!loading && results && <div className="Search-Results">
                    {results.map(r => {
                        console.log(r)
                        return(
                            <Teammate token={props.token} teammates={props.teammates} rerunTeammates={props.rerunTeammates} changeTeammate={props.changeTeammate} s={r} />
                        )
                    })}
                </div>}

            </div>
        </div>

        <div style={{padding: '7px'}}><TeammateNewList token={props.token} teammates={props.teammates} rerunTeammates={props.rerunTeammates} newTeammates={props.newTeammates} changeTeammate={props.changeTeammate} /></div>

        </>
    )
}

export default Search