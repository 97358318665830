import React, {useEffect} from 'react'
import './Account.css'
import {Link} from 'react-router-dom'
import {auth, firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import PDF from '../assets/PayoutAssurance.pdf'


const Assurance = (props) => {

    const user = props.user

    useEffect(()=>{
        firebaseAnalytics.logEvent('assurance_visit')
    },[])

    return(
        <>
        <iframe src={PDF} frameBorder="0" scrolling="yes" seamless="seamless" 
        style={{display:'block', width:'100%', height:'100vh'}}></iframe>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(Assurance)