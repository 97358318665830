import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import TeamGraph from '../Holdings/TeamGraph'
import './LandingPage3.css'

const TeamCard = (props) => {

    const t = props.t


    return(
        <>
            <div className="LP3-teamcard">

                <div><img style={{width: '25%', marginLeft: '5px', marginTop: '5px'}} src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>

                <div style={{marginLeft: '5px', marginRight: '4px'}}>
                    <div style={{fontSize: '1.2rem'}}>
                        <span style={{fontStyle: 'italic', fontWeight: '400'}}>Sim</span>{t.team.name}
                    </div>
                    <div  style={{fontSize: '1.5rem', fontWeight: '600', width: '50%', display: 'inline-block'}}>
                        {t.asking_price && `$${t.asking_price.toFixed(2)}`}
                    </div>
                    <div  style={{textAlign: 'right',fontSize: '1.4rem', fontWeight: '500', width: '50%', display: 'inline-block', color: `${t.change && t.change.day >= 0 ? 'green' : 'red'}`}}>
                        {t.change && `${t.change.day >= 0 ? '+' : '-'}${Math.abs(t.change.day * 100).toFixed(2)}%`}
                    </div>

                    <div>
                    <div  style={{fontSize: '1rem', fontWeight: '400', color: 'gray', width: '50%', display: 'inline-block'}}>
                        DAY
                    </div>
                    <div  style={{textAlign: 'right',fontSize: '1.1rem', fontWeight: '300', width: '50%', display: 'inline-block', color: `${t.change && t.change.day >= 0 ? 'green' : 'red'}`}}>
                        {t.change && `${t.change.day >= 0 ? '+' : '-'}${Math.abs(t.change.day * 100).toFixed(2)}%`}
                    </div>
                    </div>

                    <div>
                    <div  style={{fontSize: '1rem', fontWeight: '400', color: 'gray', width: '50%', display: 'inline-block'}}>
                        WEEK
                    </div>
                    <div  style={{textAlign: 'right',fontSize: '1.1rem', fontWeight: '300', width: '50%', display: 'inline-block', color: `${t.change && t.change.week >= 0 ? 'green' : 'red'}`}}>
                        {t.change && `${t.change.week >= 0 ? '+' : '-'}${Math.abs(t.change.week * 100).toFixed(2)}%`}
                    </div>
                    </div>

                    <div>
                    <div  style={{fontSize: '1rem', fontWeight: '400', color: 'gray', width: '50%', display: 'inline-block'}}>
                        YEAR
                    </div>
                    <div  style={{textAlign: 'right',fontSize: '1.1rem', fontWeight: '300', width: '50%', display: 'inline-block', color: `${t.change && t.change.year >= 0 ? 'green' : 'red'}`}}>
                        {t.change && `${t.change.year >= 0 ? '+' : '-'}${Math.abs(t.change.year * 100).toFixed(2)}%`}
                    </div>
                    </div>
                </div>
                

                
                
                {/*}
                <div className="LP3-teamcard-statsdiv">
                    <div className="LP3-teamcard-stats">Stats</div>
                    <div className="LP3-teamcard-stat">
                        <div className="LP3-teamcard-stat-left">52 Wk High</div><div className="LP3-teamcard-stat-right">{t.yearHigh && t.yearHigh.toFixed(2)}</div>
                    </div>
                    <div className="LP3-teamcard-stat">
                        <div className="LP3-teamcard-stat-left">52 Wk High</div><div className="LP3-teamcard-stat-right">{t.yearHigh && t.yearHigh.toFixed(2)}</div>
                    </div>
                    <div className="LP3-teamcard-stat">
                        <div className="LP3-teamcard-stat-left">52 Wk High</div><div className="LP3-teamcard-stat-right">{t.yearHigh && t.yearHigh.toFixed(2)}</div>
                    </div>
                    <div className="LP3-teamcard-stat">
                        <div className="LP3-teamcard-stat-left">52 Wk High</div><div className="LP3-teamcard-stat-right">{t.yearHigh && t.yearHigh.toFixed(2)}</div>
                    </div>
                </div>
                */}

                {/*<hr style={{padding: '0', margin: '0', width: '96%', marginLeft: '2%'}}/>*/}
                
                

                {/*<div style={{textAlign: 'center'}}>
                    <button style={{textAlign: 'center'}}>Add to Cart</button>
                </div>*/}

            </div>
            
        </>
    )
    
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(TeamCard)