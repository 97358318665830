import React, {useState, useRef, useEffect} from 'react'
import './Login.css'
import Navbar from '../LandingPage4/NavBar'
import Footer from '../LandingPage3/Footer'
import {db, auth} from '../../services/firebase'
import firebase from 'firebase'
import moment from 'moment'
import {Link, withRouter, useHistory} from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import Helmet from 'react-helmet'
import {login} from '../../routes/auth/auth'
import {getTotals} from '../../routes/user/user'
import {getToken} from '../../redux/actions/token'
import { useDispatch, useSelector } from 'react-redux';
import Signup from '../LandingPage3/Signup'
import Popup from 'reactjs-popup'
import { Col, Form, FormLabel, Row, ToastBody } from 'react-bootstrap'
import '../LandingPage3/landingpage.css'
import '../LandingPage3/LandingPage3.css'

const Login = (props) => {
    //states
    //const [login, setLogin] = useState(props.location.state ? false : true)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')
    const emailField = useRef(null)
    const passwordField = useRef(null)

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    let code = ''
    if(props.location.state && props.location.state.code){code = props.location.state.code}

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=>{
        let token = window.localStorage.getItem('refresh_token')
        //getTotals(token).then(res => console.log(res))
    })

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'guest'})
    },[])

    //capture autofill 
    useEffect(() => {
        let interval = setInterval(() => {
          if (emailField.current) {
            setEmail(emailField.current.value)
            setPassword(passwordField.current.value)
            //do the same for all autofilled fields
            clearInterval(interval)
          }
        }, 100)
        
    })
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])


    const handleClick = (e) => {
        e.preventDefault()
    }

    const handleSignupRes = (res) => {
        let carry
        //mail with no '@', already used email
        //short(long) username, already used username
        //short password
        if(res && res.status && res.status === 200){
            carry = {type: 'success', data: res} //res returns {refresh_token: 'token', access_token: 'token'}
        }else if(res && res.status && res.status === 400 || res.status === 403){
            if(res.data && res.data.msg && !res.data.msg.query){ //already used email -or- already user username
                carry = {type: 'err', msg: res.data.msg}
            }
            else if(/*res.data.msg.query.email[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.email && res.data.msg.query.email[0]){
                carry = {type: 'err', msg: res.data.msg.query.email[0]}//short(long) or missing email
            }
            else if(/*res.data.msg.query.username[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.username && res.data.msg.query.username[0]){
                carry = {type: 'err', msg: res.data.msg.query.username[0]} //short(long) or missing username
            }
            else if(/*res.data.msg.query.password[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.password && res.data.msg.query.password[0]){
                carry = {type: 'err', msg: `Passwords need to be 8 characters`} //short(long) or missing password
            }
            else if(/*res.data.msg.query.first_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.first_name && res.data.msg.query.first_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.first_name[0]} //short(long) or missing first_name
            }
            else if(/*res.data.msg.query.last_name[0]*/res.data && res.data.msg && res.data.msg.query && res.data.msg.query.last_name && res.data.msg.query.last_name[0]){
                carry = {type: 'err', msg: res.data.msg.query.last_name[0]} //short(long) or missing last_name
            }
            else if(res && res.status && res.status === 403){
                carry = {type: 'err', msg: `Wrong Email or Password`}
            }
            else {
                carry = {type: 'err', msg: 'Unknown Error, please refresh and try again or contact us at customer-service@simbull.app'}
            }
        }
        else if(res && res.access_token && res.refresh_token){
            carry = {type: 'success', data: res}
        }
        else if(res && res.status && res.status === 500){
            carry = {type: 'err', msg: 'Error, please refresh'}
        }
        else {
            carry = {type: 'err', msg: 'Unknown Error, please refresh and try again or contact us at customer-service@simbull.app'}
        }
        return carry
    }


    const handleLogIn = (e) => {
        e.preventDefault()
        login(email, password).then(res => {
            if(res){
                let result = handleSignupRes(res)
                if(result.type === 'success'){
                    dispatch(getToken(res))
                    history.replace('/Portfolio')
                }
                else if(result.type === 'err'){
                    setError(true)
                    setErrorMsg(result.msg)
                }
                else {
                    setError(true)
                    setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
                }
            }
            else {
                setError(true)
                setErrorMsg('Unknown Error, please refresh and try again or contact us at customer-service@simbull.app')
            }
            
        })
    }

    const [show, setShow] = useState('login')

    const switchView = () => {
        if(show === 'login'){
            setShow('signup')
        }
        else setShow('login')
    }


    return(
        <>
        <Helmet>
            <title>Login to SimBull</title>
            <meta property="og:title" content="Login to SimBull | Create a free account in seconds!" />
            <meta property="og:description" content="Buy and sell shares of your favorite sports teams teams just like the stock market. Create a free account in seconds!" />
            <meta property="og:url" content="https://www.simbull.app/Login" />
            <meta property="og:type" content="website" />
        </Helmet>
            <Navbar />
            <div className="loginPage">
            {show === 'login'
             ? <>
            
                <div className="signup" style={{marginTop: "-10%"}}>
                    <hr />
                    <div className="FAQs3">
                    <div className="signup-title robo900" style={{fontWeight: '600', fontSize: '2em'}}>Log In</div>
                    <div style={{fontWeight: '500', marginBottom: '10px', color: 'green'}}>As of February 7th, all accounts must reset their password before logging in again. 
                        <br /><Link to="/Password_Reset_Email">Reset Here</Link>
                    </div>
                    {/* <form onSubmit={handleClick}> */}
                    {error && <div style={{color: 'red'}}>Error: {errorMsg}</div>}
                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" style={{textAlign: "center"}}>
                                <Form.Label className="robo400">Email</Form.Label>
                                <Form.Control onChange={(e)=>{setEmail(e.target.value)}} value={email} className="signup-bg" type="email"  style={{textAlign: 'center', width: '70%', marginLeft: '15%'}} />
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" style={{textAlign: "center"}}>
                                <Form.Label className="robo400">Password</Form.Label>
                                <Form.Control onChange={(e)=>{setPassword(e.target.value)}} value={password} className="signup-bg" type="password" style={{textAlign: 'center', width: '70%', marginLeft: '15%'}} />
                            </Form.Group>
                        </Row>
                        <span className='signup-terms robo400'>By creating an account, you agree to SimBull's<a href='/Terms-Of-Use'> Terms</a></span>
                        <br/>
                        <div className="account-form">
                            <button className="topScrollBtn1 robo400" onClick={handleLogIn}>Go To the Market</button>
                        </div>
                    </Form>
                    </div>
                    <div style={{textAlign: 'center'}}>Need to {show === 'login' ? 'Sign Up' : 'Log In'}? <Link onClick={switchView}>{show === 'login' ? 'Sign Up' : 'Log In'} Here</Link></div>
                    <hr />
                </div>
            </>
            : 

            <Signup switchView={switchView} show={show} />}

            
            </div>
            <Footer />
        </>
    )
}

export default withRouter(Login)