import React, {useEffect, useState} from 'react' //react
import './App.css'; //CSS
import 'bootstrap/dist/css/bootstrap.min.css'; //bootstrap css ---> do i need this?
import {BrowserRouter, Switch, Route, useLocation, withRouter} from 'react-router-dom' //react router for pages
import { auth, db, storageRef } from './services/firebase'; //firebase packaging
import firebase from 'firebase' //firebase db
import { useDispatch, useSelector } from 'react-redux'; //redux state management
import {getTeamsValues} from './redux/actions/teamsValue' //run getTeams each load
import {getToken} from './redux/actions/token' //auth jwt 
import {doRefresh} from './routes/user/token'
import {get_tweets} from './routes/tweets/tweets'
import {Helmet} from 'react-helmet' // for header stuff
import moment from 'moment' //for dates
import jwt_decode from 'jwt-decode' //jwt 
//LandingPage
import LandingPage3 from './components/LandingPage3/LandingPage3'
import OurStory from './components/LandingPage3/OurStory3'
import ContactUs from './components/LandingPage3/ContactUs3'
import MakeMoney from './components/LandingPage3/MakeMoney3'
import FAQs from './components/LandingPage3/FAQs3'
//Mains
import Holdings from './components/Holdings/Holdings'
import Analysis from './components/Analysis3/Analysis'
import TwoAnalysis from './components/Analysis3/TwoAnalysis'
import HomeBar from './components/HomeBar/HomeBar'
import TopBar from './components/HomeBar/TopBar'
//THree
import Portfolio3 from './components/PortfolioDesktop/Portfolio'
import Rewards from './components/PortfolioDesktop/Rewards'
import Redeem from './components/PortfolioDesktop/Redeem/Redeem'
import Help from './components/PortfolioDesktop/Help'
import History from './components/PortfolioDesktop/History'
import Assets from './components/PortfolioDesktop/Assets'
import Trade2 from './components/Trade2/Trade2'
import TradeDesktop from './components/TradeDesktop/Trade2'
import LearnPage from './components/PortfolioDesktop/LearnDesktop/LearnPage'
import More from './components/PortfolioDesktop/MoreDesktop/More'
//Portfolio Extended
import AllTeams from './components/Portfolio3/Extended/AllTeams'
import WinPayouts from './components/Portfolio3/Extended/WinPayouts'
import Trades from './components/Portfolio3/Extended/Trades'
import MyTransfers from './components/Portfolio3/Extended/Transfers'
import AllOffers from './components/Portfolio3/Extended/AllOffers'
import FullOrderBook from './components/PortfolioDesktop/Extended/FullOrderBook'
import AllTeamOffers from './components/Portfolio3/Extended/AllTeamOffers'
import TeamPage3 from './components/PortfolioDesktop/TeamPage/TeamPage' //Desktop _____________
import Payouts3 from './components/Payouts3/Payouts'
//Trade
import Trade from './components/Trade2/Trade2'
import AllTrades from './components/Trade2/AllTrades'
import FullList from './components/Trade2/FullList'
import FullLeague from './components/Trade2/FullLeague'
import ExcelOffers from './components/Trade2/ExcelOffers'
//Articles
import Article from './components/Analysis3/Article'
import AddNews from './components/Analysis3/News/AddNews'
import Schedule from './components/Analysis3/Schedule'
//Receipts
import PurchaseReceipt from './components/Receipts/PurchaseReceipt';
//Teammates
import FriendsThree from './components/Account2/Friends/FriendsThree'
//Account
import UserAccount from './components/Account2/UserAccount'
import EditAccount from './components/Account2/EditAccount'
import EditAccountDesktop from './components/Account2/EditAccountDesktop'
import Transfers from './components/Account2/Transfers'
import TransfersDesktop from './components/Account2/TransfersDesktop'
import QuickDeposit from './components/Account2/QuickDeposit'
import Withdraw from './components/Account2/Withdraw'
import WithdrawTwo from './components/Account2/WithdrawTwo'
import WithdrawDesktop from './components/Account2/WithdrawDesktop'
import RequestPennant from './components/Account2/RequestPennant'
import SubscriptionTwo from './components/Account2/SubscriptionTwo'
import GOLD from './components/Account2/GOLDDesktop'
import Referrals from './components/Account2/Referrals'
import Notifications from './components/Account2/Notifications'
import EditNotifications from './components/Account2/EditNotifications'
import GiveReview from './components/Account2/GiveReview'
import TermsOfUse from './components/Account2/TermsOfUse'
import Privacy from './components/Account2/Privacy'
import Tutorial from './components/Account2/Tutorial'
import TextCollect from './components/Account2/TextCollect'
import Analysts from './components/Account2/Analysts/Analysts'
//Welcome
import WelcomeTour from './components/WelcomeTour3/WelcomeTour'
//Others
import GettingStarted from './components/GettingStarted/GettingsStarted'
import Login from './components/LogIn3/LoginBlack'
import Login3 from './components/LogIn3/Login'
import Admin from './components/Admin'
import { getUser } from './redux/actions/user';
import OffersReceipt from './components/Receipts/OffersReceipt';
import News from './components/Analysis3/News/News';
import NewsArticle from './components/Analysis3/News/NewsArticle';
import SimNHLRelease from './components/adHoc/SimNHL-Release'
import Loader from './components/adHoc/Loader'
import Assurance from './components/Account2/Assurance'
//TeamReports
import InputReport from './components/GOLD/TeamReports/InputReport'
import TeamReports from './components/GOLD/TeamReports/TeamReports';
import SimBullSix from './components/adHoc/SimBullSix'
import SuperBowlPick2021 from './components/adHoc/SuperBowlPick2021'
//adHoc
import TikTokForm from './components/adHoc/TikTokForm'
import PlayoffPicks from './components/PlayoffPicks/PlayoffPicks'
import {useClearCache} from 'react-clear-cache';
//password reset
import EmailCapture from './components/Account2/ResetPassword/EmailCapture'
import ResetPassword from './components/Account2/ResetPassword/ResetPassword'
// Signup
import SignupPage from "./components/Signup/SignupPage";


//WIP
import LandingPage4 from './components/LandingPage4/LandingPage4'
import SimBull16 from './components/SimBull16/SimBull16'
import Spins from './components/Spins3/Spins'
import Timeline from './components/LandingPage4/v2/Timeline'
import LandingPage5 from './components/LandingPage4/v2/LandingPage2'
import LandingPageAds from './components/LandingPage4/v2/LandingPage'
import LandingPagePicks from './components/LandingPage4/v2/LandingPagePicks'
import Teammates from './components/Teammates/Teammates'
import TeammateProfile from './components/Teammates/Profile/TeammateProfile'
import SimBull15 from './components/SimBull15/SimBull15'
import Sportle from './components/Sportle/Sportle'

import amplitude from 'amplitude-js'

import RewardsPage from './components/rewards/rewardsPage'



const App = (props) => {
  // Option 1, initialize with API_KEY only
  //States
  const teams = useSelector(state => state.teams.teams ? state.teams.teams.teams : [])
  const user = useSelector(state => state.user ? state.user.user : {})
  let token = useSelector(state => state.token ? state.token.token : '')
  const [authUser, setAuthUser] = useState({loggedIn: false}, {loading: 0})
  const [path, setPath] = useState()
  const [url, setUrl] = useState('https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/Logo1.png?alt=media&token=9cea86f8-e2a2-43c3-a990-fa37522f2e4b')

  const dispatch = useDispatch()


  //Auto Scroll to Top Every Page Switch
  function _ScrollToTop(props) {
    const { pathname } = useLocation();
    setPath(pathname)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
  }



  let location
  const [appLocation, setAppLocation] = useState()


  useEffect(()=>{
    amplitude.getInstance().init("b5bd84ea779f2b293954128f2cec59e5")
    amplitude.getInstance().logEvent('Visit')
  },[])

  //instant dispatches
  useEffect(()=>{
    dispatch(getToken())
    dispatch(getTeamsValues())
    const interval = setInterval(() => {
        dispatch(getToken())
        dispatch(getTeamsValues())
    }, 600000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks. 
  },[])



  let quickToken = false
  const getQuickToken = async () => {
    let refresh = window.localStorage.getItem('refresh_token')
    var refreshDecoded = jwt_decode(refresh);
    quickToken = await doRefresh()
  }

  useEffect(()=>{
    /*get_tweets().then(res => {
      console.log(res)
    })*/
  },[])

  
  return(
    <div style={{backgroundColor: 'black', minHeight: '100vh'}}>
      <BrowserRouter>
      <Helmet>
        <title>SimBull - Sports Stock Market | Trade Sports Stocks</title>
        <meta property="og:title" content="SimBull - Sports Stock Market | Trade Sports Stocks" />
        <meta name="description" content="Buy and sell shares of your favorite sports teams teams just like the stock market. Join SimBull's stock market for sports today!" />
        <meta property="og:image" content={url} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content="https://www.simbull.com" />
        <meta property="og:type" content="website" />
      </Helmet>
          {/*token && <TopBar appLocation={appLocation} />*/}
          {token && !window.location.pathname.includes("rewards_app") && <HomeBar />}
              { !token ? 
                    <Switch>
                      {/* Testing */}
                      <Route exact path="/rewards_app" component={RewardsPage} />
                      <Route path='/LandingPage4' component={LandingPage4} />
                      <Route path='/Timeline' component={Timeline} />
                      <Route path='/SimBull16' component={SimBull16} />
                      <Route exact path='/LandingPage5' component={LandingPage5} />
                      <Route exact path='/PicksWelcome' component={LandingPagePicks} />
                      <Route exact path='/Facebook' component={LandingPageAds} />
                      <Route exact path='/Twitter' component={LandingPageAds} />
                      <Route exact path='/TikTok' component={LandingPageAds} />
                      <Route exact path='/Email' component={LandingPageAds} />
                      <Route exact path='/TestingTeammates' component={Teammates} />
                      <Route exact path='/Sportle' component={Sportle} />

                      {/* Landing Pages */}
                      <Route exact path='/LandingPage3' component={LandingPage3} />
                      <Route exact path='/' component={LandingPage5} />

                      <Route path='/Portfolio3' component={Portfolio3} />
                      

                      <Route path='/ContactUs' component={ContactUs} />
                      <Route path='/Can-I-Make-Money' component={MakeMoney} />
                      <Route path='/F-A-Q' component={FAQs} />
                      <Route path='/Getting-Started' component={GettingStarted} />
                      <Route path='/Our-Story' component={OurStory} />
                      <Route path='/Login' component={Login} />
                      <Route path='/Login3' component={Login3} />
                      <Route path='/signup' component={SignupPage} />

                      <Route exact path='/Terms-Of-Use' component={TermsOfUse} />
                      <Route exact path='/Privacy-Policy' component={Privacy} />
                      <Route exact path='/PayoutAssurance' component={Assurance} />

                      <Route exact path='/TikTokContest' component={TikTokForm} />
                      <Route path='/Picks' component={SimBull16} />

                      <Route path='/Password_Reset_Email' component={EmailCapture} />
                      <Route path='/password_reset' component={ResetPassword} />

                       {/* Catch */}
                      <Route path='/' component={LandingPage5} />
                    </Switch>
                    : token && teams.length > 0 &&
                      <Switch>
                        <Route exact path="/rewards_app" component={RewardsPage} />
                        <Route exact path='/TestingTeammates' component={Teammates} />
                        <Route exact path='/TestingTeammates/:id' component={TeammateProfile} />
                        <Route exact path='/SimBull15' component={SimBull15} />

                        {/* Admin */}
                        <Route exact path='/Admin' component={Admin} />

                        <Route exact path='/PicksWelcome' component={LandingPagePicks} />

                        <Route exact path='/login3' component={Login3} />
                        <Route path='/Password_Reset_Email' component={EmailCapture} />

                        {/* Landing Pages */}
                        <Route path='/ContactUs' component={ContactUs} />
                        <Route path='/Can-I-Make-Money' component={MakeMoney} />
                        <Route path='/FAQs' component={FAQs} />
                        <Route path='/Getting-Started' component={GettingStarted} />
                        <Route path='/Our-Story' component={OurStory} />

                        <Route exact path='/Welcome' component={WelcomeTour} />
                        {/* Main 4 Pages */}
                        <Route path='/MyTeams' component={AllTeams} />
                        <Route path='/Picks' component={SimBull16} />
                        <Route path='/Portfolio' component={Portfolio3} />
                        <Route path='/Analysis' component={Analysis} />
                        <Route path='/TwoAnalysis' component={TwoAnalysis} />

                        {/* Three */}
                        <Route path='/Portfolio3' component={Portfolio3} />
                        <Route path='/Trade2' component={Trade2} />
                        <Route path='/Spins' component={Spins} />
                        
                        {/* Portfolio Extended */}
                        <Route path='/MyTeams' component={AllTeams} />
                        <Route path='/MyPayouts' component={WinPayouts} />
                        <Route path='/MyTrades' component={Trades} />
                        <Route path='/MyTransfers' component={MyTransfers} />
                        <Route exact path='/AllOffers' component={AllOffers} />
                        <Route path='/AllOffers/:id' component={AllTeamOffers} />
                        <Route path='/Teams/:id' component={TeamPage3} />
                        <Route path='/Teams3/:id' component={TeamPage3} />
                        <Route path='/OrderBook/:id' component={FullOrderBook} />
                        <Route path='/Payouts' component={Payouts3} />
                        <Route path='/Payouts3' component={Payouts3} />

                        <Route path='/Trade' component={Trade2} />
                        <Route path='/LatestTrades' component={AllTrades} />
                        <Route path='/TopMovers' component={FullList} />
                        <Route path='/league/:id' component={FullLeague} />

                        {/* Analysis Articles */}
                        <Route path='/content/:id' component={Article} />                      
                        <Route path='/addNews' component={AddNews} />
                        <Route exact path='/News' component={News} />
                        <Route path='/News/:id' component={NewsArticle} />
                        {/* Receipts */}
                        <Route path='/Receipts' component={PurchaseReceipt} exact/>
                        <Route path='/OfferReceipts' component={OffersReceipt} exact/>
                        {/*Leaderboard */}
                        {/*<Route path='/Leaderboard' component={Leaderboard} exact/>*/}
                        {/* Teammate Pages */}
                        <Route exact path='/Teammates' component={Teammates} />
                        {/* Account Pages */}
                        <Route exact path='/Account' component={UserAccount} />
                        <Route exact path='/EditAccount' component={EditAccount} />
                        <Route exact path='/Analysts' component={Analysts} />
                        <Route exact path='/Transfers' component={Transfers} />
                        <Route exact path='/QuickDeposit' component={QuickDeposit} />
                        <Route exact path='/Withdraw' component={WithdrawTwo} />
                        <Route exact path='/RequestPennant' component={RequestPennant} />
                        <Route exact path='/Subscription' component={GOLD} />
                        <Route exact path='/SubscriptionTwo' component={GOLD} />
                        <Route exact path='/Referrals' component={Referrals} />
                        <Route exact path='/Notifications' component={Notifications} />
                        <Route exact path='/EditNotifications' component={EditNotifications} />
                        <Route exact path='/GiveReview' component={GiveReview} />
                        <Route exact path='/Contact-Us' component={ContactUs} />
                        <Route exact path='/FAQ' component={FAQs} />
                        <Route exact path='/TermsOfUse' component={TermsOfUse} />
                        <Route exact path='/Privacy' component={Privacy} />
                        <Route exact path='/Tutorials' component={Tutorial} />
                        <Route exact path='/Text' component={TextCollect} />
                        {/*<Route path='/Notification' component={Notification} />*/}
                        <Route exact path='/SimNHL-Release' component={SimNHLRelease} />
                        <Route exact path='/PayoutAssurance' component={Assurance} />

                        <Route exact path='/ExcelOffers' component={ExcelOffers} />


                        <Route path='/TeamReports/:id' component={TeamReports} />
                        <Route exact path='/InputReport' component={InputReport} />
                        <Route exact path='/SuperBowlPick2021' component={SuperBowlPick2021} />
                        <Route exact path='/Schedule' component={Schedule} />

                        <Route exact path='/TikTokContest' component={TikTokForm} />
                        <Route exact path='/PlayoffPicks' component={PlayoffPicks} />

                        <Route path='/SimBull16' component={SimBull16} />

                        <Route exact path='/Sportle' component={Sportle} />


                        {/* Desktop ReDesign */}
                        <Route exact path='/Assets' component={Assets} />
                        <Route exact path='/TradeDesktop' component={TradeDesktop} />
                        <Route exact path='/LearnPage' component={LearnPage} />
                        <Route exact path='/More' component={More} />
                        <Route exact path='/GOLD' component={GOLD} />
                        <Route exact path='/Deposit' component={TransfersDesktop} />
                        <Route exact path='/Withdrawal' component={WithdrawDesktop} />
                        <Route exact path='/Settings' component={EditAccountDesktop} />
                        <Route exact path='/Transactions' component={History} />
                        <Route exact path='/Help' component={Help} />
                        <Route exact path='/Rewards' component={Rewards} />
                        <Route exact path='/Redeem' component={Redeem} />
                        

                        {/* All */}
                        <Route path='/' component={Portfolio3} />

                      </Switch> 
                  }

      </BrowserRouter>
      
    </div>
  )
}


export default App;

