import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../Portfolio3/portfolio2.css'
import './Trade.css'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {getTrades} from '../../redux/actions/trades'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../adHoc/Loader'
import {convertTimestamp} from '../Tools/timestampToFull'

const AllTrades = (props) => {
    //get props
    const user = props.user
    
    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])

    const dispatch = useDispatch()
    
    const [transactions, setTransactions] = useState([])

    /*useEffect(()=>{
        if(transactions.length === 0){
            let times = []
            let timesObj = {}
            let transactions1 = []
            let arr = props.trades
            console.log('trades below')
            console.log(trades)
            arr.sort((a,b) => {
                if(b.timestamp > a.timestamp){
                    return 1
                }
                else return -1
            })
            arr.map(t => {
                if(t.asset_id === 20){console.log(t)}
                let timestamp = `${t.price}-${t.asset_id}`
                if(!times.includes(timestamp)){
                    times.push(timestamp)
                    timesObj[timestamp] = t
                    timesObj[timestamp].fullPaid = (t.amount * t.price)
                }
                else{
                    timesObj[timestamp].fullPaid += (t.amount * t.price)
                    timesObj[timestamp].amount += t.amount
                }
            })
            times.map(t => {
                transactions1.push(timesObj[t])
            })
            setTransactions(transactions1)
        }
    },[trades])*/

    useEffect(()=>{
        if(props.trades.length === 0){
            dispatch(getTrades())
        }
    },[])

    useEffect(()=>{
        if(teams.length === 0 && props.teamsValue.length !== 0){
            setTeams(props.teamsValue)
        }
    },[props.teamsValue])

    useEffect(()=>{
        if(transactions.length === 0 && props.trades.length !== 0){
            setTransactions(props.trades)
        }
    },[props.trades])

    const getTeam = (asset_id) => {
        let team = {}
        teams.map(t => {
            if(t.team.id === asset_id){
                team = t
                return t
            }
        })
        return team
    }

    const getDate = (timestamp) =>{
        let date = moment(timestamp).unix()
        return convertTimestamp(date)
    }

    return(
        <div className="DesktopContainer" style={{ margin: '0', paddingBottom: '10vh', minHeight: '100vhvisual'}}>
            <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '73%', paddingLeft: '5px'}}>Latest Trades</div> <div style={{width: '20%',textAlign: 'right', display: 'inline-block', paddingLeft: '5px'}}>{/*<span className="fa fa-ellipsis-v"></span>*/}</div></div>
            <hr style={{marginTop: '2px', paddingTop: '0', marginLeft: '1%', marginLeft: '1%', marginBottom: '3px'}} />
            {(transactions && teams.length !== 0 && transactions.length > 0) ? transactions.map( t => {
                let team = getTeam(t.asset_id)
                return(
                    <div key={`${team.team.name}${team.asking_price}${Math.random()}`} style={{marginLeft: '1%', marginRight: '1%'}}>
                    <Link to={`/Teams/${team.team.name}`} style={{color: 'black'}}>
                        <div style={{width: '10%',paddingLeft: '1%', display: 'inline-block'}}><img className="allTrades-img" src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                        <div style={{display: 'inline-block', width: '50%', paddingLeft: '2%', fontSize: '1.1em', verticalAlign: 'top'}}>
                            <div style={{fontSize: '1em'}}>
                                <span>{t.type === 'BUY' ? 'Buy' : 'Sell'}:{' '}</span>
                                Sim{team.team.name} </div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{getDate(t.timestamp)}</div>
                        </div>
                        <div style={{display: 'inline-block', width: '40%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'bottom'}}>
                            <div style={{fontSize: '.9em', color: 'black'}}>${(t.price).toFixed(2)} 
                            <span style={{fontSize: '1.1em', color: team.change.day < 0 ? 'darkred' : 'green'}}>({team.change.day < 0 ? '' : '+'}{(team.change.day * 100).toFixed(2)}%)</span>
                            </div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{(t.amount).toFixed(4)} Shares</div>
                        </div>
                        <hr style={{margin: '0', padding: '0', marginTop: '3px', marginBottom: '3px'}} />

                        
                    </Link>
                    </div>
                )
            }): 
                <Loader />
            }


            {/*<div style={{marginLeft: '3%', marginRight: '3%'}}>
                <div style={{textAlign: 'center'}}>
                    <button style={{fontSize: '1.1em', textDecoration: 'underline', border: 'none', outline: 'none', backgroundColor:'white'}}>View More</button>
                </div>
            </div>*/}


        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    trades: state.trades.trades,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    teams3: state.teams3.teams3
});

export default connect(mapStateToProps)(AllTrades)