import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics, auth} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import './playoffs.css'
import logo from '../assets/Logo1.png'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import {FacebookIcon} from 'react-share'
import moment2 from 'moment-timezone'
import {useClearCache} from 'react-clear-cache';
import Spinner from './Spinner2'

const PlayoffPicks = (props) => {

    let banner = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fbanner.png?alt=media&token=5181a60f-d72c-4d8a-872a-761dce02be4a'
    let bannerMobile = 'https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2FbannerMobile.png?alt=media&token=d76a130a-a778-4620-a357-f7366a080f8c'

    const { isLatestVersion, emptyCacheStorage } = useClearCache();


    useEffect(()=>{
        window.scrollTo(0,0)
        firebaseAnalytics.logEvent('NFLplayoff_visit')
    },[])

    const getUserEmail = () => {
        if(props.info && props.info.email){
            return props.info.email
        }
        else return null
    }

    const user = props.user ? props.user : {}

    let [currentTime, setCurrentTime] = useState(moment2().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss'))

    //Raiders @ Bengals --> 194839
    //Patriots @ Bills --> 845308
    //Bucs-Eagles --> 924301
    //49ers @ Cowboys --> 438293
    //Chiefs--Steelers --> 938204
    //Carindals @ Rams --> 243914

    //Bengals @ Titans ---> 745892 Titians -3.5 Sat 3:30 47.5
    //49ers @ Packers --> 329238 Pack Sat 7:15 -4.5 47.5
    //Rams @ Bucs --> 349875 Bucs -2.5 Sun 2:00 48
    //Bills @ Chiefs --> 139282 Chiefs -2.5 Sun 5:30 54.5

    //Bengals @ Chiefs --> 948753 Chiefs -6.5 Sun 2:00 54.5 754389
    //49ers @ Rams --> 274589 Rams -3.5 Sun 5:30 46.5 893872

    //Rams @ Bengals --> 720451 Rams -4.5 Sun 5:30pm Feb 13

    const [allPicks, setAllPicks] = useState()
    const [game1, setGame1] = useState({})
    const [game2, setGame2] = useState({})
    const [game3, setGame3] = useState({})
    const [game4, setGame4] = useState({})
    const [game5, setGame5] = useState({})
    const [game6, setGame6] = useState({})
    const [game7, setGame7] = useState({})
    const [game8, setGame8] = useState({})
    const [game9, setGame9] = useState({})
    const [game10, setGame10] = useState({})
    const [game11, setGame11] = useState({})//948753
    const [game12, setGame12] = useState({})//274589
    const [game13, setGame13] = useState({})//720451

    const [game14, setGame14] = useState({}) //894512
    const [game15, setGame15] = useState({}) //734245
    const [game16, setGame16] = useState({}) //245423
    const [game17, setGame17] = useState({}) //162343

    const [game18, setGame18] = useState({}) //754389
    const [game19, setGame19] = useState({}) //893872

    

    const [newPicks, setNewPicks] = useState([])

    const getAllPicks = (email) => {
        let myPicks1 = []
        db.collection('NFLplayoffs').where('email', '==', email).onSnapshot(snapshot => {
            snapshot.forEach(doc => {
                const data = doc.data()
                myPicks1.push(data)
                if(data.game === '194839'){
                    setGame1(data)
                }
                else if(data.game === '845308'){
                    setGame2(data)
                }
                else if(data.game === '924301'){
                    setGame3(data)
                }
                else if(data.game === '438293'){
                    setGame4(data)
                }
                else if(data.game === '938204'){
                    setGame5(data)
                }
                else if(data.game === '243914'){
                    setGame6(data)
                }
                else if(data.game === '745892'){
                    setGame7(data)
                }
                else if(data.game === '329238'){
                    setGame8(data)
                }
                else if(data.game === '349875'){
                    setGame9(data)
                }
                else if(data.game === '139282'){
                    setGame10(data)
                }
                else if(data.game === '948753'){
                    setGame11(data)
                }
                else if(data.game === '274589'){
                    setGame12(data)
                }
                else if(data.game === '720451'){
                    setGame13(data)
                }
                else if(data.game === '894512'){
                    setGame14(data)
                }
                else if(data.game === '734245'){
                    setGame15(data)
                }
                else if(data.game === '245423'){
                    setGame16(data)
                }
                else if(data.game === '162343'){
                    setGame17(data)
                }
                else if(data.game === '754389'){
                    setGame18(data)
                }
                else if(data.game === '893872'){
                    setGame19(data)
                }
                
            })
            setAllPicks(myPicks1)
        })
    } 
    useEffect(()=>{
        if(user && user.email && !allPicks){
            getAllPicks(user.email)
        }else {
            if(props.info && props.info.email && !allPicks){
                getAllPicks(props.info.email)
            }
        }
    },[props])


    const makePick = (game, team, email) => {
        let makePicksTime = () => {
            db.collection('NFLplayoffs').doc(`${email}-${game}`).set({
                game: game,
                team: team,
                email: email,
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                finished: false,
                win: null
            }).catch(err => console.log(err))
        }
        if(email){
            if(game === '194839' && currentTime < '2022-01-15 16:30:00'){
                makePicksTime()
            }
            else if(game === '845308' && currentTime < '2022-01-15 20:15:00'){
                makePicksTime()
            }
            else if(game === '924301' && currentTime < '2022-01-16 13:00:00'){
                makePicksTime()
            }
            else if(game === '438293' && currentTime < '2022-01-16 16:30:00'){
                makePicksTime()
            }
            else if(game === '938204' && currentTime < '2022-01-16 20:15:00'){
                makePicksTime()
            }
            else if(game === '243914' && currentTime < '2022-01-17 20:15:00'){
                makePicksTime()
            }
             else if(game === '745892' && currentTime < '2022-01-22 16:30:00'){
                makePicksTime()
            }
             else if(game === '329238' && currentTime < '2022-01-22 20:15:00'){
                makePicksTime()
            }
             else if(game === '349875' && currentTime < '2022-01-23 15:00:00'){
                makePicksTime()
            }
            else if(game === '139282' && currentTime < '2022-01-23 18:30:00'){
                makePicksTime()
            }
            else if(game === '720451' && currentTime < '2022-02-13 18:30:00'){
                makePicksTime()
            }
            else if(game === '894512' && currentTime < '2022-01-22 16:30:00'){
                makePicksTime()
            }
             else if(game === '734245' && currentTime < '2022-01-22 20:15:00'){
                makePicksTime()
            }
             else if(game === '245423' && currentTime < '2022-01-23 15:00:00'){
                makePicksTime()
            }
             else if(game === '162343' && currentTime < '2022-01-23 18:30:00'){
                makePicksTime()
            }

            else if(game === '948753' && currentTime < '2022-01-30 15:00:00'){
                makePicksTime()
            }
                else if(game === '274589' && currentTime < '2022-01-30 18:30:00'){
                makePicksTime()
            }
                else if(game === '754389' && currentTime < '2022-01-30 15:00:00'){
                makePicksTime()
            }
                else if(game === '893872' && currentTime < '2022-01-30 18:30:00'){
                makePicksTime()
            }

            else{alert('Game already started')}
        }
        else{
            let newPicks1 = newPicks
            newPicks1.map(p => {
                if(p.game === game){
                    newPicks1.pop(p)
                }
            })
            let gameObj = {
                game: game,
                team: team,
                email: null,
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                finished: false,
                win: null
            }
            newPicks1.push(gameObj)
            if(game === '194839'){
                setGame1(gameObj)
            }
            else if(game === '845308'){
                setGame2(gameObj)
            }
            else if(game === '924301'){
                setGame3(gameObj)
            }
            else if(game === '438293'){
                setGame4(gameObj)
            }
            else if(game === '938204'){
                setGame5(gameObj)
            }
            else if(game === '243914'){
                setGame6(gameObj)
            }
            alert('Sorry, try again or login')
        }
    }


    const changePick = (game, email) => {
        let changePickTime = () => {
            db.collection('NFLplayoffs').doc(`${email}-${game}`).delete().then(res => {
                if(game === '194839'){
                    setGame1({})
                }
                else if(game === '845308'){
                    setGame2({})
                }
                else if(game === '924301'){
                    setGame3({})
                }
                else if(game === '438293'){
                    setGame4({})
                }
                else if(game === '938204'){
                    setGame5({})
                }
                else if(game === '243914'){
                    setGame6({})
                }
                else if(game === '745892'){
                    setGame7({})
                }
                else if(game === '329238'){
                    setGame8({})
                }
                else if(game === '349875'){
                    setGame9({})
                }
                else if(game === '139282'){
                    setGame10({})
                }
                
                else if(game === '948753'){
                    setGame11({})
                }
                else if(game === '274589'){
                    setGame12({})
                }
                else if(game === '720451'){
                    setGame13({})
                }

                else if(game === '894512'){
                    setGame14({})
                }
                else if(game === '734245'){
                    setGame15({})
                }
                else if(game === '245423'){
                    setGame16({})
                }
                else if(game === '162343'){
                    setGame17({})
                }
                else if(game === '754389'){
                    setGame18({})
                }
                else if(game === '893872'){
                    setGame19({})
                }
            }).catch(err => console.log(err))
        }
          //Bengals @ Titans ---> 745892 Titians -3.5 Sat 3:30 47.5
            //49ers @ Packers --> 329238 Pack Sat 7:15 -4.5 47.5
            //Rams @ Bucs --> 349875 Bucs -2.5 Sun 2:00 48
            //Bills @ Chiefs --> 139282 Chiefs -2.5 Sun 5:30 54.5
        if(email){
            if(game === '194839' && currentTime < '2022-01-15 16:30:00'){
                changePickTime()
            }
            else if(game === '845308' && currentTime < '2022-01-15 20:15:00'){
                changePickTime()
            }
            else if(game === '924301' && currentTime < '2022-01-16 13:00:00'){
                changePickTime()
            }
            else if(game === '438293' && currentTime < '2022-01-16 16:30:00'){
                changePickTime()
            }
            else if(game === '938204' && currentTime < '2022-01-16 20:15:00'){
                changePickTime()
            }
            else if(game === '243914' && currentTime < '2022-01-17 20:15:00'){
                changePickTime()
            }
            else if(game === '745892' && currentTime < '2022-01-22 15:00:00'){
                changePickTime()
            }
            else if(game === '329238' && currentTime < '2022-01-22 20:15:00'){
                changePickTime()
            }
            else if(game === '349875' && currentTime < '2022-01-23 15:00:00'){
                changePickTime()
            }
            else if(game === '139282' && currentTime < '2022-01-23 18:30:00'){
                changePickTime()
            }
            else if(game === '720451' && currentTime < '2022-02-13 18:30:00'){
                changePickTime()
            }
            

            else if(game === '894512' && currentTime < '2022-01-22 15:00:00'){
                changePickTime()
            }
            else if(game === '734245' && currentTime < '2022-01-22 20:15:00'){
                changePickTime()
            }
            else if(game === '245423' && currentTime < '2022-01-23 15:00:00'){
                changePickTime()
            }
            else if(game === '162343' && currentTime < '2022-01-23 18:30:00'){
                changePickTime()
            }
            else if(game === '948753' && currentTime < '2022-01-30 15:00:00'){
                changePickTime()
            }
            else if(game === '274589' && currentTime < '2022-01-30 18:30:00'){
                changePickTime()
            }
            else if(game === '754389' && currentTime < '2022-01-30 15:00:00'){
                changePickTime()
            }
            else if(game === '893872' && currentTime < '2022-01-30 18:30:00'){
                changePickTime()
            }
            else{alert('Game already started')}
            
        }
        else{
            let newPicks1 = newPicks
            newPicks1.map(p => {
                if(p.game === game){
                    newPicks1.pop(p)
                }
            })
            if(game === '194839'){
                    setGame1({})
            }
            else if(game === '845308'){
                setGame2({})
            }
            else if(game === '924301'){
                setGame3({})
            }
            else if(game === '438293'){
                setGame4({})
            }
            else if(game === '938204'){
                setGame5({})
            }
            else if(game === '243914'){
                setGame6({})
            }
            setNewPicks(newPicks1)
            alert('Sorry, try again or login')
        }
    }

    const sendImage = (team) => {
        return `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`
    }

    const [total, setTotals] = useState({})

    const getTotals = () => {
        let today = moment().format('YYYYMMDD')
        db.collection('Extras').doc(`${today}-NFLplayoff`).get().then(doc => {
            const data = doc.data() ? doc.data() : {yep: 'yep'}
            setTotals({
                bengals: data.Bengals ? data.Bengals : null,
                raiders: data.Raiders ? data.Raiders : null,
                patriots: data.Patriots ? data.Patriots : null,
                bills: data.Bills ? data.Bills : null,
                bucs: data.Bucs ? data.Bucs : null,
                eagles: data.Eagles ? data.Eagles : null,
                fourtyniners: data['49ers'] ? data['49ers'] : null,
                cowboys: data.Cowboys ? data.Cowboys : null,
                chiefs: data.Chiefs ? data.Chiefs : null,
                steelers: data.Steelers ? data.Steelers : null,
                cardinals: data.Cardinals ? data.Cardinals : null,
                rams: data.Rams ? data.Rams : null,
            })
        })
    }
    useEffect(()=>{
        getTotals()
    },[])


    const [accountLoading, setAccountLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [picksUploaded, setPicksUploaded] = useState(false)

    const [terms, setTerms] = useState(false)

    const yesTerms = () => {
        setTerms(true)
        //document.getElementById('termsID').scrollIntoView();
        setTimeout(() => {
            //
        }, 1000);
        window.scrollTo(0,document.body.scrollHeight)
    }

    const [wildCorrect, setWildCorrect] = useState(0)
    const getWildCorrect = () => {
        let count = 0
        if(game1){
            if(game1.team === 'Bengals'){count++}
        }
        if(game2){
            if(game2.team === 'Bills'){count++}
        }
        if(game3){
            if(game3.team === 'Bucs'){count++}
        }
        if(game4){
            if(game4.team === '49ers'){count++}
        }
        if(game5){
            if(game5.team === 'Chiefs'){count++}
        }
        if(game6){
            if(game6.team === 'Rams'){count++}
        }
        if(count !== 0){
            setWildCorrect(count)
        }
    }
    useEffect(()=>{getWildCorrect()},[game1, game2, game3, game4, game5, game6])

    const [divisionalCorrect, setDivisionalCorrect] = useState(0)
    const [divisionalTotal, setDivisionalTotal] = useState(0)
    const getDivisionalCorrect = () => {
        let count = 0
        let amount = 0
        if(game7){
            if(game7.team === 'Bengals'){count++; amount++}
            else{amount++}
        }
        if(game8){
            if(game8.team === '49ers'){count++; amount++}
            else{amount++}
        }
        if(game9){
            if(game9.team === 'Rams'){count++; amount++}
            else{amount++}
        }
        if(game10){
            if(game10.team === 'Chiefs'){count++; amount++}
            else{amount++}
        }
        if(game14){
            if(game14.team === 'Under'){count++; amount++}
            else{amount++}
        }
        if(game15){
            if(game15.team === 'Under'){count++; amount++}
            else{amount++}
        }
        if(game16){
            if(game16.team === 'Over'){count++; amount++}
            else{amount++}
        }
        if(game17){
            if(game18.team === 'Over'){count++; amount++}
            else{amount++}
        }
        if(count !== 0){
            setDivisionalCorrect(count)
        }
        if(amount !== 0){
            setDivisionalTotal(amount)
        }
    }
    useEffect(()=>{getDivisionalCorrect()},[game7, game8, game9, game10, game14, game15, game16, game17])

    const [championshipCorrect, setChampionshipCorrect] = useState(0)
    const [championshipTotal, setChampionshipTotal] = useState(0)
    const getChampionship = () => {
        let count = 0
        let amount = 0
        if(game11){
            if(game11.team === 'Bengals'){count++; amount++}
            else{amount++}
        }
        if(game12){
            if(game12.team === '49ers'){count++; amount++}
            else{amount++}
        }
        if(game18){
            if(game18.team === 'Under'){count++; amount++}
            else{amount++}
        }
        if(game19){
            if(game19.team === 'Under'){count++; amount++}
            else{amount++}
        }
        if(count !== 0){
            setChampionshipCorrect(count)
        }
        if(amount !== 0){
            setChampionshipTotal(amount)
        }
    }
    useEffect(()=>{getChampionship()},[game11, game12, game18, game19])

    const [showWildCard, setShowWildCard] = useState(false)
    const [showDivisional, setShowDivisional] = useState(false)
    const [showChampionship, setShowChampionship] = useState(false)
    const [showSuperBowl, setShowSuperBowl] = useState(false)


    return(
        <div style={{backgroundColor: 'rgb(248,248,248)', paddingTop: '10px', paddingBottom: '60px'}}>

            <div style={{textAlign: 'center'}}><img src={banner} className="banner-img desktop" /><img src={bannerMobile} className="banner-img mobile" /></div>
            

            {/*<div className="blk-back">
                <div style={{textAlign: 'center', fontSize: '3em', fontWeight: '600', color: '#c5b358'}}>SimBull 13</div>
                <div style={{textAlign: 'center', fontSize: '.9em', fontWeight: '400', color: 'white', marginBottom: '5px'}}>Football Playoff Challenge</div>
                <div style={{textAlign: 'center', fontSize: '.8em', fontWeight: '400', color: 'white', marginBottom: '5px', color: 'gray'}}> <span style={{fontSize: '.7em', fontWeight: '200', marginBottom: '5px', color: 'white'}}>Presented By:</span><br /> <span style={{fontSize: '1em', color: '#c5b358'}}>SimBull.com</span> <br />The Stock Market for Sports</div>
                <div style={{textAlign: 'center'}}><img src={logo} className="logoImg" /></div>
    </div>*/}
            {/*<div className="playoff-title">🏈 SimBull 13 NFL Playoff Challenge 💵 </div>
            <div className="playoff-subtitle">Win a share of all 189 teams on SimBull <span style={{fontWeight: '300'}}>(up to $7000 in value)</span>
                { ' ' }or { ' ' }
                <span style={{fontWeight: '300'}}>$5000 in cash!</span>
            </div>
            <div className="playoff-desc">Pick all thirteen playoff games correct, win one share of all 189 teams. Plus, for each game you get correct, spin for the chance to win up to 1 share!</div>
            */}

            {/*user.signedUp === moment().format('YYYYMMDD') && (game1.team || game2.team || game3.team || game4.team || game5.team || game6.team) && <div className="login-div">
                <div style={{textAlign: 'center', fontWeight: '300', marginBottom: '10px'}}> Picks Submitted! Login into SimBull next week to continue on your perfect picks journey. </div>
                <div style={{textAlign: 'center', fontWeight: '300', marginBottom: '10px'}}> Visit <Link to="/Welcome">SimBull.com</Link> - The Stock Market for Sports Today.</div>
        </div>*/}

            <div className="game-div" style={{textAlign: 'center', fontWeight: '300'}}>
                Your Super Bowl Round: <strong>{(game13 && game13.team === "Bengals") ? '1' : '0'} out of 1</strong>
                <br />
                <br />
                Your Championship Round: <strong>{championshipCorrect} out of {championshipTotal}</strong>
                <br />
                <br />
                Your Divisional Round: <strong>{divisionalCorrect} out of {divisionalTotal}</strong>
                <br />
                <br />
                Your Wildcard Weekend: <strong>{wildCorrect} out of 6</strong>
            </div>

            {/* Super Bow Game 
                //Rams @ Bengals --> 720451 Rams -4.5 Sun 5:30:00 
            */}

            {showSuperBowl && <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, February 13th | 5:00pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        Rams
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-4.5 </span>
                        <br />
                        <img src={sendImage('Rams')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Bengals
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+4.5</span>
                        <br />
                        <img src={sendImage('Bengals')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game13 || !game13.team ? <div>
                                <span style={{fontWeight: '300', marginBottom: '5px'}}>Select Winner:</span><br />
                                <button onClick={()=>makePick('720451', 'Rams', getUserEmail())} style={{border: '1px solid blue'}} className="team-btn">Rams -4.5</button><br />
                                <button onClick={()=>makePick('720451', 'Bengals', getUserEmail())} style={{border: '1px solid orange'}} className="team-btn">Bengals +4.5</button>
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game13.team === 'Rams' ? 'blue' : 'orange'}`}}>{game13.team}</span><br />
                                {currentTime < '2022-02-13 18:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('720451', getUserEmail())}>Change Pick</button>}
                            </div>
                            }
                    </div>

                    
                </div>
            </div>
            }

            {!showSuperBowl && <div style={{textAlign: 'center', marginTop: '15px'}}><Link onClick={()=>setShowSuperBowl(true)}>Show Super Bowl</Link></div>}


            {/* Championship Games 
                //Bengals @ Chiefs --> 948753 Chiefs -6.5 Sun 2:00 54.5 754389
                //49ers @ Rams --> 274589 Rams -3.5 Sun 5:30 46.5 893872
            */}

            {showChampionship && <>
            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 30th | 2:00pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        Bengals
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+6.5 </span>
                        <br />
                        <img src={sendImage('Bengals')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Chiefs
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-6.5</span>
                        <br />
                        <img src={sendImage('Chiefs')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game11 || !game11.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game11.team === 'Chiefs' ? 'red' : 'orange'}`}}>{game11.team}</span><br />
                                {currentTime < '2022-01-30 15:00:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('948753')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game18 || !game18.team ? <div>
\
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game18.team === 'Over' ? 'black' : 'gray'}`}}>{game18.team} 54.5</span><br />
                                {currentTime < '2022-01-30 15:00:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('754389')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>

            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 30th | 5:30pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        49ers
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+3.5 </span>
                        <br />
                        <img src={sendImage('49ers')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Rams
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-3.5</span>
                        <br />
                        <img src={sendImage('Rams')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game12 || !game12.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game12.team === '49ers' ? 'red' : 'blue'}`}}>{game12.team}</span><br />
                                {currentTime < '2022-01-30 18:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('274589')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game19 || !game19.team ? <div>


                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game19.team === 'Over' ? 'black' : 'gray'}`}}>{game19.team} 46.5</span><br />
                                {currentTime < '2022-01-30 18:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('893872')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>

            {!showChampionship && <div style={{textAlign: 'center', marginTop: '15px'}}><Link onClick={()=>setShowChampionship(true)}>Show Championship Games</Link></div>}
            </>}
            {/* End Championship Games */}

            {/* Divisional Games */}

            {!showDivisional && <div style={{textAlign: 'center', marginTop: '15px'}}><Link onClick={()=>setShowDivisional(true)}>Show Divisional Games</Link></div>}

            {showDivisional && <>
            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Saturday, January 22nd | 3:30pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        Bengals
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+3.5 </span>
                        <br />
                        <img src={sendImage('Bengals')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Titans
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-3.5</span>
                        <br />
                        <img src={sendImage('Titans')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game7 || !game7.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game7.team === 'Titans' ? 'blue' : 'orange'}`}}>{game7.team}</span><br />
                                {currentTime < '2022-01-22 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('745892')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    {wildCorrect !== 6 && <div style={{fontWeight: '400'}}>
                        {!game14 || !game14.team ? <div>
                            
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game14.team === 'Over' ? 'black' : 'gray'}`}}>{game14.team} 47.5</span><br />
                                {currentTime < '2022-01-22 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('894512')}>Change Pick</button>}
                            </div>
                            }
                    </div>}
                    
                </div>
            </div>

            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Saturday, January 22nd | 7:15pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        49ers
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+5.5 </span>
                        <br />
                        <img src={sendImage('49ers')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Packers
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-5.5</span>
                        <br />
                        <img src={sendImage('Packers')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game8 || !game8.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game8.team === 'Packers' ? 'green' : 'red'}`}}>{game8.team}</span><br />
                                {currentTime < '2022-01-22 20:15:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('329238')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    {wildCorrect !== 6 && <div style={{fontWeight: '400'}}>
                        {!game15 || !game15.team ? <div>
                                
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game15.team === 'Over' ? 'black' : 'gray'}`}}>{game15.team} 47.5</span><br />
                                {currentTime < '2022-01-22 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('734245')}>Change Pick</button>}
                            </div>
                            }
                    </div>}
                    
                </div>
            </div>
            
            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 23rd | 2:00pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        Rams
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+3.5 </span>
                        <br />
                        <img src={sendImage('Rams')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Bucs
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-3.5</span>
                        <br />
                        <img src={sendImage('Bucs')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game9 || !game9.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game9.team === 'Bucs' ? 'red' : 'blue'}`}}>{game9.team}</span><br />
                                {currentTime < '2022-01-23 15:00:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('349875')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    {wildCorrect !== 6 && <div style={{fontWeight: '400'}}>
                        {!game16 || !game16.team ? <div>
                                
                                
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game16.team === 'Over' ? 'black' : 'gray'}`}}>{game16.team} 48.5</span><br />
                                {currentTime < '2022-01-23 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('245423')}>Change Pick</button>}
                            </div>
                            }
                    </div>}
                    
                </div>
            </div>

            <div className="game-div">

                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 23rd | 5:30pm CT</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team">
                        Bills
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+2.5 </span>
                        <br />
                        <img src={sendImage('Bills')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>*/}
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team">
                        Chiefs
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-2.5</span>
                        <br />
                        <img src={sendImage('Chiefs')} className="jersey-img" /><br />
                        {/*<span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>*/}
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game10 || !game10.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game9.team === 'Bills' ? 'blue' : 'red'}`}}>{game10.team}</span><br />
                                {currentTime < '2022-01-23 18:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('139282')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    {wildCorrect !== 6 && <div style={{fontWeight: '400'}}>
                        {!game17 || !game17.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>


                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `black`, border: `1px solid ${game17.team === 'Over' ? 'black' : 'gray'}`}}>{game17.team} 55.5</span><br />
                                {currentTime < '2022-01-23 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('162343')}>Change Pick</button>}
                            </div>
                            }
                    </div>}
                    
                </div>
            </div>
            </>}
            {/* End Divisional Games */}

            {!showWildCard && <div style={{textAlign: 'center', marginTop: '15px'}}><Link onClick={()=>setShowWildCard(true)}>Show Wild Card Games</Link></div>}
            {showWildCard && <>
            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Saturday, January 15th | 3:30pm CT (NBC)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team" style={{borderBottom: '1px solid red'}}>
                        Raiders
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+6 </span>
                        <br />
                        <img src={sendImage('Raiders')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.raiders && `${total.raiders} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team" style={{borderBottom: '1px solid green'}}>
                        Bengals
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-6</span>
                        <br />
                        <img src={sendImage('Bengals')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bengals && `${total.bengals} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game1 || !game1.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px',textDecoration: `${game1.team ? 'none' : (game1.team === 'Raiders' ? 'line-through' : 'none')}`, borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', color: `${game1.team === 'Bengals' ? 'green' : 'black'}`, border: `1px solid ${game1.team === 'Raiders' ? 'black' : 'orange'}`}}>{game1.team}</span><br />
                                {currentTime < '2022-01-15 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('194839')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>

            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Saturday, January 15th | 7:15pm CT (CBS)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team"  style={{borderBottom: '1px solid red'}}>
                        Patriots
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+4.5 </span>
                        <br />
                        <img src={sendImage('Patriots')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.patriots && `${total.patriots} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team"  style={{borderBottom: '1px solid green'}}>
                        Bills
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-4.5</span>
                        <br />
                        <img src={sendImage('Bills')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bills && `${total.bills} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game2 || !game2.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', border: `1px solid ${game2.team === 'Bills' ? 'blue' : 'silver'}`}}>{game2.team}</span><br />
                                {currentTime < '2022-01-15 20:15:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('845308')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>

            
            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 16th | 12:00pm CT (Fox)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team"  style={{borderBottom: '1px solid red'}}>
                        Eagles
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+7 </span>
                        <br />
                        <img src={sendImage('Eagles')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.eagles && `${total.eagles} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team"  style={{borderBottom: '1px solid green'}}>
                        Bucs
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-7</span>
                        <br />
                        <img src={sendImage('Bucs')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.bucs && `${total.bucs} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game3 || !game3.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', border: `1px solid ${game3.team === 'Bucs' ? 'red' : 'green'}`}}>{game3.team}</span><br />
                                {currentTime < '2022-01-16 13:00:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('924301')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>


            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 16th | 3:30pm CT (CBS)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team"   style={{borderBottom: '1px solid green'}}>
                        49ers
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+3 </span>
                        <br />
                        <img src={sendImage('49ers')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.fourtyniners && `${total.fourtyniners} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team"  style={{borderBottom: '1px solid red'}}>
                        Cowboys
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-3</span>
                        <br />
                        <img src={sendImage('Cowboys')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.cowboys && `${total.cowboys} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game4 || !game4.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', border: `1px solid ${game4.team === 'Cowboys' ? 'blue' : 'red'}`}}>{game4.team}</span><br />
                                {currentTime < '2022-01-16 16:30:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('438293')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>


            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Sunday, January 16th | 7:15pm CT (NBC)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team"  style={{borderBottom: '1px solid red'}}>
                        Steelers
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+13 </span>
                        <br />
                        <img src={sendImage('Steelers')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.steelers && `${total.steelers} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team"  style={{borderBottom: '1px solid green'}}>
                        Chiefs
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-13</span>
                        <br />
                        <img src={sendImage('Chiefs')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.chiefs && `${total.chiefs} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game5 || !game5.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', border: `1px solid ${game5.team === 'Chiefs' ? 'red' : 'gold'}`}}>{game5.team}</span><br />
                                {currentTime < '2022-01-16 20:15:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('938204')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>


            <div className="game-div">
                <div style={{textAlign: 'center', fontWeight: '200', marginBottom: '10px', marginTop: '5px'}}>Monday, January 17th | 7:15pm CT (ESPN)</div>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <div className="left-team"  style={{borderBottom: '1px solid red'}}>
                        Cardinals
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>+4 </span>
                        <br />
                        <img src={sendImage('Cardinals')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.cardinals && `${total.cardinals} picks`}</span>
                    </div>

                    <div className="middle-team">
                        @
                    </div>
                    
                    <div className="right-team"  style={{borderBottom: '1px solid green'}}>
                        Rams
                        <span style={{marginLeft: '5px', fontWeight: '400'}}>-4</span>
                        <br />
                        <img src={sendImage('Rams')} className="jersey-img" /><br />
                        <span style={{fontSize: '.8em', fontWeight: '200'}}>{total&& total.rams && `${total.rams} picks`}</span>
                    </div>
                    <div style={{fontWeight: '400'}}>
                        {!game6 || !game6.team ? <div style={{textAlign: 'center', marginTop: '5px', color: 'gray'}}>
                                You didn't Pick
                            </div> : 
                            <div style={{fontSize: '.9em', fontWeight: '300'}}>
                                Your Pick: 
                                <br />
                                <span style={{fontSize: '1.5em', fontWeight: '500', padding: '2px 10px 2px 10px', borderRadius: '.6em', boxShadow: '0 0 2.5px 1px rgb(200, 200, 200)', border: `1px solid ${game6.team === 'Rams' ? 'blue' : 'red'}`}}>{game6.team}</span><br />
                                {currentTime < '2022-01-17 20:15:00' && <button style={{border: 'none', backgroundColor: 'white', outline: 'none', textDecoration: 'underline', fontWeight: '200', fontSize: '.8em'}} onClick={()=>changePick('243914')}>Change Pick</button>}
                            </div>
                            }
                    </div>
                    
                </div>
            </div>
            </>}


            <Spinner />

            <div className="share-social">Tag us on social media to earn an extra spin for the chance to win up to one full share!</div>
            <button className="share-btn">
                    <FacebookShareButton
                        url={"www.simbull.com/Picks"}
                        quote={`SimBull.com is running a playoff challenge! If you pick the playoff games right you can win one share of each team or $5000 cash!`}
                        hashtag="#SimBull"
                    >
                        Share on Facebook
                    </FacebookShareButton>
                </button>
                <button className="share-btn">
                    <TwitterShareButton
                        url={"www.simbull.com/Picks"}
                        title={`SimBull.com is running a playoff challenge! If you pick the playoff games right you can win one share of each team or $5000 cash!`}
                    >
                        Share on Twitter
                    </TwitterShareButton>
                </button>

            

            {!terms && <div style={{fontWeight: '200', fontSize: '.7em', textAlign: 'center', marginTop: '7px'}}><Link style={{color: 'gray'}} onClick={yesTerms}k>Terms and Conditions</Link></div>}

            <div style={{textAlign: 'center', fontWeight: '300', marginTop: '20px', marginBottom: '20px'}}>Visit your <Link to="/Portfolio">Portfolio</Link> Today to <Link to="/Trade/Teams">Trade Teams like Stocks</Link> at SimBull's Stock Market for Sports.</div>

            <div id="termsID" style={{marginTop: '50px', fontSize: '.6em', fontWeight: '300', paddingBottom: '50px'}}>
                    {terms && <>TERMS AND CONDITIONS (includes the 'Rules' below): <br />
                    All participants (herein “participant(s)” or “entrant(s)”) agree to abide by these terms and conditions (including the “RULES”) and any legal disclaimers below, and by the decisions of SimBull Sports Exchange, Inc, (“SimBull”)  an official corporation incorporated under the laws of Delaware. 
                    <br />These terms are final and binding on all matters pertaining to the “SimBull 13 NFL Playoff Challenge” (herein “Challenge”). Any and all legal disputes arising from the Challenge will be resolved in Delaware courts under the laws of the state chosen by SimBull. 
                    <br />Must be 18 years or older to enter. Entries by individuals under the age of 18 years old will be automatically void. Entrants must be able to prove their age if SimBull requests verification. 
                    <br />This Challenge is in no way affiliated with the National Football League, Tik Tok, Facebook, Instagram, Twitter, or any other business. 
                    <br />Except where prohibited by law, a Winner's acceptance of a Prize constitutes permission for the SimBull to use Winner's name, photograph, likeness, statements, biographical information, voice, and address (city and state) worldwide and in all forms of media, in perpetuity, without further compensation. Entrants agree to SimBull’s use of their names and likeness for advertising and publicity purposes without additional compensation, unless prohibited by law. All federal, state, local laws and regulations apply.
                    <br />Any and all taxes, if any, in relation to acceptance of a Prize are the sole responsibility of each Winner. No substitution or transfer of a Prize is permitted except by CBSI. CBSI reserves the right to substitute a Prize for one of equal or greater value.
                    <br />RULES:
                    <br />1. Maximum of one entry/submission per person (this means a person may only pick each NFL playoff game once). Multiple submissions by the same person makes their entry automatically void. Creating more than one email address by the same person with the purpose of submitting multiple entries is a violation of state and federal law and is prohibited by the Rules of this Challenge
                    <br />2. NFL game picks must occur prior to the start of each respective game to be valid. 
                    <br />3. For every game picked correctly, participants will win a chance to “spin the wheel” which gives them the opportunity to receive free shares of random teams in the SimBull Market. All correct games chosen will result in wheel spins to win parts or a whole share with the following odds: 1% chance at 1 share, 9% chance at .1 share, and 90% chance at 0.01 shares.
                    <br />4. To win the “Grand Prize” of the Challenge, a participant must correctly pick all thirteen (13) NFL Playoff games against the spread. If a pick made against the spread ties the outcome of a game, that pick will count as a loss. There will be one “Grand Prize” winner of the Challenge, unless more than one participant correctly picks the thirteen games (a “tie”). If a tie occurs, the “Grand Prize” will be split equally among the winners. 
                    <br />5. The “Grand Prize” is one (1) share of each 189 teams in the SimBull Market (roughly $7,000 in value) OR $5,000 in “SimBull Cash”. The winner shall choose between the aforementioned two prize options. 
                    <br />6. “SimBull Cash” is money that will be deposited into a participant’s SimBull account which can be used to buy and sell teams in the SimBull Market at the participants discretion. 
                    <br />7. Grand Prize winnings (either the 189 shares or the $5,000 in SimBull Cash) MUST be held in the participant’s SimBull account for at least 245 days before a participant has the opportunity to withdraw the money into their personal bank account. 
                    <br />8. Participants must provide an email address and will be notified of their winnings by February 14th, 2022 through email. All participants grant SimBull access to contact them through email. 
                    <br />9. SimBull reserves the right to modify, suspend, cancel or terminate in the event that non-authorized human intervention, a bug or virus, fraud, or other causes beyond reasonable control impact or corrupt the security, fairness, proper conduct, or administration. 
                    <br />10. Participants may be removed at any time from the Challenge at the discretion of SimBull. 
                    <br />Any dispute or questions can be made to customer-service@simbull.app.


         </>           
}
            </div>

        </div>
    )
}

    

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    info: state.info.info,
});

export default connect(mapStateToProps)(PlayoffPicks)