import React, {useState, useEffect} from 'react'
import {db, firebaseAnalytics} from '../../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import '../portfolio2.css'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import {getMyTrades} from '../../../routes/user/user'

const Trades = (props) => {
    //get props
    const user = props.user
    
    const [trades, setTrades] = useState([])
    const [user_id, setUser_id] = useState(0)

    useEffect(()=>{
        if(user_id === 0 && props.token){
            let user_id1 = jwt_decode(props.token).sub
            setUser_id(user_id1)
        }
    },[props.token])

    useEffect(()=>{
        if(props.location && props.location.state && props.location.state.trades && trades.length === 0){
            setTrades(props.location.state.trades)
        }
        else if(user_id !== 0 && trades.length === 0){
            getMyTrades(user_id).then(res => {
                res.sort((a,b)=>{
                    if(b.timestamp - a.timestamp > 0){
                        return -1
                    }else return 1
                })
                setTrades(res)
            })
        }
    },[user_id, props.location])

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const getTeamName = (id) => {
        let team = ''
        if(props.teamsValue){
            props.teamsValue.map(t => {
                if(t.team.id === id){
                    team = t.team.name
                }
            })
        }
        return team
    }

    return(
        <div className="DesktopContainer" style={{ margin: '0', marginTop: '7vh', paddingBottom: '10vh'}}>
            <div style={{fontSize: '1.6em', fontWeight: '500', width: '100%'}}><div style={{display: 'inline-block', width: '73%', paddingLeft: '5px'}}>Trades</div> <div style={{width: '20%',textAlign: 'right', display: 'inline-block', paddingLeft: '5px'}}>{/*<span className="fa fa-ellipsis-v"></span>*/}</div></div>
            <hr style={{marginTop: '3px', paddingTop: '0', marginLeft: '3%', marginLeft: '3%'}} />
            {trades && trades.length > 0 && trades.map( t => {
                return(
                    <div style={{marginLeft: '3%', marginRight: '3%'}}>
                        <div style={{display: 'inline-block', width: '70%', paddingLeft: '2%', fontSize: '1.3em', verticalAlign: 'top'}}>
                            <div style={{fontSize: '1.1em'}}><span style={{fontSize: '0.9em', color: t.seller_id === user_id ? 'darkred' : 'darkblue'}}>{t.seller_id === user_id ? 'SOLD: ' : 'ADDED: '}</span>
                            Sim{getTeamName(t.asset_id)} </div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{`${t.timestamp.substring(5, 7)}/${t.timestamp.substring(8, 10)}/${t.timestamp.substring(0, 4)}`}</div>
                        </div>
                        <div style={{display: 'inline-block', width: '30%', textAlign: 'right', paddingRight: '3%', verticalAlign: 'bottom'}}>
                            <div style={{fontSize: '1.1em', color: 'black'}}>${(t.price).toFixed(2)}</div>
                            <div style={{fontSize: '0.8em', color: 'gray'}}>{t.amount.toFixed(4)} Shares</div>
                        </div>
                        <hr />

                        
                        
                    </div>
                )
            })}

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    token: state.token.token,
    teamsValue: state.teamsValue.teamsValue
});

export default connect(mapStateToProps)(Trades)