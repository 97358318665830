import React, {useState, useEffect} from 'react'
import '../portfolio2.css'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../../services/firebase'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {getMyOffers} from '../../../routes/user/user'

const MyOffers = (props) => {

    let refresh = props.refresh ? true : false

    const user = props.user
    const [teams, setTeams] = useState([])
    useEffect(()=>{
        setTeams(props.teamsValue)
    },[props.teamsValue])

    const thisTeam = props.t

    const [offers, setOffers] = useState([])
    const [asks, setAsks] = useState([])
    const [bids, setBids] = useState([])
    const [deletedOffers, setDeletedOffers] = useState([])
    const [hasBids, setHasBids] = useState(0)
    const [hasAsks, setHasAsks] = useState(0)

    const [edit, setEdit] = useState(false)

    const getOffers = (res) => {
            let arr = res
            let arrCount = res.length
            let hasBids1 = 0
            let hasAsks1 = 0
            let bids1 = []
            let asks1 = []
            res.map(o => {
                if(o.asset_id && props.t.team && (o.asset_id === props.t.team.id)){
                    arr.push(o)
                    if(o.type === "BID" && !hasBids){
                        hasBids1++
                    }else if(o.type === "ASK" && !hasAsks){
                        hasAsks1++
                    }
                }
            })
            if(arr.length > arrCount){
                arr = arr.slice(arrCount, arr.length)
            }
            arr.map(a => {
                if(a.type === 'BID'){
                    bids1.push(a)
                }else if(a.type === "ASK"){
                    asks1.push(a)
                }
            })
            bids1.sort((a,b) => {
                return b.price - a.price
            })
            asks1.sort((a,b) => {
                return a.price - b.price
            })
            setBids(bids1)
            setAsks(asks1)
            setOffers(arr)
            setHasBids(hasBids1)
            setHasAsks(hasAsks1)
    }

    useEffect(()=>{
        if(props.offers && props.t){
            getOffers(props.offers)
        }else if(offers.length === 0 && props.token && props.t && props.t.team && props.t.team.id){
            getMyOffers(props.token).then(res => {
                getOffers(res)
            })
        }
        else return false
    },[ props.offers, props.t, props.token])

    let getTeamInfo = (id) => {
        let team
        teams && teams.map(t => {
            if(t.team.id === id){
                team = t
            }
        })
        return team
    }

    //Excel Offer Testers
    let testers = ['tsteu35@gmail.com', 'dallasjklein@gmail.com','bigb19935@aol.com', 'dlong178@gmail.com', 'jsmead88@gmail.com', 'mchim00@yahoo.com', 'penguinn101@yahoo.com', 'rsbrooks@cinci.rr.com', 'verbleke@gmail.com', 'jcsalow@gmail.com']

    return(
        <>
        {/*offers && offers.length === 0 && <div style={{marginTop: '15px', marginLeft: '5px', paddingRight: '3%', paddingLeft: '3%', fontWeight: '300', color: 'rgb(50,50,50)'}}>
                <div  style={{fontSize: '1.5em', fontWeight: '500', display: 'inline-block', width:'60%'}}>No Offers {/*testers.includes(user.email) && <span style={{fontSize: '.8em', fontWeight: '300', color: 'gray'}}>| <Link style={{color: 'gray'}} to="/ExcelOffers">Excel <i className="fas fa-arrow-right" style={{fontSize: '.85em'}}></i></Link></span>*//*}</div>
                </div>
        */}

        {(hasBids > 0 || hasAsks > 0) && <div style={{marginTop: '15px', marginLeft: '5px', paddingRight: '3%', fontWeight: '300', color: 'white'}}>
                <div  style={{fontSize: '1.6em', fontWeight: '500', display: 'inline-block', width:'60%'}}>Offers {/*testers.includes(user.email) && <span style={{fontSize: '.8em', fontWeight: '300', color: 'gray'}}>| <Link style={{color: 'gray'}} to="/ExcelOffers">Excel <i className="fas fa-arrow-right" style={{fontSize: '.85em'}}></i></Link></span>*/}</div>
                <div  style={{textAlign: 'right', fontSize: '1em', fontWeight: '300', display: 'inline-block', width:'40%', color: 'white'}}>{/*<button style={{border: 'none', outline: 'none', backgroundColor: 'white', textDecoration: 'underline', color: 'gray'}} onClick={()=>setEdit(!edit)}>{edit ? 'close': 'edit'}</button>*/}</div>
                <div className="portfolioDiv" style={{marginLeft: '0px'}}>


                    {/*offersCount === (offers.length - deletedOffers.length) && offers && offers.length > 0 && offers.map(o => {
                        if(deletedOffers.indexOf(o) === -1){
                            return(
                            <div className="portfolioBox">
                                <Link to={`/Teams/${o.team ? o.team : o.bidTeam}`} style={{color: 'black'}}>
                                    <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.date.substring(4, 6)}/${o.date.substring(6, 8)}/${o.date.substring(0, 4)}`}</div>
                                    <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{o.team ? o.team : o.bidTeam}</div>
                                    <div style={{fontSize: '1.15em', color: 'gray'}}>
                                        {o.bidTeam ? 'Bid: ' : 'Ask: '}{' '}
                                        ${o.askPrice ? o.askPrice.toFixed(2) : o.bidPrice.toFixed(2)}
                                    </div>
                                </Link>
                                {edit && <div style={{textAlign: 'center'}}>
                                    <button onClick={()=>{deleteOneOffer(o)}} style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                                </div>}
                            </div>
                            
                            )
                        }
                        
                    })*/}

                    {teams && teams.length > 0 && bids && bids.length > 0 && bids.map(o => {
                        let t = getTeamInfo(o.asset_id)
                        if(t.team && thisTeam.team && t.team.id && thisTeam.team.id && (t.team.id === thisTeam.team.id)){
                            return(
                                <div key={o.id} className="portfolioBox">
                                    <Link to={`/Teams/${t && t.team && t.team.name}`} style={{color: 'white'}}>
                                        <div style={{color: 'white', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.timestamp.substring(5, 7)}/${o.timestamp.substring(8, 10)}/${o.timestamp.substring(0, 4)}`}</div>
                                        <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{t && t.team && t.team.name}</div>
                                        <div style={{fontSize: '1.15em', color: 'white'}}>
                                            {o.type === "BID" ? 'Bid: ' : 'Ask: '}{' '}<br />
                                            {o.remaining_amount.toFixed(2)} x {''}
                                            ${o.price.toFixed(2)}
                                        </div>
                                    </Link>
                                    {edit && <div style={{textAlign: 'center'}}>
                                        <button style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'black'}}>Delete</button>
                                    </div>}
                                </div>
                            )
                        }
                        
                    })}
                    

                    {(hasBids > 4) && <div className="portfolioBox">
                        <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px', color: 'white'}}>-></div>
                        <Link to={`/AllOffers/${props.t && props.t.team.name}`}><div style={{color: 'black',fontSize: '1.3em', fontWeight: '300', marginBottom: '3px', textDecoration: 'underline'}}>View All</div></Link>
                        <div style={{fontSize: '1.15em', color: 'white'}}>-></div>
                        <div style={{fontSize: '1.15em', color: 'white'}}>-></div>
                    </div>}

                    {hasBids > 3 && hasAsks !==0 && <><br /></>}
                    {hasAsks > 3 && hasBids <= 3 && hasBids !== 0 && <><br /></>}

                    {teams && teams.length > 0 && asks && asks.length > 0 && asks.map(o => {
                        let t = getTeamInfo(o.asset_id)
                        if(t.team && thisTeam.team && t.team.id && thisTeam.team.id && (t.team.id === thisTeam.team.id)){
                            return(
                                <div key={o.id} className="portfolioBox" style={(hasAsks > 3 || hasBids > 3) ? {marginTop: '5px'} : {}}>
                                    <Link to={`/Teams/${t && t.team && t.team.name}`} style={{color: 'black'}}>
                                        <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px'}}>{`${o.timestamp.substring(5, 7)}/${o.timestamp.substring(8, 10)}/${o.timestamp.substring(0, 4)}`}</div>
                                        <div style={{fontSize: '1.3em', fontWeight: '500', marginBottom: '3px'}}>Sim{t && t.team && t.team.name}</div>
                                        <div style={{fontSize: '1.15em', color: 'gray'}}>
                                            {o.type === "BID" ? 'Bid: ' : 'Ask: '}{' '}<br />
                                            {o.remaining_amount.toFixed(2)} x {''}
                                            ${o.price.toFixed(2)}
                                        </div>
                                    </Link>
                                    {edit && <div style={{textAlign: 'center'}}>
                                        <button style={{color: 'darkred', outline: 'none', borderRadius: '.7em', border: '1px solid darkred', backgroundColor: 'white'}}>Delete</button>
                                    </div>}
                                </div>
                            )
                        }
                        
                    })}

                    {hasAsks > 4 && hasBids < 4 && <div className="portfolioBox" style={(hasAsks > 3 || hasBids > 3) ? {marginTop: '5px'} : {}}>
                        <div style={{color: 'gray', fontSize: '0.8em', marginBottom: '7px', color: 'white'}}>-></div>
                        <Link to={`/AllOffers/${props.t && props.t.team.name}`}><div style={{color: 'black',fontSize: '1.3em', fontWeight: '300', marginBottom: '3px', textDecoration: 'underline'}}>View All</div></Link>
                        <div style={{fontSize: '1.15em', color: 'white'}}>-{`>`}</div>
                        <div style={{fontSize: '1.15em', color: 'white'}}>-{`>`}</div>
                    </div>}

                </div>
                
        </div>}
        </>
    )

}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token
});

export default connect(mapStateToProps)(MyOffers)
