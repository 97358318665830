import React from 'react'
import './TeammateItem.css'
import Trade from './Items/Trade'
import Payout from './Items/Payout'
import Move from './Items/Move'

const TeammateItem = (props) => {

    const item = props.i

    return(
        <>
        {
        (item.type && item.type === 'BUY' || item.type === 'SELL') ?
            <div className="TeammateItem" >
                <Trade publicAccounts={props.publicAccounts} teammateID={props.teammateID} username={props.username} token={props.token} feed={props.feed} item={item} teammates={props.teammates} teamsValue={props.teamsValue} changeTeammate={props.changeTeammate} />
            </div>
            :
            (item.type && item.type === 'PAYOUT') ? 
            <div className="TeammateItem">
                <Payout item={item} feed={props.feed} teammates={props.teammates} teamsValue={props.teamsValue} changeTeammate={props.changeTeammate} />
            </div>
            : 
            (item.type && item.type === 'MOVE') ? 
            <div className="TeammateItem">
                <Move item={item} feed={props.feed} teammates={props.teammates} changeTeammate={props.changeTeammate} />
            </div>
            : <></>
        }
        </>
    )
}

export default TeammateItem