import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import QuestionCard from './QuestionCard'
import {faqs} from './faqList'

const Learn = (props) => {
    
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <div style={{backgroundColor: 'black', width: '100%', paddingLeft: isMobile ? '4px':'48px', paddingRight: isMobile ? '4px':'48px'}}>
            <div style={{color: 'white', fontSize: '1.4rem'}}>SimBull Learn</div>
            {faqs.map(f => {
                if(props.home){
                    let index = faqs.indexOf(f)
                    if(index < 4){
                        return <QuestionCard item={f} />
                    }
                }
                else{
                    return <QuestionCard item={f} />
                }
                
            })}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    totals: state.totals.totals,
    token: state.token.token
});

export default connect(mapStateToProps)(Learn)