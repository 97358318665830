import React, {useState, useEffect} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import {Helmet} from 'react-helmet'
import { connect, useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom'
import Graph from './Graph'
import Value from './Value'
import Notifications from './Notifications'
import Teams from './Teams'
import History from './History'
import BuyingPower from './BuyingPower'
import MyOffers from './MyOffers'
import '../Payouts3/Payouts.css'
import './portfolio2.css'
import {Link} from 'react-router-dom'
import New from './New'
import Watchlist from './WatchlistDesktop/Watchlist'
import Learn from './LearnDesktop/Learn'
import Bits from './BitsDesktop/Bits'

import {getTotals, getUncollectedPayouts, getMyOffers, getPayouts} from '../../routes/user/user'
import {getUserEquity} from '../../redux/actions/user/equity'
import {getAccountInfo, updateAccountInfo} from '../../routes/user/account'
import {signup, loginWithEmail, loginWithUsername} from '../../routes/auth/auth'
import {deleteOffer} from '../../routes/trading/trading'
import {getSpins} from '../../routes/spins/spins'
import Payouts from './Payouts/Payouts'
import RewardWidget from './Redeem/Widget'
import logo from '../assets/Logo1.png'


const Portfolio2 = (props) => {
    
    const [token, setToken] = useState(props.token)
    const [teams, setTeams] = useState(props.teamsValue)
    const [totals, setTotals] = useState(props.totals)
    const [userPrices, setUserPrices] = useState(props.userPrices)
    const [equity, setEquity] = useState(props.equity)
    const [spins, setSpins] = useState([])
    useEffect(()=>{
        if(!token && props.token){
            setToken(props.token)
        }
        if(!teams && props.teamsValue){
            setTeams(props.teamsValue)
        }
        if(!totals && props.totals){
            setTotals(props.totals)
        }
        if(!userPrices && props.userPrices){
            setUserPrices(props.userPrices)
        }
        if(props.equity && !equity){
            setEquity(props.equity)
        }
    
    },[props])

    const dispatch = useDispatch()
    useEffect(async ()=>{
        if(props.location && props.location.state && props.location.state.refresh){
            dispatch(getUserEquity(props.token))
        }
    },[])
    useEffect(()=>{
        if(props.equity !== equity){
            setEquity(props.equity)
        }
    },[props.equity])

    const [hasEquity, setHasEquity] = useState(false)

    useEffect(()=>{
        if(props.equity && props.equity.length > 0){
            props.equity.map(e => {
                if(e.amount > 0){
                    setHasEquity(true)
                }
            })
        }
    },[props.equity])

    

    //const [totals, setTotals] = useState({})
    const [uncollected, setUncollected] = useState([])
    //const [equity, setEquity] = useState([])
    const [offers, setOffers] = useState([])
    const [payouts, setPayouts] = useState([])
    useEffect(async ()=>{
        if(token){
            getUncollectedPayouts(token).then(res => {setUncollected(res)})
            getSpins(token).then(res => {setSpins(res)})
        }
    }, [token])


    //get props
    const user = props.user
    const history = useHistory()
    let spin = false
    if(props.location && props.location.state && props.location.state.spin){
        spin = true
    }

    const [show, setShow] = useState(false)

    const hide = () => {
        setShow(!show)
    }    

    const [showDeposit, setShowDeposit] = useState(false)

    useEffect(() => {
        firebaseAnalytics.logEvent("portfolio_visit", {
            user: user && user.email 
         })
        window.scrollTo(0,0)
    }, [])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
            {/*<div className="simbull16">
                      <iframe loading="lazy" className="sim16-body"
                        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAE3_nzxrhU&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                      </iframe>
                </div>*/}
            <div className='Portfolio'>
            <Helmet>
                <title>Portfolio | SimBull - The Stock Market for Sports</title>
            </Helmet>

            
            {isMobile ? 
                <div style={{height: '6vh', backgroundColor: 'black', }}>
                    <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                    <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                        <span style={{}}>Home</span>
                    </div>
                </div>
            : null}
            <Value show={show} hide={hide} totals={totals} spin={spin} prices={userPrices ? userPrices : []} />
            {uncollected && uncollected.length > 0 && <>
                <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />
                <Payouts uncollected={uncollected} />
            </>}
            <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />
            <Watchlist />
            {isMobile ? null : <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />}
            <RewardWidget available={spins.length} spins={spins} />
            <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />
            <Learn home={true} />
            <hr style={{backgroundColor: 'rgb(30,30,30)', height: '.25px', width: '95%', marginLeft: '2.5%'}} />
            <Bits />

            
            

           
            
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    token: state.token.token,
    totals: state.totals.totals,
    equity: state.equity.equity,
    userPrices: state.userPrices.userPrices,
    myuser: state.myuser.myuser
    //userInfo: state.userInfo.userInfo,
});

export default connect(mapStateToProps)(Portfolio2)