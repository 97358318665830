import React, {useState, useEffect, useRef} from 'react'
import {firebaseAnalytics} from '../../services/firebase'
import { connect } from 'react-redux'
import './Trade.css'
import TeamGraph from './TeamGraph'
import {Link, useHistory} from 'react-router-dom'
import {Overlay} from 'react-bootstrap'
import moment from 'moment'
import DownloadTeams from './DownloadTeams'
import {getTeamsValue} from '../Portfolio3/Functions'
import Search from './Search'
import Loader from '../adHoc/Loader'
import '../Account2/Friends/Friends.css'

const FullLeague = (props) => {

    //get props
    const user = props.user
    const [teams, setTeams] = useState(props.teamsValue ? props.teamsValue : [])

    useEffect(()=>{
        if(props.teamsValue && teams.length === 0){
            setTeams(props.teamsValue)
        }
    },[props.teamsValue])

    const history = useHistory()

    const [searchTeam, setSearchTeam] = useState(props.searchTeam ? props.searchTeam : '')
    useEffect(()=>{
        props.searchTeam && setSearchTeam(props.searchTeam)
    },[props])
    useEffect(()=>{
        let val = props.searchTeam
        if(val === '' || !val){
            setSearchTeam('')
        }
    }, [props])
    const teamSearch = (val) => {
        if(val === '' || !val){
            setSearchTeam('')
        }
        setSearchTeam(val)
    }

    let league1 = 'ALL'
    if(props && props.match && props.match.params && props.match.params.id && props.match.params.id.length > 4){
        league1 = props.match.params.id.substring(3, props.match.params.id.length)
    }

    let leagues = ['NFL', 'NBA', 'CFB', 'NHL', 'MLB']
    const [league, setLeague] = useState(league1)
    const [showLeagues, setShowLeagues] = useState(3)

    const [type, setType] = useState('price')
    const [period, setPeriod] = useState('1D')
    const [price, setPrice] = useState('Low')
    const [teamName, setTeamName] = useState('AtoZ')
    const [showTimePeriod, setShowTimePeriod] = useState(false)
    const target = useRef(null);

    teams.sort(function(a, b) {
        if(type === 'period'){
            if(period === '1D'){
                return Math.abs(b.change.day) - Math.abs(a.change.day)
            }
            else if(period === '1W'){
                return Math.abs(b.change.week) - Math.abs(a.change.week)
            }
            else if(period === '1M'){
                return Math.abs(b.change.month) - Math.abs(a.change.month)
            }
            else if(period === '3M'){
                return Math.abs(b.change.threeMonth) - Math.abs(a.change.threeMonth)
            }
            else if(period === '1Y'){
                return Math.abs(b.change.year) - Math.abs(a.change.year)
            }
            else if(period === 'All'){
                return Math.abs(b.change.total) - Math.abs(a.change.total)
            }
        }
        else if(type === 'price'){
            if(price === 'High'){
                return b.asking_price - a.asking_price
            }
            else if(price === 'Low'){
                return a.asking_price - b.asking_price
            }
        }
        else if(type === 'teamName'){
            if(teamName === 'AtoZ'){
                if(a.team.name < b.team.name) { return -1; }
                if(a.team.name > b.team.name) { return 1; }
            }
            else if(teamName === 'ZtoA'){
                if(b.team.name < a.team.name) { return -1; }
                if(b.team.name > a.team.name) { return 1; }
            }
        }
        
    })

    const getPercentChange = (t) => {
        if(period === '1D'){
            return t.change.day * 100
        }
        else if(period === '1W'){
            return t.change.week * 100
        }
        else if(period === '1M'){
            return t.change.month * 100
        }
        else if(period === '3M'){
            return t.change.threeMonths * 100
        }
        else if(period === '1Y'){
            return t.change.year * 100
        }
        else if(period === 'All'){
            return t.change.total * 100
        }
        
    }

    useEffect(()=>{
        !props.market && window.scrollTo(0,0)
    },[])

    useEffect(()=>{
        if(history.location.search){
            let sortArr = history.location.search.split('&')
            let leagueSort = sortArr[0].split('=')[1]
            let typeSort = sortArr[1].split('=')[1]
            let descSort = sortArr[2].split('=')[1]
            setLeague(leagueSort)
            setType(typeSort)
            if(typeSort === 'period'){
                setPeriod(descSort)
            }else if(typeSort === 'price'){
                setPrice(descSort)
            }else if(typeSort === 'teamName'){
                setTeamName(descSort)
            }
        }
    },[])

    useEffect(()=>{
        let desc = type === 'period' ? period : type === 'price' ? price : type === 'teamName' ? teamName : ''
        !props.market && history.replace(`/Trade/Teams?league=${league}&type=${type}&desc=${desc}`)
    }, [league, type, period, teamName, price])

    return(
        <div className='DesktopContainer' style={{ paddingBottom: '25vh'}}>

                

                <div style={{display: 'inline-block', width: '50%', fontSize: '1.7em', fontWeight: '600', paddingLeft: '2px'}}>Sim{league === 'ALL' ? 'Bull Market' : league}</div>
                <div style={{display: 'inline-block', width: '50%', textAlign: 'right', paddingRight: '3%', fontSize: '1.2em', fontWeight: '200', color: 'rgb(100,100,100)'}}>
                    <DownloadTeams />{' '}
                    <Link style={{color: 'rgb(90,90,90)'}} ref={target} onClick={()=>{setShowTimePeriod(!showTimePeriod)}}>
                        {type === 'period' ? `Change (${period})`: type === 'price' ? `Price ${price}`: `Name ${teamName.substring(0,1)} to ${teamName.substring(3,4)}`}{' '}<i style={{marginLeft: '5px'}} className="fas fa-ellipsis-v"></i>
                    </Link>
                    <Overlay className="FC-E-Overlay" target={target.current} show={showTimePeriod} placement="bottom">
                        {(props) => (
                            <div className='FC-E-return-div'  id="overlay-example" {...props}>
                                    {/*<button className="FC-E-item-return-item" onClick={()=>{setPeriod('All'); setShowTimePeriod(false)}} >All</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('1Y'); setShowTimePeriod(false)}} >1Y</button>
                                    <button className="FC-E-item-return-item" onClick={()=>{setPeriod('3M'); setShowTimePeriod(false)}} >3M</button>
                                    */}
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPrice('High'); setShowTimePeriod(false); setType('price')}} > Price High</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPrice('Low'); setShowTimePeriod(false); setType('price')}} > Price Low</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setTeamName('AtoZ'); setShowTimePeriod(false); setType('teamName')}} > Name (A to Z)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setTeamName('ZtoA'); setShowTimePeriod(false); setType('teamName')}} > Name (Z to A)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1M'); setShowTimePeriod(false); setType('period')}} > Change (1M)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1W'); setShowTimePeriod(false); setType('period')}} > Change (1W)</button>
                                    <button className="FC-E-item-return-item" style={{fontSize: '.85em'}} onClick={()=>{setPeriod('1D'); setShowTimePeriod(false); setType('period')}} > Change (1D)</button>
                                
                            </div>
                        )}
                    </Overlay>  
                </div>          


                {/*<div style={{display: 'inline-block', width: '59%',fontSize: '1.15em', fontWeight: '200', color: 'gray', paddingLeft: '2px'}}>SimNFL</div>
                <Link to="/league/SimNFL"><div style={{display: 'inline-block', width: '39%',fontSize: '1.05em', fontWeight: '200', color: 'gray', paddingRight: '2px', textAlign: 'right'}}>View All {' '}<i className="fas fa-arrow-right"></i></div></Link>*/}
            
            <div style={{ marginBottom: '4px', marginTop: '5px'}}>

                {(teams && teams.length > 0) ? teams.map(t => {
                    if(t.team.league === league ||  league === 'ALL'){
                        return(
                            <div key={t.team.id}>
                            <Link to={`/Teams/${t.team.name}`} style={{color: 'black'}}>
                            <div style ={{width: '100%'}}>

                                <div style={{width: '6%',paddingLeft: '1%', display: 'inline-block', textAlign: 'center'}}><img className="full-img" src={`https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${t.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} /></div>
                                <div className="full-name">Sim{t.team.name}</div>
                                <div className="full-price">
                                    <span className="full-span" style={{margin: '0', marginRight: '1px'}}>{t.bidding_price.toFixed(2)}</span>{' '}
                                    ${t.asking_price.toFixed(2)}{' '}
                                    <span className="full-span" style={{margin: '0', marginLeft: '1px'}}>{t.asking_price.toFixed(2)}</span>
                                </div>
                                <div className="full-percent" style={{color: getPercentChange(t) >= 0 ? 'darkgreen' : 'darkred'}}>{getPercentChange(t) >= 0 && "+"}{getPercentChange(t).toFixed(2)}%</div>
                                
                            </div>

                            <hr style={{color: 'rgb(250,250,250)', margin: '7px', padding: '0', fontWeight: '200'}} />
                            </Link>
                            </div>
                        )
                    }
                }): 
                    <Loader />
                }

            </div>

            {/*<Search teamSearch={teamSearch} />*/}

            <div className="LeagueFilter">
                <button onClick={()=>setLeague('ALL')} className={league === 'ALL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>All</button>
                <button onClick={()=>setLeague('NBA')} className={league === 'NBA' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNBA</button>
                <button onClick={()=>setLeague('NFL')} className={league === 'NFL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNFL</button>
                <button onClick={()=>setLeague('NHL')} className={league === 'NHL' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimNHL</button>
                <button onClick={()=>setLeague('CFB')} className={league === 'CFB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimCFB</button>
                <button onClick={()=>setLeague('MLB')} className={league === 'MLB' ? "leagueFilter-btn-selected" : "leagueFilter-btn"}>SimMLB</button>
                
            </div>

            
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams,
    teamsValue: state.teamsValue.teamsValue,
    equity: state.equity.equity,
    trades: state.trades.trades
});

export default connect(mapStateToProps)(FullLeague)