import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logo from '../../assets/Logo1.png'

export default function Modal_(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => {setShow(false); props.hide()}

  useEffect(()=>{
    if(props.show !== show){
        setShow(props.show)
    }
  }, [props.show])

  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const updateMedia = () => {
      setMobile(window.innerWidth < 768);
  };
  useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <div style={{display: 'block', position: 'fixed', zIndex: '1', left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.8)'}}>
          <div style={{border: '1px solid white', borderRadius: '1rem', boxShadow: 'rgb(220,220,220) 0px 3px 15px 0px', backgroundColor: 'white', width: isMobile ? '90vw':'50%', marginLeft: isMobile ? '5vw':'25%', marginTop: '5%', height: '80%', overflow: 'auto'}} >


              <div style={{textAlign: 'center', fontSize: '2rem', fontWeight: '500'}}>
              <img src={logo} style={{height: '35px'}} />
              <br />
                Bullish Bits</div>
              <div style={{textAlign: 'center', fontWeight: '300'}}>Real Stories. Real Users.</div>
              <hr />
              <div style={{padding: '20px', fontSize: '1.5rem', fontWeight: '600', paddingTop: '12px', textAlign: 'center'}}>{props.item.title}</div>
              <div style={{padding: '20px', paddingTop: '0px', fontWeight: '300'}}>{props.item.paragraphs[0]}</div>
              <div><img style={{width: '90%', marginLeft: '5%'}} src={props.item.images_white[0]} /></div>
              {props.item.paragraphs[1] ? <div style={{padding: '20px', paddingTop: '0px', fontWeight: '300'}}>{props.item.paragraphs[1]}</div> : null}
              {props.item.images_white[1] ? <div><img style={{width: '90%', marginLeft: '5%'}} src={props.item.images_white[1]} /></div> : null}
              {props.item.paragraphs[2] ? <div style={{padding: '20px', paddingTop: '0px', fontWeight: '300'}}>{props.item.paragraphs[2]}</div> : null}
              {props.item.images_white[2] ? <div><img style={{width: '90%', marginLeft: '5%'}} src={props.item.images_white[2]} /></div> : null}
              {props.item.paragraphs[3] ? <div style={{padding: '20px', paddingTop: '0px', fontWeight: '300'}}>{props.item.paragraphs[3]}</div> : null}
              {props.item.images_white[3] ? <div><img style={{width: '90%', marginLeft: '5%'}} src={props.item.images_white[3]} /></div> : null}
          
          <button onClick={()=>{props.hide()}} style={{border: 'none', outline: 'none', width: '100%', textDecoration: 'underline', fontWeight: '200', textAlign: 'center', backgroundColor: 'white', paddingBottom: '25px'}}>close</button>

          </div>
          </div>
    </>
  );
}