import axios from 'axios'
import {route} from '../route'

export const doRefresh = async (token) => {
    const balanceOptions = {
        method: 'GET',
        url: `${route}/refresh`,
        params: {
            "platform": "WEBSITE"
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    let newToken
    await axios.request(balanceOptions).then(function (response) {
        let result = response.data
        newToken = result
    }).catch(err => console.log(err))
    return newToken
}