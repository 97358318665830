import axios from 'axios'
import {route} from '../route'

//1. Get Index By ID

/* Index By ID */
export const getIndexByID = async (indexID, interval) => {
    const indexOptions = {
        method: 'GET',
        url: `${route}/index/${indexID}/price_history`,
        params: {
            "interval": interval
        }
    }
    let index = []
    await axios.request(indexOptions).then(function (response) {
        let results = response.data
        index = results
    }).catch(err => console.log(err))
    return index
}