import React, {useState, useEffect} from 'react'
import './welcome.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactPayPal from '../Account2/PayPal/ReactPayPal'
import { Button } from 'react-bootstrap';
import {firebaseAnalytics} from '../../services/firebase'

const Deposit = (props) => {

    const user = props.user
    const teams = props.teams

    const [amt, setAmt] = useState(250)
    const [pro, setPro] = useState(user && user.code ? user.code : '')

    const [showPaypal, setShowPaypal] = useState(false)

    useEffect(()=>{
        if(user && user.code){
            setPro(user.code)
        }
    }, [user])

    useEffect(()=>{
        if(user){
            user.code && setPro(user.code)
        }
    }, [user])

    //Analytics
    useEffect(()=>{
        firebaseAnalytics.logEvent("welcomeDeposit_visit", {
           //user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    const paypalVisible = () => {
        if(amt >= 10){
            setShowPaypal(true)
        }
        else{
            alert('Minimum of $10. Most Teams are over $25.')
        }
    }

    return(
        <div style={{textAlign: 'center'}}>
            {/*<div>
                <span>Promo:</span><br />
                <input />
            </div>
            <div>
                <span>Amount:</span><br />
                <input />
            </div>*/}
            <h2 style={{fontWeight: '600'}}>Get a 90-Day Money Back Guarantee</h2>
            <div>After 90 days, <strong>you keep the profits</strong> or <strong>we refund the entire deposit,</strong> up to $500. </div>
            {!showPaypal && <div className='transfer-deposit-body'>
                <div>
                    <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Choose an Amount</div>
                    <div style={{marginLeft: '10%', marginRight: '10%'}}>
                        <button onClick={()=>setAmt(100)} className={amt === 100 ? "deposit-btn-gold" : "deposit-btn"}>$100</button><button onClick={()=>setAmt(250)} className={amt === 250 ? "deposit-btn-gold" : "deposit-btn"}>$250</button><br />
                        <button onClick={()=>setAmt(500)} className={amt === 500 ? "deposit-btn-gold" : "deposit-btn"}>$500</button><button onClick={()=>setAmt(1000)} className={amt === 1000 ? "deposit-btn-gold" : "deposit-btn"}>$1000</button>
                    </div>
                </div>
                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Other Amount: </div><input className="deposit-input"  placeholder='$' onChange={(e)=>{setAmt(e.target.value)}} value={amt} />
                <br />
                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Promo: </div><input className="deposit-input" onChange={(e)=>{setPro(e.target.value)}} value={pro} />
                <br />
                <Button variant="secondary" style={{margin: 10, width: "40%", marginBottom: '1%', display: 'inline-block', backgroundColor: 'green', fontSize: '1.5rem'}} onClick={()=>{paypalVisible()}}>Deposit</Button>
            </div>}
            
            {showPaypal && <ReactPayPal welcome={true} promo={pro} amount={amt} nextClickHandler={props.nextClickHandler} />}
            {showPaypal && <Button style={{margin: 10, width: "80%", marginBottom: '1%', display: 'inline-block', backgroundColor: 'white', color: 'gray', border: 'none', textDecoration: 'underline', fontSize: '1rem'}} onClick={()=>{setShowPaypal(false)}}>Choose Different Amount</Button>}
            
        </div>
    )
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(Deposit))