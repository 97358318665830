import React from 'react'
import './rewardsPage.css'
import { connect } from 'react-redux'

function RewardsPage(props) {

    const urlParams = new URLSearchParams(window.location.search); // get query params
    const codePram = urlParams.get('code'); // get code from query params

    // share link
    const share = () => {
        if (navigator.share) {
            let _code = codePram ? codePram : (props.myuser && props.myuser.referral_code) ? props.myuser.referral_code : null
            navigator.share({
                title: 'SimBull: Sports Stock Market',
                text: 'Buy and Sell our favorite sports teams like stocks. Get a free stock worth up to $150 when you download SimBull on the Apple App store and sign up with code: ' + _code + "! Download here:" ,
                url: 'https://apps.apple.com/us/app/simbull-sports-stock-market/id1628754092',
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
        else {
            alert('Share not supported on this device. Copy your code and share with friends.')
        }
    }



  return (
    <div className="rewards-container">
        <div className="container-box">
            <div className="container-title-box">
                <h1 className="container-title">Refer a friend</h1>
                <h1 className="container-title">You both get <span style={{color: "#c4b454"}}>free stock</span></h1>
            </div>
            <div className="container-title-section">
                <h1 className="section-title">Invite a friend. <span style={{color: "#c4b454"}}>Get a free team stock.</span></h1>
                <h1 className="section-body">Invite friends to SimBull. Once they sign up and deposit at least $10, you'll get a random free team stock.</h1>
            </div>
            <div className="container-title-section">
                <h1 className="section-title">100% chance to get a free stock</h1>
                <h1 className="section-body">Every time a new friend signs-up and deposits at least $10, you win random team stock valued <span style={{color: "#c4b454"}}>UP TO $150!</span></h1>
            </div>
            <div className="container-title-section">
                <h1 className="section-title">Unlimited invites. Unlimited free stock.</h1>
                <h1 className="section-body">Invite as many friends as you want and build your portfolio as the market grows! Tell your friends to use this code to signup.</h1>
            </div>
            <button onClick={() => share()} className="sharebtn">
                <h1 style={{fontSize: "1.3rem", marginTop: ".4rem"}}>Share</h1>
            </button>
            <div className="refer-code">
                <div className="refer-box">
                    <div className="refer-left">
                        <div className="txtbox">
                            <h1 style={{fontSize: ".85rem", color: 'white', fontWeight: 100, fontFamily: "'Lato', sans-serif"}}>Referral Code</h1>
                        </div>
                        <div className="txtbox">
                            <h1 style={{color: "white", fontSize: "1.3rem", fontFamily: "'Lato', sans-serif", fontWeight: "bold"}}>{codePram ? codePram : ""}</h1>
                        </div>
                    </div>
                    <div className="refer-right">
                        <div onClick={() => {navigator.clipboard.writeText(codePram)}} className="copy-box">
                            <h1 style={{fontSize: ".9rem", color: 'white', fontWeight: 700, fontFamily: "'Lato', sans-serif", marginTop: ".4rem"}}>Copy</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RewardsPage;