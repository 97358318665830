import axios from 'axios'
import {route} from '../route'



/* Get My Leaderboard Data */
export const get_my_mm_leaderboard = async (token) => {
    const pickOptions = {
        method: 'GET',
        url: `${route}/mm/@`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {

        }
    }
    let pick = []
    await axios.request(pickOptions).then(function (response) {
        let results = response.data
        pick = results
    }).catch(err => console.log(err))
    return pick
}

/* Get My Picks */
export const get_my_picks = async (token) => {   
    const pickOptions = {
        method: 'GET',
        url: `${route}/mm/picks`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {

        }
    }
    let pick = []
    await axios.request(pickOptions).then(function (response) {
        let results = response.data
        pick = results
    }).catch(err => console.log(err))
    return pick
}

/* Post Picks */
export const post_picks = async (token, picksArr, repick) => {
    let params = new URLSearchParams()
    if(picksArr.length > 0){
        picksArr.map(p => {
            params.append("picks", p)
        })
    }
    params.append("repicking", repick)
    const pickOptions = {
        method: 'POST',
        url: `${route}/mm/picks`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params
    }
    let pick
    await axios.request(pickOptions).then(function (response) {
        console.log(response)
        let results = response
        pick = results
    }).catch(err => {pick = err.response.data})
    return pick
}

/* Get March Madness Games */
export const get_mm_games = async (picksArr) => {
    const pickOptions = {
        method: 'GET',
        url: `${route}/mm/games`,
        params: {

        }
    }
    let pick = []
    await axios.request(pickOptions).then(function (response) {
        let results = response.data
        pick = results
    }).catch(err => console.log(err))
    return pick
}

/* Get Leaderboard */
export const get_mm_leaderboard = async (picksArr) => {
    const pickOptions = {
        method: 'GET',
        url: `${route}/mm/leaderboard`,
        params: {

        }
    }
    let pick = []
    await axios.request(pickOptions).then(function (response) {
        let results = response.data
        pick = results
    }).catch(err => console.log(err))
    return pick
}

/* Get Teams */
export const get_mm_teams = async () => {
    const teamsOptions = {
        method: 'GET',
        url: `${route}/mm/teams`,
        params: {

        }
    }
    let teams
    await axios.request(teamsOptions).then(function (response) {
        let results = response.data
        teams = results
    }).catch(err => console.log(err))
    return teams
}

