export const getTeam = (team_id, teams) => {
    let team = ''
    //console.log(props.teams.teamsValue)
    //console.log(team_id)
    teams.map(t => {
        if(t.team.id === team_id){
            team = t
            return t
        }
    })
    return team
}
//sort list of objects
export const sortGainers = (list, teams) => { //orders a list into the top gainers on a monthly basis
    let newList = list
    newList.sort((a, b) => {
        let aTeam = getTeam(a.asset_id, teams)
        let bTeam = getTeam(b.asset_id, teams)
        if (bTeam.change.day === aTeam.change.day) {
            return 0;
        } else {
            return bTeam.change.day > aTeam.change.day ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortLosers = (list, teams) => { //orders a list into the losers on a monthly basis
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if (a.change.day === b.change.day) {
            return 0;
        } else {
            return a.change.day > b.change.day ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortHottest = (list, teams) => { //need to get trade volume for this -- was also thinking about do absolute value of change but it's messing some sorting up when swithcing lists
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        let aTeam = Math.abs(a.change.day)
        let bTeam = Math.abs(b.change.day)
        if (bTeam === aTeam) {
            return 0;
        } else {
            return bTeam > aTeam ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortHighPrice = (list, teams) => { //orders a list into the highest price
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if (a.asking_price === b.asking_price) {
            return 0;
        } else {
            return b.asking_price > a.asking_price ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortLowPrice = (list, teams) => { //orders a list into the lowest price
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if (a.asking_price === b.asking_price) {
            return 0;
        } else {
            return a.asking_price > b.asking_price ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortEquityValueLow = (list, teams) => { //orders a list into the lowest equity value
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if ((a.asking_price * a1.amount) === (b.asking_price * b1.amount)) {
            return 0;
        } else {
            return (a.asking_price * a1.amount) > (b.asking_price * b1.amount) ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortEquityValueHigh = (list, teams) => { //orders a list into the highest equity value
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if ((a.asking_price * a1.amount) === (b.asking_price * b1.amount)) {
            return 0;
        } else {
            return (b.asking_price * b1.amount) > (a.asking_price * a1.amount) ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortEquityAmountLow = (list, teams) => { //orders a list into the lowest equity value
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if ((a1.amount) === (b1.amount)) {
            return 0;
        } else {
            return (a1.amount) > (b1.amount) ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortEquityAmountHigh = (list, teams) => { //orders a list into the highest equity value
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if ((a1.amount) === (b1.amount)) {
            return 0;
        } else {
            return (b1.amount) > (a1.amount) ? 1 : -1;
        }
    }
    )
    return newList;
}
export const sortAtoZ = (list, teams) => { //orders by team name A to Z
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if(a.team.name < b.team.name) { return -1; }
        if(a.team.name > b.team.name) { return 1; }
        return 0;
    })
    return newList;
}
export const sortZtoA = (list, teams) => { //orders by team name Z to A
    let newList = list
    newList.sort((a1, b1) => {
        let a = getTeam(a1.asset_id, teams)
        let b = getTeam(b1.asset_id, teams)
        if(a.team.name < b.team.name) { return 1; }
        if(a.team.name > b.team.name) { return -1; }
        return 0;
    })
    return newList;
}
