import React, {useState, useEffect } from 'react'
import './Account.css'
import Header from './Header'
import {Link, withRouter, useHistory} from 'react-router-dom'
import { db } from '../../services/firebase'
import { connect } from 'react-redux'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {getAccountInfo, updateAccountInfo} from '../../routes/user/account'
import Popup from 'reactjs-popup'


const TextCollect = (props) => {
    //props
    const user = props.user

    const [value, setValue] = useState()

    const history = useHistory()

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [confirmation, setConfirmation] = useState(false)

    const updateUser = (address, birthday, email, first_name, last_name, phone_number, state, username, zip_code) => {
        if(props.token){
            /*let address = props.info ? (props.info.address ? props.info.address : null) : null
            let birthday = props.info ? (props.info.birthday ? props.info.birthday : null) : null
            let email = props.info ? (props.info.info.email ? props.info.email : null) : null
            let first_name = props.info ? (props.info.first_name ? props.info.first_name : null) : null
            let last_name = props.info ? (props.info.last_name ? props.info.last_name : null) : null
            let phone_number = props.info ? (props.info.phone_number ? props.info.phone_number : null) : null
            let state = props.info ? (props.info.state ? props.info.state : null) : null
            let username = props.info ? (props.info.username ? props.info.username : null) : null
            let zip_code = props.info ? (props.info.zip_code ? props.info.zip_code : null) : null*/
            updateAccountInfo(props.token, address, birthday, email, first_name, last_name, phone_number, state, username, zip_code).then(result => {
                let carry 
                if(result.username){
                    carry = {type: 'success', date: result} //good
                    setConfirmation(true)
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.password && result.data.msg.query.password[0] ){
                    carry = {type: 'err', data: result.data.msg.query.password[0]} //don't include a username
                    setError(true)
                    setErrorMsg(result.data.msg.query.password[0])
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.email && result.data.msg.query.email[0] ){
                    carry = {type: 'err', data: result.data.msg.query.email[0]} //don't include a email
                    setError(true)
                    setErrorMsg(result.data.msg.query.email[0])
                }
                else if(result.data && result.data.msg && result.data.msg.query && result.data.msg.query.phone_number && result.data.msg.query.phone_number[0] ){
                    carry = {type: 'err', data: result.data.msg.query.phone_number[0]} //don't include a email
                    setError(true)
                    setErrorMsg(result.data.msg.query.phone_number[0])
                }
                return carry
            })
        }
        else return {type: 'err'}
    }

    //Functionality
    const submitNumber = async () => {
        if(value){
            updateUser(null, null, null, null, null, value, null, null, null)
        }
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
        
        <div className="Notifications DesktopContainer">
            <div style={{fontSize: '2em', marginTop: '10px', fontWeight: '600', textAlign: 'center'}}>Never miss an opportunity again.</div>
            <div style={{textAlign: 'center', fontWeight: '300', marginTop: '5px'}}>Get a message whenever your trade executes.</div>
            <div style={{textAlign: 'center', marginTop: '15px', width: '90%', marginLeft: '5%', fontSize: '1.4em'}}>
                <PhoneInput
                defaultCountry="US"
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}/>
            </div>
            {error && <div style={{textAlign: 'center', color: 'red'}}>{errorMsg}</div>}
            <div style={{textAlign: 'center', marginTop: '25px', width: '90%', marginLeft: '5%', fontSize: '1.25em'}}>
                <button onClick={submitNumber} style={{border: 'none', outline: 'none', borderRadius: '.2rem', backgroundColor: '#c5b358', width: '60%'}}>Keep Me Informed</button>
            </div>
            <div style={{textAlign: 'center', marginTop: '35px', width: '90%', marginLeft: '5%', fontSize: '.7em', color: 'gray', fontWeight: '200'}}>
                By signing up via text, you agree to receive marketing messages at the number provided. Consent is not a condition of purchase. Message and data rates may apply. Message frequency varies. Reply HELP for help or STOP to cancel. View our Privacy Policy and Terms of Service.
            </div>

            <Popup open={confirmation} modal>
                <div style={{width: isMobile ? '90vw' : '50vw', marginLeft: '1vw', backgroundColor: 'rgb(250,250,250)', border: '5px solid black', boxShadow: '0 0 5px 10px #c5b358',}}>
                    <div style={{textAlign: 'center', fontSize: '2.5em'}}>Phone Number Confirmed!</div>
                    <div style={{width: '80%', marginLeft: '10%', marginTop: '25px', textAlign: 'center', marginBottom: '15px'}}>
                    
                        <div style={{textAlign: 'center'}}>
                            
                            <button onClick={()=>history.replace('/')} style={{backgroundColor: 'rgb(250,250,250)', border: 'none', outline: 'none', fontSize: '.8em', color: 'gray', marginTop: '10px'}}>close</button>
                        </div>
                    </div>
                    
                </div>
            </Popup>
            
        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.token.token
});

export default connect(mapStateToProps)(withRouter(TextCollect))