import React, {useEffect, useState} from 'react'
import './welcome.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {firebaseAnalytics} from '../../services/firebase'
import PhoneInput from 'react-phone-number-input'

const DataCollect = (props) => {

    const teams = props.teams

    //Analytics
    useEffect(()=>{
        firebaseAnalytics.logEvent("welcomeDataCollect_visit", {
           //user: user && user.email 
        })
        window.scrollTo(0,0)
    },[])

    return(
        <div className="DataCollect">
            <h2 style={{fontWeight: '600', textAlign: 'center'}}>Welcome to the Stock Market For Sports!</h2>
            <div style={{textAlign: 'center', marginBottom: '20px'}}>You have successfully created an account! <br /><br />
                The SimBull Market is booming with over <strong>$1.6 million dollars in trades and 7,000+ users. </strong> 
                We want you to get in on the action with <strong>NO RISK.</strong> Stay Tuned.</div>
            <div className="input-desc">First Name: </div>
            <div>
                <input 
                    className="input-input"
                    onChange={(e)=>{props.getFirstName(e.target.value)}}
                    value={props.firstName}
                />
            </div>
            <div className="input-desc">Last Name: </div>
            <div>
                <input
                    className="input-input"
                    onChange={(e)=>{props.getLastName(e.target.value)}}
                    value={props.lastName}
                 />
            </div>
            <div className="input-desc">Phone #: </div>
            <div>
               {/*} <input
                    className="input-input"
                    onChange={(e)=>{props.getPhone(e.target.value)}}
                    value={props.phone}
                 />*/}
                 <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={props.phone}
                    onChange={props.getPhone}
                    style={{textAlign: 'center', width: '90%'}}    
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams.teams,
    user: state.user.user
});

export default connect(mapStateToProps)(withRouter(DataCollect))