import * as types from "../../types";
import {getEquity} from '../../../routes/user/user'

export const getUserEquity = (token) => (dispatch) => {

  getEquity(token).then(res => {
    dispatch({
      type: types.GET_USER_EQUITY,
      payload: res
    })
  })

};
