import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import '../portfolio2.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {Link, useHistory} from 'react-router-dom'
import {addToMyWatchlist, deleteFromMyWatchlist} from '../../../routes/user/watchlist'
import {getWatchlist} from '../../../redux/actions/user/watchlist'
import {useDispatch} from 'react-redux'
import WatchlistStar from './WatchlistStar'
import amplitude from 'amplitude-js'

const TeamCard = (props) => {
    
    const history = useHistory()
    const teams = props.teamsValue
    const dispatch = useDispatch()

    const [team, setTeam] = useState(false)

    let id = props.id ? props.id : props._id

    useEffect(()=>{
        if(id && teams && !team){
            teams.map(t => {
                if(t.team.id === id){
                    setTeam(t)
                }
            })
        }
    },[props.teamsValue, props.id, props._id])

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        {team && <tr style={{width: '100%',  color: 'gray', paddingBottom: isMobile ? '6px' : '0px', borderBottom: isMobile ? '.5px solid rgb(60,60,60)': 'none'}}>
            <td style={{width: isMobile ? '40px' :'50px', paddingRight: isMobile ? '4px' : '0px', }} onClick={()=> {history.push(`/teams/${team.team.name}`); amplitude.getInstance().logEvent('watchlist_team', {team: team.team.name})}}>
                <div style={{
                    height: isMobile ? '40px' :'50px',
                    width: isMobile ? '40px' :'50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#171717',
                    border: '0.25px solid #4B4B4B',
                    margin: isMobile ? '4px' :'8px',
                }}>
                    <img style={{width: '80%', }} src={team && `https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/jerseys%2F${team.team.name}.png?alt=media&token=23f2917a-f1ad-48c5-a272-54be86a32af1`} />
                </div>
            </td>
            <td style={{textAlign: 'left'}} onClick={() => {history.push(`/teams/${team.team.name}`); amplitude.getInstance().logEvent('watchlist_team', {team: team.team.name})}}>
                <div style={{fontSize: '1.1rem', color: 'white', marginTop: isMobile ? '3px' : '0px', fontWeight: '600'}}>Sim{team && team.team && team.team.name}</div>
                <div style={{fontSize: '.9rem', color: 'darkgray', marginBottom: isMobile ? '3px' : '0px'}}>${team && team.team && team.team.league}-{team && team.team && team.team.abbr}</div>
            </td>
            {id ? <WatchlistStar id={id} /> : <td></td>}
            
            <td onClick={()=> {history.push(`/teams/${team.team.name}`); amplitude.getInstance().logEvent('watchlist_team', {team: team.team.name})}}><span style={{fontSize: '.95rem',paddingLeft: '10px', paddingRight: '10px', paddingTop: '4px', paddingBottom: '4px',backgroundColor: '#212121', color: 'white', borderRadius: '1rem', fontWeight: '600'}}>${team && team.asking_price.toFixed(2)}{' '}</span></td>
            <td onClick={()=> {history.push(`/teams/${team.team.name}`); amplitude.getInstance().logEvent('watchlist_team', {team: team.team.name})}}><span style={{fontSize: '.95rem', color: (props.change ? props.change : team && team.change.day) >= 0 ? 'rgb(0,200,0)' : 'rgb(200,0,0)', backgroundColor: (props.change ? props.change : team && team.change.day) >= 0 ? 'rgb(0,60,0)' : 'rgb(60,0,0)', borderRadius: '1rem', paddingLeft: '10px', paddingRight: '10px', paddingTop: '4px', paddingBottom: '4px'}}>{`${(props.change ? props.change : team && team.change.day) >= 0 ? '+' : '-'}${(props.change ? Math.abs(props.change).toFixed(2) : Math.abs(team.change.day * 100).toFixed(2))}%`}</span></td>
            {isMobile ? null : <td onClick={()=> {history.push({pathname: `/teams/${team.team.name}`, state: { buyNow: true }}); amplitude.getInstance().logEvent(
                "watchlist_buy", {
                    team: team.team.name,
                }
            )}} style={{textAlign: 'right'}}><span style={{fontSize: '1.1rem', fontWeight: '600', color: '#c4b454', marginRight: '0px'}}>BUY</span></td>}

        </tr>}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teamsValue: state.teamsValue.teamsValue,
    totals: state.totals.totals,
    token: state.token.token,
    watchlist: state.watchlist.watchlist
});

export default connect(mapStateToProps)(TeamCard)