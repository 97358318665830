import React, {useState, useEffect,useRef} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {db, firebaseAnalytics} from '../../services/firebase'
import firebase from 'firebase'
import {Link, useHistory} from 'react-router-dom'
import TeamCard from './TeamCard'
import './LandingPage3.css'
import Teams from './Teams'
import Reserves from './Reserves'
import FAQs from './FAQs'
import NavBar from '../LandingPage3/NavBar'
import Main from '../LandingPage3/Main'
import Footer from '../LandingPage3/Footer'
import Signup from './Signup'

import LandingPage4 from '../LandingPage4/LandingPage4'


const LandingPage3 = (props) => {

    const user = props.user
    const teams = props.teams

    const signupRef = useRef(null)

    useEffect(()=>{
        firebaseAnalytics.logEvent("landingPage_visit")
        window.scrollTo(0,0)
    },[])

    const loadChatBox = () => {
        var _grChatConfig = {urlParams:"c=490f15211d26f3403a2440bde57a7a7c6e89008391e581724283035d67",iuv:"v1"}
        // Custom configuration goes here
        return !function(a,e){
            if(Array.isArray(window.__GrChatData__)){
                window.__GrChatData__.push(e)
            }
            else{
                window.__GrChatData__=[e]
            }
            var r=document.createElement("script")
            
            r.defer=!0
            var t=document.getElementsByTagName("script")[0]
            t.parentNode.insertBefore(r,t)
            r.src="https://i.getresponse.chat/embedded_chat.js"
        }(0,_grChatConfig);
    }

    useEffect(() => {
        //setTimeout(loadChatBox, 5000)
    }, []);


    const down = () => {
        return signupRef.current.scrollIntoView()  
    }

    return(
        <div className="LandingPage3">
            {/*<NavBar />
            
            <Main down={down} />*/}
            <LandingPage4 />
            <Teams />
            <Reserves />
            <FAQs />
            <span ref={signupRef}>
                <Signup />
            </span>
            
            <Footer color={'rbg(235,235,235)'} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    teams: state.teams.teams.teams
});

export default connect(mapStateToProps)(LandingPage3)