import React, {useEffect, useState} from 'react'
import './Account.css'
import Header from './Header'
import {Link, withRouter, useHistory} from 'react-router-dom'
import ReactPayPal from './PaypalDesktop/ReactPayPal'
import PsWeekly25 from './PayPal/PS-Weekly25'
import PsWeekly10 from './PayPal/PS-Weekly10'
import PsMonthly250 from './PayPal/PS-Monthly250'
import PsMonthly100 from './PayPal/PS-Monthly100'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import {db} from '../../services/firebase'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import logo1 from '../assets/Logo1.png'

const Transfers = (props) => {
    //props
    const user = props.user
    const history = useHistory()

    //states
    const [amount, setAmount] = useState(250)
    const [promo, setPromo] = useState('')
    const [checkout, setCheckout] = useState(false)
    const [reoccurringDeposit, setReoccurringDeposit] = useState(false)

    useEffect(()=>{
        ReactPixel.track('View', {user_role: 'member'})
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    const updateAmount = (value) => {
        value = value.replace(/,/g, "");
        value = value.replace("$", "");
        setAmount(value)
    }

    const showCheckout = () => {
        let numType = parseFloat(amount)
        if(numType < 10 || numType === undefined){
            alert('$10 minimum')
        }else if(numType > 5000){
            alert(`Maximum Deposit Exceed`)
        }else if(isNaN(numType)){
            alert('Try a real number')
        }else{setCheckout(true)}
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return(
        <>
        {checkout === true ? (
              <div>
                <ReactPayPal promo={promo} amount={amount} />
              </div>
            ) : (
                    <>
                        <div style={{backgroundColor: 'black', paddingLeft: isMobile ? '12px':'248px', paddingRight: isMobile ? '12px':'348px', paddingBottom: isMobile ? '7vh':'200px', }}>

                        {isMobile ? 
                            <div style={{height: '6vh', backgroundColor: 'black', marginBottom: '2vh'}}>
                                <div style={{display: 'inline-block', width: '25%', height: '6vh'}}><img src={logo1} style={{height: '90%', marginTop: '-2vh', marginBottom: '0%', marginLeft: '4px'}} /></div>
                                <div style={{display: 'inline-block', width: '50%', height: '6vh', color: 'white', textAlign: 'center', fontSize: '1.5rem', paddingTop: '2vh', fontWeight: '500'}}>
                                    <span style={{}}>Deposit</span>
                                </div>
                            </div>
                        : null}

                        <div style={{textAlign: 'center', color: 'white'}}>
                            <div style={{backgroundColor: 'rgb(30,30,30)', display: 'inline-block', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '2rem', marginBottom: '12px'}}>
                                <div style={{display: 'inline-block'}}><button style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: '#c4b454', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Deposit</button></div>
                                <div style={{display: 'inline-block'}}><button onClick={()=>history.push('/Withdrawal')} style={{border: 'none', outline: 'none', borderRadius: '1rem', backgroundColor: 'rgb(30,30,30)', color: 'gray', fontWeight: '300', padding: '20px', paddingTop: '4px', paddingBottom: '4px'}}>Withdraw</button></div>
                            </div>
                            <div className='transfer-deposit-head'>One-Time Deposit</div>
                            <span>Your account will be funded immediately. <br />Payments are processed by PayPal or Credit/Debit Card.</span>
                            {!checkout && <div className='transfer-deposit-body'>
                                <div>
                                    <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Choose an Amount</div>
                                    <div style={{marginLeft: '10%', marginRight: '10%'}}>
                                        <button onClick={()=>setAmount(100)} className={amount === 100 ? "deposit-btn-gold" : "deposit-btn"} style={{backgroundColor: 'black'}}>$100</button><button onClick={()=>setAmount(250)} className={amount === 250 ? "deposit-btn-gold" : "deposit-btn"} style={{backgroundColor: 'black'}}>$250</button><br />
                                        <button onClick={()=>setAmount(500)} className={amount === 500 ? "deposit-btn-gold" : "deposit-btn"} style={{backgroundColor: 'black'}}>$500</button><button onClick={()=>setAmount(1000)} className={amount === 1000 ? "deposit-btn-gold" : "deposit-btn"} style={{backgroundColor: 'black'}}>$1000</button>
                                    </div>
                                </div>
                                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Other Amount: </div><input className="deposit-input" style={{backgroundColor: 'rgb(80, 80, 80)', color: 'white'}}  placeholder='$' onChange={(e)=>{setAmount(e.target.value)}} value={amount} />
                                <br />
                                <div style={{color: 'gray', marginTop: '15px',textAlign: 'left', paddingLeft: '5%', marginBottom: '5px'}}>Promo: </div><input className="deposit-input" style={{backgroundColor: 'rgb(80, 80, 80)', color: 'white'}}  onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                <br />
                                <Button variant="secondary" style={{margin: 10, width: "40%", marginBottom: '1%', display: 'inline-block', backgroundColor: 'green', fontSize: '1.5rem'}} onClick={showCheckout}>Deposit</Button>
                            </div>}
                            {/*<div className='transfer-deposit-body'>
                                Amount:<br /><input className="transfer-deposit-input" onChange={(e)=>{updateAmount(e.target.value)}} value={amount} placeholder='$' />
                                <br />
                                Promo:<br /> <input className="transfer-promo-input" onChange={(e)=>{setPromo(e.target.value)}} value={promo} />
                                <br />
                                <button className="transfer-depsoit-btn" onClick={showCheckout}>Continue to Deposit</button>
                            </div>*/}
                        </div>
                        {/*<div className='transfer-deposit'>
                            <div className='transfer-deposit-head'>Start a Reoccurring Deposit</div>f
                            
                            <div className='transfer-deposit-body'>
                                <PsWeekly10 />
                                <hr />
                                <PsWeekly25 />
                                <hr />
                                <PsMonthly100 />
                                <hr />
                                <PsMonthly250 />
                                <hr />
                            </div>
                            
                        </div>
                        {reoccurringDeposit && <>
                            <div className="reoccurringDepositDiv">
                                    You have a Reoccurring Deposit of ${reoccurringDeposit.amount} {reoccurringDeposit.frequency}. <br />
                                    <span className="reoccurringDepositSpan">Your next deposit will be {reoccurringDeposit.dow}, {reoccurringDeposit.nextDate.substring(4,6)}-{reoccurringDeposit.nextDate.substring(6,8)}-{reoccurringDeposit.nextDate.substring(0,4)}.</span>
                            </div>
                            <Link onClick={cancelReoccurringDeposit}><div className='transfer-div'>Cancel Reoccurring Deposit</div></Link>
                        </>} */}
                        {/*<Link to ='/Withdraw'><div className='transfer-div'>Withdraw</div></Link>*/}
                        {/*<Link to ='/RequestPennant'><div className='transfer-div'>Request your Pennant</div></Link>*/}
                        </div>
                    </>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps)(withRouter(Transfers))